<!--
 * @Description: 字幕设置
 * @version: 
 * @Author: Carful
 * @Date: 2022-11-14 11:03:25
 * @LastEditors: Carful
 * @LastEditTime: 2023-06-14 11:46:23
-->
<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    :width="'1100px'"
    :top="'5%'"
    center
    :close-on-click-modal="false"
    @close="closeDialog"
  >
    <template v-slot:title>
      <div class="tasknameslotTitle">
        <p class="title_p overflowEllipsis">
          <span>{{ title }}</span>
        </p>
        <div class="tasktitlebtn">
          <div>
            <!--   将当前设置保存为模板 -->
            <el-button
              type="text"
              @click="saveToTemplate()"
              v-show="project_template_id == ''"
            >
              <div class="svgbtn">
                <!-- <svg-icon icon-class="create" class="icon"></svg-icon> -->
                <p>{{ $t("ProjectManagement.saveToTemplate") }}</p>
              </div>
            </el-button>
            <!--   解除绑定 -->
            <el-button
              type="text"
              @click="unbindTemplate()"
              v-show="+project_template_id > 0"
            >
              <div class="svgbtn">
                <p>{{ $t("ProjectManagement.unbindTemplate") }}</p>
              </div>
            </el-button>
          </div>
          <div class="lastdiv"></div>
        </div>
      </div>
    </template>
    <div class="center">
      <div class="glo_top">
        <div>
          <p
            :class="Glossariestabs == '0' ? 'setbackground' : ''"
            @click="changeGlossariestabs('0')"
          >
            {{ $t("CaptionSettings.tab1") }}
            <i
              v-if="Glossariestabs == '0'"
              class="el-icon-caret-bottom tabsbtnicon"
            ></i>
          </p>
        </div>
        <div>
          <p
            :class="Glossariestabs == '1' ? 'setbackground' : ''"
            @click="changeGlossariestabs('1')"
          >
            {{ $t("CaptionSettings.tab2") }}
            <i
              v-if="Glossariestabs == '1'"
              class="el-icon-caret-bottom tabsbtnicon"
            ></i>
          </p>
        </div>
        <div>
          <p
            :class="Glossariestabs == '2' ? 'setbackground' : ''"
            @click="changeGlossariestabs('2')"
          >
            {{ $t("CaptionSettings.tab3") }}
            <i
              v-if="Glossariestabs == '2'"
              class="el-icon-caret-bottom tabsbtnicon"
            ></i>
          </p>
        </div>
        <div v-if="false">
          <p
            :class="Glossariestabs == '3' ? 'setbackground' : ''"
            @click="changeGlossariestabs('3')"
          >
            {{ $t("CaptionSettings.tab4") }}
            <i
              v-if="Glossariestabs == '3'"
              class="el-icon-caret-bottom tabsbtnicon"
            ></i>
          </p>
        </div>
        <div v-if="false">
          <p
            :class="Glossariestabs == '4' ? 'setbackground' : ''"
            @click="changeGlossariestabs('4')"
          >
            AI Translation
            <i
              v-if="Glossariestabs == '4'"
              class="el-icon-caret-bottom tabsbtnicon"
            ></i>
          </p>
        </div>
        <div v-if="false">
          <p
            :class="Glossariestabs == '5' ? 'setbackground' : ''"
            @click="changeGlossariestabs('5')"
          >
            <!--            字幕区域-->
            {{ $t("CaptionSettings.tab8") }}
            <i
              v-if="Glossariestabs == '5'"
              class="el-icon-caret-bottom tabsbtnicon"
            ></i>
          </p>
        </div>
        <div v-if="false">
          <p
            :class="Glossariestabs === '6' ? 'setbackground' : ''"
            @click="changeGlossariestabs('6')"
          >
            <!--      ASR 设置      -->
            {{ $t("CaptionSettings.tab7") }}
            <i
              v-if="Glossariestabs === '6'"
              class="el-icon-caret-bottom tabsbtnicon"
            ></i>
          </p>
        </div>
        <div v-if="false">
          <p
            :class="Glossariestabs === '7' ? 'setbackground' : ''"
            @click="changeGlossariestabs('7')"
          >
            <!--      SRT设置      -->
            {{ $t("CaptionSettings.tab9") }}
            <i
              v-if="Glossariestabs === '7'"
              class="el-icon-caret-bottom tabsbtnicon"
            ></i>
          </p>
        </div>
      </div>
      <div class="glo_bom">
        <div v-if="Glossariestabs === '0'">
          <div class="glo_bom_top">
            <!-- 每行字幕最短持续时间 -->
            <div>
              <p>
                {{ $t("CaptionSettings.Minimumdurationofsubtitlesperline") }}
              </p>
              <el-input
                v-model="Glossariestab1.min_duration"
                style="display: inline"
                maxlength="6"
              ></el-input>
              <span class="glo_bom_top_second">Second(s)</span>
            </div>
            <!-- 每行字最长持续时间 -->
            <div style="margin-left: 25px">
              <p>{{ $t("CaptionSettings.Maximumdurationperlineofwords") }}</p>
              <el-input
                v-model="Glossariestab1.max_duration"
                maxlength="6"
                style="display: inline"
              ></el-input>
              <span class="glo_bom_top_second">Second(s)</span>
            </div>
            <!-- 句段最大行数 -->
            <div style="margin-left: 25px">
              <p>{{ $t("CaptionSettings.Maxlines") }}</p>
              <el-input
                v-model="Glossariestab1.line_count"
                maxlength="1"
                style="display: inline"
              ></el-input>
              <!-- <span class="glo_bom_top_second"></span> -->
            </div>
          </div>
          <!-- Minigap Time -->
          <div class="glo_bom_center">
            <p>{{ $t("CaptionSettings.MinigapTime") }}</p>
            <div class="checkboxClass">
              <el-input
                v-model="Glossariestab1.minigap_time"
                maxlength="7"
              ></el-input>
              <el-checkbox v-model="Glossariestab1.minigap_type" border
                >{{ $t("CaptionSettings.Allowequalsto0") }}
              </el-checkbox>
            </div>
          </div>
          <div
            v-if="Glossariestab1.langs"
            class="glo_bom_table tableClass borderTableStyle"
            :class="Glossariestab1.langs.length == 0 ? 'tablebtmborder' : ''"
          >
            <p>
              {{ $t("CaptionSettings.Maximumwordslimitperlineofsubtitles") }}
            </p>
            <el-table
              :row-style="{
                height: 40 + 'px',
                background: '#454758',
                color: '#ffffff',
              }"
              :cell-style="{ padding: 0 + 'px' }"
              :header-cell-style="{
                height: 32 + 'px',
                padding: '0px',
                color: '#adb0bc',
                background: '#454758',
              }"
              :data="Glossariestab1.langs"
              tooltip-effect="dark"
              style="width: 100%"
              max-height="180px"
              border
            >
              <!--  语种-->
              <el-table-column
                prop="language_name"
                :label="$t('CaptionSettings.languages')"
              >
              </el-table-column>
              <!--最大字数  -->
              <el-table-column :label="$t('CaptionSettings.MaximumWords')">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.char_line"></el-input>
                </template>
              </el-table-column>
              <!--读速  -->
              <el-table-column
                :label="$t('CaptionSettings.Readrate')"
                label-class-name="labelClassName"
              >
                <template slot-scope="scope">
                  <el-input v-model="scope.row.char_speed"></el-input>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div v-if="Glossariestabs == '1'" class="Glossariestabs2">
          <p>
            {{ $t("CaptionSettings.Glossariestabs2Title") }}
          </p>
          <!-- <div class="radioClass">
            <el-radio-group v-model="Glossariestab2.radio">
              <el-radio border label="0">{{
                $t("CaptionSettings.nothing")
              }}</el-radio>
              <el-radio border label="1">{{
                $t("CaptionSettings.ChineseCharacters")
              }}</el-radio>
              <el-radio border label="2">{{
                $t("CaptionSettings.EnglishCharacters")
              }}</el-radio>
            </el-radio-group>
          </div> -->
          <div>
            <!-- <div class="Gloss_btm">
              <el-switch
                v-model="Glossariestab2.value"
                active-color="#46adff"
                inactive-color="#7d7f8b"
              >
              </el-switch>
              <p>{{ $t("CaptionSettings.CustomForbiddenCharacters") }}</p>
            </div> -->
            <el-input
              type="textarea"
              v-model="Glossariestab2.textarea"
              resize="none"
              :placeholder="$t('CaptionSettings.Useregextocustomize')"
            ></el-input>
          </div>
        </div>
        <div v-if="Glossariestabs == '2'" class="Glossariestabs3">
          <div class="Glossariestabs3_input">
            <p>{{ $t("CaptionSettings.tab3") }}</p>
            <el-input v-model="Glossariestab3.WaterprintSTT"></el-input>
            <!-- <p>{{ $t("CaptionSettings.Waterprint.WaterprintTRA") }}</p>
            <el-input v-model="Glossariestab3.WaterprintTRA"></el-input>
            <p>{{ $t("CaptionSettings.Waterprint.WaterprintREV") }}</p>
            <el-input v-model="Glossariestab3.WaterprintREV"></el-input> -->
          </div>
          <div
            class="tableClass borderTableStyle"
            :class="
              Glossariestab3.tab3tableData.length == 0 ? 'tablebtmborder' : ''
            "
          >
            <el-table
              :row-style="{
                height: 32 + 'px',
                background: '#393c4e',
                color: '#ffffff',
              }"
              :cell-style="{ padding: 0 + 'px' }"
              :header-cell-style="{
                height: 32 + 'px',
                padding: '0px',
                color: '#adb0bc',
                background: '#454758',
              }"
              :data="Glossariestab3.tab3tableData"
              tooltip-effect="dark myTooltips"
              style="width: 100%"
              max-height="180px"
              @selection-change="handleSelectionChange"
              border
            >
              <!-- <el-table-column type="selection" width="39"> </el-table-column> -->
              <el-table-column
                prop="job_name"
                :label="$t('CommonName.TaskName')"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                :label="$t('CaptionSettings.Waterprint.WaterprintSTT')"
                prop="watermark.1"
                width="132"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                :label="$t('CaptionSettings.Waterprint.WaterprintTRA')"
                prop="watermark.2"
                show-overflow-tooltip
                width="132"
              >
              </el-table-column>
              <el-table-column
                :label="$t('CaptionSettings.Waterprint.WaterprintREV')"
                prop="watermark.3"
                width="132"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                :label="$t('CaptionSettings.Waterprint.WaterprintSTQ')"
                prop="watermark.5"
                width="132"
                show-overflow-tooltip
              >
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div v-if="Glossariestabs == '3'" class="Glossariestabs4">
          <div
            class="tabs2 tableClass"
            :class="
              Glossariestabs3TableData.length == 0 ? 'tablebtmborder' : ''
            "
          >
            <el-table
              :row-style="{
                height: 40 + 'px',
                background: '#393c4e',
                color: '#cbd7e8',
              }"
              :cell-style="{ padding: 0 + 'px' }"
              :header-cell-style="{
                height: 32 + 'px',
                padding: '0px',
                color: '#adb0bc',
                background: '#454758',
              }"
              ref="Glossariestabs3TableData"
              :data="Glossariestabs3TableData"
              tooltip-effect="dark"
              style="width: 100%"
              max-height="320"
              border
            >
              <el-table-column
                :label="$t('ProjectManagement.Colour')"
                width="69"
                align="center"
              >
                <template slot-scope="scope">
                  <div>
                    <div
                      class="divCloor"
                      :style="{ background: scope.row.color }"
                    ></div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="name"
                :label="$t('ProjectManagement.CommentName')"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-input
                    class="inputClassdisable"
                    v-model="scope.row.comment_name"
                    v-show="!scope.row.disabled"
                    @input="handleInput(scope.row)"
                  ></el-input>
                  {{ scope.row.disabled ? scope.row.comment_name : "" }}
                </template>
              </el-table-column>

              <!-- 所用流程 -->
              <el-table-column
                show-overflow-tooltip
                :label="$t('ProjectManagement.Processused')"
              >
                <template slot-scope="scope">
                  <div class="checkboxClass">
                    <el-checkbox-group
                      v-model="scope.row.work_flow"
                      :disabled="scope.row.disabled"
                    >
                      <el-checkbox :label="1"
                        >{{ $t("ProjectManagement.STT") }}
                      </el-checkbox>
                      <el-checkbox :label="2"
                        >{{ $t("ProjectManagement.TRA") }}
                      </el-checkbox>
                      <el-checkbox :label="3"
                        >{{ $t("ProjectManagement.REV") }}
                      </el-checkbox>
                      <el-checkbox :label="4"
                        >{{ $t("ProjectManagement.Proof") }}
                      </el-checkbox>
                      <el-checkbox :label="5"
                        >{{ $t("CommonName.statusName12") }}
                      </el-checkbox>
                    </el-checkbox-group>
                  </div>
                </template>
              </el-table-column>

              <!-- 操作 -->
              <el-table-column
                label-class-name="labelClassName"
                width="88"
                align="center"
              >
                <template slot-scope="scope">
                  <div v-show="scope.row.other != 1">
                    <el-button
                      type="text"
                      @click="cancelClick(scope.$index, scope.row)"
                      v-if="scope.row.showbtn"
                    >
                      <div class="svgbtn">
                        <svg-icon icon-class="delete" class="icon"></svg-icon>
                      </div>
                    </el-button>
                    <!-- 确认 -->
                    <el-button
                      type="text"
                      @click="confirmClick(scope.row)"
                      v-if="scope.row.showEdit"
                    >
                      <div class="svgbtn">
                        <svg-icon icon-class="Edit" class="icon"></svg-icon>
                      </div>
                    </el-button>
                  </div>
                </template>
              </el-table-column>
              <!-- 从术语库添加其他术语 -->
            </el-table>
            <div class="tableappend">
              <el-button type="text" @click="addtableData">
                +{{ $t("ProjectManagement.AddNewComment") }}
              </el-button>
            </div>
          </div>
        </div>
        <div v-if="Glossariestabs == '4'" class="Glossariestabs5">
          <div class="Glossariestabs5_div">
            <p>{{ "Prompt" }}</p>
            <el-button
              type="text"
              class="Glossariestabs5_button"
              @click="promot_button_click"
            >
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('CaptionSettings.srtText6')"
                placement="top"
              >
                <i class="el-icon-info"></i>
              </el-tooltip>
            </el-button>
          </div>
          <div>
            <el-input
              type="textarea"
              v-model="Glossariestab5.textarea"
              resize="none"
            ></el-input>
          </div>
          <!--          <p style="margin-top: 0px">-->
          <!--            {{ "同时处理条数" }}-->
          <!--          </p>-->
          <!--          <div>-->
          <!--            <el-input-number-->
          <!--              v-model="Glossariestab5.input"-->
          <!--              controls-position="right"-->
          <!--              :min="0"-->
          <!--              :max="100"-->
          <!--            ></el-input-number>-->
          <!--          </div>-->
        </div>
        <div v-if="Glossariestabs == '5'" class="Glossariestabs6">
          <div>
            <p>{{ $t("CaptionSettings.srtText2") }} (%)</p>
            <el-input
              v-model="Glossariestab6.leftInput"
              @input="Glossariestab6handleInput(1)"
            ></el-input>
          </div>
          <div>
            <p>{{ $t("CaptionSettings.srtText3") }} (%)</p>
            <el-input
              v-model="Glossariestab6.rightInput"
              @input="Glossariestab6handleInput(2)"
            ></el-input>
          </div>
          <div>
            <p>{{ $t("CaptionSettings.srtText4") }} (%)</p>
            <el-input
              v-model="Glossariestab6.topInput"
              @input="Glossariestab6handleInput(3)"
            ></el-input>
          </div>
          <div>
            <p>{{ $t("CaptionSettings.srtText5") }} (%)</p>
            <el-input
              v-model="Glossariestab6.bottomInput"
              @input="Glossariestab6handleInput(4)"
            ></el-input>
          </div>
        </div>
        <div v-if="Glossariestabs == '6'" class="Glossariestabs7 switch_div">
          <!--          asr 设置-->
          <div class="switch_div_div">
            <p style="color: #ffffff; margin-bottom: 10px">
              <!--              开启/关闭ASR选项，开启后请在列表内选择需要的ASR语言-->
              {{ $t("CaptionSettings.asrText") }}
            </p>
            <el-switch
              v-model="Glossariestab7.switchValue"
              active-color="#46adff"
              inactive-color="#7d7f8b"
            >
            </el-switch>
          </div>
          <div class="select_div">
            <el-select
              v-model="Glossariestab7.selectValue"
              multiple
              :placeholder="$t('CommonName.Pleaseselect')"
              filterable
              :disabled="Glossariestab7.switchValue == 0"
            >
              <el-option
                v-for="item in selectList"
                :key="item.language_id"
                :label="item.cname"
                :value="item.language_id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div v-if="Glossariestabs == '7'" class="Glossariestabs3">
          <div class="Glossariestabs3_input">
            <!--            导出文件名前缀信息-->
            <p>{{ $t("CaptionSettings.srtText") }}</p>
            <el-input v-model="Glossariestab8.srt_before"></el-input>
            <!--            导出文件名后缀信息-->
            <p>{{ $t("CaptionSettings.srtText1") }}</p>
            <el-input v-model="Glossariestab8.srt_after"></el-input>
            <p>
              <el-checkbox v-model="Glossariestab8.single">{{
                $t("CaptionSettings.srtText11")
              }}</el-checkbox>
              <el-checkbox v-model="Glossariestab8.bilingual">{{
                $t("CaptionSettings.srtText12")
              }}</el-checkbox>
            </p>
            <!--            导出原文前缀信息-->
            <p>{{ $t("CaptionSettings.srtText7") }}</p>
            <el-input v-model="Glossariestab8.source_tag"></el-input>
            <!--            导出原文后缀信息-->
            <p>{{ $t("CaptionSettings.srtText8") }}</p>
            <el-input v-model="Glossariestab8.source_tag_end"></el-input>
            <!--            导出译文前缀信息-->
            <p>{{ $t("CaptionSettings.srtText9") }}</p>
            <el-input v-model="Glossariestab8.trans_tag"></el-input>
            <!--            导出译文后缀信息-->
            <p>{{ $t("CaptionSettings.srtText10") }}</p>
            <el-input v-model="Glossariestab8.trans_tag_end"></el-input>
          </div>
        </div>
      </div>
    </div>
    <div slot="footer" class="dialog-footer" style="margin-top: 24px">
      <div
        v-if="
          (+project_template_id > 0 && ['1', '7'].includes(Glossariestabs)) ||
          project_template_id == ''
        "
      >
        <el-button @click="closeDialog" type="info" class="setpadding23"
          ><p class="footerleftbuttonp">
            {{ $t("CommonName.Cancel") }}
          </p></el-button
        >
        <el-button
          type="primary"
          @click="determineClick(0)"
          class="setpadding23"
        >
          <p class="footerrightbuttonp2" style="">
            {{ $t("CommonName.Apply") }}
          </p>
        </el-button>
        <el-button
          type="primary"
          @click="determineClick(1)"
          class="setpadding23"
        >
          <p class="footerrightbuttonp2" style="">
            {{ $t("CommonName.SaveAndClose") }}
          </p>
        </el-button>
      </div>
    </div>
    <el-dialog
      :title="$t('ProjectManagement.saveToTemplate')"
      :visible.sync="dialogVisible1"
      :width="'480px'"
      :top="'15%'"
      center
      :close-on-click-modal="false"
      :append-to-body="true"
      @close="closeDialog1"
    >
      <div class="center">
        <div class="glo_bom">
          <div class="glo_bom_name">
            <p>{{ $t("ProjectManagement.projectTemplateName") }}</p>
            <el-input v-model="template_name"></el-input>
          </div>
          <div class="Glossariestabs2">
            <p>
              {{ $t("CaptionSettings.tab10") }}
            </p>
            <div>
              <el-input
                type="textarea"
                v-model="template_comments"
                resize="none"
                maxlength="500"
              ></el-input>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer" style="margin-top: 24px">
        <div>
          <el-button @click="closeDialog1" type="info" class="setpadding23"
            ><p class="footerleftbuttonp">
              {{ $t("CommonName.Cancel") }}
            </p></el-button
          >
          <el-button
            type="primary"
            @click="determineClick1()"
            :loading="buttonLoading"
            class="setpadding23"
          >
            <p class="footerrightbuttonp2" style="">
              {{ $t("CommonName.Sure") }}
            </p>
          </el-button>
        </div>
      </div>
    </el-dialog>
  </el-dialog>
</template>
<script>
import { get_language, customer_api_method } from "@/utils/https";
import { showLoading, hideLoading } from "@/utils/loading";
import { setColor, getColor, initColor, colorListLength } from "./color";

export default {
  props: {
    showdialog: {
      type: Boolean,
      default: false,
    },
    projectInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  created() {
    this.info = JSON.parse(sessionStorage.getItem("info"));
  },
  mounted() {
    this.dialogVisible = this.showdialog;
    this.project_id = this.$route.query.id;
    this.title = this.$t("ProjectDetails.buttonList.name6");
    showLoading(this.$t("CommonName.loadingmessage"));
    this.get_caption_info(this.project_id);
    this.project_template_id = this.projectInfo.project_template_id;
  },
  computed: {
    setGlossariestabsleft() {
      let a = "";
      let b = 0;
      if (this.Glossariestabs == "0") {
        b = document.getElementsByClassName("glo_top_p0");

        a = "tabsbtnicon1";
      } else if (this.Glossariestabs == "1") {
        b = document.getElementsByClassName("glo_top_p1");
        a = "tabsbtnicon2";
      } else {
        b = document.getElementsByClassName("glo_top_p2");
        a = "tabsbtnicon3";
      }
      // console.log(b);
      return a;
    },
  },
  data() {
    return {
      info: {},
      dialogVisible: false,
      project_id: "",
      title: "",
      Glossariestabs: "0",
      Glossariestab1: {}, //获取多媒体任务字幕规则信息
      Glossariestab2: {
        //获取多媒体任务禁止字符信息
        radio: "0",
        value: false,
        textarea: "",
      },
      Glossariestab5: {
        //  GTP 设置
        textarea: "",
        input: 0,
      },
      // 字幕区域
      Glossariestab6: {
        leftInput: 0,
        rightInput: 0,
        topInput: 0,
        bottomInput: 0,
      },
      // asr
      Glossariestab7: {
        switchValue: 0,
        selectValue: [],
      },
      Glossariestab8: {
        srt_before: "",
        srt_after: "",
        single: false,
        bilingual: true,
        source_tag: "",
        source_tag_end: "",
        trans_tag: "",
        trans_tag_end: "",
      },
      // asr 语言列表
      selectList: [],
      tableData: [], //获取多媒体任务字幕规则信息 表格数据
      tabs3DataList: [
        {
          id: "1",
          input1: "42",
          input2: "",
          selectList: ["0", "1"],
        },
        {
          id: "2",
          input1: "水印2",
          input2: "",
          selectList: ["0", "1"],
        },
      ],
      options: [
        {
          id: "1",
          name: "111",
        },
      ],
      MultipleChoiceoptions: [
        {
          value: "0",
          label: "Bleach_EP331_EN.mp4",
        },
        {
          value: "1",
          label: "Bleach_EP332_EN.mp4",
        },
      ],
      //Glossariestab3
      Glossariestab3: {
        WaterprintSTT: "",
        WaterprintTRA: "",
        WaterprintREV: "",
        WaterprintSTQ: "",
        // job_ids: [],
        tab3tableData: [],
      },
      multipleSelection: [],

      // Comment Settings 表格 数据
      Glossariestabs3TableData: [],
      colorIndex: 1,
      errorIndex: 1,
      dialogVisible1: false,
      template_name: "",
      project_template_id: "",
      template_comments: "",
      buttonLoading: false,
    };
  },
  methods: {
    closeDialog1() {
      this.dialogVisible1 = false;
    },
    saveToTemplate() {
      this.dialogVisible1 = true;
      this.template_name = "";
    },
    unbindTemplate() {
      this.$confirm(
        this.$t("CommonName.warningmessage7"),
        this.$t("CommonName.deleteMessageTitle"),
        {
          confirmButtonText: this.$t("CommonName.deleteMessagebutton"),
          cancelButtonText: this.$t("CommonName.deleteMessageClosebutton"),
          closeOnClickModal: false,
          cancelButtonClass: "closeButton",
          // confirmButtonClass: "confirmButton",
        }
      ).then(() => {
        showLoading(this.$t("CommonName.loadingmessage"));
        customer_api_method({
          action: "cancel_project_template",
          project_id: this.project_id,
        }).then((res) => {
          hideLoading();
          if (!res) {
            return;
          }
          this.project_template_id = "";
          if (this.Glossariestabs == "0") {
            this.get_caption_info(this.project_id);
          }
          this.$emit("clickCaptionSettings", this.dialogVisible, 1);
        });
      });
    },
    determineClick1() {
      if (this.template_name.trim() == "") {
        this.$messages(
          "warning",
          this.$t("ProjectManagement.warningmessage12")
        );
        return;
      }
      this.buttonLoading = true;
      customer_api_method({
        action: "set_project_to_template",
        template_name: this.template_name.trim(),
        comments: this.template_comments.trim(),
        project_id: this.project_id,
      }).then((res) => {
        this.buttonLoading = false;
        if (!res) {
          return;
        }
        this.project_template_id = res.project_template_id;
        this.dialogVisible1 = false;
        this.$emit("clickCaptionSettings", this.dialogVisible, 1);
        if (this.Glossariestabs == "0") {
          this.get_caption_info(this.project_id);
        }
      });
    },
    // ==================================接口调用方法==============================
    /**
     * 获取语言接口
     */
    get_language() {
      get_language({
        action: "get_language",
        lang:
          sessionStorage.getItem("lang") == "cn" ||
          sessionStorage.getItem("lang") == null
            ? 1
            : 2,
      }).then((res) => {
        if (res) {
          this.selectList = res.list;
          hideLoading();
        }
      });
    },
    /**
     *
     * @param {*获取多媒体任务字幕规则信息} id
     */
    get_caption_info(id) {
      customer_api_method({
        action: "get_caption_info",
        project_id: id,
      }).then((res) => {
        hideLoading();
        if (!res) {
          return;
        }
        // console.log(res.info);
        res.info.minigap_type == "0"
          ? (res.info.minigap_type = false)
          : (res.info.minigap_type = true);
        this.Glossariestab1 = res.info;
      });
    },
    /**
     *
     * @param {*修改多媒体任务字幕规则信息} id
     */
    set_project_caption_info(obj, mode) {
      customer_api_method({
        action: "set_caption_info",
        project_id: this.project_id,
        min_duration: obj.min_duration,
        max_duration: obj.max_duration,
        minigap_time: obj.minigap_time,
        line_count: obj.line_count,
        minigap_type: obj.minigap_type ? "1" : "0",
        langs: obj.langs,
      }).then((res) => {
        if (!res) {
          return;
        }
        if (mode == 1) {
          this.dialogVisible = false;
        }

        this.$emit("clickCaptionSettings", this.dialogVisible);
        hideLoading();
      });
    },
    /**
     *
     * @param {*获取多媒体任务禁止字符信息} id
     */
    get_shield_info(id) {
      customer_api_method({
        action: "get_shield_info",
        project_id: id,
      }).then((res) => {
        if (!res) {
          return;
        }

        this.Glossariestab2.radio = res.info.shield_mode;
        res.info.customize_mode == "0"
          ? (this.Glossariestab2.value = false)
          : (this.Glossariestab2.value = true);
        this.Glossariestab2.textarea = res.info.shield_str;
        hideLoading();
      });
    },
    /**
     *
     * @param {*修改多媒体任务禁止字符信息} obj
     */
    set_project_shield_info(obj, mode) {
      customer_api_method({
        action: "set_shield_info",
        project_id: this.project_id,
        shield_mode: obj.radio,
        customize_mode: obj.value ? 1 : 0,
        shield_str: obj.textarea,
      }).then((res) => {
        if (!res) {
          return;
        }
        if (mode == 1) {
          this.dialogVisible = false;
        }
        this.$emit("clickCaptionSettings", this.dialogVisible);
        hideLoading();
      });
    },
    /**
     *
     * @param {*获取任务水印信息} id
     */
    get_watermark_info(id) {
      return customer_api_method({
        action: "get_watermark_info",
        project_id: id,
        job_id: "",
      }).then((res) => {
        if (!res) {
          return;
        }
        // this.Glossariestab3 = {
        //   WaterprintSTT: "",
        //   WaterprintTRA: "",
        //   WaterprintREV: "",
        //   WaterprintSTQ: "",
        //   job_ids: [],
        //   tab3tableData: res.list,
        // };
        this.Glossariestab3.tab3tableData = res.list;
        // console.log(res.list);
        hideLoading();
      });
    },
    /**
     *
     * @param {* 更新/添加任务水印信息（批量）} id
     */
    update_watermark_batch(id, obj, mode) {
      // console.log(obj);
      customer_api_method({
        action: "update_watermark_batch",
        project_id: id,
        job_ids: obj.job_ids,
        watermark_stt: obj.WaterprintSTT,
        watermark_tra: obj.WaterprintTRA,
        watermark_rev: obj.WaterprintREV,
        watermark_stq: obj.WaterprintSTQ,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.get_watermark_info(id).then(() => {
          if (mode == 1) {
            this.dialogVisible = false;
          }
        });
      });
    },
    /**
     *
     * @param {*查看项目Comments备注信息} id
     */
    get_project_comments(id) {
      return customer_api_method({
        action: "get_project_comments",
        project_id: id,
      }).then((res) => {
        if (!res) {
          return;
        }
        initColor(); //重置 颜色
        if (res.list.length > 0) {
          res.list.forEach((i) => {
            i.showEdit = true;
            i.disabled = true;
            i.showbtn = true;
            getColor(i);
          });
        }
        // this.errorIndex = 1;
        this.colorIndex = 1;
        this.Glossariestabs3TableData = res.list;
        hideLoading();
      });
    },
    /**
     * 查看GPT设置信息
     * @param id
     */
    get_project_gpt_info(id) {
      customer_api_method({
        action: "get_project_gpt_info",
        project_id: id,
      })
        .then((res) => {
          if (res) {
            this.Glossariestab5.input = res.info.segment_count;
            this.Glossariestab5.textarea = res.info.promot;
          }
        })
        .catch((err) => {
          this.$messages("error", err);
        });
    },
    /**
     *
     * @param {*设置项目Comments错误信息} id
     */
    set_project_comments(id, mode) {
      customer_api_method({
        action: "set_project_comments",
        project_id: id,
        list: this.Glossariestabs3TableData,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.get_project_comments(id).then(() => {
          if (mode == 1) {
            this.dialogVisible = false;
          }
        });
      });
    },
    /**
     * 更新GPT设置信息
     * @param id
     */
    set_project_gpt_info(id, mode) {
      // console.log(this.Glossariestab5);
      customer_api_method({
        action: "set_project_gpt_info",
        project_id: id,
        promot: this.Glossariestab5.textarea,
        segment_count: this.Glossariestab5.input,
      })
        .then((res) => {
          hideLoading();
          if (!res) {
            return;
          }
          if (mode == 1) {
            this.dialogVisible = false;
          }
        })
        .catch((err) => {
          hideLoading();
          this.$messages("error", err);
        });
    },
    /**
     * 获取视频播放区域设置相关信息
     * @param id
     */
    get_project_area_setting(id) {
      customer_api_method({
        action: "get_project_area_setting",
        project_id: id,
      })
        .then((res) => {
          hideLoading();
          if (!res) {
            return;
          }
          this.Glossariestab6.leftInput = res.info.left_range;
          this.Glossariestab6.rightInput = res.info.right_range;
          this.Glossariestab6.topInput = res.info.front_range;
          this.Glossariestab6.bottomInput = res.info.rear_range;
        })
        .catch((err) => {
          hideLoading();
          this.$messages("error", err);
        });
    },
    /**
     * 获取项目SRT设置信息
     * @param id
     */
    get_project_srt_info(id) {
      customer_api_method({
        action: "get_project_srt_info",
        project_id: id,
      }).then((res) => {
        if (!res) {
          hideLoading();
          return;
        }
        this.Glossariestab8.srt_after = res.info.srt_after;
        this.Glossariestab8.srt_before = res.info.srt_before;
        this.Glossariestab8.single = res.info.single == 1 ? true : false;
        this.Glossariestab8.bilingual = res.info.bilingual == 1 ? true : false;
        this.Glossariestab8.source_tag = res.info.source_tag;
        this.Glossariestab8.source_tag_end = res.info.source_tag_end;
        this.Glossariestab8.trans_tag = res.info.trans_tag;
        this.Glossariestab8.trans_tag_end = res.info.trans_tag_end;
        hideLoading();
      });
    },
    /**
     * 修改项目SRT设置信息
     * @param id
     */
    set_project_srt_info(id, mode) {
      customer_api_method({
        action: "set_project_srt_info",
        project_id: id,
        srt_before: this.Glossariestab8.srt_before,
        srt_after: this.Glossariestab8.srt_after,
        single: this.Glossariestab8.single ? 1 : 0,
        bilingual: this.Glossariestab8.bilingual ? 1 : 0,
        source_tag: this.Glossariestab8.source_tag,
        source_tag_end: this.Glossariestab8.source_tag_end,
        trans_tag: this.Glossariestab8.trans_tag,
        trans_tag_end: this.Glossariestab8.trans_tag_end,
      })
        .then((res) => {
          if (!res) {
            hideLoading();
            return;
          }
          if (mode == 1) {
            this.dialogVisible = false;
          }
          hideLoading();
        })
        .catch((err) => {
          hideLoading();
          this.$messages("error", err);
        });
    },
    /**
     * 修改视频播放区域设置相关信息
     * @param id
     */
    update_project_area_setting(id, mode) {
      customer_api_method({
        action: "update_project_area_setting",
        project_id: id,
        left_range: this.Glossariestab6.leftInput,
        right_range: this.Glossariestab6.rightInput,
        front_range: this.Glossariestab6.topInput,
        rear_range: this.Glossariestab6.bottomInput,
      })
        .then((res) => {
          hideLoading();
          if (!res) {
            return;
          }
          if (mode == 1) {
            this.dialogVisible = false;
          }
        })
        .catch((err) => {
          hideLoading();
          this.$messages("error", err);
        });
    },

    // ==================================通用方法==============================
    /**
     * 关闭弹窗
     */
    closeDialog() {
      this.dialogVisible = false;
      this.$emit("clickCaptionSettings", this.dialogVisible);
    },
    /**
     *
     * @param {* 确定按钮} data
     * @param {* 0 不关闭； 1 关闭} mode
     */
    determineClick(mode) {
      if (this.Glossariestabs == "0") {
        if (
          !this.Glossariestab1.min_duration ||
          !this.Glossariestab1.max_duration ||
          !this.Glossariestab1.minigap_time ||
          !this.Glossariestab1.line_count
        ) {
          this.$messages(
            "warning",
            this.$t("TermBaseManagement.warningmessage3")
          );
          return;
        }
        for (let index = 0; index < this.Glossariestab1.langs.length; index++) {
          if (
            !this.Glossariestab1.langs[index].char_line ||
            !this.Glossariestab1.langs[index].char_speed
          ) {
            this.$messages(
              "warning",
              this.$t("TermBaseManagement.warningmessage3")
            );
            return;
          }
        }
        // projectInfo
        showLoading(this.$t("CommonName.loadingmessage"));
        this.set_project_caption_info(this.Glossariestab1, mode);
      }
      if (this.Glossariestabs == "1") {
        showLoading(this.$t("CommonName.loadingmessage"));
        this.set_project_shield_info(this.Glossariestab2, mode);
      }
      if (this.Glossariestabs == "3") {
        for (
          let index = 0;
          index < this.Glossariestabs3TableData.length;
          index++
        ) {
          if (!this.Glossariestabs3TableData[index].comment_name) {
            this.$messages(
              "warning",
              this.$t("TermBaseManagement.warningmessage3")
            );
            return;
          }
        }
        // 判断是否 重名
        const newArr = this.Glossariestabs3TableData.map(
          (item) => item.comment_name
        );
        const isRepeat = newArr.some(
          (item, index, arr) => arr.indexOf(item) != index
        );
        if (isRepeat) {
          this.$messages(
            "warning",
            this.$t("TermBaseManagement.warningmessage4")
          );
          return;
        }
        showLoading(this.$t("CommonName.loadingmessage"));
        this.set_project_comments(this.project_id, mode);
      }
      if (this.Glossariestabs == "2") {
        // if (this.multipleSelection.length < 1) {
        //   this.$messages(
        //     "warning",
        //     this.$t("ProjectManagement.warningmessage4")
        //   );
        //   return;
        // }
        // this.Glossariestab3.job_ids = [];
        // this.multipleSelection.forEach((i) => {
        //   this.Glossariestab3.job_ids.push(i.job_id);
        // });
        // console.log(this.Glossariestab3.job_ids);

        showLoading(this.$t("CommonName.loadingmessage"));
        this.update_watermark_batch(this.project_id, this.Glossariestab3, mode);
      }
      // if (this.Glossariestabs == "4") {
      //   showLoading(this.$t("CommonName.loadingmessage"));
      //   this.set_project_gpt_info(this.project_id, mode);
      // }
      // if (this.Glossariestabs == "5") {
      //   if (
      //     this.Glossariestab6.rightInput == "" ||
      //     this.Glossariestab6.bottomInput == "" ||
      //     this.Glossariestab6.topInput == "" ||
      //     this.Glossariestab6.leftInput == ""
      //   ) {
      //     this.$messages("warning", "存在未填项");
      //     return;
      //   }
      //   showLoading(this.$t("CommonName.loadingmessage"));
      //   this.update_project_area_setting(this.project_id, mode);
      // }
      // if (this.Glossariestabs == "6") {
      //   if (
      //     this.Glossariestab7.switchValue &&
      //     this.Glossariestab7.selectValue.length === 0
      //   ) {
      //     return;
      //   }

      //   showLoading(this.$t("CommonName.loadingmessage"));
      //   customer_api_method({
      //     action: "set_asr_lang",
      //     project_id: this.project_id,
      //     asr_lang_mode: !this.Glossariestab7.switchValue ? 0 : 1, //0 不开启；1：开启自定义asr语言对
      //     asr_lang: !this.Glossariestab7.switchValue
      //       ? "0"
      //       : this.Glossariestab7.selectValue.length === 0
      //       ? "0"
      //       : this.Glossariestab7.selectValue.join(","), //语言对id集合
      //   })
      //     .then((res) => {
      //       hideLoading();
      //       if (!res) {
      //         return;
      //       }
      //       this.$messages("success", "保存成功");
      //       if (mode == 1) {
      //         this.dialogVisible = false;
      //       }

      //       this.$emit("clickCaptionSettings", this.dialogVisible, 1);
      //     })
      //     .catch((err) => {
      //       hideLoading();
      //       this.$messages("error", err);
      //     });
      // }
      // if (this.Glossariestabs == "7") {
      //   showLoading(this.$t("CommonName.loadingmessage"));
      //   this.set_project_srt_info(this.project_id, mode);
      // }
    },
    // ==================================其他方法====================================

    changeGlossariestabs(str) {
      this.Glossariestabs = str;
      switch (str) {
        case "0":
          showLoading(this.$t("CommonName.loadingmessage"));
          this.get_caption_info(this.project_id);
          break;
        case "1":
          showLoading(this.$t("CommonName.loadingmessage"));
          this.get_shield_info(this.project_id);
          break;
        case "2":
          showLoading(this.$t("CommonName.loadingmessage"));
          this.get_watermark_info(this.project_id);
          break;
        case "3":
          showLoading(this.$t("CommonName.loadingmessage"));
          this.get_project_comments(this.project_id);
          break;
        case "4":
          showLoading(this.$t("CommonName.loadingmessage"));
          this.get_project_comments();
          this.get_project_gpt_info(this.project_id);

          break;
        case "5":
          showLoading(this.$t("CommonName.loadingmessage"));
          this.get_project_area_setting(this.project_id);

          break;
        case "6":
          // asr
          showLoading(this.$t("CommonName.loadingmessage"));
          this.get_language();
          this.Glossariestab7.switchValue =
            Number(this.projectInfo.asr_lang_mode) === 0 ? false : true;
          this.Glossariestab7.selectValue =
            this.projectInfo.asr_lang == 0
              ? []
              : this.projectInfo.asr_lang.split(",");
          break;
        case "7":
          showLoading(this.$t("CommonName.loadingmessage"));
          this.get_project_srt_info(this.project_id);
          break;
        default:
          break;
      }
    },
    /**
     *
     * @param {* 表格添加} data
     */
    addtableData() {
      this.Glossariestabs3TableData.push({
        comment_id: "",
        color: setColor(this.colorIndex),
        comment_name: "Error " + this.errorIndex,
        work_flow: [1, 2, 3, 4, 5],
        showbtn: true,
        showEdit: false,
        disabled: false,
      });
      if (this.colorIndex == colorListLength()) {
        initColor();
        this.colorIndex = 1;
      } else {
        this.colorIndex++;
      }
      this.errorIndex++;
      this.$nextTick(() => {
        this.$refs.Glossariestabs3TableData.bodyWrapper.scrollTop =
          this.$refs.Glossariestabs3TableData.bodyWrapper.scrollHeight;
      });
    },
    /**
     *
     * @param {取消按钮,移除按钮} row
     */
    cancelClick(index, i) {
      this.Glossariestabs3TableData.splice(index, 1);
    },
    /**
     *
     * @param {*去除首尾空格} row
     */
    handleInput(row) {
      // console.log(row);
      row.comment_name = row.comment_name.trim(); // 去除首尾空格
    },
    /**
     * promot 点击添加 到 promot 输入框中
     * @param data
     */
    promot_button_click() {
      this.Glossariestab5.textarea +=
        "我想请你作为字幕译员帮从**翻译到**。翻译后目标译文务必加上正确的标点符号，不要遗漏标点特别注意逗号后面首字母要小写（即便换行也要小写），不要漏翻句子";
    },
    /**
     *
     * @param val
     * @constructor
     */
    Glossariestab6handleInput(val) {
      // console.log(val);
      if (val == 1) {
        this.Glossariestab6.leftInput = this.Glossariestab6.leftInput.replace(
          /[^\d]/g,
          ""
        );
        if (parseInt(this.Glossariestab6.leftInput) > 100) {
          this.Glossariestab6.leftInput = 100;
        }
      } else if (val == 2) {
        this.Glossariestab6.rightInput = this.Glossariestab6.rightInput.replace(
          /[^\d]/g,
          ""
        );
        if (parseInt(this.Glossariestab6.rightInput) > 100) {
          this.Glossariestab6.rightInput = 100;
        }
      } else if (val == 3) {
        this.Glossariestab6.topInput = this.Glossariestab6.topInput.replace(
          /[^\d]/g,
          ""
        );
        if (parseInt(this.Glossariestab6.topInput) > 100) {
          this.Glossariestab6.topInput = 100;
        }
      } else {
        this.Glossariestab6.bottomInput =
          this.Glossariestab6.bottomInput.replace(/[^\d]/g, "");
        if (parseInt(this.Glossariestab6.bottomInput) > 100) {
          this.Glossariestab6.bottomInput = 100;
        }
      }
    },
    /**
     *
     * @param {*点击显示 可修改 名称} i
     */
    confirmClick(i) {
      i.disabled = false;
      i.showEdit = false;
    },
    /**
     * 多选
     */
    handleSelectionChange(val) {
      this.multipleSelection = val;
      // console.log(this.multipleSelection);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/dialogStyle.scss";

.center {
  margin: 16px 24px 24px;
  ::v-deep .el-checkbox {
    color: #ffffff;
    font-weight: normal;
  }
  .glo_top {
    //width: 840px;
    height: 32px;
    display: flex;
    line-height: 32px;
    background-color: #51546a;
    border-radius: 3px;
    color: #b3d3ec;
    font-size: 14px;

    p {
      padding: 0 15px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }

    .setbackground {
      background-color: #46adff;
      border-radius: 3px;
      font-weight: 700;
      color: #ffffff;
    }

    .tabsbtnicon {
      position: absolute;
      color: #46adff;
      z-index: 99;
      top: 27px;
    }
  }

  .textareaclass {
    ::v-deep .el-textarea__inner {
      margin-top: 6px;
      margin-bottom: 32px;
      //width: 846px !important;
      height: 138px !important;
      border: 1px solid !important;
      border-color: #7d7f8b !important;
      border-radius: 2px !important;
      padding: 11px 9px !important;
      overflow-y: auto !important;

      &:focus {
        background: none !important;
        color: #ffffff !important;
      }
    }
  }

  .glo_bom {
    .glo_bom_top {
      display: flex;

      ::v-deep .el-input__inner {
        width: 200px;
      }

      p {
        margin: 24px 0 6px 0;
        color: #adb0bc;
        font-size: 14px;
      }

      .glo_bom_top_second {
        color: #adb0bc;
        font-size: 14px;
        margin-left: 5px;
      }
    }

    .glo_bom_center {
      ::v-deep .el-input__inner {
        width: 200px;
      }

      p {
        margin: 16px 0 6px 0;
        color: #adb0bc;
        font-size: 14px;
      }

      .checkboxClass {
        margin-top: 0;

        .el-input {
          width: auto;
        }

        .el-checkbox.is-bordered {
          width: 200px;
          border-left: none;
        }

        ::v-deep .el-checkbox.is-bordered.is-checked {
          border-color: #7d7f8b !important;
        }

        // display: flex;
      }
    }

    .glo_bom_table {
      p {
        color: #adb0bc;
        font-size: 14px;
        margin: 16px 0 6px 0;
      }

      ::v-deep .el-input__inner {
        width: 258px;
      }

      ::v-deep .el-table__header colgroup col[name="gutter"] {
        display: table-cell !important;
      }

      ::v-deep .el-table__body {
        width: 100% !important;
      }

      ::v-deep .el-table {
        th.gutter,
        colgroup.gutter {
          background: #454758; //此处的宽度值，对应你自定义滚动条的宽度即可
        }
      }
    }
    .glo_bom_name {
      p {
        color: #adb0bc;
        font-size: 14px;
        margin: 16px 0 6px 0;
      }

      ::v-deep .el-input__inner {
        width: 100%;
      }
    }
    .Glossariestabs2 {
      p {
        font-size: 14px;
        color: #adb0bc;
        margin-top: 18px;
      }

      .radioClass {
        margin: 6px 0 27px 0;

        .el-radio.is-bordered {
          width: 180px;
        }
      }

      .Gloss_btm {
        display: flex;

        p {
          color: #ffffff;
          margin-top: 0;
          margin-left: 8px;
        }

        ::v-deep .el-switch__core {
          width: 48px;
          height: 24px;
        }

        ::v-deep .el-switch__core:after {
          width: 18px;
          height: 18px;
          top: 2px;
          background-color: #393c4e;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
        }
      }

      @extend .textareaclass;
    }

    .Glossariestabs3 {
      margin-top: 8px;

      .Glossariestabs3_input {
        ::v-deep .el-input__inner {
          width: 950px !important;
        }
      }

      p {
        margin: 16px 0 6px 0;
        color: #adb0bc;
        font-size: 14px;
      }

      .inputtop {
        display: flex;

        ::v-deep .el-input__inner {
          width: 400px;
        }

        ::v-deep .el-input__icon {
          line-height: 32px;
        }
      }

      .selectbtm {
        // ::v-deep .el-input__inner {
        //width: 780px;
        // }

        ::v-deep .el-input__icon {
          line-height: 32px;
        }

        ::v-deep .el-tag.el-tag--info {
          background-color: #5d5e6e;
          border: none;
          color: #ffffff;
        }

        ::v-deep .el-tag.el-tag--info .el-tag__close {
          color: #a9b5c9;
          font-size: 13px;
        }

        ::v-deep .el-select .el-tag__close.el-icon-close {
          background: none;
        }
      }

      .borderDiv {
        margin-top: 20px;
        width: 840px;
        height: 1px;
        background-image: linear-gradient(
          to right,
          #757784 0%,
          #757784 50%,
          transparent 50%
        );
        background-size: 18px 1px;
        background-repeat: repeat-x;
      }

      .el-button {
        padding: 0;
        margin-top: 20px;
      }
    }

    .Glossariestabs4 {
      .tabs2 {
        ::v-deep .el-input.is-disabled .el-input__inner {
          border: none !important;
          background: none !important;
        }

        .divCloor {
          width: 14px;
          height: 14px;
          border-radius: 100%;
          margin: 0 auto;
        }

        ::v-deep .el-input__inner {
          width: 100% !important;
        }

        .labelClassName {
          .el-button--text {
            padding: 0 !important;
          }
        }

        .tableappend {
          height: 40px;
          background-color: #393c4e;
          line-height: 40px;
          padding-left: 12px;
          border-left: 1px solid #515464;
          border-bottom: 1px solid #515464;
          border-right: 1px solid #515464;
        }

        ::v-deep .el-table {
          overflow: initial;
        }

        .checkboxClass .el-checkbox-group {
          width: 100%;
          justify-content: space-between;
        }

        .checkboxClass {
          margin-top: 0px;
        }

        ::v-deep .el-checkbox__label {
          line-height: 1;
        }
      }
    }

    .Glossariestabs5 {
      @extend .pclass;

      .Glossariestabs5_div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 18px;

        p {
          margin-top: 0;
        }
      }

      .Glossariestabs5_button {
        padding: 0 !important;

        i {
          font-size: 16px;
        }
      }

      ::v-deep .el-input-number {
        width: auto !important;

        .el-input__inner {
          text-align: left !important;
        }
      }

      ::v-deep .el-input-number.is-controls-right .el-input-number__increase {
        border-bottom: none !important;
      }

      ::v-deep .el-input-number__increase {
        border-left: none !important;
      }

      ::v-deep .el-input-number.is-controls-right .el-input-number__decrease {
        border-left: none !important;
      }

      ::v-deep .el-input-number__decrease,
      ::v-deep .el-input-number__increase {
        background: none !important;
        color: #adb0bc !important;
        width: auto !important;
        margin-right: 10px !important;
        font-size: 16px !important;
      }

      ::v-deep .el-input-number.is-controls-right .el-input-number__decrease,
      ::v-deep .el-input-number.is-controls-right .el-input-number__increase {
        line-height: 16px !important;
      }

      ::v-deep .el-input-number__increase {
        top: 4px !important;
      }

      ::v-deep .el-input-number__decrease {
        bottom: 4px !important;
      }

      @extend .textareaclass;
    }

    .Glossariestabs6 {
      display: flex;
      justify-content: space-around;
      @extend .pclass;

      ::v-deep .el-input__inner {
        margin-top: 6px;
        width: 180px !important;
      }
    }

    .Glossariestabs7 {
      .switch_div_div {
        margin: 24px 0 24px 0;
      }

      .select_div {
        width: 100%;

        .el-select {
          width: 100%;
        }

        ::v-deep .el-input__inner {
          width: 100% !important;
        }
      }
    }
  }
}

.switch_div {
  ::v-deep .el-switch__core {
    width: 48px;
    height: 24px;
  }

  ::v-deep .el-switch__core:after {
    width: 18px;
    height: 18px;
    top: 2px;
    background-color: #393c4e;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
  }
}

.pclass {
  p {
    font-size: 14px;
    color: #adb0bc;
    margin-top: 18px;
  }
}

.tableClass {
  margin-top: 21px;
}
.tasknameslotTitle {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title_p {
    width: 240px;
    text-align: left;
    font-size: 16px;
    font-weight: normal;
    color: rgb(255, 255, 255);
  }

  p {
    margin-left: 24px;
  }

  .tasktitlebtn {
    display: flex;
    align-items: center;
    margin-right: 40px;

    .svgbtn {
      margin-right: 14px;
    }
  }

  .lastdiv {
    width: 1px;
    height: 12px;
    background-color: #757784;
    margin: 0 24px 0 10px;
  }
}
</style>
