/*
 * @Description: 所有接口
 * @version:
 * @Author: Carful
 * @Date: 2022-10-19 14:49:45
 * @LastEditors: Carful
 * @LastEditTime: 2023-06-26 11:44:15
 */
import request from "@/utils/request";

export function get_language(data) {
  //调用接口函数名称
  return request({
    url: "/dict/gateway",
    method: "POST",
    data,
  });
}

// !----------------------------------- 以上通用语言 数据获取---------------------
//  *==============================================登陆 注册=======================================
/**
 *
 * @param {*注册并登陆} data"action": "register_login",
 "email": "807623460@qq.com", //电子邮箱，比如 example@qq.com
 "nickname": "Zane", //昵称
 "password": "b7aaf712e6fbb233c5217afd17677d9c" //密码,md5加密字符串
 * @returns
 */
export function register_login(data) {
  //调用接口函数名称
  return request({
    url: "/register/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {邮箱密码登陆} data {
"action": "login_mail",
"email": "807623460@qq.com", //电子邮箱，比如 example@qq.com
"password": "b7aaf712e6fbb233c5217afd17677d9c", //密码,md5加密字符串
"mode": 1 //类型，1：WiiMedia账号登录，2：Wiitrans账号登录
}
 * @returns
 */
export function login_mail(data) {
  //调用接口函数名称
  return request({
    url: "/login/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {发送注册/忘记密码邮件} data
 * {
"action": "send_mail",
"email": "zane@1.com", //电子邮箱，比如 example@qq.com
"scid": "121", //微信扫码标识id
"mode": 1 //类型，1注册邮件，2忘记密码
}
 * @returns
 */
export function send_mail(data) {
  //调用接口函数名称
  return request({
    url: "/register/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*检查昵称} data {
"action": "check_nickname",
"nickname": "zane"
}
 * @returns
 */
export function check_nickname(data) {
  //调用接口函数名称
  return request({
    url: "/register/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*修改登录密码} data{
"action": "change_password",
"password": "245528b37bcebf18344d69f491fd4d66", //新密码,md5加密字符串
"token": "c29c4627c620b1201823cd85d47ab9bf" //修改密码认证令牌
}
 * @returns
 */
export function change_password(data) {
  //调用接口函数名称
  return request({
    url: "/login/gateway",
    method: "POST",
    data,
  });
}

// !---------------------------------------------以上是登录部分接口-----------------------------------
// *===============================================个人中心部分===========================================
/**
 *
 * @param {*获取个人基本信息} data
 * @returns
 */
export function get_user_info(data) {
  //调用接口函数名称
  return request({
    url: "/user/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*发送手机验证码} data
 * "action": "send_phone_code",
 "phone": "15640199670"
 * @returns
 */
export function send_phone_code(data) {
  //调用接口函数名称
  return request({
    url: "/user/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*绑定手机} data"action": "update_phone",
 "phone": "15640199670",
 "code": "9527"
 * @returns
 */
export function update_phone(data) {
  //调用接口函数名称
  return request({
    url: "/user/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*注销账号} data "action": "remove_user"
 * @returns
 */
export function remove_user(data) {
  //调用接口函数名称
  return request({
    url: "/user/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*修改密码} data "action": "update_password",
 "opwd": "b7aaf712e6fbb233c5217afd17677d9c",
 "npwd": "25d55ad283aa400af464c76d713c07ad"
 * @returns
 */
export function update_password(data) {
  //调用接口函数名称
  return request({
    url: "/user/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*退出登录} data
 * @returns
 */
export function logout_user(data) {
  //调用接口函数名称
  return request({
    url: "/user/gateway",
    method: "POST",
    data,
  });
}

/**
 * "action": "change_phone_mail_code"
 * @param {更换手机-发送邮箱验证码} data
 * @returns
 */
export function change_phone_mail_code(data) {
  //调用接口函数名称
  return request({
    url: "/user/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*更换手机-校验邮箱验证码} data
 * "action": "check_phone_mail_code",

 "code": 5634
 * @returns
 */
export function check_phone_mail_code(data) {
  //调用接口函数名称
  return request({
    url: "/user/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*领取月度免费礼包} data
 * @returns
 */
export function get_free_quate(data) {
  //调用接口函数名称
  return request({
    url: "/user/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*获取个人资源包信息} data
 * @returns
 */
export function get_user_quota_info(data) {
  //调用接口函数名称
  return request({
    url: "/user/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*获取我加入的团队信息} data
 * @returns
 */
export function get_myteam(data) {
  //调用接口函数名称
  return request({
    url: "/user/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*获取购买记录信息} data
 * @returns
 */
export function get_pay_list(data) {
  //调用接口函数名称
  return request({
    url: "/user/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*购买/续费资源包} data
 * @returns
 */
export function pay_package_code(data) {
  //调用接口函数名称
  return request({
    url: "/user/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*获取支付结果} data
 * @returns
 */
export function get_pay_result(data) {
  //调用接口函数名称
  return request({
    url: "/user/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {购买/续费企业VIP} data
 * @returns
 */
export function pay_vip_code(data) {
  //调用接口函数名称
  return request({
    url: "/user/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {意见反馈} data
 * @returns
 */
export function set_feedback(data) {
  //调用接口函数名称
  return request({
    url: "/user/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {立即联络} data
 * @returns
 */
export function submit_contact(data) {
  //调用接口函数名称
  return request({
    url: "/register/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {兑换礼包码} data
 * @returns
 */
export function get_package_code(data) {
  //调用接口函数名称
  return request({
    url: "/user/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*获取个人基本信息根据showid} data
 * @returns
 */
export function get_user_info_member(data) {
  //调用接口函数名称
  return request({
    url: "/user/gateway",
    method: "POST",
    data,
  });
}

// !---------------------------------------------以上是个人中心部分接口----------------------------------------
/**
 *
 * @param {*获取最近任务文件信息} data "action": "get_last_job"
 * @returns
 */
export function get_last_job(data) {
  //调用接口函数名称
  return request({
    url: "/workbench/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*获取最近转写中任务文件状态} data "action": "get_last_job_status"
 * @returns
 */
export function get_last_job_status(data) {
  //调用接口函数名称
  return request({
    url: "/workbench/gateway",
    method: "POST",
    data,
  });
}

// !-------------------------------------------------以上是获取最近任务文件信息接口==================================

// *----------------------------------------------------我的文件----------------------------------------------
/**
 *
 * @param {*我的文件接口} data"action": "get_job_list",
 "name": "zane的测试项目02",
 "start_time": "",
 "end_time": "",
 "sort_type": "desc",
 "sort_name": "create_time"
 * @returns
 */
export function get_job_list(data) {
  //调用接口函数名称
  return request({
    url: "/my/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*新建文件夹} data{
"action": "create_folder",

"name": "zane的测试项目02"

}
 * @returns
 */
export function create_folder(data) {
  //调用接口函数名称
  return request({
    url: "/my/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*删除文件夹接口} data
 "action": "delete_folder",

 "name": "zane的测试项目02"
 * @returns
 */
export function delete_folder(data) {
  //调用接口函数名称
  return request({
    url: "/my/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {删除文件任务} data
 *
 "action": "delete_private_job",

 "job_id": 6
 * @returns
 */
export function delete_private_job(data) {
  //调用接口函数名称
  return request({
    url: "/my/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {移动文件至文件夹} data
 "action": "move_job_folder",
 "job_id": 6,
 "folder_id": 1
 * @returns
 */
export function move_job_folder(data) {
  //调用接口函数名称
  return request({
    url: "/my/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*获取文件详细信息} data
 * "action": "get_job_info",
 "job_id": 4,
 "job_type": 1,
 "private": 1
 * @returns
 */
export function get_job_info(data) {
  //调用接口函数名称
  return request({
    url: "/my/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {批量删除文件夹与文件} data
 *
 "action": "delete_private_batch",

 "job_id": [1,2]
 "folder_id": [1,2]
 * @returns
 */
export function delete_private_batch(data) {
  //调用接口函数名称
  return request({
    url: "/my/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*文件夹重命名} data{
"action": "rename_folder",
 "folder_id": 1
}
 * @returns
 */
export function rename_folder(data) {
  //调用接口函数名称
  return request({
    url: "/my/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*文件重命名} data{
"action": "rename_job",
 "job_id": 1
}
 * @returns
 */
export function rename_job(data) {
  //调用接口函数名称
  return request({
    url: "/my/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {获取批量下载文件} data
 *
 "action": "get_download_job",

 "job_id": [1,2]
 * @returns
 */
export function get_download_job(data) {
  //调用接口函数名称
  return request({
    url: "/my/gateway",
    method: "POST",
    data,
  });
}

//!===================================================媒体项目操作页============================================================
/**
 *
 * @param {获取多媒体任务基础信息} data
 * @returns
 */
export function get_private_job_info(data) {
  //调用接口函数名称
  return request({
    url: "/job/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {获取多媒体任务基础信息} data
 * @returns
 */
export function auto_mt(data) {
  //调用接口函数名称
  return request({
    url: "/job/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {* 修改多媒体任务字幕规则信息} data
 * @returns
 */
export function set_caption_info(data) {
  //调用接口函数名称
  return request({
    url: "/job/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {修改多媒体任务禁止字符信息} data
 * @returns
 */
export function set_shield_info(data) {
  //调用接口函数名称
  return request({
    url: "/job/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*获取多媒体任务句段信息} data
 * @returns
 */
export function get_private_job_data_info(data) {
  //调用接口函数名称
  return request({
    url: "/job/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*修改多媒体任务名称} data
 * @returns
 */
export function set_job_name(data) {
  //调用接口函数名称
  return request({
    url: "/job/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {更新句段内容-原文} data
 * @returns
 */
export function set_source_content(data) {
  //调用接口函数名称
  return request({
    url: "/job/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*更新句段内容-译文} data
 * @returns
 */
export function set_trans_content(data) {
  //调用接口函数名称
  return request({
    url: "/job/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*句段操作-拆分句段} data
 * @returns
 */
export function split_segment(data) {
  //调用接口函数名称
  return request({
    url: "/job/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*句段操作-合并句段} data
 * @returns
 */
export function merge_segment(data) {
  //调用接口函数名称
  return request({
    url: "/job/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*句段操作-删除句段} data
 * @returns
 */
export function delete_segment(data) {
  //调用接口函数名称
  return request({
    url: "/job/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {更新句段时间信息} data
 * @returns
 */
export function set_time_code(data) {
  //调用接口函数名称
  return request({
    url: "/job/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {获取句段ASR转写内容} data
 * @returns
 */
export function get_asr_content(data) {
  //调用接口函数名称
  return request({
    url: "/job/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*句段操作-新增句段获取信息} data
 * @returns
 */
export function get_new_segment(data) {
  //调用接口函数名称
  return request({
    url: "/job/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*单句内容翻译} data
 * @returns
 */
export function get_mt(data) {
  //调用接口函数名称
  return request({
    url: "/job/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*下载字幕} data
 * @returns
 */
export function download_srt_content(data) {
  //调用接口函数名称
  return request({
    url: "/job/download_srt_content",
    method: "POST",
    data,
    responseType: "blob",
  });
}

/**
 *
 * @param {*错误检查} data
 * @returns
 */
export function check_subtitle_error(data) {
  //调用接口函数名称
  return request({
    url: "/job/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*获取视频配音基础信息} data
 * @returns
 */
export function get_private_dubbing_info(data) {
  //调用接口函数名称
  return request({
    url: "/job/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*获取视频配音句段信息} data
 * @returns
 */
export function get_private_dubbing_data_info(data) {
  //调用接口函数名称
  return request({
    url: "/job/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*试听} data
 * @returns
 */
export function get_private_audittion_info(data) {
  //调用接口函数名称
  return request({
    url: "/job/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*确认配音} data
 * @returns
 */
export function confirm_private_dubbing(data) {
  //调用接口函数名称
  return request({
    url: "/job/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*修改句段文本内容} data
 * @returns
 */
export function set_dubbing_source_content(data) {
  //调用接口函数名称
  return request({
    url: "/job/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*修改句段时间} data
 * @returns
 */
export function set_dubbing_time_code(data) {
  //调用接口函数名称
  return request({
    url: "/job/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*更新主播设置} data
 * @returns
 */
export function update_private_dubbing_voice(data) {
  //调用接口函数名称
  return request({
    url: "/job/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*更新配音音频设置} data
 * @returns
 */
export function update_private_dubbing_result(data) {
  //调用接口函数名称
  return request({
    url: "/job/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*单句配音} data
 * @returns
 */
export function video_single_dubbing(data) {
  //调用接口函数名称
  return request({
    url: "/job/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*删除句段} data
 * @returns
 */
export function delete_dubbing_segment(data) {
  //调用接口函数名称
  return request({
    url: "/job/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*单句语速调整} data
 * @returns
 */
export function video_single_change_speed(data) {
  //调用接口函数名称
  return request({
    url: "/job/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*批量语速调整} data
 * @returns
 */
export function video_change_speed(data) {
  //调用接口函数名称
  return request({
    url: "/job/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*字幕时间对齐} data
 * @returns
 */
export function video_time_alignment(data) {
  //调用接口函数名称
  return request({
    url: "/job/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*智能并轨} data
 * @returns
 */
export function video_time_alignment1(data) {
  //调用接口函数名称
  return request({
    url: "/job/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*获取配音任务单次支付信息} data
 * @returns
 */
export function get_video_pay_info(data) {
  //调用接口函数名称
  return request({
    url: "/job/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*更新是否查看提示信息} data
 * @returns
 */
export function set_job_instructions(data) {
  //调用接口函数名称
  return request({
    url: "/job/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*句段操作-合并句段} data
 * @returns
 */
export function merge_dubbing_segment(data) {
  //调用接口函数名称
  return request({
    url: "/job/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*句段操作-拆分句段} data
 * @returns
 */
export function split_dubbing_segment(data) {
  //调用接口函数名称
  return request({
    url: "/job/gateway",
    method: "POST",
    data,
  });
}

/**
 * 创建 视频转写任务
 * @param data
 * @returns {*}
 */
export function create_asr_job(data) {
  //调用接口函数名称
  return request({
    url: "/job/gateway",
    method: "POST",
    data,
  });
}

/**
 * 创建 视频翻译，字幕翻译
 * @param data
 * @returns {*}
 */
export function create_trans_job(data) {
  //调用接口函数名称
  return request({
    url: "/job/gateway",
    method: "POST",
    data,
  });
}

/**
 * 个人端 批量保存
 * @param data
 * @returns {*}
 * @private
 */
export function set_tag_batch_person(data) {
  return request({
    url: "/job/gateway",
    method: "POST",
    data,
  });
}

/**
 * 硬字幕识别
 * @param data
 * @returns {*}
 */
export function create_ocr_job(data) {
  return request({
    url: "/job/gateway",
    method: "POST",
    data,
  });
}

/**
 * 获取上次硬字幕识别任务坐标信息
 * @param data
 * @returns {*}
 */
export function get_ocr_last_job(data) {
  return request({
    url: "/job/gateway",
    method: "POST",
    data,
  });
}

/**
 * 创建硬字幕识别任务-YouTube
 * @param data
 * @returns {*}
 */
export function create_tube_ocr_job(data) {
  return request({
    url: "/job/gateway",
    method: "POST",
    data,
  });
}

/**
 *个人 j 快捷键
 * @param data
 * @returns {*}
 */
export function get_new_sentence_key1(data) {
  return request({
    url: "/job/gateway",
    method: "POST",
    data,
  });
}

/**
 * 创建字幕对齐任务
 * @param data
 * @returns {*}
 */
export function create_sub_align_job(data) {
  return request({
    url: "/job/gateway",
    method: "POST",
    data,
  });
}

/**
 * 个人 平移时间轴
 * @param data
 * @returns {*}
 */
export function adjust_time_code1(data) {
  return request({
    url: "/job/gateway",
    method: "POST",
    data,
  });
}

export function undo_method1(data) {
  return request({
    url: "/job/gateway",
    method: "POST",
    data,
  });
}

/**
 * 检测-YT视频是否可正常解析
 * @param data
 * @returns {*}
 */
export function check_tube_video_info(data) {
  return request({
    url: "/job/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*视频配音方法} data
 * @returns
 */
export function video_dubbing_api(data) {
  //调用接口函数名称
  return request({
    url: "/videodubbing/gateway",
    method: "POST",
    data,
  });
}

/**
 * 视频配音创建任务
 * @param data
 * @returns {*}
 */
export function create_video_dubbing_job(data) {
  //调用接口函数名称
  return request({
    url: "/videodubbing/gateway",
    method: "POST",
    data,
  });
}

/**
 * 获取视频配音可选列表
 * @param data
 * @returns {*}
 */
export function get_video_dubbing_job_list(data) {
  //调用接口函数名称
  return request({
    url: "/videodubbing/gateway",
    method: "POST",
    data,
  });
}

export function create_video_dubbing_job1(data) {
  //调用接口函数名称
  return request({
    url: "/videodubbing/gateway",
    method: "POST",
    data,
  });
}

/**
 * 获取srt文件内容最大时长
 * @param data
 * @returns {*}
 */
export function check_srt_max_duration(data) {
  //调用接口函数名称
  return request({
    url: "/videodubbing/gateway",
    method: "POST",
    data,
  });
}

/**
 * create_video_dubbing_job_tube
 * @param data
 * @returns {*}
 */
export function create_video_dubbing_job_tube(data) {
  //调用接口函数名称
  return request({
    url: "/videodubbing/gateway",
    method: "POST",
    data,
  });
}

/**
 * 创建视频配音任务-无台词
 * @param data
 * @returns {*}
 */
export function create_video_dubbing_job_new(data) {
  //调用接口函数名称
  return request({
    url: "/videodubbing/gateway",
    method: "POST",
    data,
  });
}

// ------------------------------------------------以上是首页创建任务借口-------------------------------------------

// !==================================================以下是团队任务接口----------------------------------------------------
// *--------------------------------------------------项目管理------------------------------------------------------------
/**
 *
 * @param {*获取项目列表信息} data"action": "get_project_list"
 * @returns
 */
export function get_project_list(data) {
  //调用接口函数名称
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*克隆任务} data
 * @returns
 */
export function get_job_clone_info(data) {
  //调用接口函数名称
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*创建项目} data"action": "create_project",
 "project_name": "测试项目02",
 "type_mode": [1,2,3,4], //1是否允许时间轴交叉，2是否允许校对流程修改原文及时间轴，3MT，4是否允许翻译流程修改原文及时间轴
 "dig": 3,
 "comments": "备注02"
 * @returns
 */
export function create_project(data) {
  //调用接口函数名称
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*获取任务列表信息} data
 * @returns
 */
export function get_team_job_list(data) {
  //调用接口函数名称
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*获取项目详情信息} data
 * @returns
 */
export function get_project_info(data) {
  //调用接口函数名称
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*关闭项目} data
 * @returns
 */
export function close_project(data) {
  //调用接口函数名称
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*导入任务} data
 * @returns
 */
export function import_job_method(data) {
  //调用接口函数名称
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*获取可以导入的项目列表信息} data
 * @returns
 */
export function get_import_project_list(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*获取项目下可以导入的任务列表信息} data
 * @returns
 */
export function get_import_project_job_list(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*删除任务} data
 * @returns
 */
export function delete_job(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*关闭任务} data
 * @returns
 */
export function set_job_close(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*创建多媒体任务} data
 * @returns
 */
export function create_job_file(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*获取OSS节点列表信息} data
 * @returns
 */
export function get_oss_list(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*获取任务详情} data
 * @returns
 */
export function get_project_job_info(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*指定任务执行人（单个）} data
 * @returns
 */
export function set_job_user(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*更新/添加任务水印信息（单个）} data
 * @returns
 */
export function update_watermark_info(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {替换字幕文件} data
 * @returns
 */
export function upload_source_srt_file(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {替换OSS模式} data
 * @returns
 */
export function change_oss_mode(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*下载字幕文件(集合版)} data
 * @returns
 */
export function download_str_file(data) {
  return request({
    url: "/videojob/download_subtitle_file",
    method: "GET",
    params: data,
  });
}

/**
 *
 * @param {*打回任务至上一节点} data
 * @returns
 */
export function reject_job(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*更新项目信息} data
 * @returns
 */
export function update_project_info(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*获取项目已添加术语列表信息} data
 * @returns
 */
export function get_project_termfile_list(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*获取项目可添加术语列表信息} data
 * @returns
 */
export function get_usable_termfile_list(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*添加术语表} data
 * @returns
 */
export function add_termfile(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {* 移除术语表} data
 * @returns
 */
export function remove_termfile(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*获取任务执行人信息} data
 * @returns
 */
export function get_job_member(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {* 指定任务执行人（批量）} data
 * @returns
 */
export function set_job_user_batch(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*获取多媒体任务字幕规则信息} data
 * @returns
 */
export function get_caption_info(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*修改多媒体任务字幕规则信息} data
 * @returns
 */
export function set_project_caption_info(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*获取多媒体任务禁止字符信息} data
 * @returns
 */
export function get_shield_info(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*修改多媒体任务禁止字符信息} data
 * @returns
 */
export function set_project_shield_info(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*获取任务水印信息} data
 * @returns
 */
export function get_watermark_info(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*导出任务内容（Excel）} data
 * @returns
 */
export function export_excel_task_content(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
    responseType: "blob",
  });
}

/**
 *
 * @param {*查看项目Comments备注信息} data
 * @returns
 */
export function get_project_comments(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {* 设置项目Comments错误信息} data
 * @returns
 */
export function set_project_comments(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*更新/添加任务水印信息（批量）} data
 * @returns
 */
export function update_watermark_batch(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*更新任务名称} data
 * @returns
 */
export function update_job_name(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {获取任务TM选择信息} data
 * @returns
 */
export function get_job_tm_list(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {更新任务TM选择信息} data
 * @returns
 */
export function update_job_tm_list(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {获取可以修改处理人的任务} data
 * @returns
 */
export function get_processor_project_job_list(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {批量修改任务处理人} data
 * @returns
 */
export function set_processor_project_job(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 * 查看GPT设置信息
 * @param data
 * @returns {*}
 */
export function get_project_gpt_info(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 * 更新GPT设置信息
 * @param data
 * @returns {*}
 */
export function set_project_gpt_info(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 * 执行GPT翻译
 * @param data
 * @returns {*}
 */
export function run_gpt_jobs(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 * get_project_area_setting
 * @param data
 * @returns {*}
 */
export function get_project_area_setting(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 * 修改视频播放区域设置相关信息
 * @param data
 * @returns {*}
 */
export function update_project_area_setting(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 * 修改多媒体任务asr语言信息
 * @param data
 * @returns {*}
 */
export function set_asr_lang(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 * 获取项目模板列表
 * @param data
 * @returns {*}
 */
export function get_template_project_list(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {获取任务操作日志} data
 * @returns
 */
export function get_job_view_log(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {根据任务操作日志还原数据} data
 * @returns
 */
export function job_view_log_revert(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*更新任务截止日期（单个）} data
 * @returns
 */
export function update_deadline_info(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*共享系列接口} data
 * @returns
 */
export function get_project_share(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*重新生成波形} data
 * @returns
 */
export function rebuild_generate_waveform(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {获取TM列表} data
 * @returns
 */
export function get_pro_tm_list(data) {
  //调用接口函数名称
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*项目文件夹接口} data
 * @returns
 */
export function project_folder_api(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 * 创建多媒体识别任务 团队
 * @param data
 * @returns {*}
 */
export function create_job_file_ocr(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 * 执行MT翻译
 * @param data
 * @returns {*}
 */
export function run_mt_jobs(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 * 获取拆分任务详情
 * @param data
 * @returns {*}
 */
export function get_split_job_info(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 * 获取拆分结果
 * @param data
 * @returns {*}
 */
export function get_split_job(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 * 保存拆分结果
 * @param data
 * @returns {*}
 */
export function update_split_job(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 * 查看单个拆分任务详情
 * @param data
 * @returns {*}
 */
export function get_job_split_info(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 * 指定拆分任务人员
 * @param data
 * @returns {*}
 */
export function set_job_split_user(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 * 拆分单行
 * @param data
 * @returns {*}
 */
export function split_job(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 * 合并单行-向下
 * @param data
 * @returns {*}
 */
export function merge_job(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 * 创建多媒体项目
 * @param data
 * @returns {*}
 */
export function create_media_project(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 * 获取配音任务列表
 * @param data
 * @returns {*}
 */
export function get_dubbing_job_list(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 * 删除任务
 * @param data
 * @returns {*}
 */
export function delete_dubbing_job(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 * 任务重命名
 * @param data
 * @returns {*}
 */
export function update_dubbing_job_name(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 * 获取任务详情
 * @param data
 * @returns {*}
 */
export function get_dubbing_job_info(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 * 修改水印
 * @param data
 * @returns {*}
 */
export function update_dubbing_watermark_info(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 * 指定任务执行人（单个）
 * @param data
 * @returns {*}
 */
export function set_job_dubbing_user(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 * 指定任务执行人（Batch Assign）
 * @param data
 * @returns {*}
 */
export function set_processor_project_job_dubbing(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 * 获取更换指派人的任务列表（Batch Assign
 * @param data
 * @returns {*}
 */
export function get_processor_project_job_dubbing_list(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 * 打回拆分任务
 * @param data
 * @returns {*}
 */
export function reject_split_job(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 * 更新多媒体项目
 * @param data
 * @returns {*}
 */
export function update_media_project(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 * 获取项目SRT设置信息
 * @param data
 * @returns {*}
 */
export function get_project_srt_info(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

/**
 * 修改项目SRT设置信息
 * @param data
 * @returns {*}
 */
export function set_project_srt_info(data) {
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}

// *-------------------------------------------成员管理---------------------------------------------------
/**
 *
 * @param {{team_user_status: number, action: string}} data  "action": "get_member_list"
 * @returns
 */
export function get_member_list(data) {
  //调用接口函数名称
  return request({
    url: "/member/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*获取成员任务信息} data"action": "get_member_job",

 "member_id": 1
 * @returns
 */
export function get_member_job(data) {
  //调用接口函数名称
  return request({
    url: "/member/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*邀请成员} data
 * "action": "invitation_member",
 "user_key": "B030E989E0E6706F8CDDE48DFD282D92"
 * @returns
 */
export function invitation_member(data) {
  //调用接口函数名称
  return request({
    url: "/member/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {查看邀请记录 } data
 * @returns
 */
export function get_invitation_log(data) {
  //调用接口函数名称
  return request({
    url: "/member/gateway",
    method: "POST",
    data,
  });
}

/**
 * 获取团队成员-个人信息
 * @param data
 * @returns {*}
 */
export function get_member_user_info(data) {
  //调用接口函数名称
  return request({
    url: "/member/gateway",
    method: "POST",
    data,
  });
}

/**
 * 获取团队成员-个人任务信息
 * @param data
 * @returns {*}
 */
export function get_member_user_job_list(data) {
  //调用接口函数名称
  return request({
    url: "/member/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*接受/拒绝成员邀请（非登录）} data
 * @returns
 */
export function update_invitation_member(data) {
  //调用接口函数名称
  return request({
    url: "/member/update_invitation_member",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*获取邀请信息} data
 * @returns
 */
export function get_invitation_info(data) {
  //调用接口函数名称
  return request({
    url: "/member/get_invitation_info",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {接受/拒绝成员邀请（登录） } data
 * @returns
 */
export function update_invitation_member_method(data) {
  //调用接口函数名称
  return request({
    url: "/member/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*终止成员合作} data
 * @returns
 */
export function stop_member(data) {
  //调用接口函数名称
  return request({
    url: "/member/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*导出任务记录数据}"action": "export_member_data",

 "member_id": 1
 * @returns
 */
export function export_member_data(data) {
  //调用接口函数名称
  return request({
    url: "/member/gateway",
    method: "POST",
    data,
    responseType: "blob",
  });
}

// *------------------------------------------------TM 管理-------------------------------------------
/**
 *
 * @param {创建TM} data
 * @returns
 */
export function create_tm(data) {
  //调用接口函数名称
  return request({
    url: "/tm/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {获取TM列表} data
 * @returns
 */
export function get_tm_list(data) {
  //调用接口函数名称
  return request({
    url: "/tm/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {查看TM详细信息} data
 * @returns
 */
export function get_tm_info(data) {
  //调用接口函数名称
  return request({
    url: "/tm/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*删除TM} data
 * @returns
 */
export function delete_tm(data) {
  //调用接口函数名称
  return request({
    url: "/tm/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*更新TM信息} data
 * @returns
 */
export function update_tm_info(data) {
  //调用接口函数名称
  return request({
    url: "/tm/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*获取TM内容列表} data
 * @returns
 */
export function get_tm_content(data) {
  //调用接口函数名称
  return request({
    url: "/tm/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*导出TM} data
 * @returns
 */
export function export_tm(data) {
  //调用接口函数名称
  return request({
    url: "/tm/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*获取可合并TM列表} data
 * @returns
 */
export function get_merge_list(data) {
  //调用接口函数名称
  return request({
    url: "/tm/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*合并TM} data
 * @returns
 */
export function merge_tm(data) {
  //调用接口函数名称
  return request({
    url: "/tm/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*下载TM} data
 * @returns
 */
export function download_tm(data) {
  //调用接口函数名称
  return request({
    url: "/tm/gateway",
    method: "POST",
    data,
  });
}

//*------------------------------------------术语库管理-----------------------------------------------
/**
 *
 * @param {获取术语表列表} data
 * @returns
 */
export function get_termfile_list(data) {
  //调用接口函数名称
  return request({
    url: "/term/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*更新术语表信息} data
 * @returns
 */
export function update_termfile_info(data) {
  //调用接口函数名称
  return request({
    url: "/term/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*删除术语表} data
 * @returns
 */
export function delete_termfile(data) {
  //调用接口函数名称
  return request({
    url: "/term/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*获取术语表基础信息} data
 * @returns
 */
export function get_term_info(data) {
  //调用接口函数名称
  return request({
    url: "/term/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {获取术语表条目详情} data
 * @returns
 */
export function get_terms_list(data) {
  //调用接口函数名称
  return request({
    url: "/term/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*添加术语（单句）} data
 * @returns
 */
export function add_term(data) {
  //调用接口函数名称
  return request({
    url: "/term/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*编辑术语（单句）} data
 * @returns
 */
export function update_term(data) {
  //调用接口函数名称
  return request({
    url: "/term/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*删除单条术语} data
 * @returns
 */
export function delete_term(data) {
  //调用接口函数名称
  return request({
    url: "/term/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*查看术语表操作记录} data
 * @returns
 */
export function get_termchange_log(data) {
  //调用接口函数名称
  return request({
    url: "/term/gateway",
    method: "POST",
    data,
  });
}
/**
 *
 * @param {*添加术语库} data
 * @returns
 */
export function add_term_set(data) {
  //调用接口函数名称
  return request({
    url: "/term/gateway",
    method: "POST",
    data,
  });
}
/**
 *
 * @param {*团队任务-获取多媒体任务句段信息-翻译报告} data
 * @returns
 */
export function get_job_transreport_data_info(data) {
  //调用接口函数名称
  return request({
    url: "/videofile/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param data WiiTube-获取标题及描述内容信息
 * @returns {*}
 */
export function get_tube_localization_info(data) {
  //调用接口函数名称
  return request({
    url: "/videofile/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param data  WiiTube-保存标题内容及描述信息
 * @returns {* }
 */
export function set_tube_localization_info(data) {
  //调用接口函数名称
  return request({
    url: "/videofile/gateway",
    method: "POST",
    data,
  });
}

// *------------------------------------------------微信登录-------------------------------------------
/**
 *
 * @param {微信登录请求二维码} data {
"action": "wechat_login",
}
 * @returns
 */
export function wechat_login(data) {
  //调用接口函数名称
  return request({
    url: "/login/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {微信登录扫描二维码之后查询是否关注} data {
"action": "check_qrcode_status",
}
 * @returns
 */
export function check_qrcode_status(data) {
  //调用接口函数名称
  return request({
    url: "/login/gateway",
    method: "POST",
    data,
  });
}

// *------------------------------------------------获取消息列表-------------------------------------------
/**
 *
 * @param {获取消息列表} data {
"action": "get_message_list",
}
 * @returns
 */
export function get_message_list(data) {
  //调用接口函数名称
  return request({
    url: "/message/gateway",
    method: "POST",
    data,
  });
}

// *------------------------------------------------获取服务明细列表-------------------------------------------
/**
 *
 * @param {获取服务明细列表} data {
"action": "get_user_quota_log",
}
 * @returns
 */
export function get_user_quota_log(data) {
  //调用接口函数名称
  return request({
    url: "/user/gateway",
    method: "POST",
    data,
  });
}

// *================================================== 团队 媒体操作页 接口=========================================
/**
 *
 * @param {*团队任务-获取多媒体任务基础信息} data
 * @returns
 */
export function get_media_job_info(data) {
  //调用接口函数名称
  return request({
    url: "/videofile/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*团队任务-获取多媒体任务句段信息} data
 * @returns
 */
export function get_job_data_info(data) {
  //调用接口函数名称
  return request({
    url: "/videofile/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {* 团队任务-更新句段时间信息} data
 * @returns
 */
export function set_team_time_code(data) {
  return request({
    url: "/videofile/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {* 团队任务-更新句段内容-原文} data
 * @returns
 */
export function set_team_source_content(data) {
  return request({
    url: "/videofile/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {* 团队任务-更新句段内容-译文} data
 * @returns
 */
export function set_team_trans_content(data) {
  return request({
    url: "/videofile/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {* 团队任务-更新Comments错误信息} data
 * @returns
 */
export function update_segment_comments(data) {
  return request({
    url: "/videofile/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {* 团队任务-获取句段推荐TM & TB信息} data
 * @returns
 */
export function get_tm_result(data) {
  return request({
    url: "/videofile/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {* 团队任务-拆分句段} data
 * @returns
 */
export function team_split_segment(data) {
  return request({
    url: "/videofile/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*团队任务-合并句段} data
 * @returns
 */
export function team_merge_segment(data) {
  return request({
    url: "/videofile/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*团队任务-删除句段} data
 * @returns
 */
export function team_delete_segment(data) {
  return request({
    url: "/videofile/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*团队任务-新增句段} data
 * @returns
 */
export function get_team_new_segment(data) {
  return request({
    url: "/videofile/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*团队任务-单句转写} data
 * @returns
 */
export function get_team_asr_content(data) {
  return request({
    url: "/videofile/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*团队任务-获取错误检查结果（原文）} data
 * @returns
 */
export function check_source_subtitle_error(data) {
  return request({
    url: "/videofile/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*团队任务-获取错误检查结果（原文+译文）} data
 * @returns
 */
export function check_all_subtitle_error(data) {
  return request({
    url: "/videofile/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*团队任务-查看句段Comments错误信息} data
 * @returns
 */
export function get_segment_comments(data) {
  return request({
    url: "/videofile/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*团队任务-查询任务Comments错误信息} data
 * @returns
 */
export function get_job_comments(data) {
  return request({
    url: "/videofile/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*团队任务-删除句段comments} data
 * @returns
 */
export function delete_segment_comments(data) {
  return request({
    url: "/videofile/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*团队任务-批量添加SRT文件内容} data
 * @returns
 */
export function update_combine_content(data) {
  return request({
    url: "/videofile/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*团队任务-获取MT结果} data
 * @returns
 */
export function team_get_mt(data) {
  return request({
    url: "/videofile/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*团队任务-提交任务文件} data
 * @returns
 */
export function submit_job(data) {
  return request({
    url: "/videofile/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*团队任务-撤销译文} data
 * @returns
 */
export function revoke_segment(data) {
  return request({
    url: "/videofile/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*团队任务-MiniGap} data
 * @returns
 */
export function check_minigap_time(data) {
  return request({
    url: "/videofile/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*团队任务-撤销上一次操作} data
 * @returns
 */
export function undo_method(data) {
  return request({
    url: "/videofile/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*团队任务-平移时间轴} data
 * @returns
 */
export function adjust_time_code(data) {
  return request({
    url: "/videofile/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*团队任务-查询TM & TB信息} data
 * @returns
 */
export function search_tm_result(data) {
  return request({
    url: "/videofile/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*快捷键添加新句段（j）} data
 * @returns
 */
export function get_new_sentence_key(data) {
  return request({
    url: "/videofile/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*团队任务-校审设置评分错误类型} data
 * @returns
 */
export function set_error_type(data) {
  return request({
    url: "/videofile/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*团队任务-批量插入TM内容} data
 * @returns
 */
export function set_batch_tm(data) {
  return request({
    url: "/videofile/gateway",
    method: "POST",
    data,
  });
}

/**
 * 批量设置Tags标签信息
 * @param data
 * @returns {*}
 */
export function set_tag_batch(data) {
  return request({
    url: "/videofile/gateway",
    method: "POST",
    data,
  });
}

/**
 * 批量设置句段性别
 * @param data
 * @returns {*}
 */
export function set_segments_role_batch(data) {
  return request({
    url: "/videofile/gateway",
    method: "POST",
    data,
  });
}

/**
 * （分割srt文件）
 * @param data
 * @returns {*}
 */
export function upload_split_srt(data) {
  return request({
    url: "/other/gateway",
    method: "POST",
    data,
  });
}

/**
 * 合并srt文件
 * @param data
 * @returns {*}
 */
export function merge_srt(data) {
  return request({
    url: "/other/gateway",
    method: "POST",
    data,
  });
}

// ======================万事通==============================
/**
 * 获取聊天窗口列表信息
 * @param data
 * @returns {*}
 */
export function get_chat_room_list(data) {
  return request({
    url: "chat/gateway",
    method: "POST",
    data,
  });
}

/**
 * 获取聊天窗口-记录信息
 * @param data
 * @returns {*}
 */
export function get_chat_message_list(data) {
  return request({
    url: "chat/gateway",
    method: "POST",
    data,
  });
}

/**
 * 发送聊天窗口内容
 * @param data
 * @returns {*}
 */
export function send_chat_message(data) {
  return request({
    url: "chat/gateway",
    method: "POST",
    data,
  });
}

/**
 * 删除聊天窗口
 * @param data
 * @returns {*}
 */
export function chat_room_delete(data) {
  return request({
    url: "chat/gateway",
    method: "POST",
    data,
  });
}

/**
 * 编辑聊天窗口Title
 * @param data
 * @returns {*}
 */
export function set_chat_room_title(data) {
  return request({
    url: "chat/gateway",
    method: "POST",
    data,
  });
}

/**
 * 新增万事通模板
 * @param data
 * @returns {*}
 */
export function add_template(data) {
  return request({
    url: "chat/gateway",
    method: "POST",
    data,
  });
}

/**
 * 编辑更新万事通模板
 * @param data
 * @returns {*}
 */
export function set_template(data) {
  return request({
    url: "chat/gateway",
    method: "POST",
    data,
  });
}

/**
 * 删除万事通模板
 * @param data
 * @returns {*}
 */
export function delete_template(data) {
  return request({
    url: "chat/gateway",
    method: "POST",
    data,
  });
}

/**
 * 获取万事通模板列表
 * @param data
 * @returns {*}
 */
export function get_template_list(data) {
  return request({
    url: "chat/gateway",
    method: "POST",
    data,
  });
}

/**
 * 获取万事通模板详情
 * @param data
 * @returns {*}
 */
export function get_template_info(data) {
  return request({
    url: "chat/gateway",
    method: "POST",
    data,
  });
}

/**
 * 团队任务-获取润色内容
 * @param data
 * @returns {*}
 */
export function get_polish_info(data) {
  return request({
    url: "videofile/gateway",
    method: "POST",
    data,
  });
}

/**
 * 团队任务-批量获取错误检查结果（译文）
 * @param data
 * @returns {*}
 */
export function check_all_subtitle_error_batch(data) {
  return request({
    url: "videofile/gateway",
    method: "POST",
    data,
  });
}

/**
 * 团队任务-批量获取错误检查结果（原文）
 * @param data
 * @returns {*}
 */
export function check_source_subtitle_error_batch(data) {
  return request({
    url: "videofile/gateway",
    method: "POST",
    data,
  });
}

/**
 * 团队任务-批量查询任务Comments错误信息
 * @param data
 * @returns {*}
 */
export function get_job_comments_batch(data) {
  return request({
    url: "videofile/gateway",
    method: "POST",
    data,
  });
}

/**
 * 团队任务-获取翻译或校审进度
 * @param data
 * @returns {*}
 */
export function get_job_progress(data) {
  return request({
    url: "videofile/gateway",
    method: "POST",
    data,
  });
}

/**
 * 团队任务-获取多媒体任务基础信息（批量
 * @param data
 * @returns {*}
 */
export function get_job_info_batch(data) {
  return request({
    url: "videofile/gateway",
    method: "POST",
    data,
  });
}

/**
 * 团队任务-获取多媒体任务句段信息（批量）
 * @param data
 * @returns {*}
 */
export function get_job_data_info_batch(data) {
  return request({
    url: "videofile/gateway",
    method: "POST",
    data,
  });
}

/**
 * 团队任务-获取翻译或校审进度（批量）
 * @param data
 * @returns {*}
 */
export function get_job_progress_batch(data) {
  return request({
    url: "videofile/gateway",
    method: "POST",
    data,
  });
}

/**
 *
 * @param {*视频配音方法} data
 * @returns
 */
export function video_team_dubbing_api(data) {
  //调用接口函数名称
  return request({
    url: "/videoteamdubbing/gateway",
    method: "POST",
    data,
  });
}

/**
 * 创建视频配音任务-有台词
 * @param data
 * @returns {*}
 */
export function create_video_dubbing_job_team(data) {
  //调用接口函数名称
  return request({
    url: "/videoteamdubbing/gateway",
    method: "POST",
    data,
  });
}

/**
 * 创建视频配音任务-无台词
 * @param data
 * @returns {*}
 */
export function create_video_dubbing_job_new_team(data) {
  //调用接口函数名称
  return request({
    url: "/videoteamdubbing/gateway",
    method: "POST",
    data,
  });
}

/**
 * 创建视频配音任务-YouTube
 * @param data
 * @returns {*}
 */
export function create_video_dubbing_job_tube_team(data) {
  //调用接口函数名称
  return request({
    url: "/videoteamdubbing/gateway",
    method: "POST",
    data,
  });
}

/**
 * 定制主播-获取任务
 * @param data
 * @returns {*}
 */
export function get_clone_customized_job_list(data) {
  //调用接口函数名称
  return request({
    url: "/videoteamdubbing/gateway",
    method: "POST",
    data,
  });
}

/**
 * 定制主播-获取任务下的角色
 * @param data
 * @returns {*}
 */
export function get_clone_customized_job_role_list(data) {
  //调用接口函数名称
  return request({
    url: "/videoteamdubbing/gateway",
    method: "POST",
    data,
  });
}

/**
 * 定制角色
 * @param data
 * @returns {*}
 */
export function clone_customized_anchor(data) {
  //调用接口函数名称
  return request({
    url: "/videoteamdubbing/gateway",
    method: "POST",
    data,
  });
}

/**
 * 获取项目角色
 * @param data
 * @returns {*}
 */
export function get_project_roles(data) {
  //调用接口函数名称
  return request({
    url: "/videoteamdubbing/gateway",
    method: "POST",
    data,
  });
}

/**
 * 团队任务-提交任务文件（批量）
 * @param data
 * @returns {*}
 */
export function submit_job_batch(data) {
  //调用接口函数名称
  return request({
    url: "/videofile/gateway",
    method: "POST",
    data,
  });
}

/**
 *  在线ocr
 * @param data
 * @returns {*}
 */
export function get_ocr_result(data) {
  //调用接口函数名称
  return request({
    url: "/videofile/gateway",
    method: "POST",
    data,
  });
}
/**
 *
 * @param {*术语表格查询} data
 * @returns
 */
export function get_term_sheet_info(data) {
  return request({
    url: "/term/download_subtitle_file",
    method: "GET",
    params: data,
  });
}

/**
 * other 通用方法
 * @param data
 * @returns {*}
 */
export function other_api_method(data) {
  return request({
    url: "/other/gateway",
    method: "POST",
    data,
  });
}
/**
 * 团队任务-检测任务流程密码是否正确
 * @param data
 * @returns {*}
 */
export function check_job_code(data) {
  //调用接口函数名称
  return request({
    url: "/videofile/gateway",
    method: "POST",
    data,
  });
}
/**
 *团队任务- 批量导出项目
 * @returns
 */
export function export_project_jobs_info(data) {
  //调用接口函数名称
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}
/**
 * 团队任务-设置字体样式显示信息
 * @param data
 * @returns {*}
 */
export function set_font_style(data) {
  //调用接口函数名称
  return request({
    url: "/videofile/gateway",
    method: "POST",
    data,
  });
}
/**
 *团队任务- 快捷添加术语
 * @returns
 */
export function add_term_category(data) {
  //调用接口函数名称
  return request({
    url: "/videojob/gateway",
    method: "POST",
    data,
  });
}
/**
 *个人任务- 获取OSS临时密钥
 * @returns
 */
export function get_oss_policy(data) {
  //调用接口函数名称
  return request({
    url: "/user/gateway",
    method: "POST",
    data,
  });
}
/**
 * 团队端方法 -对外
 * @param {*} data
 * @returns
 */
export function customer_api_method(data) {
  //调用接口函数名称
  return request({
    url: "/videojobcustomer/gateway",
    method: "POST",
    data,
  });
}
