<!--
 * @Description: 主播单体
 * @version: 
 * @Author: Tom
 * @Date: 2022-10-27 14:25:20
 * @LastEditors: Carful
 * @LastEditTime: 2023-03-21 10:42:20
-->
<template>
  <div
    class="tts-model-anchor"
    :id="anchorSelected ? 'anchor_active' : ''"
    :class="anchorSelected ? 'active' : ''"
    @click="anchorClick()"
  >
    <div class="tts-model-anchor-head">
      <img :src="anchorSrc" />
    </div>
    <div class="tts-model-anchor-text">
      <div class="tts-model-anchor-text-1">
        <span>{{ anchorName }}</span>
        <svg-icon
          :iconClass="
            anchorGender == '2' || anchorGender == '3' ? 'female' : 'male'
          "
          className="svg-gender"
        ></svg-icon>
      </div>
      <div class="tts-model-anchor-text-2">
        <span v-for="(item, index) in ageTitles" :key="index">{{
          $t("VoiceStyle." + item)
        }}</span>
      </div>
      <div class="tts-model-anchor-text-3">
        <span v-for="(item, index) in moodTitles" :key="index">{{
          $t("VoiceStyle." + item)
        }}</span>
      </div>
    </div>
    <el-tooltip
      :content="$t('VideoDubbingClone.dubText25')"
      placement="top"
      effect="dark"
      :visible-arrow="false"
    >
      <svg-icon
        style="font-size: 19px"
        :iconClass="auditionStatus ? 'Audition_Stop' : 'Audition'"
        className="Audition"
        @click="auditionClick($event)"
      ></svg-icon>
    </el-tooltip>
    <!-- <div class="tts-model-anchor-flag flag-left" v-if="isFree === '1'">
      {{ $t("dubbing.currentFree") }}
    </div> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "anchor-com",
  props: {
    anchorId: {
      type: String,
      default: "",
    },
    anchorName: {
      type: String,
      default: "",
    },
    anchorDescriptionAge: {
      type: String,
      default: "",
    },
    anchorDescriptionMood: {
      type: String,
      default: "",
    },
    anchorGender: {
      type: String,
      default: "1",
    },
    anchorUrl: {
      type: String,
      default: require("@/assets/imgs/Portrait_Man.png"),
    },
    isFree: {
      type: String,
      default: "0",
    },
    anchorIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      //角色头像集合1：男声； 2：女声；3：童声（女）；4：童声（男）
      //src：常态图片地址；hover:鼠标滑动图片地址； play：播放时图片地址
      anchorSrcs: {
        1: {
          src: require("@/assets/imgs/Portrait_Man.png"),
          hover: require("@/assets/imgs/Portrait_ManPlay.png"),
          play: require("@/assets/imgs/Portrait_ManPause.png"),
        },
        2: {
          src: require("@/assets/imgs/Portrait_Woman.png"),
          hover: require("@/assets/imgs/Portrait_WomanPlay.png"),
          play: require("@/assets/imgs/Portrait_WomanPause.png"),
        },
        3: {
          src: require("@/assets/imgs/Portrait_child.png"),
          hover: require("@/assets/imgs/Portrait_childPlay.png"),
          play: require("@/assets/imgs/Portrait_childPause.png"),
        },
        4: {
          src: require("@/assets/imgs/Portrait_child2.png"),
          hover: require("@/assets/imgs/Portrait_child2.png"),
          play: require("@/assets/imgs/Portrait_child2.png"),
        },
      },
      //角色状态 0：常态；1：hover；2：播放;3:暂停
      anchorStatus: 0,
      collectStatus: false,
      anchorSelected: false,
      auditionStatus: false,
    };
  },
  computed: {
    anchorSrc() {
      let src;
      if (this.anchorStatus === 0) {
        src = this.anchorSrcs[this.anchorGender]["src"];
      } else if (this.anchorStatus === 1) {
        src = this.anchorSrcs[this.anchorGender]["hover"];
      } else if (this.anchorStatus === 2) {
        src = this.anchorSrcs[this.anchorGender]["play"];
      } else if (this.anchorStatus === 3) {
        src = this.anchorSrcs[this.anchorGender]["hover"];
      }
      return src;
    },
    ageTitles() {
      let array =
        this.anchorDescriptionAge !== ""
          ? this.anchorDescriptionAge.split(",")
          : [];
      return array;
    },
    moodTitles() {
      let array =
        this.anchorDescriptionMood !== ""
          ? this.anchorDescriptionMood.split(",")
          : [];
      return array;
    },
    ...mapState({
      selectedAIAnchorId: (state) => state.VideoDubbingClone.selectedAIAnchorId,
      playAIAnchorId: (state) => state.VideoDubbingClone.playAIAnchorId,
    }),
  },
  watch: {
    selectedAIAnchorId(value) {
      console.log("value", value);
      if (this.anchorId === value) {
        this.anchorSelected = true;
      } else {
        this.anchorSelected = false;
      }
    },
    playAIAnchorId(value) {
      console.log(value);
      if (this.anchorId === value) {
        this.auditionStatus = true;
      } else {
        this.auditionStatus = false;
      }
    },
  },
  methods: {
    anchorMouseover() {
      if (this.anchorStatus === 0) {
        this.anchorStatus = 1;
      }
    },
    anchorMouseout() {
      if (this.anchorStatus === 1) {
        this.anchorStatus = 0;
      }
    },
    anchorMouseClick() {
      if (this.anchorStatus === 2) {
        this.anchorStatus = 3;
      } else {
        this.anchorStatus = 2;
      }
      this.$store.dispatch("updatePlayAnchorId", this.anchorId);
    },
    anchorClick() {
      this.$emit("anchor-click", [this.anchorId, this.anchorIndex]);
      this.$store.dispatch(
        "VideoDubbingClone/updateSelectedAIAnchorId",
        this.anchorId
      );
    },
    auditionClick(e) {
      e.stopPropagation();
      this.$emit("anchor-audition", [
        this.anchorId,
        this.anchorIndex,
        !this.auditionStatus,
      ]);
    },
  },
  mounted() {
    if (this.anchorId === this.selectedAIAnchorId) {
      this.anchorSelected = true;
    } else {
      this.anchorSelected = false;
    }
  },
};
</script>
<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.ts-model-main-right-anchors-tab-pane .tts-model-anchor {
  margin: 0 10px 8px;
}
.tts-model-anchor {
  width: 296px;
  height: 90px;
  border: 1px solid;
  border-color: #7d7f8b;
  border-radius: 4px;
  padding: 14px 8px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.tts-model-anchor:hover {
  background-color: #212334;
}
.tts-model-anchor.active {
  border-color: #46adff;
}
.tts-model-anchor-head {
  width: 60px;
  height: 60px;
  float: left;
}
.tts-model-anchor-text {
  color: #fff;
  float: left;
  margin-left: 8px;
  text-align: left;
  width: 210px;
}
.tts-model-anchor-text-1 {
  font-size: 12px;
  margin-bottom: 4px;
}
.tts-model-anchor-text-2 span {
  background-image: linear-gradient(89.71deg, #47c9f9 0%, #1270ce 100%);
  border-radius: 10px;
  font-size: 10px;
  padding: 2px 5px;
  margin-right: 3px;
}
.tts-model-anchor-text-3 {
  margin-top: 4px;
  span {
    background-color: #51546a;
    border-radius: 10px;
    font-size: 10px;
    padding: 2px 5px;
    margin-right: 3px;
  }
}
.tts-model-anchor-head img {
  width: 100%;
}
.tts-model-anchor .collect-svg {
  position: absolute;
  right: 5px;
  top: 5px;
  color: #51546a;
}
.tts-model-anchor .Audition {
  position: absolute;
  right: 5px;
  top: 5px;
  color: #fff;
}
.tts-model-anchor .Audition:hover {
  color: #2196f3;
}
.tts-model-anchor .collect-svg:hover {
  color: #fff784;
}
.tts-model-anchor .collect-svg.active {
  color: #fff784;
}
.svg-gender {
  margin-left: 5px;
}
.tts-model-anchor-flag {
  position: absolute;
  height: 27px;
  line-height: 34px;
  text-align: center;
  width: 70px;
  background-color: #da9813;
  color: #fff;
  font-size: 10px;
}
.tts-model-anchor .flag-left {
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: -25px;
  top: -5px;
}
</style>
