<!--
 * @Description:  点击视频查看详情
 * @version: 
 * @Author: Carful
 * @Date: 2022-10-26 15:15:28
 * @LastEditors: Carful
 * @LastEditTime: 2023-07-07 10:37:39
-->
<template>
  <div class="contentdiv" v-if="dataInfo.base_info">
    <div class="titlediv">
      <div>
        <div v-show="showtitleflag" class="title_div">
          <p :class="title.length > 60 ? 'title_p overflowEllipsis' : ''">
            {{ title }}
          </p>
          <svg-icon
            icon-class="Edit"
            class="svgicon"
            @click="titleClick"
          ></svg-icon>
          <!-- <svg-icon
              iconClass="Edit"
              className="edit"
              @click="jobNameClick"
            ></svg-icon> -->
        </div>
        <div v-show="!showtitleflag" class="title_div">
          <el-input
            v-model="title"
            @blur="titleinputBlur"
            :maxlength="60"
            ref="Blurinput"
            @input="textareaBlues = true"
            @focus="textareaBlues = true"
          ></el-input>
        </div>
      </div>
      <div class="title_right_div">
        <div v-if="!dataInfo.base_info.show" @click="autoMt">
          <!--          全局AI翻译-->
          <el-button type="text">{{ $t("VideoDetails.buttonName") }}</el-button>
        </div>
        <div>
          <!--          禁用字符-->
          <el-button type="text" @click="Prohibitcharactersetting">
            {{ $t("OverwriteOperation.Forbiddencharacter") }}
          </el-button>
        </div>
        <div>
          <!--          字幕规则-->
          <el-button type="text" @click="SubtitlerulesDialog">
            {{ $t("VideoDetails.buttonName2") }}
          </el-button>
        </div>
        <!-- 下载功能 -->
        <el-dropdown @command="handleDownloadCommand">
          <el-button
            id="step-10"
            type="primary"
            class="setpadding23"
            style="margin-left: 10px"
          >
            {{ $t("VideoDubbing.download")
            }}<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="1"
              >{{ $t("PersonalEndLanguage.download1") }}
            </el-dropdown-item>
            <el-dropdown-item
              command="2"
              v-if="+this.dataInfo.base_info.tlang_id > 0"
              >{{ $t("PersonalEndLanguage.download2") }}
            </el-dropdown-item>
            <el-dropdown-item
              command="3"
              v-if="+this.dataInfo.base_info.tlang_id > 0"
              >{{ $t("PersonalEndLanguage.download3") }}
            </el-dropdown-item>
            <el-dropdown-item
              command="4"
              v-if="
                this.dataInfo.base_info.subtitle_suppression != '0' &&
                this.dataInfo.base_info.private_job_type < 4
              "
            >
              <el-tooltip
                :content="
                  $t('VideoDubbing.tooltipContent17') +
                  video_daub_minute_limit +
                  $t('VideoDubbing.tooltipContent29')
                "
                placement="left"
                effect="dark"
                :visible-arrow="false"
                :enterable="false"
              >
                <div>
                  {{ $t("VideoDubbingClone.dubText83") }}
                </div>
              </el-tooltip>
            </el-dropdown-item>
            <el-dropdown-item
              command="5"
              v-if="this.dataInfo.base_info.subtitle_smearing != '0'"
            >
              <div>
                {{ $t("PersonalEndLanguage.downloadText") }}
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="content">
      <div class="content_content">
        <div class="content_btm">
          <div class="content_left">
            <div class="content_left_top" id="leftCol">
              <div
                class="left_video_div"
                id="left_video_div"
                @click="videoplayclick"
              >
                <!-- 视频展示 -->
                <video
                  class="left_video"
                  id="video1"
                  ref="video1"
                  :src="videoUrl"
                  type="video/mp4"
                  oncontextmenu="return false"
                ></video>
                <canvas
                  id="canvas"
                  :width="1"
                  :height="videoDom.clientHeight"
                  v-if="videoDom"
                >
                </canvas>
                <div v-show="rect" class="rect_div">
                  <el-button
                    type="info"
                    class="setpadding10"
                    @click="closeCanvas"
                    ><i class="el-icon-close"></i
                  ></el-button>
                  <el-button
                    type="primary"
                    class="setpadding10"
                    @click="cutPicture"
                  >
                    <i class="el-icon-check"></i
                  ></el-button>
                </div>
                <div
                  class="left_video_div_div"
                  id="left_video_div_div"
                  v-drag
                  @click="(e) => e.stopPropagation()"
                >
                  <div
                    class="left_video_div_div_pdiv"
                    :class="this.fullscreen ? '' : 'fullscreendivp'"
                  >
                    <p
                      :class="
                        dataInfo.base_info.tlang_id == '20'
                          ? 'setFontstyles'
                          : dataInfo.base_info.tlang_id == '2'
                          ? 'fontfamilyClass'
                          : ''
                      "
                      v-if="
                        !dataInfo.base_info.show &&
                        ['1', '2'].includes(subtitleradio) &&
                        captionsObj.sentence_trans
                      "
                    >
                      {{ captionsObj.sentence_trans }}
                    </p>
                    <p
                      :class="
                        dataInfo.base_info.slang_id == '20'
                          ? 'setFontstyles'
                          : dataInfo.base_info.slang_id == '2'
                          ? 'fontfamilyClass'
                          : ''
                      "
                      v-show="
                        ['0', '2'].includes(subtitleradio) &&
                        captionsObj.sentence_source
                      "
                    >
                      {{ captionsObj.sentence_source }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="sliderClass">
                <el-slider
                  v-model="slidervalue"
                  :show-tooltip="true"
                  :step="step"
                  @change="sliderChange"
                  @input="sliderInput"
                  :max="silderMax"
                  :format-tooltip="formatTooltip"
                  @mousedown.native="openVideo"
                ></el-slider>
              </div>
              <div class="left_videobom_div">
                <div class="left">
                  <!-- 全屏 -->

                  <div @click="FullScreen" class="btndiv">
                    <el-tooltip
                      :content="$t('TeamOperation.FullScreen')"
                      placement="top"
                      effect="dark"
                      :visible-arrow="false"
                      :enterable="false"
                    >
                      <svg-icon
                        icon-class="Fullscreen"
                        v-if="fullscreen"
                        class="svgiconclass"
                      ></svg-icon>
                      <!-- ! todo -->
                      <svg-icon
                        icon-class="VideoWindows"
                        v-else
                        class="svgiconclass"
                      ></svg-icon>
                    </el-tooltip>
                  </div>

                  <!-- 设置是否静音状态 -->

                  <div
                    @click="openmuted"
                    class="btndiv"
                    style="margin-left: 16px"
                  >
                    <!--                    <el-tooltip-->
                    <!--                      content="声音"-->
                    <!--                      placement="top"-->
                    <!--                      effect="dark"-->
                    <!--                      :visible-arrow="false"-->
                    <!--                    >-->
                    <el-popover
                      placement="top"
                      trigger="hover"
                      :visible-arrow="false"
                      :append-to-body="false"
                      popper-class="mutepopover"
                    >
                      <div class="mutepopover_div">
                        <p>{{ muteSlidervalue }}</p>
                        <el-slider
                          v-model="muteSlidervalue"
                          vertical
                          height="80px"
                          :show-tooltip="false"
                          @change="muteSliderChange"
                        >
                        </el-slider>
                      </div>
                      <svg-icon
                        icon-class="sound"
                        class="svgiconclass"
                        slot="reference"
                        v-if="mute"
                      ></svg-icon>
                      <!-- ! todo -->
                      <svg-icon
                        icon-class="silence"
                        slot="reference"
                        class="svgiconclass"
                        v-else
                      ></svg-icon>
                      <!--                    </el-tooltip>-->
                    </el-popover>
                  </div>
                  <!-- 切换涂抹视频-->
                  <div
                    v-if="this.dataInfo.base_info.subtitle_smearing != '0'"
                    style="line-height: 18px"
                  >
                    <el-tooltip
                      :content="
                        dataInfo.base_info.dubbing_smearing_status != '0'
                          ? $t('VideoDubbingClone.dubText71')
                          : $t('VideoDubbingClone.dubText69')
                      "
                      placement="top"
                      effect="dark"
                      :visible-arrow="false"
                    >
                      <el-switch
                        style="margin-left: 10px"
                        class="switchClass"
                        v-model="switchDaubVisible"
                        :disabled="
                          dataInfo.base_info.dubbing_smearing_status != '0'
                        "
                        active-color="#46ADFF"
                        inactive-color="#515565"
                        active-text=""
                        @change="daubSwitchChange"
                        inactive-text=""
                      >
                      </el-switch>
                    </el-tooltip>
                  </div>
                </div>
                <div class="center">
                  <!-- 回退 -->
                  <div @click="rew" class="btndiv" id="rew">
                    <!-- ! todo -->
                    <svg-icon
                      icon-class="VideoBack"
                      class="svgiconclass"
                    ></svg-icon>
                  </div>
                  <!-- 开始时间 -->
                  <p>{{ videoStartTime }}</p>
                  <!-- 暂停播放按钮 -->

                  <div @click="playvideo" v-if="player" class="btndiv">
                    <!-- <el-tooltip
                      content="播放"
                      placement="top"
                      effect="dark"
                      :visible-arrow="false"
                    > -->
                    <svg-icon
                      icon-class="VideoPlay"
                      class="svgiconclass"
                    ></svg-icon>
                    <!-- </el-tooltip> -->
                  </div>
                  <div @click="closevideo" v-else class="btndiv">
                    <!-- <el-tooltip
                      content="暂停"
                      placement="top"
                      effect="dark"
                      :visible-arrow="false"
                    > -->
                    <svg-icon
                      icon-class="VideoPause"
                      class="svgiconclass"
                    ></svg-icon>
                    <!-- </el-tooltip> -->
                  </div>

                  <!-- 结束时间 -->
                  <p>{{ videolangtime }}</p>
                  <!-- 前进 -->
                  <div @click="speek" class="btndiv" id="speek">
                    <!-- ! todo -->
                    <svg-icon
                      icon-class="VideoForward"
                      class="svgiconclass"
                    ></svg-icon>
                  </div>
                </div>
                <div class="right">
                  <!-- 字幕样式设置 -->
                  <div
                    @click="TitleStyleSetting"
                    class="btndiv"
                    style="margin-right: 12px"
                    v-if="
                      this.dataInfo.base_info.subtitle_suppression != '0' &&
                      this.dataInfo.base_info.subtitle_suppression != null
                    "
                  >
                    <el-tooltip
                      :content="$t('VideoDubbingClone.dubText42')"
                      placement="top"
                      effect="dark"
                      :visible-arrow="false"
                    >
                      <svg-icon
                        icon-class="Title_style_setting"
                        class="svgiconclass"
                      ></svg-icon>
                    </el-tooltip>
                  </div>
                  <!-- 局部涂抹 -->
                  <div
                    v-if="this.dataInfo.base_info.subtitle_smearing != '0'"
                    @click="LocalDaub"
                    class="btndiv"
                    style="margin-right: 12px"
                  >
                    <el-tooltip
                      :content="
                        dataInfo.base_info.dubbing_smearing_status != '0'
                          ? $t('VideoDubbingClone.dubText71')
                          : $t('VideoDubbingClone.dubText43')
                      "
                      placement="top"
                      effect="dark"
                      :visible-arrow="false"
                      :class="
                        this.dataInfo.base_info.dubbing_smearing_status != '0'
                          ? 'icon_disabled'
                          : ''
                      "
                    >
                      <svg-icon
                        icon-class="Local_blur"
                        class="svgiconclass"
                      ></svg-icon>
                    </el-tooltip>
                  </div>
                  <!-- 播放倍数 -->
                  <el-dropdown
                    @command="handleCommand"
                    placement="top"
                    trigger="click"
                  >
                    <p
                      class="el-dropdown-link"
                      style="margin-right: 16px; cursor: pointer"
                    >
                      {{ ds + "X" }}
                    </p>
                    <el-dropdown-menu slot="dropdown" :append-to-body="false">
                      <el-dropdown-item command="0.5">0.5X</el-dropdown-item>
                      <el-dropdown-item command="1">1X</el-dropdown-item>
                      <el-dropdown-item command="1.25">1.25X</el-dropdown-item>
                      <el-dropdown-item command="1.5">1.5X</el-dropdown-item>
                      <el-dropdown-item command="1.75">1.75X</el-dropdown-item>
                      <el-dropdown-item command="2">2X</el-dropdown-item>
                      <el-dropdown-item command="2.5">2.5X</el-dropdown-item>
                      <el-dropdown-item command="3">3X</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                  <!-- 设置字幕显示 -->

                  <div @click="showSubtitle" class="btndiv showSubtitlediv">
                    <el-popover
                      placement="top-end"
                      :append-to-body="false"
                      :visible-arrow="false"
                      width="100"
                      trigger="click"
                      popper-class="popperClassDiv"
                    >
                      <div class="radioClass">
                        <el-radio-group v-model="subtitleradio">
                          <el-radio label="0"
                            >{{ $t("OverwriteOperation.SourceSubtitle") }}
                          </el-radio>
                          <el-radio label="1" v-if="!dataInfo.base_info.show"
                            >{{ $t("OverwriteOperation.TargetSubtitle") }}
                          </el-radio>
                          <el-radio label="2" v-if="!dataInfo.base_info.show"
                            >{{ $t("OverwriteOperation.BilingualSubtitle") }}
                          </el-radio>
                          <el-radio label="3"
                            >{{ $t("OverwriteOperation.HideSubtitle") }}
                          </el-radio>
                        </el-radio-group>
                      </div>
                      <svg-icon
                        icon-class="SubtitleSettings"
                        class="svgiconclass"
                        slot="reference"
                      ></svg-icon>
                    </el-popover>
                  </div>
                </div>
              </div>
            </div>
            <div class="content_left_bom">
              <el-tabs v-model="tabname" @tab-click="handleClicktab">
                <el-tab-pane label="TM 搜索" name="first" v-if="showTM"
                  >TM 搜索
                </el-tab-pane>
                <el-tab-pane
                  :label="$t('OverwriteOperation.FindAndReplace')"
                  name="second"
                >
                  <div class="tabdivclass">
                    <div class="p_div">
                      <p class="p1">{{ $t("OverwriteOperation.lookup") }}</p>
                      <p class="p2">{{ $t("OverwriteOperation.lookup") }}</p>
                    </div>
                    <div class="btninptu">
                      <div
                        class="btninptu_topInput"
                        v-if="dataInfo.base_info.show"
                      >
                        <el-input
                          :placeholder="$t('OverwriteOperation.placeholder1')"
                          v-model="lookupinput"
                          length="300"
                          @blur="textareaBlues = false"
                          @focus="textareaBlues = true"
                          @input="textareaBlues = true"
                          @change="lookupinputChange($event)"
                        ></el-input>
                        <div class="loockUpandReplaceDiv">
                          <span v-if="lookupinput !== lookupinput.trim()">
                            {{ $t("CommonName.loockUptext") }}</span
                          >
                        </div>
                      </div>

                      <div class="changeinputs" v-else>
                        <el-input
                          :placeholder="$t('OverwriteOperation.placeholder1')"
                          class="input-with-select"
                          v-model="lookupinput"
                          @blur="textareaBlues = false"
                          @focus="textareaBlues = true"
                          @input="textareaBlues = true"
                          @change="lookupinputChange($event)"
                        >
                          <el-select
                            v-model="select"
                            slot="prepend"
                            :placeholder="$t('CommonName.Pleaseselect')"
                            @blur="textareaBlues = false"
                            @focus="textareaBlues = true"
                            @input="textareaBlues = true"
                            @change="lookupSelectChange"
                          >
                            <el-option
                              :label="$t('CommonName.lookupText1')"
                              value="1"
                            ></el-option>
                            <el-option
                              :label="$t('CommonName.lookupText2')"
                              value="2"
                            ></el-option>
                          </el-select>
                        </el-input>
                        <div class="loockUpandReplaceDiv">
                          <span v-if="lookupinput !== lookupinput.trim()">
                            {{ $t("CommonName.loockUptext") }}</span
                          >
                        </div>
                      </div>
                      <div class="button_div">
                        <el-button
                          type="primary"
                          plain
                          v-show="showLookup"
                          class="buttons setpadding10"
                          @click="lookup(0)"
                          >{{ $t("CommonName.Prev") }}
                        </el-button>
                        <el-button
                          type="primary"
                          plain
                          v-show="showLookup"
                          class="buttons setpadding10"
                          @click="lookup(1)"
                          style="margin-left: 0"
                          >{{ $t("CommonName.Nexts") }}
                        </el-button>
                      </div>
                    </div>
                    <div class="checkboxClass" style="margin-top: 5px">
                      <el-checkbox-group v-model="checkboxGroup">
                        <el-checkbox label="0" border>
                          {{ $t("OverwriteOperation.Matchcase") }}
                        </el-checkbox>
                        <el-checkbox label="1" border>
                          {{ $t("OverwriteOperation.Wholewordmatching") }}
                        </el-checkbox>
                      </el-checkbox-group>
                    </div>
                    <div class="p_div">
                      <p class="p1">
                        {{ $t("OverwriteOperation.Replacewith") }}
                      </p>
                      <p class="p2">{{ $t("ProjectManagement.Replace") }}</p>
                    </div>
                    <div class="btninptu">
                      <div class="btninptu_topInput">
                        <el-input
                          :placeholder="$t('OverwriteOperation.placeholder1')"
                          v-model="replaceInput"
                          @blur="textareaBlues = false"
                          @focus="textareaBlues = true"
                          @input="textareaBlues = true"
                        ></el-input>
                        <div class="loockUpandReplaceDiv">
                          <span v-if="replaceInput !== replaceInput.trim()">{{
                            $t("CommonName.Replacetext")
                          }}</span>
                        </div>
                      </div>

                      <div class="button_div">
                        <el-button
                          class="buttons setpadding10"
                          type="primary"
                          @click="replace(0)"
                          plain
                          v-show="showLookup"
                          >{{ $t("CommonName.Prev") }}
                        </el-button>
                        <el-button
                          class="buttons setpadding10"
                          type="primary"
                          v-show="showLookup"
                          style="margin-left: 0"
                          @click="replace(1)"
                          plain
                        >
                          {{ $t("CommonName.Nexts") }}
                        </el-button>
                      </div>
                    </div>
                  </div>
                </el-tab-pane>
                <!-- 错误检查 -->
                <el-tab-pane name="third">
                  <span slot="label"
                    >{{ $t("OverwriteOperation.Warnings")
                    }}<span v-if="showWarningNumber">{{
                      "(" + showWarningNumbernum + ")"
                    }}</span></span
                  >
                  <div class="tableClass">
                    <!-- <el-table
                      :row-style="{
                        height: 40 + 'px',
                        background: 'none',
                        color: '#ffffff',
                      }"
                      :cell-style="{ padding: 0 + 'px' }"
                      :data="tabTableData"
                      :show-header="false"
                      tooltip-effect="dark"
                      style="width: 100%"
                      @row-click="tabrowClick"
                      :row-class-name="tabtableRowClassName"
                      height="100%"
                    >
                      <el-table-column
                        label="#"
                        prop="index"
                        width="50"
                        class-name="showborder"
                      >
                      </el-table-column>
                      <el-table-column label="" prop="errorData">
                      </el-table-column>
                    </el-table> -->

                    <virtual-list
                      class="tabTableScroller"
                      :data-key="'sentence_id'"
                      :data-sources="tabTableData"
                      :estimate-size="40"
                      :data-component="tabTablebody"
                      :item-class="'tabTablebodyItem'"
                      ref="tabTable"
                      :keeps="20"
                      :extra-props="{
                        currentRegion,
                        tabrowData,
                        steptabTable,
                      }"
                    />
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
          <div class="content_right">
            <div class="content_right_top">
              <div class="top">
                <div class="right" v-if="dataInfo.base_info">
                  <div class="wavediv"></div>
                  <!-- <div id="wave-timeline" ref="wave-timeline"></div> -->
                  <!-- <div id="waveform" ref="waveform" @click="clearReagion" /> -->
                  <div id="waveform-container" ref="waveform-container" />
                  <div
                    v-if="false"
                    :id="
                      dataInfo.base_info.show ? 'reginscroll' : 'reginscroll2'
                    "
                    style=""
                  >
                    <div id="reginscroll1" v-if="Reginleft">
                      <div v-for="item in Reginleft" :key="item.id">
                        <p
                          class="reginleft"
                          :id="'lang' + item.id"
                          :style="{
                            left: item.left + 'px',
                            width: item.width + 'px',
                            color: currentRegion.id == item.id ? 'blue' : '',
                          }"
                          @mouseover="
                            onShowNameTipsMouseenter('lang' + item.id, item)
                          "
                        >
                          <el-tooltip
                            class="item"
                            effect="dark"
                            :content="item.subtitle"
                            :placement="'top'"
                            :visible-arrow="false"
                            :disabled="!item.showdropdown"
                          >
                            <!-- <div slot="content" style="width: 300px">
                          {{ item.subtitle }}
                        </div> -->
                            <span>{{ item.subtitle }}</span>
                          </el-tooltip>
                        </p>
                        <p
                          v-if="!dataInfo.base_info.show && item.sentence_trans"
                          class="reginleft_btm"
                          :id="'lang1' + item.id"
                          :style="{
                            left: item.left + 'px',
                            width: item.width + 'px',
                            color: currentRegion.id == item.id ? 'blue' : '',
                          }"
                          @mouseover="
                            onShowNameTipsMouseenter('lang1' + item.id, item)
                          "
                        >
                          <el-tooltip
                            class="item"
                            effect="dark"
                            :content="item.sentence_trans"
                            placement="bottom"
                            :visible-arrow="false"
                            :disabled="!item.showdropdown"
                          >
                            <span>{{ item.sentence_trans }}</span>
                          </el-tooltip>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="btm" v-if="false">
                  <!-- 波形操作 -->
                  <!-- 波长边长 -->
                  <el-tooltip
                    content="放大"
                    placement="left"
                    effect="dark"
                    :visible-arrow="false"
                  >
                    <svg-icon
                      icon-class="stretch"
                      @click="videozoomlang"
                    ></svg-icon>
                  </el-tooltip>
                  <!-- 波长变窄 -->
                  <el-tooltip
                    content="缩小"
                    placement="left"
                    effect="dark"
                    :visible-arrow="false"
                  >
                    <svg-icon
                      icon-class="narrow"
                      @click="videozoomshort"
                    ></svg-icon>
                  </el-tooltip>
                  <!-- 波长变高 -->
                  <el-tooltip
                    content="拉高"
                    placement="left"
                    effect="dark"
                    :visible-arrow="false"
                  >
                    <svg-icon
                      icon-class="amplification"
                      @click="setwaveformHeight"
                    ></svg-icon>
                  </el-tooltip>
                  <!-- 波长变低 -->
                  <el-tooltip
                    content="拉低"
                    placement="left"
                    effect="dark"
                    :visible-arrow="false"
                  >
                    <svg-icon
                      icon-class="compress"
                      @click="setwaveformHeightlow"
                    ></svg-icon>
                  </el-tooltip>
                </div>
              </div>
            </div>
            <div class="content_right_bom">
              <div class="top">
                <p>
                  {{
                    $t("CommonName.Numberofsentencesegments") +
                    " : " +
                    tableData.length
                  }}
                </p>
                <div class="topcion">
                  <!-- 表格操作 -->
                  <!-- 单句播放 -->
                  <el-tooltip
                    :content="$t('OverwriteOperation.tooltipContent')"
                    placement="top"
                    effect="dark"
                    :visible-arrow="false"
                  >
                    <div
                      class="svgdiv"
                      id="SentencesPlayIcon"
                      @click="areaPlayClick(0)"
                      @mousedown="(e) => e.preventDefault()"
                      v-if="showloopbtn"
                    >
                      <svg-icon
                        icon-class="SentencesPlay"
                        class="icon"
                      ></svg-icon>
                    </div>
                    <div
                      class="svgdiv"
                      id="SentencesPlayIcon"
                      @click="areaPlayClick(1)"
                      @mousedown="(e) => e.preventDefault()"
                      v-else
                    >
                      <svg-icon icon-class="Pause" class="icon"></svg-icon>
                    </div>
                  </el-tooltip>
                  <!-- Asr -->
                  <el-tooltip
                    :content="$t('OverwriteOperation.tooltipContent1')"
                    placement="top"
                    effect="dark"
                    :visible-arrow="false"
                  >
                    <div
                      class="svgdiv"
                      @click="toolClick(1)"
                      id="asrSvg"
                      @mousedown="(event) => event.preventDefault()"
                    >
                      <svg-icon
                        icon-class="SentencesTranscription"
                        class="icon"
                      ></svg-icon>
                    </div>
                  </el-tooltip>
                  <div class="division"></div>
                  <!-- ================添加文字样式============             -->
                  <el-tooltip
                    :content="$t('SubtitleStyle.style')"
                    placement="top"
                    effect="dark"
                    :visible-arrow="false"
                  >
                    <el-dropdown
                      trigger="click"
                      @command="statuscommand"
                      :hide-on-click="hideOnClick"
                      @visible-change="stylevisiblechange"
                    >
                      <div class="el-dropdown-link">
                        <div class="svgdiv" style="color: #b7d8f2">
                          <svg-icon
                            icon-class="Font_style"
                            class="icon"
                          ></svg-icon>
                        </div>
                      </div>

                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                          :command="{ id: 0, tag: 'i', type: 1 }"
                          @mouseenter.native="handleDropdown(0)"
                        >
                          <!--                   斜体   -->
                          <div>
                            <svg-icon
                              icon-class="qingxie"
                              class="icon"
                            ></svg-icon>
                            {{ $t("SubtitleStyle.italic") }}
                          </div>
                        </el-dropdown-item>
                        <!--              下划线-->
                        <el-dropdown-item
                          :command="{ id: 1, tag: 'u', type: 2 }"
                          @mouseenter.native="handleDropdown(0)"
                        >
                          <div>
                            <svg-icon
                              icon-class="xiahuaxian"
                              class="icon"
                            ></svg-icon>
                            {{ $t("SubtitleStyle.underline") }}
                          </div>
                        </el-dropdown-item>
                        <!--                    加粗-->
                        <el-dropdown-item
                          :command="{ id: 2, tag: 'b', type: 3 }"
                          @mouseenter.native="handleDropdown(0)"
                        >
                          <div>
                            <svg-icon
                              icon-class="jiacu"
                              class="icon"
                            ></svg-icon>
                            {{ $t("SubtitleStyle.Bold") }}
                          </div>
                        </el-dropdown-item>
                        <!--                    括号-->
                        <el-dropdown-item
                          :command="{
                            id: 6,
                            tag: dropdownTag2,
                            type: dropdownType2,
                          }"
                          @mouseenter.native="handleDropdown(3)"
                        >
                          <!--                               @mouseleave.native="handleDropdownShow1 = false"-->
                          <div class="handleDropdown_div">
                            <div>
                              <svg-icon
                                icon-class="parentheses"
                                class="icon"
                              ></svg-icon>
                              {{ $t("SubtitleStyle.bracket") }}
                            </div>
                            <i class="el-icon-arrow-right"></i>
                            <div
                              v-if="handleDropdownShow3"
                              class="handleDropdown_div_div3"
                              :class="
                                showlanguage == 'en'
                                  ? 'handleDropdown_div_div_English'
                                  : ''
                              "
                              @mouseleave="handleDropdownShow3 = false"
                            >
                              <div @click="handleDropdownDiv3Click(1)">
                                <svg-icon
                                  icon-class="parentheses_cn"
                                  class="icon"
                                ></svg-icon>
                                {{ $t("SubtitleStyle.Fullangleparentheses") }}
                              </div>
                              <div @click="handleDropdownDiv3Click(2)">
                                <svg-icon
                                  icon-class="parentheses_en"
                                  class="icon"
                                ></svg-icon>
                                {{ $t("SubtitleStyle.Halfangleparentheses") }}
                              </div>
                              <div @click="handleDropdownDiv3Click(3)">
                                <svg-icon
                                  icon-class="square_brackets"
                                  class="icon"
                                ></svg-icon>
                                {{ $t("SubtitleStyle.squarebrackets") }}
                              </div>
                            </div>
                          </div>
                        </el-dropdown-item>
                        <!--                    音乐符号-->
                        <el-dropdown-item
                          :command="{
                            id: 3,
                            tag: dropdownTag,
                            type: dropdownType,
                          }"
                          @mouseenter.native="handleDropdown(1)"
                        >
                          <!--                               @mouseleave.native="handleDropdownShow1 = false"-->
                          <div class="handleDropdown_div">
                            <div>
                              <svg-icon
                                icon-class="yinyuefuhao"
                                class="icon"
                              ></svg-icon>
                              {{ $t("SubtitleStyle.musicnotation") }}
                            </div>
                            <i class="el-icon-arrow-right"></i>
                            <div
                              v-show="handleDropdownShow1"
                              class="handleDropdown_div_div"
                              :class="
                                showlanguage == 'en'
                                  ? 'handleDropdown_div_div_English'
                                  : ''
                              "
                              @mouseleave="handleDropdownShow1 = false"
                            >
                              <div @click="handleDropdownDivClick(1)">
                                <svg-icon
                                  icon-class="danyinfu"
                                  class="icon"
                                ></svg-icon>
                                {{ $t("SubtitleStyle.SmallMusicSymbol") }}
                              </div>
                              <div @click="handleDropdownDivClick(2)">
                                <svg-icon
                                  icon-class="shuangyinfu"
                                  class="icon"
                                ></svg-icon>
                                {{ $t("SubtitleStyle.Bigmusicsymbols") }}
                              </div>
                              <div @click="handleDropdownDivClick(3)">
                                <svg-icon
                                  icon-class="danyinfu"
                                  class="icon"
                                ></svg-icon>
                                {{ $t("SubtitleStyle.SmallMusicSymbolspace") }}
                              </div>
                              <div @click="handleDropdownDivClick(4)">
                                <svg-icon
                                  icon-class="shuangyinfu"
                                  class="icon"
                                ></svg-icon>
                                {{ $t("SubtitleStyle.Bigmusicsymbolsspace") }}
                              </div>
                            </div>
                          </div>
                        </el-dropdown-item>
                        <!--                    字幕位置-->
                        <el-dropdown-item
                          :command="{
                            id: 4,
                            tag: dropdownTag1,
                            type: dropdownType1,
                          }"
                          @mouseenter.native="handleDropdown(2)"
                        >
                          <div class="handleDropdown_div">
                            <div>
                              <svg-icon
                                icon-class="zimuweizhi"
                                class="icon"
                              ></svg-icon>
                              {{ $t("SubtitleStyle.Subtitleposition") }}
                            </div>

                            <i class="el-icon-arrow-right" style=""></i>
                            <div
                              class="handleDropdown_div_div2"
                              v-show="handleDropdownShow2"
                              @mouseleave="handleDropdownShow2 = false"
                              :class="{
                                EnglishDiv: showlanguage == 'en',
                                EnglishDiv1: showlanguage == 'en',
                              }"
                              :style="{
                                left: !dataInfo.base_info.show ? '' : '-330px',
                              }"
                            >
                              <div
                                v-for="item in handleDropdownShowData"
                                :key="item.id"
                                @click="handlehandleDropdown2Click(item.id)"
                              >
                                <svg-icon
                                  :icon-class="item.iconClass"
                                  class="icon"
                                ></svg-icon>
                                {{ item.name }}
                              </div>
                            </div>
                          </div>
                        </el-dropdown-item>
                        <!--                    清除样式-->
                        <el-dropdown-item
                          :command="{ id: 5, type: -1 }"
                          @mouseenter.native="handleDropdown(0)"
                        >
                          <div>
                            <svg-icon
                              icon-class="cleartag"
                              class="icon"
                            ></svg-icon>
                            {{ $t("SubtitleStyle.ClearStyle") }}
                          </div>
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-tooltip>
                  <!--              在线ocr-->
                  <el-tooltip
                    :content="$t('OverwriteOperation.tooltipContent17')"
                    placement="top"
                    effect="dark"
                    :visible-arrow="false"
                  >
                    <div class="svgdiv" @click="toolClick(11)">
                      <svg-icon icon-class="OCR" class="icon"></svg-icon>
                    </div>
                  </el-tooltip>
                  <!-- ================插入========================== -->
                  <el-tooltip
                    :content="$t('OverwriteOperation.tooltipContent2')"
                    placement="top"
                    effect="dark"
                    :visible-arrow="false"
                  >
                    <div class="svgdiv" @click="toolClick(2)">
                      <svg-icon icon-class="insert" class="icon"></svg-icon>
                    </div>
                  </el-tooltip>
                  <!-- 合并 -->
                  <el-tooltip
                    :content="$t('OverwriteOperation.tooltipContent3')"
                    placement="top"
                    effect="dark"
                    :visible-arrow="false"
                  >
                    <div
                      class="svgdiv"
                      @click="toolClick(3)"
                      @mousedown="(event) => event.preventDefault()"
                    >
                      <svg-icon icon-class="merge" class="icon"></svg-icon>
                    </div>
                  </el-tooltip>
                  <!-- 拆分 -->
                  <el-tooltip
                    :content="$t('OverwriteOperation.tooltipContent4')"
                    placement="top"
                    effect="dark"
                    :visible-arrow="false"
                  >
                    <!-- @mousedown="(event) => event.preventDefault()" -->
                    <div
                      class="svgdiv"
                      @click="toolClick(4)"
                      @mousedown="(event) => event.preventDefault()"
                      id="splitSvg"
                    >
                      <svg-icon icon-class="Split" class="icon"></svg-icon>
                    </div>
                  </el-tooltip>
                  <!-- 删除 -->
                  <el-tooltip
                    :content="$t('OverwriteOperation.tooltipContent5')"
                    placement="top"
                    effect="dark"
                    :visible-arrow="false"
                  >
                    <div class="svgdiv" @click="toolClick(5)">
                      <svg-icon icon-class="delete" class="icon"></svg-icon>
                    </div>
                  </el-tooltip>
                  <div class="division"></div>
                  <el-tooltip
                    :content="$t('OverwriteOperation.AjustTimecodes')"
                    placement="top"
                    effect="dark"
                    :visible-arrow="false"
                  >
                    <div class="svgdiv" @click="toolClick(9)">
                      <svg-icon
                        icon-class="Ajust_Timecodes"
                        class="icon"
                      ></svg-icon>
                    </div>
                  </el-tooltip>
                  <el-tooltip
                    :content="$t('VideoDubbing.toolText7')"
                    placement="top"
                    effect="dark"
                    :visible-arrow="false"
                  >
                    <div class="svgdiv" @click="toolClick(10)">
                      <svg-icon icon-class="Undo" class="icon"></svg-icon>
                    </div>
                  </el-tooltip>
                  <div class="division"></div>
                  <!-- MT -->
                  <el-tooltip
                    :content="$t('OverwriteOperation.tooltipContent11')"
                    placement="top"
                    effect="dark"
                    :visible-arrow="false"
                    v-if="!dataInfo.base_info.show"
                  >
                    <div
                      class="svgdiv"
                      @click="toolClick(6)"
                      id="MTsvg"
                      @mousedown="(event) => event.preventDefault()"
                    >
                      <svg-icon
                        icon-class="TranslateMT"
                        class="icon"
                      ></svg-icon>
                    </div>
                  </el-tooltip>
                  <div style="height: 16px; line-height: 16px">
                    <el-tooltip
                      :content="$t('OverwriteOperation.tooltipContent7')"
                      placement="top"
                      effect="dark"
                      :visible-arrow="false"
                    >
                      <el-popover
                        placement="bottom-end"
                        width="230"
                        v-model="visible"
                        :visible-arrow="false"
                        @show="showpopoverInput"
                      >
                        <div class="popoverDiv popoverDiv1">
                          <el-input
                            v-model="popoverInput"
                            :placeholder="$t('OverwriteOperation.placeholder')"
                            @input="
                              popoverInput = popoverInput.replace(
                                /[^0-9,.:]+$/g,
                                ''
                              )
                            "
                            @keyup.enter.native="searchdetermineClick"
                            ref="popoverInputref"
                            maxlength="100"
                          ></el-input>
                          <el-button
                            type="primary"
                            class="setpadding16"
                            @click="searchdetermineClick"
                          >
                            Go
                          </el-button>
                        </div>
                        <div
                          class="svgdiv"
                          slot="reference"
                          @click="toolClick(7)"
                        >
                          <svg-icon icon-class="SkipTo" class="icon"></svg-icon>
                        </div>
                      </el-popover>
                    </el-tooltip>
                  </div>
                  <el-tooltip
                    :content="$t('OverwriteOperation.Verify')"
                    placement="top"
                    effect="dark"
                    :visible-arrow="false"
                  >
                    <div class="svgdiv" @click="toolClick(8)">
                      <svg-icon
                        icon-class="ErrorChecking"
                        class="icon"
                      ></svg-icon>
                    </div>
                  </el-tooltip>

                  <div class="division"></div>
                  <el-dropdown
                    @command="svghandleCommand"
                    placement="bottom"
                    trigger="click"
                    class="svghandleCommandClass"
                  >
                    <div class="lasticon svgdiv">
                      <i class="el-icon-more"></i>
                    </div>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="0">
                        {{ $t("OverwriteOperation.Help") }}
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
              <div class="bottom">
                <div style="" class="tableDiv">
                  <div
                    class="titleDiv"
                    @click="headerClick"
                    :class="
                      !dataInfo.base_info.show
                        ? 'titleDivheight56'
                        : 'titleDivheight40'
                    "
                  >
                    <div class="onediv borderdiv">#</div>
                    <div class="twodiv borderdiv">
                      <!-- 时间轴 -->
                      {{ $t("OverwriteOperation.TimeRange") }}
                    </div>
                    <div class="threediv borderdiv">
                      <!-- 时长 -->
                      {{ $t("OverwriteOperation.Duration") }}
                    </div>
                    <div
                      class="borderdiv"
                      :class="
                        showMarginRight6 ? 'sbtitledivarright' : 'sbtitlediv'
                      "
                    >
                      <div
                        style=""
                        class="sbtitledivtitle"
                        v-if="dataInfo.base_info"
                      >
                        <p>
                          {{
                            $t("OverwriteOperation.Source") +
                            " : " +
                            dataInfo.base_info.slang
                          }}
                        </p>
                        <p v-if="!dataInfo.base_info.show">
                          {{
                            $t("OverwriteOperation.Target") +
                            " : " +
                            dataInfo.base_info.tlang
                          }}
                        </p>
                      </div>
                    </div>
                    <div class="sixdiv borderdiv">
                      <!-- 字符数 -->
                      <p>{{ $t("OverwriteOperation.Char") }}</p>
                    </div>
                    <div
                      class="lastdiv borderdiv"
                      :class="showMarginRight6 ? 'lastdivmarright' : ''"
                    >
                      <!-- 读速 -->
                      <p>{{ $t("OverwriteOperation.Speed") }}</p>
                    </div>
                  </div>
                  <!-- @scroll="updateElementWidth" -->
                  <virtual-list
                    :class="{
                      scroller58: !dataInfo.base_info.show ? true : false,
                      scroller: dataInfo.base_info.show ? true : false,
                      setUserSelect: ShiftDown ? true : false,
                    }"
                    :id="!dataInfo.base_info.show ? 'scroller58' : 'scroller'"
                    :data-key="'sentence_id'"
                    :data-sources="tableData"
                    :estimate-size="58"
                    :data-component="commentsItem"
                    :item-class="'list-item-dynamic'"
                    ref="virtualref"
                    :size="tableData.length"
                    :keeps="20"
                    :extra-props="{
                      caption_info,
                      dataInfo,
                      currentRegion,
                      selectionRow,
                      ShiftDown,
                    }"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DialogComVue
      :dialogVisible="dialogVisible"
      @determineClick="determineClick"
      @changeDialogVisible="changeDialogVisible"
      :showotherclosebtn="showotherclosebtn"
      :showfooter="showfooter"
      :width="dialogWidth"
      :top="'10%'"
      :title="dialogtitle"
      :footerbtnName="footerbtnName"
      :closefooterbtnName="closefooterbtnName"
      v-if="dialogVisible"
    >
      <!--      字幕规则设置-->
      <div
        v-if="dialogtitle == $t('VideoDetails.dialogText')"
        class="Glossariestabs1"
      >
        <div class="glo_bom_top">
          <div>
            <!--            每行字幕最短持续时间-->
            <p>{{ $t("VideoDetails.dialogText1") }}</p>
            <el-input v-model="Glossariestab1.min_duration"></el-input>
          </div>
          <div style="margin-left: 40px">
            <!--            每行字幕最长持续时间-->
            <p>{{ $t("VideoDetails.dialogText2") }}</p>
            <el-input v-model="Glossariestab1.max_duration"></el-input>
          </div>
        </div>
        <div class="glo_bom_center">
          <div>
            <!--            时间轴最小间隔-->
            <p>{{ $t("VideoDetails.dialogText3") }}</p>
            <div class="checkboxClass checkboxClass1">
              <el-input v-model="Glossariestab1.minigap_time"></el-input>
              <el-checkbox v-model="Glossariestab1.minigap_type" border>
                <!--                允许无间隔-->
                {{ $t("VideoDetails.dialogText4") }}
              </el-checkbox>
            </div>
          </div>
          <div style="margin-left: 40px">
            <!--            允许时间轴重叠-->
            <p>{{ $t("VideoDetails.dialogText5") }}</p>
            <div class="checkboxClass">
              <el-checkbox v-model="Glossariestab1.duration_cross" border
                >{{ $t("VideoDetails.dialogText5") }}
              </el-checkbox>
            </div>
          </div>
        </div>
        <div class="glo_bom_table tableClass borderTableStyle">
          <p>{{ $t("VideoDetails.dialogText6") }}</p>
          <el-table
            :row-style="{
              height: 40 + 'px',
              background: '#454758',
              color: '#ffffff',
            }"
            :cell-style="{ padding: 0 + 'px' }"
            :header-cell-style="{
              height: 32 + 'px',
              padding: '0px',
              color: '#adb0bc',
              background: '#454758',
            }"
            :data="Glossariestab1.langs"
            tooltip-effect="dark"
            style="width: 100%"
            max-height="130px"
            border
          >
            <el-table-column
              prop="language_name"
              :label="$t('CaptionSettings.languages')"
            >
            </el-table-column>
            <el-table-column :label="$t('CaptionSettings.MaximumWords')">
              <template slot-scope="scope">
                <el-input v-model="scope.row.char_line"></el-input>
              </template>
            </el-table-column>
            <el-table-column :label="$t('CaptionSettings.Readrate')">
              <template slot-scope="scope">
                <el-input v-model="scope.row.char_speed"></el-input>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <!--    禁用字符  -->
      <div
        v-if="dialogtitle == $t('OverwriteOperation.Forbiddencharacter')"
        class="Glossariestabs2"
      >
        <p>{{ $t("CaptionSettings.Glossariestabs2Title") }}</p>
        <!-- <div class="radioClass">
          <el-radio-group v-model="Glossariestab2.radio">
            <el-radio border label="0">无</el-radio>
            <el-radio border label="1">中文字符</el-radio>
            <el-radio border label="2">英文字符</el-radio>
          </el-radio-group>
        </div> -->
        <div>
          <!-- <div class="Gloss_btm">
            <el-switch
              v-model="Glossariestab2.value"
              active-color="#46adff"
              inactive-color="#7d7f8b"
            >
            </el-switch>
            <p>自定义禁止字符</p>
          </div> -->
          <el-input
            type="textarea"
            v-model="Glossariestab2.textarea"
            @blur="textareaBlues = false"
            @focus="textareaBlues = true"
            @input="textareaBlues = true"
            resize="none"
            :placeholder="$t('CaptionSettings.Useregextocustomize')"
          ></el-input>
        </div>
      </div>
      <!--      错误检查-->
      <div
        v-if="dialogtitle == $t('OverwriteOperation.Warnings')"
        class="Glossariestabs3"
      >
        <div class="checkboxClass" v-if="dataInfo.base_info.show">
          <el-checkbox-group v-model="Glossariestabs4">
            <el-checkbox
              v-for="item in checkboxList"
              :key="item.label"
              :label="item.label"
              border
              >{{ item.name }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
        <el-tabs v-model="verifyname" @tab-click="verifyhandleClicktab" v-else>
          <el-tab-pane :label="$t('OverwriteOperation.TargetCheck')" name="1">
            <div class="checkboxClass checkboxClass1" v-if="verifyname == '1'">
              <el-checkbox-group v-model="Glossariestabs3">
                <el-checkbox
                  v-for="item in checkboxList"
                  :key="item.label"
                  :label="item.label"
                  border
                  >{{ item.name }}
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="$t('OverwriteOperation.SourceCheck')" name="2">
            <div class="checkboxClass checkboxClass1" v-if="verifyname == '2'">
              <el-checkbox-group v-model="Glossariestabs4">
                <el-checkbox
                  v-for="item in checkboxList1"
                  :key="item.label"
                  :label="item.label"
                  border
                  >{{ item.name }}
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div
        v-if="dialogtitle == $t('VideoDubbing.toolText6')"
        class="Glossariestabs4"
      >
        <HelpContent :canChange="true" :showwhat="1"></HelpContent>
      </div>
      <!--      平移时间轴-->
      <div
        v-if="dialogtitle == $t('OverwriteOperation.AjustTimecodes')"
        class="Glossariestabs5"
      >
        <div class="radioClass radioClass1">
          <el-radio-group v-model="form5.topradio" @input="radioinput">
            <el-radio border label="1"
              >{{ $t("OverwriteOperation.Shiftfrom") }}
            </el-radio>
            <el-radio border label="2"
              >{{ $t("OverwriteOperation.TimetoAdd") }}
            </el-radio>
          </el-radio-group>
        </div>
        <el-input v-model="form5.time" placeholder="00:00:00.000"></el-input>
        <div class="radioClass radioClass2">
          <el-radio-group v-model="form5.btmradio1">
            <el-radio
              border
              v-for="item in radiolist"
              :label="item.key"
              :key="item.key"
              >{{ item.value }}
            </el-radio>
          </el-radio-group>
        </div>
      </div>
    </DialogComVue>
    <!--局部涂抹-->
    <LocalDaub
      :dialogVisible="LocalDaubVisible"
      :videoUrl="videoUrl1"
      :job_id="job_id"
      @dialogMethod="LocalDaubMethod"
      @changeAutoVisible="HideLocalDaubVisible"
      v-if="LocalDaubVisible"
    ></LocalDaub>
    <!--字幕样式设置-->
    <SubtitleStyle
      :dialogVisible="SubStyleVisible"
      :videoUrl="videoUrl1"
      :job_id="job_id"
      :tableData="tableData"
      :dataInfo="dataInfo.base_info"
      :currentTime="this.videoDom.currentTime"
      @dialogMethod="SubStyleMethod"
      @changeAutoVisible="HideSubStyleVisible"
      v-if="SubStyleVisible"
    ></SubtitleStyle>
  </div>
</template>
<script>
import Peaks from "peaks.js";
import config from "@/config";
import HelpContent from "@/components/HelpContent.vue";
import DialogComVue from "@/components/DialogCom.vue";
import tabTablebody from "../../TeamMain/MediaOperation/ErrorCheckingTableBody.vue";
import { TimeHandle } from "@/api/setTime";
import FileSaver from "file-saver";
import {
  get_private_job_info,
  auto_mt,
  set_caption_info,
  set_shield_info,
  get_private_job_data_info,
  set_job_name,
  set_source_content,
  set_trans_content,
  split_segment,
  get_asr_content,
  merge_segment,
  delete_segment,
  get_new_segment,
  adjust_time_code1,
  undo_method1,
  set_time_code,
  get_mt,
  download_srt_content,
  check_subtitle_error,
  set_tag_batch_person,
  get_new_sentence_key1,
} from "@/utils/https";
import VideoDetialsItem from "./VideoDetialsItem.vue";
import { getStringToTime, getTimeString } from "@/utils/tools";
import { showLoading, hideLoading } from "@/utils/loading";
import VirtualList from "vue-virtual-scroll-list";
import { find_next, replace_find_content } from "@/utils/findreplace";
import { copyClick } from "@/assets/js/Publicmethods";
import LocalDaub from "./LocalDaub.vue";
import SubtitleStyle from "./SubtitleStyle.vue";
import $ from "jquery";
import { fabric } from "fabric";

export default {
  created() {
    // this.$emit("header", false, false, true, true);
    this.job_id = this.$route.query.job_id;
    this.info = JSON.parse(sessionStorage.getItem("info"));
    this.showlanguage = sessionStorage.getItem("lang");
    /**
     *
     * @param {* 粘贴 取消换行 } row
     * @param {*} data
     */
    this.$off("handlePaste").$on(
      "handlePaste",
      (row, data, text, cursorPosition) => {
        if (data == 1) {
          row.sentence_source =
            row.sentence_source.slice(0, cursorPosition) +
            text +
            row.sentence_source.slice(cursorPosition);
        } else {
          row.sentence_trans =
            row.sentence_trans.slice(0, cursorPosition) +
            text +
            row.sentence_trans.slice(cursorPosition);
        }
      }
    );
    /**
     *
     * @param {*点击 切换输入框} row
     * @param {*} data
     */
    this.$on("cellPClick", (row, data) => {
      if (data == 1) {
        row.showStartInput = !row.showStartInput;
      } else if (data == 2) {
        row.showEndInput = !row.showEndInput;
      } else if (data == 3) {
        row.showsourceInput = !row.showsourceInput;
      } else {
        row.showtransInput = !row.showtransInput;
      }

      // switch (data) {
      //   case 1:
      //     row.showStartInput = !row.showStartInput;
      //     break;
      //   case 2:
      //     row.showEndInput = !row.showEndInput;
      //     break;
      //   case 3:
      //     row.showsourceInput = !row.showsourceInput;
      //     break;
      //   default:
      //     row.showtransInput = !row.showtransInput;
      //     break;
      // }
    });
    /**
     *
     * @param {表格内输入框 失去焦点} val
     * @param {*} row
     */
    this.$on("textareaBlue", (row, e, data) => {
      this.textareaBlues = false;
      this.showLookup = true;
      this.splitFlag = false;
      row.typeinput = 0;
      if (data == 1) {
        if (!this.deleteFlag) {
          this.set_source_content(row);
        }
        row.showsourceInput = !row.showsourceInput;
        row.sentence_source = row.sentence_source.replace(/ +(?= )/g, " ");
        row.sentence_source_html = row.sentence_source_html.replace(
          / +(?= )/g,
          " "
        );
        const lines = row.sentence_source.split("\n");
        const formattedLines = lines.map((line) => line.trim());
        row.sentence_source = formattedLines.join("\n");
      } else {
        if (!this.deleteFlag) {
          this.set_trans_content(row);
        }
        row.showtransInput = !row.showtransInput;
        row.sentence_trans = row.sentence_trans.replace(/ +(?= )/g, " ");
        row.sentence_trans_html = row.sentence_trans_html.replace(
          / +(?= )/g,
          " "
        );
        const lines = row.sentence_trans.split("\n");
        const formattedLines = lines.map((line) => line.trim());
        row.sentence_trans = formattedLines.join("\n");
      }
      // this.offset = e.srcElement.selectionStart;
      this.find_cur_index = -1;
      row = this.setNumber(row);
    });
    /**
     *
     * @param {获取焦点} val
     * @param {*} row
     */
    this.$on("textareafocus", (val, row, data, event) => {
      this.textareaBlues = true;
      this.showLookup = false;
      this.splitFlag = true;
      row.data = data;
      row.typeinput = data;
      row = this.setNumbererr(row, data, val);
      // console.log(row, "row");
      this.Jumpposition(row);
      // console.log(111111, data, this.textareafocusAsr);
    });
    /**
     *
     * @param {textarea输入时} val
     * @param {*} row
     */
    this.$on("textareainput", (val, row, data) => {
      // console.log(val);
      this.textareaBlues = true;
      this.splitFlag = true;
      row = this.setNumbererr(row, data, val);
      // console.log(row);
    });
    /**
     *
     * @param {*格内输入框 失去焦点   时间修改  输入框} row
     * @param {*start表示开始时间 end 表示结束时间} data
     */
    this.$on("inputBlue", (row, data) => {
      for (let index = 0; index < this.tableData.length; index++) {
        if (this.tableData[index].sentence_id === row.sentence_id) {
          if (data === "start") {
            row.start_time = row.start_time.trim();
            if (!this.validateTimeFormat(row.start_time)) {
              row.start_time = getTimeString(row.data_start, 1, 3).replace(
                ".",
                ","
              );
              this.$messages(
                "warning",
                this.$t("OverwriteOperation.warningmessage1")
              );
              row.showStartInput = !row.showStartInput;
              return;
            }
            let a = getStringToTime(row.start_time);

            if (isNaN(a)) {
              row.start_time = getTimeString(row.data_start, 1, 3).replace(
                ".",
                ","
              );
              this.$messages(
                "warning",
                this.$t("OverwriteOperation.warningmessage1")
              );
              row.showStartInput = !row.showStartInput;
              return;
            } else {
              row.showStartInput = !row.showStartInput;
              if (
                Number(getStringToTime(row.start_time)) >= Number(row.data_end)
              ) {
                row.start_time = getTimeString(row.data_start, 1, 3).replace(
                  ".",
                  ","
                );
                return;
              }
              // duration_cross
              // 输入 时间 根据 是否允许时间轴交叉 来判断 能输入 得最大值 是多少
              if (this.caption_info.duration_cross == "0") {
                // 判断 不是第一个
                if (index != 0) {
                  if (
                    Number(getStringToTime(row.start_time)) <
                    Number(this.tableData[index - 1].data_end)
                  ) {
                    row.start_time = getTimeString(
                      row.data_start,
                      1,
                      3
                    ).replace(".", ",");
                    return;
                  }
                }
              } else {
                if (row.sentence_id !== this.tableData[0].sentence_id) {
                  if (
                    Number(getStringToTime(row.start_time)) <
                    Number(this.tableData[index - 1].data_start)
                  ) {
                    row.start_time = getTimeString(
                      row.data_start,
                      1,
                      3
                    ).replace(".", ",");
                    return;
                  }
                }
              }

              row.data_start = getStringToTime(row.start_time);
            }
          } else {
            row.end_time = row.end_time.trim();
            if (!this.validateTimeFormat(row.end_time)) {
              row.end_time = getTimeString(row.data_end, 1, 3).replace(
                ".",
                ","
              );
              this.$messages(
                "warning",
                this.$t("OverwriteOperation.warningmessage1")
              );
              row.showEndInput = !row.showEndInput;
              return;
            }
            let b = getStringToTime(row.end_time);
            if (isNaN(b)) {
              row.end_time = getTimeString(row.data_end, 1, 3).replace(
                ".",
                ","
              );
              this.$messages(
                "warning",
                this.$t("OverwriteOperation.warningmessage1")
              );
              row.showEndInput = !row.showEndInput;
              return;
            } else {
              row.showEndInput = !row.showEndInput;
              if (
                row.sentence_id !==
                this.tableData[this.tableData.length - 1].sentence_id
              ) {
                // duration_cross
                // 判断 是否 超过 下一句  得结束事件 0 表示 不允许 事件轴交叉
                if (this.caption_info.duration_cross == "0") {
                  if (
                    row.sentence_id !==
                    this.tableData[this.tableData.length - 1].sentence_id
                  ) {
                    if (
                      Number(getTimeString(row.end_time)) >
                      Number(this.tableData[index + 1].data_start)
                    ) {
                      row.end_time = getTimeString(row.data_end, 1, 3).replace(
                        ".",
                        ","
                      );
                      return;
                    }
                  }
                } else {
                  if (
                    row.sentence_id !==
                    this.tableData[this.tableData.length - 1].sentence_id
                  ) {
                    if (
                      Number(getStringToTime(row.end_time)) >
                      Number(this.tableData[index + 1].data_end)
                    ) {
                      row.end_time = getTimeString(row.data_end, 1, 3).replace(
                        ".",
                        ","
                      );
                      return;
                    }
                  }
                }
              }
              if (
                Number(getStringToTime(row.end_time) <= Number(row.data_start))
              ) {
                row.end_time = getTimeString(row.data_end, 1, 3).replace(
                  ".",
                  ","
                );
                return;
              }
              row.data_end = getStringToTime(row.end_time);
            }
          }
          // console.log(11111);
          this.set_time_code(row);
        }
      }
    });
    this.$on("rowClick", (row) => {
      if (this.showCanvas) {
        this.closeCanvas();
      }
      if (this.ShiftDown) {
        // console.log(11111);
        if (row.sentence_id !== this.startrow.sentence_id) {
          this.selectionRow.push(row);
          // console.log(22222);
          let data;

          if (this.startrow.index < row.index) {
            // console.log(33333);
            data = this.tableData.slice(this.startrow.index, row.index + 1);
          } else {
            //  console.log(4444);
            data = this.tableData.slice(row.index, this.startrow.index + 1);
          }
          this.selectionRow = data;
        } else {
          this.selectionRow = [];
          this.selectionRow.push(row);
        }
      } else {
        this.selectionRow = [];
        this.startrow = {};
        this.startrow = row;
        this.selectionRow.push(row);
      }
      // duration_cross
      let maxtimes = "";
      for (let i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i].sentence_id == row.sentence_id) {
          if (this.tableData[i + 1]) {
            if (this.caption_info.duration_cross == "0") {
              maxtimes = this.tableData[i + 1].data_start;
            } else {
              maxtimes = this.tableData[i + 1].data_end;
            }
          }
        }
      }
      if (this.startrow.maxtime !== maxtimes) {
        this.startrow.maxtime = maxtimes;
      }

      this.currentRegion = {};
      this.captionsObj = {};
      //错误列表当前句段已高亮则无需重新定位高亮
      if (
        (!!this.tabrowData.sentence_id &&
          row.sentence_id != +this.tabrowData.sentence_id) ||
        this.tabrowData.sentence_id == undefined
      ) {
        this.tabrowData = {};
        this.setTabOrCommentsRowData(row);
      }
      this.Jumpposition(this.startrow, 1);
      if (this.find_tr_index !== this.startrow.index) {
        this.find_cur_index = -1;
      }
      this.find_tr_index = this.startrow.index;
      this.showloopbtn = true;
      if (+this.startrow.data_start > this.silderMax) {
        return;
      }

      // console.log(this.currentRegion);
    });
    this.$on("tabrowclick", (row) => {
      let num = row.index || row.number;
      this.tabrowData = row;
      this.tableData.forEach((i, index) => {
        if (row.sentence_id == i.sentence_id) {
          this.Jumpposition(i);
          this.setselectionRow(i);
          this.jumpgo(index + 1);
          this.srtTrCurIndex(index);
        }
      });
    });
    /**
     * 清除 句段样式
     */
    this.$off("clearTags").$on("clearTags", (row) => {
      let val = "";
      if (row.data == 1) {
        if (row.sentence_source) {
          row.sentence_source = row.sentence_source
            .replace(
              /<\/?b>|<\/?i>|<\/?u>|♫|♪|\[|\]|\(|\)|\（|\）|{\\an[789456123]}/gi,
              ""
            )
            .trim();
          row.sentence_source_html = row.sentence_source_html
            .replace(
              /<\/?b>|<\/?i>|<\/?u>|♫|♪|\[|\]|\(|\)|\（|\）|{\\an[789456123]}/gi,
              ""
            )
            .trim();
        }
        val = row.sentence_source;
      } else {
        if (row.sentence_trans) {
          row.sentence_trans = row.sentence_trans
            .replace(
              /<\/?b>|<\/?i>|<\/?u>|♫|♪|\[|\]|\(|\)|\（|\）|{\\an[789456123]}/gi,
              ""
            )
            .trim();
          row.sentence_trans_html = row.sentence_trans_html
            .replace(
              /<\/?b>|<\/?i>|<\/?u>|♫|♪|\[|\]|\(|\)|\（|\）|{\\an[789456123]}/gi,
              ""
            )
            .trim();
        }
        val = row.sentence_trans;
      }
      row = this.setNumbererr(row, row.data, val);
      // }
    });
    /**
     * 给字幕添加样式
     */
    this.$off("addTag").$on(
      "addTag",
      (tag, flag, row, selectedText, start, end) => {
        let newText = "";
        let val = "";
        if (flag == 1) {
          newText = `<${tag}>${selectedText}</${tag}>`;
        } else if (flag == 3) {
          switch (tag) {
            case "1":
              newText = `（${selectedText}）`;
              break;
            case "2":
              newText = `(${selectedText})`;
              break;
            default:
              newText = `[${selectedText}]`;
              break;
          }
        } else {
          newText = `${tag}${selectedText}${tag}`;
        }
        if (row.data == 1) {
          row.sentence_source =
            row.sentence_source.slice(0, start) +
            newText +
            row.sentence_source.slice(end);
          row.sentence_source_html =
            row.sentence_source_html.slice(0, start) +
            newText +
            row.sentence_source_html.slice(end);
          val = row.sentence_source;
        } else {
          row.sentence_trans =
            row.sentence_trans.slice(0, start) +
            newText +
            row.sentence_trans.slice(end);
          row.sentence_trans_html =
            row.sentence_trans_html.slice(0, start) +
            newText +
            row.sentence_trans_html.slice(end);
          val = row.sentence_trans;
        }
        row = this.setNumbererr(row, row.data, val);
      }
    );
  },
  beforeDestroy() {
    window.removeEventListener("mousedown", this.windowmousedown, true);
    window.removeEventListener("keydown", this.windowkeydown, true);
    window.removeEventListener("keyup ", this.windowkeyup, true);
    this.$off("cellPClick");
    this.$off("textareaBlue");
    this.$off("textareafocus");
    this.$off("inputBlue");
    this.$off("textareainput");
    this.$off("tabrowclick");
    this.$off("handlePaste");
    this.$off("addTag");
    this.$off("clearTags");
  },
  name: "VideoDetails",
  components: {
    DialogComVue,
    HelpContent,
    "virtual-list": VirtualList,
    LocalDaub,
    SubtitleStyle,
  },
  data() {
    return {
      showlanguage: "", //获取 页面中英文
      isMoveLeft: true,
      maxScaleX: 1,
      isMoveTop: true,
      maxScaleY: 1,
      canvas: null,
      downPoint: null, // 按下鼠标时的坐标
      upPoint: null, // 松开鼠标时的坐标
      rect: null,
      showCanvas: false,
      DragPosition: 0, //判断是否 存在拖拽后的值
      showotherclosebtn: false,
      radiolist: [],
      form5: {
        topradio: "1",
        time: "00:00:00.000",
        btmradio1: "1",
      }, //平移时间轴数据
      jflag: false, //快捷键j 是否触发
      jstart: "", //快捷键j 开始时间
      jend: "", //快捷键j 结束时间
      showLookup: true, //输入框获取焦点  不显示查找替换按钮
      isMatching: true,
      hasPrinted: false,
      currentIndex: 0,
      batchSize: 20,
      processing: false,
      matchedObjects: new Set(),
      // ==============================
      muteSlidervalue: 100,
      gospeek: false, // 点击 快件 回退按钮  不走 波形赋值时间 事件
      showMarginRight6: true,
      commentsItem: VideoDetialsItem,
      tabTablebody: tabTablebody,
      iconFlag: false,
      visible: false,
      popoverInput: "", //按 id 查找句段
      info: {},
      dataInfo: {}, //整体 的 info 信息
      caption_info: {}, //字幕规则 info数据
      rowData: {},
      tabrowData: {}, // 错误检查 tab 表格点击 数据
      job_id: "", //获取 视频 id
      maxHeight: 400,

      dialogtitle: "", //弹窗 标题
      dialogVisible: false, //是否开启弹窗
      dialogWidth: "",
      footerbtnName: "", //弹窗 确定 按钮  更换名称
      closefooterbtnName: "", //弹窗 取消 按钮  更换名称
      Glossariestab1: {
        //字幕规则设置
        min_duration: "",
        max_duration: "",
        minigap_time: "",
        minigap_type: "",
        langs: [],
        duration_cross: "",
      },
      Glossariestab2: {
        // 禁用字符
        radio: "0",
        value: true,
        textarea: "",
      },
      Glossariestabs3: [], //错误检查
      Glossariestabs4: [], //错误 检查 源语言 目标语言切换
      checkboxList: [],
      checkboxList1: [], //错误检查 译文 数组
      checkboxLists: [
        {
          label: "1", //空句段
          name: this.$t("OverwriteOperation.EmptySegment"),
        },
        {
          label: "2", //时间轴时长
          name: this.$t("OverwriteOperation.SubtitleDuration"),
        },
        {
          label: "4", //读速
          name: this.$t("OverwriteOperation.ReadingSpeed"),
        },
        {
          label: "7", //时间轴重叠
          name: this.$t("OverwriteOperation.TimeCodesOverlap"),
        },
        {
          label: "8", //时间轴最小间隔
          name: this.$t("OverwriteOperation.MiniGap"),
        },
        {
          label: "3", //字符数限制
          name: this.$t("OverwriteOperation.CharacterLimitation"),
        },
        // {
        //   label: "5", //拼写和语法
        //   name: this.$t("OverwriteOperation.SpellingAndGrammarCheck"),
        // },
        {
          label: "6", //禁用字符
          name: this.$t("OverwriteOperation.Forbiddencharacter"),
        },
        // {
        //   label: "9", //双空格
        //   name: this.$t("OverwriteOperation.DoubleBlank"),
        // },
        {
          label: "10", //数字不匹配
          name: this.$t("OverwriteOperation.MissingNumber"),
        },
        {
          label: "11", //译文不一致
          name: this.$t("OverwriteOperation.InconsistentTranslation"),
        },
        {
          label: "13",
          name: this.$t("OverwriteOperation.StyleConsistency"),
        },
        {
          label: "14",
          name: this.$t("OverwriteOperation.StyleAccuracy"),
        },
      ],
      currentrow: true, //是否高亮显示table
      showcurrent: "", //table row id
      title: "", //头部名称
      showtitleflag: true, //点击 显示修改 名称
      icon: "", //头部 图标
      slidervalue: 0, //当前步
      step: 0.01, //步长
      silderMax: 0, //总步长
      subtitleradio: "0", //是否显示字幕 1不显示 2显示 字幕
      videoStartTime: "00:00:00.00",
      videolangtime: "00:00:00.00", //总时长
      fullscreen: true, //判断是否全屏
      mute: true, //是否静音
      videoDom: null, //波形实例
      player: true, // 播放暂停
      ds: 1.0, // 倍数
      tabname: "second", //tab页切换
      tabTableData: [],
      showTM: false, //是否显示TM
      inputchange: true, //显示查找 模式
      select: "1", //当有下拉框的时候选择语言
      find_select: "1", //当前查找原文还是译文
      replaceInput: "", //替换 输入框 内容
      lookupinput: "", //查找输入框 输入内容
      find_tr_index: -1,
      find_cur_index: -1,
      find_cur_tr_data: {}, //当前查找到正在高亮的数据
      checkboxGroup: [],
      currentRegion: {},
      // 选中区域
      regionOption: {
        id: "",
        start: "", // 选中区域开始时间
        end: "", // 选中区域开始时间
        loop: false, // 播放时是否循环播放片段
        drag: false, // 允许/禁止拖动区域
        resize: true, // 允许/禁止调整区域大小
        color: " rgba(70, 173, 255, 0.5)", // 选中区块颜色
        minLength: 0.08,
      },
      segment: null, //区域实例
      barHeight: 1.5,
      height: 0,
      zooms: 50, //设置波形 伸展收缩
      Reginleft: [], // 储存距离值
      addRegion: [],
      minPxPerSec: 50,
      tableData: [],
      videoUrl: "", //视频 路径
      offset: "",
      playflag: false,
      tableflag: false,
      count: 50,
      handleDropdownShow1: false, //鼠标划上 弹出框 固定项 显示隐藏
      handleDropdownShow2: false, //鼠标划上 弹出框 固定项 显示隐藏（字幕设置）
      handleDropdownShow3: false, //鼠标划上 弹出框 固定项 显示隐藏（括号）
      handleDropdownShowData: [
        {
          id: 1,
          name: `${this.$t("SubtitleStyle.Up")} / ${this.$t(
            "SubtitleStyle.Left"
          )}`,
          iconClass: "upper_l",
        },
        {
          id: 2,
          name: `${this.$t("SubtitleStyle.Up")} / ${this.$t(
            "SubtitleStyle.centre"
          )}`,
          iconClass: "upper_m",
        },
        {
          id: 3,
          name: `${this.$t("SubtitleStyle.Up")} / ${this.$t(
            "SubtitleStyle.Right"
          )}`,
          iconClass: "upper_r",
        },
        {
          id: 4,
          name: `${this.$t("SubtitleStyle.middle")} / ${this.$t(
            "SubtitleStyle.Left"
          )}`,
          iconClass: "middle_l",
        },
        {
          id: 5,
          name: `${this.$t("SubtitleStyle.middle")} / ${this.$t(
            "SubtitleStyle.centre"
          )}`,
          iconClass: "middle_m",
        },
        {
          id: 6,
          name: `${this.$t("SubtitleStyle.middle")} / ${this.$t(
            "SubtitleStyle.Right"
          )}`,
          iconClass: "middle_r",
        },
        {
          id: 7,
          name: `${this.$t("SubtitleStyle.Down")} / ${this.$t(
            "SubtitleStyle.Left"
          )}`,
          iconClass: "bottom_l",
        },
        {
          id: 8,
          name: `${this.$t("SubtitleStyle.Down")} / ${this.$t(
            "SubtitleStyle.centre"
          )}`,
          iconClass: "bottom_m",
        },
        {
          id: 9,
          name: `${this.$t("SubtitleStyle.Down")} / ${this.$t(
            "SubtitleStyle.Right"
          )}`,
          iconClass: "bottom_r",
        },
      ], //设置字幕位置
      hideOnClick: true, //是否 点击 下拉框内 关闭下拉框
      dropdownTag: "", //点击 赋值 大小音乐符号
      dropdownTag1: "", //点击赋值 字幕位置
      dropdownTag2: "", //点击赋值 括号
      dropdownType: 0, //点击 赋值 大小音乐符号
      dropdownType1: 0, //点击赋值 字幕位置
      dropdownType2: 0, //点击赋值 括号
      currentRegionInterval: null,
      // showloopbtn: false, //单句 播放 切换 按钮
      captionsObj: {},
      showloopbtn: true, //true 显示 播放 false 显示暂停 播放区域 图标切换
      loopPlay: false, // 判断是否 播放 区域
      current_sentence_id: 0, //高亮句段id
      current_peek_time: 0,
      gostart: false, //判断是否 是的、波形跳转 第一次
      showWarningNumber: false, //是否显示 warning 数量
      showWarningNumbernum: 0,
      smalldivtops: 0, // 小屏 高度
      bigdivtops: 0, //大屏 高度
      bigdivtopsClick: false,
      steptabTable: 4, //判断 是哪个 点击事件
      showfooter: true, //是否显示 下面按钮
      textareaBlues: false, //判断是否在输入
      splitFlag: false, //判断是否 是 可编辑状态下 能否进行剪切
      deleteFlag: false, // 判断 是否 是删除  删除 则不触发保存
      verifyname: "1", //错误 检查 弹窗 切换
      ShiftDown: false, //判断是否 按下shift 健
      selectionRow: [], // 获取 多选 数组
      startrow: {}, // 第一次 点击 的 row
      startIndex: 0,
      videoHeight: 0,
      LocalDaubVisible: false, //局部涂抹弹层显示
      SubStyleVisible: false, //字幕设置弹层显示
      switchDaubVisible: false, //切换涂抹食品
      videoUrl1: "", //涂抹视频  dubbing_smearing_path
      daubInterval: null,
      dubbingInterval: null,
      video_daub_minute_limit: "",
    };
  },
  /**
   * 拖拽
   */
  directives: {
    focus(el) {
      // console.log(el.querySelector("textarea"));
      setTimeout(() => {
        if (el.querySelector("textarea")) {
          el.querySelector("textarea").focus();
        } else {
          el.querySelector("input").focus();
        }
      });
    },
    drag(el, binding, vnode) {
      el.style.cursor = "move";
      const instance = vnode.context;
      el.onmousedown = function (e) {
        var disx = e.pageX - el.offsetLeft;
        var disy = e.pageY - el.offsetTop;

        if (e.preventDefault) {
          e.preventDefault();
        } else {
          e.returnValue = false; // 解决快速频繁拖动滞后问题
        }

        document.onmousemove = function (e) {
          el.style.bottom = "auto";
          // 鼠标位置-鼠标相对元素位置=元素位置
          let left = e.clientX - disx;
          let top = e.clientY - disy;

          // 限制拖拽范围不超出可视区
          if (left <= 0) {
            left = 0; // 设置成5,离边缘不要太近
          } else if (
            left >
            // document.documentElement.clientWidth - el.clientWidth
            document.getElementById("video1").clientWidth - el.clientWidth
          ) {
            // document.documentElement.clientWidth屏幕可视区宽度
            left =
              document.getElementById("video1").clientWidth - el.clientWidth;
          }
          if (top <= 0) {
            top = 5;
          } else if (
            top >
            // document.documentElement.clientHeight - el.clientHeight
            document.getElementById("video1").clientHeight - el.clientHeight
          ) {
            top =
              document.getElementById("video1").clientHeight - el.clientHeight;
          }
          el.style.left = left + "px";
          el.style.top = top + "px";
          instance.DragPosition = top;
        };

        document.onmouseup = function () {
          document.onmousemove = document.onmouseup = null;
        };
      };
    },
  },
  watch: {},

  mounted() {
    // this.$nextTick(() => {
    //   let height = document.getElementById("waveform");
    //   this.height = height.offsetHeight;
    //   // console.log(this.wavesurfer);
    // });
    // this.$nextTick(() => {
    //   this.tableData.forEach((i) => {
    //     this.setRegion(i);
    //   });
    // });
    this.init_info();
    window.addEventListener("mousedown", this.windowmousedown, true);
    window.addEventListener("keydown", this.windowkeydown, true);
    window.addEventListener("keyup", this.windowkeyup, true);
    window.onresize = () => {
      if (this.showCanvas) {
        this.closeCanvas();
      }
      let div = document.getElementById("left_video_div_div");
      let videodiv = document.getElementById("video1").clientHeight;
      if (!checkFull()) {
        //要执行的动作
        this.bigdivtopsClick = false;
        this.fullscreen = true;
      }
      let top = 0;
      div.style.bottom = "auto";
      if (!this.fullscreen) {
        // 变大
        top = div.offsetTop * Number((videodiv / this.videoHeight).toFixed(2));
      } else {
        // 缩小;
        top = div.offsetTop / Number((this.videoHeight / videodiv).toFixed(2));
      }
      this.$nextTick(() => {
        if (top + div.clientHeight > videodiv) {
          top = videodiv - div.clientHeight;
        }
        div.style.top = Number(top.toFixed(2)) + "px";
      });
      // this.$nextTick(() => {
      //
      // });

      this.videoHeight = videodiv;

      function checkFull() {
        var isFull = true;
        if (
          document.fullscreenEnabled === false ||
          window.fullScreen === false ||
          document.webkitIsFullScreen === false ||
          document.msFullscreenEnabled === false
        ) {
          isFull = false;
        }
        //to fix : false || undefined == undefined
        if (isFull === undefined) {
          isFull = false;
        }
        return isFull;
      }
    };
  },

  methods: {
    setTabOrCommentsRowData(row) {
      let arrs = [];
      arrs = this.tabTableData.filter((i) => i.sentence_id == row.sentence_id);

      if (arrs[0]) {
        this.tabrowData = arrs[0];
        this.$nextTick(() => {
          let tabIndex = this.tabTableData.findIndex(
            (i) => i.sentence_id === this.tabrowData.sentence_id
          );
          if (tabIndex === this.tabTableData.length) {
            this.$refs.tabTable.scrollToIndex(tabIndex);
          } else {
            this.$refs.tabTable.scrollToIndex(tabIndex - 3);
          }
        });
      }
    },
    //初始化
    init_info() {
      hideLoading();
      showLoading(this.$t("CommonName.loadingmessage"));
      // showLoading(this.$t("CommonName.AItext"));
      this.get_private_job_info().then((res) => {
        this.video_daub_minute_limit = res.info.video_daub_minute_limit;
        this.dataInfo = res.info;
        this.caption_info = res.info.caption_info;

        this.title = res.info.base_info.job_name;
        this.dataInfo.base_info.show = ["1", "7", "8"].includes(
          this.dataInfo.base_info.private_job_type
        );
        this.setCheckoutData();
        this.Glossariestab2.textarea = res.info.base_info.shield_str;
        this.videoUrl = res.info.base_info.video_path;
        this.videoUrl1 =
          !!res.info.base_info.dubbing_smearing_path == true
            ? res.info.base_info.dubbing_smearing_path
            : res.info.base_info.video_path;
        //如果正在涂抹中 需要轮询状态
        if (res.info.base_info.dubbing_smearing_status != "0") {
          this.daubingCheckStatus();
        }
        //  showLoading(this.$t("CommonName.loadingmessage"));
        // fetch(res.info.base_info.video_path)
        //   .then((response) => response.blob())
        //   .then((blob) => {
        //     this.videoUrl = URL.createObjectURL(blob);
        this.initvideo();
        if (this.dataInfo.base_info.status == "15") {
          hideLoading();
          showLoading(this.$t("CommonName.loadingtext"));
          let times = setInterval(() => {
            this.get_private_job_info().then((res) => {
              if (res.info.base_info.status != "15") {
                clearInterval(times);
                this.init_info();
              }
            });
          }, 2000);
          return;
        }
        this.init(res.info.base_info.json_path);

        // });
        this.get_private_job_data_info(this.job_id).then(() => {
          hideLoading();
          if (this.dataInfo.base_info.status == "21") {
            // hideLoading();
            showLoading(this.$t("CommonName.AItext"));
            let times = setInterval(() => {
              this.get_private_job_info().then((res) => {
                if (res.info.base_info.status != "21") {
                  clearInterval(times);
                  this.get_private_job_data_info(this.job_id).then(() => {
                    hideLoading();
                  });
                }
              });
            }, 2000);
          } else {
            hideLoading();
          }
          //生成压制视频文件中
          if (+res.info.base_info.dubbing_status == 41) {
            showLoading(this.$t("VideoDubbing.loadingDubbing13"));
            this.dubbingInterval = setInterval(() => {
              this.get_private_job_info().then((recs) => {
                console.log(recs);
                if (
                  recs.info.base_info.dubbing_status == 0 &&
                  recs.info.base_info.dubbing_suppression_path != ""
                ) {
                  let name = this.title;
                  this.download_method(
                    recs.info.base_info.dubbing_suppression_path,
                    name.slice(0, name.lastIndexOf(".")) + ".mp4"
                  );
                  // this.download_method(
                  //   recs.base_info.dubbing_suppression_path,
                  //   file_name
                  // );
                  clearInterval(this.dubbingInterval);
                  hideLoading();
                }
              });
            }, 10000);
          }
        });

        this.$nextTick(() => {
          let videodiv = document.getElementById("video1").clientHeight;
          this.videoHeight = videodiv;
          // console.log(res);
        });
      });
    },
    //局部涂抹
    LocalDaub() {
      if (this.dataInfo.base_info.dubbing_smearing_status == "0") {
        this.LocalDaubVisible = true;
        this.closevideo();
      }
    },
    LocalDaubMethod(data) {
      this.switchDaubVisible = false;
      this.dataInfo.base_info.dubbing_smearing_status = "1";
      this.daubSwitchChange(false);
      this.daubingCheckStatus();
      this.LocalDaubVisible = false;
    },
    HideLocalDaubVisible(data) {
      this.LocalDaubVisible = false;
    },
    SubStyleMethod(data) {
      this.SubStyleVisible = false;
    },
    HideSubStyleVisible(data) {
      this.SubStyleVisible = false;
    },
    //字幕样式设置
    TitleStyleSetting() {
      this.SubStyleVisible = true;
      this.closevideo();
    },
    //切换涂抹视频
    daubSwitchChange(value) {
      // console.log(value);
      const ref = document.querySelector(".switchClass .el-switch__input"); // 加了个clss类名
      ref.blur(); // 让el-switch失去焦点的方法
      this.closevideo();
      if (value) {
        //切换到涂抹视频
        this.videoUrl = this.dataInfo.base_info.dubbing_smearing_path;
      } else {
        //切换回原视频
        this.videoUrl = this.dataInfo.base_info.video_path;
      }
    },
    //涂抹中时需要轮询查看状态
    daubingCheckStatus() {
      this.daubInterval = setInterval(() => {
        this.get_private_job_info().then((res) => {
          if (res.info.base_info.dubbing_smearing_status == "0") {
            this.dataInfo = res.info;
            this.videoUrl1 =
              !!res.info.base_info.dubbing_smearing_path == true
                ? res.info.base_info.dubbing_smearing_path
                : res.info.base_info.video_path;
            clearInterval(this.daubInterval);
          }
        });
      }, 10000);
    },
    /**
     * 设置 带有选中 的输入框添加标签
     * @param item
     * @constructor
     */
    SetSelectedText(items, tag, flag, selectedText, start, end) {
      let targetItem = this.tableData.find(
        (item) => item.sentence_id === items.sentence_id
      );
      if (targetItem) {
        let newText = "";
        let val = "";
        if (flag == 1) {
          newText = `<${tag}>${selectedText}</${tag}>`;
        } else if (flag == 3) {
          switch (tag) {
            case "1":
              newText = `（${selectedText}）`;
              break;
            case "2":
              newText = `(${selectedText})`;
              break;
            default:
              newText = `[${selectedText}]`;
              break;
          }
        } else if (flag == 4) {
          newText = `${tag} ${selectedText} ${tag}`;
        } else {
          newText = `${tag}${selectedText}${tag}`;
        }
        if (items.data == 1) {
          targetItem.sentence_source =
            targetItem.sentence_source.slice(0, start) +
            newText +
            targetItem.sentence_source.slice(end);
          targetItem.sentence_source_html =
            targetItem.sentence_source_html.slice(0, start) +
            newText +
            targetItem.sentence_source_html.slice(end);
          val = targetItem.sentence_source;
        } else {
          targetItem.sentence_trans =
            targetItem.sentence_trans.slice(0, start) +
            newText +
            targetItem.sentence_trans.slice(end);
          targetItem.sentence_trans_html =
            targetItem.sentence_trans_html.slice(0, start) +
            newText +
            targetItem.sentence_trans_html.slice(end);
          val = targetItem.sentence_trans;
        }
        targetItem = this.setNumbererr(targetItem, items.data, val);
        let textarea = "";
        this.$refs.virtualref.$children.forEach((j) => {
          this.$nextTick(() => {
            if (items.data == 1) {
              textarea =
                j.$children[0].$refs[targetItem.sentence_id + "textareasource"];
            } else {
              textarea =
                j.$children[0].$refs[targetItem.sentence_id + "textareatrans"];
            }
            if (!textarea) {
              return;
            }
            // console.log(textarea);
            textarea.style.height = "auto";
            textarea.style.height = `${textarea.scrollHeight}px`;
          });
        });
      }
    },
    /**
     * 获取 选中 开始位置 结束位置 以及 选中文字
     * @param item
     * @returns {{selectionStart: number, selectedText: string, selectionEnd: number}}
     */
    getStartEndandText(item) {
      let selectionStart = 0;
      let selectionEnd = 0;
      let childrenRef = "";
      let selectedText = "";
      this.$refs.virtualref.$children.forEach((i) => {
        if (item.data == 1) {
          childrenRef =
            i.$children[0].$refs[item.sentence_id + "textareasource"];
          if (!childrenRef) {
            return;
          }
          selectionStart = childrenRef.selectionStart;
          selectionEnd = childrenRef.selectionEnd;
          selectedText = item.sentence_source.substring(
            selectionStart,
            selectionEnd
          );
        } else {
          childrenRef =
            i.$children[0].$refs[item.sentence_id + "textareatrans"];
          if (!childrenRef) {
            return;
          }
          selectionStart = childrenRef.selectionStart;
          selectionEnd = childrenRef.selectionEnd;

          selectedText = item.sentence_trans.substring(
            selectionStart,
            selectionEnd
          );
        }
        // childrenRef.style.height = "auto";
        // childrenRef.style.height = `${childrenRef.scrollHeight}px`;
        // console.log(childrenRef.style.height);
      });
      return { selectionStart, selectionEnd, selectedText };
    },
    /**
     *
     * @param { 监听 DOM 元素的变化} entries
     */
    updateElementWidth() {
      let className = !this.dataInfo.base_info.show ? "scroller58" : "scroller";
      // console.log(className);
      var obj = document.getElementById(className);
      if (
        obj.scrollHeight > obj.clientHeight ||
        obj.offsetHeight > obj.clientHeight
      ) {
        this.showMarginRight6 = true;
      } else {
        this.showMarginRight6 = false;
      }
    },
    /**
     *
     * @param {*使用正则表达式匹配时间格式} time
     */
    validateTimeFormat(time) {
      // 使用正则表达式匹配时间格式
      var regex = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)[.,]\d{2,3}$/;
      // 检查时间是否符合格式要求
      return regex.test(time);
    },
    /**
     *
     * @param {*修改 显示} row
     */
    setselectionRow(row) {
      this.selectionRow = [];
      this.startrow = {};
      this.selectionRow.push(row);
      this.startrow = row;
    },
    /**
     *
     * @param {* 判断 时间 格式 两位或三位} data
     */
    setgetTimeString(data) {
      let dig = 0;
      if (this.dataInfo.base_info.dig === "2") {
        dig = 2;
      } else {
        dig = 3;
      }
      return getTimeString(data, 1, dig).replace(".", ",");
    },
    /**
     *
     * @param {*键盘 按下事件} e
     */
    windowkeydown(e) {
      const nodeName = e.target.nodeName;
      //局部涂抹和字幕设置时 按键无效
      if (this.LocalDaubVisible || this.SubStyleVisible) {
        return;
      }
      if (e.shiftKey && (e.ctrlKey || e.altKey)) {
        if (this.ShiftDown) {
          this.ShiftDown = false;
        }
      }
      if (e.keyCode == 16 && !e.ctrlKey && !e.altKey) {
        e.stopPropagation();
        e.returnValue = false;
        this.ShiftDown = true;
      }
      //空格
      if (e.keyCode == 0 || e.keyCode == 32) {
        if (
          (nodeName && nodeName.toUpperCase() == "INPUT") ||
          (nodeName && nodeName.toUpperCase() == "TEXTAREA")
        ) {
          return;
        }
        e.preventDefault();
        if (this.player) {
          if (this.loopPlay) {
            this.showloopbtn = false;
          }
          this.videoDom.play();
          this.setTimeInterval(); //开启定时器
          // this.wavesurferaudioprocess();
        } else {
          if (this.loopPlay) {
            this.showloopbtn = true;
          }
          this.videoDom.pause();
          clearInterval(this.currentRegionInterval);
          this.currentRegionInterval = null;
        }
        this.loopPlay = false;
        this.player = !this.player;
      }

      // w复制
      if (e.keyCode === 87) {
        // console.log(this.textareaBlues);
        if (this.textareaBlues) {
          return;
        }
        // this.CombineTime = getTimeString(this.videoDom.currentTime, 1, 3);
        this.form5.time = this.setgetTimeString(this.videoDom.currentTime);
        copyClick(this.setgetTimeString(this.videoDom.currentTime));
      }
      // 单句播放
      if (e.keyCode == 119) {
        if (!this.segment) {
          return;
        }
        if (this.selectionRow.length > 1) {
          return;
        }
        if (!this.currentRegion.sentence_id) {
          return;
        }
        if (this.showloopbtn) {
          this.loopPlay = true;
          this.player = false;
          this.showloopbtn = false;
          this.videoDom.play();
          this.setTimeInterval();
        } else {
          clearInterval(this.currentRegionInterval);
          this.currentRegionInterval = null;
          this.player = true;
          this.showloopbtn = true;
          this.videoDom.pause();
        }
      }
      // s=">83"选中句段，非编辑状态下，s调整句段/波形开始时间到当前播放位置
      if (e.keyCode == 83 && !e.altKey) {
        if (!this.segment) {
          return;
        }
        if (!this.currentRegion.sentence_id) {
          return;
        }
        if (this.selectionRow.length > 1) {
          return;
        }
        if (!this.player) {
          return;
        }
        if (this.textareaBlues) {
          return;
        }
        for (let index = 0; index < this.tableData.length; index++) {
          if (
            this.currentRegion.sentence_id == this.tableData[index].sentence_id
          ) {
            this.tableData[index].data_start = parseFloat(
              this.videoDom.currentTime.toFixed(3)
            );
            this.currentRegion = this.tableData[index];
            this.setselectionRow(this.currentRegion);
            this.captionsObj = this.currentRegion;
            this.videoDom.currentTime = this.currentRegion.data_start;
            this.setRegion(this.tableData[index]);
            this.set_time_code(this.tableData[index]);
            this.tableData[index].start_time = getTimeString(
              this.tableData[index].data_start,
              1,
              3
            ).replace(".", ",");

            return;
          }
        }
      }
      // e=>69e调整句段/波形结束时间到当前播放位置
      if (e.keyCode == 69) {
        if (!this.segment) {
          return;
        }
        if (!this.currentRegion.sentence_id) {
          return;
        }
        if (this.selectionRow.length > 1) {
          return;
        }
        if (!this.player) {
          return;
        }
        if (this.textareaBlues) {
          return;
        }
        for (let index = 0; index < this.tableData.length; index++) {
          if (
            this.currentRegion.sentence_id == this.tableData[index].sentence_id
          ) {
            this.tableData[index].data_end = parseFloat(
              this.videoDom.currentTime.toFixed(3)
            );
            this.currentRegion = this.tableData[index];
            this.setselectionRow(this.currentRegion);
            this.captionsObj = this.currentRegion;
            this.videoDom.currentTime = this.currentRegion.data_end - 0.0001;
            this.setRegion(this.tableData[index]);
            this.set_time_code(this.tableData[index]);
            this.tableData[index].end_time = getTimeString(
              this.tableData[index].data_end,
              1,
              3
            ).replace(".", ",");
            return;
          }
        }
      }
      //alt+s 快捷键 剪切

      if (e.altKey && e.keyCode === 83) {
        if (!this.currentRegion.sentence_id) {
          return;
        }
        if (!this.splitFlag) {
          return;
        }
        if (this.selectionRow.length > 1) {
          return;
        }
        this.$refs.virtualref.$children.forEach((i) => {
          if (!this.dataInfo.base_info.show) {
            // console.log(this.currentRegion.data);
            if (this.currentRegion.data == 1) {
              if (
                !i.$children[0].$refs[
                  this.currentRegion.sentence_id + "textareasource"
                ]
              ) {
                return;
              }
              this.offset =
                i.$children[0].$refs[
                  this.currentRegion.sentence_id + "textareasource"
                ].selectionStart;
            } else {
              if (
                !i.$children[0].$refs[
                  this.currentRegion.sentence_id + "textareatrans"
                ]
              ) {
                return;
              }
              this.offset =
                i.$children[0].$refs[
                  this.currentRegion.sentence_id + "textareatrans"
                ].selectionStart;
            }
          } else {
            if (
              !i.$children[0].$refs[
                this.currentRegion.sentence_id + "textareasource"
              ]
            ) {
              return;
            }
            this.offset =
              i.$children[0].$refs[
                this.currentRegion.sentence_id + "textareasource"
              ].selectionStart;
          }

          if (!this.offset) {
            return;
          }
          showLoading(this.$t("CommonName.loadingmessage"));
          this.split_segment(this.currentRegion);
        });
      }
      // CTRL+DELETE 删除 快捷键
      if (e.ctrlKey && e.keyCode === 46) {
        if (!this.currentRegion.sentence_id) {
          return;
        }
        showLoading(this.$t("CommonName.loadingmessage"));
        this.deleteFlag = true;
        this.delete_segment(this.selectionRow, this.selectionRow[0].data_start);
      }
      // 38=> 上 40 =》下
      // ctrl 加 上 健 快捷键  使 高亮 到上一句
      if (e.ctrlKey && e.keyCode === 38) {
        if (!this.currentRegion.sentence_id) {
          return;
        }
        if (!this.player) {
          return;
        }
        if (this.selectionRow.length > 1) {
          return;
        }
        if (this.textareaBlues) {
          return;
        }
        for (let index = 0; index < this.tableData.length; index++) {
          if (
            this.currentRegion.sentence_id == this.tableData[index].sentence_id
          ) {
            if (Number(this.tableData[index].data_start) > this.silderMax) {
              this.currentRegion = this.tableData[index - 1];
              this.setselectionRow(this.currentRegion);
              this.captionsObj = this.currentRegion;
              this.jumpgo(index);
              this.srtTrCurIndex(index);
              return;
            }
            if (
              this.currentRegion.sentence_id == this.tableData[0].sentence_id
            ) {
              return;
            }
            this.currentRegion = this.tableData[index - 1];
            this.setselectionRow(this.currentRegion);
            this.captionsObj = this.currentRegion;
            this.videoDom.currentTime = this.currentRegion.data_start;
            this.setRegion(this.tableData[index - 1]);
            this.jumpgo(index);
            this.srtTrCurIndex(index);
            return;
          }
        }
      }
      // ctrl 加 下 健 快捷键  使 高亮 到下一句
      if (e.ctrlKey && e.keyCode === 40) {
        if (!this.currentRegion.sentence_id) {
          return;
        }
        if (!this.player) {
          return;
        }
        if (this.selectionRow.length > 1) {
          return;
        }
        if (this.textareaBlues) {
          return;
        }
        for (let index = 0; index < this.tableData.length; index++) {
          if (
            this.currentRegion.sentence_id == this.tableData[index].sentence_id
          ) {
            if (Number(this.tableData[index + 1].data_start) > this.silderMax) {
              this.currentRegion = this.tableData[index + 1];
              this.setselectionRow(this.currentRegion);
              this.captionsObj = this.currentRegion;
              this.jumpgo(index + 2);
              this.srtTrCurIndex(index);
              return;
            }
            if (
              this.currentRegion.sentence_id ==
              this.tableData[this.tableData.length - 1].sentence_id
            ) {
              return;
            }
            this.currentRegion = this.tableData[index + 1];
            this.setselectionRow(this.currentRegion);
            this.captionsObj = this.currentRegion;
            this.videoDom.currentTime = this.currentRegion.data_start;
            this.setRegion(this.tableData[index + 1]);
            this.jumpgo(index + 2);
            this.srtTrCurIndex(index);
            return;
          }
        }
      }
      // ctrl 加 enter 回车健  保存当前句 跳到下一句 下一句 获取焦点
      if (e.ctrlKey && e.keyCode === 13) {
        if (!this.currentRegion.sentence_id) {
          return;
        }
        if (this.selectionRow.length > 1) {
          return;
        }
        if (!this.player) {
          return;
        }
        if (!this.textareaBlues) {
          return;
        }
        for (let index = 0; index < this.tableData.length; index++) {
          if (
            this.currentRegion.sentence_id == this.tableData[index].sentence_id
          ) {
            if (
              this.currentRegion.sentence_id ==
              this.tableData[this.tableData.length - 1].sentence_id
            ) {
              return;
            }
            if (Number(this.tableData[index + 1].data_start) > this.silderMax) {
              if (this.dataInfo.base_info.show) {
                this.tableData[index + 1].showsourceInput = true;
              } else {
                if (this.currentRegion.data == 1) {
                  this.tableData[index + 1].showsourceInput = true;
                } else {
                  this.tableData[index + 1].showtransInput = true;
                }
              }
              this.currentRegion = this.tableData[index + 1];
              this.setselectionRow(this.currentRegion);
              this.captionsObj = this.currentRegion;
              this.jumpgo(index + 2);
              this.srtTrCurIndex(index);
              return;
            }

            if (this.dataInfo.base_info.show) {
              this.tableData[index + 1].showsourceInput = true;
            } else {
              if (this.currentRegion.data == 1) {
                this.tableData[index + 1].showsourceInput = true;
              } else {
                this.tableData[index + 1].showtransInput = true;
              }
            }
            // this.tableData[index + 1].showsourceInput = true;
            this.currentRegion = this.tableData[index + 1];
            this.setselectionRow(this.currentRegion);
            this.captionsObj = this.currentRegion;
            this.videoDom.currentTime = this.currentRegion.data_start;
            this.setRegion(this.tableData[index + 1]);
            this.jumpgo(index + 2);
            this.srtTrCurIndex(index);
            return;
          }
        }
      }
      // ctrl + m
      if (e.ctrlKey && e.keyCode == 77) {
        if (this.dataInfo.base_info.show) {
          return;
        }
        this.toolClick(6);
      }
      // ctrl + b 加粗
      if (e.ctrlKey && e.keyCode === 66) {
        if (!this.currentRegion.sentence_id) {
          return;
        }
        let obj = this.getStartEndandText(this.currentRegion);
        if (
          obj.selectionStart !== obj.selectionEnd &&
          obj.selectedText !== ""
        ) {
          this.SetSelectedText(
            this.currentRegion,
            "b",
            1,
            obj.selectedText,
            obj.selectionStart,
            obj.selectionEnd
          );
          this.popoverVisibles = Math.random();
        } else {
          this.statuscommand({ id: 2, tag: "b", type: 3 });
        }
      }
      // ctrl + i 斜体
      if (e.ctrlKey && e.keyCode === 73) {
        if (!this.currentRegion.sentence_id) {
          return;
        }
        let obj = this.getStartEndandText(this.currentRegion);
        if (
          obj.selectionStart !== obj.selectionEnd &&
          obj.selectedText !== ""
        ) {
          this.SetSelectedText(
            this.currentRegion,
            "i",
            1,
            obj.selectedText,
            obj.selectionStart,
            obj.selectionEnd
          );
          this.popoverVisibles = Math.random();
        } else {
          this.statuscommand({ id: 0, tag: "i", type: 1 });
        }
      }
      // alt + u 下划线
      if (e.altKey && e.keyCode === 85) {
        if (!this.currentRegion.sentence_id) {
          return;
        }
        let obj = this.getStartEndandText(this.currentRegion);
        if (
          obj.selectionStart !== obj.selectionEnd &&
          obj.selectedText !== ""
        ) {
          this.SetSelectedText(
            this.currentRegion,
            "u",
            1,
            obj.selectedText,
            obj.selectionStart,
            obj.selectionEnd
          );
          this.popoverVisibles = Math.random();
        } else {
          this.statuscommand({ id: 1, tag: "u", type: 2 });
        }
      }
      //alt + n 小音乐符号
      if (e.altKey && e.keyCode === 78 && !e.shiftKey) {
        if (!this.currentRegion.sentence_id) {
          return;
        }
        let obj = this.getStartEndandText(this.currentRegion);
        if (
          obj.selectionStart !== obj.selectionEnd &&
          obj.selectedText !== ""
        ) {
          this.SetSelectedText(
            this.currentRegion,
            "♪",
            2,
            obj.selectedText,
            obj.selectionStart,
            obj.selectionEnd
          );
          this.popoverVisibles = Math.random();
        } else {
          this.statuscommand({ id: 3, tag: "♪", type: 4 });
        }
      }
      //alt + m 大音乐符号
      if (e.altKey && e.keyCode === 77 && !e.shiftKey) {
        if (!this.currentRegion.sentence_id) {
          return;
        }
        let obj = this.getStartEndandText(this.currentRegion);
        if (
          obj.selectionStart !== obj.selectionEnd &&
          obj.selectedText !== ""
        ) {
          this.SetSelectedText(
            this.currentRegion,
            "♫",
            2,
            obj.selectedText,
            obj.selectionStart,
            obj.selectionEnd
          );
          this.popoverVisibles = Math.random();
        } else {
          this.statuscommand({ id: 3, tag: "♫", type: 5 });
        }
      }
      //Alt + Shift + N 小音乐符号带空格
      if (e.altKey && e.shiftKey && e.keyCode === 78) {
        if (!this.currentRegion.sentence_id) {
          return;
        }
        let obj = this.getStartEndandText(this.currentRegion);
        if (
          obj.selectionStart !== obj.selectionEnd &&
          obj.selectedText !== ""
        ) {
          this.SetSelectedText(
            this.currentRegion,
            "♪",
            4,
            obj.selectedText,
            obj.selectionStart,
            obj.selectionEnd
          );
          this.popoverVisibles = Math.random();
        } else {
          this.statuscommand({ id: 3, tag: "♪", type: 15 });
        }
      }
      //大音乐符号带空格 Alt + Shift + M
      if (e.altKey && e.shiftKey && e.keyCode === 77) {
        if (!this.currentRegion.sentence_id) {
          return;
        }
        let obj = this.getStartEndandText(this.currentRegion);
        if (
          obj.selectionStart !== obj.selectionEnd &&
          obj.selectedText !== ""
        ) {
          this.SetSelectedText(
            this.currentRegion,
            "♫",
            4,
            obj.selectedText,
            obj.selectionStart,
            obj.selectionEnd
          );
          this.popoverVisibles = Math.random();
        } else {
          this.statuscommand({ id: 3, tag: "♫", type: 16 });
        }
      }
      // j 快捷键
      if (e.keyCode == 74) {
        // console.log(e.keyCode);
        if (this.jflag) {
          return;
        }
        if (this.textareaBlues) {
          return;
        }
        this.jstart = this.videoDom.currentTime.toFixed(3);
        this.jflag = true;
      }
    },
    /**
     *
     * @param {* 键盘 抬起 事件} e
     */
    windowkeyup(e) {
      if (e.keyCode == 16) this.ShiftDown = false;
      if (e.keyCode == 74) {
        this.jflag = false;
        if (this.textareaBlues) {
          return;
        }
        this.jend = this.videoDom.currentTime.toFixed(3);
        if (this.jstart == this.jend) {
          return;
        }
        if (Number(this.jstart) > Number(this.jend)) {
          return;
        }
        this.get_new_sentence_key1();
      }
    },
    /**
     *
     * @param {* 按下 事件} e
     */
    windowmousedown(e) {
      let calssNames = e.srcElement.className;
      if (
        calssNames == "headerrow" ||
        calssNames == "titlediv" ||
        calssNames == "top"
      ) {
        if (this.player) {
          this.tabrowData = {};
          this.rowData = {};
          this.initPosition();
        }
      }
      if (e.srcElement.localName == "canvas") {
        this.peaksSeek();
      }
      // console.log(e);
      // this.iconFlag = false;
      if (e.srcElement.id == "SentencesPlayIcon") {
        // console.log(e.srcElement.id);
        this.iconFlag = true;
      }
      // console.log(e.srcElement);
      // if (e.srcElement.id !== "MTsvg") {
      //   this.textareafocusMT = false; //设置MT 获取焦点 触发
      // }
      // if (e.srcElement.id !== "asrSvg") {
      //   this.textareafocusAsr = false; //设置asr 获取焦点 触发
      // }
      // if (e.srcElement.id !== "splitSvg") {
      //   this.offset = ""; //设置剪切 位置 为空
      // }
      if (e.srcElement.id !== "speek") this.gospeek = false;
      if (e.srcElement.id !== "rew") this.gospeek = false;
    },

    /**
     * 设置  头部 操作按钮
     */
    setCheckoutData() {
      if (this.dataInfo.base_info.show) {
        // console.log(1111111);
        this.Glossariestabs4 = [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "14",
        ];
        this.checkboxList = [];
        this.checkboxLists.forEach((item) => {
          if (
            ["1", "2", "3", "4", "5", "6", "7", "8", "9", "14"].includes(
              item.label
            )
          ) {
            this.checkboxList.push(item);
          }
        });
      } else {
        this.Glossariestabs3 = [
          "1",
          "3",
          "4",
          "5",
          "6",
          "9",
          "10",
          "11",
          "13",
          "14",
        ];
        this.Glossariestabs4 = [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "14",
        ];
        this.checkboxList = [];
        this.checkboxLists.forEach((item) => {
          if (
            ["1", "3", "4", "5", "6", "9", "10", "11", "13", "14"].includes(
              item.label
            )
          ) {
            this.checkboxList.push(item);
          }
        });
      }
    },
    /**
     * 四舍五入保留小 数字
     * @param number
     * @param decimals
     * @returns {number}
     */
    roundNumber(number, decimals) {
      const factor = 10 ** decimals;
      return Math.round(number * factor) / factor;
    },
    /**
     *
     * @param {* 设置波形 播放 时间 波形遮罩} val
     */
    setTimeInterval() {
      // clearInterval(this.currentRegionInterval);
      // this.currentRegionInterval = null;
      this.currentRegionInterval = setInterval(() => {
        let videoDomcurrentTime = this.roundNumber(
          this.videoDom.currentTime,
          3
        );
        if (this.loopPlay) {
          if (
            parseFloat(this.videoDom.currentTime.toFixed(2)) >=
            this.currentRegion.data_end
          ) {
            this.player = true;
            this.videoDom.pause();
            this.videoDom.currentTime = this.currentRegion.data_start;
            this.loopPlay = false;
            this.showloopbtn = true;
            clearInterval(this.currentRegionInterval);
          }
        }
        if (!this.player && !this.loopPlay) {
          // this.batchProcessObjects();
          // if(this.startIndex<this.tableData.length){
          //   setTimeout(()=>{
          //
          //   },50)
          // }
          // ======================================================
          // this.captionsObj = {};
          // this.peaksInstance.segments.removeAll();
          // 当this.currentRegion.index 不等于undefined，则从当前索引开始，否则从0开始
          if (this.currentRegion.index == undefined) {
            // console.log("从0开始找全部");
            for (let index = 0; index < this.tableData.length; index++) {
              if (
                videoDomcurrentTime >=
                  Number(this.tableData[index].data_start) &&
                videoDomcurrentTime <= Number(this.tableData[index].data_end)
              ) {
                if (
                  this.currentRegion &&
                  this.currentRegion.sentence_id ==
                    this.tableData[index].sentence_id &&
                  this.currentRegion.data_start ==
                    this.tableData[index].data_start &&
                  this.currentRegion.data_end == this.tableData[index].data_end
                ) {
                  if (
                    this.peaksInstance.segments._segments != undefined &&
                    this.peaksInstance.segments._segments.length > 0 &&
                    this.peaksInstance.segments._segments[0].id ==
                      this.currentRegion.sentence_id
                  ) {
                    // console.log("不用了");
                    break;
                  }
                  console.log("再生成一次", this.currentRegion.sentence_id);
                }
                this.currentRegion = this.tableData[index];
                this.setselectionRow(this.currentRegion);
                this.captionsObj = this.currentRegion;
                // if (this.current_sentence_id == this.tableData[index].sentence_id)
                //   break;
                this.setRegion(this.tableData[index]);
                this.setTabOrCommentsRowData(this.currentRegion);
                this.jumpgo(index + 1);
                this.srtTrCurIndex(index);
                return;
              } else {
                this.captionsObj = {};
              }
            }
          } else {
            const current_index = this.currentRegion.index;
            // console.log(
            //   "从" + this.currentRegion.index + "开始找" + this.batchSize
            // );
            for (
              let index = current_index;
              index < this.batchSize + current_index;
              index++
            ) {
              if (index >= this.tableData.length) {
                // console.log("结束");
                this.captionsObj = {};
                break;
              }

              if (
                videoDomcurrentTime >=
                  Number(this.tableData[index].data_start) &&
                videoDomcurrentTime <= Number(this.tableData[index].data_end)
              ) {
                if (
                  this.currentRegion &&
                  this.currentRegion.sentence_id ==
                    this.tableData[index].sentence_id &&
                  this.currentRegion.data_start ==
                    this.tableData[index].data_start &&
                  this.currentRegion.data_end == this.tableData[index].data_end
                ) {
                  if (
                    this.peaksInstance.segments._segments != undefined &&
                    this.peaksInstance.segments._segments.length > 0 &&
                    this.peaksInstance.segments._segments[0].id ==
                      this.currentRegion.sentence_id
                  ) {
                    // console.log("不用了");
                    break;
                  }
                  console.log("再生成一次", this.currentRegion.sentence_id);
                }
                this.currentRegion = this.tableData[index];
                this.setselectionRow(this.currentRegion);
                this.captionsObj = this.currentRegion;
                // if (this.current_sentence_id == this.tableData[index].sentence_id)
                //   break;
                this.setRegion(this.tableData[index]);
                this.setTabOrCommentsRowData(this.currentRegion);
                this.jumpgo(index + 1);
                this.srtTrCurIndex(index);
                return;
              } else {
                this.captionsObj = {};
              }
            }
          }
        }
      }, 30);
    },

    batchProcessObjects() {
      let that = this;

      function animate() {
        if (!that.isMatching) {
          return;
        }
        // console.log(1111111);
        let videoDomcurrentTime = that.roundNumber(
          that.videoDom.currentTime,
          3
        );
        for (const obj of that.tableData) {
          if (
            videoDomcurrentTime >= obj.data_start &&
            videoDomcurrentTime <= obj.data_end
          ) {
            // console.log(obj.number, "111111");
            that.isMatching = false;
            return;
          }
        }

        requestAnimationFrame(animate);
      }

      animate();
    },
    handleDownloadCommand(data) {
      // console.log(data);
      switch (data) {
        case "1":
          this.downloadSrt(1);
          break;
        case "2":
          this.downloadSrt(2);
          break;
        case "3":
          this.downloadSrt(3);
          break;
        case "4":
          this.downloadDuppressionClick();
          break;
        case "5":
          this.downloadSmearingVideoClick();
          break;
        default:
          break;
      }
    },
    // 点击下载字幕 1:下载原文 2:下载译文,3:下载双语
    downloadSrt(mode) {
      if (!this.title) {
        return;
      }
      showLoading(this.$t("CommonName.loadingmessage"));
      download_srt_content({
        job_id: this.job_id,
        mode,
      }).then((res) => {
        // console.log(res);
        hideLoading();
        if (res.result == "FAILED") {
          this.$messages("error", res.msg);
          return;
        }
        const blob = new Blob([res], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
        });
        let downloadElement = document.createElement("a");
        let href = window.URL.createObjectURL(blob); //创建下载的链接
        downloadElement.href = href;
        // if (this.dataInfo.base_info.show) {
        const testmsg = this.title.substring(this.title.lastIndexOf(".") + 1);
        const extension = testmsg.toLowerCase() === "mp4";
        if (extension) {
          this.title = this.title.slice(0, -4);
        }
        if (mode != "3") {
          downloadElement.download = `${this.title}.srt`; //下载后文件名
        } else {
          downloadElement.download = `${this.title}.zip`; //下载后文件名
        }

        document.body.appendChild(downloadElement);
        downloadElement.click(); //点击下载
        document.body.removeChild(downloadElement); //下载完成移除元素
        window.URL.revokeObjectURL(href);
      });
    },
    //下载压制视频
    downloadDuppressionClick() {
      if (
        parseFloat(this.videoDom.duration) >
        +this.video_daub_minute_limit * 60
      ) {
        this.$messages(
          "warning",
          this.$t("VideoDubbing.tooltipContent17") +
            this.video_daub_minute_limit +
            this.$t("VideoDubbing.tooltipContent29")
        );
        return false;
      }
      showLoading(this.$t("VideoDubbing.loadingDubbing13"));
      get_private_job_info({
        action: "download_pressing_sound_track",
        job_id: this.job_id,
      }).then((res) => {
        hideLoading();
        if (!res) {
          return;
        }
        if (res.url !== "") {
          let name = this.title;
          this.download_method(
            res.url,
            name.slice(0, name.lastIndexOf(".")) + ".mp4"
          );
        } else {
          this.$router.go(0);
        }
      });
    },
    //下载涂抹视频
    downloadSmearingVideoClick() {
      if (!!this.dataInfo.base_info.dubbing_smearing_path == true) {
        let name = this.title;
        this.download_method(
          this.dataInfo.base_info.dubbing_smearing_path,
          name.slice(0, name.lastIndexOf(".")) + ".mp4"
        );
      } else {
        this.$messages(
          "warning",
          this.$t("PersonalEndLanguage.warningmessage51")
        );
      }
    },
    download_method(url, file_name) {
      showLoading(this.$t("CommonName.loadingmessage"));
      const oReq = new XMLHttpRequest();
      const URL = url; // URL 为下载的URL地址
      oReq.open("GET", URL, true);
      oReq.responseType = "blob";
      let that = this;
      oReq.onload = function () {
        const file = new Blob([oReq.response], {
          type: "blob",
        });
        FileSaver.saveAs(file, file_name); // fileName为文件名
        hideLoading();
      };
      oReq.send();
    },
    /**
     * initposition 初始化 位置 清空波形上的 一切操作
     */
    initPosition() {
      this.loopPlay = false;
      this.showloopbtn = true;
      this.currentRegion = {};
      this.captionsObj = {};
      this.Reginleft = [];
      this.selectionRow = [];
      this.startrow = {};
      this.peaksInstance.segments.removeAll();
    },
    /**
     *
     * @param {*跳转位置} i
     */
    Jumpposition(i, data) {
      this.peaksInstance.off("player.seeked");
      this.videoDom.pause();
      this.currentRegion = i;
      this.captionsObj = this.currentRegion;
      this.player = true;
      this.$nextTick(() => {
        let div = document.getElementById("left_video_div_div");
        let videodiv = document.getElementById("video1").clientHeight;
        if (this.DragPosition > 0) {
          div.style.top = this.DragPosition + "px";
          div.style.bottom = "auto";
          if (this.DragPosition + div.clientHeight > videodiv) {
            div.style.top = videodiv - div.clientHeight + "px";
          }
        } else {
          div.style.bottom = "auto";
          div.style.top = videodiv - 60 - div.clientHeight + "px";
        }
      });
      this.setRegion(i);
      if (data == 1) {
        if (this.textareaBlues) {
          return;
        }
      }
      if (+i.data_start > this.silderMax) {
        return;
      }
      this.videoDom.currentTime = i.data_start;
      // this.getWavesurferRegion(i);
    },
    /**
     *
     * @param {设置特殊字符} html
     */
    filter_html_auto(html) {
      html = html.replace(/\{/g, "}");
      html = html.replace(/(&lt;i&gt;|&lt;\/i&gt;)/g, "");
      html = html
        .replace(/&amp;/g, "&")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&quot;/g, '"')
        .replace(/&nbsp;/gi, " ")
        .replace(/(<i>|<\/i>)/g, "")
        .replace(/(<u>|<\/u>)/g, "")
        .replace(/(<b>|<\/b>)/g, "");
      return (html = html.replace(
        /(}\\an1}|}\\an2}|}\\an3}|}\\an4}|}\\an5}|}\\an6}|}\\an7}|}\\an8}|}\\an9})/g,
        ""
      ));
    },
    /**
     *
     * @param {*设置 表格数据} arr
     * @param {*} flag
     */
    steTableData(arr, flag) {
      for (let index = 0; index < arr.length; index++) {
        // 0 不允许 事件周交叉 1允许 时间轴交叉
        // console.log(this.caption_info); duration_cross
        if (this.caption_info.duration_cross == "0") {
          arr[index].mintime = arr[index - 1] ? arr[index - 1].data_end : "";
          arr[index].maxtime = arr[index + 1] ? arr[index + 1].data_start : "";
          if (index !== 0) {
            if (arr[index - 1].end_time >= arr[index].end_time) {
              arr[index].mintime = arr[index - 1].data_start;
              // 判断 是否是 最后一句
              if (index == arr.length - 1) {
                arr[index].maxtime = this.videoDom.duration;
              } else {
                arr[index].maxtime = arr[index + 1].data_end;
              }
            }
          }
          if (arr[index].sentence_id == arr[arr.length - 1].sentence_id) {
            arr[index].maxtime = this.videoDom.duration;
          }
          if (arr[index].sentence_id == arr[0].sentence_id) {
            arr[index].mintime = 0;
          }
        } else {
          arr[index].mintime = arr[index - 1] ? arr[index - 1].data_start : "";
          arr[index].maxtime = arr[index + 1] ? arr[index + 1].data_end : "";
          if (arr[index].sentence_id == arr[arr.length - 1].sentence_id) {
            arr[index].maxtime = this.videoDom.duration;
          }
          if (arr[index].sentence_id == arr[0].sentence_id) {
            arr[index].mintime = 0;
          }
        }
        if (flag) {
          arr[index].showsourceInput = false;
          arr[index].showtransInput = false;
          arr[index].showStartInput = false;
          arr[index].showEndInput = false;
        }
        arr[index].index = index;
        arr[index].sentence_source_find = "";
        arr[index].sentence_trans_find = "";
        arr[index] = this.setNumber(arr[index]);
        if (!arr[index + 1]) {
          arr[index].showredEndTime = false;
          continue;
        }
        if (+arr[index + 1].data_start < +arr[index].data_end) {
          arr[index].showredEndTime = true;
          arr[index + 1].showredStartTime = true;
          continue;
        }
        if (
          Math.abs(
            this.numSub(arr[index].data_end, arr[index + 1].data_start)
          ) < this.caption_info.minigap_time
        ) {
          // 判断 结束时间和开始时间是否可以相等 0 相等红 1=>相等不红
          if (this.caption_info.minigap_type == "0") {
            // console.log(arr[index].data_end, arr[index + 1].data_start);
            arr[index].showredEndTime = true;
            arr[index + 1].showredStartTime = true;
          } else {
            //判断 结束时间和开始时间是否可以相等 0 相等红 1=>相等不红
            if (+arr[index].data_end != +arr[index + 1].data_start) {
              arr[index].showredEndTime = true;
              arr[index + 1].showredStartTime = true;
            } else {
              // arr[index].showredStartTime = false;
              arr[index].showredEndTime = false;
              arr[index + 1].showredStartTime = false;
            }
          }
        } else {
          arr[index].showredEndTime = false;
          arr[index + 1].showredStartTime = false;
        }
      }
      return arr;
    },
    /**
     *
     * @param {* 调整计算精度} num1
     * @param {*} num2
     */
    numSub(num1, num2) {
      var baseNum, baseNum1, baseNum2;
      var precision; // 精度
      try {
        baseNum1 = num1.toString().split(".")[1].length;
      } catch (e) {
        baseNum1 = 0;
      }
      try {
        baseNum2 = num2.toString().split(".")[1].length;
      } catch (e) {
        baseNum2 = 0;
      }
      baseNum = Math.pow(10, Math.max(baseNum1, baseNum2));
      precision = baseNum1 >= baseNum2 ? baseNum1 : baseNum2;
      return ((num1 * baseNum - num2 * baseNum) / baseNum).toFixed(precision);
    },

    // /**
    //  *
    //  * 高亮 跳转 表格
    //  */
    // Highlightjump() {
    //   const Tbody = this.$refs.multipleTable.$el.querySelector(
    //     ".el-table__body-wrapper> table > tbody"
    //   );
    //   this.$nextTick(() => {
    //     Tbody.querySelector(".success-row")?.scrollIntoView({
    //       behavior: "instant",
    //       block: "center",
    //       inline: "nearest",
    //     });
    //   });
    // },
    /**
     *
     * @param {*跳转 位置 高亮} val
     */
    jumpgo(val) {
      if (Number(val) == this.tableData.length) {
        this.$refs.virtualref.scrollToIndex(Number(val));
      } else {
        // console.log(Number(val) - 3);
        this.$nextTick(() => {
          this.$refs.virtualref.scrollToIndex(Number(val) - 3);
        });
      }
    },
    /**
     * 设置 替换位置
     * @param index
     */
    srtTrCurIndex(index) {
      this.find_tr_index = index;
      this.find_cur_index = -1;
    },
    // *================================== title 按钮 操作=========================================
    /**
     * 点击修改 名称
     */
    titleClick() {
      this.showtitleflag = false;
      this.$nextTick(() => {
        this.$refs.Blurinput.focus();
      });
    },
    /**
     * input 失去 焦点 修改 名称
     */
    titleinputBlur() {
      this.textareaBlues = false;
      if (!this.title) {
        this.$messages("warning", this.$t("CommonName.warningmessage3"));
        this.$refs.Blurinput.focus();
        return;
      }
      this.set_job_name(this.job_id)
        .then(() => {
          this.showtitleflag = true;
        })
        .catch(() => {});
    },
    /**
     *字幕规则设置
     */
    SubtitlerulesDialog() {
      this.get_private_job_info().then((res) => {
        // console.log(res);
        // console.log(this.dataInfo.base_info.show);

        this.Glossariestab1 = this.setcaption_info_Data(res.info.caption_info);
        this.dialogtitle = this.$t("VideoDetails.dialogText");
        this.dialogVisible = true;
        this.showfooter = true;
        this.footerbtnName = this.$t("CommonName.OK");
        this.closefooterbtnName = this.$t("CommonName.Cancel");
        this.showotherclosebtn = false;
        this.dialogWidth = "888px";
      });
    },
    autoMt() {
      showLoading();
      auto_mt({
        action: "auto_mt_bath",
        job_id: this.job_id,
      }).then((res) => {
        if (res) {
          window.location.reload();
          // this.get_private_job_data_info(this.job_id).then(() => {
          //   this.$messages("success", this.$t("VideoDetails.dialogText7"));
          //   this.dialogVisible = false;
          // });
        }
      });
    },
    /**
     * 禁用字符
     */
    Prohibitcharactersetting() {
      // this.Glossariestab2 = {
      //   // 禁止字符设置
      //   radio: "0",
      //   value: false,
      //   textarea: "",
      // };
      // if (this.dataInfo.customize_mode == "0") {
      //   this.Glossariestab2.value = true;
      // } else {
      //   this.Glossariestab2.value = false;
      // }
      this.dialogtitle = this.$t("OverwriteOperation.Forbiddencharacter");
      this.closefooterbtnName = this.$t("CommonName.Cancel");
      this.showotherclosebtn = false;
      this.dialogVisible = true;
      this.showfooter = true;
      this.footerbtnName = this.$t("CommonName.OK");
      this.dialogWidth = "888px";
    },
    /**
     *
     * @param {* Ajust Timecodes 弹出框  选择按钮} label
     */
    radioinput(label) {
      this.form5.btmradio1 = "0";
      if (label == "1") {
        this.form5.btmradio1 = "1";
        this.radiolist = [
          { key: "0", value: this.$t("OverwriteOperation.Selectedlinesonly") },
          {
            key: "1",
            value: this.$t("OverwriteOperation.Alllinesafterselectedline1"),
          },
        ];
        this.showotherclosebtn = false;
        this.footerbtnName = this.$t("OverwriteOperation.OK");
        this.closefooterbtnName = this.$t("CommonName.Cancel");
      } else {
        this.form5.btmradio1 = "0";
        this.radiolist = [
          { key: "0", value: this.$t("OverwriteOperation.Alllines") },
          {
            key: "1",
            value: this.$t("OverwriteOperation.Selectedlinesonly"),
          },
          {
            key: "2",
            value: this.$t("OverwriteOperation.Alllinesbeforeselectedline"),
          },
          {
            key: "3",
            value: this.$t("OverwriteOperation.Alllinesafterselectedline"),
          },
        ];
        this.showotherclosebtn = true;
        this.footerbtnName = this.$t("OverwriteOperation.Forward");
        this.closefooterbtnName = this.$t("OverwriteOperation.Backward");
      }
    },
    /**
     *
     * @param {* 点击 错误检查 弹窗 显示 tab 切换} val
     */
    verifyhandleClicktab(radio) {
      if (radio === "1") {
        this.checkboxList = [];
        this.checkboxLists.forEach((item) => {
          if (
            ["1", "3", "4", "5", "6", "9", "10", "11", "13", "14"].includes(
              item.label
            )
          ) {
            this.checkboxList.push(item);
          }
        });
      } else {
        this.checkboxList1 = [];
        this.checkboxLists.forEach((item) => {
          if (["2", "4", "7", "8"].includes(item.label)) {
            this.checkboxList1.push(item);
          } else if (["1", "3", "5", "6", "9", "14"].includes(item.label)) {
            this.checkboxList1.push(item);
          }
        });
      }
    },

    /**
     *
     * @param {*弹窗关闭} val
     */
    changeDialogVisible(val) {
      this.dialogVisible = val;
    },
    /**
     * 弹窗 确定按钮
     */
    determineClick(flag) {
      if (this.dialogtitle == this.$t("VideoDetails.dialogText")) {
        if (
          !this.Glossariestab1.min_duration ||
          !this.Glossariestab1.max_duration ||
          !this.Glossariestab1.minigap_time
        ) {
          this.$messages(
            "warning",
            this.$t("TermBaseManagement.warningmessage3")
          );
          return;
        }
        if (
          +this.Glossariestab1.min_duration >= +this.Glossariestab1.max_duration
        ) {
          this.$messages("warning", this.$t("VideoDetails.dialogText8"));
          return;
        }
        for (let index = 0; index < this.Glossariestab1.langs.length; index++) {
          if (
            !this.Glossariestab1.langs[index].char_line ||
            !this.Glossariestab1.langs[index].char_speed
          ) {
            this.$messages(
              "warning",
              this.$t("TermBaseManagement.warningmessage3")
            );
            return;
          }
        }
        showLoading(this.$t("CommonName.loadingmessage"));
        this.set_caption_info();
      } else if (
        this.dialogtitle == this.$t("OverwriteOperation.Forbiddencharacter")
      ) {
        // console.log(1111);
        let dataobj = {};
        dataobj.shield_mode = this.Glossariestab2.radio;
        if (this.Glossariestab2.value) {
          dataobj.customize_mode = "1";
          dataobj.shield_str = this.Glossariestab2.textarea;
        } else {
          dataobj.customize_mode = "0";
          dataobj.shield_str = "";
        }
        // console.log(dataobj);
        this.set_shield_info(dataobj);
      } else if (
        this.dialogtitle == this.$t("OverwriteOperation.AjustTimecodes")
      ) {
        if (!this.form5.time || !this.currentRegion.sentence_id) {
          return;
        }
        if (this.selectionRow.length > 1) {
          return;
        }
        if (isNaN(getStringToTime(this.form5.time, 3))) {
          this.form5.time = "00:00:00.000";
          this.$messages(
            "warning",
            this.$t("OverwriteOperation.warningmessage1")
          );
          return;
        }
        this.form5.sentence_id = this.currentRegion.sentence_id;
        this.form5.data_start = this.currentRegion.data_start;
        if (this.form5.topradio == "1") {
          if (this.form5.btmradio1 == "0") {
            this.form5.btmradio1 = "1";
          } else {
            this.form5.btmradio1 = "3";
          }
          let data;
          this.form5.times =
            getStringToTime(this.form5.time) - this.form5.data_start;
          if (this.form5.times < 0) {
            data = 1;
          } else {
            data = 2;
          }
          this.form5.times = Math.abs(this.form5.times);
          showLoading(this.$t("CommonName.loadingmessage"));
          this.adjust_time_code(this.form5, data).then(() => {
            this.get_private_job_data_info(this.job_id).then(() => {
              // this.setNewTableData();
              this.initPosition();
              this.dialogVisible = false;
              hideLoading();
            });
          });
        } else {
          let data;
          this.form5.times = getStringToTime(this.form5.time);
          if (flag == 1) {
            data = 1;
          } else {
            data = 2;
          }
          showLoading(this.$t("CommonName.loadingmessage"));
          this.adjust_time_code(this.form5, data).then(() => {
            this.get_private_job_data_info(this.job_id).then(() => {
              // this.setNewTableData();
              this.initPosition();
              this.dialogVisible = false;
              hideLoading();
            });
          });
        }
      } else {
        let type = 0;
        if (this.dataInfo.base_info.show) {
          type = 1;
        } else {
          if (this.verifyname == "1") {
            type = 2;
          } else {
            type = 1;
          }
        }
        showLoading(this.$t("CommonName.loadingmessage"));
        this.check_subtitle_error(this.job_id, type);
      }
    },
    // *================================== 数据请求 方法===========================================
    /**
     * 获取 整体数据
     */
    get_private_job_info() {
      return get_private_job_info({
        action: "get_private_job_info",
        job_id: this.job_id,
      }).then((res) => {
        if (res) {
          // console.log(res);
          // hideLoading();
          return res;
        }
      });
    },
    /**
     * 修改多媒体任务字幕规则信息
     */
    set_caption_info() {
      return set_caption_info({
        action: "set_caption_info",
        job_id: this.job_id,
        min_duration: this.Glossariestab1.min_duration,
        max_duration: this.Glossariestab1.max_duration,
        minigap_time: this.Glossariestab1.minigap_time,
        minigap_type: !this.Glossariestab1.minigap_type ? "0" : "1",
        duration_cross: !this.Glossariestab1.duration_cross ? "0" : "1",
        langs: this.Glossariestab1.langs,
      }).then((res) => {
        if (res) {
          this.get_private_job_info().then((res) => {
            this.dataInfo = res.info;
            this.dataInfo.base_info.show = ["1", "7", "8"].includes(
              this.dataInfo.base_info.private_job_type
            );
            this.caption_info = res.info.caption_info;
            this.initPosition();
            this.get_private_job_data_info(this.job_id).then(() => {
              this.$messages("success", this.$t("VideoDetails.dialogText9"));
              this.dialogVisible = false;
              hideLoading();
            });
          });
        }
      });
    },
    /**
     *
     * @param {*是否检测0，0不检测，1检测} data
     */
    setcaption_info_Data(data) {
      if (data.minigap_type == "0") {
        data.minigap_type = false;
      } else {
        data.minigap_type = true;
      }
      if (data.duration_cross == "0") {
        data.duration_cross = false;
      } else {
        data.duration_cross = true;
      }

      // console.log(data);
      return data;
    },
    /**
     *
     * @param {修改多媒体任务禁止字符信息} item
     */
    set_shield_info(item) {
      return set_shield_info({
        action: "set_shield_info",
        job_id: this.job_id,
        customize_mode: item.customize_mode, //是否开启自定义模式，0关闭，1开启
        shield_mode: item.shield_mode, //屏蔽字模式，0无；1中文；2英文；3自定义
        shield_str: item.shield_str, //屏蔽字内容，3时有效
      }).then((res) => {
        if (res) {
          this.$messages("success", this.$t("VideoDetails.dialogText9"));
          this.dialogVisible = false;
        }
      });
    },
    /**
     *
     * @param {获取多媒体任务句段信息} data
     */
    get_private_job_data_info(data) {
      return get_private_job_data_info({
        action: "get_private_job_data_info",
        job_id: data,
      }).then((res) => {
        if (res) {
          // console.log(res, "res");
          let arr = res.info.segments;
          this.tableData = this.steTableData(arr, true);
          // hideLoading();
        }
      });
    },

    /**
     *
     * @param {修改多媒体任务名称} data
     */
    set_job_name(data) {
      return set_job_name({
        action: "set_job_name",
        job_id: data,
        job_name: this.title,
      }).then((res) => {
        if (res) {
          this.get_private_job_info().then((res) => {
            this.title = res.info.base_info.job_name;
            this.$messages("success", this.$t("VideoDetails.dialogText9"));
          });
        } else {
          this.get_private_job_info().then((res) => {
            this.title = res.info.base_info.job_name;
          });
        }
      });
    },
    /**
     *
     * @param {*更新句段内容-原文} item
     */
    set_source_content(item) {
      return set_source_content({
        action: "set_source_content",
        job_id: this.job_id,
        sentence_id: item.sentence_id,
        content: item.sentence_source,
      }).then((res) => {
        if (res) {
          let arr = this.tableData;
          arr.forEach((i) => {
            if (i.sentence_id == item.sentence_id) {
              i.sentence_source = res.info.content;
              i.sentence_source_find = "";
              i.sentence_source_html = res.info.content_html;
            }
          });
          // this.tableData = this.steTableData(arr, false);
          this.tableData = arr;
        } else {
          item.sentence_source =
            item.sentence_source_find == ""
              ? item.sentence_source_html
              : item.sentence_source_find;
        }
      });
    },
    /**
     *
     * @param {*更新句段内容-译文} item
     */
    set_trans_content(item) {
      return set_trans_content({
        action: "set_trans_content",
        job_id: this.job_id,
        sentence_id: item.sentence_id,
        content: item.sentence_trans,
      }).then((res) => {
        if (res) {
          let arr = this.tableData;
          arr.forEach((i) => {
            if (i.sentence_id == item.sentence_id) {
              i.sentence_trans = res.info.content;
              i.sentence_trans_find = "";
              i.sentence_trans_html = res.info.content_html;
            }
          });
          // this.tableData = this.steTableData(arr, false);
          this.tableData = arr;
        } else {
          item.sentence_trans =
            item.sentence_trans_find == ""
              ? item.sentence_trans_html
              : item.sentence_trans_find;
        }
      });
    },
    /**
     *
     * @param {更新句段时间信息} item
     */
    set_time_code(item) {
      set_time_code({
        action: "set_time_code",
        job_id: this.job_id,
        sentence_id: item.sentence_id,
        data_start: item.data_start,
        data_end: item.data_end,
      }).then((res) => {
        if (!res) {
          return;
        }
        // this.get_private_job_data_info(this.job_id);
        let targetItem = this.tableData.find(
          (item) => item.sentence_id === res.info.sentence_id
        );
        if (targetItem) {
          targetItem.start_time = getTimeString(
            res.info.data_start,
            1,
            3
          ).replace(".", ",");
          targetItem.end_time = getTimeString(res.info.data_end, 1, 3).replace(
            ".",
            ","
          );
          targetItem.duration = (
            Number(res.info.data_end) - Number(res.info.data_start)
          ).toFixed(3);
          if (this.dataInfo.base_info.show) {
            targetItem.charNumber = this.filter_html_auto(
              targetItem.sentence_source
            );
            targetItem.readingNumber = (
              Number(targetItem.charNumber.replace(/\n/, "").length) /
              Number(targetItem.duration)
            ).toFixed(1);

            if (targetItem.charNumber.split("\n").length > 1) {
              targetItem.charNumbererr1 =
                targetItem.charNumber.split("\n")[0].length >
                this.caption_info.langs[0].char_line;
              targetItem.charNumbererr2 =
                targetItem.charNumber.split("\n")[1].length >
                this.caption_info.langs[0].char_line;
            } else {
              targetItem.charNumbererr =
                Number(targetItem.charNumber.length) >
                this.caption_info.langs[0].char_line;
            }
            targetItem.readingNumbererr =
              Number(targetItem.readingNumber) >
              this.caption_info.langs[0].char_speed;
          } else {
            targetItem.charNumber = this.filter_html_auto(
              targetItem.sentence_trans
            );
            targetItem.readingNumber = (
              Number(targetItem.charNumber.replace(/\n/, "").length) /
              Number(targetItem.duration)
            ).toFixed(1);

            if (targetItem.charNumber.split("\n").length > 1) {
              targetItem.charNumbererr1 =
                targetItem.charNumber.split("\n")[0].length >
                this.caption_info.langs[1].char_line;
              targetItem.charNumbererr2 =
                targetItem.charNumber.split("\n")[1].length >
                this.caption_info.langs[1].char_line;
            } else {
              targetItem.charNumbererr =
                Number(targetItem.charNumber.length) >
                this.caption_info.langs[1].char_line;
            }
            targetItem.readingNumbererr =
              Number(targetItem.readingNumber) >
              this.caption_info.langs[1].char_speed;
          }
        }
        this.tableData = this.steTableData(this.tableData, false);
        hideLoading();
      });
    },
    /**
     *
     * @param data
     */
    get_new_sentence_key1() {
      get_new_sentence_key1({
        action: "get_new_sentence_key",
        job_id: this.job_id,
        start: this.jstart,
        end: this.jend,
      })
        .then((res) => {
          if (res) {
            this.get_private_job_data_info(this.job_id).then(() => {
              const targetItem = this.tableData.find(
                (item) => item.sentence_id === res.info.segment.sentence_id
              );
              if (targetItem) {
                this.setRegion(targetItem);
                this.currentRegion = targetItem;
                this.setselectionRow(this.currentRegion);
                this.captionsObj = this.currentRegion;
                this.jumpgo(targetItem.number);
              }
              hideLoading();
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /**
     *
     * @param {*句段操作-拆分句段} item
     */
    split_segment(item) {
      split_segment({
        action: "split_segment",
        job_id: this.job_id, //任务id
        sentence_id: item.sentence_id, //句段id
        offset: String(this.offset), //光标位置偏移量
        edit_now: item.data == 1 ? "source" : "target", //编辑类型，source表示原文，target表示译文
        content: item.data == 1 ? item.sentence_source : item.sentence_trans, //编辑区内容，表示最新的原文或译文
      }).then((res) => {
        if (res) {
          // console.log();

          // this.getWavesurferRegion(res.old);

          this.get_private_job_data_info(this.job_id).then(() => {
            const targetItem = this.tableData.find(
              (item) => item.sentence_id === res.old.sentence_id
            );
            if (targetItem) {
              // console.log(targetItem);
              this.offset = "";
              if (item.data === 1) {
                targetItem.showsourceInput = true;
              } else {
                targetItem.showtransInput = true;
              }
              this.setRegion(targetItem);
              this.currentRegion = targetItem;
              this.setselectionRow(this.currentRegion);
              this.captionsObj = this.currentRegion;
            }
            hideLoading();
          });
          // if (item.data == 1) {
          //   res.old.showsourceInput = true;
          //   res.old.showtransInput = false;
          // } else {
          //   res.old.showtransInput = true;
          //   res.old.showsourceInput = false;
          // }
          // res.new.showsourceInput = false;
          // res.new.showtransInput = false;
          // res.old.showStartInput = false;
          // res.old.showEndInput = false;
          // res.new.showStartInput = false;
          // res.new.showEndInput = false;
          // let arr = this.tableData;
          // arr.forEach((i, index) => {
          //   if (i.sentence_id == item.sentence_id) {
          //     arr.splice(index, 1, res.old);
          //     arr.splice(index + 1, 0, res.new);
          //   }
          // });
          // this.tableData = this.steTableData(arr, false);
        }
      });
    },
    /**
     *
     * @param {*句段操作-合并句段} item
     */
    merge_segment(item) {
      merge_segment({
        action: "merge_segment",
        job_id: this.job_id,
        sentence_id: item.sentence_id,
        nsentence_id: item.nsentence_id,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.get_private_job_data_info(this.job_id).then(() => {
          const targetItem = this.tableData.find(
            (item) => item.sentence_id === res.info.segment.sentence_id
          );
          if (targetItem) {
            if (item.typeinput === 1) {
              targetItem.showsourceInput = true;
            } else if (item.typeinput === 2) {
              targetItem.showtransInput = true;
            }
            // targetItem.showsourceInput = true;
            hideLoading();
          }
        });
        // this.currentRegion = res.info.segment;
        // this.setRegion(res.info.segment);
        // res.info.segment.showsourceInput = false;
        // res.info.segment.showStartInput = false;
        // res.info.segment.showEndInput = false;
        // res.info.segment.showtransInput = false;
        // let arr = this.tableData;
        // let newindex = 0;
        // arr.forEach((i, index) => {
        //   if (i.sentence_id == item.sentence_id) {
        //     arr.splice(index, 1, res.info.segment);
        //     newindex = index + 1;
        //   }
        // });
        // arr.splice(newindex, 1);
        // this.tableData = this.steTableData(arr, false);
        // this.setInputfocus(item);
      });
    },
    /**
     * 批量设置Tags标签信息
     * @param item
     * @param type
     */
    set_tag_batch_person(item, type) {
      let arrids = [];
      item.forEach((j) => arrids.push(j.sentence_id));
      set_tag_batch_person({
        action: "set_tag_batch",
        job_id: this.job_id,
        type: type, //-1表示清空标签，1斜体，2下划线，3粗体，4音乐符号1，5音乐符号2，6字幕位置-上左，7字幕位置-上中，8字幕位置-上右，9字幕位置-中左，10字幕位置-中中，11字幕位置-中右，12字幕位置-下左，13字幕位置-下中，14字幕位置-下右
        sentence_ids: arrids,
      }).then((res) => {
        if (res) {
          console.log(res);
        }
      });
    },
    /**
     *
     * @param {*句段操作-新增句段获取信息} item
     */
    get_new_segment(item) {
      get_new_segment({
        action: "get_new_segment",
        job_id: this.job_id,
        sentence_id: item.sentence_id, //选择插入的句段
      }).then((res) => {
        if (!res) {
          return;
        }
        // this.get_private_job_data_info(this.job_id);
        res.info.segment.showsourceInput = false;
        res.info.segment.showtransInput = false;
        res.info.segment.showStartInput = false;
        res.info.segment.showEndInput = false;
        res.info.segment.sentence_source_find = "";
        res.info.segment.sentence_trans_find = "";
        res.info.segment.sentence_source_html = "";
        res.info.segment.sentence_trans_html = "";
        // console.log(res);

        // if (this.dataInfo.base_info.private_job_type == "1") {
        //   res.info.segment.charNumber = this.filter_html_auto(
        //     res.info.segment.sentence_source
        //   );
        //   res.info.segment.readingNumber = (
        //     Number(res.info.segment.charNumber.replace(/\n/, "").length) /
        //     Number(res.info.segment.duration)
        //   ).toFixed(1);
        //
        //   if (res.info.segment.charNumber.split("\n").length > 1) {
        //     if (
        //       res.info.segment.charNumber.split("\n")[0].length >
        //       this.caption_info.langs[0].char_line
        //     ) {
        //       res.info.segment.charNumbererr1 = true;
        //     } else {
        //       res.info.segment.charNumbererr1 = false;
        //     }
        //     if (
        //       res.info.segment.charNumber.split("\n")[1].length >
        //       this.caption_info.langs[0].char_line
        //     ) {
        //       res.info.segment.charNumbererr2 = true;
        //     } else {
        //       res.info.segment.charNumbererr2 = false;
        //     }
        //   } else {
        //     if (
        //       Number(res.info.segment.charNumber.length) >
        //       this.caption_info.langs[0].char_line
        //     ) {
        //       res.info.segment.charNumbererr = true;
        //     } else {
        //       res.info.segment.charNumbererr = false;
        //     }
        //   }
        //   if (
        //     Number(res.info.segment.readingNumber) >
        //     this.caption_info.langs[0].char_speed
        //   ) {
        //     res.info.segment.readingNumbererr = true;
        //   } else {
        //     res.info.segment.readingNumbererr = false;
        //   }
        // } else {
        //   res.info.segment.charNumber = this.filter_html_auto(
        //     res.info.segment.sentence_trans
        //   );
        //   res.info.segment.readingNumber = (
        //     Number(res.info.segment.charNumber.replace(/\n/, "").length) /
        //     Number(res.info.segment.duration)
        //   ).toFixed(1);
        //
        //   if (res.info.segment.charNumber.split("\n").length > 1) {
        //     if (
        //       res.info.segment.charNumber.split("\n")[0].length >
        //       this.caption_info.langs[1].char_line
        //     ) {
        //       res.info.segment.charNumbererr1 = true;
        //     } else {
        //       res.info.segment.charNumbererr1 = false;
        //     }
        //     if (
        //       res.info.segment.charNumber.split("\n")[1].length >
        //       this.caption_info.langs[1].char_line
        //     ) {
        //       res.info.segment.charNumbererr2 = true;
        //     } else {
        //       res.info.segment.charNumbererr2 = false;
        //     }
        //   } else {
        //     if (
        //       Number(res.info.segment.charNumber.length) >
        //       this.caption_info.langs[1].char_line
        //     ) {
        //       res.info.segment.charNumbererr = true;
        //     } else {
        //       res.info.segment.charNumbererr = false;
        //     }
        //   }
        //   if (
        //     Number(res.info.segment.readingNumber) >
        //     this.caption_info.langs[1].char_speed
        //   ) {
        //     res.info.segment.readingNumbererr = true;
        //   } else {
        //     res.info.segment.readingNumbererr = false;
        //   }
        // }
        this.currentRegion = res.info.segment;
        this.setselectionRow(this.currentRegion);
        this.captionsObj = this.currentRegion;
        this.videoDom.currentTime = this.currentRegion.data_start;
        this.setRegion(res.info.segment);
        let arr = this.tableData;
        arr.forEach((i, index) => {
          if (i.sentence_id == item.sentence_id) {
            arr.splice(index + 1, 0, res.info.segment);
            this.jumpgo(index + 1);
            this.srtTrCurIndex(index);
          }
        });
        // console.log(arr);
        this.tableData = this.steTableData(arr, false);
      });
    },
    /**
     * 个人-平移时间轴
     * @param item
     * @param data
     */
    adjust_time_code(item, data) {
      return adjust_time_code1({
        action: "adjust_time_code",
        job_id: this.job_id,
        direction_type: data, //1减少时间； 2： 增加时间
        time_code: item.times, //时间偏移量
        direction_mode: item.btmradio1, //四种模式 0 全部 1 选中 2选中项及以前 3 选中项及以后
        sentence_ids: item.sentence_id, //选中句段 条件direction_mode = 1
        start_time: item.data_start, //选中句段的开始时间
        shift_mode: 1, //为1 时 选中当前句段也要修改+
      }).then((res) => {
        if (!res) {
          return;
        }
      });
    },
    /**
     * 个人 撤销上一次操作
     * @param type
     */
    undo_method(type) {
      return undo_method1({
        action: "undo_method",
        job_id: this.job_id,
        type,
      }).then((res) => {
        if (!res) {
          return;
        }
        return res;
      });
    },
    /**
     *
     * @param {*句段操作-删除句段} item
     */
    delete_segment(item, nextid) {
      let arrids = [];
      item.forEach((j) => arrids.push(j.sentence_id));
      delete_segment({
        action: "delete_segment",
        job_id: this.job_id,
        sentence_ids: arrids,
      }).then((res) => {
        if (res) {
          this.get_private_job_data_info(this.job_id).then(() => {
            hideLoading();
            setTimeout(() => {
              this.deleteFlag = false;
            }, 1);
            if (item.length > 1) {
              this.videoDom.currentTime = nextid;
              this.jumpgo(item[0].number);
            }
            this.currentRegion = {};
            this.initPosition();
          });
          // let arr = this.tableData;
          // arr.forEach((i, index) => {
          //   if (i.sentence_id == res.info.sentence_id) {
          //     arr.splice(index, 1);
          //   }
          // });
          // this.tableData = arr;
          // res.info.sentence_ids.forEach((j) => {
          //   arr.forEach((i, index) => {
          //     if (j == i.sentence_id) {
          //       arr.splice(index, 1);
          //     }
          //   });
          // });
          // this.tableData = this.steTableData(arr, false);

          // this.tableData = arr;
          // this.initPosition();
          // hideLoading();
          // setTimeout(() => {
          //   this.deleteFlag = false;
          // }, 1);
          // this.$messages('')
        }
      });
    },
    /**
     *
     * @param {*单句内容翻译} item
     */
    get_mt(item) {
      get_mt({
        action: "get_mt",
        job_id: this.job_id,
        source: item.sentence_source,
      }).then((res) => {
        if (!res) {
          hideLoading();
          return;
        }
        let arr = this.tableData;
        arr.forEach((i, index) => {
          if (i.sentence_id == item.sentence_id) {
            i.showtransInput = !i.showtransInput;
            i.sentence_trans = res.content;
          }
        });
        this.tableData = arr;
        hideLoading();
      });
    },
    /**
     *
     * @param {*获取句段ASR转写内容} item
     */
    get_asr_content(item) {
      get_asr_content({
        action: "get_asr_content",
        job_id: this.job_id,
        sentence_id: item.sentence_id,
      }).then((res) => {
        if (!res) {
          return;
        }
        let arr = this.tableData;
        arr.forEach((i, index) => {
          if (i.sentence_id == item.sentence_id) {
            i.showsourceInput = !i.showsourceInput;
            i.sentence_source = res.info.content;
          }
        });
        this.tableData = arr;
        hideLoading();
      });
    },
    /**
     *
     * @param {*错误检查} id
     */
    check_subtitle_error(id, type) {
      check_subtitle_error({
        action: "check_subtitle_error",
        job_id: id,
        error_info: type == 1 ? this.Glossariestabs4 : this.Glossariestabs3,
        type: type, //1检查原文，2检查译文
      }).then((res) => {
        if (!res) {
          hideLoading();
          return;
        }
        // console.log(res.list);
        res.list.forEach((i, index) => {
          this.settabTableData(i, index);
        });
        this.showWarningNumber = true;
        this.showWarningNumbernum = this.tabTableData.length;
        this.dialogVisible = false;
        this.tabname = "third";
        hideLoading();
      });
    },
    // *================================= 操作 波形 方法=========================================
    /**
     *
     * @param {* 设置 tab 错误检查 里面的数据} i
     */
    settabTableData(i, index) {
      i.error.forEach((j) => {
        let obj = {};

        obj.index = i.number;
        obj.sentence_id = i.sentence_id;
        obj.errorData = j;
        this.tabTableData.push(obj);
      });
    },
    /**
     *
     * @param {*查找} data
     */
    lookup(find_direction = 1) {
      if (!this.player) {
        this.player = !this.player;
        this.videoDom.pause();
        this.stopPlayBack();
        this.showloopbtn = true;
      }
      this.initPosition();
      this.find_cur_tr_data = {};
      let match_case = "0";
      let match_whole = "0";
      if (this.checkboxGroup.includes("0")) {
        match_case = "1";
      }
      if (this.checkboxGroup.includes("1")) {
        match_whole = "1";
      }
      if (find_direction == 1 && this.find_cur_index > -1) {
        this.find_cur_index += this.lookupinput.length;
      }

      let find_result = find_next(
        this.lookupinput,
        this.tableData,
        this.select,
        match_case,
        match_whole,
        this.find_tr_index,
        this.find_cur_index,
        find_direction
      );
      // console.log(find_result);
      if (find_result.status) {
        this.tableData = JSON.parse(JSON.stringify(find_result.data.data));
        this.find_tr_index =
          find_result.data.current_tr > 0 ? find_result.data.current_tr : 0;
        this.find_cur_index = find_result.data.current_index;
        this.find_cur_tr_data = find_result.cur_tr_data;

        let item =
          this.find_tr_index > 0
            ? this.tableData[this.find_tr_index]
            : this.tableData[0];
        this.tableData.forEach((i, index) => {
          if (i.sentence_id == item.sentence_id) {
            console.log("jumpgo");
            this.jumpgo(index + 1);
          }
        });
        // console.log(item);
        // console.log(item.number);
        // this.jumpgo(item.number);
      } else {
        this.find_cur_tr_data = {};
        switch (find_result.errorCode) {
          case 0:
            this.$messages("warning", this.$t("OverwriteOperation.findTips1"));
            break;
          case 1:
            this.$messages("warning", this.$t("OverwriteOperation.findTips2"));
            break;
          case 2:
            this.$messages("warning", this.$t("OverwriteOperation.findTips3"));
            this.tableData = JSON.parse(JSON.stringify(find_result.data.data));
            this.find_tr_index = -1;
            this.find_cur_index = -1;
            // this.lookup(find_direction);
            break;
          case 3:
            this.$messages("warning", this.$t("OverwriteOperation.findTips4"));
            this.find_tr_index = -1;
            this.find_cur_index = -1;
            break;
          default:
            break;
        }
      }
    },
    /**
     *
     * @param {*替换} data
     */
    replace(find_direction) {
      // this.initPosition();
      if (JSON.stringify(this.find_cur_tr_data) == "{}") {
        this.lookup(find_direction);
      } else {
        let content = this.find_cur_tr_data.content;
        let start = this.find_cur_tr_data.start;
        let len = this.find_cur_tr_data.len;

        let result = replace_find_content(
          this.replaceInput,
          content,
          start,
          len,
          find_direction
        );
        // console.log("replace_result", result);
        let replace_content = result.new_content;
        let replace_content_html = result.new_content_html;
        this.find_cur_index = result.new_start;
        //请求接口修改句端内容
        this.tableData.forEach((i, index) => {
          if (i.sentence_id == this.find_cur_tr_data.sentence_id) {
            if (this.select == "1") {
              i.sentence_source = replace_content;
              i.sentence_source_html = replace_content_html;
              i.sentence_source_find = "";
            } else {
              i.sentence_trans = replace_content;
              i.sentence_trans_html = replace_content_html;
              i.sentence_trans_find = "";
            }

            let data = this.select == "1" ? 1 : 2;
            let val = this.select == "1" ? i.sentence_source : i.sentence_trans;
            // if (this.select == "2") {
            i = this.setNumbererr(i, data, val);
            // }

            if (this.find_tr_index != index) {
              this.find_cur_index = 0;
            }
            if (i.oldval == i.sentence_trans) {
              return;
            }
            if (this.select == "1") {
              this.set_source_content(i).then(() => {
                this.lookup(find_direction);
              });
            } else {
              this.set_trans_content(i).then(() => {
                this.lookup(find_direction);
              });
            }
          }
        });

        this.find_cur_tr_data = {};
      }
    },

    lookupinputChange(e) {
      // console.log("e", e);
      let index = this.find_tr_index <= 0 ? 0 : this.find_tr_index;
      if (this.find_cur_index <= 0) {
        index = index <= 0 ? 0 : index;
      }
      this.find_cur_index = -1;
      if (!this.currentRegion.sentence_id) {
        this.tableData[index].sentence_source_find = "";
        this.tableData[index].sentence_trans_find = "";
        this.find_tr_index = -1;

        this.find_cur_tr_data = {};
      }
    },
    lookupSelectChange(value) {
      if (this.find_select != value) {
        let index = this.find_tr_index;
        // if (this.find_cur_index == 0) {
        //   index = index == 0 ? 0 : index - 1;
        // }

        if (!this.currentRegion.sentence_id) {
          index = index == -1 ? 0 : index;
          this.tableData[index].sentence_source_find = "";
          this.tableData[index].sentence_trans_find = "";
        }
        this.find_cur_index = -1;
        this.find_tr_index = -1;

        this.find_cur_tr_data = {};
        this.find_select = value;
      }
    },

    /**
     *
     * @param {超出溢出 显示} i
     */
    onShowNameTipsMouseenter(id, i) {
      // console.log(id);
      var clientWidth = document.getElementById(id).clientWidth;
      var scrollWidth = document.getElementById(id).scrollWidth;
      if (clientWidth < scrollWidth) {
        // alert("已省略……");
        i.showdropdown = true;
      } else {
        i.showdropdown = false;
      }
    },
    /**
     *
     * @param {* 设置字符数与读数  飘红} row
     */
    setNumber(row) {
      if (!this.dataInfo.base_info.show) {
        row.charNumber = this.filter_html_auto(row.sentence_trans);
        row.readingNumber = (
          Number(
            this.filter_html_auto(row.sentence_trans).replace(/\n/, "").length
          ) / Number(row.duration)
        ).toFixed(1);
        // console.log(row.charNumber.split("\n").length);
        // 判断 字幕是否换行 换行 就判断是都飘红
        if (row.charNumber.split("\n").length > 1) {
          if (
            row.charNumber.split("\n")[0].length >
            this.caption_info.langs[1].char_line
          ) {
            row.charNumbererr1 = true;
          } else {
            row.charNumbererr1 = false;
          }
          if (
            row.charNumber.split("\n")[1].length >
            this.caption_info.langs[1].char_line
          ) {
            row.charNumbererr2 = true;
          } else {
            row.charNumbererr2 = false;
          }
        } else {
          if (
            Number(this.filter_html_auto(row.sentence_trans).length) >
            this.caption_info.langs[1].char_line
          ) {
            row.charNumbererr = true;
          } else {
            row.charNumbererr = false;
          }
        }

        row.readingNumbererr =
          Number(row.readingNumber) > this.caption_info.langs[1].char_speed;
      } else {
        row.charNumber = this.filter_html_auto(row.sentence_source);
        row.readingNumber = (
          Number(
            this.filter_html_auto(row.sentence_source).replace(/\n/, "").length
          ) / Number(row.duration)
        ).toFixed(1);
        if (row.charNumber.split("\n").length > 1) {
          row.charNumbererr1 =
            row.charNumber.split("\n")[0].length >
            this.caption_info.langs[0].char_line;
          row.charNumbererr2 =
            row.charNumber.split("\n")[1].length >
            this.caption_info.langs[0].char_line;
        } else {
          row.charNumbererr =
            Number(this.filter_html_auto(row.sentence_source).length) >
            this.caption_info.langs[0].char_line;
        }
        row.readingNumbererr =
          Number(row.readingNumber) > this.caption_info.langs[0].char_speed;
      }
      return row;
    },
    // *========================================表格操作============================================
    /**
     *
     * @param {* 点击 更多} command
     */
    svghandleCommand(command) {
      if (command == "0") {
        this.dialogtitle = this.$t("VideoDubbing.toolText6");
        this.dialogVisible = true;
        this.dialogWidth = "608px";
        this.showfooter = false;
      }
    },
    /**
     *
     * @param {*表格内 输入框获取焦点} item
     */
    setInputfocus(item) {
      if (item.data == 1) {
        this.$nextTick(() => {
          this.$refs[item.sentence_id + "textareasource"].focus();
        });
      } else {
        this.$nextTick(() => {
          this.$refs[item.sentence_id + "textareatrans"].focus();
        });
      }
    },

    /**
     *
     * @param {* 按钮 操作表格 } data
     */
    toolClick(data) {
      this.iconFlag = false;
      this.showotherclosebtn = false;
      this.closefooterbtnName = this.$t("CommonName.Cancel");
      if (data == 8) {
        // console.log(1111);
        if (!this.dataInfo.base_info.show) {
          this.verifyname = "1";
          this.checkboxList = [];
          this.checkboxLists.forEach((item) => {
            if (
              ["1", "3", "4", "5", "6", "9", "10", "11", "13", "14"].includes(
                item.label
              )
            ) {
              this.checkboxList.push(item);
            }
          });
        }
        this.showotherclosebtn = false;
        this.showWarningNumbernum = "0";
        this.tabTableData = [];
        this.dialogtitle = this.$t("OverwriteOperation.Warnings");
        this.dialogVisible = true;
        this.showfooter = true;
        this.footerbtnName = this.$t("OverwriteOperation.Verify");
        this.dialogWidth = "448px";
        return;
      }
      if (data === 7) {
        this.popoverInput = "";
        // this.visible = true;
        return;
      }
      // 撤销
      if (data == 10) {
        showLoading(this.$t("CommonName.loadingmessage"));
        var srt = "";
        this.undo_method(0)
          .then((res) => {
            switch (res.undo_info.type) {
              // 平移时间轴
              case "1":
                srt = this.$t("OverwriteOperation.warningmessage17");
                break;
              //   调整时间轴最小间隔
              case "2":
                srt = this.$t("OverwriteOperation.warningmessage18");
                break;
              //   导入字幕块
              case "3":
                srt = this.$t("OverwriteOperation.warningmessage19");
                break;
              default:
                break;
            }
            hideLoading();
            // 撤销 操作
            this.$confirm(srt, this.$t("CommonName.deleteMessageTitle"), {
              confirmButtonText: this.$t("CommonName.deleteMessagebutton"),
              cancelButtonText: this.$t("CommonName.deleteMessageClosebutton"),
              closeOnClickModal: false,
              cancelButtonClass: "closeButton",
            })
              .then(() => {
                showLoading(this.$t("CommonName.loadingmessage"));
                this.undo_method(1).then(() => {
                  this.get_private_job_data_info(this.job_id).then(() => {
                    this.initPosition();
                    hideLoading();
                  });
                });
              })
              .catch(() => {});
          })
          .catch((err) => {
            console.log(err.msg);
          });
      }
      if (!this.currentRegion.sentence_id) {
        return;
      }
      // 剪切
      if (data == 4) {
        if (!this.splitFlag) {
          return;
        }
        if (this.selectionRow.length > 1) {
          return;
        }
        // this.$refs.virtualref.$children.forEach((i) => {
        //   if (
        //     !i.$children[0].$refs[
        //       this.currentRegion.sentence_id + "textareasource"
        //     ]
        //   ) {
        //     return;
        //   }
        //   this.offset =
        //     i.$children[0].$refs[
        //       this.currentRegion.sentence_id + "textareasource"
        //     ].$el.children[0].selectionStart;
        //   if (!this.offset) {
        //     return;
        //   }
        //    showLoading(this.$t("CommonName.loadingmessage"));
        //   this.split_segment(this.currentRegion, data);
        // });
        this.$refs.virtualref.$children.forEach((i) => {
          if (!this.dataInfo.base_info.show) {
            // console.log(this.currentRegion.data);
            if (this.currentRegion.data == 1) {
              if (
                !i.$children[0].$refs[
                  this.currentRegion.sentence_id + "textareasource"
                ]
              ) {
                return;
              }
              this.offset =
                i.$children[0].$refs[
                  this.currentRegion.sentence_id + "textareasource"
                ].selectionStart;
            } else {
              if (
                !i.$children[0].$refs[
                  this.currentRegion.sentence_id + "textareatrans"
                ]
              ) {
                return;
              }
              this.offset =
                i.$children[0].$refs[
                  this.currentRegion.sentence_id + "textareatrans"
                ].selectionStart;
            }
          } else {
            if (
              !i.$children[0].$refs[
                this.currentRegion.sentence_id + "textareasource"
              ]
            ) {
              return;
            }
            this.offset =
              i.$children[0].$refs[
                this.currentRegion.sentence_id + "textareasource"
              ].selectionStart;
          }

          if (!this.offset || !this.currentRegion.sentence_id) {
            return;
          }
          showLoading(this.$t("CommonName.loadingmessage"));
          this.split_segment(this.currentRegion);
        });
      }
      // 合并
      if (data == 3) {
        if (this.selectionRow.length > 1) {
          return;
        }
        if (
          this.currentRegion.sentence_id ==
          this.tableData[this.tableData.length - 1].sentence_id
        ) {
          this.$messages(
            "warning",
            this.$t("OverwriteOperation.warningmessage2")
          );
          return;
        }
        showLoading(this.$t("CommonName.loadingmessage"));
        this.tableData.map((i, index) => {
          if (i.sentence_id == this.currentRegion.sentence_id) {
            this.currentRegion.nsentence_id =
              this.tableData[index + 1].sentence_id;
          }
        });
        this.merge_segment(this.currentRegion);
      }
      // 删除
      if (data == 5) {
        showLoading(this.$t("CommonName.loadingmessage"));
        this.deleteFlag = true;
        this.delete_segment(this.selectionRow, this.selectionRow[0].data_start);
      }
      // 新增
      if (data == 2) {
        if (this.selectionRow.length > 1) {
          return;
        }
        this.get_new_segment(this.currentRegion);
      }
      // 单句内容翻译
      if (data == 6) {
        if (this.currentRegion.typeinput !== 2) {
          return;
        }
        showLoading(this.$t("CommonName.loadingmessage"));
        this.get_mt(this.currentRegion);
      }
      // Asr
      if (data == 1) {
        if (this.currentRegion.typeinput !== 1) {
          return;
        }
        showLoading(this.$t("CommonName.loadingmessage"));
        this.get_asr_content(this.currentRegion);
      }
      // 平移时间轴
      if (data == 9) {
        if (this.selectionRow.length > 1) {
          return;
        }
        this.form5 = {
          topradio: "1",
          time: "00:00:00.000",
          btmradio1: "1",
        };
        this.radiolist = [
          {
            key: "0",
            value: this.$t("OverwriteOperation.Selectedlinesonly"),
          },
          {
            key: "1",
            value: this.$t("OverwriteOperation.Alllinesafterselectedline1"),
          },
        ];
        this.dialogtitle = this.$t("OverwriteOperation.AjustTimecodes");
        this.dialogVisible = true;
        this.showfooter = true;
        this.footerbtnName = this.$t("OverwriteOperation.OK");
        this.closefooterbtnName = this.$t(
          "CommonName.deleteMessageClosebutton"
        );
        this.dialogWidth = "448px";
        this.showotherclosebtn = false;
      }
      // 在线ocr
      if (data === 11) {
        if (!this.player) {
          return;
        }
        if (this.selectionRow.length > 1) {
          return;
        }
        this.captionsObj = {};
        // console.log(1111111);
        // this.watermark.text = "";
        this.OpenCanvas();
      }
      // if (data == 0) {
      //   this.loopPlay = true;
      //   this.player = false;
      //   this.videoDom.play();
      //   this.setTimeInterval();
      // }
    },
    /**
     * 初始化 canvas
     * @param obj
     * @param row
     */
    initCanvas(obj, row) {
      if (this.canvas) {
        this.canvas.setWidth(1);
        this.canvas.dispose();
        this.canvas = null;
      }
      this.canvas = new fabric.Canvas("canvas");
      this.canvas.setWidth(this.videoDom.clientWidth);
      this.canvas.wrapperEl.style.position = "absolute";
      this.canvas.wrapperEl.style.zIndex = 130;
      this.canvas.on("mouse:down", this.canvasMouseDown); // 鼠标在画布上按下
      this.canvas.on("mouse:up", this.canvasMouseUp); // 鼠标在画布上松开
      // this.createRect(obj);
    },
    canvasMouseDown(e) {
      if (this.rect) {
        return;
      }
      // 鼠标左键按下时，将当前坐标 赋值给 downPoint。{x: xxx, y: xxx} 的格式
      this.downPoint = e.absolutePointer;
    },
    canvasMouseUp(e) {
      // 松开鼠标左键时，将当前坐标 赋值给 upPoint
      if (this.rect) {
        return;
      }
      this.upPoint = e.absolutePointer;
      if (
        this.upPoint.x < 0 ||
        this.upPoint.x > this.videoDom.clientWidth ||
        this.upPoint.y < 0 ||
        this.upPoint.y > this.videoDom.clientHeight
      ) {
        return;
      }
      // 调用 创建矩形 的方法
      this.createRect();
    },
    /**
     *  开启遮罩
     * @param data
     * @constructor
     */
    OpenCanvas() {
      // console.log(this.rect);
      if (this.rect) {
        return;
      }
      this.showCanvas = true;

      this.initCanvas(); //创建canvas
    },
    /**
     * 关闭遮罩
     * @param
     */
    closeCanvas(e) {
      this.canvas.setWidth(1);
      this.canvas.dispose();
      this.canvas = null;
      this.rect = null;
      this.showCanvas = false;
      this.captionsObj = this.currentRegion;
      // this.dataInfo.watermark_info.forEach((i) => {
      //   if (i.type == 3) {
      //     if (i.watermark !== "") {
      //       this.watermark.text = i.watermark;
      //     }
      //   }
      // });
      if (e) {
        e.stopPropagation();
      }
    },
    cutPicture(e) {
      var v = this.$refs.video1;
      let video = null;
      let videoHeight = 0;
      let videoWidth = 0;
      let tops = 0;
      let lefts = 0;
      let widths = 0;
      let heights = 0;
      video = document.createElement("video");
      video.src = this.videoUrl;
      document.body.appendChild(video);
      video.onloadedmetadata = () => {
        videoWidth = video.videoWidth;
        videoHeight = video.videoHeight;
        if (videoWidth > videoHeight) {
          heights = Number(
            (
              videoHeight / Number((videoWidth / v.clientWidth).toFixed(2))
            ).toFixed(2)
          );
          widths = v.clientWidth;
          if (heights > v.clientHeight) {
            heights = v.clientHeight;
            widths = Number(
              (
                videoWidth / Number((videoHeight / v.clientHeight).toFixed(2))
              ).toFixed(2)
            );
            lefts = Number(((v.clientWidth - widths) / 2).toFixed(2));
          }
          tops = (v.clientHeight - heights) / 2;
        } else {
          widths = Number(
            (
              videoWidth / Number((videoHeight / v.clientHeight).toFixed(2))
            ).toFixed(2)
          );
          heights = v.clientHeight;
          lefts = (v.clientWidth - widths) / 2;
        }
        showLoading(this.$t("CommonName.loadingmessage"));
        set_tag_batch_person({
          action: "get_ocr_result",
          job_id: this.job_id,
          // job_split_id: this.job_split_id,
          position_original_x: this.rect.left - lefts,
          position_original_y: this.rect.top - tops,
          position_original_w: this.rect.width * this.rect.scaleX,
          position_original_h: this.rect.height * this.rect.scaleY,
          original_width: widths,
          original_height: heights,
          time: this.videoDom.currentTime,
        }).then((res) => {
          if (!res) {
            hideLoading();
            return;
          }
          this.closeCanvas();
          const targetItem = this.tableData.find(
            (item) => item.sentence_id === this.currentRegion.sentence_id
          );
          if (targetItem) {
            console.log(targetItem);
            targetItem.showsourceInput = !targetItem.showsourceInput;
            targetItem.sentence_source += res.txt;
            targetItem.sentence_source_html += res.txt;
            let textarea = "";
            this.$refs.virtualref.$children.forEach((j) => {
              this.$nextTick(() => {
                textarea =
                  j.$children[0].$refs[
                    targetItem.sentence_id + "textareasource"
                  ];
                if (!textarea) {
                  return;
                }
                textarea.style.height = "auto";
                textarea.style.height = `${textarea.scrollHeight}px`;
              });
            });
          }
          hideLoading();
        });
      };

      document.body.removeChild(video);

      e.stopPropagation();
    },
    /**
     *  创建 canvas 矩形
     * @param obj
     */
    createRect(obj) {
      // 如果点击和松开鼠标，都是在同一个坐标点，不会生成矩形
      if (JSON.stringify(this.downPoint) === JSON.stringify(this.upPoint)) {
        return;
      }

      // 创建矩形
      // 矩形参数计算（前面总结的4条公式）
      let top = Math.min(this.downPoint.y, this.upPoint.y);
      let left = Math.min(this.downPoint.x, this.upPoint.x);
      let width = Math.abs(this.downPoint.x - this.upPoint.x);
      let height = Math.abs(this.downPoint.y - this.upPoint.y);

      // 矩形对象
      this.rect = new fabric.Rect({
        top,
        left,
        width,
        height,
        fill: "blue", // 填充色：透明
        stroke: "#46adff", // 边框颜色：黑色
        strokeWidth: 0.5, // 边框宽度
        cornerSize: 5,
        cornerColor: "#fff",
        lockRotation: true,
        strokeDashArray: [5, 7],
      });

      // 将矩形添加到画布上
      this.canvas.add(this.rect);

      let div = document.getElementsByClassName("rect_div")[0];
      // console.log(div, "div");
      div.style.top = top + "px";
      div.style.left = left + width + 10 + "px";
      // 创建完矩形，清空 downPoint 和 upPoint。当然，你也可以不做这步。
      this.downPoint = null;
      this.upPoint = null;
      // let left = 0;
      // let videoDom = {};
      // let option = {
      //   top: 0,
      //   left: 0,
      //   width: 0,
      //   height: 0,
      //   // fill: "transparent", // 填充色：透明
      //   fill: "blue", // 填充色：透明
      //   // fill: "rgba(255,255,255,0.1)",
      //   stroke: "#46adff", // 边框颜色：黑色
      //   strokeWidth: 0.5, // 边框宽度
      //   // strokeUniform: true,
      //   cornerSize: 5,
      //   cornerColor: "#fff",
      //   lockRotation: true,
      //   strokeDashArray: [5, 7],
      // };
      // videoDom = this.videoDom;
      // option.top = this.canvas.height - 50;
      // option.left = this.canvas.width / 2 - this.canvas.width / 4;
      // option.width = this.canvas.width / 2;
      // option.height = 40;
      //
      // // 矩形对象
      // this.rect = new fabric.Rect(option);
      // this.canvas.add(this.rect);
      // const mask = new fabric.Rect({
      //   left: 0,
      //   top: 0,
      //   width: this.canvas.width,
      //   height: this.canvas.height,
      //   // backgroundVpt: false,
      //   // height: 370,
      //   fill: "rgba(0,0,0,0.4)",
      //   // fill: "rgba(255,255,255,0.5)",
      //   selectable: false,
      // });
      //
      // // 将矩形添加到画布上
      //
      // this.canvas.add(mask);

      // mask.globalCompositeOperation = "source-over";
      // mask.globalCompositeOperation = "destination-out";
      this.rect.globalCompositeOperation = "destination-out";
      // this.rect.globalCompositeOperation = "source-over";
      this.canvas.bringToFront(this.rect);
      this.canvas.setActiveObject(this.rect);
      this.canvas.selection = true; // 允许框选
      this.canvas.selectionColor = "rgba(100, 100, 255, 0.3)"; // 选框填充色：半透明的蓝色
      this.canvas.selectionBorderColor = "rgba(255, 255, 255, 0.3)"; // 选框边框颜色：半透明灰色
      this.canvas.skipTargetFind = false; // 允许选中
      this.canvas.on("selection:cleared", () => {
        this.canvas.setActiveObject(this.rect);
      });
      // 监听 矩形移动事件
      this.canvas.off("object:moving").on("object:moving", (e) => {
        this.isMoveLeft = true;
        this.isMoveTop = true;
        var target = e.target;
        // console.log(target, "target");
        var canvasWidth = this.canvas.width;
        var canvasHeight = this.canvas.height;
        var left = target.left;
        var top = target.top;
        var maxLeft = canvasWidth - target.width * target.scaleX;
        var maxTop = canvasHeight - target.height * target.scaleY;
        if (left < 0) {
          target.left = 3;
        } else if (left > maxLeft) {
          target.left = maxLeft - 3;
        }
        if (top < 0) {
          target.top = 3;
        } else if (top > maxTop) {
          target.top = maxTop - 3;
        }
        let div = document.getElementsByClassName("rect_div")[0];
        // console.log(div, "div");
        div.style.top = 0;
        div.style.left = 0;
        div.style.top = target.top + "px";
        div.style.left = target.left + target.width * target.scaleX + 10 + "px";
      });
      this.rect.off("scaling").on("scaling", (e) => {
        var rect = e.transform.target;
        // 获取矩形的当前位置和大小
        var left = rect.left;
        var top = rect.top;
        var width = rect.width * rect.scaleX;
        var height = rect.height * rect.scaleY;
        // 获取画布的尺寸
        var canvasWidth = this.canvas.width;
        var canvasHeight = this.canvas.height;

        // 计算矩形边界
        var right = left + width;
        var bottom = top + height;
        // 检查是否超出画布边界，如果是，则限制大小

        if (height < 10) {
          rect.scaleY = 10 / rect.height;
        }
        if (width < 10) {
          rect.scaleX = 10 / rect.width;
        }
        if (left > 0 && right < canvasWidth) {
          this.isMoveLeft = true;
        }
        if (top > 0 && bottom < canvasHeight) {
          this.isMoveTop = true;
        }
        if (left < 0) {
          rect.left = 3;
          if (this.isMoveLeft) {
            rect.scaleX = (width - 3 + left) / rect.width;
            this.maxScaleX = rect.scaleX;
            this.isMoveLeft = false;
          } else {
            // console.log(this.maxScaleX, "maxScaleX");
            rect.scaleX = this.maxScaleX;
          }
        }

        if (right > canvasWidth) {
          rect.scaleX = (canvasWidth - 5 - left) / rect.width;
        }
        if (top < 0) {
          rect.top = 3;
          if (this.isMoveTop) {
            rect.scaleY = (height - 3 + top) / rect.height;
            this.maxScaleY = rect.scaleY;
            this.isMoveTop = false;
          } else {
            rect.scaleY = this.maxScaleY;
          }
        }
        if (bottom > canvasHeight) {
          rect.scaleY = (canvasHeight - 3 - top) / rect.height;
        }
        let div = document.getElementsByClassName("rect_div")[0];
        // console.log(div, "div");
        div.style.top = 0;
        div.style.left = 0;
        div.style.top = rect.top + "px";
        // console.log(rect, " rect");
        div.style.left = rect.left + rect.width * rect.scaleX + 10 + "px";
      });
    },
    /**
     *
     * @param {* 点击 单句播放} data 0可以 播放 1展厅
     */
    areaPlayClick(data) {
      if (!this.currentRegion.sentence_id) {
        return;
      }
      if (this.selectionRow.length > 1) {
        return;
      }
      if (!this.segment) {
        return;
      }
      if (data == 0) {
        this.loopPlay = true;
        this.player = false;
        this.showloopbtn = false;
        this.videoDom.play();
        this.setTimeInterval();
      } else {
        clearInterval(this.currentRegionInterval);
        this.currentRegionInterval = null;
        this.loopPlay = false;
        this.player = true;
        this.showloopbtn = true;
        this.videoDom.pause();
      }
    },
    /**
     * 鼠标划上 字符设置  是否显示弹窗
     * @param val
     */
    handleDropdown(val) {
      // console.log(val);
      if (val == 1) {
        this.handleDropdownShow1 = true;
      } else {
        this.handleDropdownShow1 = false;
      }
      if (val == 2) {
        this.handleDropdownShow2 = true;
      } else {
        this.handleDropdownShow2 = false;
      }
      if (val == 3) {
        this.handleDropdownShow3 = true;
      } else {
        this.handleDropdownShow3 = false;
      }
    },
    /**
     * 点击 弹窗内 按钮 触发 添加 符号
     * @param val
     */
    handleDropdownDivClick(val) {
      if (val == 1) {
        this.dropdownTag = "♪";
        this.dropdownType = 4;
      } else if (val == 2) {
        this.dropdownTag = "♫";
        this.dropdownType = 5;
      } else if (val == 3) {
        this.dropdownTag = "♪";
        this.dropdownType = 15;
      } else {
        this.dropdownTag = "♫";
        this.dropdownType = 16;
      }
    },
    /**
     * 点击选择 括号 种类
     * @param val
     */
    handleDropdownDiv3Click(val) {
      switch (val) {
        case 1:
          // this.dropdownTag2 = "（";
          this.dropdownType2 = 17;
          break;
        case 2:
          // this.dropdownTag2 = "(";
          this.dropdownType2 = 18;
          break;
        case 3:
          // this.dropdownTag2 = "[";
          this.dropdownType2 = 19;
          break;
        default:
          break;
      }
    },
    /**
     * 判断是否 是否带有 特定位置的字幕
     * @param text
     * @constructor
     */
    SetSubtitlePosition(text) {
      this.$nextTick(() => {
        let div = document.querySelector(".left_video_div_div");
        let videodiv = document.getElementById("video1").clientHeight;
        // let p = div
        //   .querySelector(".left_video_div_div_pdiv")
        //   .querySelectorAll("p");
        let a =
          (100 - +this.dataInfo.base_info.front_range) / 2 +
          +this.dataInfo.base_info.front_range -
          +this.dataInfo.base_info.rear_range / 2 +
          "%";
        const regex = /^{\\(an[123456789])}/;
        const match = regex.exec(text);
        if (match) {
          switch (match[1]) {
            case "an7":
              div.style.top = this.dataInfo.base_info.front_range + "%";
              div.style.bottom = "auto";
              $(".left_video_div_div_pdiv p").css({
                marginRight: "auto",
                marginLeft: this.dataInfo.base_info.left_range + "%",
              });
              // p.style.marginRight = "auto";

              // p.style.marginLeft = this.dataInfo.base_info.left_range + "%";
              break;
            case "an8":
              div.style.top = this.dataInfo.base_info.front_range + "%";
              div.style.bottom = "auto";
              $(".left_video_div_div_pdiv p").css({
                margin: "0 auto",
              });
              // p.style.margin = "0 auto";
              break;
            case "an9":
              // p.style.marginLeft = "auto";
              div.style.top = this.dataInfo.base_info.front_range + "%";
              div.style.bottom = "auto";
              // p.style.marginRight = this.dataInfo.base_info.right_range + "%";
              $(".left_video_div_div_pdiv p").css({
                marginLeft: "auto",
                marginRight: this.dataInfo.base_info.right_range + "%",
              });
              break;
            case "an4":
              // p.style.marginRight = "auto";
              div.style.top = `calc(${a} - ${div.clientHeight / 2}px)`;
              div.style.bottom = "auto";
              // p.style.marginLeft = this.dataInfo.base_info.left_range + "%";
              $(".left_video_div_div_pdiv p").css({
                marginRight: "auto",
                marginLeft: this.dataInfo.base_info.left_range + "%",
              });
              break;
            case "an5":
              div.style.top = `calc(${a} - ${div.clientHeight / 2}px)`;
              div.style.bottom = "auto";
              $(".left_video_div_div_pdiv p").css({
                margin: "0 auto",
              });
              // p.style.margin = "0 auto";
              break;
            case "an6":
              // p.style.marginLeft = "auto";
              div.style.top = `calc(${a} - ${div.clientHeight / 2}px)`;
              div.style.bottom = "auto";
              $(".left_video_div_div_pdiv p").css({
                marginLeft: "auto",
                marginRight: this.dataInfo.base_info.right_range + "%",
              });
              // p.style.marginRight = this.dataInfo.base_info.right_range + "%";
              break;
            case "an1":
              // p.style.marginRight = "auto";
              div.style.top = "auto";
              div.style.bottom = this.dataInfo.base_info.rear_range + "%";
              // p.style.marginLeft = this.dataInfo.base_info.left_range + "%";
              $(".left_video_div_div_pdiv p").css({
                marginRight: "auto",
                marginLeft: this.dataInfo.base_info.left_range + "%",
              });
              break;
            case "an2":
              div.style.top = "auto";
              div.style.bottom = this.dataInfo.base_info.rear_range + "%";
              $(".left_video_div_div_pdiv p").css({
                margin: "0 auto",
              });
              // p.style.margin = "0 auto";
              break;
            case "an3":
              // p.style.marginLeft = "auto";
              div.style.top = "auto";
              div.style.bottom = this.dataInfo.base_info.rear_range + "%";
              // p.style.marginRight = this.dataInfo.base_info.right_range + "%";
              $(".left_video_div_div_pdiv p").css({
                marginLeft: "auto",
                marginRight: this.dataInfo.base_info.right_range + "%",
              });
              break;
            default:
              break;
          }
        } else {
          if (this.showSafetyline === false) {
            if (this.DragPosition > 0) {
              div.style.top = this.DragPosition + "px";
              div.style.bottom = "auto";
            } else {
              // div.style.top = "auto";
              // div.style.bottom = 60 + "px";
              div.style.bottom = "auto";
              div.style.top = videodiv - 60 - div.clientHeight + "px";
            }
          } else {
            if (this.DragPosition > 0) {
              div.style.top = this.DragPosition + "px";
              div.style.bottom = "auto";
            } else {
              div.style.top = "auto";
              div.style.bottom = this.dataInfo.base_info.rear_range + "%";
            }
          }
          $(".left_video_div_div_pdiv p").css({
            margin: "0 auto",
          });
          // p.style.margin = "0 auto";
        }
      });
    },
    /**
     * 点击字幕设置  设置 字幕位置
     * @param id
     */
    handlehandleDropdown2Click(id) {
      let div = document.querySelector(".left_video_div_div");
      // let p = div
      //   .querySelector(".left_video_div_div_pdiv")
      //   .querySelectorAll("p");
      // console.log(p);
      let a =
        (100 - +this.dataInfo.base_info.front_range) / 2 +
        +this.dataInfo.base_info.front_range -
        +this.dataInfo.base_info.rear_range / 2 +
        "%";
      switch (id) {
        case 1:
          this.dropdownTag1 = "{\\an7}";
          this.dropdownType1 = 6;
          // div.style.top = this.dataInfo.base_info.front_range + "%";
          // div.style.bottom = "auto";
          // $(".left_video_div_div_pdiv p").css(
          //   "marginLeft",
          //   this.dataInfo.base_info.left_range + "%"
          // );
          break;
        case 2:
          this.dropdownTag1 = "{\\an8}";
          this.dropdownType1 = 7;
          // div.style.top = this.dataInfo.base_info.front_range + "%";
          // div.style.bottom = "auto";
          // $(".left_video_div_div_pdiv p").css("margin", "0 auto");
          // p[0].style.margin = "0 auto";
          // p[1].style.margin = "0 auto";
          break;
        case 3:
          this.dropdownTag1 = "{\\an9}";
          this.dropdownType1 = 8;
          // div.style.top = this.dataInfo.base_info.front_range + "%";
          // div.style.bottom = "auto";
          // $(".left_video_div_div_pdiv p").css(
          //   "marginRight",
          //   this.dataInfo.base_info.right_range + "%"
          // );
          // p[0].style.marginRight = this.dataInfo.base_info.right_range + "%";
          // p[1].style.marginRight = this.dataInfo.base_info.right_range + "%";
          break;
        case 4:
          this.dropdownTag1 = "{\\an4}";
          this.dropdownType1 = 9;
          // div.style.top = `calc(${a} - ${div.clientHeight / 2}px)`;
          // div.style.bottom = "auto";
          // $(".left_video_div_div_pdiv p").css(
          //   "marginLeft",
          //   this.dataInfo.base_info.left_range + "%"
          // );
          // p[0].style.marginLeft = this.dataInfo.base_info.left_range + "%";
          // p[1].style.marginLeft = this.dataInfo.base_info.left_range + "%";
          break;
        case 5:
          this.dropdownTag1 = "{\\an5}";
          this.dropdownType1 = 10;
          // div.style.top = `calc(${a} - ${div.clientHeight / 2}px)`;
          // div.style.bottom = "auto";
          // $(".left_video_div_div_pdiv p").css("margin", "0 auto");
          // p[0].style.margin = "0 auto";
          // p[1].style.margin = "0 auto";
          break;
        case 6:
          this.dropdownTag1 = "{\\an6}";
          this.dropdownType1 = 11;
          // div.style.top = `calc(${a} - ${div.clientHeight / 2}px)`;
          // div.style.bottom = "auto";
          // $(".left_video_div_div_pdiv p").css(
          //   "marginRight",
          //   this.dataInfo.base_info.right_range + "%"
          // );
          // p[0].style.marginRight = this.dataInfo.base_info.right_range + "%";
          // p[1].style.marginRight = this.dataInfo.base_info.right_range + "%";
          break;
        case 7:
          this.dropdownTag1 = "{\\an1}";
          this.dropdownType1 = 12;
          // div.style.top = "auto";
          // div.style.bottom = this.dataInfo.base_info.rear_range + "%";
          // $(".left_video_div_div_pdiv p").css(
          //   "marginLeft",
          //   this.dataInfo.base_info.left_range + "%"
          // );
          // p.style.marginLeft = this.dataInfo.base_info.left_range + "%";
          break;
        case 8:
          this.dropdownTag1 = "{\\an2}";
          this.dropdownType1 = 13;
          // div.style.top = "auto";
          // div.style.bottom = this.dataInfo.base_info.rear_range + "%";
          // $(".left_video_div_div_pdiv p").css("margin", "0 auto");
          // p.style.margin = "0 auto";
          break;
        case 9:
          this.dropdownTag1 = "{\\an3}";
          this.dropdownType1 = 14;
          // div.style.top = "auto";
          // div.style.bottom = this.dataInfo.base_info.rear_range + "%";
          // $(".left_video_div_div_pdiv p").css(
          //   "marginRight",
          //   this.dataInfo.base_info.right_range + "%"
          // );
          // p.style.marginRight = this.dataInfo.base_info.right_range + "%";
          break;
        default:
          break;
      }
    },
    /**
     * 清除 划上显示框
     * @param val
     */
    stylevisiblechange(val) {
      // console.log(val);
      if (val == true) {
        this.handleDropdownShow1 = false;
        this.handleDropdownShow2 = false;
        this.handleDropdownShow3 = false;
      }
    },
    /**
     *级联 下拉菜单 选择
     * @param val
     */
    statuscommand(val) {
      if (!this.currentRegion.sentence_id) {
        return;
      }
      if (val.id === 4 || val.id === 6 || val.id === 3 || val.id === 7) {
        if (val.type === 0) return;
      }
      if (this.selectionRow.length > 1) {
        // console.log(this.selectionRow);
        this.selectionRow.forEach((i) => {
          let targetItem = this.tableData.find(
            (item) => item.sentence_id === i.sentence_id
          );

          if (targetItem) {
            // console.log(targetItem, "targetItem");
            if (val.id !== 5) {
              if (val.id == 3) {
                if (val.type !== 0) {
                  if (val.type == 15 || val.type == 16) {
                    targetItem.sentence_source = `${val.tag + " "}${
                      targetItem.sentence_source
                    }${" " + val.tag}`;
                    targetItem.sentence_source_html = `${val.tag + " "}${
                      targetItem.sentence_source_html
                    }${" " + val.tag}`;
                    targetItem.sentence_trans = `${val.tag + " "}${
                      targetItem.sentence_trans
                    }${" " + val.tag}`;
                    targetItem.sentence_trans_html = `${val.tag + " "}${
                      targetItem.sentence_trans_html
                    }${" " + val.tag}`;
                  } else {
                    targetItem.sentence_source = `${val.tag}${targetItem.sentence_source}${val.tag}`;
                    targetItem.sentence_trans = `${val.tag}${targetItem.sentence_trans}${val.tag}`;
                    targetItem.sentence_source_html = `${val.tag}${targetItem.sentence_source_html}${val.tag}`;
                    targetItem.sentence_trans_html = `${val.tag}${targetItem.sentence_trans_html}${val.tag}`;
                  }
                }
              } else if (val.id == 4) {
                if (val.type !== 0) {
                  targetItem.sentence_source = `${val.tag}${targetItem.sentence_source}`;
                  targetItem.sentence_trans = `${val.tag}${targetItem.sentence_trans}`;
                  targetItem.sentence_source_html = `${val.tag}${targetItem.sentence_source_html}`;
                  targetItem.sentence_trans_html = `${val.tag}${targetItem.sentence_trans_html}`;
                }
              } else if (val.id == 6) {
                switch (val.type) {
                  case 17:
                    targetItem.sentence_source = `（${targetItem.sentence_source}）`;
                    targetItem.sentence_source_html = `（${targetItem.sentence_source_html}）`;
                    targetItem.sentence_trans = `（${targetItem.sentence_trans}）`;
                    targetItem.sentence_trans_html = `（${targetItem.sentence_trans_html}）`;
                    break;
                  case 18:
                    targetItem.sentence_source = `(${targetItem.sentence_source})`;
                    targetItem.sentence_source_html = `(${targetItem.sentence_source_html})`;
                    targetItem.sentence_trans = `(${targetItem.sentence_trans})`;
                    targetItem.sentence_trans_html = `(${targetItem.sentence_trans_html})`;
                    break;
                  case 19:
                    targetItem.sentence_source = `[${targetItem.sentence_source}]`;
                    targetItem.sentence_source_html = `[${targetItem.sentence_source_html}]`;
                    targetItem.sentence_trans = `[${targetItem.sentence_trans}]`;
                    targetItem.sentence_trans_html = `[${targetItem.sentence_trans_html}]`;
                    break;
                  default:
                    break;
                }
              } else {
                let newText = `<${val.tag}>${targetItem.sentence_source_html}</${val.tag}>`;
                let newText1 = `<${val.tag}>${targetItem.sentence_trans_html}</${val.tag}>`;

                targetItem.sentence_source = `<${val.tag}>${targetItem.sentence_source}</${val.tag}>`;
                targetItem.sentence_trans = `<${val.tag}>${targetItem.sentence_trans}</${val.tag}>`;
                targetItem.sentence_source_html = this.escapeHTML(
                  this.decodeHtmlEntities(newText)
                );
                targetItem.sentence_trans_html = this.escapeHTML(
                  this.decodeHtmlEntities(newText1)
                );
              }
            } else {
              targetItem.sentence_source = targetItem.sentence_source
                .replace(
                  /<\/?b>|<\/?i>|<\/?u>|♫|♪|\[|\]|\(|\)|\（|\）|{\\an[789456123]}/g,
                  ""
                )
                .trim();
              targetItem.sentence_trans = targetItem.sentence_trans
                .replace(
                  /<\/?b>|<\/?i>|<\/?u>|♫|♪|\[|\]|\(|\)|\（|\）|{\\an[789456123]}/g,
                  ""
                )
                .trim();
              targetItem.sentence_source_html = this.decodeHtmlEntities(
                targetItem.sentence_source_html
              )
                .replace(
                  /<\/?b>|<\/?i>|<\/?u>|♫|♪|\[|\]|\(|\)|\（|\）|{\\an[789456123]}/g,
                  ""
                )
                .trim();
              targetItem.sentence_trans_html = this.decodeHtmlEntities(
                targetItem.sentence_trans_html
              )
                .replace(
                  /<\/?b>|<\/?i>|<\/?u>|♫|♪|\[|\]|\(|\)|\（|\）|{\\an[789456123]}/g,
                  ""
                )
                .trim();
              // let div = document.querySelector(".left_video_div_div");
              // let p = div
              //   .querySelector(".left_video_div_div_pdiv")
              //   .querySelector("p");
              // console.log(this.showSafetyline);
              // if (this.showSafetyline == false) {
              //   if (this.DragPosition > 0) {
              //     div.style.top = this.DragPosition + "px";
              //     div.style.bottom = "auto";
              //   } else {
              //     div.style.top = "auto";
              //     div.style.bottom = 60 + "px";
              //   }
              // } else {
              //   if (this.DragPosition > 0) {
              //     div.style.top = this.DragPosition + "px";
              //     div.style.bottom = "auto";
              //   } else {
              //     div.style.top = "auto";
              //     div.style.bottom = this.dataInfo.base_info.rear_range + "%";
              //   }
              // }
              // // p.style.margin = "0 auto";
              // $(".left_video_div_div_pdiv p").css("margin", "0 auto");
            }
            if (this.dataInfo.base_info.show) {
              targetItem = this.setNumbererr(
                targetItem,
                1,
                targetItem.sentence_source
              );
            } else {
              targetItem = this.setNumbererr(
                targetItem,
                1,
                targetItem.sentence_source
              );
              targetItem = this.setNumbererr(
                targetItem,
                2,
                targetItem.sentence_trans
              );
            }
          }
        });
        // console.log(val.type, "val");
        this.set_tag_batch_person(this.selectionRow, val.type);
      } else {
        let targetItem = this.tableData.find(
          (item) => item.sentence_id === this.currentRegion.sentence_id
        );
        if (targetItem) {
          if (val.id !== 5) {
            if (val.id == 3) {
              if (val.type !== 0) {
                if (val.type == 15 || val.type == 16) {
                  targetItem.sentence_source = `${val.tag + " "}${
                    targetItem.sentence_source
                  }${" " + val.tag}`;
                  targetItem.sentence_source_html = `${val.tag + " "}${
                    targetItem.sentence_source_html
                  }${" " + val.tag}`;
                  targetItem.sentence_trans = `${val.tag + " "}${
                    targetItem.sentence_trans
                  }${" " + val.tag}`;
                  targetItem.sentence_trans_html = `${val.tag + " "}${
                    targetItem.sentence_trans_html
                  }${" " + val.tag}`;
                } else {
                  targetItem.sentence_source = `${val.tag}${targetItem.sentence_source}${val.tag}`;
                  targetItem.sentence_trans = `${val.tag}${targetItem.sentence_trans}${val.tag}`;
                  targetItem.sentence_source_html = `${val.tag}${targetItem.sentence_source_html}${val.tag}`;
                  targetItem.sentence_trans_html = `${val.tag}${targetItem.sentence_trans_html}${val.tag}`;
                }
              }
            } else if (val.id == 4) {
              if (val.type !== 0) {
                targetItem.sentence_source = `${val.tag}${targetItem.sentence_source}`;
                targetItem.sentence_trans = `${val.tag}${targetItem.sentence_trans}`;
                targetItem.sentence_source_html = `${val.tag}${targetItem.sentence_source_html}`;
                targetItem.sentence_trans_html = `${val.tag}${targetItem.sentence_trans_html}`;
              }
            } else if (val.id == 6) {
              switch (val.type) {
                case 17:
                  targetItem.sentence_source = `（${targetItem.sentence_source}）`;
                  targetItem.sentence_source_html = `（${targetItem.sentence_source_html}）`;
                  targetItem.sentence_trans = `（${targetItem.sentence_trans}）`;
                  targetItem.sentence_trans_html = `（${targetItem.sentence_trans_html}）`;
                  break;
                case 18:
                  targetItem.sentence_source = `(${targetItem.sentence_source})`;
                  targetItem.sentence_source_html = `(${targetItem.sentence_source_html})`;
                  targetItem.sentence_trans = `(${targetItem.sentence_trans})`;
                  targetItem.sentence_trans_html = `(${targetItem.sentence_trans_html})`;
                  break;
                case 19:
                  targetItem.sentence_source = `[${targetItem.sentence_source}]`;
                  targetItem.sentence_source_html = `[${targetItem.sentence_source_html}]`;
                  targetItem.sentence_trans = `[${targetItem.sentence_trans}]`;
                  targetItem.sentence_trans_html = `[${targetItem.sentence_trans_html}]`;
                  break;
                default:
                  break;
              }
            } else {
              let newText = `<${val.tag}>${targetItem.sentence_source_html}</${val.tag}>`;
              let newText1 = `<${val.tag}>${targetItem.sentence_trans_html}</${val.tag}>`;

              targetItem.sentence_source = `<${val.tag}>${targetItem.sentence_source}</${val.tag}>`;
              targetItem.sentence_trans = `<${val.tag}>${targetItem.sentence_trans}</${val.tag}>`;
              targetItem.sentence_source_html = this.escapeHTML(
                this.decodeHtmlEntities(newText)
              );
              targetItem.sentence_trans_html = this.escapeHTML(
                this.decodeHtmlEntities(newText1)
              );
            }
            let textarea = "";
            this.$refs.virtualref.$children.forEach((j) => {
              this.$nextTick(() => {
                if (this.currentRegion.data == 1) {
                  textarea =
                    j.$children[0].$refs[
                      targetItem.sentence_id + "textareasource"
                    ];
                } else {
                  textarea =
                    j.$children[0].$refs[
                      targetItem.sentence_id + "textareatrans"
                    ];
                }
                if (!textarea) {
                  return;
                }
                textarea.style.height = "auto";
                textarea.style.height = `${textarea.scrollHeight}px`;
              });
            });
          } else {
            targetItem.sentence_source = targetItem.sentence_source
              .replace(
                /<\/?b>|<\/?i>|<\/?u>|♫|♪|\[|\]|\(|\)|\（|\）|{\\an[789456123]}/g,
                ""
              )
              .trim();
            targetItem.sentence_trans = targetItem.sentence_trans
              .replace(
                /<\/?b>|<\/?i>|<\/?u>|♫|♪|\[|\]|\(|\)|\（|\）|{\\an[789456123]}/g,
                ""
              )
              .trim();
            targetItem.sentence_source_html = this.decodeHtmlEntities(
              targetItem.sentence_source_html
            )
              .replace(
                /<\/?b>|<\/?i>|<\/?u>|♫|♪|\[|\]|\(|\)|\（|\）|{\\an[789456123]}/g,
                ""
              )
              .trim();
            targetItem.sentence_trans_html = this.decodeHtmlEntities(
              targetItem.sentence_trans_html
            )
              .replace(
                /<\/?b>|<\/?i>|<\/?u>|♫|♪|\[|\]|\(|\)|\（|\）|{\\an[789456123]}/g,
                ""
              )
              .trim();
            // let div = document.querySelector(".left_video_div_div");
            // if (this.showSafetyline == false) {
            //   if (this.DragPosition > 0) {
            //     div.style.top = this.DragPosition + "px";
            //     div.style.bottom = "auto";
            //   } else {
            //     div.style.top = "auto";
            //     div.style.bottom = 60 + "px";
            //   }
            // } else {
            //   if (this.DragPosition > 0) {
            //     div.style.top = this.DragPosition + "px";
            //     div.style.bottom = "auto";
            //   } else {
            //     div.style.top = "auto";
            //     div.style.bottom = this.dataInfo.base_info.rear_range + "%";
            //   }
            // }
            // $(".left_video_div_div_pdiv p").css("margin", "0 auto");
          }
          let data = [];
          if (!this.dataInfo.base_info.show) {
            data = [1, 2];
          } else {
            data = [1];
          }

          let vals = "";
          data.forEach((i) => {
            if (i == 1) {
              this.set_source_content(targetItem);
            } else {
              this.set_trans_content(targetItem);
            }
            if (this.textareaBlues) {
              if (this.currentRegion.data == 1) {
                vals = targetItem.sentence_source;
              } else {
                vals = targetItem.sentence_trans;
              }
              targetItem = this.setNumbererr(
                targetItem,
                this.currentRegion.data,
                vals
              );
            } else {
              if (i == 1) {
                vals = targetItem.sentence_source;
              } else {
                vals = targetItem.sentence_trans;
              }
              targetItem = this.setNumbererr(targetItem, i, vals);
            }
          });
        }
      }
      // this.tableData = this.steTableData(this.tableData, false);
      this.dropdownType = 0; //点击 赋值 大小音乐符号
      this.dropdownType1 = 0; //点击赋值 字幕位置
      this.dropdownType2 = 0; //点击赋值 括号
    },
    /**
     * 反转义
     * @param text
     * @returns {string}
     */
    decodeHtmlEntities(text) {
      const textarea = document.createElement("textarea");
      textarea.innerHTML = text;
      return textarea.value;
    },
    /**
     * 转义
     * @param text
     * @returns {string}
     */
    escapeHTML(text) {
      const div = document.createElement("div");
      div.textContent = text;
      return div.innerHTML;
    },
    /**
     *
     * @param {* 点击展示  输入框 获取焦点} data
     */
    showpopoverInput() {
      this.$nextTick(() => {
        this.$refs.popoverInputref.focus();
      });
    },
    /**
     *
     * @param time
     * @returns {string}
     */
    // formattedTime(time) {
    //   const [hours, minutes, seconds] = time.split(":").map(Number);
    //   const totalSeconds = hours * 3600 + minutes * 60 + seconds;
    //   return totalSeconds; // 返回总秒数
    // },
    timeToSeconds(timeStr) {
      // 使用正则表达式分割时间字符串
      const [hours, minutes, seconds, milliseconds] = timeStr
        .split(/:/)
        .map(Number);
      console.log(hours, minutes, seconds, milliseconds);
      // 如果毫秒不为空，则将其转换为0.001秒并加到总秒数上
      if (milliseconds) {
        const [ms1, ms2, ms3] = milliseconds.split(".").map(Number);
        const millisecondsInSeconds = ms1 + ms2 / 100 + ms3 / 10000;
        return hours * 3600 + minutes * 60 + seconds + millisecondsInSeconds;
      } else {
        return hours * 3600 + minutes * 60 + seconds;
      }
    },
    /**
     * 点击 GO to 中的 按钮
     */
    searchdetermineClick() {
      if (!this.popoverInput) {
        return;
      }
      if (this.popoverInput.indexOf(":") > -1) {
        this.popoverInput = this.popoverInput.trim();
        var regex =
          /^(?:2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9](?:[.,]\d{1,3})?$/;
        // 检查时间是否符合格式要求
        if (!regex.test(this.popoverInput)) {
          // 请输入正确格式时间
          this.$messages(
            "warning",
            this.$t("OverwriteOperation.warningmessage1")
          );
          return;
        }
        let a = getStringToTime(this.popoverInput);
        if (isNaN(a)) {
          // 请输入正确格式时间
          this.$messages(
            "warning",
            this.$t("OverwriteOperation.warningmessage1")
          );
          return;
        }

        if (+a > +this.tableData[this.tableData.length - 1].data_end) {
          this.$refs.virtualref.scrollToBottom();
          this.Jumpposition(this.tableData[this.tableData.length - 1]);
          this.setselectionRow(this.tableData[this.tableData.length - 1]);
          this.$messages(
            "warning",
            this.$t("OverwriteOperation.warningmessage21")
          );

          return;
        }
        let targetItem = this.tableData.find(
          (item) => +a >= +item.data_start && +a <= +item.data_end
        );
        if (targetItem) {
          this.Jumpposition(targetItem);
          this.setselectionRow(targetItem);
          this.jumpgo(targetItem.number);
          this.srtTrCurIndex(targetItem.number - 1);
          // this.currentIndex = targetItem.number;
        } else {
          for (let index = 0; index < this.tableData.length; index++) {
            if (+a < this.tableData[this.tableData.length - 1].data_end) {
              if (
                +a > parseFloat(this.tableData[index].data_end) &&
                +a < parseFloat(this.tableData[index + 1].data_start)
              ) {
                this.currentRegion = {};
                this.Jumpposition(this.tableData[index + 1]);
                this.setselectionRow(this.tableData[index + 1]);
                this.jumpgo(this.tableData[index + 1].number);
                this.srtTrCurIndex(this.tableData[index + 1].number - 1);
                // this.currentIndex = this.tableData[index].number;
              }
            } else {
              this.$refs.virtualref.scrollToBottom();
              this.Jumpposition(this.tableData[this.tableData.length - 1]);
              this.setselectionRow(this.tableData[this.tableData.length - 1]);
              // this.currentIndex = this.tableData.length - 1;
            }
          }
          this.videoDom.currentTime = +a;
          this.$messages(
            "warning",
            this.$t("OverwriteOperation.warningmessage21")
          );
          return;
        }
      } else {
        if (isNaN(+this.popoverInput)) {
          return;
        }
        if (+this.popoverInput > this.tableData.length) {
          this.$messages(
            "warning",
            this.$t("OverwriteOperation.warningmessage9")
          );
          this.$refs.virtualref.scrollToBottom();
          this.Jumpposition(this.tableData[this.tableData.length - 1]);
          this.setselectionRow(this.tableData[this.tableData.length - 1]);
          return;
        }
        this.tableData.map((i, index) => {
          if (index + 1 === Number(this.popoverInput)) {
            this.Jumpposition(i);
            this.setselectionRow(i);
            this.jumpgo(this.popoverInput);
            this.srtTrCurIndex(index);
            this.currentIndex = index + 1;
          } else {
            // this.$messages("warning", "句段不存在");
            return;
          }
        });
      }
      this.visible = false;
    },
    /**
     * 根据选择区域来添加高亮
     * @param row
     * @param rowIndex
     * @returns {string}
     */
    tableRowClassName({ row, rowIndex }) {
      // console.log(this.currentRegion.sentence_id, row.sentence_id);
      if (this.currentRegion.sentence_id == row.sentence_id) {
        // console.log(rowIndex);
        // 通过区域id来判断
        //跳转到 高亮区域
        // if (!this.player || this.visible) {
        //   this.Highlightjump();
        // }
        //     scrollTo(0, top2 - 240);
        // this.$refs.multipleTable.setCurrentRow(this.tableData[rowIndex], true);
        return "success-row";
      }
      return "";
    },
    // /**
    //  *
    //  * @param {* 点击 表格 某一项} row
    //  */
    // tabrowClick(row) {
    //   this.tabrowData = row;
    //   this.tableData.forEach((i, index) => {
    //     if (row.sentence_id == i.sentence_id) {
    //       this.Jumpposition(i);
    //       this.jumpgo(index + 1);
    //     }
    //   });
    // },
    /**
     *
     * @param {*tab 页 错误检查 给表格加高亮} param0
     */
    tabtableRowClassName({ row }) {
      // console.log(row);
      if (
        row.sentence_id == this.tabrowData.sentence_id &&
        row.errorData == this.tabrowData.errorData
      ) {
        return "success-row";
      }
    },
    workOrderClick() {
      // console.log(11111111111);
      // this.$refs.multipleTable.setCurrentRow(-1);
    },
    /**
     *
     * @param {* 显示 字幕 波形下} j
     */
    getWavesurferRegion(j) {
      this.Reginleft = [];
      const wave = document.getElementsByTagName("wave")[0].scrollWidth;
      const a = document.getElementById("reginscroll1");
      let reginscroll;
      if (this.dataInfo.base_info.show) {
        reginscroll = document.getElementById("reginscroll");
      } else {
        reginscroll = document.getElementById("reginscroll2");
      }
      a.style.width = wave + "px";
      reginscroll.style.overflowX = "hidden";
      const b = document.querySelectorAll(".wavesurfer-region");
      b.forEach((i) => {
        if (i.getAttribute("data-id") == j.sentence_id) {
          // console.log(j.sentence_source);
          this.Reginleft.push({
            id: j.sentence_id,
            width: i.offsetWidth,
            left: i.offsetLeft,
            subtitle: j.sentence_source,
            sentence_trans: !this.dataInfo.base_info.show
              ? j.sentence_trans
              : "",
            showdropdown: false,
          });
        }
      });
    },
    /**
     *
     * @param {*点击头部} row
     */
    headerClick() {
      if (this.player) {
        this.tabrowData = {};
        this.initPosition();
        if (this.showCanvas) {
          this.closeCanvas();
        }
      }
    },
    cellClick(row, column, cell, event) {
      if (!column.className) {
        row.data == "0";
      }
      // console.log(row, column, cell, event);
    },
    // /**
    //  *
    //  * @param {*点击 切换输入框} row
    //  * @param {*} data
    //  */
    // cellPClick(row, data) {
    //   switch (data) {
    //     case 1:
    //       row.showStartInput = !row.showStartInput;
    //       break;
    //     case 2:
    //       row.showEndInput = !row.showEndInput;
    //       break;
    //     case 3:
    //       row.showsourceInput = !row.showsourceInput;
    //       break;
    //     default:
    //       row.showtransInput = !row.showtransInput;
    //       break;
    //   }
    // },
    /** @keyup.native="SendEvent($event)"
     * 阻止 换行行为
     */
    // SendEvent(event, data) {
    //   if (!data) {
    //     return;
    //   }
    //   if (data.split("\n").length > 1) {
    //     if (event.keyCode == 13) {
    //       event.returnValue = false;
    //       return false;
    //     }
    //   } else {
    //     if (event.keyCode == 13) {
    //       event.returnValue = true;
    //       return true;
    //     }
    //   }
    // },
    /**
     *
     * @param {*阻止换行行为} event
     */
    SendEventTimeInput(event) {
      event.returnValue = false;
      return false;
    },
    // /**
    //  *
    //  * @param {表格内输入框 失去焦点} val
    //  * @param {*} row
    //  */
    // textareaBlue(val, row, data, e) {
    //   if (data == 1) {
    //     this.set_source_content(row);
    //     row.showsourceInput = !row.showsourceInput;
    //   } else {
    //     this.set_trans_content(row);
    //     row.showtransInput = !row.showtransInput;
    //   }
    //   this.offset = e.srcElement.selectionStart;
    //   row = this.setNumber(row);
    //   // row.data = 0;
    // },
    /**
     *
     * @param {* 设置失去焦点 和 正在输入 时 飘红} row
     * @param {*} data
     */
    setNumbererr(row, data, val) {
      row.charNumber = this.filter_html_auto(val);
      row.readingNumber = (
        Number(row.charNumber.replace(/\n/, "").length) / Number(row.duration)
      ).toFixed(1);
      let a =
        data === 1
          ? this.caption_info.langs[0].char_line
          : this.caption_info.langs[1].char_line;
      let b =
        data === 1
          ? this.caption_info.langs[0].char_speed
          : this.caption_info.langs[1].char_speed;

      if (row.charNumber.split("\n").length > 1) {
        if (row.charNumber.split("\n")[0].length > a) {
          row.charNumbererr1 = true;
        } else {
          row.charNumbererr1 = false;
        }
        if (row.charNumber.split("\n")[1].length > a) {
          row.charNumbererr2 = true;
        } else {
          row.charNumbererr2 = false;
        }
      } else {
        if (Number(row.charNumber.length) > a) {
          row.charNumbererr = true;
        } else {
          row.charNumbererr = false;
        }
      }
      if (Number(row.readingNumber) > b) {
        row.readingNumbererr = true;
      } else {
        row.readingNumbererr = false;
      }
      return row;
    },
    // /**
    //  *
    //  * @param {获取焦点} val
    //  * @param {*} row
    //  */
    // textareafocus(val, row, data, event) {
    //   row.data = data;
    //   row = this.setNumbererr(row, data, val);
    //   this.Jumpposition(row);
    //   if (data == 1) {
    //     this.textareafocusAsr = true;
    //     this.textareafocusMT = false;
    //   } else {
    //     this.textareafocusAsr = false;
    //     this.textareafocusMT = true;
    //   }
    // },
    // /**
    //  *
    //  * @param {textarea输入时} val
    //  * @param {*} row
    //  */
    // textareainput(val, row, data) {
    //   row = this.setNumbererr(row, data, val);
    //   this.Reginleft.forEach((j) => {
    //     if (j.id === row.sentence_id) {
    //       if (data == 1) {
    //         j.subtitle = row.sentence_source;
    //       } else {
    //         j.sentence_trans = row.sentence_trans;
    //       }
    //     }
    //   });
    // },
    /**
     *
     * @param {*格内输入框 失去焦点   时间修改  输入框} row
     * @param {*start表示开始时间 end 表示结束时间} data
     */
    // inputBlue(row, data) {
    //   // var timeRegex =
    //   //   "([0-1][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]((.|,)?[0-9]{1,3})?$";
    //   // var Regex = new RegExp(timeRegex);
    //   if (data == "start") {
    //     let a = getStringToTime(row.start_time);
    //     if (isNaN(a)) {
    //       row.start_time = getTimeString(row.data_start, 1, 3).replace(
    //         ".",
    //         ","
    //       );
    //       this.$messages("warning", "请输入正确格式时间");
    //       return;
    //     } else {
    //       row.data_start = getStringToTime(row.start_time);
    //     }
    //     row.showStartInput = !row.showStartInput;
    //   } else {
    //     let b = getStringToTime(row.end_time);
    //     if (isNaN(b)) {
    //       this.$messages("warning", "请输入正确格式时间");
    //       row.end_time = getTimeString(row.data_end, 1, 3).replace(".", ",");
    //       return;
    //     } else {
    //       row.data_end = getStringToTime(row.end_time);
    //     }
    //     row.showEndInput = !row.showEndInput;
    //   }
    //   this.set_time_code(row);
    // },

    /**
     *
     * @param {创建波形区域} item
     */
    setRegion(item) {
      let isCreate = true;
      if (
        this.peaksInstance.segments._segments != undefined &&
        this.peaksInstance.segments._segments.length > 0
      ) {
        let _segments = this.peaksInstance.segments._segments[0];
        if (
          parseFloat(item.data_start) == _segments._startTime &&
          parseFloat(item.data_end) == _segments._endTime
        ) {
          isCreate = false;
        }
        _segments = null;
      }

      if (isCreate) {
        let regs = this.peaksInstance.segments.getSegment(
          this.current_sentence_id
        );
        if (regs != null) {
          this.peaksInstance.segments.removeById(this.current_sentence_id);
        }
        let segmentsOption = {
          startTime: parseFloat(item.data_start),
          endTime: parseFloat(item.data_end),
          color: "rgba(70, 173, 255, 0.5)",
          editable: true, // 允许/禁止调整区域大小,
          id: item.sentence_id,
          mintime: parseFloat(item.mintime),
          maxtime: parseFloat(item.maxtime),
        };
        this.current_sentence_id = item.sentence_id;
        this.segment = this.peaksInstance.segments.add(segmentsOption);
      }
      // this.peaksInstance.segments.removeAll();
    },
    /**
     * 清除所有区域
     */
    clearReagion() {},
    /**
     *
     * @param {*查找替换 下的单选框} e
     */
    radiochange(e) {
      console.log(e);
    },
    /**
     *
     * @param {tab页切换} tab
     * @param {*} event
     */
    handleClicktab(tab, event) {
      console.log(tab, event);
    },
    /**
     * 格式化显示 时间
     * @param val
     */
    formatTooltip(val) {
      return getTimeString(val, 1, 2).replace(".", ",");
    },
    /**
     *
     * @param {*，更改播放位置} slider
     */
    sliderChange(slider) {
      this.videoStartTime = TimeHandle(slider);
      if (Number(this.silderMax.toFixed(2)) == slider) {
        this.videoDom.currentTime = this.silderMax;
        // console.log(this.$refs.video1);
        // 直接调用ended事件
      } else {
        this.videoDom.currentTime = slider;
      }
      if (this.playflag) {
        this.videoDom.play();
        this.player = false;
        this.playflag = false;

        // this.wavesurferaudioprocess();
      }
      this.peaksSeek();
      // console.log(slider);
    },
    /**
     *  滑块 按下事件
     */
    openVideo() {
      if (!this.player) {
        this.playflag = true;
      }
      this.videoDom.pause();
      this.loopPlay = false;
      this.showloopbtn = true;
      this.player = true;
      // this.player = true;
    },
    /**
     *
     * @param {* 滑块 实时变化 数据} slider
     */
    sliderInput(slider) {
      // console.log(slider);
      // this.slidervalue = slider;
      // if (slider == 0) {
      //   if (this.currentRegion.sentence_id) {
      //     this.initPosition();
      //   }
      // }
    },
    /**
     *
     * @param {全屏按钮} data
     */
    FullScreen() {
      const leftCol = document.getElementById("leftCol");
      let div = document.querySelector(".left_video_div_div");
      let videodiv = document.getElementById("video1").clientHeight;
      if (this.fullscreen == true) {
        // this.smalldivtops = 0;
        // this.smalldivtops = videodiv;
        // //
        // if (this.bigdivtops !== 0) {
        //   div.style.top = div.offsetTop * (this.bigdivtops / videodiv) + "px";
        // }
        // div.style.bottom = "auto";
        // if (this.bigdivtops !== 0) {
        //   if (this.DragPosition > 0) {
        //     div.style.top =
        //       this.DragPosition * (window.innerHeight / videodiv) + "px";
        //   } else {
        //     // console.log(111111);
        //     div.style.top = div.offsetTop * (this.bigdivtops / videodiv) + "px";
        //     // div.style.bottom = (window.innerHeight / videodiv) * 60 + "px";
        //   }
        // } else {
        //   div.style.top =
        //     this.DragPosition * (window.innerHeight / videodiv) + "px";
        // }
        if (leftCol.requestFullscreen) {
          //W3C
          leftCol.requestFullscreen();
        } else if (leftCol.mozRequestFullScreen) {
          //FIREFOX
          leftCol.mozRequestFullScreen();
        } else if (leftCol.webkitRequestFullScreen) {
          //CHROME
          leftCol.webkitRequestFullScreen();
        } else if (leftCol.msRequestFullscreen) {
          //MSIE
          leftCol.msRequestFullscreen();
        }
      } else {
        // this.bigdivtops = 0;
        // this.bigdivtops = videodiv;
        // this.bigdivtopsClick = true;
        // let max_top = videodiv - div.clientHeight;
        // if (div.offsetTop / (videodiv / this.smalldivtops) > max_top) {
        //   div.style.top = max_top + "px";
        // } else {
        //   console.log(11111);
        //   div.style.top =
        //     div.offsetTop / (videodiv / this.smalldivtops) - 30 + "px";
        // }
        if (document.exitFullscreen) {
          //W3C
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          //FIREFOX
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          //CHROME
          document.webkitCancelFullScreen();
        } else if (document.msExitFullscreen) {
          //MSIE
          document.msExitFullscreen();
        } else if (document.oRequestFullscreen) {
          document.oCancelFullScreen();
        }
      }
      this.fullscreen = !this.fullscreen;
    },
    /**
     * 是否静音
     */
    openmuted() {
      this.mute = !this.mute;
      this.videoDom.muted = !this.mute;
    },
    /**
     *改变后的音量值
     * @param val
     */
    muteSliderChange(val) {
      // console.log(val / 10);
      // if (this.mute) {
      this.videoDom.volume = val / 100;
      // }
    },
    /**
     * 后退
     */
    rew() {
      this.gospeek = true;
      // console.log(this.slidervalue);
      if (this.videoDom.currentTime == 0) {
        this.initPosition();
        return;
      }
      this.videoDom.currentTime = this.videoDom.currentTime - 1; // 视频退三秒
      this.videoStartTime = TimeHandle(this.videoDom.currentTime);
    },
    /**
     * 前进
     */
    speek() {
      this.gospeek = true;
      if (this.videoDom.currentTime >= this.silderMax) {
        // console.log(
        //   "123456 this.videoDom.currentTime == this.videoDom.duration"
        // );
        this.videoStartTime = TimeHandle(this.silderMax);
        return;
      }
      this.videoDom.currentTime = this.videoDom.currentTime + 1; // 前进1秒
      this.videoStartTime = TimeHandle(this.videoDom.currentTime);
    },
    /**
     * 播放波形与 视频
     */
    playvideo() {
      this.player = false;
      this.videoDom.play();
      this.setTimeInterval(); ///定时器 计算时间
      this.loopPlay = false;
      this.showloopbtn = true;
    },
    /**
     *
     * @param {*点击 播放 暂停} val
     */
    videoplayclick() {
      if (this.showCanvas) {
        return;
      }
      clearInterval(this.currentRegionInterval);
      this.currentRegionInterval = null;
      if (this.player) {
        this.videoDom.play();
        this.setTimeInterval(); //开启定时器
      } else {
        this.videoDom.pause();
      }
      this.loopPlay = false;
      this.player = !this.player;
      this.showloopbtn = true;
    },
    /**
     * 暂停视频
     */
    closevideo() {
      this.player = true;
      this.videoDom.pause();
      clearInterval(this.currentRegionInterval);
      this.currentRegionInterval = null;
      this.showloopbtn = true;
    },
    /**
     * 控制播放倍数
     */
    handleCommand(command) {
      this.ds = command;
      this.videoDom.playbackRate = command;
    },
    /**
     * 是否显示字幕
     */
    showSubtitle() {
      this.radio = !this.radio;
    },
    /**
     * 水平放大和缩小波形。该参数是音频每秒的水平像素数。它还会更改参数minPxPerSec并启用该 scrollParent选项
     */
    videozoomlang() {
      this.wavesurfer.zoom((this.zooms += 20));
      // console.log(this.zooms)
      this.setcontentLocation();
    },
    /**
     * 波形缩小
     */
    videozoomshort() {
      if (this.zooms > 0) {
        this.wavesurfer.zoom((this.zooms -= 20));
      }
      this.setcontentLocation();
    },
    /**
     * 波形变高
     */
    setwaveformHeight() {
      this.wavesurfer.setHeight((this.height += 50));
    },
    /**
     * 波形变低
     */
    setwaveformHeightlow() {
      if (this.height <= 50) {
        return;
      }
      this.wavesurfer.setHeight((this.height -= 50));
    },
    /**
     *  设置字幕位置
     */
    setcontentLocation() {
      this.Reginleft = [];
      const wavesurferRegion = document.querySelectorAll(".wavesurfer-region");
      wavesurferRegion.forEach((i) => {
        this.tableData.forEach((j) => {
          if (i.getAttribute("data-id") == j.sentence_id) {
            this.Reginleft.push({
              id: j.sentence_id,
              width: i.offsetWidth,
              left: i.offsetLeft,
              subtitle: j.sentence_source,
              sentence_trans: !this.dataInfo.base_info.show
                ? j.sentence_trans
                : "",
              showdropdown: false,
            });
          }
        });
      });

      const wave = document.getElementsByTagName("wave")[0];
      // console.log(div)
      const a = document.getElementById("reginscroll1");
      a.style.width = wave.scrollWidth + "px";
    },
    /**
     * 视频初始化____
     */
    initvideo(url) {
      this.$nextTick(() => {
        this.videoDom = document.getElementById("video1"); // 获取视频dom元素
        // 在视频准备好后获取时长
        this.videoDom.ontimeupdate = () => {
          // 播放走动时，实时获取播放时间

          if (this.videoDom.currentTime >= this.silderMax) {
            this.videoStartTime = TimeHandle(this.silderMax);
            this.slidervalue = this.silderMax;
            return;
          }
          this.videoStartTime = TimeHandle(this.videoDom.currentTime);
          this.slidervalue = this.videoDom.currentTime;
        };
        this.videoDom.addEventListener("durationchange", () => {
          console.log(this.videoDom.duration, "this.videoDom.duration");
          this.silderMax = this.videoDom.duration;
        });
        this.videoDom.addEventListener("error", () => {
          this.$messages("warning", this.$t("CommonName.videoWarning"));
          this.peaksInstance.off("player.seeked");
          this.init(this.dataInfo.base_info.json_path);
          let times = this.videoDom.currentTime;
          this.showloopbtn = true;
          this.videoDom.pause();
          this.stopPlayBack();
          this.loopPlay = false;
          this.videoDom.status = false;
          this.videoDom.src = this.videoUrl;
          this.videoDom.currentTime = times;
          this.player = true;
          this.videoDom.volume = this.muteSlidervalue / 100;
          this.videoDom.muted = this.mute;
          setTimeout(() => {
            this.videoDom.playbackRate = this.ds;
          });
        });

        // 当视频可以播放的时候触发
        // this.videoDom.addEventListener("canplay", () => {
        //   // SomeJavaScriptCode
        //
        //   this.videoDom.currentTime = this.videoDom.duration;
        //   // this.videolangtime = TimeHandle(this.videoDom.duration);
        //   // console.log(this.videolangtime);
        //   // this.silderMax = this.videoDom.duration;
        //   // this.videoDom.muted = false;
        //   // this.slidervalue = this.videoDom.duration
        // });
        this.videoDom.addEventListener("loadedmetadata", () => {
          this.videolangtime = TimeHandle(this.videoDom.duration);
          this.silderMax = this.videoDom.duration;
          this.videoDom.muted = false;
          setTimeout(() => {
            this.videoDom.playbackRate = this.ds;
          });
        });

        // 播放 结束
        this.videoDom.addEventListener("ended", () => {
          clearInterval(this.currentRegionInterval);
          this.currentRegionInterval = null;
          this.player = true;
          // this.showloopbtn = true;
          this.initPosition();
        });
      });
    },
    /**
     * 停止定时器
     */
    stopPlayBack() {
      if (this.currentRegionInterval) {
        clearInterval(this.currentRegionInterval);
        this.currentRegionInterval = null;
      }
    },
    /**
     *
     * @param {设置 波形图时间轴样式} seconds
     * @param {*} pxPerSec
     */
    formatTimeCallback(seconds) {
      var secondsStr = TimeHandle(seconds, true);
      return secondsStr;
    },
    /**
     *
     * @param {* 跳转 方法} seek
     */
    peaksSeek() {
      this.peaksInstance.on("player.seeked", (time) => {
        if (time == this.current_peek_time) return;
        //检测是否在当前选中句段中
        if (
          this.currentRegion &&
          time >= parseFloat(this.currentRegion.data_start) &&
          time <= parseFloat(this.currentRegion.data_end)
        ) {
          console.log("在里面");
          this.peaksInstance.off("player.seeked");
          this.captionsObj = this.currentRegion;
          if (this.currentRegionInterval == null) {
            console.log("启动");
            this.setTimeInterval();
          }
          return false;
        }
        this.current_sentence_id = 0;
        this.current_peek_time = time;
        this.captionsObj = {};
        this.peaksInstance.segments.removeAll();
        this.segment = null;
        this.selectionRow = [];
        for (let index = 0; index < this.tableData.length; index++) {
          if (time < this.tableData[this.tableData.length - 1].data_end) {
            //是不是在两个句子之间
            if (
              time > parseFloat(this.tableData[index].data_end) &&
              time < parseFloat(this.tableData[index + 1].data_start)
            ) {
              this.currentRegion = {};
              this.currentIndex = this.tableData[index].number;
              if (this.currentRegionInterval == null) {
                console.log("启动");
                this.setTimeInterval();
              }
              return;
            }
            //是不是在第一句子之前
            if (time < parseFloat(this.tableData[0].data_start)) {
              this.currentRegion = {};
              this.currentIndex = 0;
              if (this.currentRegionInterval == null) {
                console.log("启动");
                this.setTimeInterval();
              }
              return;
            }
          } else {
            this.currentRegion = {};
            this.currentIndex = this.tableData.length - 1;
            return;
          }
          if (
            time >= this.tableData[index].data_start &&
            time <= this.tableData[index].data_end
          ) {
            if (
              this.currentRegion.sentence_id ==
              this.tableData[index].sentence_id
            ) {
              this.gostart = false;
            } else {
              this.gostart = true;
            }
            if (this.gostart && !this.gospeek) {
              this.videoDom.currentTime = this.tableData[index].data_start;
              this.gostart = false;
            }
            this.current_sentence_id = this.tableData[index].sentence_id;
            this.currentRegion = this.tableData[index];
            if (
              (!!this.tabrowData.sentence_id &&
                this.currentRegion.sentence_id !=
                  +this.tabrowData.sentence_id) ||
              this.tabrowData.sentence_id == undefined
            ) {
              this.tabrowData = {};
              this.setTabOrCommentsRowData(this.currentRegion);
            }
            this.setselectionRow(this.currentRegion);
            this.captionsObj = this.currentRegion;
            this.setRegion(this.tableData[index]);
            this.jumpgo(index + 1);
            this.srtTrCurIndex(index);
            this.peaksInstance.off("player.seeked");
            return;
          }
        }
      });
    },
    /**
     * 波形初始化
     */
    init(url) {
      this.$nextTick(() => {
        const options = {
          zoomview: {
            container: this.$refs["waveform-container"],
            waveformColor: "#5f697f",
            playedWaveformColor: "#5f697f",
            playheadColor: "#ffb06b",
            playheadTextColor: "#aaa",
            playheadClickTolerance: 0,
            showPlayheadTime: false,
            timeLabelPrecision: 2,
            axisGridlineColor: "#ccc",
            axisLabelColor: "#aaa",
            showAxisLabels: true,
            fontFamily: "sans-serif",
            fontSize: 11,
            fontStyle: "normal",
            wheelMode: "none",
            segmentOptions: {},
          },
          mediaElement: document.getElementById("video1"),
          inMarkerColor: "rgba(33,150,243,0.3)", //段落开始部分颜色
          outMarkerColor: "rgba(33,150,243,0.3)", //段落结束部分颜色
          dataUri: {
            json: url,
          },
          keyboard: false,
          zoomWaveformColor: "#455a64",
        };

        Peaks.init(options, (err, peaks) => {
          this.peaksInstance = peaks;
          if (err) {
            console.error(
              "Failed to initialize Peaks instance: " + err.message
            );
            this.$messages("error", "波形加载失败");
            return;
          }
          this.peaksSeek();
          peaks.on("segments.dragend", (segment) => {
            this.peaksInstance.off("player.seeked");
            let arr = this.tableData;
            arr.forEach((i) => {
              if (i.sentence_id == segment.segment.id) {
                i.data_start = segment.segment.startTime;
                i.data_end = segment.segment.endTime;
                i.start_time = getTimeString(
                  segment.segment.startTime,
                  1,
                  3
                ).replace(".", ",");
                i.end_time = getTimeString(
                  segment.segment.endTime,
                  1,
                  3
                ).replace(".", ",");
                if (segment.startMarker) {
                  this.videoDom.currentTime = i.data_start;
                } else {
                  this.videoDom.currentTime = i.data_end;
                }
                this.set_time_code(i);
              }
            });
            this.tableData = arr;
          });
        });
      });
    },
  },
};
</script>
<style scoped lang="scss">
@import "./VideoDetails.scss";

::v-deep .el-table__body tr.current-row > td.el-table__cell {
  background: #46adff;
}

::v-deep .el-table .success-row {
  background: #46adff !important;
  // position: sticky;
  td {
    color: #ffffff !important;
  }

  &:hover {
    td {
      background-color: #46adff !important;
    }
  }
}

::v-deep .wavesurfer-handle {
  background: none !important;
  width: 8px !important;
  height: 30px !important;
  top: 50% !important;
  margin-top: -15px !important;

  &:hover {
    background: #000000 !important;
  }
}

.titlediv {
  display: flex;
  justify-content: space-between;

  .svgicon {
    font-size: 16px;
    margin-left: 6px;
    cursor: pointer;
  }
}

.title_right_div {
  display: flex;
  align-items: center;
  margin-right: 24px;

  div {
    margin-left: 36px;
  }
}

.content {
  background: none !important;

  .content_content {
    height: 100%;
    width: 100%;
    overflow-y: auto;
  }

  // display: flex;
}

::v-deep .el-tabs__item:focus.is-active.is-focus:not(:active) {
  -webkit-box-shadow: none;
  box-shadow: none;
}

// tab ye
::v-deep .el-tabs__nav-wrap::after {
  background-color: #515464 !important;
}

::v-deep .el-tabs__item {
  font-size: 14px;
  line-height: 40px;
  color: #cbd7e8 !important;
  padding: 0 24px;
  box-shadow: none;
}

::v-deep .el-tabs__header {
  margin: 0;
  height: 40px;
}

::v-deep .el-tabs__content {
  height: calc(100% - 40px);
}

::v-deep .el-tabs__item.is-active {
  color: rgba(70, 173, 255, 1) !important;
}

::v-deep .el-tabs__active-bar {
  min-height: 2px;
}

::v-deep .el-tabs__nav-scroll {
  padding-left: 24px;
}

::v-deep .el-table--enable-row-transition .el-table__body td.el-table__cell {
  padding: 0;
}

.setpColor {
  color: #ef7373 !important;

  ::v-deep .el-input__inner {
    color: #ef7373 !important;
  }
}

.scroller {
  height: calc(100% - 44px) !important;
  // border-bottom: 1px solid #515464;
  overflow-y: scroll;
}

.scroller58 {
  height: calc(100% - 58px) !important;
  // border-bottom: 1px solid #515464;
  overflow-y: scroll;
}

// 错误检查  表格 高度  边框
.tabTableScroller {
  height: 100%;
  overflow-y: auto;
  // border-bottom: 1px solid #515464;
}

.list-item-dynamic {
  // display: flex;
  // align-items: center;
  padding: 1em;
  // border-bottom: 1px solid;
  // border-color: lightgray;
  ::v-deep .el-textarea__inner {
    padding: 6px 6px 6px 6px !important;
    width: 100%;
    min-height: 100% !important;
    border-radius: 0px !important;
  }
}

.setUserSelect {
  user-select: none;
}

// 阿语 字幕 显示 样式
.setFontstyles {
  direction: rtl !important;
}

/**
字幕样式
 */
.handleDropdown_div {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .el-icon-arrow-right {
    //text-align: right;
    margin-left: 20px;
    margin-right: 0px;
  }

  .handleDropdown_div_div_English {
    left: 170px !important;
  }

  //.handleDropdown_div_div_English{
  //  width: 160px !important;
  //}
  .handleDropdown_div_div_1_3 {
    position: absolute;
    left: 140px;
    top: 0;
    width: 160px;

    background: #454758;
    border: 1px solid;
    border-color: #525462;
    border-radius: 4px;
    box-shadow: 0px 2px 6px rgba(2, 3, 4, 0.6);
    color: #adb0bc !important;
    font-size: 14px !important;
    text-align: center;

    .icon {
      margin-right: 10px;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: left;
      margin-left: 6px;

      &:hover {
        color: #ffffff !important;
        background-color: #545565 !important;
      }
    }
  }

  .handleDropdown_div_div {
    @extend .handleDropdown_div_div_1_3;
    height: 172px;

    div {
      height: 25%;
    }
  }

  .EnglishDiv {
    left: -405px !important;

    div {
      justify-content: left !important;
      //box-sizing: border-box;
      .icon {
        margin-left: 3px;
        margin-right: 8px;
      }
    }
  }
  .EnglishDiv1 {
    left: 170px !important;
    width: 370px !important;

    div {
      justify-content: left !important;
      //box-sizing: border-box;
      .icon {
        margin-left: 3px;
        margin-right: 8px !important;
      }
    }
  }
  .handleDropdown_div_div2 {
    position: absolute;
    left: 140px;
    top: 0;
    width: 295px;
    height: 121px;
    background: #454758;
    border: 1px solid;
    border-color: #525462;
    border-radius: 4px;
    box-shadow: 0px 2px 6px rgba(2, 3, 4, 0.6);
    display: flex;
    flex-wrap: wrap;
    color: #adb0bc;
    justify-content: space-between;
    align-content: space-between;

    .icon {
      margin-right: 10px;
    }

    div {
      height: 33.33%;
      width: 33.33%;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        color: #ffffff !important;
        background-color: #545565 !important;
      }
    }
  }

  .handleDropdown_div_div3 {
    @extend .handleDropdown_div_div_1_3;
    height: 130px;

    div {
      height: 33.33%;
    }
  }
}
.fontfamilyClass {
  font-family: "Titillium Web2", "sans-serif" !important;
}
</style>
