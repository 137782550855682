<!--
 * @Description: 主播设置弹窗
 * @version: 
 * @Author: Tom
 * @Date: 2022-10-27 14:25:20
 * @LastEditors: Tom
 * @LastEditTime: 2022-10-27 15:19:44
-->
<template>
  <el-dialog
    :title="dialogTitle"
    :visible.sync="CenterdialogVisible"
    width="1010px"
    top="calc(5%)"
    :close-on-click-modal="false"
    append-to-body
    @close="closeDialog"
  >
    <div class="tts-model-main-right-anchors">
      <el-tabs v-model="AnchorsTab" @tab-click="AnchorsHandleClick">
        <el-tab-pane
          :label="
            $t('VideoDubbingClone.dubText29') +
            ' (' +
            MyCollectRolesList.length +
            '/' +
            MaxAnchorCount +
            ')'
          "
          name="1"
        >
          <div class="ts-model-main-right-anchors-tab-pane">
            <!-- 筛选 -->
            <div style="display: flex; justify-content: flex-start">
              <div class="ts-model-main-right-anchors-tab-pane-select">
                <span style="margin: 0 8px; color: #adb0bc">{{
                  $t("VideoDubbing.anchorsText7")
                }}</span>
                <el-input
                  type="text"
                  class="selectClass"
                  :placeholder="$t('VideoDubbing.anchorsText8')"
                  v-model="anchorNameInput"
                  :maxlength="20"
                  @input="anchorNameSearch"
                >
                </el-input>
              </div>
            </div>
            <div
              class="ts-model-main-right-anchors-tab-pane-anchors"
              id="AnchorsAll"
            >
              <AnchorCom
                v-for="(data, index) in MyCollectRolesList"
                v-show="
                  data.is_delete == '0' &&
                  data.role_name
                    .trim()
                    .toLowerCase()
                    .indexOf(anchorNameInput.trim().toLowerCase()) > -1
                "
                :key="index"
                :anchorId="data.role_collect_id"
                :anchorName="data.role_name"
                :anchorNameUpdate="data.role_name_update"
                :anchorNameInput="data.role_name_input"
                :anchorGender="'1'"
                :anchorIndex="index"
                :updateNumber="data.updateNumber"
                :mode="1"
                @anchor-click="anchorClick"
                @anchor-audition="anchorAudition"
                @anchor-delete="anchorDelete"
                @anchor-update="anchorUpdate"
              ></AnchorCom>
              <div v-if="MyCollectRolesList.length == 0" class="noDataDiv">
                <svg-icon icon-class="NoData" class="noDataicon"></svg-icon>
                <p>{{ $t("CommonName.noData") }}</p>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="$t('VideoDubbingClone.dubText33')" name="2">
          <div class="ts-model-main-right-anchors-tab-pane">
            <!-- 筛选 -->
            <div style="display: flex; justify-content: flex-start">
              <div class="ts-model-main-right-anchors-tab-pane-select">
                <span style="margin: 0 8px; color: #adb0bc">{{
                  $t("VideoDubbing.anchorsText5")
                }}</span>
                <el-select
                  v-model="anchorGenderSelect"
                  :placeholder="$t('VideoDubbing.anchorsText6')"
                  class="selectClass"
                >
                  <el-option
                    :key="0"
                    :label="$t('VideoDubbing.All')"
                    value="All"
                  >
                  </el-option>
                  <el-option
                    v-for="item in anchorGenders"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="ts-model-main-right-anchors-tab-pane-select">
                <span style="margin: 0 8px; color: #adb0bc">{{
                  $t("VideoDubbing.anchorsText7")
                }}</span>
                <el-input
                  type="text"
                  class="selectClass"
                  :placeholder="$t('VideoDubbing.anchorsText8')"
                  v-model="anchorNameInput"
                  :maxlength="20"
                  @input="anchorNameSearch"
                >
                </el-input>
              </div>
            </div>

            <div
              class="ts-model-main-right-anchors-tab-pane-anchors"
              :id="'AnchorsAll2'"
            >
              <template v-for="(data, index) in AnchorAll">
                <AnchorComAI
                  :key="index"
                  v-if="
                    (anchorGenderSelect == data.gender ||
                      anchorGenderSelect == 'All') &&
                    data.name
                      .trim()
                      .toLowerCase()
                      .indexOf(anchorNameInput.trim().toLowerCase()) > -1
                  "
                  :anchorId="data.voice_id"
                  :anchorName="data.name"
                  :anchorDescriptionAge="data.age_key"
                  :anchorDescriptionMood="data.mood_key"
                  :anchorGender="data.gender"
                  :isFree="data.free"
                  :anchorIndex="index"
                  @anchor-click="anchorClick"
                  @anchor-audition="anchorAuditionAI"
                ></AnchorComAI>
              </template>
              <div v-if="AnchorAll.length == 0" class="noDataDiv">
                <svg-icon icon-class="NoData" class="noDataicon"></svg-icon>
                <p>{{ $t("CommonName.noData") }}</p>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div slot="footer" class="dialog-footer" style="padding: 16px">
      <div>
        <div
          style="
            display: inline-block;
            color: #adb0bc;
            margin-right: 21px;
            font-size: 13px;
          "
          v-if="Mode == '2'"
        >
          {{ multipleAnchorGroup.length }}
          {{ $t("VideoDubbingClone.dubText16") }}
        </div>
        <!-- <el-button @click="ResetClick" class="setpadding16" type="info">{{
          $t("dubbing.reset")
        }}</el-button> -->
        <el-button type="primary" class="setpadding16" @click="saveClick">{{
          $t("dubbing.confirm")
        }}</el-button>
      </div>
    </div>
    <!--播放器 -->
    <audio
      ref="tryPlaySampleAudio"
      v-show="false"
      @ended="overTryPlaySampleAudio"
    >
      <source />
    </audio>
  </el-dialog>
</template>
<script>
import AnchorCom from "./AnchorCom.vue";
import AnchorComAI from "./AnchorComAI.vue";
import { mapState, mapGetters } from "vuex";
import config from "@/config";
import { video_team_dubbing_api } from "@/utils/https";
import { showLoading, hideLoading } from "@/utils/loading";
export default {
  components: {
    AnchorCom,
    AnchorComAI,
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    JobId: {
      type: String,
      default: "0",
    },
    AnchorId: {
      type: String,
      default: "0",
    },
    Mode: {
      type: String,
      default: "1",
    },
    //收藏角色列表
    MyCollectRoles: {
      type: Array,
      default: () => {
        return [];
      },
    },
    //最大收藏数量
    MaxAnchorCount: {
      type: Number,
      default: 20,
    },
    AnchorMood: {
      type: String,
      default: "1",
    },
    AnchorAge: {
      type: String,
      default: "1",
    },
    AnchorAll: {
      type: Array,
      default: () => {
        return [];
      },
    },
    ProjectRolelist: {
      type: Array,
      default: () => {
        return [];
      },
    },
    RoleType: {
      type: String,
      default: "1",
    },
  },
  data() {
    return {
      CenterdialogVisible: false,
      AnchorsTab: "1",
      currentAnchorId: 0, //当前设置主播id
      currentAnchorName: 0, //当前设置主播名称
      currentPlayAnchorId: 0, //当前播放主播id
      play: false, // 播放状态，true为正在播放
      baseUrl: config.baseUrl.dev, //请求地址
      anchorGenders: [
        {
          id: "1",
          name: this.$t("VideoDubbing.anchorsText9"),
        },
        {
          id: "2",
          name: this.$t("VideoDubbing.anchorsText10"),
        },
        {
          id: "4",
          name: this.$t("VideoDubbing.anchorsText11"),
        },
        {
          id: "3",
          name: this.$t("VideoDubbing.anchorsText12"),
        },
      ], //性别列表
      anchorGenderSelect: "All", //性别筛选
      anchorNameInput: "", //名称筛选
      dialogTitle: this.$t("VideoDubbingClone.dubText7"),
      currentRoleCollectId: "0", //当前角色id
      JobCollectRolesList: [], //收藏角色列表
      MyCollectRolesList: [], //我的收藏角色列表
      setAnchorAge: this.AnchorAge, //设置当前主播角色风格
      oldAnchorAge: this.AnchorAge, //设置当前主播角色风格
      setAnchorMood: this.AnchorMood, //设置当前主播说话风格
      oldAnchorMood: this.AnchorMood, //设置当前主播说话风格
      AnchorAgeGroup: [], //当前主播角色风格组
      AnchorMoodGroup: [], //当前主播说话风格组
      AnchorAgeAudio: "", //当前主播风格默认播放地址
      AgePlayStyleId: "", //角色风格正在播放的id
      MoodPlayStyleId: "", //说话风格正在播放的id
    };
  },
  computed: {
    ...mapState({
      selectedAnchorId: (state) => state.VideoDubbingClone.selectedAnchorId,
      selectedAIAnchorId: (state) => state.VideoDubbingClone.selectedAIAnchorId,
    }),
    ...mapGetters("VideoDubbingClone", ["multipleAnchorGroup"]),
  },
  watch: {
    playAnchorId(value) {},
    CenterdialogVisible(value) {
      console.log("123123");
      this.CenterdialogVisible = value;
      if (value) {
        this.MyCollectRolesList = this.MyCollectRoles;
        this.MyCollectRolesList.forEach((item) => {
          item["role_name_update"] = item.role_name;
          item["role_name_input"] = false;
          item["updateNumber"] = Math.random();
        });
        this.dialogTitle = this.$t("VideoDubbing.anchorsText");
        this.roleNameInput = this.RoleName;
        this.roleChecked = false;
        if (this.RoleType == "0") {
          console.log(this.AnchorId);
          this.AnchorsTab = "2";
          this.$store.dispatch(
            "VideoDubbingClone/updateSelectedAIAnchorId",
            this.AnchorId
          );
          this.$store.dispatch("VideoDubbingClone/updateSelectedAnchorId", "0");
          if (this.Mode == "1") {
            this.AnchorAll.forEach((item) => {
              if (item.voice_id == this.AnchorId) {
                this.$nextTick(() => {
                  let top =
                    document.getElementById("anchor_active").offsetTop - 47;
                  // console.log("top", top);
                  if (top >= 0) {
                    document.getElementById("AnchorsAll2").scrollTop = top;
                  }
                });
              }
            });
          }
        } else {
          console.log(this.AnchorId);
          this.$store.dispatch(
            "VideoDubbingClone/updateSelectedAIAnchorId",
            "0"
          );
          this.$store.dispatch(
            "VideoDubbingClone/updateSelectedAnchorId",
            this.AnchorId
          );
          this.AnchorsTab = "1";
          if (this.Mode == "1") {
            this.MyCollectRolesList.forEach((item) => {
              if (item.role_collect_id == this.AnchorId) {
                this.$nextTick(() => {
                  let top =
                    document.getElementById("anchor_active").offsetTop - 47;
                  // console.log("top", top);
                  if (top >= 0) {
                    document.getElementById("AnchorsAll").scrollTop = top;
                  }
                });
              }
            });
          }
        }
      }
    },
  },
  methods: {
    /**
     * 关闭弹窗
     */
    closeDialog() {
      this.CenterdialogVisible = false;
      this.$refs.tryPlaySampleAudio.pause();
      this.$store.dispatch("VideoDubbingClone/updatePlayAnchorId", "0");
      this.$store.dispatch("VideoDubbingClone/updatePlayAIAnchorId", "0");
      this.$emit("changeDialogVisible", this.CenterdialogVisible);
    },
    /**
     * 主播点击
     */
    anchorClick(data) {},
    AnchorsHandleClick(tab, event) {
      this.anchorGenderSelect = "All";
      this.anchorNameInput = "";
      this.$refs.tryPlaySampleAudio.pause();
      this.$store.dispatch("VideoDubbingClone/updatePlayAnchorId", "0");
      this.$store.dispatch("VideoDubbingClone/updatePlayAIAnchorId", "0");
      this.$store.dispatch(
        "VideoDubbingClone/updateMultipleAnchorGroupAll",
        []
      );
    },
    overTryPlaySampleAudio() {
      this.play = false;
      this.$store.dispatch("VideoDubbingClone/updatePlayAnchorId", "0");
      this.$store.dispatch("VideoDubbingClone/updatePlayAIAnchorId", "0");
    },
    /**
     * 保存主播设置
     */
    saveClick() {
      let AnchorsTab = this.AnchorsTab;
      let AnchorId =
        this.AnchorsTab == "1"
          ? this.selectedAnchorId
          : this.selectedAIAnchorId;
      let Mode = this.Mode;
      let Group = this.multipleAnchorGroup;
      this.$refs.tryPlaySampleAudio.pause();
      this.$store.dispatch("VideoDubbingClone/updatePlayAnchorId", "0");
      this.$store.dispatch("VideoDubbingClone/updatePlayAIAnchorId", "0");
      // this.CenterdialogVisible = false;
      this.$emit("SaveClick", {
        AnchorId,
        AnchorsTab,
        Mode,
        Group,
      });
    },
    anchorNameSearch() {},
    anchorAudition(data) {
      if (data[2]) {
        let path = this.MyCollectRolesList[data[1]].audition_file_path;
        if (path != null && path != "") {
          this.$store.dispatch("VideoDubbingClone/updatePlayAnchorId", data[0]);
          this.$refs.tryPlaySampleAudio.src = path;
          this.$refs.tryPlaySampleAudio.volume = 1;
          this.$refs.tryPlaySampleAudio.play();
          this.play = true;
        } else {
          //请求试听文件
          showLoading(this.$t("VideoDubbing.loadingmessage"));
          video_team_dubbing_api({
            action: "video_clone_audition_collect_role",
            job_id: this.JobId,
            job_type:
              this.MyCollectRolesList[data[1]].role_type == "2" ? "2" : "1",
            role_collect_id: data[0],
          }).then((res) => {
            hideLoading();
            if (!res) {
              return;
            }
            this.MyCollectRolesList[data[1]].audition_file_path =
              res.audition_file_path;
            this.$store.dispatch(
              "VideoDubbingClone/updatePlayAnchorId",
              data[0]
            );
            this.$refs.tryPlaySampleAudio.src = res.audition_file_path;
            this.$refs.tryPlaySampleAudio.volume = 1;
            this.$refs.tryPlaySampleAudio.play();
            this.play = true;
          });
        }
      } else {
        this.$refs.tryPlaySampleAudio.pause();
        this.$store.dispatch("VideoDubbingClone/updatePlayAnchorId", "0");
      }
    },
    anchorDelete(data) {
      if (this.selectedAnchorId == data[0]) {
        this.$store.dispatch("VideoDubbingClone/updateSelectedAnchorId", "0");
      }
      this.$refs.tryPlaySampleAudio.pause();
      this.$store.dispatch("VideoDubbingClone/updatePlayAnchorId", "0");
      this.$emit("UpdateCollectRole", [data[0], 0, ""]);
      this.MyCollectRolesList[data[1]].is_delete = 1;
      this.MyCollectRolesList.splice(data[1], 1);
    },
    anchorUpdate(data) {
      let role_name = data[2].trim();
      if (role_name == "") {
        this.MyCollectRolesList[data[1]].role_name =
          this.MyCollectRolesList[data[1]].role_name_update;
        this.MyCollectRolesList[data[1]].updateNumber = Math.random();
        return;
      }
      if (role_name == this.MyCollectRolesList[data[1]].role_name_update) {
        return;
      }
      this.MyCollectRolesList[data[1]].role_name = role_name;

      video_team_dubbing_api({
        action: "video_clone_edit_collect_role",
        job_id: this.JobId,
        role_collect_id: data[0],
        role_name: role_name,
      }).then((res) => {
        if (!res) {
          this.MyCollectRolesList[data[1]].role_name =
            this.MyCollectRolesList[data[1]].role_name_update;
          this.MyCollectRolesList[data[1]].updateNumber = Math.random();
          return;
        }
        this.MyCollectRolesList[data[1]].role_name_update = "";
        this.MyCollectRolesList[data[1]].role_name_update = role_name;
        this.$emit("UpdateCollectRole", [data[0], 1, role_name]);
      });
    },
    anchorAuditionAI(data) {
      if (data[2]) {
        if (this.AnchorAll[data[1]] != null) {
          this.$store.dispatch(
            "VideoDubbingClone/updatePlayAIAnchorId",
            data[0]
          );
          let path = this.baseUrl + this.AnchorAll[data[1]].sample_audio;
          this.$refs.tryPlaySampleAudio.src = path;
          this.$refs.tryPlaySampleAudio.volume = 1;
          this.$refs.tryPlaySampleAudio.play();
          this.play = true;
        }
      } else {
        this.$refs.tryPlaySampleAudio.pause();
        this.$store.dispatch("VideoDubbingClone/updatePlayAIAnchorId", "0");
      }
    },
  },
  mounted() {
    this.MyCollectRolesList = this.MyCollectRoles;
    this.MyCollectRolesList.forEach((item) => {
      item["role_name_update"] = item.role_name;
      item["role_name_input"] = false;
      item["updateNumber"] = Math.random();
    });
    this.CenterdialogVisible = this.dialogVisible;
  },
};
</script>
<style scoped lang="scss">
::v-deep .el-dialog__title {
  margin-top: 24px !important;
}
::v-deep .el-dialog__title {
  // width: 64px;
  height: 21px;
  //font-family: "TitilliumWeb-SemiBold", "Microsoft YaHei" !important;
  font-family: "TitilliumWeb-SemiBold", "sans-serif" !important;
  color: #ffffff;
  font-size: 16px;
  line-height: 0;
  margin: 0 0 0 24px;
  float: left;
  // margin-top: 25px;
}
::v-deep .el-dialog__headerbtn {
  font-size: 20px;
  top: 8px;
}
::v-deep .el-dialog__body {
  min-height: 170px !important;
}
::v-deep .el-dialog--center .el-dialog__body {
  padding: 0px !important;
  max-height: 400px;
  overflow-y: auto;
}
::v-deep .el-dialog__title {
  font-size: 16px;
  font-weight: normal;
  color: rgba(255, 255, 255, 1);
  margin-top: 13px;
}
::v-deep .el-dialog__header {
  min-height: 48px;
  background: rgba(57, 60, 78, 1);
  border-bottom: 1px solid #525462;
  padding: 0;
}
::v-deep .el-dialog__body {
  background: rgba(57, 60, 78, 1);
  min-height: 308px;
  padding: 0;
}
::v-deep .el-dialog__footer {
  min-height: 80px;
  padding: 0;
  border-top: 1px solid #525462;
  background: rgba(57, 60, 78, 1);
}
.dialogp {
  height: 19px;
  //font-family: Microsoft YaHei;
  font-family: "TitilliumWeb-SemiBold", "sans-serif" !important;
  color: #adb0bc;
  font-size: 14px;
  margin-bottom: 6px;
}
::v-deep input::-webkit-input-placeholder {
  -webkit-text-fill-color: #787e83;
}
::v-deep .el-upload-list__item-name {
  color: #ffffff;
}
::v-deep .el-upload-list__item .el-icon-upload-success {
  color: #46adff;
}
::v-deep .el-input__inner {
  width: 360px;
  height: 32px;
  background-color: rgba(57, 60, 78, 1);
  border: 1px solid;
  border-color: #7d7f8b;
  border-radius: 2px;
  font-family: TitilliumWeb-SemiBold;
  color: #ffffff;
  font-size: 14px;
}
::v-deep .el-input__inner {
  font-family: TitilliumWeb-SemiBold;
  color: #ffffff;
  font-size: 14px;
}

.tts-model-main-right-anchors {
  padding: 24px 24px 0 24px;
  background-color: #2e303e;
}
.ts-model-main-right-anchors-tab-pane-select {
  text-align: left;
  margin-bottom: 15px;
  margin-right: 10px;
  .selectClass {
    width: auto;
    ::v-deep .el-input__inner {
      width: 222px;
    }
    ::v-deep .el-input__icon {
      line-height: 32px;
    }
  }
  ::v-deep .el-checkbox__label {
    color: #fff;
  }
}
.ts-model-main-right-anchors-tab-pane-anchors {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: baseline;
  height: 288px;
  overflow-y: auto;
}
.tts-model-speed {
  width: 182px;
  float: left;
  margin-right: 24px;
}
.tts-model-speed .cont {
  color: #adb0bc;
  font-size: 14px;
  text-align: center;
}
.tts-model-speed .txt {
  color: #adb0bc;
  font-size: 14px;
  text-align: center;
}
::v-deep .el-slider__runway {
  height: 4px;
  margin: 8px 0;
}
::v-deep .el-slider__button {
  height: 10px;
  width: 10px;
}
::v-deep .el-tabs__nav-scroll {
  padding: 0;
}
.tts-model-main-style-age {
  padding: 16px 16px 8px;
  min-height: 56px;
}
.style-age-title {
  margin-right: 8px;
  color: #adb0bc;
  font-size: 14px;
  float: left;
}
.style-age-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: baseline;
}
.style-age-content-div {
  background-color: #51546a;
  border-radius: 18px;
  padding: 0;
  margin: 0 16px 8px 0;
  border: 1px solid;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.style-age-content-div:hover {
  border: 1px solid;
  border-color: #46adff;
  .style-age-content-span,
  .svg-play {
    color: #46adff;
  }
}
.style-age-content-div.active {
  border: 1px solid;
  border-color: #46adff;
  .style-age-content-span,
  .svg-play {
    color: #46adff;
  }
}
.style-age-content-span {
  margin: 0px 12px;
  color: #ffffff;
  font-size: 12px;
  vertical-align: middle;
}
.style-age-content-div .svg-play {
  width: 22px;
  height: 22px;
  color: #fff;
  vertical-align: middle;
}
.tts-model-main-style-mood {
  padding: 16px 16px 8px;
  min-height: 56px;
}
::v-deep .el-tabs__item {
  color: #ffffff;
}
::v-deep .el-tabs__item.is-active {
  color: #409eff;
}
.noDataDiv {
  width: 250px;
  height: 250px;
  margin: 0 auto;
  margin-top: 35px;
}
</style>
