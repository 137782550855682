<!--
 * @Description: 成员管理
 * @version: 
 * @Author: Carful
 * @Date: 2022-11-07 10:07:36
 * @LastEditors: Carful
 * @LastEditTime: 2023-02-24 11:32:28
-->
<template>
  <div class="mainCenter">
    <div class="mainCenterTop titleHeader">
      <div class="titleStyle">
        <div class="titledivStyle"></div>
        <p>{{ $t("MemberManagement.title") }}</p>
      </div>
      <div>
        <el-button type="text" class="headerbtn1" @click="goInvitationrecord">
          <div class="svgbtn">
            <svg-icon icon-class="TextVoice" class="icon"></svg-icon>
            <p>{{ $t("MemberManagement.headerbtn1") }}</p>
          </div>
        </el-button>
        <el-button type="text" @click="addNewmember">
          <div class="svgbtn">
            <!-- headerbtn2 -->
            <svg-icon icon-class="create" class="icon"></svg-icon>
            <p>{{ $t("MemberManagement.headerbtn2") }}</p>
          </div>
        </el-button>
      </div>
    </div>
    <!-- 筛选 -->
    <div class="contentCenter_tags screenStyle">
      <p>{{ $t("CommonName.screen") }}</p>
      <el-tag
        v-for="tag in tags"
        :key="tag.name"
        closable
        :type="tag.type"
        @close="handleClose(tag)"
      >
        <p
          class="screenp"
          :id="'tag' + tag.id"
          @mouseover="onShowNameTipsMouseenter(tag)"
        >
          <el-tooltip
            class="item"
            effect="dark"
            :content="tag.name"
            placement="top-start"
            :disabled="!tag.showdropdown"
          >
            <span>{{ tag.name }}</span>
          </el-tooltip>
        </p>
      </el-tag>
    </div>
    <div class="mainCenterbtm tableClass">
      <el-table
        :row-style="{
          height: 48 + 'px',
          background: '#393c4e',
          color: '#cbd7e8',
        }"
        :cell-style="{ padding: 0 + 'px' }"
        :header-cell-style="{
          height: 48 + 'px',
          padding: '0px',
          color: '#adb0bc',
          background: '#393c4e',
        }"
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        :max-height="tableHeight"
        :default-sort="defaultSort"
        @sort-change="sortChange"
      >
        <div slot="empty" class="noDataDiv">
          <svg-icon icon-class="NoData" class="noDataicon"></svg-icon>
          <p>{{ $t("CommonName.noData") }}</p>
        </div>
        <el-table-column :min-width="140">
          <template slot="header">
            <!-- 邮箱 -->
            <div class="tableheaderdiv">
              <p class="namep">{{ $t("MemberManagement.email") }}</p>
              <div class="icondiv">
                <el-popover
                  placement="bottom-end"
                  width="278"
                  v-model="visible"
                  :visible-arrow="false"
                >
                  <div class="popoverDiv">
                    <el-input
                      v-model="popoverInput"
                      :placeholder="$t('CommonName.search')"
                      maxlength="100"
                      @keyup.enter.native="searchdetermineClick"
                    ></el-input>
                    <el-button
                      type="primary"
                      class="setpadding16"
                      @click="searchdetermineClick"
                      >{{ $t("CommonName.determine") }}
                    </el-button>
                  </div>
                  <svg-icon
                    icon-class="Filter"
                    class="iconsize"
                    slot="reference"
                  ></svg-icon>
                </el-popover>

                <div class="tableheader"></div>
              </div>
            </div>
          </template>
          <template slot-scope="scope">
            <div class="menuscopediv">
              {{ scope.row.email }}
            </div>
          </template>
        </el-table-column>
        <!-- 昵称 -->
        <el-table-column :min-width="120" prop="nickname" show-overflow-tooltip>
          <template slot="header">
            <div class="tableheaderdiv">
              <p>{{ $t("MemberManagement.Nickname") }}</p>
              <div class="icondiv">
                <el-popover
                  placement="bottom-end"
                  width="278"
                  v-model="nicknameVisible"
                  :visible-arrow="false"
                >
                  <div class="popoverDiv">
                    <el-input
                      v-model="nicknameInput"
                      :placeholder="$t('CommonName.search')"
                      maxlength="100"
                      @keyup.enter.native="searchNicknameClick"
                    ></el-input>
                    <el-button
                      type="primary"
                      class="setpadding16"
                      @click="searchNicknameClick"
                      >{{ $t("CommonName.determine") }}
                    </el-button>
                  </div>
                  <svg-icon
                    icon-class="Filter"
                    class="iconsize"
                    slot="reference"
                  ></svg-icon>
                </el-popover>
                <div class="tableheader"></div>
              </div>
            </div>
          </template>
        </el-table-column>
        <!-- 状态 -->
        <el-table-column prop="team_user_status1">
          <template slot="header">
            <div class="tableheaderdiv">
              <p>{{ $t("CommonName.status") }}</p>
              <div class="icondiv">
                <el-dropdown
                  @command="statuscommand"
                  trigger="click"
                  placement="bottom-end"
                >
                  <span class="el-dropdown-link">
                    <svg-icon icon-class="Filter" class="iconsize"></svg-icon>
                  </span>
                  <el-dropdown-menu
                    slot="dropdown"
                    class="tabledropdowndiv noshowtriangle"
                  >
                    <el-dropdown-item
                      v-for="item in statusdropdownList"
                      :key="item.id"
                      :command="{ name: item.name, value: item.id }"
                    >
                      <span>{{ item.name }}</span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <div class="tableheader"></div>
              </div>
            </div>
          </template>
        </el-table-column>
        <!-- 参与任务数 -->
        <el-table-column prop="job_count" width="100">
          <template slot="header">
            <div class="tableheaderdiv">
              <p>{{ $t("MemberManagement.Numberoftasksinvolved") }}</p>
              <div class="icondiv">
                <div class="tableheader"></div>
              </div>
            </div>
          </template>
        </el-table-column>
        <!-- 加入时间 -->
        <el-table-column
          prop="update_time"
          sortable="custom"
          :sort-orders="['ascending', 'descending']"
        >
          <template slot="header">
            <span>{{ $t("MemberManagement.Joiningtime") }}</span>
            <span class="tableheaderspan"></span>
          </template>
        </el-table-column>
        <!-- 操作 -->
        <el-table-column width="240" :label="$t('CommonName.operation')">
          <template slot-scope="scope">
            <div class="tableBtnClass">
              <!-- 终止合作 -->
              <div>
                <el-button
                  type="text"
                  v-if="['2'].includes(scope.row.team_user_status)"
                  @click="handleClick(scope.row)"
                  >{{ $t("MemberManagement.tableBtn1") }}
                </el-button>
              </div>
              <!-- 任务记录 -->
              <div>
                <el-button
                  class="btn1"
                  type="text"
                  v-if="['2', '4'].includes(scope.row.team_user_status)"
                  @click="ViewTaskRecord(scope.row)"
                  >{{ $t("MemberManagement.tableBtn2") }}
                </el-button>
              </div>
              <div>
                <el-button
                  class="btn1"
                  type="text"
                  v-if="['2', '4'].includes(scope.row.team_user_status)"
                  @click="ViewTaskRecord1(scope.row)"
                  >{{ $t("MemberManagement.tableBtn3") }}
                </el-button>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div
        style="margin-top: 20px; float: right; margin-right: 20px"
        v-if="tableData.length > 0"
      >
        <PaginationComVue
          :tableData="[]"
          :pageSize="pageSize"
          :currentPage="currentPage"
          :total="tableTotal"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        ></PaginationComVue>
      </div>
      <DialogComVue
        :dialogVisible="dialogVisible"
        @determineClick="determineClick"
        @changeDialogVisible="changeDialogVisible"
        :footerbtnName="$t('CommonName.determine')"
        :closefooterbtnName="$t('CommonName.Cancel')"
        :width="'888px'"
        :top="'10%'"
        :title="dialogtitle"
        v-if="dialogVisible"
        v-loading="SearchLoading"
        element-loading-text=""
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.3)"
      >
        <div class="dialogContent">
          <p>{{ $t("MemberManagement.accountnumber") }}</p>
          <el-input
            ref="dialogInput"
            v-model="dialogInput"
            :placeholder="$t('MemberManagement.accountnumberinput')"
            @input="HandleInput"
            maxlength="50"
          ></el-input>
          <div class="accountSearch" v-show="accountSearchShow">
            <p>{{ $t("MemberManagement.Nickname") }}</p>
            <div style="color: #ffffff" v-text="accountSearch"></div>
          </div>
        </div>
      </DialogComVue>
    </div>
  </div>
</template>
<script>
import {
  onShowNameTipsMouseenter,
  addAndreplaceTags,
} from "@/assets/js/Publicmethods";
import PaginationComVue from "@/components/PaginationCom.vue";
import DialogComVue from "@/components/DialogCom.vue";
import {
  get_member_list,
  invitation_member,
  stop_member,
  get_user_info_member,
} from "@/utils/https";
import { showLoading, hideLoading } from "@/utils/loading";

export default {
  components: {
    DialogComVue,
    PaginationComVue,
  },
  data() {
    return {
      tags: [], //筛选数组
      popoverInput: "", //筛选内部 弹出框 输入
      visible: false,
      dialogVisible: false,
      dialogtitle: "",
      dialogInput: "",
      tableData: [],
      nicknameVisible: false,
      nicknameInput: "", //筛选内部 弹出框 输入
      statusdropdownList: [
        {
          id: "1",
          name: this.$t("MemberManagement.Inviting"),
        },
        {
          id: "2",
          name: this.$t("MemberManagement.Incooperation"),
        },
        // {
        //   id: "3",
        //   name: this.$t("MemberManagement.Rejected"),
        // },
        {
          id: "4",
          name: this.$t("MemberManagement.Terminated"),
        },
      ],
      older: "update_time",
      sort: "desc",
      pageSize: 20,
      currentPage: 1,
      tableHeight: 0,
      accountSearch: this.$t("CaptionSettings.nothing"),
      accountSearchShow: false,
      SearchLoading: false,
      defaultSort: { prop: "update_time", order: "descending" },
      tableTotal: 0,
    };
  },
  mounted() {
    hideLoading();
    //将储存的搜索条件，赋值
    if (
      sessionStorage.getItem("menuIndex") != null &&
      sessionStorage.getItem("menuIndex") ==
        "/ContainerHome/Team/MemberManagement"
    ) {
      if (
        sessionStorage.getItem("menuTags") != "" &&
        sessionStorage.getItem("menuTags") != null
      ) {
        let menuTags = JSON.parse(sessionStorage.getItem("menuTags"));
        if (menuTags.currentPage != undefined) {
          this.currentPage = menuTags.currentPage;
        }
        if (menuTags.pageSize != undefined) {
          this.pageSize = menuTags.pageSize;
        }
        if (menuTags.older != undefined) {
          this.older = menuTags.older;
          this.defaultSort.prop = this.older;
        }
        if (menuTags.sort != undefined) {
          this.sort = menuTags.sort;
          this.defaultSort.order =
            this.sort == "asc" ? "ascending" : "descending";
        }
        if (menuTags.tags != undefined) {
          this.tags = menuTags.tags;
        }
      }
    }
    this.get_member_list();
    this.$nextTick(() => {
      this.tableHeight = window.innerHeight - 300;
    });
    window.onresize = () => {
      this.tableHeight = window.innerHeight - 300;
    };
  },
  methods: {
    /**
     *
     * @param {*终止 合作 调用} id
     */
    stop_member(id) {
      stop_member({
        action: "stop_member",
        member_id: id,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.get_member_list();
      });
    },
    /**
     *
     * @param {*点击终止合作} row
     */
    handleClick(row) {
      // console.log(row);
      this.$confirm(
        this.$t("MemberManagement.deleteMessage") +
          "“" +
          row.nickname +
          "”" +
          this.$t("MemberManagement.deleteMessage1"),
        this.$t("CommonName.deleteMessageTitle"),
        {
          confirmButtonText: this.$t("CommonName.deleteMessagebutton"),
          cancelButtonText: this.$t("CommonName.deleteMessageClosebutton"),
          closeOnClickModal: false,
          cancelButtonClass: "closeButton",
          // confirmButtonClass: "confirmButton",
        }
      ).then(() => {
        showLoading(this.$t("CommonName.loadingmessage"));
        this.stop_member(row.member_id);
      });
    },
    /**
     *
     * @param {* 设置 状态} item
     */
    setData(item) {
      let a = "";
      switch (item.team_user_status) {
        case "1":
          a = this.$t("MemberManagement.Inviting");
          item.update_time = "--";
          break;
        case "2":
          a = this.$t("MemberManagement.Incooperation");
          break;
        case "3":
          a = this.$t("MemberManagement.Rejected");
          break;
        default:
          a = this.$t("MemberManagement.Terminated");
      }
      return a;
    },
    /**
     * 获取成员列表信息
     */
    get_member_list() {
      showLoading(this.$t("CommonName.loadingmessage"));
      var data = { action: "get_member_list" };
      if (this.older != "" && this.sort != "") {
        data["older"] = this.older;
        data["sort"] = this.sort;
      }
      this.tags.forEach((item, index) => {
        data[item.key] = item.value;
      });
      data["pageSize"] = this.pageSize;
      data["currentPage"] = this.currentPage;
      return get_member_list(data).then((res) => {
        if (res) {
          if (res.list.length > 0) {
            res.list.forEach((i) => {
              i.team_user_status1 = this.setData(i);
            });
          }
          this.tableData = res.list;
          this.tableTotal = parseInt(res.list_count);
          hideLoading();
        }
      });
    },
    sortChange(column) {
      // console.log(column);
      showLoading(this.$t("CommonName.loadingmessage"));
      if (column.order == "ascending") {
        this.older = column.prop;
        this.sort = "asc";
      } else {
        this.older = column.prop;
        this.sort = "desc";
      }
      this.currentPage = 1;
      this.handleMenuTags();
      this.get_member_list();
    },
    /**
     * 弹窗确认按钮
     */
    determineClick() {
      if (!this.dialogInput) {
        this.$messages(
          "warning",
          this.$t("MemberManagement.accountnumberinput")
        );
        return;
      }
      showLoading(this.$t("CommonName.loadingmessage"));
      invitation_member({
        action: "invitation_member",
        user_key: this.dialogInput,
      }).then((res) => {
        if (res) {
          if (res.error == "0") {
            sessionStorage.setItem("menuTags", "");
            this.currentPage = 1;
            this.pageSize = 10;
            this.older = "update_time";
            this.defaultSort = { prop: "update_time", order: "descending" };
            this.sort = "desc";
            this.tags = [];
            this.get_member_list().then(() => {
              this.$messages(
                "success",
                this.$t("MemberManagement.successmessage1")
              );
              this.dialogVisible = false;
              hideLoading();
            });
          } else if (res.error == "1") {
            hideLoading();
            this.$messages(
              "error",
              this.$t("MemberManagement.invitationMessage1")
            );
          } else if (res.error == "2") {
            hideLoading();
            this.$messages(
              "error",
              this.$t("MemberManagement.invitationMessage2")
            );
          } else if (res.error == "3") {
            hideLoading();
            this.$messages(
              "error",
              this.$t("MemberManagement.invitationMessage3")
            );
          }
        }
      });
    },
    /**
     *
     * @param {*弹窗关闭按钮} val
     */
    changeDialogVisible(val) {
      this.dialogVisible = val;
    },
    /**
     *
     * @param {点击查看任务记录} row
     */
    ViewTaskRecord(row) {
      // console.log(row);
      this.$router.push({
        path: "/ContainerHome/Team/MemberManagement/MemberDetails",
        query: { name: row.nickname, member_id: row.member_id },
      });
    },
    /**
     * 个人记录 查看
     * @param row
     * @constructor
     */
    ViewTaskRecord1(row) {
      // console.log(row);
      this.$router.push({
        path: "/ContainerHome/Team/MemberManagement/PersonalRecords",
        query: { name: row.nickname, member_id: row.member_id },
      });
    },
    /**
     * 邀请新成员按钮
     */
    addNewmember() {
      this.dialogInput = "";
      this.dialogtitle = this.$t("MemberManagement.headerbtn2");
      this.dialogVisible = true;
      this.accountSearchShow = false;
    },
    // 跳转 邀请记录页面
    goInvitationrecord() {
      this.$router.push({
        path: "/ContainerHome/Team/MemberManagement/InvitationRecord",
        // query: { id: row.name },
      });
    },
    /**
     *
     * @param {超出溢出 显示} i
     */
    onShowNameTipsMouseenter(i) {
      onShowNameTipsMouseenter(i);
    },
    /**
     *
     * @param {*筛选 tab 删除} tag
     */
    handleClose(tag) {
      this.tags.splice(this.tags.indexOf(tag), 1);
      this.currentPage = 1;
      this.handleMenuTags();
      this.get_member_list();
    },
    /**
     * 表格表头 名称 筛选 按钮
     */
    searchdetermineClick() {
      if (this.popoverInput) {
        this.tags = addAndreplaceTags(
          this.$t("MemberManagement.email"),
          this.popoverInput,
          this.tags,
          "email",
          this.popoverInput
        );
        this.currentPage = 1;
        this.handleMenuTags();
      }
      this.get_member_list();

      this.visible = false;
      this.popoverInput = "";
    },
    /**
     *表格表头 昵称 筛选 按钮
     *
     */
    searchNicknameClick() {
      if (this.nicknameInput) {
        this.tags = addAndreplaceTags(
          this.$t("MemberManagement.Nickname"),
          this.nicknameInput,
          this.tags,
          "nickname",
          this.nicknameInput
        );
        this.currentPage = 1;
        this.handleMenuTags();
      }
      this.get_member_list();
      this.nicknameVisible = false;
      this.nicknameInput = "";
    },
    /**
     *
     * @param {*状态筛选下拉框} command
     */
    statuscommand(command) {
      this.tags = addAndreplaceTags(
        this.$t("CommonName.status"),
        command.name,
        this.tags,
        "team_user_status",
        command.value
      );
      this.currentPage = 1;
      this.handleMenuTags();
      this.get_member_list();
    },
    /**
     * 分页器
     * @param {} val 页数
     */
    handleSizeChange(val) {
      this.pageSize = val;
      this.handleMenuTags();
      this.get_member_list();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.handleMenuTags();
      this.get_member_list();
    },
    HandleInput(value) {
      // console.log(value);
      this.accountSearchShow = false;
      let name = value.trim();
      if (name.length == 32) {
        this.$refs.dialogInput.blur();
        this.SearchLoading = true;
        get_user_info_member({
          action: "get_user_info_member",
          user_show_id: name,
        }).then((res) => {
          if (res) {
            if (res.info != "") {
              this.accountSearch = res.info.nickname;
              this.accountSearchShow = true;
            } else {
              this.accountSearch = this.$t("CaptionSettings.nothing");
            }
            this.$refs.dialogInput.focus();
            this.SearchLoading = false;
          }
        });
      }
    },
    handleMenuTags() {
      let menuTags = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        older: this.older,
        sort: this.sort,
        tags: this.tags,
      };
      sessionStorage.setItem("menuTags", JSON.stringify(menuTags));
    },
  },
};
</script>
<style scoped lang="scss">
.mainCenter {
  height: calc(100% - 38px);
  width: 100%;
  box-sizing: border-box;

  .mainCenterbtm {
    height: calc(100% - 76px);
  }
}

.mainCenterTop {
  // float: right;
  margin: 14px 24px 14px 0;

  .headerbtn1 {
    margin-right: 15px;
  }
}

.tableBtnClass {
  display: flex;

  div {
    width: 33%;
  }
}

.menuscopediv {
  margin-left: 14px;
}

.namep {
  margin-left: 14px;
}

.dialogContent {
  margin: 24px;

  p {
    color: #adb0bc;
    font-size: 14px;
    margin-bottom: 6px;
  }

  ::v-deep .el-input__inner {
    width: 840px;
  }

  .accountSearch {
    margin-top: 8px;
  }
}
</style>
