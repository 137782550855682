var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"title":_vm.dialogTitle,"visible":_vm.CenterdialogVisible,"width":"1010px","top":"calc(5%)","close-on-click-modal":false},on:{"update:visible":function($event){_vm.CenterdialogVisible=$event},"close":_vm.closeDialog}},[_c('div',{staticClass:"tts-model-main-right-anchors"},[_c('div',{staticClass:"ts-model-main-right-anchors-tab-pane"},[_c('div',{staticStyle:{"display":"flex","justify-content":"flex-start"}},[_c('div',{staticClass:"ts-model-main-right-anchors-tab-pane-select"},[_c('span',{staticStyle:{"margin":"0 8px","color":"#adb0bc"}},[_vm._v(_vm._s(_vm.$t("VideoDubbing.anchorsText7")))]),_c('el-input',{staticClass:"selectClass",attrs:{"type":"text","placeholder":_vm.$t('VideoDubbing.anchorsText8'),"maxlength":20},on:{"input":_vm.anchorNameSearch},model:{value:(_vm.anchorNameInput),callback:function ($$v) {_vm.anchorNameInput=$$v},expression:"anchorNameInput"}})],1)]),_c('div',{staticClass:"ts-model-main-right-anchors-tab-pane-anchors",attrs:{"id":"AnchorsAll"}},[_vm._l((_vm.MyCollectRolesList),function(data,index){return _c('AnchorCom',{directives:[{name:"show",rawName:"v-show",value:(
              data.is_delete == '0' &&
              data.role_name
                .trim()
                .toLowerCase()
                .indexOf(_vm.anchorNameInput.trim().toLowerCase()) > -1
            ),expression:"\n              data.is_delete == '0' &&\n              data.role_name\n                .trim()\n                .toLowerCase()\n                .indexOf(anchorNameInput.trim().toLowerCase()) > -1\n            "}],key:index,attrs:{"anchorId":data.role_collect_id,"anchorName":data.role_name,"anchorNameUpdate":data.role_name_update,"anchorNameInput":data.role_name_input,"anchorGender":'1',"anchorIndex":index,"updateNumber":data.updateNumber},on:{"anchor-click":_vm.anchorClick,"anchor-audition":_vm.anchorAudition,"anchor-delete":_vm.anchorDelete,"anchor-update":_vm.anchorUpdate}})}),(_vm.MyCollectRolesList.length == 0)?_c('div',{staticClass:"noDataDiv"},[_c('svg-icon',{staticClass:"noDataicon",attrs:{"icon-class":"NoData"}}),_c('p',[_vm._v(_vm._s(_vm.$t("CommonName.noData")))])],1):_vm._e()],2)])]),_c('audio',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"tryPlaySampleAudio",on:{"ended":_vm.overTryPlaySampleAudio}},[_c('source')])])
}
var staticRenderFns = []

export { render, staticRenderFns }