<!--
 * @Description: 团队总页面
-->
<template>
  <el-container class="contentcon">
    <el-aside width="140px">
      <el-menu
        :default-active="defaultActive"
        class="el-menu-vertical-demo"
        background-color="#242533"
        text-color="#959dad"
        active-text-color="#fff"
        router
        @select="selectClick"
        :collapse="false"
      >
        <el-menu-item :index="i.index" v-for="i in menuList" :key="i.index">
          <svg-icon :icon-class="i.svgicon"></svg-icon>
          <span slot="title">{{ i.menuName }}</span>
        </el-menu-item>
      </el-menu>
    </el-aside>
    <el-main>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
      <DialogComVue
        :dialogVisible="dialogVisible"
        :width="'536px'"
        :top="'15%'"
        :showClose="false"
        :showTitle="false"
        :showfooter="false"
        :modalAppend="false"
        v-if="dialogVisible"
      >
        <div class="vipClass">
          <div>
            <svg-icon icon-class="lock" class="svgClass"></svg-icon>
          </div>
          <div>体验更多团队功能，请购买企业 VIP</div>
          <div>
            <el-button
              type="warning"
              class="setpadding23"
              @click="gobuyEnterprisesOpenedVIP"
              >去购买
            </el-button>
          </div>
        </div>
      </DialogComVue>
    </el-main>
  </el-container>
</template>
<script>
import DialogComVue from "@/components/DialogCom.vue";
import config from "@/config";

export default {
  data() {
    return {
      defaultActive: "",
      menuList: [
        {
          index: "/ContainerHome/ExTeam/ProjectManagement",
          svgicon: "project",
          menuName: this.$t("name.Projects"),
        },
        {
          index: "/ContainerHome/ExTeam/MemberManagement",
          svgicon: "member",
          menuName: this.$t("name.Members"),
        },
        // {
        //   index: "/ContainerHome/ExTeam/TMManagement",
        //   svgicon: "TMfiles",
        //   menuName: this.$t("name.TranslationMemory"),
        // },
        {
          index: "/ContainerHome/ExTeam/TermBaseManagement",
          svgicon: "Glossaries",
          menuName: this.$t("name.Glossary"),
        },
        // {
        //   index: "/ContainerHome/ExTeam/HoldAll",
        //   svgicon: "Tools",
        //   menuName: this.$t("name.Tools"),
        // },
      ],
      dialogVisible: false,
      menustatus: false,
      info: [],
    };
  },
  components: {
    DialogComVue,
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        // console.log(val);
        this.menuList.map((i) => {
          if (i.index == val.path) {
            sessionStorage.setItem("menuIndex", val.path);
            this.defaultActive = val.path;
          } else {
            if (
              val.path ==
              "/ContainerHome/ExTeam/ProjectManagement/ProjectDetails"
            ) {
              this.defaultActive = "/ContainerHome/ExTeam/ProjectManagement";
              sessionStorage.setItem(
                "menuIndex",
                "/ContainerHome/ExTeam/ProjectManagement"
              );
            } else if (
              val.path ==
              "/ContainerHome/ExTeam/TMManagement/TMManagementDetails"
            ) {
              this.defaultActive = "/ContainerHome/ExTeam/TMManagement";
              sessionStorage.setItem(
                "menuIndex",
                "/ContainerHome/ExTeam/TMManagement"
              );
            } else if (
              val.path ==
              "/ContainerHome/ExTeam/TermBaseManagement/TermBaseManagementDetails"
            ) {
              this.defaultActive = "/ContainerHome/ExTeam/TermBaseManagement";
              sessionStorage.setItem(
                "menuIndex",
                "/ContainerHome/ExTeam/TermBaseManagement"
              );
            } else {
              this.defaultActive = sessionStorage.getItem("menuIndex");
            }
          }
        });
      },
      // 深度观察监听
      deep: true,
    },
  },
  created() {
    //   $emit子组件调用父组件的方法并传递数据
    this.$emit("header", false, false, false, true, true);
    if (sessionStorage.getItem("menuIndex") == null) {
      this.defaultActive = "/ContainerHome/ExTeam/ProjectManagement";
      sessionStorage.setItem(
        "menuIndex",
        "/ContainerHome/ExTeam/ProjectManagement"
      );
    } else {
      this.defaultActive = sessionStorage.getItem("menuIndex");
    }
    this.info = JSON.parse(sessionStorage.getItem("info"));
    if (this.info.role == 1) {
      this.dialogVisible = true;
    }
    if (this.info.role == 3) {
      this.menustatus = true;
    }
    this.handleLang();
    // this.$router.push(this.defaultActive);
  },
  methods: {
    selectClick(index) {
      console.log(index);
      // this.defaultActive = index;
      if (sessionStorage.getItem("menuIndex") == index) {
        this.$router.push({
          path: index,
          query: {},
        });
        this.$router.go(0);
      }
      sessionStorage.setItem("menuIndex", index);
      if (this.defaultActive != sessionStorage.getItem("menuIndex")) {
        sessionStorage.setItem("menuTags", "");
      }

      this.handleLang();
    },
    /**
     * 点击购买更多
     */
    gobuyEnterprisesOpenedVIP() {
      this.setSessionItem("BuyServices", "second");
      this.$router.push({
        path: "/ContainerHome/PurchaseServices",
      });
    },
    /**
     * 团队端语言显示设置
     */
    handleLang() {
      let lang = this.$Cookie.get("lang");
      if (lang == undefined || lang == "") {
        //读取当前浏览器语言
        const navLang = navigator.language || navigator.userLanguage;
        let langCode = "cn";
        // console.log(navLang);
        if (navLang == "zh-CN") {
          langCode = "cn";
        } else if (navLang == "en-US" || navLang == "en") {
          langCode = "en";
        } else {
          langCode = "cn";
        }
        this.$i18n.locale = langCode;
        sessionStorage.setItem("lang", langCode);
        this.$Cookie.set("lang", langCode, {
          expires: 7,
          domain: config.cookies.domain,
        });
        window.location.reload();
      } else {
        // console.log("bugai");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.contentcon {
  height: 100%;
  width: 100%;
  background-color: #2c2d37 !important;

  .el-aside {
    height: 100%;
    background-color: #242533 !important;

    .el-menu {
      border-right: none;
      height: 100%;

      .el-menu-item {
        display: flex;
        align-items: center;
        // box-shadow: 0px 2px 8px rgba(12, 25, 32, 0.4);
        .svg-icon {
          margin-right: 8px;
          font-size: 16px;
        }
      }

      .el-menu-item.is-active {
        background-color: #46adff !important;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
      }
    }
  }

  .el-main {
    height: calc(100% - 16px);
    width: calc(100% - 156px);
    padding: 0;
    margin: 8px;
    background-color: #393c4e;
    border-radius: 4px;
    box-shadow: 0px 2px 8px rgba(12, 25, 32, 0.3);
    color: #ffffff;
    position: relative;
  }

  //::v-deep .el-dialog__header {
  //  border: 0;
  //}

  ::v-deep .el-dialog__wrapper {
    position: absolute;
  }

  ::v-deep .v-modal {
    position: absolute;
  }

  .vipClass {
    color: #ffffff;
    height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 32px;

    .svgClass {
      font-size: 29px;
    }

    .setpadding23 {
      padding: 0 23px !important;
    }
  }
}
</style>
