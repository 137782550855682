<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    append-to-body
    :width="'888px'"
    :top="'3%'"
    center
    :close-on-click-modal="false"
    @close="closeDialog"
  >
    <div class="stepCenter">
      <!-- 配音语言 -->
      <div class="stepCenterItem">
        <div class="stepCenter_left">
          <p>{{ $t("PersonalEndLanguage.VideoDubbingText") }}</p>
        </div>
        <div class="stepCenter_right">
          <el-select v-model="form.select1" filterable>
            <el-option
              v-for="item in languageoptions"
              :key="item.language_id"
              :label="item.cname"
              :value="item.language_id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <!-- 源视频和配音脚本来源 -->
      <div class="stepCenterItem">
        <div class="stepCenter_left">
          <p>{{ $t("ProjectDetails.createText1") }}</p>
        </div>
        <div class="stepCenter_right">
          <div class="radioClass content_2">
            <el-radio-group v-model="form.radio" @input="radioInput">
              <el-radio
                v-for="i in radioList"
                :key="i.label"
                :label="i.label"
                border
                >{{ i.name }}</el-radio
              >
            </el-radio-group>
          </div>
        </div>
      </div>
      <!-- 上传 -->
      <div
        class="content_center_1"
        v-show="form.radio == '0'"
        style="margin: 12px 0"
      >
        <div class="uploadp" style="justify-content: space-between">
          <p>{{ $t("PersonalEndLanguage.uploadFile") }}</p>
          <p>{{ form.fileList.length }} / {{ maxFiles }}</p>
          <svg-icon
            icon-class="presentation"
            class="verificationIcon"
            v-if="form.uploadinput1 == null"
          ></svg-icon>
        </div>
        <div class="tag_left_third">
          <el-upload
            class="upload-demo"
            drag
            ref="upload"
            :with-credentials="true"
            :action="$uploadURL + '/videojob/gateway'"
            :on-change="hardSubtitleRecognition_change_third"
            :on-exceed="hardSubtitleRecognition_Exceed_third"
            :auto-upload="false"
            :show-file-list="false"
            :limit="maxFiles"
            :file-list="form.fileList"
            multiple
          >
            <div
              class="uploaddiv"
              slot="trigger"
              @mouseenter="AudiotranscriptionClick_third"
            >
              <div v-if="form.fileList.length > 0" class="filelist_div">
                <div
                  class="upload_table_div"
                  @click="
                    (e) => {
                      return e.stopPropagation();
                    }
                  "
                >
                  <div class="videoDatanamediv_header videoDatanamediv_header1">
                    <p style="" class="first_p">
                      <!--                              视频标题-->
                      {{ $t("PersonalEndLanguage.videoTitle") }}
                    </p>
                    <!--                    <p style="" class="last_p">-->
                    <!--                      &lt;!&ndash;                              源语言&ndash;&gt;-->
                    <!--                      {{ $t("MyFiles.sourceLanguage") }}-->
                    <!--                    </p>-->
                  </div>
                  <div class="videoDatanamediv_body_list">
                    <div
                      class="videoDatanamediv_body"
                      v-for="item in form.fileList"
                      :key="item.id"
                    >
                      <div class="left_div left_div2">
                        <div class="svgdivs">
                          <svg-icon icon-class="video" class="icon"></svg-icon>
                        </div>
                        <div
                          class="body_p"
                          :id="'tag' + item.id"
                          @mouseover="onShowNameTipsMouseenter(item)"
                        >
                          <el-tooltip
                            class="item"
                            effect="dark"
                            :content="item.name"
                            placement="top-start"
                            :disabled="!item.showdropdown"
                            :visible-arrow="false"
                          >
                            <span>{{ item.name }}</span>
                          </el-tooltip>
                        </div>
                      </div>
                      <div class="center_input">
                        <el-input
                          :placeholder="
                            $t('PersonalEndLanguage.VideoDubbingText4')
                          "
                          v-model="item.srtinput"
                          class="input-with-select"
                          @input="item.srtinput = ''"
                        >
                          <el-upload
                            slot="append"
                            class="upload-demo"
                            :ref="item.id + 'srtupload'"
                            :action="$uploadURL + '/job/upload_srt_file'"
                            name="srt_file"
                            :with-credentials="true"
                            :before-upload="srtbeforeUpload"
                            :show-file-list="false"
                            :on-success="srtuploadSuccess"
                            :auto-upload="true"
                            :limit="1"
                          >
                            <el-button
                              @click="srtupload(item.id + 'srtupload', item, 1)"
                              type="text"
                            >
                              <div class="svgbtn">
                                <svg-icon
                                  icon-class="upload"
                                  class="icon"
                                ></svg-icon>
                                <!--                              上传-->
                                <div>
                                  {{ $t("PersonalEndLanguage.upload") }}
                                </div>
                              </div>
                            </el-button>
                          </el-upload>
                        </el-input>
                      </div>
                      <div class="last_delete last_delete1">
                        <el-button
                          type="text"
                          @click="
                            hardSubtitleRecognitionList_Delete_Click(
                              $event,
                              item
                            )
                          "
                        >
                          <svg-icon icon-class="delete" class="icon"></svg-icon>
                        </el-button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="p_div">
                  <i class="el-icon-plus"></i>
                  <p style="color: #2f9efd">
                    <!--                            点击选择或拖动视频到这里-->
                    {{ $t("PersonalEndLanguage.DragVideo") }}
                  </p>
                </div>
              </div>
              <div v-else>
                <i class="el-icon-plus"></i>
                <p class="topp">
                  {{ $t("PersonalEndLanguage.DragVideo") }}
                </p>
                <p class="botp">
                  {{
                    $t("PersonalEndLanguage.SupportsMP4formatvideo") +
                    "≤" +
                    info.video_dubbing_filesize_limit +
                    "GB，" +
                    $t("PersonalEndLanguage.duration") +
                    "≤"
                  }}
                  <span>{{ info.video_dubbing_duration_limit }}</span>
                  {{
                    $t("PersonalEndLanguage.minute") +
                    $t("PersonalEndLanguage.moreLangth4")
                  }}
                </p>
              </div>
            </div>
          </el-upload>
        </div>
      </div>
      <div class="content_center_2" v-show="form.radio == '1'">
        <div
          class="tableClass borderTableStyle"
          :class="tableData.length == 0 ? 'tablebtmborder' : ''"
        >
          <el-table
            :row-style="{
              height: 40 + 'px',
              background: '#393c4e',
              color: '#cbd7e8',
            }"
            :cell-style="{ padding: 0 + 'px' }"
            :header-cell-style="{
              height: 32 + 'px',
              padding: '0px',
              color: '#adb0bc',
              background: '#393c4e',
            }"
            ref="multipleTable"
            :data="tableData"
            @select="handleSelect"
            @select-all="handleALLSelect"
            :row-key="getRowKey"
            tooltip-effect="dark"
            style="width: 100%; overflow-y: auto; border-right: 1px solid"
            max-height="233px"
            border
          >
            <div slot="empty" class="noDataDiv">
              <p>{{ $t("CommonName.noData") }}</p>
            </div>
            <el-table-column
              type="selection"
              width="65"
              align="center"
              :reserve-selection="true"
            ></el-table-column>
            <!-- 名称 -->
            <el-table-column
              :min-width="140"
              prop="name"
              :label="$t('CommonName.TaskName')"
              show-overflow-tooltip
            >
            </el-table-column>
            <!-- 字幕涂抹状态 -->
            <el-table-column
              prop="status"
              :label="$t('ProjectDetails.createText4')"
              width="120"
            >
              <template slot-scope="scope">
                <div style="" class="menuscopediv">
                  {{
                    scope.row.tlang_id == "1"
                      ? $t("ProjectDetails.createText5")
                      : ""
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <!-- 上传人声音轨（非必填 -->
      <div class="stepCenterItem" v-if="createId == 3">
        <div class="stepCenter_left">
          <p>{{ $t("ProjectDetails.createText6") }}</p>
        </div>
        <div class="stepCenter_right">
          <el-input
            v-model="form.audioName"
            :placeholder="$t('ProjectDetails.createText8')"
            class="input-with-select"
            :class="dubbingVocalsUploadDisabled ? '' : 'srtUnput'"
            @input="form.audioName = ''"
            :disabled="dubbingVocalsUploadDisabled"
          >
            <template v-if="!dubbingVocalsUploadDisabled">
              <el-upload
                slot="append"
                class="upload-demo"
                ref="dubbing_vocals"
                :action="$uploadURL + '/job/upload_audio_file'"
                name="audio_file"
                :with-credentials="true"
                :before-upload="audioBeforeUpload"
                :show-file-list="false"
                :on-success="
                  (response, file, fileList) =>
                    audioUploadSuccess(response, file, fileList, 1)
                "
                :auto-upload="true"
                :limit="1"
              >
                <el-button @click="srtupload('dubbing_vocals')" type="text">
                  <div class="svgbtn">
                    <svg-icon icon-class="upload" class="icon"></svg-icon>
                    <!--                              上传-->
                    <div>
                      {{ $t("PersonalEndLanguage.upload") }}
                    </div>
                  </div>
                </el-button>
              </el-upload>
            </template>
          </el-input>
        </div>
      </div>
      <!-- 背景音频地址 -->
      <div class="stepCenterItem" v-if="createId == 3">
        <div class="stepCenter_left">
          <p>{{ $t("ProjectDetails.createText7") }}</p>
        </div>
        <div class="stepCenter_right">
          <el-input
            v-model="form.audioName1"
            :placeholder="$t('ProjectDetails.createText8')"
            class="input-with-select"
            :class="dubbingVocalsUploadDisabled ? '' : 'srtUnput'"
            @input="form.audioName1 = ''"
            :disabled="dubbingVocalsUploadDisabled"
          >
            <template v-if="!dubbingVocalsUploadDisabled">
              <el-upload
                slot="append"
                class="upload-demo"
                ref="dubbing_novocals"
                :action="$uploadURL + '/job/upload_audio_file'"
                name="audio_file"
                :with-credentials="true"
                :before-upload="audioBeforeUpload"
                :show-file-list="false"
                :on-success="
                  (response, file, fileList) =>
                    audioUploadSuccess(response, file, fileList, 2)
                "
                :auto-upload="true"
                :limit="1"
              >
                <el-button @click="srtupload('dubbing_novocals')" type="text">
                  <div class="svgbtn">
                    <svg-icon icon-class="upload" class="icon"></svg-icon>
                    <!-- 上传-->
                    <div>
                      {{ $t("PersonalEndLanguage.upload") }}
                    </div>
                  </div>
                </el-button>
              </el-upload>
            </template>
          </el-input>
        </div>
      </div>
      <!-- 是否涂抹源视频字幕 -->
      <div class="stepCenterItem">
        <div class="stepCenter_left">
          <p>
            {{ $t("ProjectDetails.createText3") }}
            <el-tooltip placement="top-start" popper-class="uploadptooltip">
              <div slot="content">
                <div>
                  {{
                    $t("PersonalEndLanguage.warningmessage41") +
                    info.video_daub_minute_limit +
                    $t("PersonalEndLanguage.warningmessage50")
                  }}
                </div>
              </div>
              <svg-icon
                icon-class="presentation"
                class="icon"
                style="cursor: pointer"
              ></svg-icon>
            </el-tooltip>
          </p>
        </div>
        <div class="stepCenter_right">
          <div class="radioClass content_2">
            <el-radio-group v-model="form.radio1" :disabled="radioDisabled">
              <el-radio
                v-for="i in radioList1"
                :key="i.label"
                :label="i.label"
                border
                >{{ i.name }}</el-radio
              >
            </el-radio-group>
          </div>
        </div>
      </div>
    </div>
    <div slot="footer" class="dialog-footer" style="margin-top: 24px">
      <div>
        <!--        取消-->
        <el-button @click="closeDialog" type="info" class="setpadding23"
          ><p class="footerleftbuttonp">
            {{ $t("CommonName.Cancel") }}
          </p></el-button
        >
        <!--        确定-->
        <el-button type="primary" @click="createClick" class="setpadding23">
          <p class="footerrightbuttonp2" style="">
            {{ $t("CommonName.OK") }}
          </p>
        </el-button>
      </div>
    </div>
    <!--      硬字幕识别-->
    <el-dialog
      :visible.sync="innerVisible"
      @close="closeinnerVisible(1)"
      custom-class="innerDialogClass"
      append-to-body
      width="888px"
      top="5%"
      center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :destroy-on-close="true"
      v-if="innerVisible"
    >
      <div slot="title" class="dialogTitle_div overflowEllipsis">
        {{ dialogtitle }}
      </div>
      <div class="innerDialog_div">
        <!--                      错误示例-->
        <div class="innerDialog_div_top_p">
          <p>
            {{ $t("PersonalEndLanguage.warningmessage35") }}
            <el-popover
              placement="bottom-end"
              trigger="hover"
              :visible-arrow="false"
              popper-class="innerDialogPopover"
            >
              <div class="innerDialogPopover_div">
                <div>
                  <p>
                    <svg-icon icon-class="icon_No" class="cuoWu"></svg-icon
                    >{{ $t("PersonalEndLanguage.ViewExamples1") }}
                  </p>
                  <el-image
                    :src="require('@/assets/imgs/cuoWu_shiLi.png')"
                    fit="fill"
                  ></el-image>
                  <p class="btmp" style="word-break: break-word">
                    {{ $t("PersonalEndLanguage.ViewExamples3") }}
                  </p>
                </div>
                <div>
                  <p>
                    <svg-icon icon-class="Yes" class="zhengQue"></svg-icon>
                    {{ $t("PersonalEndLanguage.ViewExamples2") }}
                  </p>
                  <el-image
                    :src="require('@/assets/imgs/zhengQue_shiLi.png')"
                    fit="fill"
                  ></el-image>
                  <p class="btmp" style="word-break: break-word">
                    {{ $t("PersonalEndLanguage.ViewExamples4") }}
                  </p>
                </div>
              </div>
              <el-button slot="reference" type="text">{{
                $t("PersonalEndLanguage.ViewExamples")
              }}</el-button>
            </el-popover>
          </p>
        </div>
        <!-- 视频展示 -->
        <div class="innerDialog_video_div" id="innerDialog_video_div">
          <video
            class="innerDialog_video_class"
            id="dialogVideo"
            ref="videoref"
            :src="videoUrl"
            type="video/mp4"
            oncontextmenu="return false"
          >
            <!-- <source /> -->
          </video>
          <canvas id="canvas" :width="1" :height="360"></canvas>
        </div>
        <!--        滑块-->
        <div class="sliderClass">
          <el-slider
            v-model="slidervalue"
            :show-tooltip="true"
            :step="step"
            @change="sliderInput"
            :max="silderMax"
            :marks="form.radio == '1' ? marks : {}"
            :format-tooltip="formatTooltip"
          ></el-slider>
        </div>
        <div class="left_videobom_div left_videobom_div_play">
          <div class="left"></div>
          <div class="center">
            <!-- 回退 -->
            <div @click="rew" class="btndiv">
              <!-- ! todo -->
              <svg-icon icon-class="VideoBack" class="svgiconclass"></svg-icon>
            </div>
            <!-- 开始时间 -->
            <p>{{ videoStartTime }}</p>
            <!-- 暂停播放按钮 -->

            <div @click="playvideo" v-if="player" class="btndiv" id="step-5">
              <svg-icon icon-class="VideoPlay" class="svgiconclass"></svg-icon>
            </div>
            <div @click="closevideo" v-else class="btndiv" id="step-5">
              <svg-icon icon-class="VideoPause" class="svgiconclass"></svg-icon>
            </div>

            <!-- 结束时间 -->
            <p>{{ videolangtime }}</p>
            <!-- 前进 -->
            <div @click="speek" class="btndiv">
              <!-- ! todo -->
              <svg-icon
                icon-class="VideoForward"
                class="svgiconclass"
              ></svg-icon>
            </div>
          </div>
          <div class="right">
            <!-- 播放倍数 -->
            <!-- <el-dropdown @command="handleCommand" placement="top" trigger="click">
            <p
              class="el-dropdown-link"
              style="text-align: end; cursor: pointer"
            >
              {{ ds + "X" }}
            </p>
            <el-dropdown-menu slot="dropdown" :append-to-body="false">
              <el-dropdown-item command="0.5">0.5X</el-dropdown-item>
              <el-dropdown-item command="0.75">0.75X</el-dropdown-item>
              <el-dropdown-item command="1">1X</el-dropdown-item>
              <el-dropdown-item command="1.25">1.25X</el-dropdown-item>
              <el-dropdown-item command="1.5">1.5X</el-dropdown-item>
              <el-dropdown-item command="1.75">1.75X</el-dropdown-item>
              <el-dropdown-item command="2">2X</el-dropdown-item>
              <el-dropdown-item command="2.5">2.5X</el-dropdown-item>
              <el-dropdown-item command="3">3X</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown> -->
          </div>
        </div>
        <!--         时间-->
        <div class="time_div" v-if="form.radio == '1'">
          <div class="start_time_div">
            <div>
              <!--                片头时间点-->
              <span>{{ $t("PersonalEndLanguage.openingTime") }}</span>
              <el-input
                v-model="startTime"
                placeholder="00:00:00"
                clearable
                suffix-icon="el-icon-time"
                @clear="clearInput(0)"
                @blur="inputBlur(0)"
              >
              </el-input>
            </div>
            <div
              class="icon_div"
              @click="timeIconClick(0, videoDom.currentTime)"
            >
              <i class="el-icon-location icon"></i>
            </div>
          </div>
          <div class="end_time_div">
            <div>
              <!--                片尾时间点-->
              <span>{{ $t("PersonalEndLanguage.endingTime") }}</span>
              <el-input
                v-model="endTime"
                placeholder="00:00:00"
                clearable
                suffix-icon="el-icon-time"
                @clear="clearInput(1)"
                @blur="inputBlur(1)"
              >
              </el-input>
            </div>
            <div
              class="icon_div"
              @click="timeIconClick(1, videoDom.currentTime)"
            >
              <i class="el-icon-location icon1"></i>
            </div>
          </div>
        </div>
        <p class="tips_p" v-if="form.radio == '1'">
          <!--          您可以手动选择要识别的片头时间点和片尾时间，选择后将仅对所选时间范围内进行硬字幕识别。-->
          {{ $t("PersonalEndLanguage.ViewExamples7") }}
        </p>
      </div>
      <!--      底部 按钮-->
      <div slot="footer" class="dialog-footer" style="margin-top: 24px">
        <el-button
          type="info"
          @click="closeinnerVisible(2)"
          class="setpadding23"
          >{{
            form.fileList.length > 1
              ? row_id === 0
                ? $t("CommonName.Previous")
                : $t("CommonName.Prev")
              : $t("CommonName.Previous")
          }}</el-button
        >
        <el-button
          type="primary"
          @click="SureClick"
          class="setpadding23"
          :loading="buttonLoading"
          >{{
            form.fileList.length > 1
              ? row_id + 1 === form.fileList.length
                ? $t("CommonName.OK")
                : $t("CommonName.Nexts")
              : $t("CommonName.OK")
          }}</el-button
        >
        <el-button
          type="primary"
          @click="immediateClick"
          class="setpadding23"
          :loading="buttonLoading"
          >{{ $t("CommonName.immediateClick") }}</el-button
        >
      </div>
      <div
        class="progressdiv"
        v-if="
          form.fileList.length > 0 && totalProgress > 0 && totalProgress < 100
        "
        v-loading.fullscreen.lock="loading"
        element-loading-custom-class="disaplaynoneicon"
        element-loading-background="rgba(0, 0, 0, 0.2)"
      >
        <el-progress
          type="circle"
          :percentage="totalProgress"
          :stroke-width="10"
        ></el-progress>
      </div>
    </el-dialog>
    <!-- 进度条 -->
    <div
      class="progressdiv"
      v-if="
        form.fileList.length > 0 && totalProgress > 0 && totalProgress < 100
      "
      v-loading.fullscreen.lock="loading"
      element-loading-custom-class="disaplaynoneicon"
      element-loading-background="rgba(0, 0, 0, 0.2)"
    >
      <el-progress
        type="circle"
        :percentage="totalProgress"
        :stroke-width="10"
      ></el-progress>
    </div>
  </el-dialog>
</template>
<script>
import { hideLoading, showLoading } from "@/utils/loading";
import {
  get_oss_policy,
  get_ocr_last_job,
  get_language,
  get_user_quota_info,
  customer_api_method,
  check_srt_max_duration,
} from "@/utils/https";
import { getCurrentTime, TimeHandle } from "@/api/setTime";
import { onShowNameTipsMouseenter } from "@/assets/js/Publicmethods";
import { fabric } from "fabric";
import { getTimeString, getFileName, getFileNameUUID } from "@/utils/tools";
export default {
  props: {
    showdialog: {
      type: Boolean,
      default: false,
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    multipleSelectionArray: {
      type: Array,
      default: () => [],
    },
    createId: {
      type: Number,
      default: 2,
    },
    createType: {
      type: String,
      default: "0", //0：基础创建；1：选已有任务创建
    },
  },
  computed: {
    /**
     * 上传 进度
     * @returns {number}
     */
    totalProgress() {
      const total = this.form.fileList.reduce(
        (acc, progressobj) => acc + progressobj.percentage,
        0
      );
      const total1 = this.form.fileList.reduce(
        (acc, progressobj) => acc + progressobj.size,
        0
      );
      // console.log(Math.floor((total / total1) * 100), "111111");
      return Math.floor((total / total1) * 100);
    },
    /**
     * 人声音轨与背景音轨上传是否禁用
     */
    dubbingVocalsUploadDisabled() {
      return (
        (this.form.radio == "0" && this.form.fileList.length != 1) ||
        (this.form.radio == "1" && this.multipleSelection.length != 1)
      );
    },
    /**
     * 硬字幕涂抹是否禁用
     */
    radioDisabled() {
      return (
        (this.form.radio == "0" && this.isRadioDisabled) ||
        (this.form.radio == "1" && this.isRadioDisabled1)
      );
    },
  },
  data() {
    return {
      innerVisible: false, //内部弹窗 开关
      dialogtitle: "", //内部弹窗 文件名称
      buttonLoading: false, // 内部弹窗 确定创建任务 按钮loading
      row_id: 0, //当前 展示得 视频
      info: {}, // 获取得 账号信息
      loading: false, //点击上传 获取上传进度 loading
      dialogVisible: false,
      title: "", //弹窗名称
      project_id: "", // 项目id
      form: {
        radio: "0", //原字幕获取方式
        radio1: "0", //涂抹选项
        uploadinput1: "", //Upload Media Zip File
        select1: "", //Source language
        select2: "", //Target language
        fileList: [],
        checkboxChecked: false, //将字幕翻译为
        audioName: "", //上传人声音轨文件显示名称
        dubbing_vocals_name: "", //上传人声音轨文件 名称
        dubbing_vocals_path: "", //上传人声音轨文件地址
        dubbing_vocals_time: 0, //上传人声音轨文件时长
        audioName1: "", //上传国际轨音轨文件显示名称
        dubbing_novocals_name: "", //上传国际轨音轨文件 名称
        dubbing_novocals_path: "", //上传国际轨音轨文件地址
        dubbing_novocals_time: 0, //上传国际轨音轨文件时长
        checkboxDisabled: true, //是否可以选择硬字幕涂抹与硬字幕压制
      },
      // 选项
      radioList2: [
        {
          label: "1",
          name: this.$t("CommonName.PreprocessOptions.label4"),
          disabled: false,
        },
      ],
      // 源视频和配音脚本来源
      radioList: [
        {
          label: "0",
          name: this.$t("ProjectDetails.createRadioText1"),
        },
        {
          label: "1",
          name: this.$t("ProjectDetails.createRadioText2"),
        },
      ],
      // 涂抹选项
      radioList1: [
        {
          label: "0",
          name: this.$t("ProjectDetails.createRadioText3"),
        },
        {
          label: "1",
          name: this.$t("ProjectDetails.createRadioText5"),
        },
      ],
      // 硬字幕识别 所需参数
      videoWidth: 0, //获取视频 原始高度
      videoUrl: "", //内部弹窗 中的视频
      videoHeight: 0, //获取视频原始 高度
      startTime: "", //片头时间点
      endTime: "", //片尾时间点
      old_time: "", //记录 片头时间
      old_time1: "", //记录 片尾事件
      Sliderstep: 0.01, //步长
      silderMax: 0, //总步长
      marks: {}, // 标记 片头时间点 片尾时间点
      canvas: null, //canvas
      rect: null, //创建矩形
      videoDom: {}, //创建 视频对象
      slidervalue: 0, //当前步
      formatmessage: true, //视频配音 上传文件 格式错误 提示标识
      sizemessage: true, //视频配音 上传文件 大小错误 提示标识
      langMessage: true, //视频配音 上传文件 名称长短 提示标识
      chunkSize: 20 * 1024 * 1024, // 每个分片大小为2MB
      select2List: [], // 成员列表 转写 翻译 校对 处理人
      HardlanguageList: [
        "2",
        "1",
        "3",
        "8",
        "9",
        "5",
        "11",
        "13",
        "15",
        "17",
        "24",
        "25",
      ], //硬字幕识别支持的源语言
      videoStartTime: "00:00:00.00",
      videolangtime: "00:00:00.00", //总时长
      player: true,
      ds: 1.0, // 倍数
      maxFiles: 100, //批量创建任务最大数量
      languageoptions: [],
      multipleSelection: [],
      isRadioDisabled: false,
      isRadioDisabled1: false,
      fileSizeMB: 0,
      fileTotalDuration: 0,
    };
  },
  mounted() {
    console.log(this.tableData);
    console.log(this.multipleSelectionArray);
    this.dialogVisible = this.showdialog;
    this.title =
      this.createId == 3
        ? this.$t("ProjectDetails.createTaskTitle2")
        : this.$t("ProjectDetails.createTaskTitle1");
    this.info = JSON.parse(sessionStorage.getItem("info"));
    this.project_id = this.$route.query.id;
    this.get_language();
    if (this.createType == "1") {
      this.$nextTick(() => {
        this.form.radio = this.createType;
        this.multipleSelection = this.multipleSelectionArray;
        this.$refs.multipleTable.clearSelection();
        this.multipleSelection.forEach((rows) => {
          this.$refs.multipleTable.toggleRowSelection(rows);
        });
      });
    }
    window.addEventListener("keydown", this.windowkeydown, true);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.windowkeydown, true);
  },
  methods: {
    /**
     *
     * @param {*判断是否是 上传的音轨对应后缀文件} file
     */
    audioBeforeUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testmsg.toLowerCase() === "wav";
      //视频克隆配音
      if (file.size / 1024 / 1024 > 200) {
        this.$messages(
          "warning",
          this.$t("PersonalEndLanguage.warningmessage43")
        );
        return false;
      }

      if (!extension) {
        this.$messages(
          "warning",
          this.$t("PersonalEndLanguage.warningmessage40")
        );
        return false;
      } else {
        return true;
      }
    },
    /**
     *
     * @param {*上传成功code} response
     * @param {*上传成功文件} file
     */
    audioUploadSuccess(response, file, list, mode) {
      if (response.result == "SUCCESS") {
        if (mode == 1) {
          this.form.audioName = file.name; //上传人声音轨文件 名称
          this.form.dubbing_vocals_name = response.name; //上传人声音轨文件 名称
          this.form.dubbing_vocals_path = response.path; //上传人声音轨文件地址
          this.form.dubbing_vocals_time = response.duration; //上传人声音轨文件时长
        } else if (mode == 2) {
          this.form.audioName1 = file.name; //上传人声音轨文件 名称
          this.form.dubbing_novocals_name = response.name; //上传国际轨音轨文件 名称
          this.form.dubbing_novocals_path = response.path; //上传国际轨音轨文件地址
          this.form.dubbing_novocals_time = response.duration; //上传国际音轨文件时长
        }
        console.log("this.form", this.form);
      } else if (response.result === "LOGIN_FAILED") {
        this.$messages("warning", this.$t("CommonName.tokenMessage"));
        this.$router.push("/Login");
        this.$Cookie.remove("token_code");
        sessionStorage.clear();
      } else {
        this.$messages("error", response.msg);
      }
    },
    /**
     *
     * @param {* 设置表格 key} row
     */
    getRowKey(row) {
      return row.job_id;
    },
    /**
     * 表格复选框 选中
     * @param selection
     * @param row
     */
    handleSelect(selection, row) {
      // if (this.ShiftDown) {
      //   // this.multipleSelection.push(row);
      //   if (!!this.startrow.job_id == false) {
      //     this.multipleSelection = selection;
      //     this.$refs.multipleTable.clearSelection();
      //     this.$refs.multipleTable.toggleRowSelection(row);
      //     this.startrow = row;
      //   } else {
      //     let data;
      //     if (this.startrow.index < row.index) {
      //       data = this.tableData.slice(this.startrow.index, row.index + 1);
      //     } else {
      //       data = this.tableData.slice(row.index, this.startrow.index + 1);
      //     }
      //     this.multipleSelection = data;
      //     this.$refs.multipleTable.clearSelection();
      //     data.forEach((rows) => {
      //       this.$refs.multipleTable.toggleRowSelection(rows);
      //     });
      //   }

      //   return;
      // }
      this.startrow = {};
      this.startrow = row;
      console.log("selection", selection);
      this.isRadioDisabled = false;
      selection.forEach((i) => {
        console.log("少了一个", i);
        if (+i.duration_video_time / 60 > +this.info.video_daub_minute_limit) {
          this.isRadioDisabled1 = true;
          this.form.radio1 = "0";
        }
      });
      this.multipleSelection = selection;
    },
    /**
     * 表格复选框 全选/全不选
     * @param selection
     * @param row
     */
    handleALLSelect(selection) {
      this.startrow = {};
      selection.forEach((i) => {
        console.log("少了一个", i);
        if (+i.videotime / 60 > +this.info.video_daub_minute_limit) {
          this.isRadioDisabled1 = true;
          this.form.radio1 = "0";
        }
      });
      this.multipleSelection = selection;
    },
    /**
     * 获取语言接口
     */
    get_language() {
      get_language({
        action: "get_language",
        lang:
          sessionStorage.getItem("lang") === "cn" ||
          sessionStorage.getItem("lang") === null
            ? 1
            : 2,
        tts_mode: this.createId == 3 ? 2 : 0,
      }).then((res) => {
        if (res) {
          this.languageoptions = res.list;
        }
      });
    },
    // 点击上传文件（srt） 清空已上传文件
    srtupload(refs, item, data = 1) {
      let uploadFilesArr = {};
      if (item) {
        // if (data == 1) {
        //   this.currentItem = item;
        //   this.srtmaxtime = 0;
        //   item.srttime = 0;
        // } else {
        this.currentItem = item;
        this.srtmaxtime = 0;
        item.srttime = 0;
        // }
        if (data == 2) {
          uploadFilesArr = this.$refs[refs][0].uploadFiles; //上传字幕文件
          // item.srtinput = "";
          // item.srturl = "";
        }
        if (item.id) {
          uploadFilesArr = this.$refs[refs][0].uploadFiles; //上传字幕文件
        }
      } else {
        uploadFilesArr = this.$refs[refs].uploadFiles; //上传字幕文件
      }

      if (uploadFilesArr.length == 0) {
        return;
      } else {
        if (item) {
          if (item.id) {
            this.$refs[refs][0].uploadFiles = [];
          }
          if (data == 2) {
            this.$refs[refs][0].uploadFiles = []; //上传字幕文件
            // item.srtinput = "";
            // item.srturl = "";
          }
        } else {
          this.$refs[refs].uploadFiles = [];
        }
      }
    },
    /**
     *
     * @param {*判断是否是 上传的对应后缀文件} file
     */
    srtbeforeUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension1 = testmsg.toLowerCase() === "srt";

      if (!extension1) {
        this.$messages(
          "warning",
          this.$t("PersonalEndLanguage.warningmessage21")
        );
        return false;
      } else {
        return true;
      }
    },
    /**
     *
     * @param {*上传成功code} response
     * @param {*上传成功文件} file
     */
    srtuploadSuccess(response, file) {
      // console.log(response);
      if (response.result == "SUCCESS") {
        let obj = this.form.fileList.find((i) => i.id === this.currentItem.id);
        obj.srturl = response.path;
        check_srt_max_duration({
          action: "check_srt_max_duration",
          srt_path: response.path,
        }).then((res) => {
          if (res) {
            let obj = this.form.fileList.find(
              (i) => i.id === this.currentItem.id
            );
            obj.videotime = Math.ceil(obj.videotime * 100) / 100;
            if (res.maxtime > Number(obj.videotime)) {
              this.$messages(
                "warning",
                this.$t("PersonalEndLanguage.warningmessage23")
              );
              obj.srtinput = "";
              this.$forceUpdate();
              return;
            }
            obj.srttime = res.maxtime;
            obj.srtinput = file.name;
            this.$forceUpdate();
          } else {
            obj.srtinput = "";
            this.$forceUpdate();
          }
        });
      } else if (response.result === "LOGIN_FAILED") {
        this.$messages("warning", this.$t("CommonName.tokenMessage"));
        this.$router.push("/Login");
        this.$Cookie.remove("token_code");
        sessionStorage.clear();
      } else {
        this.$messages("error", response.msg);
      }
    },
    /**
     *将字幕翻译为点击
     */
    checkboxChange(data) {
      console.log(data);
    },
    /**
     *
     * @param {*键盘 按下事件} e
     */
    windowkeydown(e) {
      const nodeName = e.target.nodeName;
      //空格
      if (e.keyCode == 0 || e.keyCode == 32) {
        if (
          (nodeName && nodeName.toUpperCase() == "INPUT") ||
          (nodeName && nodeName.toUpperCase() == "TEXTAREA")
        ) {
          return;
        }
        e.preventDefault();
        if (this.player) {
          this.playvideo();
        } else {
          this.closevideo();
        }
      }
    },
    // 弹窗 food 按钮方法
    /**
     * 关闭弹窗
     */
    closeDialog() {
      this.dialogVisible = false;
      this.$emit("closeCreateDialog", this.dialogVisible);
    },
    /**
     * 创建任务
     * @constructor
     */
    createClick() {
      // 判断是否选择源语言
      if (!this.form.select1) {
        this.$messages(
          "warning",
          this.$t("PersonalEndLanguage.warningmessage31")
        );
        return;
      }
      // 判断是否选择目标语言
      if (this.form.checkboxChecked) {
        if (!this.form.select2) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage30")
          );
          return;
        }
        if (this.form.select1 === this.form.select2) {
          this.$messages("warning", this.$t("CommonName.warningmessage2"));
          return;
        }
      }
      //选择上传字幕
      if (this.form.radio === "0") {
        //判断是否上传视频文件
        if (this.form.fileList.length === 0) {
          this.$messages("warning", this.$t("CommonName.warningmessage5"));
          return;
        }
        //判断是否上传字幕文件
        const resultObj = this.form.fileList.find(
          (obj) => !obj.srtinput || !obj.srturl
        );
        if (resultObj) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage2")
          );
          return;
        }
      } else {
        //选择已有视频AI任务
        if (this.multipleSelection.length === 0) {
          this.$messages(
            "warning",
            this.$t("ProjectManagement.warningmessage4")
          );
          return;
        }
        this.form.fileList = [];
        this.multipleSelection.forEach((item) => {
          let file = {};
          file.id = Math.random() + ".mp4";
          file.showdropdown = false;
          file.srtinput = "";
          file.videoLanguage = item.slang_id;
          file.srturl = "";
          file.videotime = item.duration_video_time;
          file.srttime = "";
          file.totalChunks = 0;
          file.currentChunk = 0;
          file.percentage = 0;
          file.video_url = item.task_url;
          file.independence_percentage = 0;
          this.form.fileList.push(file);
        });
      }

      //选择硬字幕识别或者涂抹 都要打开识别弹窗
      if (this.form.radio === "1" || this.form.radio1 !== "0") {
        console.log("打开识别弹窗", this.form);
        this.form.fileList.forEach((i, index) => {
          i.videoHeight = 0;
          i.videoWidth = 0;
          i.slidervalue = 0;
          i.startTime = "";
          i.endTime = "";
          i.marks = {};
          i.rect = null;
          i.canvas = null;
          i.position = {};
          i.new_id = index;
        });
        showLoading(this.$t("CommonName.loadingmessage"));
        get_ocr_last_job({ action: "get_ocr_last_job" }).then((res) => {
          if (res) {
            this.initHardSubtitleRecognition();
            this.form.fileList.forEach((i) => {
              i.position = res.position;
            });
            // this.fileList[0].position = res.position;
            this.innerVisible = true;
            this.dialogtitle = this.form.fileList[0].name;
            this.videoUrl = this.form.fileList[0].video_url;
            this.windowdev = window.devicePixelRatio;
            // console.log(this.windowdev);
            this.initVideo(this.form.fileList[0].position);
            hideLoading();
          }
        });
      } else {
        //直接创建任务
        console.log("直接创建任务", this.form);
        return false;
        this.buttonLoading = true;
        this.form.fileList.forEach((i) => {
          this.uploadOSSBatchMethod(1, i);
        });
      }
    },
    /**
     * 初始化 硬字幕识别 弹窗内容
     * @param data
     */
    initHardSubtitleRecognition() {
      this.videoHeight = 0;
      this.videoWidth = 0;
      this.slidervalue = 0;
      this.startTime = "";
      this.endTime = "";
      this.marks = {};
      this.rect = null;
      this.canvas = null;
    },
    /**
     * 时间转换
     * @param time
     * @returns {string}
     */
    formatTime(time) {
      const milliseconds = parseInt(time * 1000);
      const date = new Date(milliseconds);
      const hours = date.getUTCHours().toString().padStart(2, "0");
      const minutes = date.getUTCMinutes().toString().padStart(2, "0");
      const seconds = date.getUTCSeconds().toString().padStart(2, "0");
      return `${hours}:${minutes}:${seconds}`;
    },
    /**
     *
     * @param time
     * @returns {string}
     */
    formattedTime(time) {
      const [hours, minutes, seconds] = time.split(":").map(Number);
      const totalSeconds = hours * 3600 + minutes * 60 + seconds;
      return totalSeconds; // 返回总秒数
    },
    /**
     * 初始化 视频
     * @param data
     */
    initVideo(obj, row = {}) {
      this.$nextTick(() => {
        // this.videoDom = null;
        this.videoDom = document.getElementById("dialogVideo"); // 获取视频dom元素
        var v = this.$refs.videoref;
        let isCurrentMetadataLoaded = false;
        this.videoDom.addEventListener("loadedmetadata", () => {
          if (!isCurrentMetadataLoaded) {
            this.marks = {};
            this.silderMax = this.videoDom.duration;
            this.videoWidth = Number(
              (
                this.videoDom.videoWidth /
                Number((this.videoDom.videoHeight / v.clientHeight).toFixed(2))
              ).toFixed(2)
            );
            this.videoHeight = v.clientHeight;
            console.log(this.videoHeight, "this.videoHeight", this.videoWidth);
            console.log(row.id, 1111);
            if (row.id) {
              if (Object.keys(row.marks).length === 0) {
                this.marks[this.silderMax] = this.setObjData(2, this.silderMax);
              } else {
                this.marks = row.marks;
              }
              if (!row.endTime) {
                this.endTime = this.formatTime(this.silderMax);
              } else {
                this.endTime = row.endTime;
              }
              this.startTime = row.startTime;
            } else {
              this.startTime = "";
              this.endTime = this.formatTime(this.silderMax);
              this.marks[this.silderMax] = this.setObjData(2, this.silderMax);
            }
            this.marks = Object.assign({}, this.marks);
            this.initCanvas(obj); //创建canvas
            isCurrentMetadataLoaded = true;
          }
        });
        this.videoDom.ontimeupdate = () => {
          // 播放走动时，实时获取播放时间
          this.videoStartTime = TimeHandle(this.videoDom.currentTime);
          this.slidervalue = this.videoDom.currentTime;
        };
        // 播放 结束
        this.videoDom.addEventListener("ended", () => {
          this.player = true;
        });
        this.videoDom.addEventListener("error", () => {
          this.$messages("warning", this.$t("CommonName.videoWarning"));
        });
      });
    },
    /**
     *
     * @param {* 回退} data
     */
    rew() {
      this.videoDom.currentTime = this.videoDom.currentTime - 0.1;
      this.videoStartTime = TimeHandle(this.videoDom.currentTime);
    },
    /**
     *
     * @param {*播放} data
     */
    playvideo() {
      this.player = false;
      this.videoDom.play();
    },
    /**
     *
     * @param {* 暂停播放} data
     */
    closevideo() {
      this.player = true;
      this.videoDom.pause();
    },
    /**
     *
     * @param {* 快进} data
     */
    speek() {
      if (this.videoDom.currentTime == this.videoDom.duration) {
        return;
      }
      this.videoDom.currentTime = this.videoDom.currentTime + 0.1;
      this.videoStartTime = TimeHandle(this.videoDom.currentTime);
    },
    /**
     *
     * @param {* 调整播放速度} command
     */
    handleCommand(command) {
      this.ds = command;
      this.videoDom.playbackRate = command;
    },
    initCanvas(obj, row) {
      if (this.canvas) {
        this.canvas.setWidth(1);
        this.canvas.dispose();
        this.canvas = null;
      }
      this.canvas = new fabric.Canvas("canvas");
      this.canvas.setWidth(this.videoDom.clientWidth);
      this.canvas.wrapperEl.style.position = "absolute";
      // if (this.windowdev > 1) {
      //   this.canvas.clear();
      // }
      console.log(obj, "obj");
      this.createRect(obj);
    },
    /**
     *  创建 canvas 矩形
     * @param obj
     */
    createRect(obj) {
      let left = 0;
      let videoDom = {};
      let option = {
        top: 0,
        left: 0,
        width: 0,
        height: 0,
        // fill: "transparent", // 填充色：透明
        fill: "blue", // 填充色：透明
        // fill: "rgba(255,255,255,0.1)",
        stroke: "#46adff", // 边框颜色：黑色
        strokeWidth: 0.5, // 边框宽度
        // strokeUniform: true,
        cornerSize: 5,
        cornerColor: "#fff",
        lockRotation: true,
        strokeDashArray: [5, 7],
      };
      videoDom = this.videoDom;
      if (+obj.position_w + +obj.position_x > videoDom.clientWidth) {
        left = this.canvas.width / 2 - this.canvas.width / 4;
      }
      option.top = Object.values(obj).every((i) => +i == 0)
        ? this.canvas.height - 100
        : Number(obj.position_y);
      option.left = Object.values(obj).every((i) => +i == 0)
        ? this.canvas.width / 2 - this.canvas.width / 4
        : +obj.position_w + +obj.position_x > videoDom.clientWidth
        ? this.canvas.width / 2 - this.canvas.width / 4
        : Number(obj.position_x);
      option.width = Object.values(obj).every((i) => +i == 0)
        ? this.canvas.width / 2
        : +obj.position_w > videoDom.clientWidth
        ? this.canvas.width / 2
        : Number(obj.position_w);
      option.height = Object.values(obj).every((i) => +i == 0)
        ? 40
        : Number(obj.position_h);
      console.log("option", option);
      // 矩形对象
      this.rect = new fabric.Rect(option);
      this.canvas.add(this.rect);
      const mask = new fabric.Rect({
        left: 0,
        top: 0,
        width: this.canvas.width,
        height: this.canvas.height,
        // backgroundVpt: false,
        // height: 360,
        fill: "rgba(0,0,0,0.4)",
        // fill: "rgba(255,255,255,0.5)",
        selectable: false,
      });

      // 将矩形添加到画布上

      this.canvas.add(mask);
      // mask.globalCompositeOperation = "source-over";
      // mask.globalCompositeOperation = "destination-out";
      this.rect.globalCompositeOperation = "destination-out";
      // this.rect.globalCompositeOperation = "source-over";
      this.canvas.bringToFront(this.rect);
      this.canvas.setActiveObject(this.rect);
      this.canvas.selection = true; // 允许框选
      this.canvas.selectionColor = "rgba(100, 100, 255, 0.3)"; // 选框填充色：半透明的蓝色
      this.canvas.selectionBorderColor = "rgba(255, 255, 255, 0.3)"; // 选框边框颜色：半透明灰色
      this.canvas.skipTargetFind = false; // 允许选中
      this.canvas.on("selection:cleared", () => {
        this.canvas.setActiveObject(this.rect);
      });
      // 监听 矩形移动事件
      this.canvas.off("object:moving").on("object:moving", (e) => {
        this.isMoveLeft = true;
        this.isMoveTop = true;
        var target = e.target;
        var canvasWidth = this.canvas.width;
        var canvasHeight = this.canvas.height;
        var left = target.left;
        var top = target.top;
        var maxLeft = canvasWidth - target.width * target.scaleX;
        var maxTop = canvasHeight - target.height * target.scaleY;
        if (left < 0) {
          target.left = 3;
        } else if (left > maxLeft) {
          target.left = maxLeft - 3;
        }
        if (top < 0) {
          target.top = 3;
        } else if (top > maxTop) {
          target.top = maxTop - 3;
        }
      });
      this.rect.off("scaling").on("scaling", (e) => {
        var rect = e.transform.target;
        // 获取矩形的当前位置和大小
        var left = rect.left;
        var top = rect.top;
        var width = rect.width * rect.scaleX;
        var height = rect.height * rect.scaleY;
        // 获取画布的尺寸
        var canvasWidth = this.canvas.width;
        var canvasHeight = this.canvas.height;

        // 计算矩形边界
        var right = left + width;
        var bottom = top + height;
        // 检查是否超出画布边界，如果是，则限制大小

        if (height < 10) {
          rect.scaleY = 10 / rect.height;
        }
        if (width < 10) {
          rect.scaleX = 10 / rect.width;
        }
        if (left > 0 && right < canvasWidth) {
          this.isMoveLeft = true;
        }
        if (top > 0 && bottom < canvasHeight) {
          this.isMoveTop = true;
        }
        if (left < 0) {
          rect.left = 3;
          if (this.isMoveLeft) {
            rect.scaleX = (width - 3 + left) / rect.width;
            this.maxScaleX = rect.scaleX;
            this.isMoveLeft = false;
          } else {
            // console.log(this.maxScaleX, "maxScaleX");
            rect.scaleX = this.maxScaleX;
          }
        }

        if (right > canvasWidth) {
          rect.scaleX = (canvasWidth - 5 - left) / rect.width;
        }
        if (top < 0) {
          rect.top = 3;
          if (this.isMoveTop) {
            rect.scaleY = (height - 3 + top) / rect.height;
            this.maxScaleY = rect.scaleY;
            this.isMoveTop = false;
          } else {
            rect.scaleY = this.maxScaleY;
          }
        }
        if (bottom > canvasHeight) {
          rect.scaleY = (canvasHeight - 3 - top) / rect.height;
        }
      });
    },
    //   其他 方法
    /**
     * 溢出隐藏 划上显示
     * @param i
     */
    onShowNameTipsMouseenter(i) {
      this.$forceUpdate();
      onShowNameTipsMouseenter(i);
    },
    /**
     * 任务流程 切换
     * @param val
     */
    radioInput(val) {
      // if (val === "1") {
      // }
    },
    /**
     * 设置批量上传 数据 上传之前 判断是否满足条件
     * @param file
     * @param arr
     * @returns {*|boolean}
     */
    setUploadListData_change(file, arr) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isLt2M =
        file.size / 1024 / 1024 / 1024 <
        parseFloat(this.info.video_dubbing_filesize_limit);
      const extension = testmsg.toLowerCase() === "mp4";
      if (!extension) {
        if (this.formatmessage) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage7")
          );
          this.formatmessage = false;
        }
        this.$refs.upload.clearFiles();
        return false;
      } else if (!isLt2M) {
        if (this.sizemessage) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage18") +
              this.info.video_dubbing_filesize_limit +
              "GB"
          );
          this.sizemessage = false;
        }
        this.$refs.upload.clearFiles();
        return false;
      } else if (file.name.length > 80) {
        if (this.langMessage) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage36")
          );
          this.langMessage = false;
        }
        this.$refs.upload.clearFiles();
        return false;
      } else {
        let duration_limit = this.info.video_dubbing_duration_limit;
        this.getFileTime(file).then((res) => {
          if (res / 60 > parseFloat(duration_limit)) {
            this.$messages(
              "warning",
              this.$t("PersonalEndLanguage.warningmessage19") +
                duration_limit +
                this.$t("PersonalEndLanguage.minute")
            );
            this.$refs.upload.clearFiles();
            return false;
          } else {
            // fileTotalDuration
            get_user_quota_info({
              action: "get_user_quota_info",
            })
              .then((res1) => {
                if (res1) {
                  this.fileSizeMB += Number(
                    (file.size / (1024 * 1024)).toFixed(2)
                  ); // 转换为MB并保留两位小数
                  if (
                    +this.fileSizeMB >
                    res1.info.space.max - res1.info.space.current
                  ) {
                    if (this.filesizemessage) {
                      this.$messages(
                        "warning",
                        this.$t("PersonalEndLanguage.warningmessage10")
                      );
                      this.fileSizeMB =
                        this.fileSizeMB -
                        Number((file.size / (1024 * 1024)).toFixed(2));
                      // console.log(this.fileSizeMB, "222222");
                      this.filesizemessage = false;
                    }
                    this.$refs.upload.clearFiles();
                    return;
                  }

                  this.fileTotalDuration += +res;
                  console.log("fileTotalDuration", this.fileTotalDuration);
                  //在视频配音中 上传视频时长小于等于5分钟 则开放硬字幕涂抹
                  if (res / 60 <= +this.info.video_daub_minute_limit) {
                    this.isRadioDisabled = false;
                  } else {
                    this.isRadioDisabled = true;
                    this.form.radio1 = "0";
                  }
                  file.id = Math.random() + ".mp4";
                  file.showdropdown = false;
                  file.srtinput = "";
                  file.videoLanguage = "";
                  file.srturl = "";
                  file.videotime = res;
                  file.srttime = "";
                  file.totalChunks = 0;
                  file.currentChunk = 0;
                  file.percentage = 0;
                  file.independence_percentage = 0;
                  file.video_url = URL.createObjectURL(file.raw);
                  arr.push(file);
                  arr.sort((a, b) => {
                    let nameA = a.name.toLowerCase();
                    let nameB = b.name.toLowerCase();
                    return nameA.localeCompare(nameB);
                  });
                  console.log(file);
                }
              })
              .catch((err) => {});
          }
        });
      }
      return arr;
    },
    /**
     * 硬字幕识别 上传之前 验证是否满足上传条件  change 事件
     * @param file
     * @param fileList
     */
    hardSubtitleRecognition_change_third(file, fileList) {
      if (!fileList || !file) return;
      this.setUploadListData_change(file, this.form.fileList);
    },
    /**
     * 上传进度
     * @param file
     */
    uploadProgress(file) {
      console.log(file, "uploadProgress");
    },
    /**
     *  上传成功
     * @param response
     * @param file
     */
    uploadSuccess(response, file) {
      if (response.result == "SUCCESS") {
        console.log(response, "response");
      }
    },
    /**
     * 硬字幕识别 上传超出 数量限制 事件
     * @param filelist
     */
    hardSubtitleRecognition_Exceed_third(filelist) {
      filelist = this.form.fileList;
      this.$messages(
        "warning",
        this.$t("PersonalEndLanguage.warningmessage11")
      );
    },
    /**
     *  视频配音 多文件上传时 限制
     * @constructor
     */
    AudiotranscriptionClick_third() {
      this.formatmessage = true;
      this.sizemessage = true;
      this.langMessage = true;
    },
    /**
     * 硬字幕识别 删除 上传列表中的某条
     * @param e
     * @param item
     */
    hardSubtitleRecognitionList_Delete_Click(e, item) {
      this.form.fileList = this.setUploadListData_delete(
        this.form.fileList,
        item
      );
      e.stopPropagation();
    },
    /**
     * 设置批量上传 数据 删除
     * @param arr
     * @returns {*}
     */
    setUploadListData_delete(arr, item) {
      this.fileSizeMB = 0;
      this.fileTotalDuration = 0;
      arr.map((i, index) => {
        if (i.id == item.id) {
          arr.splice(index, 1);
        }
      });
      this.isRadioDisabled = false;
      arr.forEach((i) => {
        this.fileSizeMB += Number((i.size / (1024 * 1024)).toFixed(2));
        this.fileTotalDuration += Number(i.videotime);
        console.log("少了一个", i);
        if (+i.videotime / 60 > +this.info.video_daub_minute_limit) {
          this.isRadioDisabled = true;
          this.form.radio1 = "0";
        }
      });
      return arr;
    },
    /**
     * 滑块滑动  修改视频时间
     * @param slider
     */
    sliderInput(slider) {
      this.slidervalue = slider;
      this.videoDom.currentTime = slider;
    },
    /**
     * 格式化显示 时间
     * @param val
     */
    formatTooltip(val) {
      return getTimeString(val, 1, 2).replace(".", ",");
    },
    /**
     * 清除 输入框里得内容
     * @param data
     */
    clearInput(data) {
      for (let Key in this.marks) {
        if (data == 0) {
          if (this.marks[Key].id == 1) {
            delete this.marks[Key];
          }
          this.old_time = "";
        } else {
          if (this.marks[Key].id == 2) {
            delete this.marks[Key];
          }
          this.old_time1 = "";
        }
      }
      this.marks = Object.assign({}, this.marks);
    },
    /**
     *  片头片尾 时间点 失去焦点
     * @param data
     */
    inputBlur(data) {
      let timeRegExp = /^([01][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/;
      if (data == 0) {
        if (!this.startTime) {
          return;
        }
        //判断时间格式是否正确的正则表达式
        if (!timeRegExp.test(this.startTime)) {
          this.$messages(
            "warning",
            this.$t("OverwriteOperation.warningmessage1")
          );
          return;
        }

        let time = this.formattedTime(this.startTime);
        if (this.endTime) {
          let time1 = this.formattedTime(this.endTime);
          if (time >= time1) {
            this.old_time = this.startTime;
            this.startTime = "";
            for (const marks in this.marks) {
              if (this.marks[marks].id == 1) {
                delete this.marks[marks];
              }
            }
            this.marks = Object.assign({}, this.marks);
            return;
          }
        }
        if (time > this.silderMax) {
          this.old_time = this.startTime;
          this.startTime = "";

          for (const marks in this.marks) {
            if (this.marks[marks].id == 1) {
              delete this.marks[marks];
            }
          }
          this.marks = Object.assign({}, this.marks);
          this.$messages("warning", this.$t("PersonalEndLanguage.timeMessage"));
          return;
        }
        this.old_time = this.startTime;
        this.timeIconClick(data, time);
      } else {
        if (!this.endTime) {
          return;
        }
        if (!timeRegExp.test(this.endTime)) {
          this.$messages(
            "warning",
            this.$t("OverwriteOperation.warningmessage1")
          );
          return;
        }
        let time = this.formattedTime(this.endTime);
        if (this.startTime) {
          let time1 = this.formattedTime(this.startTime);
          if (time <= time1) {
            this.old_time1 = this.endTime;
            this.endTime = "";
            for (const marks in this.marks) {
              if (this.marks[marks].id == 2) {
                delete this.marks[marks];
              }
            }
            this.marks = Object.assign({}, this.marks);
            return;
          }
        }
        if (time > this.silderMax) {
          this.old_time1 = this.endTime;
          this.endTime = "";
          for (const marks in this.marks) {
            if (this.marks[marks].id == 2) {
              delete this.marks[marks];
            }
          }
          this.marks = Object.assign({}, this.marks);
          this.$messages("warning", this.$t("PersonalEndLanguage.timeMessage"));
          return;
        }
        this.old_time1 = this.endTime;
        this.timeIconClick(data, time);
      }
    },
    /**
     * 点击 片头片尾 按钮  获取当前时间 赋值片头片尾时间
     * @param data 0：片头 1：片尾
     * @constructor
     */
    timeIconClick(data, time) {
      if (data == 0) {
        if (Object.keys(this.marks).length !== 0) {
          let obj = {};
          for (let Key in this.marks) {
            if (this.marks[Key].id === 2) {
              obj = this.marks[Key];
            }
          }
          if (time >= obj.time) {
            return;
          }

          for (let Key in this.marks) {
            if (this.marks[Key].id === 1) {
              delete this.marks[Key];
              this.marks[time] = this.setObjData(1, time);
            } else {
              if (this.marks[Key].id === 2) {
                if (time >= this.marks[Key].time) {
                  return;
                }
              }
              this.marks[time] = this.setObjData(1, time);
            }
          }
        } else {
          this.marks[time] = this.setObjData(1, time);
        }
        this.startTime = this.formatTime(time);
        this.marks = Object.assign({}, this.marks);
      } else {
        if (Object.keys(this.marks).length !== 0) {
          let obj = {};
          for (let Key in this.marks) {
            if (this.marks[Key].id === 1) {
              obj = this.marks[Key];
            }
          }
          if (time <= obj.time) {
            return;
          }
          for (let Key in this.marks) {
            if (this.marks[Key].id === 2) {
              delete this.marks[Key];
              this.marks[time] = this.setObjData(2, time);
            } else {
              if (this.marks[Key].id === 1) {
                if (time <= this.marks[Key].time) {
                  return;
                }
              }
              this.marks[time] = this.setObjData(2, time);
            }
          }
        } else {
          this.marks[time] = this.setObjData(2, time);
        }
        this.endTime = this.formatTime(time);
        this.marks = Object.assign({}, this.marks);
      }
    },
    /**
     * 往对象中添加 属性值
     * @param id
     */
    setObjData(id, time) {
      return {
        id: id,
        time: time,
        label: this.$createElement("i", {
          class: {
            "el-icon-location": true,
          },
          style: {
            transform: "rotate(180deg)",
            color: id === 1 ? "#98FF6C" : "#FFB655",
          },
        }),
      };
    },
    /**
     * 内部弹窗 关闭
     * @param data
     * @constructor
     */
    closeinnerVisible(data) {
      this.closevideo();
      // 上一步
      if (data === 1) {
        this.row_id = 0;
        this.innerVisible = false;
        this.buttonLoading = false;
        this.closeCanvas();
      } else {
        if (this.form.fileList.length === 1) {
          this.row_id = 0;
          this.innerVisible = false;
          this.buttonLoading = false;
          this.closeCanvas();
        } else {
          if (this.row_id - 1 < 0) {
            this.row_id = 0;
            this.innerVisible = false;
            this.buttonLoading = false;
            this.closeCanvas();
            return;
          }
          // 上一个
          // 当最后一个为 列表的最后一个时 点击上一步 赋值开始结束时间标点
          // if (
          //   this.form.fileList[this.row_id].new_id ===
          //   this.form.fileList[
          //     this.form.fileList.length - 1
          //   ].new_id
          // ) {
          this.form.fileList[this.row_id].marks = this.marks;
          this.form.fileList[this.row_id].startTime = this.startTime;
          this.form.fileList[this.row_id].endTime = this.endTime;
          this.form.fileList[this.row_id].rect = this.rect;
          this.form.fileList[this.row_id].position = this.setPositions(
            this.form.fileList[this.row_id]
          );
          // }
          this.canvas.setWidth(1);
          this.canvas.dispose();
          let video = null;
          this.videoUrl = "";
          this.initHardSubtitleRecognition();
          if (this.form.fileList[this.row_id - 1]) {
            this.dialogtitle = this.form.fileList[this.row_id - 1].name;

            this.videoUrl = this.form.fileList[this.row_id - 1].video_url;
            this.videoDom.removeEventListener("loadedmetadata", () => {});
            // video = document.createElement("video");
            // video.src = this.videoUrl;
            // document.body.appendChild(video);
            // video.onloadedmetadata = () => {
            //   this.videoHeight = video.videoHeight;
            //   this.videoWidth = video.videoWidth;
            // };
            // document.body.removeChild(video);
            this.windowdev = window.devicePixelRatio;
            let position = this.form.fileList[this.row_id - 1].position;

            // 创建 video 与 canvas
            this.initVideo(position, this.form.fileList[this.row_id - 1]);
            this.row_id--;
          }
        }
      }
    },
    /**
     * 设置 矩形
     * @param obj
     */
    setPositions(obj) {
      let position = {};
      position.position_h = obj.rect.height * obj.rect.scaleY;
      position.position_w = obj.rect.width * obj.rect.scaleX;
      position.position_x = obj.rect.left;
      position.position_y = obj.rect.top;
      return position;
    },
    /**
     * 内部中得内部 弹窗 确定 按钮
     * @constructor
     */
    SureClick() {
      this.closevideo();
      //选择涂抹再验证时间
      if (this.form.radio == "1") {
        let timeRegExp = /^([01][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/;
        if (this.startTime && !timeRegExp.test(this.startTime)) {
          return;
        }
        if (this.endTime && !timeRegExp.test(this.endTime)) {
          return;
        }
        let startTime = this.formattedTime(this.old_time);
        let endTime = this.formattedTime(this.old_time1);
        if (startTime && endTime) {
          if (startTime >= endTime) {
            this.$messages(
              "warning",
              this.$t("PersonalEndLanguage.timeMessage1")
            );
            return;
          }
        }
        // console.log(startTime, this.silderMax);
        if (startTime > this.silderMax) {
          this.old_time = "";
          return;
        }

        if (endTime > this.silderMax) {
          this.old_time1 = "";
          return;
        }
      }
      let obj = this.form.fileList[this.row_id];
      obj.endTime = this.endTime;
      obj.startTime = this.startTime;
      obj.marks = this.marks;
      obj.silderMax = this.silderMax;
      obj.rect = this.rect;
      obj.videoHeight = this.videoHeight;
      obj.videoWidth = this.videoWidth;
      obj.position = this.setPositions(obj);
      if (this.row_id + 1 === this.form.fileList.length) {
        // 创建任务
        this.buttonLoading = true;
        this.form.fileList.forEach((i) => {
          this.uploadOSSBatchMethod(1, i, 0);
        });
        return;
      }
      // 下一个
      this.canvas.setWidth(1);
      this.canvas.dispose();
      let video = null;
      this.videoUrl = "";
      this.initHardSubtitleRecognition();
      if (this.form.fileList[this.row_id + 1]) {
        this.dialogtitle = this.form.fileList[this.row_id + 1].name;
        this.videoUrl = this.form.fileList[this.row_id + 1].video_url;
        this.videoDom.removeEventListener("loadedmetadata", () => {});
        // video = document.createElement("video");
        // video.src = this.videoUrl;
        // document.body.appendChild(video);
        // video.onloadedmetadata = () => {
        //   this.videoHeight = video.videoHeight;
        //   this.videoWidth = video.videoWidth;
        // };
        // document.body.removeChild(video);
        this.windowdev = window.devicePixelRatio;
        let position = {};
        if (!this.form.fileList[this.row_id + 1].rect) {
          position = this.setPositions(this.form.fileList[this.row_id]);
        } else {
          position = this.form.fileList[this.row_id + 1].position;
        }
        this.initVideo(position, this.form.fileList[this.row_id + 1]);
        this.row_id++;
      }
    },
    /**
     * 直接创建 跳过每个视频都重新选框，全部使用当前框属性来创建
     */
    immediateClick() {
      this.closevideo();
      //选择涂抹再验证时间
      if (this.form.radio == "1") {
        let timeRegExp = /^([01][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/;
        if (this.startTime && !timeRegExp.test(this.startTime)) {
          return;
        }
        if (this.endTime && !timeRegExp.test(this.endTime)) {
          return;
        }
        let startTime = this.formattedTime(this.old_time);
        let endTime = this.formattedTime(this.old_time1);
        if (startTime && endTime) {
          if (startTime >= endTime) {
            this.$messages(
              "warning",
              this.$t("PersonalEndLanguage.timeMessage1")
            );
            return;
          }
        }
        // console.log(startTime, this.silderMax);
        if (startTime > this.silderMax) {
          this.old_time = "";
          return;
        }

        if (endTime > this.silderMax) {
          this.old_time1 = "";
          return;
        }
      }
      let obj = {
        rect: this.rect,
      };
      let title = "";
      title = this.$t("ProjectManagement.hardSubtitleTip");
      this.$confirm(title, this.$t("VideoDubbing.comfirmTips1"), {
        confirmButtonText: this.$t("VideoDubbing.comfirmTips2"),
        cancelButtonText: this.$t("VideoDubbing.comfirmTips3"),
        cancelButtonClass: "el-button--info",
        iconClass: "none",
        closeOnClickModal: false,
        distinguishCancelAndClose: false,
        closeOnPressEscape: false,
      })
        .then(() => {
          this.form.fileList.forEach((i, index) => {
            this.form.fileList[index].endTime = this.endTime;
            this.form.fileList[index].startTime = this.startTime;
            this.form.fileList[index].marks = this.marks;
            this.form.fileList[index].silderMax = this.silderMax;
            this.form.fileList[index].rect = this.rect;
            this.form.fileList[index].videoHeight = this.videoHeight;
            this.form.fileList[index].videoWidth = this.videoWidth;
            this.form.fileList[index].endTime = this.endTime;
            this.form.fileList[index].position = this.setPositions(obj);
            this.uploadOSSBatchMethod(1, this.form.fileList[index], 1);
          });
          // console.log(this.row_id, this.form.fileList);
        })
        .catch((action) => {
          if (action === "cancel") {
            console.log("cancel");
          }
        });
    },
    /**
     * 关闭遮罩
     * @param
     */
    closeCanvas() {
      this.canvas.setWidth(1);
      this.canvas.dispose();
      this.canvas = null;
      this.rect = null;
    },
    /**
     * OSS自定义批量上传
     * @param quick_mode 1:快捷模式；0：普通模式
     */
    uploadOSSBatchMethod(id, obj, quick_mode = 0) {
      console.log("obj", obj);
      // return;
      //是否选择涂抹
      let isdaub = 0;
      if (this.form.radio1 !== "0") {
        isdaub = 1;
      }
      get_oss_policy({
        action: "get_policy_upload_info",
        daub: isdaub, //1是选择涂抹，其他未选择
      }).then((resu) => {
        if (resu) {
          // console.log(resu);
          // return false;
          let that = this;
          let file = obj.raw;
          let filename = getFileNameUUID() + getFileName(file.name);
          let data = resu.info;
          // let resu = {
          //   policy:
          //     "eyJleHBpcmF0aW9uIjoiMjAyNC0xMC0xNFQwNzoxMTozMC4wMDBaIiwiY29uZGl0aW9ucyI6W1siY29udGVudC1sZW5ndGgtcmFuZ2UiLDAsMzIyMTIyNTQ3Ml0sWyJzdGFydHMtd2l0aCIsIiRrZXkiLCJwcml2YXRlXzBcLyJdXX0=",
          //   ossAccessKeyId: "LTAI4FdmBa8evmQuXNqkoijs",
          //   signature: "mmREYdkGs/fKIEL74CoY+PB+u6E=",
          //   dir: "private_0/",
          //   host: "http://wiimedia-zjk.oss-cn-zhangjiakou.aliyuncs.com/",
          // };
          const formData = new FormData();
          formData.append("name", filename);
          formData.append("policy", data.policy);
          formData.append("OSSAccessKeyId", data.ossAccessKeyId);
          formData.append("success_action_status", "200");
          formData.append("signature", data.signature);
          formData.append("key", data.dir + filename);
          // file必须为最后一个表单域，除file以外的其他表单域无顺序要求。
          formData.append("file", file);
          // 使用XMLHttpRequest手动上传分片
          const xhr = new XMLHttpRequest();
          xhr.open("POST", data.host, true);
          xhr.withCredentials = true;
          xhr.upload.onprogress = (event) => {
            if (event.lengthComputable) {
              // console.log("event.loaded", event.loaded);
              // console.log("event.total", event.total);
              obj.independence_percentage = Math.floor(
                (event.loaded / event.total) * 100
              );
              obj.percentage = event.loaded;
              this.loading = true;
            }
          };

          xhr.onerror = (error) => {
            this.$messages(
              "error",
              this.$t("PersonalEndLanguage.errormessage")
            );
            obj.currentChunk = 0;
            obj.percentage = 0;
            this.percentage = 0;
            this.loading = false;
            this.buttonLoading = false;
            this.dialogVisible = false;
            this.innerVisible = false;
          };
          xhr.onreadystatechange = (event) => {
            //status状态为200 表示请求成功，readystate为4表示对象传递完毕或者准备就绪
            if (xhr.readyState == 4 && xhr.status == 200) {
              // that.$messages("success", that.$t("CommonName.successmessage5"));
              // console.log(res.savename, "文件上传完成！");
              console.log(data.host + data.dir + filename);
              let savepath = data.host + data.dir + filename;
              if (id === 1) {
                //配音
                const isAll = this.hardSubtitleRecognition_fileList.every(
                  (obj) => obj.independence_percentage == 100
                );
                if (isAll) {
                  this.loading = false;
                  showLoading(this.$t("PersonalEndLanguage.loadingMessage"));
                }
              } else if (id === 2) {
                //硬字幕
                const isAll1 = this.hardSubtitleRecognition_fileList.every(
                  (obj) => obj.independence_percentage == 100
                );
                if (isAll1) {
                  this.loading = false;
                  showLoading(this.$t("PersonalEndLanguage.loadingMessage"));
                }
              } else if (id === 3) {
                //视频AI
                const isAll1 = this.hardSubtitleRecognition_fileList.every(
                  (obj) => obj.independence_percentage == 100
                );
                if (isAll1) {
                  this.loading = false;
                  showLoading(this.$t("PersonalEndLanguage.loadingMessage"));
                }
              } else if (id === 4) {
                const isAll = this.fileList.every(
                  (obj) => obj.independence_percentage == 100
                );
                if (isAll) {
                  this.loading = false;
                  showLoading(this.$t("PersonalEndLanguage.loadingMessage"));
                }
              }
              if (savepath !== "") {
                //  if (id == 3) {
                //   let action = "create_asr_job_oss";
                //   //mode:1表示自动识别，2表示上传srt，3硬字幕识别
                //   let mode =
                //     this.form1.radio2 === "2"
                //       ? 3
                //       : this.form1.activeName == "1" && this.form1.srtinput
                //       ? 2
                //       : 1;
                //   if (this.form1.checked) {
                //     //视频AI翻译
                //     action = "create_trans_job_oss";
                //     mode = this.form1.radio2 === "2" ? 3 : 1;
                //   }
                //   //视频AI
                //   create_trans_job({
                //     action: action,
                //     mode: mode,
                //     slang: this.form1.videoLanguage,
                //     tlang: this.form1.targetlanguage,
                //     srt_path: "",
                //     srt_name: "",
                //     mt: "",
                //     savename: filename,
                //     savepath: savepath,
                //     duration: obj.videotime,
                //     size: obj.size,
                //     media_file_name: obj.name,
                //     position_original_x:
                //       this.form1.radio2 === "2" || this.form1.checkbox1
                //         ? obj.rect.left
                //         : "",
                //     position_original_y:
                //       this.form1.radio2 === "2" || this.form1.checkbox1
                //         ? obj.rect.top
                //         : "",
                //     position_original_w:
                //       this.form1.radio2 === "2" || this.form1.checkbox1
                //         ? obj.rect.width * obj.rect.scaleX
                //         : "",
                //     position_original_h:
                //       this.form1.radio2 === "2" || this.form1.checkbox1
                //         ? obj.rect.height * obj.rect.scaleY
                //         : "",
                //     original_width: obj.videoWidth,
                //     original_height: obj.videoHeight,
                //     time_start:
                //       this.form1.radio2 === "2" || this.form1.checkbox1
                //         ? obj.startTime
                //           ? obj.startTime
                //           : ""
                //         : "",
                //     time_end:
                //       this.form1.radio2 === "2" || this.form1.checkbox1
                //         ? obj.endTime
                //           ? obj.endTime
                //           : this.formatTime(obj.silderMax)
                //         : "",
                //     subtitle_smearing: this.form1.checkbox1 ? 1 : 0, //字幕涂抹
                //     subtitle_suppression: this.form1.checkbox2 ? 1 : 0, //字幕压制
                //     subtitle_smearing_mode: this.form1.checkbox3 ? 1 : 0, //字幕涂抹
                //     folder_type: this.isAddFolder ? 1 : 0, //是否新建文件夹
                //     folder_id: this.AddFolderID, //文件夹ID
                //     folder_name: this.AddFolderName, //新建文件夹名称
                //     quick_mode,
                //   }).then((res) => {
                //     if (!res) {
                //       this.videoData.file.status = "ready";
                //       this.buttonLoading = false;
                //       return;
                //     }
                //     if (res.result == "SUCCESS") {
                //       const isAll = this.hardSubtitleRecognition_fileList.every(
                //         (obj) => obj.independence_percentage == 100
                //       );
                //       if (isAll) {
                //         this.get_last_job().then(() => {
                //           this.$messages(
                //             "success",
                //             this.$t("PersonalEndLanguage.successMessage")
                //           );
                //           hideLoading();
                //           this.dialogVisible = false;
                //           this.buttonLoading = false;
                //           this.innerVisible = false;
                //           this.initData();
                //         });
                //       }
                //     } else if (res.result == "LOGIN_FAILED") {
                //       this.otherResult();
                //     } else if (
                //       res.result == "QUOTE_FAILED" ||
                //       res.result == "SPACE_FAILED"
                //     ) {
                //       this.otherResult2(res);
                //     } else {
                //       this.otherResult1(res);
                //     }
                //   });
                // } else if (id == 1) {
                //   //配音
                //   //硬字幕识别 或 字幕涂抹
                //   let x = "";
                //   let y = "";
                //   let width = "";
                //   let height = "";
                //   if (this.form9.radio2 === "2" || this.form9.checkbox1) {
                //     let num = this.videoHeight / 360;
                //     x = Math.ceil(this.rect.left * num);
                //     y = Math.ceil(this.rect.top * num);
                //     width = Math.ceil(this.rect.width * this.rect.scaleX * num);
                //     height = Math.ceil(
                //       this.rect.height * this.rect.scaleY * num
                //     );
                //   }
                //   create_video_dubbing_job_new({
                //     category:
                //       this.project_id === 2 ? 0 : this.project_id === 9 ? 1 : 2,
                //     slang: this.form9.sourceLanguage,
                //     tlang: this.form9.videoLanguage
                //       ? this.form9.videoLanguage
                //       : "",
                //     stt_mode: this.form9.radio2 === "1" ? "2" : "3",
                //     srt_path: "",
                //     srt_name: "",
                //     savename: filename,
                //     savepath: savepath,
                //     duration: obj.videotime,
                //     size: obj.size,
                //     media_file_name: obj.name,
                //     action: "create_video_dubbing_job_new_oss",
                //     video_dubbing_mode: "",
                //     speaker_number: "0",
                //     position_x: x,
                //     position_y: y,
                //     position_w: width,
                //     position_h: height,
                //     position_original_x:
                //       this.form9.radio2 === "2" ? obj.rect.left : "",
                //     position_original_y:
                //       this.form9.radio2 === "2" ? obj.rect.top : "",
                //     position_original_w:
                //       this.form9.radio2 === "2"
                //         ? obj.rect.width * obj.rect.scaleX
                //         : "",
                //     position_original_h:
                //       this.form9.radio2 === "2"
                //         ? obj.rect.height * obj.rect.scaleY
                //         : "",
                //     original_width: obj.videoWidth,
                //     original_height: obj.videoHeight,
                //     time_start:
                //       this.form9.radio2 === "2"
                //         ? obj.startTime
                //           ? obj.startTime
                //           : ""
                //         : "",
                //     time_end:
                //       this.form9.radio2 === "2"
                //         ? obj.endTime
                //           ? obj.endTime
                //           : this.formatTime(obj.silderMax)
                //         : "",
                //     subtitle_smearing: this.form9.checkbox1 ? 1 : 0, //字幕涂抹
                //     subtitle_suppression: this.form9.checkbox2 ? 1 : 0, //字幕压制
                //     subtitle_smearing_mode: this.form9.checkbox3 ? 1 : 0, //字幕涂抹
                //     dubbing_vocals_name: "", //人声音频名称
                //     dubbing_vocals_path: "", //人声音频地址
                //     dubbing_novocals_name: "", //国际音轨音频名称
                //     dubbing_novocals_path: "", ///国际音轨音频地址
                //     folder_type: this.isAddFolder ? 1 : 0, //是否新建文件夹
                //     folder_id: this.AddFolderID, //文件夹ID
                //     folder_name: this.AddFolderName, //新建文件夹名称
                //     quick_mode,
                //   })
                //     .then((res) => {
                //       if (!res) {
                //         this.videoData.file.status = "ready";
                //         this.buttonLoading = false;
                //         return;
                //       }
                //       if (res.result == "SUCCESS") {
                //         const isAll =
                //           this.hardSubtitleRecognition_fileList.every(
                //             (obj) => obj.independence_percentage === 100
                //           );
                //         if (isAll) {
                //           this.get_last_job().then(() => {
                //             if (this.uploadSuccessflag) {
                //               this.$messages(
                //                 "success",
                //                 this.$t("PersonalEndLanguage.successMessage")
                //               );
                //               this.uploadSuccessflag = false;
                //             }
                //             hideLoading();
                //             this.dialogVisible = false;
                //             this.buttonLoading = false;
                //             this.innerVisible = false;
                //             this.initData();
                //           });
                //         }
                //       } else if (res.result == "LOGIN_FAILED") {
                //         this.percentage = 0;
                //         this.loading = false;
                //         hideLoading();
                //         this.$messages(
                //           "warning",
                //           this.$t("CommonName.tokenMessage")
                //         );
                //         this.$router.push("/Login");
                //         this.$Cookie.remove("token_code");
                //         sessionStorage.clear();
                //       } else if (res.result == "SPACE_FAILED") {
                //         this.percentage = 0;
                //         this.loading = false;
                //         hideLoading();
                //         this.quotetype = res.type;
                //         this.buttonLoading = false;
                //       } else {
                //         this.percentage = 0;
                //         this.loading = false;
                //         // console.log(22222222);
                //         // this.quotetype = response.type;
                //         hideLoading();
                //         this.$messages("error", res.msg);
                //         this.buttonLoading = false;
                //         this.dialogVisible = false;
                //         this.innerVisible = false;
                //       }
                //     })
                //     .catch((err) => {
                //       console.log(err);
                //     });
                // } else if (id == 4) {
                //   //配音
                //   create_video_dubbing_job({
                //     action: "create_video_dubbing_job_oss",
                //     slang: obj.videoLanguage,
                //     srt_path: obj.srturl,
                //     srt_name: obj.srtinput,
                //     savename: filename,
                //     savepath: savepath,
                //     duration: obj.videotime,
                //     size: obj.size,
                //     mode: 1,
                //     media_file_name: obj.name,
                //     category:
                //       this.project_id === 2 ? 0 : this.project_id === 9 ? 1 : 2,
                //     folder_type: this.isAddFolder ? 1 : 0, //是否新建文件夹
                //     folder_id: this.AddFolderID, //文件夹ID
                //     folder_name: this.AddFolderName, //新建文件夹名称
                //   })
                //     .then((res) => {
                //       if (!res) {
                //         this.buttonLoading = false;
                //         return;
                //       }
                //       if (res.result == "SUCCESS") {
                //         const isAll = this.fileList.every(
                //           (obj) => obj.independence_percentage === 100
                //         );
                //         if (isAll) {
                //           this.get_last_job().then(() => {
                //             if (this.uploadSuccessflag) {
                //               this.$messages(
                //                 "success",
                //                 this.$t("PersonalEndLanguage.successMessage")
                //               );
                //               this.uploadSuccessflag = false;
                //             }
                //             hideLoading();
                //             this.dialogVisible = false;
                //             this.buttonLoading = false;
                //             this.initData();
                //           });
                //         }
                //       } else if (res.result == "LOGIN_FAILED") {
                //         this.percentage = 0;
                //         this.loading = false;
                //         hideLoading();
                //         this.$messages(
                //           "warning",
                //           this.$t("CommonName.tokenMessage")
                //         );
                //         this.$router.push("/Login");
                //         this.$Cookie.remove("token_code");
                //         sessionStorage.clear();
                //       } else if (res.result == "SPACE_FAILED") {
                //         this.percentage = 0;
                //         this.loading = false;
                //         hideLoading();
                //         this.quotetype = res.type;
                //         this.buttonLoading = false;
                //       } else {
                //         this.percentage = 0;
                //         this.loading = false;
                //         // console.log(22222222);
                //         // this.quotetype = response.type;
                //         hideLoading();
                //         this.$messages("error", res.msg);
                //         this.buttonLoading = false;
                //         this.dialogVisible = false;
                //       }
                //     })
                //     .catch((err) => {
                //       console.log(err);
                //     });
                // }
              }
            }
          };
          xhr.send(formData);
        }
      });
    },
    /**
     * 创建项目 不成功 的其中一个情况
     */
    otherResult() {
      this.percentage = 0;
      this.loading = false;
      hideLoading();
      this.$messages("warning", this.$t("CommonName.tokenMessage"));
      this.$router.push("/Login");
      this.$Cookie.remove("token_code");
      sessionStorage.clear();
    },
    /**
     * 创建项目 不成功 的其中一个情况
     * @param res
     */
    otherResult1(res) {
      this.percentage = 0;
      this.loading = false;
      // this.videoData.file.status = "ready";
      // this.quotetype = response.type;
      hideLoading();
      this.$messages("error", res.msg);
      this.buttonLoading = false;
      this.dialogVisible = false;
      this.innerVisible = false;
    },
    /**
     * 创建项目 不成功 的其中一个情况
     * @param res
     */
    otherResult2(res) {
      // this.videoData.file.status = "ready";
      this.percentage = 0;
      this.loading = false;
      hideLoading();
      this.quotetype = res.type;
      this.buttonLoading = false;
    },
    /**
     *
     * @param {*获取音频时长} file
     */
    async getFileTime(file) {
      // console.log(file);
      let content = file.raw;
      let a = await new Promise((resolve) => {
        var url = URL.createObjectURL(content);
        var audioElement = new Audio(url);
        audioElement.addEventListener("loadedmetadata", () => {
          resolve(audioElement.duration.toFixed(3)); //playTime就是当前视频长度
          // this.playTime = audioElement.duration;
        });
      });
      return a;
    },
  },
};
</script>
<style scoped lang="scss">
@import "./AllDialogradio.scss";
.stepCenter {
  margin: 24px 24px 24px 24px;
  p {
    color: #adb0bc;
    font-size: 14px;
  }
  .el-radio.is-bordered.is-disabled {
    border-color: #5b5d6c;
  }
  ::v-deep .el-radio__input.is-disabled + span.el-radio__label {
    color: #595d69 !important;
  }
  ::v-deep .el-radio__input.is-disabled .el-radio__inner {
    background: none;
    border-color: #595d69;
  }
  ::v-deep .el-checkbox .el-checkbox__label {
    color: #adb0bc;
  }
  ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #409eff;
  }
  ::v-deep .el-input.is-disabled .el-input__inner {
    background-color: #404253 !important;
    border-color: #4a4c5d !important;
  }

  ::v-deep .el-input.is-disabled .el-input__icon {
    color: #4a4c5d !important;
  }
  ::v-deep .el-table-column--selection .cell {
    padding-right: 10px;
  }
  .stepCenterItem {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 32px;
    line-height: 33px;
    .stepCenter_left {
      width: 190px;
    }
    .stepCenter_right {
      width: calc(100% - 190px);
      ::v-deep .el-input__inner {
        width: 650px;
      }
      .srtUnput {
        margin-top: 0px;
        ::v-deep .el-input__inner {
          width: 566px;
        }
      }
    }
  }
  .stepCenterItem + .stepCenterItem {
    margin-top: 16px;
  }
  .content_1 {
    .el-radio-group {
      width: 100%;
    }
    .el-radio.is-bordered {
      width: 33.3%;
    }
  }
  .content_2 {
    .el-radio-group {
      width: 100%;
    }
    .el-radio.is-bordered {
      width: 50%;
    }
  }
  .tag_left_third {
    margin-top: 7px;
    ::v-deep .el-upload-dragger {
      width: 840px !important;
      height: 200px !important;
    }
    .svgdivs {
      width: 48px;
      height: 48px;
      background-color: #474e61;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        width: 33px;
        height: 33px;
        color: #ffffff;
      }
    }

    .uploaddiv {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .el-icon-plus {
        font-size: 15.66px;
        color: #46adff;
      }

      .filelist_div {
        width: 100%;
        height: 100%;

        .upload_table_div {
          .widthClass {
            width: 240px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          height: 170px;
          width: 100%;

          .videoDatanamediv_header {
            display: flex;
            //padding-top: 15px;

            p {
              color: #adb0bc;
              font-size: 12px;
              width: 240px;
              text-align: left;
            }
          }
          .videoDatanamediv_header1 {
            p {
              margin-top: 6px;
            }
            .first_p {
              width: 500px;
              padding-left: 15px;
              box-sizing: border-box;
            }
            .last_p {
              width: calc(100% - 500px);
              padding-left: 30px;
              box-sizing: border-box;
            }
          }
          .videoDatanamediv_body_list {
            display: flex;
            flex-direction: column;
            align-items: center;
            overflow-y: auto;
            height: calc(100% - 34px);

            .videoDatanamediv_body {
              display: flex;
              align-items: center;
              width: 820px;
              height: 49px;
              background: #474e61;
              border-radius: 4px;
              margin-bottom: 3px;

              // .svgdivs {
              //   //margin-left: 17px
              // }
              .left_div1 {
                width: 740px;
                .body_p {
                  width: 685px !important;
                  text-align: left;
                }
              }
              .left_div2 {
                width: 500px;
                .body_p {
                  width: 445px !important;
                  text-align: left;
                }
              }

              .last_delete1 {
                width: calc(100% - 500px - 240px) !important;
              }

              .left_div {
                @extend .widthClass;

                .body_p {
                  color: #ffffff;
                  font-size: 14px;
                  width: 220px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }

              .center_input {
                @extend .widthClass;

                ::v-deep .el-input__inner {
                  width: 170px !important;
                }

                //::v-deep .el-input__suffix {
                //  right: 40px !important;
                //  top: 1px;
                //}
                ::v-deep .el-input-group__append,
                .el-input-group__prepend {
                  // right: 10px;
                  width: 70px;
                  background: #393c4e;
                  border: 1px solid #7d7f8b;
                  border-left: none;
                  text-align: center;
                  padding: 0;
                }
              }

              .right_select {
                @extend .widthClass;
                margin-left: 20px;

                .selectclass {
                  ::v-deep .el-input__inner {
                    width: 240px !important;
                  }
                }
              }

              .last_delete {
                width: 7%;
                .icon {
                  width: 18px;
                  height: 18px;
                }
              }
            }
          }
        }

        .p_div {
          display: flex;
          justify-content: center;
          align-items: center;
          height: calc(100% - 170px);

          p {
            margin-left: 10px;
            margin-top: 0px;
          }
        }
      }
    }
    .topp {
      color: #46adff;
      font-size: 14px;
      margin-top: 11.9px;
    }

    .botp {
      color: #787e83;
      font-size: 10px;
      margin-top: 8px;
      text-align: center;
    }
    ::v-deep .el-upload-dragger {
      background-color: inherit !important;
      width: 400px;
    }
  }
  .content_center_2 {
    margin: 12px 0;
  }
}
</style>
