<template>
  <DialogComVue
    :dialogVisible="dialogAutoVisible"
    @changeDialogVisible="changeDialogVisible"
    :fullscreen="false"
    custom-class="innerDialogClass"
    :width="'1320px'"
    :top="'3%'"
    :showTitle="false"
    :showfooter="false"
    :destroyonclose="true"
    :title="$t('VideoDubbingClone.dubText51')"
    v-if="dialogAutoVisible"
  >
    <div class="innerDialog_div" ref="drawerContainer">
      <div style="display: flex; height: 100%">
        <div style="width: calc(100% - 330px); height: 100%">
          <div class="innerDialog_video_div" id="innerDialog_video_div">
            <!-- 视频展示 -->
            <video
              class="innerDialog_video_class"
              id="dialogVideoSub"
              ref="videorefSub"
              :src="videoUrl"
              type="video/mp4"
              oncontextmenu="return false"
            ></video>
            <!-- 字幕 -->
            <div class="left_video_div_div" id="left_video_div_div_sub">
              <div
                class="left_video_div_div1"
                id="left_video_div_div1"
                ref="left_video_div_div1"
                v-drag1
                @click="(e) => e.stopPropagation()"
              >
                <p
                  class="sub_p sub_p1"
                  :class="dataInfo.tlang_id == '20' ? 'setFontstyles' : ''"
                  :style="computedStyle"
                  v-show="
                    captionsObj.sentence_trans && +dataInfo.private_job_type > 1
                  "
                >
                  <span
                    :style="computedSpanStyle"
                    class="sub_p_span sub_p_span1"
                    >{{ captionsObj.sentence_trans }}</span
                  >
                </p>
                <p
                  class="sub_p sub_p2"
                  :style="computedStyle"
                  :class="dataInfo.slang_id == '20' ? 'setFontstyles' : ''"
                  v-show="
                    captionsObj.sentence_source &&
                    dataInfo.private_job_type == '1'
                  "
                >
                  <span
                    :style="computedSpanStyle"
                    class="sub_p_span sub_p_span2"
                  >
                    {{ captionsObj.sentence_source }}</span
                  >
                </p>
              </div>
            </div>
          </div>
          <div class="sliderClass">
            <el-slider
              v-model="slidervalue"
              :show-tooltip="true"
              :step="step"
              @change="sliderInput"
              :max="silderMax"
              :format-tooltip="formatTooltip"
              @mousedown.native="openVideo"
            ></el-slider>
          </div>
          <div class="left_videobom_div">
            <div class="left"></div>
            <div class="center">
              <!-- 回退 -->
              <div @click="rew" class="btndiv">
                <!-- ! todo -->
                <svg-icon
                  icon-class="VideoBack"
                  class="svgiconclass"
                ></svg-icon>
              </div>
              <!-- 开始时间 -->
              <p>{{ videoStartTime }}</p>
              <!-- 暂停播放按钮 -->

              <div @click="playvideo" v-if="player" class="btndiv" id="step-5">
                <svg-icon
                  icon-class="VideoPlay"
                  class="svgiconclass"
                ></svg-icon>
              </div>
              <div @click="closevideo" v-else class="btndiv" id="step-5">
                <svg-icon
                  icon-class="VideoPause"
                  class="svgiconclass"
                ></svg-icon>
              </div>

              <!-- 结束时间 -->
              <p>{{ videolangtime }}</p>
              <!-- 前进 -->
              <div @click="speek" class="btndiv">
                <!-- ! todo -->
                <svg-icon
                  icon-class="VideoForward"
                  class="svgiconclass"
                ></svg-icon>
              </div>
            </div>
            <div class="right">
              <!-- 显示字幕设置 -->
              <!-- <el-button type="text" @click="OpenDrawer">{{
                drawer
                  ? $t("VideoDubbingClone.dubText50")
                  : $t("VideoDubbingClone.dubText49")
              }}</el-button> -->
            </div>
          </div>
        </div>
        <div class="subDrawer">
          <div class="sub_style_div">
            <!-- 字体设置 -->
            <div class="style_div_1">
              <div class="style_div_1_top">
                <div>{{ $t("VideoDubbingClone.dubText52") }}</div>
                <el-button type="text" size="mini" @click="resetStyle">{{
                  $t("VideoDubbingClone.dubText53")
                }}</el-button>
              </div>
              <div class="style_div_1_bottom">
                <el-select
                  v-model="styleData.fontFamily"
                  filterable
                  :placeholder="$t('dubbing.please_select')"
                  class="selectClass"
                >
                  <el-option
                    v-for="(item, index) in fontFamilyList"
                    :key="index"
                    :label="$t('CommonName.' + item.name)"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="style_div_2">
              <!-- 字符大小 -->
              <div class="style_div_2_item">
                <div class="">{{ $t("VideoDubbingClone.dubText54") }}</div>
                <div class="style_div_2_item_bottom">
                  <el-select
                    v-model="styleData.fontSize"
                    filterable
                    :placeholder="$t('dubbing.anchorsText4')"
                    class="selectClass"
                  >
                    <el-option
                      v-for="(item, index) in fontSizeList"
                      :key="index"
                      :label="item.value"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <!-- 字符间距 -->
              <div class="style_div_2_item">
                <div class="">{{ $t("VideoDubbingClone.dubText55") }}</div>
                <div class="style_div_2_item_bottom">
                  <el-select
                    v-model="styleData.letterSpacing"
                    filterable
                    :placeholder="$t('dubbing.anchorsText4')"
                    class="selectClass"
                  >
                    <el-option :key="0" :label="0" :value="0"> </el-option>
                    <el-option
                      v-for="item in 24"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="style_div_3">
              <div class="style_div_3_item">
                <!-- 加粗 -->
                <el-tooltip
                  :content="$t('VideoDubbingClone.dubText56')"
                  placement="top"
                  effect="dark"
                  :enterable="false"
                  :visible-arrow="false"
                >
                  <div
                    class="svgdiv"
                    @click="styleData.Bold = !styleData.Bold"
                    @mousedown="(e) => e.preventDefault()"
                  >
                    <svg-icon
                      icon-class="jiacu"
                      :class="styleData.Bold ? 'active' : ''"
                      class="icon"
                    ></svg-icon>
                  </div>
                </el-tooltip>
                <!-- 斜体 -->
                <el-tooltip
                  :content="$t('VideoDubbingClone.dubText57')"
                  placement="top"
                  effect="dark"
                  :visible-arrow="false"
                  :enterable="false"
                >
                  <div
                    class="svgdiv"
                    @click="styleData.Tilt = !styleData.Tilt"
                    @mousedown="(e) => e.preventDefault()"
                  >
                    <svg-icon
                      icon-class="qingxie"
                      :class="styleData.Tilt ? 'active' : ''"
                      class="icon"
                    ></svg-icon>
                  </div>
                </el-tooltip>
                <!-- 下划线 -->
                <el-tooltip
                  :content="$t('VideoDubbingClone.dubText58')"
                  placement="top"
                  effect="dark"
                  :visible-arrow="false"
                  :enterable="false"
                >
                  <div
                    class="svgdiv"
                    @click="styleData.Underline = !styleData.Underline"
                    @mousedown="(e) => e.preventDefault()"
                  >
                    <svg-icon
                      icon-class="xiahuaxian"
                      :class="styleData.Underline ? 'active' : ''"
                      class="icon"
                    ></svg-icon>
                  </div>
                </el-tooltip>
              </div>
              <div class="style_div_3_item">
                <!-- 向左对齐 -->
                <el-tooltip
                  :content="$t('VideoDubbingClone.dubText59')"
                  placement="top"
                  effect="dark"
                  :visible-arrow="false"
                  :enterable="false"
                >
                  <div class="svgdiv" @click="styleAlign('left')">
                    <svg-icon
                      icon-class="TextLeft"
                      :class="styleData.Align == 'left' ? 'active' : ''"
                      class="icon1"
                    ></svg-icon>
                  </div>
                </el-tooltip>
                <!-- 居中对齐 -->
                <el-tooltip
                  :content="$t('VideoDubbingClone.dubText60')"
                  placement="top"
                  effect="dark"
                  :visible-arrow="false"
                  :enterable="false"
                >
                  <div class="svgdiv" @click="styleAlign('center')">
                    <svg-icon
                      icon-class="TextCenter"
                      :class="styleData.Align == 'center' ? 'active' : ''"
                      class="icon1"
                    ></svg-icon>
                  </div>
                </el-tooltip>
                <!-- 向右对齐 -->
                <el-tooltip
                  :content="$t('VideoDubbingClone.dubText61')"
                  placement="top"
                  effect="dark"
                  :visible-arrow="false"
                  :enterable="false"
                >
                  <div class="svgdiv" @click="styleAlign('right')">
                    <svg-icon
                      icon-class="TextRight"
                      :class="styleData.Align == 'right' ? 'active' : ''"
                      class="icon1"
                    ></svg-icon>
                  </div>
                </el-tooltip>
              </div>
            </div>
            <div class="style_div_4">
              <!-- 字幕颜色 -->
              <div class="style_div_4_item">
                <div class="">{{ $t("VideoDubbingClone.dubText62") }}</div>
                <div class="style_div_4_item_bottom">
                  <el-color-picker
                    v-model="styleData.Color"
                    :predefine="predefineColors"
                    @change="color_change(1, $event)"
                  >
                  </el-color-picker>
                </div>
              </div>
              <!-- 字幕背景 -->
              <div class="style_div_4_item">
                <div class="">{{ $t("VideoDubbingClone.dubText63") }}</div>
                <div class="style_div_4_item_bottom">
                  <el-color-picker
                    v-model="styleData.bgColor"
                    show-alpha
                    :predefine="predefineColors"
                    @change="color_change(2, $event)"
                  >
                  </el-color-picker>
                </div>
              </div>
              <!-- 字幕位置 -->
              <div class="style_div_4_item">
                <div class="">
                  {{ $t("VideoDubbingClone.dubText64") }}
                  <el-tooltip
                    :content="$t('VideoDubbingClone.dubText72')"
                    placement="top"
                    effect="dark"
                    :enterable="false"
                    :visible-arrow="false"
                  >
                    <svg-icon
                      icon-class="Help_Question"
                      class="icon"
                      style="width: 14px; height: 14px"
                    ></svg-icon>
                  </el-tooltip>
                </div>
                <div class="style_div_4_item_bottom_position">
                  <!-- 底部 -->
                  <el-tooltip
                    :content="$t('VideoDubbingClone.dubText67')"
                    placement="top"
                    effect="dark"
                    :enterable="false"
                    :visible-arrow="false"
                  >
                    <div
                      class="svgdiv"
                      @click="stylePosition(0)"
                      @mousedown="(e) => e.preventDefault()"
                    >
                      <svg-icon
                        icon-class="bottom"
                        :class="styleData.Position == 'bottom' ? 'active' : ''"
                        class="icon"
                      ></svg-icon>
                    </div>
                  </el-tooltip>
                  <!-- 顶部 -->
                  <el-tooltip
                    :content="$t('VideoDubbingClone.dubText68')"
                    placement="top"
                    effect="dark"
                    :enterable="false"
                    :visible-arrow="false"
                  >
                    <div
                      class="svgdiv"
                      @click="stylePosition(1)"
                      @mousedown="(e) => e.preventDefault()"
                    >
                      <svg-icon
                        icon-class="Pinned"
                        :class="styleData.Position == 'top' ? 'active' : ''"
                        class="icon"
                      ></svg-icon>
                    </div>
                  </el-tooltip>
                </div>
              </div>
            </div>
            <div class="style_div_4">
              <!-- 字幕描边 -->
              <div class="style_div_4_item">
                <div class="">{{ $t("VideoDubbingClone.dubText65") }}</div>
                <div class="style_div_4_item_bottom">
                  <el-color-picker
                    v-model="styleData.textStroke"
                    :predefine="predefineColors"
                  >
                  </el-color-picker>
                </div>
              </div>
              <!-- 字幕投影 -->
              <div class="style_div_4_item">
                <div class="">{{ $t("VideoDubbingClone.dubText66") }}</div>
                <div class="style_div_4_item_bottom">
                  <el-color-picker
                    v-model="styleData.textShadow"
                    :predefine="predefineColors"
                    @change="color_change(4, $event)"
                  >
                  </el-color-picker>
                </div>
              </div>
            </div>
          </div>
          <div class="sub_style_div_foot">
            <el-button type="info" size="mini" @click="SubCancel">{{
              $t("CommonName.Cancel")
            }}</el-button>
            <el-button type="primary" size="mini" @click="SubSave">{{
              $t("CommonName.Sure")
            }}</el-button>
          </div>
        </div>
      </div>
      <!-- 样式抽屉 -->
      <el-drawer
        :title="$t('VideoDubbingClone.dubText49')"
        :visible.sync="drawer"
        :append-to-body="false"
        :modal="false"
        style="position: absolute"
        custom-class="subDrawer"
        :modal-append-to-body="true"
        v-if="false"
      >
        <div class="sub_style_div">
          <!-- 字体设置 -->
          <div class="style_div_1">
            <div class="style_div_1_top">
              <div>{{ $t("VideoDubbingClone.dubText52") }}</div>
              <el-button type="text" size="mini" @click="resetStyle">{{
                $t("VideoDubbingClone.dubText53")
              }}</el-button>
            </div>
            <div class="style_div_1_bottom">
              <el-select
                v-model="styleData.fontFamily"
                filterable
                :placeholder="$t('dubbing.please_select')"
                class="selectClass"
              >
                <el-option
                  v-for="(item, index) in fontFamilyList"
                  :key="index"
                  :label="$t('CommonName.' + item.name)"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="style_div_2">
            <!-- 字符大小 -->
            <div class="style_div_2_item">
              <div class="">{{ $t("VideoDubbingClone.dubText54") }}</div>
              <div class="style_div_2_item_bottom">
                <el-select
                  v-model="styleData.fontSize"
                  filterable
                  :placeholder="$t('dubbing.anchorsText4')"
                  class="selectClass"
                >
                  <el-option
                    v-for="(item, index) in fontSizeList"
                    :key="index"
                    :label="item.value"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <!-- 字符间距 -->
            <div class="style_div_2_item">
              <div class="">{{ $t("VideoDubbingClone.dubText55") }}</div>
              <div class="style_div_2_item_bottom">
                <el-select
                  v-model="styleData.letterSpacing"
                  filterable
                  :placeholder="$t('dubbing.anchorsText4')"
                  class="selectClass"
                >
                  <el-option :key="0" :label="0" :value="0"> </el-option>
                  <el-option
                    v-for="item in 24"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="style_div_3">
            <div class="style_div_3_item">
              <!-- 加粗 -->
              <el-tooltip
                :content="$t('VideoDubbingClone.dubText56')"
                placement="top"
                effect="dark"
                :enterable="false"
                :visible-arrow="false"
              >
                <div
                  class="svgdiv"
                  @click="styleData.Bold = !styleData.Bold"
                  @mousedown="(e) => e.preventDefault()"
                >
                  <svg-icon
                    icon-class="jiacu"
                    :class="styleData.Bold ? 'active' : ''"
                    class="icon"
                  ></svg-icon>
                </div>
              </el-tooltip>
              <!-- 斜体 -->
              <el-tooltip
                :content="$t('VideoDubbingClone.dubText57')"
                placement="top"
                effect="dark"
                :visible-arrow="false"
                :enterable="false"
              >
                <div
                  class="svgdiv"
                  @click="styleData.Tilt = !styleData.Tilt"
                  @mousedown="(e) => e.preventDefault()"
                >
                  <svg-icon
                    icon-class="qingxie"
                    :class="styleData.Tilt ? 'active' : ''"
                    class="icon"
                  ></svg-icon>
                </div>
              </el-tooltip>
              <!-- 下划线 -->
              <el-tooltip
                :content="$t('VideoDubbingClone.dubText58')"
                placement="top"
                effect="dark"
                :visible-arrow="false"
                :enterable="false"
              >
                <div
                  class="svgdiv"
                  @click="styleData.Underline = !styleData.Underline"
                  @mousedown="(e) => e.preventDefault()"
                >
                  <svg-icon
                    icon-class="xiahuaxian"
                    :class="styleData.Underline ? 'active' : ''"
                    class="icon"
                  ></svg-icon>
                </div>
              </el-tooltip>
            </div>
            <div class="style_div_3_item">
              <!-- 向左对齐 -->
              <el-tooltip
                :content="$t('VideoDubbingClone.dubText59')"
                placement="top"
                effect="dark"
                :visible-arrow="false"
                :enterable="false"
              >
                <div class="svgdiv" @click="styleAlign('left')">
                  <svg-icon
                    icon-class="TextLeft"
                    :class="styleData.Align == 'left' ? 'active' : ''"
                    class="icon1"
                  ></svg-icon>
                </div>
              </el-tooltip>
              <!-- 居中对齐 -->
              <el-tooltip
                :content="$t('VideoDubbingClone.dubText60')"
                placement="top"
                effect="dark"
                :visible-arrow="false"
                :enterable="false"
              >
                <div class="svgdiv" @click="styleAlign('center')">
                  <svg-icon
                    icon-class="TextCenter"
                    :class="styleData.Align == 'center' ? 'active' : ''"
                    class="icon1"
                  ></svg-icon>
                </div>
              </el-tooltip>
              <!-- 向右对齐 -->
              <el-tooltip
                :content="$t('VideoDubbingClone.dubText61')"
                placement="top"
                effect="dark"
                :visible-arrow="false"
                :enterable="false"
              >
                <div class="svgdiv" @click="styleAlign('right')">
                  <svg-icon
                    icon-class="TextRight"
                    :class="styleData.Align == 'right' ? 'active' : ''"
                    class="icon1"
                  ></svg-icon>
                </div>
              </el-tooltip>
            </div>
          </div>
          <div class="style_div_4">
            <!-- 字幕颜色 -->
            <div class="style_div_4_item">
              <div class="">{{ $t("VideoDubbingClone.dubText62") }}</div>
              <div class="style_div_4_item_bottom">
                <el-color-picker
                  v-model="styleData.Color"
                  :predefine="predefineColors"
                  @change="color_change(1, $event)"
                >
                </el-color-picker>
              </div>
            </div>
            <!-- 字幕背景 -->
            <div class="style_div_4_item">
              <div class="">{{ $t("VideoDubbingClone.dubText63") }}</div>
              <div class="style_div_4_item_bottom">
                <el-color-picker
                  v-model="styleData.bgColor"
                  show-alpha
                  :predefine="predefineColors"
                  @change="color_change(2, $event)"
                >
                </el-color-picker>
              </div>
            </div>
            <!-- 字幕位置 -->
            <div class="style_div_4_item">
              <div class="">
                {{ $t("VideoDubbingClone.dubText64") }}
                <el-tooltip
                  :content="$t('VideoDubbingClone.dubText72')"
                  placement="top"
                  effect="dark"
                  :enterable="false"
                  :visible-arrow="false"
                >
                  <svg-icon
                    icon-class="Help_Question"
                    class="icon"
                    style="width: 14px; height: 14px"
                  ></svg-icon>
                </el-tooltip>
              </div>
              <div class="style_div_4_item_bottom_position">
                <!-- 底部 -->
                <el-tooltip
                  :content="$t('VideoDubbingClone.dubText67')"
                  placement="top"
                  effect="dark"
                  :enterable="false"
                  :visible-arrow="false"
                >
                  <div
                    class="svgdiv"
                    @click="stylePosition(0)"
                    @mousedown="(e) => e.preventDefault()"
                  >
                    <svg-icon
                      icon-class="bottom"
                      :class="styleData.Position == 'bottom' ? 'active' : ''"
                      class="icon"
                    ></svg-icon>
                  </div>
                </el-tooltip>
                <!-- 顶部 -->
                <el-tooltip
                  :content="$t('VideoDubbingClone.dubText68')"
                  placement="top"
                  effect="dark"
                  :enterable="false"
                  :visible-arrow="false"
                >
                  <div
                    class="svgdiv"
                    @click="stylePosition(1)"
                    @mousedown="(e) => e.preventDefault()"
                  >
                    <svg-icon
                      icon-class="Pinned"
                      :class="styleData.Position == 'top' ? 'active' : ''"
                      class="icon"
                    ></svg-icon>
                  </div>
                </el-tooltip>
              </div>
            </div>
          </div>
          <div class="style_div_4">
            <!-- 字幕描边 -->
            <div class="style_div_4_item">
              <div class="">{{ $t("VideoDubbingClone.dubText65") }}</div>
              <div class="style_div_4_item_bottom">
                <el-color-picker
                  v-model="styleData.textStroke"
                  :predefine="predefineColors"
                >
                </el-color-picker>
              </div>
            </div>
            <!-- 字幕投影 -->
            <div class="style_div_4_item">
              <div class="">{{ $t("VideoDubbingClone.dubText66") }}</div>
              <div class="style_div_4_item_bottom">
                <el-color-picker
                  v-model="styleData.textShadow"
                  :predefine="predefineColors"
                  @change="color_change(4, $event)"
                >
                </el-color-picker>
              </div>
            </div>
          </div>
        </div>
      </el-drawer>
    </div>
  </DialogComVue>
</template>
<script>
import DialogComVue from "@/components/DialogCom.vue";
import { get_private_job_info } from "@/utils/https";
import { hideLoading, showLoading } from "@/utils/loading";
import { fabric } from "fabric";
import $ from "jquery";
import { getStringToTime, getTimeString } from "@/utils/tools";
import { TimeHandle } from "@/api/setTime";
import configOther from "@/config/other";
export default {
  components: {
    DialogComVue,
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    job_id: {
      type: String,
      default: "",
    },
    videoUrl: {
      type: String,
      default: "",
    },
    currentTime: {
      type: Number,
      default: 0,
    },
    tableData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    dataInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  created() {},
  computed: {
    computedStyle() {
      return {
        textAlign: this.styleData.Align,
        backgroundColor:
          !!this.styleData.bgColor == true ? this.styleData.bgColor : "unset",
      };
    },
    computedSpanStyle() {
      return {
        fontFamily: this.styleData.fontFamily + " !important",
        fontSize: this.styleData.fontSize + "px",
        letterSpacing: this.styleData.letterSpacing + "px",
        fontWeight: this.styleData.Bold ? "bold" : "unset",
        fontStyle: this.styleData.Tilt ? "italic" : "unset",
        textDecoration: this.styleData.Underline ? "underline" : "unset",
        color: this.styleData.Color,
        textShadow:
          !!this.styleData.textStroke == true
            ? "0 -1px " +
              this.styleData.textStroke +
              ", 1px -1px " +
              this.styleData.textStroke +
              ", 1px 0 " +
              this.styleData.textStroke +
              ", 1px 1px " +
              this.styleData.textStroke +
              ", 0px 1px " +
              this.styleData.textStroke +
              ", -1px 1px " +
              this.styleData.textStroke +
              ", -1px 1px " +
              this.styleData.textStroke +
              ", -1px 0 " +
              this.styleData.textStroke +
              ", -1px -1px " +
              this.styleData.textStroke
            : "unset", //文字描边
      };
    },
  },
  mounted() {
    this.dialogAutoVisible = this.dialogVisible;
    this.get_dubbing_style();
    this.initVideo();
    window.addEventListener("keydown", this.windowkeydown, true);
    window.addEventListener("keyup", this.windowkeyup, true);
  },
  data() {
    return {
      dialogAutoVisible: false,
      videoDom: {}, //创建 视频对象
      HardSubtitleRecognitionTab: "first", // 硬字幕识别 tab
      videoStartTime: "00:00:00.00",
      videolangtime: "00:00:00.00", //总时长
      slidervalue: 0, //当前步
      step: 0.01, //步长
      silderMax: 0, //总步长
      canvas: null,
      downPoint: null, // 按下鼠标时的坐标
      upPoint: null, // 松开鼠标时的坐标
      rect: null,
      player: true,
      playflag: false,
      rectList: [], // 矩形属性 时间范围
      rect_id: 1, //矩形ID
      selectedRectId: 0, //选中矩形ID
      drawer: false, //选中矩形ID
      captionsObj: {
        // sentence_trans: "模板文字",
        // sentence_source: "template text",
      }, //选中句段字幕
      AlignData: {
        left: "0px auto 0px 0%",
        center: "0 auto",
        right: "0px 0% 0px auto",
      }, //对齐方式数据

      styleData: {
        fontFamily: "TitilliumWeb-SemiBold", //字体,
        fontSize: "18", //文字大小px
        letterSpacing: "0", //字体间距px
        Bold: false, //加粗
        Tilt: false, //倾斜
        Underline: false, //下划线
        Align: "center", // left  center  right  对齐方式
        Position: "bottom", // top  bottom    字幕显示方式
        Color: "#ffffff", //字幕颜色
        bgColor: "", // 字幕背景颜色
        textStroke: "", // 字幕描边颜色
        textShadow: "", // 字幕投影颜色
        top: 0, // 字幕offsetTop
        bottom: 0, // 字幕bottom
        videoHeight: 0, //视频显示高度
        trueHeight: 0, //视频真实高度
        textHeight: 0, //文字真实高度
        left: 0, // 字幕offsetLeft
        right: 0, // 字幕right
        videoWidth: 0, //视频显示宽度
        trueWidth: 0, //视频真实宽度
      }, //字体样式数据集
      fontFamilyList: configOther.fontFamilyList, //字体列表
      fontSizeList: configOther.fontSizeList, //字符大小列表
      letterSpacingList: configOther.letterSpacingList, //字符间距列表
      predefineColors: configOther.predefineColors, //颜色默认
      padding_x: 0, //获取视频父元素的宽度与视频显示宽度差值
    };
  },
  /**
   * 拖拽
   */
  directives: {
    drag1(el, binding, vnode) {
      el.style.cursor = "move";
      el.onmousedown = (e) => {
        var disx = e.pageX - el.offsetLeft;
        var disy = e.pageY - el.offsetTop;
        var that = this;
        if (e.preventDefault) {
          e.preventDefault();
        } else {
          e.returnValue = false; // 解决快速频繁拖动滞后问题
        }
        document.onmousemove = (e) => {
          // 鼠标位置-鼠标相对元素位置=元素位置
          let top = e.clientY - disy;
          let bottom = 0;
          if (top <= 0) {
            top = 0;
          } else if (
            top >
            // document.documentElement.clientHeight - el.clientHeight
            document.getElementById("dialogVideoSub").clientHeight -
              el.clientHeight
          ) {
            top =
              document.getElementById("dialogVideoSub").clientHeight -
              el.clientHeight;
          }
          bottom =
            document.getElementById("dialogVideoSub").clientHeight -
            el.clientHeight -
            top;
          if (vnode.context.styleData.Position == "top") {
            el.style.top = top + "px";
            el.style.bottom = "auto";
          } else {
            el.style.top = "auto";
            el.style.bottom = bottom + "px";
          }
          let left = e.clientX - disx;
          let right = 0;
          // console.log("left", left);
          //如果是居中对齐 左右拖拽 则字幕div不动且出于背景div 位置left：50%，背景div动。
          if (vnode.context.styleData.Align == "center") {
            // console.log("left", left);
          } else {
            // console.log("vnode.context.padding_x", vnode.context.padding_x);
            //限制拖拽范围不超出可视区
            //获取视频父元素的宽度与视频显示宽度差值
            if (left <= 0) {
              left = 0;
            } else if (
              left >
              document.getElementById("dialogVideoSub").clientWidth -
                el.clientWidth
            ) {
              // document.documentElement.clientWidth屏幕可视区宽度
              left =
                document.getElementById("dialogVideoSub").clientWidth -
                el.clientWidth;
            }
            right =
              document.getElementById("dialogVideoSub").clientWidth -
              el.clientWidth -
              left;
            if (vnode.context.styleData.Align == "right") {
              el.style.right = right + "px";
              el.style.left = "auto";
            } else {
              el.style.right = "auto";
              el.style.left = left + "px";
            }
          }
        };

        document.onmouseup = function () {
          document.onmousemove = document.onmouseup = null;
        };
      };
    },
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.windowkeydown, true);
    window.removeEventListener("keyup", this.windowkeyup, true);
  },
  methods: {
    //获取字幕样式信息
    get_dubbing_style() {
      showLoading(this.$t("VideoDubbing.loadingmessage"));
      get_private_job_info({
        action: "get_job_style",
        job_id: this.job_id,
      }).then((res) => {
        hideLoading();
        if (!res) {
          return;
        }
        if (!!res.info == true) {
          this.styleData = JSON.parse(res.info);

          if (this.styleData.Position == "top") {
            let top =
              (this.styleData.top / this.styleData.videoHeight) *
              this.videoDom.clientHeight;
            this.$refs.left_video_div_div1.style.top = top + "px";
            this.$refs.left_video_div_div1.style.bottom = "auto";
          } else {
            let bottom =
              (this.styleData.bottom / this.styleData.videoHeight) *
              this.videoDom.clientHeight;
            this.$refs.left_video_div_div1.style.top = "auto";
            this.$refs.left_video_div_div1.style.bottom = bottom + "px";
          }
          //兼容老数据
          if (this.styleData.Align == "center") {
            this.$refs.left_video_div_div1.style.left = "unset";
            this.$refs.left_video_div_div1.style.right = "unset";
            this.$refs.left_video_div_div1.style.width = "100%";
          } else {
            if (!!this.styleData.left == true || this.styleData.left == 0) {
              if (this.styleData.Align == "right") {
                let right =
                  (this.styleData.right / this.styleData.videoWidth) *
                  this.videoDom.clientWidth;
                this.$refs.left_video_div_div1.style.right = right + "px";
                this.$refs.left_video_div_div1.style.left = "auto";
                this.$refs.left_video_div_div1.style.width = "auto";
              } else if (this.styleData.Align == "left") {
                let left =
                  (this.styleData.left / this.styleData.videoWidth) *
                  this.videoDom.clientWidth;
                this.$refs.left_video_div_div1.style.left = left + "px";
                this.$refs.left_video_div_div1.style.right = "auto";
                this.$refs.left_video_div_div1.style.width = "auto";
              }
            }
          }

          //投影颜色
          let filter =
            !!this.styleData.textShadow == false
              ? "none"
              : "drop-shadow(" + this.styleData.textShadow + " 2px 2px 0px)";
          $(".sub_p_span").css("filter", filter);
        }
        // this.initPosition();
      });
    },
    /**
     * 初始化 视频
     * @param data
     */
    initVideo() {
      this.$nextTick(() => {
        // this.videoDom = null;
        this.videoDom = document.getElementById("dialogVideoSub"); // 获取视频dom元素

        let isCurrentMetadataLoaded = false;
        this.videoDom.addEventListener("loadedmetadata", (e) => {
          if (!isCurrentMetadataLoaded) {
            this.videolangtime = TimeHandle(this.videoDom.duration);
            this.silderMax = this.videoDom.duration;
            isCurrentMetadataLoaded = true;
            //字幕区域与视频实际宽度一致
            $("#left_video_div_div_sub").css(
              "width",
              document.getElementById("dialogVideoSub").clientWidth
            );
            console.log("currentTime", this.currentTime);
            this.videoDom.currentTime = this.currentTime;
            if (this.currentTime > 0) {
              //句段自动显示
              this.captionsObj = {};

              let findItem = this.tableData.find(
                (item) =>
                  this.videoDom.currentTime >= item.data_start &&
                  this.videoDom.currentTime < item.data_end
              );
              if (findItem) {
                this.captionsObj = findItem;
              }

              // this.currentRegion = {};
              // for (let index = 0; index < this.tableData.length; index++) {
              //   if (
              //     this.videoDom.currentTime >=
              //       this.tableData[index].data_start &&
              //     this.videoDom.currentTime < this.tableData[index].data_end
              //   ) {
              //     this.captionsObj = this.tableData[index];
              //     console.log("!loopplay&&this.player");
              //     break;
              //   }
              // }
            }
          }
        });
        this.videoDom.ontimeupdate = () => {
          // 播放走动时，实时获取播放时间
          this.videoStartTime = TimeHandle(this.videoDom.currentTime);
          this.slidervalue = this.videoDom.currentTime;
        };
        // 播放 结束
        this.videoDom.addEventListener("ended", () => {
          this.player = true;
        });
        this.videoDom.addEventListener("error", () => {
          this.$messages("warning", this.$t("CommonName.videoWarning"));
        });
      });
    },
    /**
     *
     * @param {* 回退} data
     */
    rew() {
      this.gospeek = true;
      this.videoDom.currentTime = this.videoDom.currentTime - 1; // 视频退三秒
      this.videoStartTime = TimeHandle(this.videoDom.currentTime);
      this.setSeek();
    },
    /**
     *
     * @param {*播放} data
     */
    playvideo() {
      this.player = false;
      this.videoDom.play();
      clearInterval(this.currentRegionInterval);
      this.setTimeInterval();
    },
    /**
     *
     * @param {* 暂停播放} data
     */
    closevideo() {
      clearInterval(this.currentRegionInterval);
      this.player = true;
      this.videoDom.pause();
      this.currentRegionInterval = null;
    },
    //播放时轮询句段
    setTimeInterval() {
      clearInterval(this.currentRegionInterval);
      this.currentRegionInterval = null;

      this.currentRegionInterval = setInterval(() => {
        if (!this.player && !this.loopPlay) {
          this.captionsObj = {};

          // this.currentRegion = {};
          for (let index = 0; index < this.tableData.length; index++) {
            if (
              this.videoDom.currentTime >= this.tableData[index].data_start &&
              this.videoDom.currentTime < this.tableData[index].data_end
            ) {
              this.captionsObj = this.tableData[index];
              console.log("!loopplay&&this.player");
              break;
            }
          }
        }
      }, 30);
    },
    /**
     *
     * @param {* 快进} data
     */
    speek() {
      this.gospeek = true;
      if (this.videoDom.currentTime == this.videoDom.duration) {
        return;
      }
      this.videoDom.currentTime = this.videoDom.currentTime + 1; // 前进三秒
      this.videoStartTime = TimeHandle(this.videoDom.currentTime);
      this.setSeek();
    },
    /**
     * 设置快进快退之后 位置跳转
     * @param data
     */
    setSeek() {
      this.captionsObj = {};

      // this.currentRegion = {};
      for (let index = 0; index < this.tableData.length; index++) {
        if (
          this.videoDom.currentTime >= this.tableData[index].data_start &&
          this.videoDom.currentTime < this.tableData[index].data_end
        ) {
          this.captionsObj = this.tableData[index];
          // console.log("!loopplay&&this.player");
          break;
        }
      }
    },
    /**
     *
     * @param {* 调整播放速度} command
     */
    handleCommand(command) {
      this.videoDom.playbackRate = command;
    },
    /**
     *
     * @param {*关闭弹窗} val
     */
    changeDialogVisible(val) {
      this.dialogAutoVisible = val;
      this.$emit("changeAutoVisible", this.dialogAutoVisible);
    },
    /**
     *
     * @param {*确定按钮} val
     */
    determineClick() {
      console.log(this.rectList);
      if (this.selectedRectId > 0) {
        let old_index = this.rectList.findIndex(
          (item1) =>
            this.selectedRectId == item1.rect_id && item1.isShowSave == true
        );
        console.log("old_index", old_index);
        if (old_index > -1) {
          //调用保存方法
          if (!this.saveTimeRange(old_index)) {
            return false;
          }
        }
      }
    },

    /**
     * 滑块滑动  修改视频时间
     * @param slider
     */
    sliderInput(slider) {
      this.slidervalue = slider;
      this.videoStartTime = TimeHandle(slider);
      this.videoDom.currentTime = slider;
      if (this.playflag) {
        this.videoDom.play();
        this.player = false;
        this.playflag = false;
      } else {
        this.setSeek();
      }
    },
    /**
     *
     * @param {滑块 按下事件} data
     */
    openVideo() {
      if (!this.player) {
        this.playflag = true;
      }
      this.videoDom.pause();
      this.player = true;
    },
    /**
     * 格式化显示 时间
     * @param val
     */
    formatTooltip(val) {
      return getTimeString(val, 1, 2).replace(".", ",");
    },
    /**
     * 往对象中添加 属性值
     * @param id
     */
    setObjData(id, time) {
      return {
        id: id,
        time: time,
        label: this.$createElement("i", {
          class: {
            "el-icon-location": true,
          },
          style: {
            transform: "rotate(180deg)",
            color: id == 1 ? "#98FF6C" : "#FFB655",
          },
        }),
      };
    },
    /**
     * 时间转换
     * @param time
     * @returns {string}
     */
    formatTime(time) {
      const milliseconds = parseInt(time * 1000);
      const date = new Date(milliseconds);
      const hours = date.getUTCHours().toString().padStart(2, "0");
      const minutes = date.getUTCMinutes().toString().padStart(2, "0");
      const seconds = date.getUTCSeconds().toString().padStart(2, "0");
      return `${hours}:${minutes}:${seconds}`;
    },
    /**
     *
     * @param {*键盘 按下事件} e
     */
    windowkeydown(e) {
      console.log();
      const nodeName = e.target.nodeName;
      //空格
      if (e.keyCode == 0 || e.keyCode == 32) {
        if (
          (nodeName && nodeName.toUpperCase() == "INPUT") ||
          (nodeName && nodeName.toUpperCase() == "TEXTAREA")
        ) {
          return;
        }
        e.preventDefault();
        if (this.player) {
          this.playvideo();
        } else {
          this.closevideo();
        }
      }
    },
    //打开抽屉
    OpenDrawer() {
      this.drawer = true;
    },
    //取消
    SubCancel() {
      this.dialogAutoVisible = false;
      this.$emit("changeAutoVisible", this.dialogAutoVisible);
    },
    //保存
    SubSave() {
      this.styleData.videoHeight = this.videoDom.clientHeight;
      this.styleData.videoWidth = this.videoDom.clientWidth;
      this.styleData.top = this.$refs.left_video_div_div1.offsetTop;
      this.styleData.trueHeight = this.videoDom.videoHeight;
      this.styleData.trueWidth = this.videoDom.videoWidth;
      this.styleData.bottom =
        this.$refs.left_video_div_div1.style.bottom.replace("px", "");
      this.styleData.left = this.$refs.left_video_div_div1.offsetLeft;
      this.styleData.right = this.$refs.left_video_div_div1.style.right.replace(
        "px",
        ""
      );
      // if (this.styleData.Align == "right") {
      //   this.styleData.right =
      //     this.styleData.right - this.padding_x < 0
      //       ? 0
      //       : this.styleData.right - this.padding_x;
      // } else if (this.styleData.Align == "left") {
      //   this.styleData.left =
      //     this.styleData.left - this.padding_x < 0
      //       ? 0
      //       : this.styleData.left - this.padding_x;
      // }
      // console.log(this.styleData);
      // return false;
      if (+this.dataInfo.private_job_type > 1) {
        $(".sub_p1").show();
        $(".sub_p_span1").text(" ");
        this.styleData.textHeight = $(".sub_p_span1").height();
      } else {
        $(".sub_p2").show();
        $(".sub_p_span2").text(" ");
        this.styleData.textHeight = $(".sub_p_span2").height();
      }
      showLoading(this.$t("VideoDubbing.loadingmessage"));
      get_private_job_info({
        action: "set_job_style",
        job_id: this.job_id,
        result_info: JSON.stringify(this.styleData),
      }).then((res) => {
        hideLoading();
        if (!res) {
          // $(".sub_p_span1").text(this.captionsObj.sentence_source);
          return;
        }
        this.dialogAutoVisible = false;
        this.$emit("changeAutoVisible", this.dialogAutoVisible);
        // this.initPosition();
      });
    },
    //重置样式
    resetStyle() {
      this.styleData = {
        fontFamily: "TitilliumWeb-SemiBold",
        fontSize: "18",
        letterSpacing: "0", //字体间距
        Bold: false, //加粗
        Tilt: false, //倾斜
        Underline: false, //下划线
        Align: "center", // left  center  right  对齐方式
        Position: "bottom", // top  bottom    字幕显示方式
        Color: "#ffffff", //字幕颜色
        bgColor: "", // 字幕背景颜色
        textStroke: "", // 字幕描边颜色
        textShadow: "", // 字幕投影颜色
      };
      this.$refs.left_video_div_div1.style.top = "auto";
      this.$refs.left_video_div_div1.style.bottom = "60px";
      this.$refs.left_video_div_div1.style.left = "unset";
      this.$refs.left_video_div_div1.style.right = "unset";
      this.$refs.left_video_div_div1.style.width = "100%";
      //投影颜色
      $(".sub_p_span").css("filter", "none");
    },
    //字体颜色设置
    color_change(mode, data) {
      if (data == null) {
        if (mode == 1) {
          //字体颜色
          this.styleData.Color = "#fff";
        } else if (mode == 2) {
          //背景颜色
          this.styleData.bgColor = "";
        }
      }
      if (mode == 4) {
        //投影颜色
        let filter =
          data == null ? "none" : "drop-shadow(" + data + " 2px 2px 0px)";
        $(".sub_p_span").css("filter", filter);
      }
    },
    //字体位置设置 置顶还是底部
    stylePosition(mode) {
      let top = this.$refs.left_video_div_div1.offsetTop;
      if (mode == 1) {
        this.styleData.Position = "top";
        this.$refs.left_video_div_div1.style.top = top + "px";
        this.$refs.left_video_div_div1.style.bottom = "auto";
      } else {
        this.styleData.Position = "bottom";

        let bottom =
          this.videoDom.clientHeight -
          document.getElementById("left_video_div_div1").clientHeight -
          top;
        this.$refs.left_video_div_div1.style.top = "auto";
        this.$refs.left_video_div_div1.style.bottom = bottom + "px";
      }
    },
    //字幕对齐设置
    styleAlign(type) {
      this.styleData.Align = type;
      this.$refs.left_video_div_div1.style.transform = "unset";
      if (type == "right") {
        this.$refs.left_video_div_div1.style.left = "auto";
        this.$refs.left_video_div_div1.style.width = "auto";
        this.$refs.left_video_div_div1.style.right = "0px";
      } else if (type == "center") {
        this.$refs.left_video_div_div1.style.left = "unset";
        this.$refs.left_video_div_div1.style.right = "unset";
        this.$refs.left_video_div_div1.style.width = "100%";
      } else {
        this.$refs.left_video_div_div1.style.left = "0px";
        this.$refs.left_video_div_div1.style.right = "auto";
        this.$refs.left_video_div_div1.style.width = "auto";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./style.scss";
.innerDialog_div {
  width: 100%;
  height: 595px;
  box-sizing: border-box;

  .showOpenButton {
    position: absolute;
    right: 24px;
  }

  .innerDialog_video_div {
    background: #000000;
    width: 100%;
    height: calc(100% - 55px);
    display: flex;
    justify-content: center;
    position: relative;

    .innerDialog_video_class {
      height: 100%;
      width: auto;
      max-width: 100%;
    }
    .left_video_div_div {
      width: 100%;
      height: 100%;
      position: absolute;
    }
    .left_video_div_div1 {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      bottom: 60px;
      left: unset;
      z-index: 300;
      width: 100%;
      cursor: pointer;
      white-space: pre-wrap;
      word-break: break-word;
      &:hover {
        background: rgba(0, 0, 0, 0.6);
      }

      .sub_p {
        color: #ffffff;
        background: rgba(0, 0, 0, 0.6);
        width: fit-content;
        margin: 0 auto;
      }
    }
  }

  .sliderClass {
    width: 97%;
    //margin-top: 10px;
    height: 15px;

    ::v-deep .el-slider__marks-text {
      margin-top: 3px !important;
    }

    ::v-deep .el-slider__runway {
      margin: 10px 1.5% 0 1.5%;
    }

    ::v-deep .el-slider__button-wrapper {
      width: 26px !important;
      height: 26px !important;
      top: -10px !important;
    }

    ::v-deep .el-slider__button {
      width: 10px !important;
      height: 10px !important;
      border: none !important;
      background: #409eff !important;
    }
  }
  .left_videobom_div {
    height: 40px;
    width: calc(100% - 32px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 16px;
    position: relative;

    .btndiv {
      color: #46adff;
      // margin-top: 2px;
      cursor: pointer;
      .svgiconclass {
        font-size: 16px;
        outline: 0 !important;
      }
      ::v-deep .popperClassDiv {
        min-width: 100px;
      }
    }
    .left {
      display: flex;
      align-items: center;
      width: 250px;
    }
    .right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 250px;
      p {
        color: #46adff;
        width: 40px;
      }

      ::v-deep .popper__arrow {
        left: 20px !important;
      }
    }
    .center {
      display: flex;
      align-items: center;
      p {
        color: #cbd7e8;
        font-size: 12px;
        margin: 0 17px;
      }
    }
  }
}
.subDrawer {
  height: 100%;
  width: 330px !important;
  .el-drawer__header {
    color: #fff;
    border-bottom: 1px solid;
    padding: 20px 20px;
    margin-bottom: unset;
  }
  .sub_style_div {
    padding: 24px;
    height: calc(100% - 50px);
    box-sizing: border-box;
    .el-input__icon {
      line-height: 32px;
    }
    .style_div_1 {
      .style_div_1_top {
        display: flex;
        justify-content: space-between;
        color: #fff;
        font-size: 14px;
      }
      .style_div_1_bottom {
        margin-top: 6px;
        ::v-deep .el-input__inner {
          width: 282px !important;
        }
      }
    }
    .style_div_2 {
      display: flex;
      justify-content: space-between;
      color: #fff;
      font-size: 14px;
      margin-top: 8px;
      .style_div_2_item {
        width: 120px;
        ::v-deep .el-input__inner {
          width: 120px !important;
        }
        .style_div_2_item_bottom {
          margin-top: 6px;
        }
      }
    }
    .style_div_3 {
      display: flex;
      justify-content: space-between;
      color: #fff;
      font-size: 14px;
      margin-top: 12px;
      .style_div_3_item {
        width: 120px;
        background: rgba(69, 71, 89, 0.6);
        border-radius: 2px;
        height: 32px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        .icon {
          width: 18px;
          height: 18px;
          color: #adb0bc;
          cursor: pointer;
          &:hover {
            color: #46adff;
          }
        }
        .icon.active {
          color: #46adff;
        }
        .icon1 {
          width: 20px;
          height: 20px;
          color: #adb0bc;
          cursor: pointer;
          &:hover {
            color: #46adff;
          }
        }
        .icon1.active {
          color: #46adff;
        }
      }
    }
    .style_div_4 {
      display: grid;
      color: #fff;
      font-size: 14px;
      margin-top: 16px;
      justify-content: space-between;
      grid-template-columns: repeat(auto-fill, 75px);
      grid-auto-flow: dense;
      grid-gap: 25px;
      .style_div_4_item {
        width: 96px;
        .style_div_4_item_bottom {
          margin-top: 6px;
          ::v-deep .el-color-picker {
            height: 20px !important;
          }
          ::v-deep .el-color-picker__trigger {
            width: 56px !important;
            height: 20px !important;
            padding: 0px !important;
            border: 1px solid #7d7f8b !important;
          }
          ::v-deep .el-color-picker__color {
            border: none;
            border-radius: 0px;
          }
        }
        .style_div_4_item_bottom_position {
          width: 56px;
          background: rgba(69, 71, 89, 0.6);
          border-radius: 2px;
          height: 20px;
          margin-top: 6px;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          .icon {
            width: 16px;
            height: 16px;
            color: #adb0bc;
            cursor: pointer;
            &:hover {
              color: #46adff;
            }
          }
          .icon.active {
            color: #46adff;
          }
        }
      }
    }
  }
  .sub_style_div_foot {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 12px;
  }
}
::v-deep .el-dialog.innerDialogClass .el-dialog__body {
  max-height: unset;
  height: calc(100% - 49px);
}
::v-deep .innerDialogClass {
  ::v-deep .el-dialog__body {
    height: calc(100% - 49px) !important;
    max-height: 100% !important;
  }
}
</style>
<style>
.el-color-dropdown .el-color-dropdown__btns .el-button {
  line-height: 1 !important;
}
</style>
