/*
 * @Description:
 * @version:
 * @Author: Carful
 * @Date: 2023-02-14 17:23:45
 * @LastEditors: Carful
 * @LastEditTime: 2023-07-18 10:15:10
 */
module.exports = {
  // 项目管理
  ProjectManagement: {
    Replace3: "Change operator to...",
    Replace1: "Current task operator",
    Replace2: "Operator after replacement",
    Media: "Media Project",
    Media1: "Dubbing Project",
    Media2: "Project",
    createTask: "Create Project",
    createTask1: "Create Dubbing Project",
    projectmanagement: "Projects",
    formItemLabel: "Copy Existing Project Attributes",
    projectName: "Project Name",
    projectNameplaceholder: "Enter project name...",
    Numberoftasks: "Tasks",
    TOTALMEDIALENGTH: "Media Length",
    BasicSetting: "Global Settings",
    SelectDateTime: "Select Date",
    Deadline: "Deadline",
    Completedon: "Completed on",
    Waterprint: "Waterprint",
    Waterprint1: "Operator",
    TaskURL: "Task URL",
    ReportURL: "Report URL",
    CopyTaskURL: "Copy Task Information",
    CopyTaskURL1: "Details",
    TaskInformation: "Task Information",
    Downloadsubtitles: "Download Subtitle",
    Replacevideofile: "Replace",
    ImportSRTname: "Import",
    SRTFileName: "SRT File Name",
    Workflow: "Workflow",
    Status: "Status",
    refuse: "Reject",
    MainASREngine: "ASR Engine",
    MediaLength: "Media Length",
    MediaSegments: "Media Segments",
    SourceWordcount: "Word Count",
    Createdon: "Created on",
    Deliveredon: "Delivered on",
    TempTM: "Translation TM",
    EditTM: "Editing TM",
    ProofUrl: "Proof URL",
    ProofUrl1: "GPT Translation Report",
    EditItem: "Edit",
    DeleteItem: "Delete Project",
    CREATED: "Created on",
    TimeCodeMode: "Timecode Mode",
    TimeSettings: "Selected Settings",
    OtherSettings: "Other Settings",
    Basicinformation: "Details",
    haveinhand: "In Progress",
    Delivered: "Delivered",
    notSet: "Not set",
    STTname: "Speech-to-text Transcription (STT)",
    TRAname: "Subtitle Translation (TRA)",
    REVname: "Subtitle Review (REV)",
    changeVideo1:
      "Supported audio formats: aac, ac3, ape, flac, m4a, mp3, ogg, wav, wma",
    changeVideo2: "Supported video formats: mp4 (H264), webm",
    changeVideo3: "The maximum file size is limited to 2 GB",
    changeVideo4: "Supported formats: .zip",
    changeVideo5: "Ensure video file and .srt file names are the same",
    changeVideo6: "The .zip file cannot contain any folder",
    changeVideo7: "Only supports uploading ZIP files",
    Importoptions: "Import Process",
    Replace: "Replace",
    Source: " Source",
    Target: "Target",
    Bilingual: "Bilingual",
    Bilingual1: "Bilingual-Source First",
    Bilingual2: "Bilingual-Target First",
    TextFormat: "Text Format",
    SourceFirst: "Source First",
    TargetFirst: "Target First",
    SubtitleFile: "Subtitle File",
    UploadSubtitleFile: "Upload Subtitle File",
    SelectTaskinProject: "Select Task in Project",
    SelectSubtitleFileinTask: "Select Subtitle File in Task",
    DownloadSubtitleFiles: "Download Subtitle Files",
    SourceTXT: "Source .txt",
    SourceDOCX: "Source .docx",
    SourceSRT: "Source .srt",
    SourceChangeSRT: "Modified source only .srt",
    TargetTXT: "Target .txt",
    TargetTTML: "Target .ttml",
    TargetSRT: "Target .srt",
    BilingualSRT1: "Bilingual-Source First .srt",
    BilingualSRT2: "Bilingual-Target First .srt",
    Comment: "Comment",
    CreateOn: "Create On",
    Changetms: "Select TM",
    AllowTimeCodesOverlap: "Allow timecode overlap",
    MachineTranslation: "AI Translation",
    tai: "Calculate Thai characters by ignoring vowel symbols, tone marks, and other non-placeholder symbols.",
    Allowreviewertoeditoriginalsubtitlefile:
      "Allow reviewer to edit original subtitle file",
    Allowtranslationtomodify: "Allow translator to edit original subtitle file",
    Termquery: "Term Search",
    Managementtermfile: "Select Term List",
    query: "Search",
    translation: "Translation",
    Currentprojectglossary: "Term List for Current Project",
    Filename: "File Name",
    Numberofterms: "Segments",
    confirm: "Save",
    remove: "Remove",
    addTerms: "Select Term List from Glossary",
    Colour: "Colour",
    CommentName: "Comment Name",
    Processused: "Available Workflow",
    AddNewComment: "Add Comment",
    STT: "STT",
    TRA: "TRA",
    REV: "REV",
    Proof: "Proof",
    successmessage1: "Created successfully",
    successmessage2: "Edited successfully",
    successmessage3: "Closed successfully",
    warningmessage1: "Only accept .srt file",
    warningmessage2: "Only accept .zip file",
    warningmessage3:
      "All the video&audio files in this project will be permanently deleted," +
      "\n" +
      "do you want to close the project?",
    warningmessage4: "Please select task...",
    warningmessage5: "Cannot view the details under current status",
    warningmessage6: "Two temporary TM cannot select the same item",
    ExcelToContent: "Excel To Content",
    ProjectSettings: "Project Settings",
    renameJob: "Rename",
    Createdby: "Created by",
    DownloadExcelTemplate: "Download Template (.xlsx)",
    STT1: "STT",
    TRA1: "TRA",
    REV1: "REV",
    Update: "Update",
    dropdownList: {
      name1: "Share",
      name2: "Project Info",
      name3: "Rename",
      name4: "Delete",
    },
    Sharetitle: "Share Project",
    Sharetitle1: "Share with",
    Sharetitle2: "Candidates",
    Shares: "Shared with",
    successmessage4: "Project shared successfully.",
    DownloadSelectedTaskFiles: "Download All Files in",
    LocalizationUrl: "WiiTube Video Info",
    WiiTubeVideoID: "YouTube Video ID",
    ReplaceOssMode: "Change",
    ReplaceOssModeTitle: "Change Storage Location",
    CreateFolder: "Create Folder",
    FolderName: "Folder Name",
    RenameFolder: "Rename Folder",
    FolderNameTip: "Please enter folder name.",
    FolderNameTip1: "Created folder successfully.",
    FolderNameTip2: "Renamed folder successfully.",
    MoveToFile: "Move",
    MoveToFileTitle: "Move to",
    AllProject: "All Projects",
    FolderDeleteTip: "Cannot delete this folder. It contains projects.",
    FolderDeleteTip1: "Are you sure you want to delete this folder?",
    FolderMoveTip:
      "Move selected items successfully. (Folder with duplicate name will not be moved.)",
    FolderMoveTip1: "The selected folder has already been deleted",
    CheckInfo: "View",
    JobMoveTip: "Move selected items successfully.",
    ReportURL1: "翻译质量报告链接",
    exportName: "批量项目数据导出",
    warningmessage7: "请选择项目",
    dubSetting: "配音设置",
    autoClone: "允许自动克隆",
    STTUrl1: "转写提交对比报告",
    ModificationTime: "Update on",
    TermCategory: "Term category",
    TermCategoryName: "类别名称",
    addTermCategory: "新增类别",
    STQname: "STQ",
    STQ1: "STQ",
    STQUrl1: "校轴提交对比报告",
    HuaSRT2: "画面字 .srt",
    captionsMove: "禁止拖拽字幕",
    Subtitling: "Subtitling",
    hardSubtitleTip:
      "Are you sure to synchronize all video identification areas with the current video and create the task immediately?",
    projectTemplateManage: "项目模板管理",
    createProjectTemplate: "创建项目模板",
    projectTemplateName: "项目模板名称",
    saveToTemplate: "将当前设置保存为模板",
    exportText1: "导出项目信息",
    exportText2: "导出项目成员工作信息",
    AssociatedProject: "已关联项目",
    warningmessage8: "字幕区域存在未填项",
    warningmessage9: "ASR设置存在未填项",
    warningmessage10: "设置备注存在未填项",
    warningmessage11: "设置备注名称重复",
    warningmessage12: "模板名称不能为空",
    projectTemplate: "项目模板",
    unbindTemplate: "取消应用项目模板",
    warningmessage13:
      "This action will delete the item. Do you want to continue?",
  },
  // 通用
  CommonName: {
    loockUptext: "*Find content with leading/trailing spaces.",
    Replacetext: "*Replace content with leading/trailing spaces.",
    Prev: "Prev",
    Nexts: "Next",
    videoWarning:
      "An error occurred while loading video. Connection has been reestablished.",
    Next: "Next",
    Sure: "Save",
    OK: "OK",
    Cancel: "Cancel",
    Previous: "Previous",
    Taskworkflow: {
      name: "Task Workflow",
      label0: "Transcription (STT)",
      label1: "Transcription (STT) > Translation (TRA)",
      label2: "Translation (TRA)",
      label3: "Translation (TRA) > Review (REV)",
      label4: "Transcription (STT) > Translation (TRA) > Review (REV)",
      label5: "Transcription (STT) > Transcription QC (STQ)",
      label6:
        "Transcription (STT) > Transcription QC (STQ) > Translation (TRA) > Review (REV)",
    },
    PreprocessOptions: {
      name: "Options",
      label0: "Auto Speech-to-text Recognition",
      label1: "Manually Upload Source File .srt/.ass/.vtt",
      label2: "Manually Upload Bilingual File .srt/.ass/.vtt",
      label3: "Manually Upload Two Source&Target Files .srt/.ass/.vtt",
      label4: "Hard Subtitle Recognition",
      label5: "Subtitle Align",
    },
    Orderoftextsinsubtitlefile: {
      name: "Language Sequence in Bilingual File",
      label0: "Target Language First",
      label1: "Source Language First",
    },
    Sourcelanguage: "Source Language",
    Targetlanguage: "Target Language",
    Selectproject: "Select Project",
    Selectmediafileinproject: "Select Media File in Project",
    Speechtranscriptiondeadline: "Speech Transcription Deadline",
    Subtitletranslationdeadline: "Subtitle Translation Deadline",
    Subtitlereviewdeadline: "Subtitle Review Deadline",
    MainTMoptional: "Main TM (optional)",
    TempTMToptional: "Translation TM (optional)",
    optional: "(optional)",
    TempTMEoptional: "Editing TM (optional)",
    selectMessage: "Please complete required items",
    selectMessage1: "Source and target languages cannot be the same",
    selectMessage2: "Name cannot be empty",
    Upload: "Upload",
    ASREngine: {
      name: "ASR Engine",
      label0: "Microsoft",
      label1: "Tencent",
      label2: "Alibaba",
      label3: "Bytedance",
    },
    title2: "STEP 2/2 - Task Information",
    status: "Status",
    operation: "",
    sourcelanguagetable: "Source",
    targetlanguagetable: "Target",
    duration: "Duration",
    Numberofsentencesegments: "Segments",
    Numberofwords: "Word Count",
    screen: "Filter:",
    determine: "Save",
    search: "Search",
    delete: "Delete",
    import: "Import",
    export: "Export",
    merge: "Merge",
    download: "Download",
    remarks: "Comments",
    TaskName: "Task Name",
    taskname: "Task Name",
    taskType: "Task Type",
    Pleaseselect: "Please select...",
    Copysucceeded: "Copied successfully",
    warningmessage1: "Please select language",
    warningmessage2: "Source and target languages cannot be the same",
    warningmessage3: "Please enter the name",
    warningmessage4: "token is invalid, please login again",
    warningmessage5: "Please upload the file",
    warningmessage6: "The file has been uploaded and in process now... ",
    successmessage1: "Created successfully",
    successmessage2: "Deleted successfully",
    successmessage3: "Edited successfully",
    successmessage4: "Imported successfully",
    successmessage5: "Uploaded successfully",
    successmessage6: "Modified successfully ",
    loadingmessage: "Loading...",
    noData: "No data",
    statusName1: "STT",
    statusName2: "TRA",
    statusName3: "REV",
    statusName4: "Processing",
    statusName5: "Delivered",
    statusName6: "Closed",
    deleteMessage:
      "The file will be permanently deleted, do you want to continue?",
    deleteMessage1:
      "The task will be permanently deleted, do you want to continue?",
    deleteMessage2:
      "The term will be permanently deleted, do you want to continue?",
    deleteMessageTitle: "Warning",
    deleteMessagebutton: "Yes",
    deleteMessageClosebutton: "No",
    Completed: "Completed",
    SelectAllItem: "All Selected",
    goTo: "Go to",
    NewJobName: "New task name",
    WorkflowNode: "Workflow Node",
    replace1: "Replace ",
    replace2: "With ",
    lookupText1: "Source",
    lookupText2: "Target",
    deleteMessage3:
      "The glossary will be permanently deleted, do you want to continue?",
    OnlineHelp: "Online Help",
    Messages: "Messages",
    uploadTermTip1: "",
    uploadTermTip2: "  terms uploaded. ",
    uploadTermTip3: " terms imported successfully.",
    commentsDelete: "Delete",
    closeMessage:
      "This operation will close the selected task. Do you want to continue?",
    tokenMessage: "Token has expired, please log in again",
    revertMessage:
      "It will permanently delete the task, do you want to continue?",
    statusName10: "Voice Customized Dubbing",
    statusName11: "Accent Clone Dubbing",
    create: "Create",
    AItext: "AI translation in progress...",
    info: "Details",
    deleteMessage4:
      "The term base will no longer be used in the current project. Do you wish to continue?",
    selectMessage3:
      "When you select automatic speech recognition or manually upload bilingual or target language subtitle files, you cannot select multiple target languages",
    SaveAndClose: "Save and close",
    Apply: "Apply",
    Pleaseselect1: "请选择执行人",
    Pleaseselect2: "请选择文件夹",
    Pleaseinput1: "请输入新建文件夹名称",
    AITText: "自动AIT",
    content1: "初始内容",
    content2: "新内容",
    statusName12: "STQ",
    STQDeadline: "STQ截止时间",
    deleteMessage5: "此操作将清除任务中的画面字, 是否继续?",
    GlossaryCount: "术语库数量",
    fontFamilyText1: "TitilliumWeb-SemiBold",
    fontFamilyText2: "Source Han Serif",
    fontFamilyText3: "Source Han Sans",
    fontFamilyText4: "Roboto-Regular",
    OcrMode: {
      name: "硬字幕识别设置",
      label0: "仅识别原文",
      label1: "识别原文与译文",
    },
    findText1: "No content requiring replacement was found.",
    findText2:
      "The replacement operation is completed, Number of segments modified: ",
    loadingtext: "File processing...",
    immediateClick: "Create now",
    ExportDetails: "Export details",
    deleteMessage6:
      "The template will be permanently deleted, do you want to continue?",
    deleteMessage7: "此操作将删除 ",
    deleteMessage8: " 句, 是否继续?",
    warningmessage7: "此操作将取消应用项目模板, 是否继续?",
    targetFolder: "Destination folder",
    warningmessage8: "The folder name already exists",
    purchaseMessage: "请发送购买需求到 ramona.yu@wiitrans.com 邮箱",
    AITMode: {
      title: "服务类型",
      label0: "GPT-4o",
      label1: "Deepseek-R1（阿里）",
      label2: "Deepseek-R1（字节）",
      label3: "Deepseek-V3（字节）",
      label4: "历史记录发送",
      label5: "简洁对话",
    },
    logining: "Log in...",
  },
  // 团队 媒体操作
  TeamOperation: {
    Submit: "Submit",
    PleaseCompletebefore: "Please Complete before",
    FullScreen: "Full screen",
    text: "Review in progress, click refresh to get the latest report.",
    text1: "Review completed.",
    text2: "Revised segments: ",
    text3: " Revision Rate: ",
    text4: "%",
    text5:
      "Transcription QC in progress, click refresh to get the latest report.",
    text6: "Transcription QC completed.",
  },
  // 创建任务
  ExternalCreateTask: {
    title1: "STEP 1/2 - Create Task",
    title2: "STEP 1/3 - Create Task",
    title3: "STEP 2/3 - Task Information",
    title4: "STEP 3/3 - Hard Subtitle Recognition",
    title5: "STEP 1/4 - Create Task",
    title6: "STEP 2/4 - Task Information",
    title7: "STEP 3/4 - Hard Subtitle Recognition (Source)",
    title8: "STEP 4/4 - Hard Subtitle Recognition (Target)",
    Sourceofmediafile: {
      name: "Media File",
      label0: "Upload New File",
      label1: "Select Existing File",
      label2: "Local Mode",
    },
    TimeCodeMode: "Time Code Mode",
    UploadMediaFile: "Upload Media File",
    FileStoragelocation: "File Storage Location",
    UploadFile: "Upload File .srt/.ass/.vtt",
    UploadSourceFile: "Upload Source File .srt/.ass/.vtt)",
    UploadTargetFile: "Upload Target File .srt/.ass/.vtt",
    Taskprocessor: "ASR Engineer",
    Translator: "Translator",
    ProofreadTaskHandler: "Reviewer",
    warningmessage1: "Only accept file format in .srt/.ass/.vtt",
    warningmessage2:
      "Only accept file format in .aac/.ac3/.ape/.flac/.m4a/.mp3/.ogg/.wav/.wma/.mp4/.webm",
    warningmessage3: "The maximum file size is limited to 1.5 GB",
    warningmessage4: "Creating...",
    warningmessage5: "Please select the date and time",
    warningmessage6: "Please upload the file",
    successmessage1: "Created successfully",
    sortText: "Default task order",
    sortText1: "Task name ascending",
    sortText2: "Task name descending",
    UploadFile1: "Upload File .txt",
    warningmessage7: "Only accept file format in .txt",
    termText1: "Glossary",
    termText2: "Select Existing Glossary",
    termText3: "Create Glossary",
    termText4: "Glossary Name",
    termText5: "Glossary Language",
    warningmessage8: "The glossary name and glossary language cannot be empty",
    subShowText: "Caption display mode",
    subShowText1: "A single-sentence",
    subShowText2: "Multi-sentence",
    STQprocessor: "校轴任务处理人",
    projectText1: "项目属性",
    projectText2: "选择已有项目模板",
    projectText3: "选择已有项目属性",
  },
  // 导入任务
  ImportTask: {
    Selecttaskmode: {
      name: "Select Task Mode",
      label0: "Optional Tasks",
      label1: "All Tasks",
    },
    title1: "STEP 1/2 - Import Tasks",
    selectMessages: "Source and Target languages cannot be the same",
    successmessage1: "Imported successfully",
  },
  // 批量创建
  BatchCreationTask: {
    title1: "STEP 1/2 - Batch Create",
    OSSMode: "File Storage Location",
    UploadMediaZipFile: "Upload Multiple files in one .zip file",
  },
  BatchCreateHardSubtitleRecognitionTasks: {
    title1: "STEP 1/3 - Batch Create (OCR)",
    title2: "步骤 1/3 - 批量创建 (画面字)",
    uploadFiles: "",
  },
  // 团队 项目详情
  ProjectDetails: {
    buttonList: {
      name1: "Import Task",
      name2: "Create Task",
      name3: "Batch Create",
      name4: "Project Info",
      name5: "Term List",
      name6: "Settings",
      name7: "Batch Assign",
      name8: "Batch Create (OCR)",
      name9: "Create Voiceprint Custom Dubbing Task",
      name10: "Create Video Clone Dubbing Task",
      name11: "Customize Anchor - Voiceprint",
      name12: "Customize Anchor - Clone",
      name13: "Project Anchor List",
      name14: "Customize Voice",
      name15: "Secondment Task",
      name16: "Secondment Subtitle Task",
      name17: "Secondment Dubbing Task",
      name18: "Project Role",
      name19: "Voiceprint Custom Dubbing Project Role",
      name20: "Video Clone Dubbing Project Role",
      name21: "Batch Assign/Reassign",
      name22: "Batch Reassign",
      name23: "Subtitle Style Settings",
      name24: "Subtitle Settings",
      name25: "Batch Open Tasks",
      name26: "批量创建 (画面字)",
      name27: "创建声音",
      name28: "项目声音管理",
      name29: "项目角色管理",
    },
    dropdownList: {
      name1: "Export Excel",
      name2: "Import Excel",
      name3: "Generate Waveform",
      name4: "Clone Task",
      name5: "Delete Task",
      name6: "Assign Resource",
      name7: "Rename",
      name8: "Import AIT File",
      name9: "Split",
      name10: "从其他任务导入AIT",
      name11: "从其他任务导入备注",
    },
    topButton: {
      name: "Add Role",
      name2: "One-Click Clone",
      name3: "One-Click Dubbing",
      name4: "Merge Roles",
      name5: "Lock/Unlock",
      name6: "Delete Role",
      name7: "一键配音还原",
    },
    dropdownBatchList: {
      name1: "Download All Files in .docx",
      name2: "Download All Files with Source in .srt",
      name3: "Download All Files with Target in .srt",
      name4: "Download All Files with Target in .ttml",
      name5: "Download All Task URLs in .xlsx",
      name6: "Download All Files in .xlsx",
      name7: "Download All Files in",
      name8: "Run AIT",
      name9: "Close Task",
      name10: "Clean up AIT",
      name11: "Download All Bilingual Files with Source first .srt",
      name12: "Download All Bilingual Files with Target first .srt",
      name13: "Stop AIT",
      name14: "Batch Reject",
      name15: "Batch Open Review Tasks",
      name16: "Run MT",
      name17: "Batch Open Review Tasks",
      name18: "Batch Open Assigned Tasks (Translation)",
      name19: "Batch Open Assigned Tasks (Transcription)",
      name20: "Batch Open Transcription Tasks",
      name21: "Batch Open Translation Tasks",
      name22: "移动任务",
      name23: "批量修改任务名称",
      name24: "Delete Task",
      name25: "下载所有任务校轴报告 .xlsx",
      name26: "下载所有任务校审报告 .xlsx",
      name27: "批量打开校轴任务",
      name28: "导出软字幕错误检查报告 .xlsx",
      name29: "下载所有中括号画面字内容 .xlsx",
      name30: "从其他项目导入AIT",
      name31: "批量打开指派任务（校轴）",
      name32: "Batch Open QA Reports",
      name33: "从其他项目导入画面字",
      name34: "下载所有任务源语言画面字 .srt",
      name35: "下载所有任务源语言对白 .srt",
      name36: "批量替换任务原文SRT",
      name37: "批量更新任务时间轴和原文",
    },
    dropExportBatchList: {
      name1: "Download All Files in .xlsx (Only Source and Target)",
      name2: "Download All Task URLs in .xlsx",
    },
    bottomButtonList: {
      name1: "取消提交任务",
      name2: "导出字幕",
      name3: "导出视频(带字幕)",
      name4: "为字幕配音(AI)",
      name5: "为字幕配音(克隆原声)",
      name6: "导出台词(按角色)",
      name7: "导出背景音",
      name8: "导出配音结果",
      name9: "导出音频(按音轨)",
      name10: "导出音频(按角色)",
      name11: "导出音频(混音)",
      name12: "导出视频",
      name13: "为字幕配音(AI)",
      name14: "指定执行人",
      name15: "更换执行人",
      name16: "导出视频(已涂抹)",
    },
    ViewLog: "View Log",
    EditTM: "Select TM",
    CloseProject: "Close Project",
    DeleteTask: "Delete Task",
    Operationprocess: "Workflow",
    closingdate: "Deadline",
    Uploadanewglossary: "Create Term List",
    AssignChangeTaskHandler: "Assign Operator",
    Transcription: "Transcription",
    Translation: "Translation",
    Review: "Review",
    Pending: "Pending",
    Delivered: "Delivered",
    UploadUpdateglossary: "Update Term List",
    GenerateWaveform: "Creating waveform ...",
    GenerateWaveform1: "Waveform created successfully.",
    revivification: "Apply this version",
    version: "Version",
    versionCreateOn: "Version creation time",
    versiontext1: "Replace the previous version of the operation",
    versiontext2: "Version prior to merge operation",
    versiontext3: "Version before importing Excel file operation",
    repulseMessage:
      "Are you sure you want to return the selected task to the previous process",
    repulseMessage1:
      "The selected task process is different. Do you still want to return all selected tasks to the previous process",
    text1: "Merge Next Task",
    borrowText1: "Subtitle Project",
    borrowText2: "Dubbing Script",
    borrowText3: "Reassigned Task Target Language SRT",
    borrowText4: "Upload SRT File",
    borrowText5: "Voiceprint Customization",
    borrowText6: "Video Cloning",
    borrowText7: "Please Upload the Dubbed SRT File",
    borrowText8: "International Track (Optional)",
    borrowText9: "Dry Audio Track (Optional)",
    borrowText10: "Reassign Subtitle Task",
    borrowText11: "Reassign Dubbing Task",
    downloadText: "Download Audio Tracks/Composite Video",
    downloadText1: "Dubbed Track",
    downloadText2: "Track in Role",
    downloadText3: "Mixed Track",
    downloadText4: "Synthesized Video",
    downloadText5: "Not Generated",
    downloadText6: "Generating",
    downloadText7: "Generated",
    dubbingText: "Global Dubbing",
    downloadTips:
      "There is a task currently generating, please wait to download",
    downloadTips1:
      "Generating the required audio tracks, please wait to download",
    dubbingText1: "上传背景音频/人声音频",
    dubbingText2:
      "支持上传Zip格式文件，无文件夹，背景音频命名规则：**_no_vocals.mp3/wav，人声音频命名规则：**_vocals.mp3/wav，文件名前缀需与任务名前缀保持一致",
    borrowText12: "借调任务源语言 SRT",
    dubbingText3: "视频预览",
    dubbingText4: "任务列表中没有任务，无法打开字幕样式设置",
    dubbingText5: "压制视频",
    dubbingText6: "项目字幕样式设置",
    labelText: "修改方式",
    labelText0: "追加到任务名前",
    labelText1: "替换",
    labelText2: "追加内容",
    labelText3: "请输入被替换内容",
    dubbingText7: "审阅任务",
    TranscriptionQC: "Transcription QC",
    text2: "设置术语",
    text3: "移除画面字",
    versiontext5: "画面字去除前版本",
    versiontext6: "全局内容替换",
    text4: "全局内容替换",
    downloadText8: "下载合成视频",
    downloadTips2: "正在生成所需视频，请稍候下载",
    AITTips:
      "需确保被借调项目下任务名称与当前选择任务名称一致，替换后将默认为AIT内容，不会覆盖已编辑的翻译内容",
    AITTips1: "替换后将默认为AIT内容，不会覆盖已编辑的翻译内容",
    AITTips2: "需确保被借调项目下任务名称与当前选择任务名称一致",
    cancelSplit: "取消拆分",
    HuaTips: "画面字识别",
    HuaTips1: "画面字识别设置",
    HuaTips2:
      "请按住鼠标左键，在视频中框选非画面字的区域（普通字幕或连续的文字提示信息，至少需框选一个区域），框选后区域将不会进行识别",
    uploadText: "上传原文 Zip 文件",
    sworkflow: "其他任务流程",
    tworkflow: "当前任务流程",
    name1: "包含标准时间轴和原文的任务",
    name2: "目标任务",
    versiontext7: "同步其他任务原文及时间轴",
    name3: "包含标准时间轴和原文的项目",
    FolderNameTip: "新建文件夹与现有文件夹存在同名，保存时将会分配到该文件夹下",
    AssignChangeTaskHandler1: "设置项目默认人员",
    Videotranscription: "视频AI字幕任务",
    Videodubbing: "视频AI配音任务",
    VideoCloningDubbing: "视频克隆配音任务",
    warningmessage: "此操作将选中任务取消提交状态, 是否继续?",
    subtitleSuppression: "字幕压制结果",
    VideoDubbingResults: "视频配音结果",
    tableText: "视频(带字幕)",
    createText: "源视频字幕获取方式",
    createText1: "源视频和配音脚本来源",
    createRadioText: "上传字幕",
    createRadioText1: "上传视频和字幕文件",
    createRadioText2: "选择视频 AI 字幕任务",
    createText2: "将字幕翻译为",
    createText3: "是否涂抹源视频字幕",
    createRadioText3: "不涂抹",
    createRadioText4: "普通涂抹",
    createRadioText5: "高级涂抹",
    createTaskTitle: "创建视频AI字幕任务",
    createTaskTitle1: "创建视频AI配音任务",
    createTaskTitle2: "创建视频克隆配音任务",
    createText4: "涂抹情况",
    createText5: "已涂抹",
    createText6: "源视频人声音轨（可选）",
    createText7: "源视频背景音音轨（可选）",
    createText8: "wav格式，≤200MB，仅支持单一文件",
    warningmessage1: "请先创建任务",
    subtitleSmearing: "视频涂抹结果",
    tableText1: "视频(已涂抹)",
    splitText1: "Split (Segmens)",
    splitText2: "Split (Minutes)",
  },
  // name6字幕设置
  CaptionSettings: {
    tab1: "Subtitle Baseline",
    tab2: "Forbidden Characters",
    tab3: "Project Waterprint",
    tab4: "Set Comments",
    tab5: "GPT Settings",
    tab7: "ASR Settings",
    tab8: "Subtitle Area",
    tab9: "SRT Settings",
    asrText:
      "Enable/Disable ASR option; after enabling, please select the desired ASR language from the list.",
    srtText: "Export prefix information",
    srtText1: "Export suffix information",
    srtText2: "Left Indent",
    srtText3: "Right Indent",
    srtText4: "Top Indent",
    srtText5: "Bottom Indent",
    srtText6:
      "I would like to request you to assist as a subtitle translator in translating from ** to **. After translation, the target text must include correct punctuation, and do not omit any. Pay special attention to the lowercase letter after a comma, even if it is at the beginning of a new line. Also, make sure not to miss translating any sentences.",
    Waterprint: {
      WaterprintSTT: "Waterprint STT",
      WaterprintTRA: "Waterprint TRA",
      WaterprintREV: "Waterprint REV",
      WaterprintSTQ: "Waterprint STQ",
    },
    Minimumdurationofsubtitlesperline: "Timecode Duration Min",
    Maximumdurationperlineofwords: "Timecode Duration Max",
    MinigapTime: "Timecode Mini Gap",
    Allowequalsto0: "Allow equals to 0",
    Maximumwordslimitperlineofsubtitles: "Line Limitation",
    languages: "Language",
    MaximumWords: "Character Max",
    Readrate: "Reading Speed",
    nothing: "None",
    ChineseCharacters: "Chinese Characters",
    EnglishCharacters: "English Characters",
    CustomForbiddenCharacters: "Customize Characters",
    Useregextocustomize:
      "One set of character per line, e.g.:" +
      "\n" +
      "&" +
      "\n" +
      "#" +
      "\n" +
      "@",
    Glossariestabs2Title:
      " Warn if one of the following characters appear in transcript/target text",
    MiniDuration: "Mini Duration",
    srtText7: "导出原文前缀信息",
    srtText8: "导出原文后缀信息",
    srtText9: "导出译文前缀信息",
    srtText10: "导出译文后缀信息",
    srtText11: "导出单语增加前后缀信息",
    srtText12: "导出双语增加前后缀信息",
    Maxlines: "译文单句最大行数",
    tab10: "客户要求",
  },
  //  团队 成员管理
  MemberManagement: {
    title: "Members",
    headerbtn1: "Invitation Log",
    headerbtn2: "Invite Member",
    tableBtn1: "Terminate",
    tableBtn2: "Team Log",
    tableBtn3: "Personal log",
    Numberoftasksinvolved: "Tasks",
    Joiningtime: "Joined Time",
    Nickname: "Nickname",
    email: "Email",
    accountnumber: "Account ID",
    accountnumberinput: "Please enter accurate account ID",
    successmessage1: "Successfully invited the other party to our team", //Invitation sent and wait for response
    Inviting: "Inviting...",
    Incooperation: "In Collaboration",
    Rejected: "Rejected",
    Terminated: "Terminated",
    deleteMessage: "Are you sure to terminate collaboration with",
    deleteMessage1: "?",
    invitationMessage1: "Invitation account does not exist",
    invitationMessage2: "The invitation account cannot be for oneself",
    invitationMessage3: "Repeat invitation",
    AllProject: "All",
  },
  // 团队 任务记录
  MemberDetails: {
    time1: "Please select start date",
    time2: "Please select end date",
    exportData: "Export Log",
    details: "Task log",
    details1: "Personal log",
    taskcontent: "Task Type",
    createTime: "Created on",
    Submissiontime: "Submitted on",
    datetime1: "Start Date",
    datetime2: "to",
    datetime3: "End Date",
    datetime4: "Consumption Quantity/Unit",
    datetime5: "File Length/Unit",
    individual: " Individual",
  },
  // 邀请记录
  InvitationRecord: {
    title: "Invitation Log",
    Invitationtime: "Invitation Time",
    AccountID: "Account ID",
    AccountEmail: "Emails",
    Accountnickname: "Nickname",
  },

  // TM 管理
  TMManagement: {
    title: "Translation Memory",
    headerbtn: "Create TM",
    TMType: "TM Type",
    TMName: "TM Name",
    detailedinformation: "TM Info",
    Search: "Search",
    placeholder: "Search in source text only",
    tootipcontent: "Results are shown when match score is greater than 75%",
    tabletitle1: "Source",
    tabletitle2: "Target",
    TMdetailedinformation: "TM Info",
    edit: "Edit",
    TMName1: "TM Name",
    TMKey: "TM Key",
    LanguagePair: "Language Pair",
    Segments: "Segments",
    CreatedDate: "Created on",
    Comments: "Comments",
    MainTM: "Main TM",
    TemporaryTM: "Temporary TM",
    ImportTM: "Import TM",
    Merge: "Merge",
    Uploadfile: "Upload File",
    tootip1: "Only accept a .xlsx file less than 100M",
    Addnewtranslationunits: "Add as new translation units",
    Overwriteexistingtranslationunits: "Overwrite existing translation units",
    Leaveexistingtranslationunitsunchanged: "Keep existing translation units",
    mergetabletitle: "Select TM",
    Mergeoptions: "If target segments differ:",
    statusName1: "Ready",
    statusName2: "Importing...",
    statusName3: "Exporting...",
    statusName4: "Merging...",
    warningmessage1: "Please enter TM name",
    warningmessage2: "Source and target Languages cannot be the same",
    successmessage1: "Created successfully",
    successmessage2: "Exported successfully",
    successmessage3: "Imported successfully",
    successmessage4: "Merged successfully",
    warningmessage3: "Please complete it",

    warningmessage4: "Only accept .xlsx file",
    select: "Please select...",
    importTMTypeTitle: "If target segments differ:",
    MergeTitle: "Merge TM",
    Workflow: "Process options",
    dropdownBatchText1: "批量删除",
  },
  // 术语库管理
  TermBaseManagement: {
    title: "Glossary",
    NewGlossary: "Create Term List",
    GlossaryName: "Glossary Name",
    Importtermfile: "Import Terms",
    Uploadtermfile: "Upload Terms (.xlsx)",
    DownloadGlossaryTemplate: "Download Template (.xlsx)",
    ImportGlossary: "Import Glossary",
    Quicklyaddterms: "Add Terms",
    Operationrecord: "View Log",
    Operationrecord1: "Log",
    Sourceinput: "Enter term in source language...",
    Targetinput: "Enter term in target language...",
    Editglossaryinformation: "Edit Glossary Information",
    dialogSourceinput: "Please enter term in source language.",
    dialogTargetinput: "Please enter term in target language.",
    OperationTime: "Time",
    Operator: "operator",
    Operationcontent: "Details",
    Editglossary: "Edit Terms",
    warningmessage1: "Only accept .xlsx file",
    warningmessage2: "Edited successfully",
    warningmessage3: "Please complete required items",
    warningmessage4: "comment name cannot be the same",
    successmessage1: "Added successfully",
    updateTermRadio1:
      "Update with latest translation when source terms are same",
    updateTermRadio2: "Empty it first and then import the latest term list",
    warningmessage5: "Please enter the correct language",
    warningmessage6: "The first row cannot be filled without a language",
    warningmessage7: "Do not paste multiple lines of content in the first row",
    warningmessage8: "Pasted content must not contain empty values",
    warningmessage9:
      "You cannot modify the content of the first three columns in the first row",
    warningmessage10: "This worksheet cannot be deleted",
    warningmessage11: "The term already exists, in line ",
    warningmessage12: "",
    warningmessage13: "The language already exists, in line ",
    warningmessage14: "",
    warningmessage15: "The pasted content cannot contain duplicate values",
    termType: "术语类型",
    termTypename: "项目术语库",
    termTypename1: "通用术语库",
    termname1: "当前通用术语表",
  },
  // 团队 工具箱
  toolset: {
    title: "Tools",
    toolsetList: {
      name0: "Segment Sequence Sorting",
      name1: "Combine Documents",
      name2: ".xlsx To .srt Converter",
      name3: "Convert .srt to UTF-8",
      name4: "Error Checker",
      name5: "Words",
      name6: "Swap Source&Target",
      name7: "Combine Source&Target",
      name8: "Split Subtitle",
      name9: "Join Subtitles",
      name10: "Know-all",
      name11: "SRT To ASS",
      name12: "Excel To Srt File",
      name13: "To Srt Excel",
      name14: "Bilingual Srt To Excel",
      name15: "Video Conversion",
      name16: "Hiventy项目检查",
      name17: "Combine Source&Target（画面字特别版）",
      name18: "导入Excel、SRT 并导出SRT",
      name19: "分离画面字",
      name20: "批量语音识别",
    },
    name15Text: "Please enter a notification email",
    name15Text1: "Conversion Successful",
    name0dialogtitle: "Upload ZIP File",
    name0dialogtooltip: "Please compress files first and then upload",
    Importoptions: "Import Process",
    SourceSRT: "Source SRT",
    BilingualSRT: "Bilingual SRT",
    Uploadsourcefile: "Upload Source File",
    Uploadreplacefile: "Upload Replace File",
    UploadExcelFile: "Upload Excel File",
    Uploadfilezip: "Upload File (.zip)",
    Uploadfilesrt: "Upload File (.zip)",
    Uploadtargetfile: "Upload Target file",
    AddWords: "Add Words",
    Word: "Word",
    Errortype: "Error Type",
    Crossovertime: "Crossover Time",
    ContainsEnglish: "Contains English",
    Containsnumbers: "Contains Numbers",
    Emptysgement: "Empty Sgement",
    Morethanrows: "More Than Rows",
    Morethancharssinglelength: "More Than Chars (single length)",
    Durationlessthan: "Duration Less Than",
    Morethancharstotallength: "More Than Chars (total length)",
    uploadwarningmessage1: "Only accept .zip file",
    uploadwarningmessage3: "Only accept .srt file",
    uploadwarningmessage2: "File size is limited to 100MB",
    uploadwarningmessage4: "Please upload file",
    confirm: "Save",
    Importoptions1: "File Content",
    srtText: "Lines",
    srtText1: "Characters",
    srtText2: "Time",
    srtText3: "Split Options",
    srtText4: "Number of equal parts",
    srtText5: "Subtitle info",
    srtText6: "Number of lines: ",
    srtText7: "Number of characters: ",
    srtText8: "Upload file",
    srtText9: "Preview",
    srtText10: "File name",
    srtText11: "Lines",
    srtText12: "Start time",
    srtText13: "End time",
    srtText14: "Clear",
    srtText15: "Files already have correct time codes",
    srtText16: "Add end time of previous file",
    srtText17: "Add milliseconds after each file",
    ASS: "ASS template",
    ASS1: "Please enter the ASS template",
    ASS2: "ASS template content",
    Excel: "Upload Excel File",
    name16Text: "Source language ",
    name16Text1: "Target language ",
    name16Text2: "Glossary ",
    name16Text3:
      "Maximum number of characters in a single sentence of translation subtitles ",
    name16Text4: "Translation symbol Check (optional) ",
    name16Text5: "Share Check tool ",
    name16Text6:
      "The maximum number of characters in a single sentence of translation subtitles cannot be empty ",
    name16Text7: "Translation symbol check ",
    name16Text8: "Upload source language file ",
    name16Text9: "Upload target language file ",
    name16Text10: "Shared link copied successfully ",
    name16Text11: "zip format only ",
    name16Text12: "Click to select or drag file here ",
    name16Text13: "File size ",
    name16Text14: "inspect",
    name16Text15: "srt format only ",
    name16Text16: "Remove formatting and positioning information.",
    name16Text17: "以源语言条目为准",
    name16Text18: "以目标语言条目为准",
    name16Text19: "导出doc文档",
    name16Text20: "导出检查报告",
    name16Text21: "拆行不当错误",
    name16Text22: "一致性错误",
    name19Text: "The on-screen text content that needs to be ignored",
    name19Text1: "画面字括号类型",
    name19Text2: "方括号",
    name19Text3: "半角圆括号",
    name19Text4: "全角圆括号",
    uploadwarningmessage5: "只能上传.mp3格式",
  },
  AppointedTask: {
    Taskprocessor: "转写任务处理人",
  },
  //音频转写
  audio: {
    ShowSpeakers: "Speaker",
    ShowTimecode: "Time",
    Download: "Download",
    popper_speakers_tip1: "Modify ",
    popper_speakers_tip2: " as",
    popper_speakers_tip3: "Pleas enter the name for speaker",
    popper_speakers_tip4: "Named As",
    DoubleSpeed: "Speed",
    confirm: "Update",
    VideoBackText: "Backward 5 secs",
    VideoForwardText: "Forward 5 secs",
    AllUpdate: "Update All",
    speakersInputTip: "The speaker name cannot be empty",
    jobNameInputTip: "Please enter the content",
    NoSegmentedTip: "One Pragragh",
    jobNameInputTip1: "Modify task name failed",
    setJobRoleTip1: "Add role failed",
    still_loading: "In process, please wait...",
    downloadText: "Preview",
    downloadText1: "Content Preview",
    cancel: "Cancel",
    revocation: "Undo",
    revivification: "Redo",
  },
  //AI配音
  dubbing: {
    input_placeholder: "Please enter text",
    help_center: "Help",
    create_new_file: "Create File",
    number: "Digits",
    tts_number_tip: "Select digits before set the pronunciation",
    annotation: "Polyphone",
    tts_annotation_tip: "Select word before set the pronunciation",
    alias: "Alias",
    tts_alias_tip: "Select sentence before set the pronunciation",
    continuity: "Liaison",
    tts_continuity_tip: "Select multiple words before set the liaison",
    pause: "Pause",
    tts_pause_tip: "Place the cursor before set the pause",
    intonation: "Sound",
    tts_anchor: "Multi-voice",
    tts_anchor_tip: "Select sentence before set different voice",
    background_music: "Background Music",
    system_prefabrication: "In-system",
    volume: "Volume",
    confirm: "OK",
    auto_upload: "Upload New",
    audition: "Try",
    continue: "Continue",
    suspend: "Pause",
    reset: "Reset",
    generating: "In process...",
    number_of_words: "Characters: ",
    price: "Price: ",
    save_draft: "Save Draft",
    confirm_dubbing: "Dubbing",
    download_audio: "Download Audio",
    download_subtitles: "Download Subtitles",
    bgm_tip: "Please select background music",
    add_to: "Add",
    please_select: "Please select",
    tts_number_tip1: "Please select the digits",
    tts_pause_tip1: "Do not select text",
    tts_number_cardinal: "Pronounce as digits (.)",
    tts_number_digits: "Pronounce as digits (,)",
    tts_number_telephone: "Pronounce as phone number",
    tts_number_date: "Pronounce as a date",
    tts_number_time: "Pronounce as a time",
    tts_number_currency: "Pronounce as currency amount",
    selected_content: "Selected content",
    alias_replacement: "Alias replacement",
    replace_single: "Replace single",
    replace_all: "Replace all",
    replace_tip: "It cannot be empty",
    auto: "Custom",
    still_confirmation: "Still confirming",
    download_subtitle_tip:
      "Please confirm the dubbing before downloading the subtitle",
    download_subtitle_tip1:
      "Dubbing in progress, please wait before downloading the subtitle",
    download_audio_tip:
      "The audio can be downloaded after the dubbing complete",
    download_audio_tip1: "Dubbing is in process, please download audio later",
    download_audio_tip2: "Download failed, permission error",
    download_audio_tip3: "Download failed",
    pause_input_tip: "The custom content cannot be empty",
    try_play_tip: "The audio cannot be played while editing text",
    try_play_tip1: "The audio play failed",
    try_play_tip2: "Do not click on the editing area when playing the audio",
    try_play_tip3: "The audio play failed, please try again",
    paste_tip: "You cannot paste when text is selected",
    revocation: "Undo",
    revivification: "Redo",
    createDubFile: "Create dubbing file",
    currentFree: "Currently Free",
    uploadText1: "Click to upload",
    clearText: "Delete",
    styleDefault: "Default",
    styleAge: "Style",
    styleMood: "Emotion",
    myCollection: "My Favorites",
    roleCollection: "Saved Roles",
    readingSpeed: "Speed",
    anchorTone: "Tone",
    anchorVolume: "Volume",
    save: "Save",
    wordOfAuditions: "Remaining Characters:",
    numberOfAuditions: "Remaining Trials:",
    todayDubbingNum: "Free dubbing quota (up to 1000 characters):",
    totalDownloads: "Total number of downloads:",
    numberOfTimes: "times",
    title1: "Information",
    ToBuy: "Go to Purchase",
    dialogContent:
      "You have insufficient trial listening attempts left, please purchase to retry",
    dialogContent1:
      "You have insufficient dubbing attempts left, please purchase to retry",
    dubbingTip: "Selected content error",
    dubbingTip1: "Cross-selection is not supported",
    pause_input_tip1: "Characters exceed the limit, editing failed",
    pause_input_tip2:
      "Pasting content or pressing enter within tags is not supported",
    try_play_tip4:
      "Current audio playback is invalid, please try listening again",
    upload_bgm_tip: "Please upload an audio file",
    upload_bgm_tip1: "Uploaded audio file should be less than 20MB",
    try_play_tip5:
      "Trial listening failed, please enter content before proceeding",
    try_play_tip6:
      "Confirmation of dubbing failed, please enter content before proceeding",
    prev_confirm_time: "Last confirmation time:",
    notice_title: "Usage Notice",
    notice_go_on: "Continue",
    notice_buy: "Discounts on Card Purchase",
    noticeContent1:
      "Before you start dubbing, please carefully read the following:",
    noticeContent2:
      "1. WiiMedia's AI text-to-speech service is charged by character count, 2 yuan per thousand characters (more savings with a character card), half price for less than five hundred characters, and one thousand character rate for over five hundred but less than one thousand characters.",
    noticeContent3:
      "2. Charges include the selection of standard/advanced voice actors, foreign and minority language voice actors, all fine-tuning features, free sound effects, free background music, confirmation of dubbing, and downloads.",
    noticeContent4:
      "3. For users without a character card, a single project can be trial listened 20 times, with a 200-character limit per trial. You can place the cursor after the last character of the truncated trial listening to continue listening to the subsequent content.",
    confirm_dubbing_tip: "Your character balance is insufficient by ",
    confirm_dubbing_tip1: ", please purchase a character card to continue.",
    empty_text: "No data available",
    dubText: "Save failed",
    dubText1: "Please enter digits",
    dubText2: "Pause duration can be up to 5000ms",
    dubText3:
      "Your text content has been edited, do you want to save the document before creating a new file?",
    dubText4: "Information",
    dubText5: "Don't Save",
    dubText6: "The system may not save your changes.",
    dubText7: "Cross-selection is not supported",
    payText: "AI Text-to-Speech",
    payText1: "Text-to-Speech Character Card for 70,000 characters",
    payText2: "Character count",
    payText3: "AI Text-to-Speech",
    anchorsText: "Anchor Settings",
    anchorsText3: "Language",
    anchorsText4: "Please select language",
    All: "All",
    anchorsText5: "Sex",
    anchorsText6: "Please select sex",
    anchorsText7: "Nickname",
    anchorsText8: "Enter the nickname to search",
    anchorsText9: "Male",
    anchorsText10: "Female",
    anchorsText11: "Boy",
    anchorsText12: "Girl",
    setAnchorstitle1: "Chinese (Mandarin)", //new
    setAnchorstitle2: "Chinese (Dialects)", //new
    setAnchorstitle3: "English", //new
    setAnchorstitle4: "Foreign Languages", //new
    setAnchorstitle5: "Others", //new
    bgm_text1: "Epic",
    bgm_text2: "Horror",
    bgm_text3: "Cheerful",
    bgm_text4: "Romantic",
    sound_text1: "Crow", //from table dict_sound, number corresponds to sound_id
    sound_text2: "Sword Clashing Sounds",
    sound_text3: "Noise",
    sound_text4: "Shooting",
    sound_text5: "Heartbeat",
    sound_text6: "Suspense",
    sound_text7: "Phone Vibration",
    sound_text8: "Applause",
    sound_text9: "Unsheathe Sword",
    sound_text10: "Disconnected Dial",
    sound_text11: "Sword Swinging",
    sound_text12: "Sword Swinging 2",
    sound_text13: "Knocking on Door",
    sound_text14: "Typing on a Standard Keyboard",
    sound_text15: "Faucet",
    sound_text16: "Water Dripping",
    sound_text17: "Match Strike",
    sound_text18: "Rocket Launcher",
    sound_text19: "Artillery Fire",
    sound_text20: "Explosion",
    sound_text21: "Cat Meowing",
    sound_text22: "Flipping Pages",
    sound_text23: "Cicada Sound",
    sound_text24: "Walking Up Stairs",
    sound_text25: "Running",
    sound_text26: "Clock Hands",
    sound_text27: "Clock Ticking",
    sound_text28: "Piano Accent Note",
    sound_text29: "Dart",
    sound_text30: "Ghost Laughing",
  },
  comic: {
    close: "Close",
  },
  message: {
    refuseTip: "Rejected joining the team, the page will refresh in 2 seconds",
    refuseTitle:
      "Are you sure you want to reject the invitation to join the team?",
    agreeTip: "Agreed to join the team, the page will refresh in 2 seconds",
    agreeTitle: "Are you sure you want to join the team?",
    title1: "Information",
    refuse: "Reject",
    cancel: "Cancel",
    accept: "Accept",
    renew: "Renew",
    confirm: "Confirm",
    title: "Messages",
  },
  // 媒体 操作页 转写环节
  OverwriteOperation: {
    teamTips: "Open the online glossary",
    OCR: "Online OCR",
    ErrorTypes: "Error Types",
    switchText: "Filter unreviewed sentences",
    switchText1: "After turning on, only display unreviewed sentences",
    switchText2: "There are",
    switchText3: "unconfirmed sentences, do you confirm to submit?",
    switchText4: "Content is consistent",
    switchText5: "Content is inconsistent",
    task: "Task",
    moreConcise: "More Concise",
    moreConcise_tooltip:
      "Make the current English sentence more concise and with less characters, but no changes to its meaning",
    MoreColloquial: "More Colloquial",
    MoreColloquial_tooltip:
      "Make the current English sentence sound more colloquial to target audience and avoid formal words or complex sentence structure, but no changes to its meaning",
    MoreWritten: "More Formal",
    MoreWritten_tooltip:
      "Make the current English sentence sound more formal to target audience and avoid colloquial words or phrases, but no changes to its meaning",
    TimeRange: "Timecode",
    Duration: "Duration",
    Subtitle: "Subtitle",
    Char: "Char",
    Speed: "Speed",
    Segment: "Segment",
    TransSegment: "For Translation",
    ReviewSegment: "For Review",
    TMAndTB: "TM&TB",
    TMSearch: "TM Search",
    FindAndReplace: "Find&Replace",
    Find: "Find",
    Warnings: "Warnings",
    VerifyOptions: "Verify Options",
    Verify: "Global Verify",
    EmptySegment: "Empty Segments",
    SubtitleDuration: "Subtitle Duration",
    CharacterLimitation: "Character Limitation",
    ReadingSpeed: "Reading Speed",
    SpellingAndGrammarCheck: "Spelling and Grammar Check",
    Forbiddencharacter: "Forbidden Characters",
    TimeCodesOverlap: "Timecodes Overlap",
    MiniGap: "Mini Gap",
    DoubleBlank: "Double Spaces",
    StyleConsistency: "Style Consistency",
    StyleAccuracy: "Style Accuracy",
    MissingNumber: "Numbers Mismatch",
    InconsistentTranslation: "Inconsistent Translation",
    TermCheck: "Terminology Check",
    tooltipContent: "Playback",
    tooltipContent1: "ASR",
    tooltipContent2: "Insert",
    tooltipContent3: "Merge",
    tooltipContent4: "Split",
    tooltipContent5: "Delete",
    tooltipContent6: "Go to Next Comment",
    tooltipContent7: "Go to",
    tooltipContent9: "Import Subtitle",
    tooltipContent10: "Adjust Mini Gap",
    tooltipContent11: "AI Translation",
    tooltipContent12: "Delete Translation",
    tooltipContent13: "Insert Selected Segments into TM",
    tooltipContent14: "Restore Target Text",
    tooltipContent15: "Open Media File",
    tooltipContent16: "AI Polish",
    tooltipContent17: "Identify Current Sentence",
    Type: "Comment Type",
    Play: "Play",
    Source: "Source",
    Target: "Target",
    OK: "OK",
    Current: "Current",
    All: "All",
    ZoomInTimecode: "Zoom In Timecode",
    ZoomOutTimecode: "Zoom Out Timecode",
    ZoomInAmplitude: "Zoom In Amplitude",
    ZoomOutAmplitude: "Zoom Out Amplitude",
    AdjustMiniGap: "Adjust Mini Gap",
    SourceSubtitle: "Source Subtitle",
    TargetSubtitle: "Target Subtitle",
    BilingualSubtitle: "Bilingual Subtitle",
    HideSubtitle: "Hide Subtitle",
    Displaysubtitle: "Display Subtitle",
    AjustTimecodes: "Adjust Timecode",
    UndoAdjustment: "Undo Timecode Update",
    Help: "Help",
    Backward: "Backward",
    Forward: "Forward",
    DownloadTargetSubtitleFile: "Download Target File in .docx",
    DownloadSourceSubtitleFile: "Download Source File in .docx",
    DownloadSubtitleFile: "Download Target File in .docx",
    DownloadSubtitleFileexcel: "Download Target File in .xlsx",
    TimetoAdd: "Time to Add",
    Shiftfrom: "Shift from",
    Alllines: "All lines",
    Selectedlinesonly: "Selected lines only",
    Alllinesbeforeselectedline: "All lines before selected line (including)",
    Alllinesafterselectedline: "All lines after selected line (including)",
    Alllinesafterselectedline1: "All lines after selected line (including)",
    lookup: "Find",
    placeholder: "Segment/Timecode",
    placeholder1: "Please enter text...",
    Matchcase: "Match Case",
    Wholewordmatching: "Match whole word",
    Replacewith: "Replace with",
    Command: "Command",
    Shortcut: "Shortcut",
    PlayPause: "Play&Pause",
    GotoPreviousSegment: "Go to Previous Segment",
    GotoNextSegment: "Go to Next Segment",
    LookPlayback: "Playback",
    Delete: "Delete",
    MT: "AI Translation",
    CheckTarget: "Global Verify",
    Searchsource: "Search source...",
    Searchsource1: "Search for Team Member",
    closebtn: "Check Warnings",
    closebtn1: "Ignore Warnings",
    TargetCheck: "Verify Target Only",
    SourceCheck: "Verify Source Only",
    CheckOptions: "Check Options",
    Checklist: "Checklist",
    warningmessage1: "Please enter the correct format time",
    warningmessage2: "The last sentence segment cannot be merged",
    warningmessage3: "Are you sure you want to delete this comment?",
    warningmessage4: "Please enter the time",
    warningmessage5: "Please enter content",
    warningmessage6: "Do you want to submit this task?",
    warningmessage7: "Please select a sentence segment",
    warningmessage8: "Are you sure you want to undo the last timecode update?",
    warningmessage9: "The segment does not exist",
    warningmessage10: "Waveform loading failed",
    warningmessage11:
      "There are warnings and please make necessary adjustment before submit.",
    warningmessage12: "Are you sure you want to submit this task?",
    warningmessage13:
      "Cannot submit this task now. target in one or more segments is empty.",
    warningmessage14: "No data",
    warningmessage15: "Enter segment number",
    warningmessage16:
      "Cannot submit this task now. source in one or more segments is empty.",
    warningmessage17:
      "Are you sure you want to restore the data to an earlier version before adjusting timecode?",
    warningmessage18:
      "Are you sure you want to restore the data to an earlier version before adjusting mini gap?",
    warningmessage19:
      "Are you sure you want to restore the data to an earlier version before importing subtitle?",
    warningmessage20: "Enter specific time",
    warningmessage21: "The searched sentence segment does not exist",
    findTips1: "What you search for should not be empty.",
    findTips2: "Errors in segments.",
    findTips3: "Search is completed. Click to start again.",
    findTips4: "No matches found",
    successmessage1: "Batch insertion successful",
    Confirmed: "Reviewed",
    Confirmedtips: "The translation has been reviewed and approved.",
    Preference: "Preference",
    Preferencetips:
      "The original translation is of no error, yet still suggested to be changed into the current one for perfection purpose.",
    SG: "Compliance",
    SGtips:
      "Translation not conforming to Style Guide (SG), Term List or any other rules; or with careless mistakes, including typos, spelling errors, punctuation errors, capitalization problems, grammar mistakes, etc.",
    Accuracy: "Accuracy",
    Accuracytips:
      "Wrong understanding of the source text, translation not correctly conveying the meaning of the source text, or missing or redundant translation.",
    Language: "Language",
    Languagetips:
      "Awkward translation that is unreadable, ambiguous, or does not sound native to local people in the target market.",
    Typo: "Typo",
    Typotips:
      "Careless mistakes, including typos, spelling errors, punctuation errors, capitalization problems, grammar mistakes, etc.",
    Other: "Others",
    Othertips: "Other types of translation errors",
    Withcomments: "With comments",
    SetErrorSeverity: "QA Rating",
    SetErrorSeverity1: "Add Comments",
    SetErrorSeverity2: "You can enter some comments here.",
    proofurlitem: "Genderless",
    proofurlitem1: "None",
    proofurlitem2: "Male",
    proofurlitem3: "Female",
    proofurlitem4: "Set Gender",
    DownloadSubtitleSrtFile: "Download Target File in .srt",
    warningmessage22:
      "A maximum of ten sentences can be selected for batch merging",
    title_p1: "Transcribing",
    title_p2: "Translating",
    title_p3: "Reviewing",
    title_p4: "校轴中",
    verif_text1: "您正在打开", //您正在打开 xxx 分享的任务，请登录或输入密码查看
    verif_text2: "分享的任务，请",
    verif_text3: "或输入密码查看",
    verif_text4: "请输入密码",
    verif_text5: "登录",
    verif_text6: "Passcode",
    verif_text7: "任务流程密码验证成功",
    edit_comment: "Remarks on overall quality",
    submit_task: "Submit task",
    is_tm: "Upload/update unconfirmed segments into TM",
    tooltipContent18: "快捷添加术语",
    tooltipContent19: "时间段硬字幕识别",
    ocrLoadingMessage: "字幕识别中...",
    successmessage2: "快速添加术语成功",
    warningmessage23:
      "存在未确认句段，无法提交。请先完成所有句段的校审和确认，或者关闭未校审句段筛选 ",
    Category: "Category",
    transInfo: "翻译人员信息",
    fontSize: "字体大小",
    letterSpacing: "文字间距",
    tooltipContent20: "Mini Duration",
    UI_text: "布局",
    UI_text1: "经典",
    UI_text2: "创意",
    UI_text3: "Layout transformation",
    hua_text: "仅提取画面字内容",
    warningmessage25: "批量转写最多支持选择十个句段",
    warningmessage26: "批量ASR转写时不允许句段处于编辑状态",
    STQErrorType1: "Reviewed",
    STQErrorType1_desc: "The segment has been reviewed and approved.",
    STQErrorType2: "Timecode",
    STQErrorType2_desc:
      "Timecode redundant, missing, not synchronized with the video, nonconforming duration, reading speed, or overlap, etc.",
    STQErrorType3: "Content",
    STQErrorType3_desc:
      "Text redundant, missing, typos, spelling mistakes, grammar errors, not synchronized with the video, etc.",
    STQErrorType4: "Style",
    STQErrorType4_desc:
      "Nonconforming punctuations, usage of space, usage of various symbols and marks, and many other styles specified in Style Guide or client’s requirements",
    STQErrorType5: "Others",
    STQErrorType5_desc: "Other types of errors",
    newSource: "New Source",
    STQSegment: "For STQ",
    switchText6: "Filter unchecked sentences",
    warningmessage27: "Please select the start and end time points.",
    STTInfo: "转写人员信息",
    tooltipContent21: "术语备注",
    termSendTip: "将术语发送至译文",
    warningmessage24: "原文和译文不能为空",
    successmessage3: "编辑术语成功",
    warningmessage28: "开始时间与上句结束时间有交叉",
    warningmessage29: "结束时间与下句开始时间有交叉",
    warningmessage30: "句段时间超出拆分任务边界",
    warningmessage31: "参数非法",
    UI_text4: "Waveform",
    warningmessage32:
      "The imported character count exceeds the maximum limit of 10000",
    switchText7: "Show Subtitle Area",
    switchText8: "Hide Subtitle Area",
    switchText9: "显示字幕区域",
    switchText10: "预览已涂抹视频",
    successmessage4: "已复制当前时间",
    warningmessage33: "请先复制当前时间",
    warningmessage34: "确定要还原数据到上一个批量修改句段样式操作前吗？",
    switchText11: "自由拖动字幕",
    TimelineExceedsVideoDuration: "时间轴超出视频时长",
  },
  name: {
    Projects: "Projects",
    Members: "Members",
    TranslationMemory: "TM",
    Glossary: "Glossary",
    Tools: "Tools",
  },
  // 视频配音操作页
  VideoDubbing: {
    marginView: "Balance",
    residueText1: "Minutes",
    residueText2: "Trials",
    residueText3: "Remaining dubbing opportunities: ",
    residueText4: "",
    residueText5: "",
    dubText: "Dubbing",
    dubText1: "",
    download: "Download",
    downloadText1: "Subtitle .srt",
    downloadText2: "Role Lines .excel",
    downloadText_3: "Batch Replace SRT",
    downloadText_4: "Batch Export Error Sentence Segment Information",
    downloadText_5: "Batch Subtitle Alignment",
    downloadText_6: "批量生成配音文件",
    downloadText_7: "批量生成混音文件",
    roleText0: "Adjust reading speed for all audio clips",
    roleText1: "Adjust speed for this role",
    roleText2: "Speed",
    speed_tip1:
      "The minimum single adjustment for character speech rate is 0.5 times the current value.",
    cancel: "Cancel",
    apply: "Apply",
    roleText3: "Match the lines for this role",
    roleText4: "Dub all lines for this role",
    roleText5: "Delete",
    roleText6: "Add to Favorites",
    roleText7: "Add Role",
    roleText8: "Existing Roles",
    roleText9: "Collapse",
    roleText10: "Role name cannot be empty",
    HideSubtitle: "Hide subtitle",
    Displaysubtitle: "Display subtitle",
    SourceSubtitle: "Display source",
    TargetSubtitle: "Display target",
    BilingualSubtitle: "Display both",
    SingleDubbing: "Dub current segment",
    speed_tip: "Speed warning（speed is less than x0.8 or greater than x1.3）",
    loadingSingleDubbing: "Dubbing in process...",
    loadingDelete: "Deleting...",
    videoTimeAlignment: "Alignment in process...",
    noticeContent1:
      "Before you start dubbing, please carefully read the following content:",
    noticeContent2:
      "1. WiiMedia's AI video dubbing service is charged by the minute, at a rate of 2 yuan per minute (discounts available with the purchase of a duration card), and any portion of a minute is charged as a full minute.",
    noticeContent3:
      "2. Fees include the choice of ordinary/advanced dubbing artists, foreign languages and minority languages function, all fine-tuning features, confirmation of dubbing, and downloading.",
    noticeContent4:
      "3. Users who have not purchased a duration card can have up to 20 free trial listens for a single project.",
    dialogContent:
      "You have insufficient trial/single-line dubbing opportunities remaining, please purchase more before retrying.",
    Segment: "Segments",
    tooltipContent3: "Merge",
    tooltipContent4: "Split",
    tooltipContent5: "Delete",
    tooltipContent6: "Reuse Roles",
    tooltipContent7: "Speaker recognition",
    warningmessage2: "Merge cannot be done because it's the last segment",
    loadingmessage: "Loading...",
    warningmessage3: "You can create up to 50 roles",
    segmentText: "Timecode",
    segmentText1: "Duration",
    segmentText2: "Role: ",
    segmentText3: "Lines",
    segmentText4: "Speed",
    toolText1:
      "Auto Align: WiiMedia automatically adjusts the speed of audio clips to align them as much as possible within the same track.",
    toolText2:
      "Align Left: After selecting multiple consecutive audio clips, Wiimedia automatically shift the selected audio clips to the left (the leftmost one remains unchanged) to eliminate any gaps",
    toolText3:
      "Align Right: After selecting multiple consecutive audio clips, Wiimedia automatically shift the selected audio clips to the right (the rightmost one remains unchanged) to eliminate any gaps",
    toolText4:
      "Subtitle Align: WiiMedia automatically adjusts the time code to match the start and end times of audio clips.",
    toolText5: "Delete selected clips",
    toolText6: "Help",
    toolText7: "Undo",
    toolText8: "Log",
    speakerText: "Speaker recognition",
    speakerText1:
      "The speaker recognition feature only supports videos in Chinese contexts and is not yet available for other languages. After recognition, a new role will be added, and the role information within the sentence segments will be replaced.",
    speakerText2:
      "Please enter a number between 1-10 to specify the number of speakers to be recognized; 0 means automatic recognition of the number of speakers. Entering the accurate number will improve the accuracy of recognition.",
    dubText2:
      "Begin the trial listening, please do not click on the page, otherwise the trial will be stopped.",
    dubText3: "Your duration balance is insufficient ",
    dubText4: " minutes, please renew your duration card.",
    dubText5: "This line cannot be dubbed because no role assigned yet",
    dubText6:
      "Please confirm whether to dub the sentence segments related to the already associated roles?",
    comfirmTips1: "Information",
    comfirmTips2: "OK",
    comfirmTips3: "Cancel",
    loadingDubbing: "Dubbing in progress...",
    comfirmTips4: "Are you sure you want to delete it?",
    dubText7: "Please select at least 2 clips",
    dubText8: "to left",
    dubText9: "to right",
    dubText10: "Are you sure you want to align the selected clips ", // 向左/向右
    dubText11: "?",
    dubText12:
      "This line cannot be dubbed because you need to dub all lines first",
    dubText13: "Please select the line first",
    dubText14: "This line cannot be dubbed because it's empty",
    dubText15: "This line cannot be dubbed because no role assigned ye",
    dubText16: "Please select audio clips from the same track",
    dubText17: "Please select consecutive audio clips",
    comfirmTips5:
      "Merge/Split this line will delete current audio clip, do you want to continue?",
    comfirmTips6:
      "After deleting the role, you need to assign new role to it, do you want to continue?",
    comfirmTips7:
      "Are you sure to do the dubbing for  all segments are assigned by this role?",
    dubText18:
      "Dubbing cannot be done because the role has no lines associated.",
    dubText19: "Updated ",
    dubText20:
      " segments successfully，the page will be refreshed automatically",
    dubText21: "Automatic count of people identified as incorrect",
    dubText22: "It cannot play because the segment has no role assigned",
    loadingDubbing1: "Dubbing in process...",
    loadingDubbing2: "Adjusting the reading speed...",
    loadingDubbing3: "Generating the dubbed files...",
    loadingDubbing4: "Aligning the selected audio clips...",
    loadingDubbing5: "Parsing the file...",
    loadingDubbing6: "Speaker recognition in progress, please wait...",
    loadingDubbing7: "Retrieving the video...",
    roleText11: "Null",
    roleText12: "Fine-tune current line",
    roleText13: "Add Role",
    anchorsText: "Role Settings",
    anchorsText1: "Enter role name",
    anchorsText2: "Save As New Role",
    anchorsText3: "Language",
    anchorsText4: "Please select language",
    All: "All",
    anchorsText5: "Sex",
    anchorsText6: "Please select sex",
    anchorsText7: "Nickname",
    anchorsText8: "Enter nickname to search",
    anchorsText9: "Male",
    anchorsText10: "Female",
    anchorsText11: "Boy",
    anchorsText12: "Girl",
    segmentText5: "New Speed",
    segmentText6: "All subtitles",
    segmentText7: "This character's subtitles",
    segmentText8: "No character subtitles",
    segmentText9: "Match lines",
    segmentText10:
      "Please enter the lines for that character, matching content by individual lines and sentence segments.",
    segmentText11: "Match only non-character dialogue lines.",
    payText: "This purchase",
    payText1: "Discount Card Purchase",
    payText2: "Product",
    payText3: "AI Video Dubbing",
    payText4: "Video Dubbing Duration Card 60 minutes",
    payText5: "100 yuan",
    payText6: "Duration count",
    payText7: "minutes",
    payText8: "Discount",
    payText9: "",
    // payText9: "Enterprise account 10% off",
    payText10: "None",
    payText11: "Actual payment",
    payText12: "Validity period",
    payText13: "months",
    payText14: "Payment method",
    payText15: "WeChat QR Code Payment",
    payText16: "Alipay QR Code Payment",
    payText17: "yuan",
    comfirmTips8:
      "Are you sure you want to restore the audio clip data to its state before the last operation?",
    segmentText12: "Operations",
    segmentText13: "Operation Time",
    segmentText14: "Drag audio clip",
    segmentText15: "Drag the sides of the audio clip",
    dubText23: "There are no operations to undo",
    comfirmTips9:
      "It will align timecodes with the audio clips,<br> do you want to continue?",
    dubText24: "Adjust Speed",
    speed_tip2: "The speed cannot less than x0.5",
    downloadText3: "M&E Track",
    downloadText4: "Dubbed Track",
    downloadText5: "Mixed Track",
    downloadText6: "Dubbed Video",
    dubText25: "Saved successfully",
    toolText9: "Auto Align",
    tooltipContent10: "Play current segment",
    tooltipContent11: "Try current segment",
    dubText26: "It has no role assigned, so fine tune is not available",
    dubText27: "Role",
    tooltipContent12: "AI Translation",
    tooltipContent13: "Quick Assign",
    tooltipContent14: "Hotkey",
    tooltipContent15: "Role",
    tooltipContent16:
      "When the video is playing, press the hotkey to set the role for the current line, and the video will automatically start playing the next line.",
    tooltipContent20:
      "Or to selecte multiple lines and then use the hotkey to assign the same role to the selected lines.",
    tooltipContent23: "Advice the newbies to set the play speed at x0.5",
    segmentText16: "Source Language",
    segmentText17: "Dubbing Language",
    dubText28: "Make sure to activate the line for AI translation",
    comfirmTips10:
      "Modifying segment content will delete the audio clip, do you want to continue?",
    comfirmTips11:
      "Deleting the selected segments will delete relevant audio clips, do you want to continue?",
    dubText29: "Original Audio",
    dubText30: "M&E track is not available for this video",
    loadingDubbing8: "Generating the mixed track...",
    loadingDubbing9: "Generating the synthesized video...",
    tooltipContent17: "Video less than 5",
    tooltipContent8:
      "1. The Excel contains 4 columns, Start Time, End Time, Role Name and Voice ID.",
    tooltipContent9:
      "2. Match the start and end times from the Excel with the start and end times of all segments.",
    tooltipContent18:
      "3. If the times match (2 decimals), then assign the role to that segment.",
    tooltipContent19: "4. If they do not match, then do not assign the role.",
    dubText31:
      "There are segments that have not been dubbed yet, do you want to continue?",
    loadingDubbing10: "BGM separation is in process...",
    toolText10:
      "Auto Align: WiiMedia automatically adjusts the speed of audio clips to align them as much as possible within the same track.",
    comfirmTips12: "It will ",
    comfirmTips13: "cost ",
    comfirmTips14: " minutes for the dubbing,<br> do you want to go ahead?",
    comfirmTips15: "It will ",
    tooltipContent21: "Transcript current segment",
    tooltipContent22: "Insert",
    warningmessage1: "The searched sentence segment does not exist",
    warningmessage4: "Please enter the correct format time",
    warningmessage5:
      "The sentence fragment is not within the filtering results.",
    tooltipContent24: "Go to...",
    tooltipContent25: "Please",
    tooltipContent26: "Please enter the specific time.",
    dubText32: "This function is not available in the current mode.",
    dubText33: "This function is not available during filtering.",
    dubText34: "Full Screen",
    warningmessage6: "Paragraph content cannot be empty.",
    comfirmTips16:
      "The following characters in the table have been detected as having the same name as existing characters.",
    comfirmTips17: "Clicking continue will overwrite the existing characters.",
    continue: "Continue",
    ZoomInTimecode: "Zoom Out",
    ZoomOutTimecode: "Zoom In",
    warningmessage7: "No dubbed file to export",
    loadingDubbing11: "Generating the files...",
    downloadText7: "Track in Role",
    UploadExcelFile: "Upload file .xlsx",
    UploadOnlineRole: "Import online task roles",
    dubText35: "Task",
    dubText36: "Please select task",
    tooltipContent27: "Lock/Unlock",
    dubText37: "It cannot play because the segment is empty",
    loadingDubbing12: "AI translation is in process...",
    tooltipContent28:
      "Match assigned roles to the same video in different languages.",
    dubText38: "Change Voice",
    dubText39: "Auto Align",
    dubText40: "Subtitle Align",
    dubText41: "Download",
    loadingDubbing13: "Video encoding in progress...",
    dubText42: "Begin the trial listening",
    downloadText9: "Source Subtitle .srt",
    loadingDubbing14: "生成配音波形中，请稍后...",
    tooltipContent29: " minutes",
    loadingDubbing15: "原声提取中，请稍后...",
    comfirmTips18:
      "设置句段无角色会导致已经生成的音频块被删除, 是否继续执行操作?",
  },
  VideoDubbingClone: {
    dubText1: "Customize Voice",
    dubText2: "Voice Name",
    dubText3: "Please enter the name for voice",
    fileText: "Upload a high quality audio clip to customize",
    fileText1: "Support .wav with audio duration ≤30min and file size ≤100MB",
    dubText4: "BGM Separation",
    warningmessage1: "The audio clip should be within 1-10 minutes",
    warningmessage2: "Support .wav only",
    warningmessage3: "Please upload the file",
    warningmessage4: "This role hasn't been dubbed yet",
    dubText5: "My Favorites",
    dubText6: "Add Role",
    dubText7: "Role Settings",
    dubText8: "Clone",
    dubText9: "Edit name for this voice",
    comfirmTips1:
      "Are you sure you want to delete this voice from My Favorites?",
    dubText10: "Clone voice from a clean sample recording.",
    dubText11:
      " 1. Audio clip within 1-10 minutes in .wav format and file size up to 50MB.",
    dubText12:
      "2. The audio quality is more important than length. Ensure remove background noise as much as possible.",
    dubText13: "Unbind the voice with this role",
    dubText14: "Favorites",
    dubText15: "Select Role",
    dubText16: "voices selected ",
    warningmessage5:
      "The role for this segment hasn't been cloned yet, cannot run dubbing for it",
    dubText17: "The name is duplicated, please rename it",
    dubText18:
      "3. After customization, the voice will automatically be added to My Favorites, which can hold up to 20 voices.",
    warningmessage6: "My Favorites can hold up to 20 voices",
    dubText19: "Delete this voice",
    dubText20: "Add to My Favorites",
    warningmessage7: "The .wav file size cannot exceed 50MB",
    dubText21:
      "Your time balance is insufficient, please re-purchase a time card.",
    comfirmTips2: "It will cost ",
    comfirmTips3: "",
    comfirmTips4: " minutes for the dubbing, do you want to go ahead?",
    comfirmTips5: "It will cost ",
    comfirmTips6: " minutes for the dubbing, do you want to go ahead?",
    dubText22: "Role Name",
    dubText23: "Quick Guide",
    dubText24: "Run 'Quick Assign'",
    dubText_25: "Anchor",
    dubText_26: "Sentence Segments (Duration/min)",
    dubText_27: "Clone Role's Task",
    dubText_28: "Status",
    dubText_29: "Dubbing Successful",
    dubText_30: "Cloning Successful",
    dubText_31: "Confirm Dubbing?",
    dubText_32: "Confirm Deletion of Role?",
    dubText_33:
      "There are roles with zero sentence segments, cloning not possible",
    dubText_34: "Confirm Execution of Batch Subtitle Alignment?",
    dubText_35: "Confirm Execution of Batch Dubbing File Creation?",
    dubText_36: "Confirm Execution of Batch Mixing File Creation?",
    dubText_37: "Confirm Execution of Global Dubbing?",
    driverText1:
      "Click Add Role to name the new role. It is recommended to name only the important characters, others can be named the same. ",
    driverText2:
      "You can select one or multiple voices from My Favorites and WiiMedia will automatically create the role. By default, the role name is the same as the saved name, but you can modify the role name as needed.",
    driverText3:
      "Through here, you can upload a audio clip without background noise to customize the voice you like. Once done, it will automatically saved in My Favorites for the long-term use.",
    driverText4:
      "Assigning roles is a crucial step. Click the button to set the hotkeys 1 to 6, which means you can set hotkeys for up to 6 roles.",
    driverText5:
      "While playing the video, if the current segment matches the role corresponds to the hotkey 1, then just press 1. WiiMedia will automatically assign this role to it and then jump to the next segment to start playing. Continue pressing the hotkey corresponding to the role of current segment, and so on.",
    driverText6:
      "After assigning roles to all segments, click Dubbing button and WiiMedia will perform the dubbing for the whole video. When complete, you can fine-tune audio clips by adjusting speed and moving forward/backward the clip position accordingly. You can also obtain a better dubbing effect by splitting, merging, or editing the content of the segment.",
    driverText7:
      "If you are not satisfied with the dubbing effect of the selected voice, go to My Favorites to choose other customized or AI voice.",
    driverText8:
      "To avoid overlapping, the audio clips will appear on different tracks. You can fine-tune individual audio clip, or click Auto Align to automatically adjust the speed of all audio clips to align them as much as possible within the same track.",
    driverText9:
      "If you need to burn subtitles in the video, click Subtitle Align to automatically adjust the timecodes base on the start and end times of the corresponding audio clips.",
    driverText10:
      "WiiMedia offers a variety of materials for download. Please download as needed and wish you all the best!",
    driverText11:
      "Click Add Role to name the new role. It is recommended to name only the important characters, others can be named the same. Then click the grey area next to it to select suitable customized or AI voices accordingly.",
    driverText12:
      "You can select one or multiple voices from My Favorites and WiiMedia will automatically create the role. By default, the role name is the same as the saved name, but you can modify the role name as needed.",
    driverText13:
      "Through here, you can upload a audio clip without background noise to customize the voice you like. Once done, it will automatically saved in My Favorites for the long-term use.",
    driverText14:
      "Assigning roles is a crucial step. Click the button to set the hotkeys 1 to 6, which means you can set hotkeys for up to 6 roles.",
    driverText15:
      "While playing the video, if the current segment matches the role corresponds to the hotkey 1, then just press 1. WiiMedia will automatically assign this role to it and then jump to the next segment to start playing. Continue pressing the hotkey corresponding to the role of current segment, and so on.",
    driverText16:
      "After assigning roles to all segments, click Dubbing button and WiiMedia will perform the dubbing for the whole video. When complete, you can fine-tune audio clips by adjusting speed and moving forward/backward the clip position accordingly. You can also obtain a better dubbing effect by splitting, merging, or editing the content of the segment.",
    driverText17:
      "If you are not satisfied with the dubbing effect of the selected voice, go to My Favorites to choose other customized or AI voice.",
    driverText18:
      "To avoid overlapping, the audio clips will appear on different tracks. You can fine-tune individual audio clip, or click Auto Align to automatically adjust the speed of all audio clips to align them as much as possible within the same track.",
    driverText19:
      "If you need to burn subtitles in the video, click Subtitle Align to automatically adjust the timecodes base on the start and end times of the corresponding audio clips.",
    driverText20:
      "WiiMedia offers a variety of materials for download. Please download as needed and wish you all the best!",
    driverText21:
      "Click Add Role to name the new role. It is recommended to name only the important characters, others can be named the same. Then select suitable AI voices accordingly.",
    driverText22:
      "Assigning roles is a crucial step. Click the button to set the hotkeys 1 to 6, which means you can set hotkeys for up to 6 roles.",
    driverText23:
      "While playing the video, if the current segment matches the role corresponds to the hotkey 1, then just press 1. WiiMedia will automatically assign this role to it and then jump to the next segment to start playing. Continue pressing the hotkey corresponding to the role of current segment, and so on.",
    driverText24:
      "After assigning roles to all segments, click Dubbing button and WiiMedia will perform the dubbing for the whole video. When complete, you can fine-tune audio clips by adjusting speed and moving forward/backward the clip position accordingly. You can also obtain a better dubbing effect by splitting, merging, or editing the content of the segment.",
    driverText25:
      "If you are not satisfied with the dubbing effect of the selected voice, click here to choose other AI voice.",
    driverText26:
      "To avoid overlapping, the audio clips will appear on different tracks. You can fine-tune individual audio clip, or click Auto Align to automatically adjust the speed of all audio clips to align them as much as possible within the same track.",
    driverText27:
      "If you need to burn subtitles in the video, click Subtitle Align to automatically adjust the timecodes base on the start and end times of the corresponding audio clips.",
    driverText28:
      "WiiMedia offers a variety of materials for download. Please download as needed and wish you all the best!",
    dubText25: "Try",
    tooltipContent1:
      "It shows segment which has no audio clip, or its audio clip and the current assigned role do not match.  ",
    dubText26: "Warning",
    warningmessage8: "You need to assign voice to the role before the dubbing",
    warningmessage9:
      "You need to assign voice to those roles before the dubbing。",
    dubText27: "Lock",
    dubText28: "Unlock",
    warningmessage10: "You need to unlock the segment before the dubbing",
    warningmessage11: "The current segment is locked and cannot merge",
    warningmessage12: "The next segment is locked and cannot merge",
    warningmessage13: "The segment is locked and cannot run Quick Assign",
    warningmessage14: "The segment is locked and cannot be deleted",
    dubText29: "Project Voices",
    tabName: "Customized-voice List",
    tabName1: "Accent-cloned lList",
    dubText30: "Role created",
    dubText31: "Role saved",
    dubText32: "Minutes",
    warningmessage15:
      "The number of created or saved roles has reached limit and cannot run dubbing",
    warningmessage16:
      "The number of created or saved roles has reached limit and cannot run customization",
    dubText33: "AI Voices",
    dubText34:
      "Your remaining duration balance is insufficient, please contact the task assigner.",
    dubText35:
      "You have an insufficient number of remaining trials/single sentence dubbing opportunities, please contact the task assigner.",
    dubText36: "Done",
    dubText37: "Edit",
    dubText38: "Project Roles",
    warningmessage17:
      "You can select up to 10 sentences for voiceover at a time.",
    dubText39: "Select sentence segment for dubbing",
    warningmessage18: "Please select file first",
    roleText_gender: "Sex",
    roleText_age: "Age",
    roleText_position: "Position",
    roleText_character: "Character",
    roleText_gender_1: "Female",
    roleText_gender_2: "Male",
    roleText_age_1: "Toddler",
    roleText_age_2: "Child",
    roleText_age_3: "Young",
    roleText_age_4: "Middle-aged",
    roleText_age_5: "Senior",
    roleText_position_1: "Protagonist",
    roleText_position_2: "Supporting Role",
    roleText_position_3: "Antagonist",
    roleText_position_4: "Comic",
    roleText_position_5: "Extra",
    roleText_position_10: "Other",
    roleText_character_1: "Introverted",
    roleText_character_2: "Extroverted",
    roleText_character_3: "Humorous",
    roleText_character_4: "Serious",
    roleText_character_5: "Strong",
    roleText_character_6: "Vulnerable",
    roleText_character_10: "Other",
    dubText40: "Roles Tags",
    warningmessage19: "Each role label must be selected.",
    dubText41: "Unset",
    warningmessage20:
      "The audio clip content of the sentence segment has been copied",
    warningmessage21:
      "Do you want to paste the copied audio clip below this sentence segment?",
    warningmessage22: "Sentence segment content is empty, paste not possible",
    warningmessage23:
      "No character assigned to the sentence segment, paste not possible",
    warningmessage24:
      "Copy and paste content of the sentence segment do not match, paste not possible",
    warningmessage25:
      "Copy and paste roles of the sentence segment do not match, paste not possible",
    warningmessage26:
      "Copy and paste attempt of the same sentence segment, paste not possible",
    warningmessage27:
      "No associated audio clip for the sentence fragment, copy failed.",
    warningmessage28:
      "Clipboard has no copied sentence fragment, unable to paste.",
    loadingPasteDubbing: "Pasting...",
    dubText42: "Subtitle Style Settings",
    dubText43: "Local Smearing",
    dubText44:
      "Please hold down the left mouse button and select the area to be smeared in the video",
    dubText45: "Start Time Point",
    dubText46: "End Time Point",
    dubText47: "Save",
    dubText48: "Remove",
    dubText49: "Show Subtitle Settings",
    dubText50: "Hide Subtitle Settings",
    dubText51: "Subtitle Style Settings",
    dubText52: "Font",
    dubText53: "Reset Style",
    dubText54: "Character Size",
    dubText55: "Character Spacing",
    dubText56: "Bold",
    dubText57: "Italic",
    dubText58: "Underline",
    dubText59: "Align Left",
    dubText60: "Center Align",
    dubText61: "Align Right",
    dubText62: "Color",
    dubText63: "Background",
    dubText64: "Vertical Align",
    dubText65: "Outline",
    dubText66: "Shadow",
    dubText67:
      "Single-line subtitles aligned with the bottom of multi-line subtitles",
    dubText68:
      "Single-line subtitles aligned with the top of multi-line subtitles",
    dubText69: "Preview Smear Video",
    dubText70: "Dubbed Video with Subtitle",
    dubText71: "Erasing subtitles",
    dubText72:
      "All subtitles (top/bottom) will align uniformly at the height you select",
    warningmessage29: "Start time and end time cannot be empty",
    dubText73: "Backup name",
    dubText74:
      'This operation will perform dubbing for the selected roles and back up the current dubbing data of tasks associated with these roles. Later, when dubbing is completed, you can use the "One-Click Dubbing Restoration" feature to restore the dubbing data. Note: If the dubbing data of a task is modified, the backup for that task will be removed and restoration becomes unavailable. Only one backup of the same task type can exist under the project.',
    dubText75: "Associated tasks",
    warningmessage30: "Please enter a backup name",
    dubText76:
      "This operation will restore the dubbing data of tasks linked to this backup. Continue?",
    dubText77: "Dubbing data restored successfully",
    dubText78: "Adjust audio block volume",
    dubText79: "Audio gain value",
    dubText80: "Enter an integer between ±20",
    warningmessage31: "Please select an audio block first",
    warningmessage32: "Enter a valid gain value",
    loadingVolumeGain: "Processing volume gain...",
    dubText_38: "Confirm one-click cloning?",
    dubText81: "Yes",
    dubText82: "No",
    dubText83: "Video with Subtitle",
    dubText84:
      "Favorite file: Click 'Favorite' after selecting an audio block. Switching audio blocks or segments will clear the favorite file. Only one file can be favorited.",
    dubText85: "Preview favorite file",
    dubText86: "Retrieve favorite file",
    daubText1: "Brush settings",
    daubText2: "Undo",
    daubText3: "Import SRT",
    daubText4: "Deselect",
    dubText87: "Toggle background audio",
    dubText88: "Review mode",
    dubText89:
      "This is review mode. In this mode, operations on segments will not delete their corresponding audio blocks.",
    dubText90: "Generate dubbing waveform",
    dubText91: "Extract original audio for current segment",
    dubText92: "Extract all original audio for this role",
    dubText98: "Original audio",
    comfirmTips7:
      "This will extract original audio from all segments bound to this role. Continue?",
    warningmessage33: "Current segment is locked and cannot be extracted",
    warningmessage34:
      "No role assigned to this segment; extraction unavailable",
    warningmessage35:
      "This role is not an original audio role; extraction unavailable",
    warningmessage36:
      "This role is an original audio role; dubbing unavailable",
    tooltipContent2:
      "Check: Segments with roles but no audio blocks; overlapping audio blocks and unassigned role segments; mismatched audio blocks with role/speaker sources",
    dubText99: "勾选不压制的角色",
  },
  Help: {
    Spacebar: "Space",
    SpacebarVal: "Play&Pause",
    Gotolast: "Go to previous segment (non-edit mode)",
    Gotonext: "Go to next segment (non-edit mode)",
    SaveNext: "Save&Go to next segment (Edit mode)",
    Playcurrent: "Play current segment",
    Deleteselected: "Delete selected segments",
    MachineTranslation: "AI translation",
    Copycurrenttimestamp: "Copy playback time to shift timeline",
    Splitsegment: "Split Segment (Edit mode)",
    Creattimecodes: "Press&Hold J key to add time code while playing",
    Creattimecodes1: "Press down for start time, release for end time",
    Setstarttime:
      "Set the start time on the timeline (while a sentence segment is selected)",
    Setendtime:
      "Set the end time on the timeline (while a sentence segment is selected)",
    helpText: "Left-click",
    helpText1: "Select multiple audio clips",
    helpText2: "Select multiple segments",
    helpText3: "Delete selected audio clips",
    helpText4: "Shift selected audio clips to the left",
    helpText5: "Shift selected audio clips to the right",
    helpText6: "Move waveform left/right",
    helpText7: "Scroll up/down",
    helpText8: "Undo",
    helpText9: "Select multiple segments",
    helpText10: "Audio Clip",
    helpText11: "Not in filtered range",
    helpText12: "Fine-tuned",
    helpText13: "Audio clip does not match current assigned role",
    helpText15: "Audio clip does not match current assigned role",
    helpText14: "Default color",
    helpText16: "Copy Clip",
    helpText17: "Paste Clip",
    helpText18: "开启原音音轨",
    helpText19: "开启配音音轨",
    helpText20: "音频块关联句段内容不一致",
    helpText21: "时间轴平移",
    helpText22: "音频块与无角色句段时间重合",
  },
  ViewReport: {
    ViewReport: "View Report",
    ViewReportInfo: "View Report Info",
    SOURCE: "Source",
    Allsentencesegments: "All Segments",
    TRANSLATORSTARGET: "From Translator",
    REVIEWERSTARGET: "From Reviewer",
  },
  supplement: {
    STT: "STT",
    TRA: "TRA",
    REV: "REV",
    STQ: "STQ",
    PROOF: "PROOF",
    Type: "Type",
    Description: "Description",
  },
  TranslationLanguageChange: {
    sourcelanguage: "Source",
    title: "Title",
    description: "Description",
    translation: "Translation",
    successMessage: "Saved successfully",
  },
  SubtitleStyle: {
    style: "Style",
    italic: "Italic",
    underline: "Underline",
    Bold: "Bold",
    musicnotation: "Musical Notes",
    bracket: "Brackets",
    Fullangleparentheses: "Full-width Brackets",
    Halfangleparentheses: "Parentheses",
    squarebrackets: "Square Brackets",
    SmallMusicSymbol: "Eighth Note",
    Bigmusicsymbols: "Beamed Note",
    SmallMusicSymbolspace: "Eighth Note + space",
    Bigmusicsymbolsspace: "Beamed Note + space",
    Subtitleposition: "Alignment",
    togglecase: "Toggle Case",
    togglecase1: "To Upper Case",
    togglecase2: "Title Case",
    ClearStyle: "Remove Style",
    Up: "Top",
    Down: "Bottom",
    Left: "Left",
    Right: "Right",
    centre: "Center",
    middle: "Middle",
    Italic: "Italic",
    Underline: "Underline",
    MusicSign: "Music Sign ♪",
    MusicSign1: "Music Sign ♫",
    MusicSignwithSpace: "Music Sign with Space ♪",
    MusicSignwithSpace1: "Music Sign with Space ♫",
    text: "Activate Translation",
    text1: "Set Error Types",
    ClearStyle1: "清除特定样式",
    ClearStyle2: "清除斜体",
    ClearStyle3: "清除下划线",
    ClearStyle4: "清除粗体",
    ClearStyle5: "清除方括号",
    ClearStyle6: "清除圆括号",
    ClearStyle7: "清除音乐符号",
    ClearStyle8: "清除字幕位置",
  },
  // 个人部分 语言切换
  PersonalEndLanguage: {
    vip: "Custom services available exclusively to VIP clients.",
    moreLangth: ",Select up to 5 files",
    moreLangth1: ",Select up to 10 files",
    moreLangth2: ",Select up to 30 files",
    moreLangth3: ",Select up to 60 files",
    staging: "Desktop",
    myfile: "My Files",
    openingTime: "The start time",
    endingTime: "The end time",
    timeMessage: "The end time cannot exceed the video length.",
    timeMessage1: "Time you entered is invalid",
    ViewExamples: "View Examples",
    ViewExamples1: "Wrong",
    ViewExamples2: "Correct",
    ViewExamples3:
      "The frame edge is too close to the text, and it will affect the OCR result.",
    ViewExamples4:
      "Leave some space while ensuring the frame does not enclose any text outside the subtitles.",
    ViewExamples5: "Set Range",
    ViewExamples6: "Display Source Video",
    ViewExamples7:
      "You can set the OCR range by selecting the start and end time of the video", //2.19 新增
    PersonalCenter: "Personal Info",
    PurchaseServices: "Purchase Services",
    removeVIP: "Activate VIP",
    Feedback: "Feedback",
    Logoutoflogin: "Log Out",
    buy: "Purchase",
    enterpriseVip: "Enterprise VIP",
    Claimgiftpackage: "Claim Monthly Free Gift Pack",
    GiftPackCodeRedemption: "礼包码兑换",
    Giftpackagecode: "礼包码",
    Allservices: "All Services",
    Hardsubtitlerecognition: "Subtitle OCR",
    Hardsubtitlerecognitionillustrate: "Extract embedded subtitles from video",
    Subtitlealignment: "Subtitle Align",
    Subtitlealignmentillustrate:
      "Input video & script text</br>Output Subtitles",
    Videodubbing: "AI-voice Dubbing",
    Videodubbingillustrate: "Massive AI voices</br>Subtitling & Dubbing in One",
    Videotranscription: "Video Subtitling",
    Videotranscriptionillustrate:
      "AI converts speech to text</br>(with timecodes)",
    Videotranslation: "Video Translation",
    Videotranslationillustrate:
      "AI speech transcription</br>AI/Human translation",
    subtitling: "Subtitle Translation",
    subtitlingillustrate: "Upload video + subtitle </br>AI/Human translation",
    Audiotranscription: "Audio Transcription",
    Audiotranscriptionillustrate:
      "AI converts speech to text</br>(no timecodes)",
    ComicQuickFlip: "Comic Agile-translation",
    ComicQuickFlipillustrate:
      "Upload comic images</br>One-click for publishing",
    VideoCloningDubbing: "Accent-clone Dubbing",
    VideoCloningDubbingillustrate:
      "Accent clone</br>Subtitling & Dubbing in One",
    VideoCloningDubbingsmall: "Customized-voice Dubbing",
    VideoCloningDubbingsmallillustrate:
      "Upload voices you like</br>Subtitling & Dubbing in One",
    Textdubbing: "Text Dubbing",
    Textdubbingillustrate: "Enter text</br>AI converts text to audio",
    AIdubbing: "AI Dubbing",
    fileAddress: "YouTube VURLs",
    fileAddress1: "Please enter the video address.",
    fileAddress2: "Illegal address,please re-enter",
    fileAddress3: "Upload Video",
    AIdubbingillustrate: "AI Text Dubbing</br>AI Video Dubbing",
    Comingsoonstaytuned: "Coming soon, stay tuned",
    RecentFiles: "Recent Files",
    Processing: "In processing...",
    Viewmore: "View more...",
    NoData: "No data available",
    uploadvideo: "Upload Video",
    uploadvideo1: "Zip Upload",
    Reupload: "Reupload",
    uploadImage: "Upload Image",
    DragVideo: "Click to select or drag the video here",
    DragVideo1: "Click to select or drag the file here",
    DragVideo2: "Click to select or drag the audio here",
    SupportsMP4formatvideo: "Supports mp4 format, video",
    fileText: " Supports mp3, wav, aac formats, media length ≤",
    fileText1: "Supports jpg, jpeg, png, zip formats",
    fileText2:
      "Single image width and height dimensions not exceeding 4001*10001 \n file size not exceeding",
    Transcriptionmethod: "Transcription Options",
    Transcriptionmethod1: "AI Automatic Speech Transcription",
    Transcriptionmethod2: "Upload Non-Precise Subtitle File",
    Transcriptionmethod3: "Source Language",
    Transcriptionmethod4: "Source Language",
    Transcriptionmethod5: "Text Orientation",
    Transcriptionmethod6: "Horizontal",
    Transcriptionmethod7: "Vertical",
    Transcriptionmethod8: "Subtitles need to be translated",
    Freegiftpackage: "Claim Successful",
    Freegiftpackage1:
      "Thank you for using WiiMedia, your monthly free gift pack has been credited.",
    Freegiftpackage2: "Valid Until ",
    Freegiftpackage3: "Monthly Free Gift Pack",
    Freegiftpackage4: "Translation & Production Package (10 minutes)",
    Freegiftpackage5:
      "Available features: Audio/Video Transcription, Machine Translation, Subtitle Adjustment, Transcription, Segment Cutting, Axis Adjustment, Hard Subtitle Recognition, Subtitle Alignment, Subtitle/Audio Download.",
    Freegiftpackage6: "Text-to-Speech Package (10 Trial Listens)",
    Freegiftpackage7:
      "Available features: Use Basic and Advanced (limited-free) AI Voice Actors, All Text Fine-Tuning Functions.",
    Freegiftpackage8: "Video Dubbing Package (10 Trial Listens)",
    Freegiftpackage9:
      "Available features: Use Basic and Advanced (limited-free) AI Voice Actors, All Video Fine-Tuning Functions.",
    Freegiftpackage10: "Comic Translation & Production Package (10 Pages)",
    Freegiftpackage11:
      "Customized Comic OCR, Brush, Image Batch Processing, Automatic Error Checking, Professional CAT Translation Tools.",
    Freegiftpackage12: "Start Experiencing",
    duration: "media length",
    minute: " minutes",
    uploadFile: "Upload File",
    uploadFile1: " .srt, .ass",
    uploadFile2: " .srt",
    uploadFilep: "WiiMedia runs auto transcription if no subtitles",
    upload: "Upload",
    SRTLanguage: "Language for .srt",
    PleaseselectSRTlanguage: "Select language",
    PleaseselectSRTlanguage1: "Select language", //2.19 新增
    batchupload: "Batch Upload",
    batchuploadSRT: "Batch Upload (SRT)",
    videoTitle: "Video Name",
    Youtubevideo: "YouTube Video",
    Youtubevideo1: "YouTube URLs",
    Youtubevideo2: "Add up to 5 videos",
    Youtubevideo3: "Please enter the correct address.",
    youtubeTitle: "Task Name",
    youtubeTitle1: "YouTube URL",
    youtubeTitle2: "Task name not filled in",
    youtubeTitle3: "YouTube video address not filled in",
    SRTfile: "Upload .srt for Dubbing",
    SRTLanguage1: "Language for Dubbing",
    Selectuploadedvideo: "Select uploaded videos",
    gobuy: "Go Buy",
    Teammode: "Teams",
    messages1:
      "Do not upload more than 10 videos at once, mp4 format supported, video ≤1GB, duration ≤ 120 minutes",
    messages2:
      "Insufficient remaining duration of subtitle translation package under your account, unable to create task",
    messages3: "Insufficient trial listens to create task",
    messages4: "Insufficient dubbing attempts to create task",
    messages5: "Insufficient number of anime pages to create task",
    messages6: "Insufficient available space to create task",
    warningmessage: "Please upload the file",
    warningmessage1: "Please select the language of the video",
    warningmessage2: "Please upload the srt file",
    warningmessage3: "There are unselected languages",
    warningmessage4: "The source and target languages cannot be the same",
    warningmessage5: "Please select the language of the audio",
    warningmessage6: "AI Dubbing Options",
    warningmessage7: "Only .mp4 format can be uploaded",
    warningmessage8:
      "Single video upload size should be less than or equal to 1GB",
    warningmessage9:
      "Single video upload duration should be less than 120 minutes",
    warningmessage10: "Insufficient remaining space, please re-select the file",
    warningmessage11: "Exceeds maximum upload limit",
    warningmessage12: "Only .mp3, .wav, .aac formats can be uploaded",
    warningmessage13: "Uploaded audio duration should be less than",
    warningmessage14: "Only .jpg, .jpeg, .png, .zip formats can be uploaded",
    warningmessage15: "Zip file size should be less than",
    warningmessage16: "Uploaded image file size should be less than 50MB",
    warningmessage17:
      "Single image width and height dimensions should not exceed 4001*10001",
    warningmessage18: "Uploaded video size should be less than or equal to",
    warningmessage19: "Uploaded video duration should be less than",
    warningmessage20: "Only .txt files can be uploaded",
    warningmessage21: "Only .srt files can be uploaded",
    warningmessage22: "Only .srt and .ass files can be uploaded",
    warningmessage23:
      "The uploaded srt file duration is longer than the video duration, please replace and re-upload",
    warningmessage24: "Only .jpg, .jpeg, .png formats can be uploaded",
    warningmessage25: "Uploaded file size should be less than 2MB",
    warningmessage26: "Feedback has been submitted",
    warningmessage27: "Please enter your suggestions or comments",
    warningmessage28: "请正确输入礼包码",
    warningmessage29: "请输入礼包码",
    warningmessage30: "Please select the target language",
    warningmessage34: "Please select the source language",
    warningmessage31: "Please select the language of the video",
    warningmessage32: "Upload Script",
    warningmessage33: "Support only in .txt",
    warningmessage35:
      "Please highlight the subtitle range in the video, and ensure that every sentence of the subtitle is within the recognition frame. The top and bottom borders should not be too close to the text. Leaving some blank space on both sides can help to improve the accuracy of recognition.",
    warningmessage36: "The file name is too long.",
    warningmessage37: "Please upload a txt file",
    warningmessage38:
      " 1.Ensure one subtitle script per line \n" +
      "                 2.The susbtitle script has to be correct content and in sequence ",
    warningmessage39: "Please select the dubbing language",
    errormessage: "Creation failed",
    errormessage1: "An error occurred while parsing the file, please re-upload",
    successMessage: "Created successfully",
    successMessage1: "Logged out successfully",
    loadingMessage:
      "The file has been uploaded and is currently being parsed...",
    inputMessage:
      "Please enter your suggestions or opinions, we will continuously optimize our services based on your feedback.",
    VideoDubbingText: "Language for Dubbing",
    VideoDubbingText2: "Upload .srt for dubbing",
    VideoDubbingText3: "No .srt for dubbing",
    VideoDubbingText4: "Upload .srt file",
    VideoDubbingText5: "Upload source language .srt",
    VideoDubbingText6: "Upload .srt",
    VideoDubbingText7: "WiiMedia runs AI translation automatically",
    VideoDubbingText8: "No source language .srt",
    VideoDubbingText9: "Transcription",
    VideoDubbingText10: "Subtitle OCR",
    VideoDubbingText11: "Dubbing method",
    VideoDubbingText12: "Single character dubbing",
    VideoDubbingText13: "Male and female dubbing",
    VideoDubbingText14:
      "The system automatically pre-identifies male and female character sentences, with manual post-production adjustment",
    VideoDubbingText15: "Multi-character dubbing",
    VideoDubbingText16: "Number of characters",
    VideoDubbingText17:
      "The system automatically identifies multi-character sentences, with manual post-production adjustment",
    VideoDubbingText18:
      "The language for dubbing cannot be same as source language",
    youtubeText: "Add",
    youtubeText1:
      "Only dual-column Excel files are supported: Column 1 for task names and Column 2 for YouTube video URLs. Reading starts from the second row, with a maximum of 5 rows per upload.",
    youtubeText2: "Upload Excel",
    youtubeText3: "URL samples ",
    warningmessage40: "Only .wav formats can be uploaded",
    VideoDubbingText20: "Erase subtitles ",
    VideoDubbingText21: "Burn-in subtitles",
    VideoDubbingText22: "Human Voice Track, ≤200MB (Optional)",
    VideoDubbingText23: "M&E Track, ≤200MB (Optional)",
    warningmessage41: "It only supports video less than ",
    warningmessage42:
      "Insufficient remaining duration for video subtitle smear",
    warningmessage43: "File size should be less than 200MB",
    warningmessage44:
      "The voice track length is different from the video length",
    warningmessage45:
      "The voice track length is different from the video length",
    warningmessage46: "Name cannot be empty",
    AssignText1: "Save Settings",
    AssignText2: "New folder",
    warningmessage47: "The new folder name cannot be empty",
    warningmessage48:
      "If no replacement is selected, the current assignee of the following tasks will be cleared",
    warningmessage49: "Dubbing language and source language are not selected",
    warningmessage50: " minutes",
    VideoDubbingText24: "Deep Processing",
    VideoDubbingText25:
      "◆ Enhanced Results: Intelligently reduces edge traces for natural blending transitions\n◆ Time consumption note: consumes advanced smearing duration.",
    downloadText: "涂抹视频",
    warningmessage51: "涂抹视频失败，涂抹视频为空",
    successMessage2: "准备开始下载文件",
    warningmessage53:
      "Advanced video subtitle smearing has insufficient remaining duration.",
    illustrate1: "单行介绍文字不超过十六个汉字上限",
    illustrate2: "单行介绍文字不超过十六个汉字上限",
    illustrate3: "单行介绍文字不超过十六个汉字上限",
    ViewExamples8: "原片",
    ViewExamples9: "硬字幕涂抹-基础版",
    ViewExamples10: "硬字幕涂抹-高级版",
    download1: "下载字幕文件（原文）",
    download2: "下载字幕文件（译文）",
    download3: "下载字幕文件（zip）",
    moreLangth4: ",Select up to 100 files",
  },
  // 购买服务
  PurchaseServices: {
    DeepExperiencePackage: "深度体验包",
    Translationfunctionpackage: "译制功能包",
    // Translationfunctionpackageexplain: "开通企业VIP，享受9折优惠>>",
    Translationfunctionpackageexplain: "",
    TranslationfunctionpackageMessage:
      "购买后您将获得特权：音/视频转写、机翻、字幕调整、听译、切轴、调轴、硬字幕识别、字幕对齐、字幕下载",
    periodofvalidity: "有效期",
    Months: "个月",
    makeanappointment: "约",
    yuanminute: "元/分钟",
    buynow: "立即购买",
    AIdubbingpackage: "AI 视频配音包",
    AIdubbingpackageexplain:
      "购买后您将获得特权：适用于视频AI配音和声纹定制配音的所有功能",
    AIdubbingpackageexplain1: "1，一分钟视频消耗一分钟时长卡。",
    AIdubbingpackageexplain2:
      "2，服务范围包括视频字幕语音转写/硬字幕识别、AI自动翻译、音频合成等，无重复收费。",
    AIdubbingpackageexplain3:
      "**声纹定制配音，每一个账号可以免费收藏20个声纹定制主播，超出部分，收费标准为1个声纹定制主播收取100元。",
    AIdubbingpackageexplain4: "**视频克隆配音模块目前仅对VIP客户开放定制服务，",
    AIdubbingpackageexplain5: "联系客服",
    AIdubbingpackageexplain6: "获取更多支持。",
    TextdubbingCharactercards: "文本配音·字符卡",
    Validityperiod: "（有效期36个月）",
    Validityperiod1: "70,000字符",
    Validityperiod2: "约1.4元/千字符",
    ValidityperiodTitle: "文本配音·字符卡 70,000字符",
    Videodubbingdurationcard: "视频配音·时长卡",
    Videodubbingdurationcard1: "60分钟",
    Videodubbingdurationcard2: "约1.6元/分钟",
    Videodubbingdurationcard3: "视频配音·时长卡 60分钟",
    break: "折",
    ComicFlipBag: "AI 文本配音包",
    ComicFlipBag1:
      "购买后您将获得特权：使用基础及高级（限免）AI配音师，全部文本精调功能",
    average: "平均",
    only: "仅",
    Yuanpiece: "元/张",
    illustrate: "说明：",
    illustrate1: "1、付费功能包仅供同一账户一个设备使用",
    illustrate2: "1、个人版与企业VIP用户使用以上功能均需要购买付费功能包",
    illustrate3: "1、深度体验包每个账户终身只可购买一次",
    illustrate4: "2、付费功能包均自购买之日生效，并有不同有效期，超期失效",
    illustrate5: "3、如您有开票需求及其他疑问，可联系客服",
    illustrate6:
      "1、一个账号仅限一台设备登录（企业VIP用户分配任务给多个独立账户，非一账户多设备登录）",
    illustrate7:
      "1、个人用户与企业VIP用户在部分功能上有区别，但是在使用译制功能、AI配音功能以及漫画快翻功能时，仍需购买对应的付费包。",
    illustrate8: "2、企业VIP购买后立即生效，有效期自购买之日起算，为期1年。",
    illustrate9: "3、如您有业务定制需求、开票需求及其他疑问，可联系客服",
    email: "邮箱",
    phone: "电话",
    product: "产品",
    preferential: "优惠",
    Enterpriseaccountdiscount: "",
    // Enterpriseaccountdiscount: "企业账户 9 折",
    nothave: "无",
    Paidamount: "实付金额",
    Paymentmethod: "支付方式",
    WeChatscanningpayment: "微信扫码支付",
    Alipaycodescanningpayment: "支付宝扫码支付",
    yuan: "元",
    payment: "支付",
    titlep1: "5 小时 译制功能包",
    titlep2: "20 小时 译制功能包",
    titlep3: "100 小时 译制功能包",
    titlep4: "文本配音·字符卡",
    titlep7: "5 小时  视频配音·时长卡",
    titlep8: "20 小时  视频配音·时长卡",
    titlep9: "100 小时  视频配音·时长卡",
    Personal: "个人版",
    EnterpriseVIPEdition: "企业 VIP 版",
    Noannualfee: "无年费",
    year: "年",
    activatenow: "立即开通",
    durationcard: "购买时长卡折扣",
    Nodiscount: "无折扣",
    unlimited: "无限制",
    subtitledownload: "字幕下载",
    audiodownload: "音频下载",
    videoDownload: "视频下载",
    Cloudstoragespace: "云储存空间",
    Cloudstoragespace1: "3G（30天）",
    Cloudstoragespace2: "（过期仅删除音视频文件，不删除项目数据）",
    Cloudstoragespace3: "50G（180天）",
    Exclusivecustomerservice: "专属客服",
    TeamFunctionality: "团队功能",
    Glossary: "术语库",
    TranslationMemoryLibrary: "翻译记忆库",
    Automaticerrorchecking: "自动错误检查",
    Recommendedusagescenarios: "推荐使用场景",
    Recommendedusagescenarios1:
      "适合个人译员使用，注册每月可免费领取部分使用时长，可享基础云储存空间，进一步体验可购买9.9深度体验包。 如长期使用，可按需购买字幕译制包、AI 配音包及漫画快翻包。",
    Recommendedusagescenarios2:
      "适合有团队管理需求、专业语言资产管理功能需求及大批内容储备的企业用户。<br />企业VIP用户除了享有个人版一切权益之外，还提供更大的储存空间、专属客服以及企业专属功能。",
    EnterprisePLUSEdition: "企业 PLUS 版",
    Ondemandcustomization: "按需定制",
    Contactimmediately: "立即联络",
    Contactimmediately1:
      " 如企业VIP提供的功能仍未能满足您的需求，或您有其他定制化需求，可以联系我们，来为您定制专属解决方案。",
    Contactyou: "我们会尽快联系您",
    name: "姓名",
    entername: "请输入您的姓名",
    corporatename: "公司名称",
    entercorporatename: "请输入您的公司名称",
    Emails: "电子邮箱",
    enteremail: "请输入邮箱地址",
    enterthecorrectemail: "请输入正确的邮箱地址",
    enteryouremail: "请输入您的电子邮箱",
    Othercontactinformation: "其他联系方式",
    PhoneWeChatQQ: "电话/微信/QQ...",
    titlep10: "5 小时  视频涂抹·时长卡",
    titlep11: "20 小时  视频涂抹·时长卡",
    titlep12: "100 小时  视频涂抹·时长卡",
    AIDuabPackage: "视频涂抹功能包",
    AIDuabPackageExplain: "购买后您将获得特权：适用于视频字幕涂抹功能",
    AIAdvancedDuabPackage: "视频高级涂抹功能包",
    AIAdvancedDuabPackageExplain:
      "购买后您将获得特权：适用于视频字幕高级涂抹功能",
    titlep13: "5 小时  视频高级涂抹·时长卡",
    titlep14: "20 小时  视频高级涂抹·时长卡",
    titlep15: "100 小时  视频高级涂抹·时长卡",
  },
  // 我的文件
  MyFiles: {
    MyFilesTitle: "My Files",
    CreateFolder: "Create New Folder",
    CreateFolder1: "Enter folder name",
    FolderName: "Folder Name",
    FolderType: "Type",
    inputFolderTips: "Please enter the folder name",
    inputFolderTips1: "New folder created successfully",
    loadingTips: "Loading...",
    defaultFolder: "Default Folder",
    datetime1: "Start Date",
    datetime2: "to",
    datetime3: "End Date",
    inputFolderTips2: "Enter file name",
    search: "Search",
    spaceUsage: "Space Usage",
    fileSave: "Files saved for ",
    day: "days",
    fileSaveTips1:
      "1. The task won't be deleted after expiration, only the files will be deleted.",
    fileSaveTips2:
      "2. After activating the VIP account, the expiration is extended to 180 days.",
    moveTo: "Move to...",
    moveTo1: "Move to",
    delete: "Delete",
    name: "Name",
    filesNum: "File Counts",
    createTime: "Created On",
    updateTime: "Updated On",
    operation: "",
    fileDetails: "File Details",
    service: "Type",
    team: "Team",
    sourceLanguage: "Source Language",
    targetLanguage: "Target Language",
    SRTLanguage: "SRT Language",
    duration: "Duration",
    segments: "Segment Counts",
    wordCount: "Word Counts",
    mediaDuration: "Media Duration",
    deadline: "Deadline",
    imgCount: "Image Count",
    comments: "Comments",
    newName: "New Name",
    newNameTips: "Please enter a new name",
    searchTips: "No data available",
    details: "Details",
    rename: "Rename",
    renameFolder: "Rename Folder",
    my: "Mine",
    jobType1: "Video Transcription",
    jobType2: "Video Translation",
    jobType3: "Subtitle Translation",
    jobType4: "Audio Transcription",
    jobType5: "Comic Agile-translation",
    jobType6: "Text Dubbing",
    jobType7: "Subtitle OCR",
    jobType8: "Subtitle Alignment",
    jobType9: "AI Dubbing",
    jobType10: "Customized-voice",
    jobType11: "Accent-clone",
    jobType101: "Transcription", //new
    jobType102: "Translation", //new
    jobType103: "Review", //new
    jobType106: "Transcription QC", //new
    jobType104: "Customized-voice", //new
    jobType105: "Accent-clone", //new
    teamJob1: "- In Progress",
    teamJob2: "- Completed",
    teamJob3: "is not completed, current progress",
    moveToTips: "Please select the folder to move into...",
    moveToTips1: "Moved Successfully",
    jobStatus1: "Processing in progress, please wait",
    jobStatus2: "Identifying...",
    renameFiles: "Rename File",
    deleteTips1: "Are you sure you want to delete this folder?",
    deleteTips2: "Are you sure you want to delete this file?",
    comfirmTips1: "Notice",
    comfirmTips2: "Confirm",
    comfirmTips3: "Cancel",
    deleteTips3: "Deleted Successfully",
    moveToTips2: "Folder cannot be moved",
    deleteTips4: "Folder contains files, cannot be deleted",
    deleteTips5: "File belongs to other team, cannot be deleted",
    deleteTips6: "File is in pending status, cannot be deleted",
    deleteTips7: "Are you sure you want to delete the selected items?",
    renameTips: "Name cannot be empty",
    renameTip1: "Folder renamed successfully",
    renameTip2: "File renamed successfully",
    All: "All",
    download: "Download",
    downloadTitle: "AI dubbing",
    downloadTips: "Batch download only supports AI video dubbing tasks",
    downloadLoading: "Exporting...",
    downloadTips1: "Failed to download the file",
    downloadTips2: "The selected task has no downloadable files",
    DUBLanguage: "Dubbing Language",
    Projecttask: "Only supports assigned tasks (review process)",
    Projecttask1: "Only supports review process",
    Projecttask2:
      "Support is provided only for assigned tasks (translation process, must be the same language pair, within the same project, not split)",
    Projecttask3:
      "Support is provided only for assigned tasks (transcription process, must be the same language pair, within the same project, not split)",
    Projecttask4: "Support is provided for transcription process only",
    Projecttask5: "Support is provided for translation process only",
    Projecttask6: "Only the same language pair is supported",
    Projecttask7: "Only the same language is supported",
    Projecttask8: "Support is provided for transcription QC process only",
    Projecttask9:
      "Support is provided only for assigned tasks (transcription QC process, must be the same language pair, within the same project, not split)",
    Projecttask10: "Only tasks in the same state are supported",
    Projecttask11: "Only completed tasks are supported",
    Projecttask12: "不支持已交付的任务",
    Projecttask13: "不支持没有翻译流程的任务",
    Projecttask14: "仅支持翻译流程已提交的任务",
    deleteTips8: "You have selected ",
    deleteTips9: " items. Are you sure you want to delete these tasks?",
  },
  //Personal Center
  PersonalCenter: {
    unit: " (Minutes)",
    unit1: " (Images)",
    unit2: " (Char)",
    unit3: " (Hour)",
    ID: "ID: ",
    title: "Personal Info",
    loadingTips: "Loading...",
    teamInformation: "Team Information",
    corporateVIP: "VIP",
    expirationTime: "Expiration Date: ",
    email: "Email: ",
    mobilephone: "Mobile: ",
    Nickname: "Nickname: ",
    unbound: "Unbound",
    binding: "Bind",
    bound: "Bound",
    unbind: "Unbind",
    replace: "Replace",
    wechat: "WeChat: ",
    setUp: "Settings",
    changePassword: "Change Password",
    accountCancellation: "Account Cancellation",
    giftText: "Get Monthly Freebies",
    giftText1: "Already Got Monthly Freebies",
    giftText2: "Purchase History",
    giftText3: "Service Details",
    giftText4: "Service Package",
    remainingDuration: "Remaining Time",
    giftText5:
      "Availability: Audio/Video Transcription, AI Translation, Subtitle Adjustment (including timecodes), Subtitle OCR, Subtitle Alignment, Subtitle/Audio Download",
    hour: " Hours",
    giftText6: "Buy More",
    giftText7: "AI Text Dubbing Package",
    giftText8: "Remaining Characters",
    giftText9: "Trials",
    giftText10: "Free Dubbing Times (within 1000 characters)",
    giftText11: " Times",
    giftText12: "Total Download Times",
    giftText13: "Unlimited",
    giftText14:
      "Availability: Use Basic and Advanced (free for now) AI dubbers, rich fine-tuning elements",
    giftText15: "AI Video Dubbing Package",
    minute: " Minutes",
    char: " Char",
    giftText16: "Free Dubbing Times (within 5 minutes)",
    giftText17: "Comic Agile-translation Package",
    giftText18: "Remaining Images",
    giftText19: " Images",
    giftText20:
      "Availability: Customized comic OCR, brush, batch image processing, automatic error checking, professional CAT translation Tool",
    giftText21: "Space Usage",
    giftText22: "If you have any questions, please contact customer service",
    giftText23: "Bind Mobile Phone",
    giftText24: "Bind New Mobile Phone",
    phoneNumber: "Phone Number",
    tips1: "Please enter the phone number",
    phoneText: "Verification Code",
    phoneText1: "SMS verification code",
    phoneText2: "Send Code",
    second: "Seconds",
    oldPassword: "Old Password",
    oldPasswordText: "Please enter your old password",
    newPassword: "New Password",
    newPasswordText: "Please enter your new password",
    confirmPassword: "Confirm",
    confirmPasswordText: "Please re-enter your new password",
    PasswordText: "Please enter a password of 8-20 characters",
    PasswordText1: "Please re-enter the password",
    PasswordText2: "The two passwords entered do not match!",
    giftText25: "Verify Identity Information",
    email1: "Email",
    giftText26: "Please enter the verification code",
    team: "Team",
    joinedOn: "Joined On",
    wechatText: "Please scan with WeChat",
    wechatText1: "Loading",
    wechatText2: "QR Code has expired",
    wechatText3: "Reload",
    wechatText4: "Scan and follow 'Wiitrans 我译网' to complete binding",
    phoneText3: "Phone number cannot be empty",
    phoneText4: "Please enter a correct phone number",
    uploadText: "Only .jpg, .jpeg, .png formats can be uploaded",
    uploadText1: "Uploaded file size should be less than 2MB",
    uploadText2: "Token has expired, please log in again",
    copyText: "Copied successfully",
    comfirmText:
      "Cancelling the account will clear all contents on your account, are you sure to cancel?",
    comfirmTips1: "Notice",
    comfirmTips2: "Confirm",
    comfirmTips3: "Cancel",
    comfirmTips4: "Cancelled successfully",
    teamTips: "Not joined any team yet",
    phoneText5: "Phone number bound successfully",
    PasswordText3: "Password changed successfully, please log in again",
    phoneText6: "Verified successfully, bind new phone number",
    phoneText7: "Please enter phone number",
    phoneText8: "Verification code sent",
    wechatText5:
      "This WeChat has already been bound to another account, please unbind first",
    wechatText6: "Are you sure to unbind WeChat?",
    PurchaseRecordText: "Purchase Record",
    PurchaseRecordText1: "Purchase Date",
    PurchaseRecordText2: "Product Purchased",
    PurchaseRecordText3: "Payment Method",
    PurchaseRecordText4: "Amount Spent",
    ServiceDetailsText: "Service Details",
    ServiceDetailsText1: "Date",
    ServiceDetailsText2: "Description",
    ServiceDetailsText3: "Source",
    giftText27: "Video Smoothing Function Pack",
    giftText28: "Available Feature: Video Subtitle Smoothing",
    TurnOnMessage: "Turn on message",
    TurnOffMessage: "Turn off message",
    exportInfo: "Export Personal Information",
    CancellationTips:
      "Kindly provide a brief reason for account cancellation. After submitting the cancellation request, if you do not log in within 15 days, your account and associated information will be automatically cancelled. If you log in during this period, the cancellation request will be revoked.",
    CancellationTips1: "Please State the Reason for Cancellation",
    personInfo: "Personal Information",
    otherInfo: "Third-Party Sharing List",
    giftText29: "Advanced Video Smearing Function Package",
    giftText30: "Available features: Advanced Smearing for Video Subtitles",
  },
  // Login
  Login: {
    text1: "Enter email to start your journey",
    text2: "Register",
    text3: "Login with existing account",
    text4: "Enter email to verify identity",
    confirm: "Confirm",
    text5:
      "Verification email has been sent, please log in to your email to complete verification",
    wechatText: "WeChat Account",
    wechatText1: "WiiMedia Platform",
    wechatText2: "Loading",
    wechatText3: "QR Code has expired",
    wechatText4: "Reload",
    wechatText5: " Follow official account and scan it to login",
    wechatText6: "Once followed you'll receive all valued information in time",
    wechatText7:
      "First time WeChat users need to bind WiiMedia or Wiitrans account,",
    wechatText8: "Then, you can login directly by scanning the QR code",
    wechatText9: "Bind existing account",
    wechatText10: "Register new account",
    wechatText11: "Back",
    email: "Email",
    password: "Password",
    binding: "Bind",
    text6: "WiiMedia Account",
    text7: "Register account",
    text8: "Forgot password?",
    login: "Login",
    text9: "Wiitrans Account",
    mediaText: "Register WiiMedia account",
    nickname: "Nickname",
    nicknameText: "Please enter a nickname",
    nicknameText1:
      "Nickname is unique and cannot be changed after registration",
    passwordText: "Please enter a password",
    passwordText1:
      "Password can include letters, numbers, and underscores; case sensitive; length is 8-20 characters",
    passwordText2: "Please re-enter the password",
    text10: "I have read and agree to the ",
    text11: "Privacy Policy ",
    text12: "Service Agreement",
    passwordText3: "Set new password",
    passwordText4: "Confirm",
    passwordText5: "Setup completed",
    passwordText6:
      "New password setup completed! Please remember your new password",
    passwordText7: "Return to login",
    wechatText12: "Please scan with WeChat",
    wechatText13: "Scan and follow 'Wiitrans 我译网' to complete binding",
    wechatText14: "Bind WiiMedia account",
    passwordText8: "The two passwords entered are not the same!",
    nicknameText2: "Nickname cannot exceed 50 characters",
    nicknameText3: "This nickname already exists, please change the nickname",
    text13: "Please enter an email",
    text14: "Please enter a correct email",
    text15:
      "Activation email has been sent to your registered email, please log in to activate as soon as possible.",
    passwordText9:
      "Password input only supports numbers, letters (case sensitive), and underscores",
    passwordText10: "Please enter an 8-20 character password",
    passwordText11: "Service Agreement not checked",
    text16: "",
  },
  SplitTask: {
    name: "Number of lines : ",
    name1: "Media Length : ",
    name2: "Task Name : ",
    name3: "Splitting method",
    name4: "Progress",
    name5: "Please split it first",
  },
  CustomizedAnchor: {
    name1: "Audio Source",
    name2: "Select existing task roles",
    name3: "Add more",
    name4: "Customization Successful",
  },
  VoiceStyle: {
    text1: "Affectionate",
    text2: "Poetry-reading",
    text3: "Serious",
    text4: "Assistant",
    text5: "Service",
    text6: "Terrified",
    text7: "Calm",
    text8: "Cheerful",
    text9: "Sad",
    text10: "Lyrical",
    text11: "Newscast",
    text12: "Gentle",
    text13: "Angry",
    text14: "Chat",
    text15: "Disgruntled",
    text16: "Depressed",
    text17: "Relaxed",
    text18: "Newscast-casual",
    text19: "Narration-professional",
    text20: "Chat-casual",
    text21: "Newscast-formal",
    text22: "Ads-upbeat",
    text23: "Embarrassed",
    text24: "Depressed",
    text25: "Young Adult Male",
    text26: "Young Adult Male",
    text27: "Senior Male",
    text28: "Older Adult Female",
    text29: "Girl",
    text30: "Senior Female",
    text31: "Envious",
    text32: "Boy",
    text33: "Older Adult Male",
    text34: "Documentary",
    text35: "Whisper",
    text36: "Hopeful",
    text37: "Shouting",
    text38: "Friendly",
    text39: "Unfriendly",
    text40: "Excited",
    text41: "Empathy",
    text42: "English (US)",
    text43: "English (UK)",
    text44: "Northeastern Dialect",
    text45: "Henan Dialect",
    text46: "Shaanxi Dialect",
    text47: "Jilu Mandarin",
    text48: "Taiwanese Mandarin",
    text49: "Wu-Chinese",
    text50: "Cantonese-Simplified",
    text51: "Cantonese-Traditional",
    text52: "Southwestern Mandarin",
    text53: "Japanese",
    text54: "Korean",
    text55: "German",
    text56: "French (France)",
    text57: "French (Canada)",
    text58: "French (Belgium)",
    text59: "French (Switzerland)",
    text60: "Italian",
    text61: "Russian",
    text62: "Spanish (Mexico)",
    text63: "Spanish (Spain)",
    text64: "Portuguese (Brazil)",
    text65: "Portuguese (Portugal)",
    text66: "Thai",
    text67: "Vietnamese",
    text68: "Indonesian",
    text69: "Danish",
    text70: "Ukrainian",
    text71: "Uzbek",
    text72: "Urdu (India)",
    text73: "Urdu (Pakistan)",
    text74: "Norwegian",
    text75: "Bulgarian",
    text76: "Sinhala",
    text77: "Croatian",
    text78: "Icelandic",
    text79: "Galician",
    text80: "Hungarian",
    text81: "Hindi",
    text82: "Kazakh",
    text83: "Turkish",
    text84: "Serbian",
    text85: "Bengali",
    text86: "Hebrew",
    text87: "Greek",
    text88: "Latvian",
    text89: "Czech",
    text90: "Slovak",
    text91: "Slovenian",
    text92: "Polish",
    text93: "Bosnian",
    text94: "Swedish",
    text95: "Burmese",
    text96: "Romanian",
    text97: "Lao",
    text98: "Finnish",
    text99: "Dutch",
    text100: "Tagalog",
    text101: "Arabic",
    text102: "Malay",
    text103: "Maltese",
    text104: "Khmer",
    text105: "Spanish (US)",
    text106: "Arabic",
    text107: "Chinese (Hong Kong)",
    text108: "Emotional",
    text109: "Multi-scenario",
    text110: "Multi-age",
    text111: "Role-playing",
    text112: "Childlike voice",
    text113: "Mature and intellectual",
    text114: "Relaxed",
    text115: "Professional",
    text116: "Soft and cute child voice",
    text117: "Child's voice",
    text118: "Loli voice",
    text119: "Healing child voice",
    text120: "TikTok popular",
    text121: "Film commentary",
    text122: "Documentary",
    text123: "Audiobook",
    text124: "Children's reading",
    text125: "Literature",
    text126: "E-commerce",
    text127: "Advertising",
    text128: "Gaming",
    text129: "Short video",
    text130: "Story",
    text131: "Child-like voice",
  },
  //通用语言（取自数据库表dict_language，与language_id对应）
  GlobalLanguage: {
    lang1: "English",
    lang2: "Chinese (Simplified)",
    lang3: "French",
    lang4: "Hindi",
    lang5: "Japanese",
    lang6: "Khmer",
    lang7: "Malay",
    lang8: "Portuguese (Brazil)",
    lang9: "Portuguese (Europe)",
    lang10: "Romanian",
    lang11: "Thai",
    lang12: "Vietnamese",
    lang13: "Chinese (Taiwan)",
    lang14: "German",
    lang15: "Spanish (Latin)",
    lang16: "Russian",
    lang17: "Korean",
    lang18: "Italian",
    lang19: "Indonesian",
    lang20: "Arabic",
    lang21: "Burmese",
    lang22: "Lao",
    lang23: "Hebrew",
    lang24: "Spanish (Europe)",
    lang25: "Chinese (Hong Kong)",
    lang26: "Tagalog",
    lang27: "French (Canada)",
  },
  // 个人媒体操作页
  VideoDetails: {
    buttonName: "Global AI Translation",
    buttonName2: "Settings",
    buttonName3: "Download",
    dialogText: "Subtitle Settings",
    dialogText1: "Timecode Duration Min",
    dialogText2: "Timecode Duration Max",
    dialogText3: "Timecode Mini Gap",
    dialogText4: "Allow equals to 0",
    dialogText5: "Timecode Overlap", //Allow overlap @@@@@
    dialogText6: "Line Limitation",
    dialogText7: "Translation complete",
    dialogText8: "The min duration needs to be less than max duration",
    dialogText9: "Set successfully",
  },
};
