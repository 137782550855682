<!--
 * @Description: 项目详情
 * @version: 
 * @Author: Carful
 * @Date: 2022-11-09 17:43:22
 * @LastEditors: Carful
 * @LastEditTime: 2023-06-28 16:58:31
-->
<template>
  <div class="mainCenter">
    <div class="mainHeader">
      <div class="mainHeader_left">
        <div class="signoutClass" @click="signOut">
          <svg-icon icon-class="Aright" class="icon"></svg-icon>
        </div>
        <div class="titleClass">
          <div></div>
          <el-tooltip
            class="item"
            effect="dark"
            :content="title"
            placement="top"
            :disabled="title.length < 20"
          >
            <p :class="title.length > 20 ? 'overflowp overflowEllipsis' : ''">
              {{ title }}
            </p>
          </el-tooltip>
        </div>
        <!-- <div class="dropdowndiv">
          <el-dropdown
            trigger="click"
            @command="commandClick"
            class="dropdownbutton"
          >
            <div class="el-dropdown-link">
              <p>
                <span>批量处理</span
                ><span class="el-icon-arrow-down el-icon--right"></span>
              </p>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="1"> </el-dropdown-item>
              <el-dropdown-item command="2"> </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div> -->
      </div>
      <div class="mainHeader_right">
        <div
          v-for="(i, index) in buttonList"
          :key="index"
          class="buttonList_class"
        >
          <el-dropdown
            @command="commandButtonClicks"
            trigger="hover"
            v-if="i.ischildren && i.status"
          >
            <el-button type="text">
              <div class="buttondiv">
                {{ i.name }}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </div>
            </el-button>
            <el-dropdown-menu slot="dropdown" class="tabledropdowndiv">
              <el-dropdown-item
                v-for="item in i.children"
                :key="item.id"
                :command="{ id: item.id }"
                v-show="item.status"
              >
                <span v-if="item.status">{{ item.name }}</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button
            type="text"
            @click="headerBtnClick(i.name, i.id)"
            v-show="i.status"
            v-else
          >
            <div class="buttondiv">
              <svg-icon :icon-class="i.icon" class="icon"></svg-icon>
              <p>{{ i.name }}</p>
            </div>
          </el-button>
        </div>

        <div class="lastdiv"></div>
        <div class="lastbtn">
          <el-dropdown @command="commandBatchClicks" trigger="click">
            <el-button type="text">
              <div class="svgbtn">
                <svg-icon icon-class="MoreOperations" class="icon"></svg-icon>
              </div>
            </el-button>
            <el-dropdown-menu slot="dropdown" class="tabledropdowndiv">
              <el-dropdown-item
                v-for="item in dropdownBatchList"
                :key="item.id"
                :command="{ command: item.id }"
                v-show="item.status"
              >
                <span v-if="item.status">{{ item.name }}</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <!-- 筛选 -->
    <div class="contentCenter_tags screenStyle">
      <p>{{ $t("CommonName.screen") }}</p>
      <el-tag
        v-for="tag in tags"
        :key="tag.name"
        closable
        :type="tag.type"
        @close="handleClose(tag)"
      >
        <p
          class="screenp"
          :id="'tag' + tag.id"
          @mouseover="onShowNameTipsMouseenter(tag)"
        >
          <el-tooltip
            class="item"
            effect="dark"
            :content="tag.name"
            placement="top-start"
            :disabled="!tag.showdropdown"
          >
            <span>{{ tag.name }}</span>
          </el-tooltip>
        </p>
      </el-tag>
    </div>
    <div class="mainCenterbtm tableClass" v-if="projectInfo.sort_mode">
      <el-table
        :row-style="{
          height: 48 + 'px',
          background: '#393c4e',
          color: '#cbd7e8',
        }"
        :cell-style="{ padding: 0 + 'px' }"
        :header-cell-style="{
          height: 48 + 'px',
          padding: '0px',
          color: '#adb0bc',
          background: '#393c4e',
        }"
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        :max-height="tableHeight"
        :row-class-name="tableRowClassName"
        @header-click="headerClick"
        @select="handleSelect"
        @select-all="handleALLSelect"
        :row-key="getRowKey"
        @expand-change="expandChange"
        :default-sort="{
          prop: 'job_name',
          order: projectInfo.sort_mode === '0' ? 'ascending' : 'descending',
        }"
        @sort-change="sortChange"
      >
        <!--          @row-click="rowClick"-->
        <div slot="empty" class="noDataDiv">
          <svg-icon icon-class="NoData" class="noDataicon"></svg-icon>
          <p>{{ $t("CommonName.noData") }}</p>
        </div>
        <el-table-column type="expand" width="30">
          <template slot-scope="props">
            <div class="expand_div">
              <div class="left_div"></div>
              <div class="right_div">
                <div class="right_div_top">
                  <el-tabs
                    v-model="props.row.activeName"
                    @tab-click="handleTabClick(props.row)"
                  >
                    <!--      转写-->
                    <el-tab-pane
                      :label="$t('ProjectDetails.Transcription')"
                      name="1"
                      v-if="
                        ['1', '3', '5', '6', '7'].includes(props.row.job_type)
                      "
                    ></el-tab-pane>
                    <!--      校轴-->
                    <el-tab-pane
                      :label="$t('ProjectDetails.TranscriptionQC')"
                      name="5"
                      v-if="['6', '7'].includes(props.row.job_type)"
                    ></el-tab-pane>
                    <!--      翻译-->
                    <el-tab-pane
                      :label="$t('ProjectDetails.Translation')"
                      name="2"
                      v-if="
                        ['2', '3', '4', '5', '7'].includes(props.row.job_type)
                      "
                    ></el-tab-pane>
                    <!--      校对-->
                    <el-tab-pane
                      :label="$t('ProjectDetails.Review')"
                      name="3"
                      v-if="['4', '5', '7'].includes(props.row.job_type)"
                    ></el-tab-pane>
                  </el-tabs>
                </div>
                <!--                class="right_div_btm tableborderClass tableClass borderTableStyle"-->
                <div>
                  <el-table
                    :row-style="{
                      height: 40 + 'px',
                      background: '#393c4e',
                      color: '#ffffff',
                    }"
                    :cell-style="{ padding: 0 + 'px' }"
                    :header-cell-style="{
                      height: 40 + 'px',
                      padding: '0px',
                      color: '#adb0bc',
                      background: '#454758',
                    }"
                    :data="props.row.tableData"
                    tooltip-effect="dark"
                    style="width: 100%"
                    max-height="160"
                    ref="childTable"
                    :row-key="(row) => row.job_split_id"
                    border
                  >
                    <!--                    任务名称-->
                    <el-table-column>
                      <template slot-scope="scope">
                        <div
                          style="padding-left: 12px"
                          class="menuscopediv"
                          @click="tovideoDetails1(scope.row)"
                        >
                          {{ scope.row.job_name }}
                        </div>
                      </template>
                      <template slot="header">
                        <p style="padding-left: 12px">
                          {{ $t("CommonName.taskname") }}
                        </p>
                      </template>
                    </el-table-column>
                    <!--                   进度-->
                    <el-table-column
                      :label="$t('SplitTask.name4')"
                      width="150"
                      prop="progress"
                    >
                      <template slot-scope="scope">
                        <div class="statusClass">
                          <p>{{ scope.row.progress }}</p>
                          <p
                            v-if="
                              scope.row.workflow == 2 &&
                              scope.row.gpt_progress == 1
                            "
                          >
                            {{ " AIT" }}
                          </p>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      :label="$t('ProjectManagement.Waterprint1')"
                      width="320"
                      prop="nickname"
                    ></el-table-column>
                    <!--                    时长-->
                    <el-table-column
                      :label="$t('CommonName.duration')"
                      width="150"
                      prop="minutes"
                    ></el-table-column>
                    <!--                    句段数-->
                    <el-table-column
                      :label="$t('CommonName.Numberofsentencesegments')"
                      width="150"
                      prop="line"
                    ></el-table-column>
                    <!--                    字数-->
                    <el-table-column
                      :label="$t('CommonName.Numberofwords')"
                      width="100"
                      prop="words"
                    ></el-table-column>
                    <!--                    操作-->
                    <el-table-column
                      :label="''"
                      width="40"
                      label-class-name="labClassName"
                    >
                      <template slot-scope="scope">
                        <div class="operationClass">
                          <el-dropdown
                            @command="commandSpiltClicks"
                            trigger="click"
                          >
                            <span class="el-dropdown-link">
                              <svg-icon
                                icon-class="MoreOperations"
                                style="color: #cbd7e8"
                              ></svg-icon>
                            </span>
                            <el-dropdown-menu
                              slot="dropdown"
                              class="tabledropdowndiv"
                              style="max-height: 400px"
                            >
                              <el-dropdown-item
                                v-for="item in dropdownList1"
                                :key="item.id"
                                :command="{ command: item.id, row: scope.row }"
                                v-show="item.status"
                              >
                                <template>
                                  <span>{{ item.name }}</span></template
                                >
                              </el-dropdown-item>
                            </el-dropdown-menu>
                          </el-dropdown>
                        </div>
                        <!-- <el-button type="text" @click="rejectjob(scope.row)">
                          <svg-icon
                            icon-class="RejectBack"
                            class="icon"
                          ></svg-icon>
                        </el-button>
                        <el-button
                          type="text"
                          @click="runSplitAITJob(scope.row)"
                          v-if="
                            scope.row.workflow == 2 &&
                            scope.row.gpt_progress == 0
                          "
                        >
                          <svg-icon
                            icon-class="TranslateMT"
                            class="icon"
                          ></svg-icon>
                        </el-button> -->
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          type="selection"
          width="45"
          align="center"
          :reserve-selection="true"
        ></el-table-column>
        <el-table-column
          :min-width="140"
          prop="job_name"
          sortable="custom"
          :sort-orders="['ascending', 'descending']"
        >
          <template slot="header">
            <span class="fontSize12">{{ $t("CommonName.taskname") }}</span>
            <div
              class="icondiv"
              style="font-size: 17px; position: absolute; right: 15px; top: 8px"
            >
              <el-popover
                placement="bottom-end"
                width="260"
                v-model="visible"
                :visible-arrow="false"
              >
                <div class="popoverDiv">
                  <el-input
                    v-model="popoverInput"
                    :placeholder="$t('CommonName.search')"
                    maxlength="100"
                    @keyup.enter.native="searchdetermineClick"
                  ></el-input>
                  <el-button
                    type="primary"
                    class="setpadding16"
                    @click="searchdetermineClick"
                    >{{ $t("ProjectManagement.query") }}
                  </el-button>
                </div>
                <svg-icon
                  icon-class="Filter"
                  class="iconsize"
                  slot="reference"
                  @click.stop="jobNameClick"
                ></svg-icon>
              </el-popover>
              <div class="tableheader"></div>
            </div>
            <span class="tableheaderspan" style="margin-top: 12px"></span>
            <!-- 任务名称 -->
          </template>
          <template slot-scope="scope">
            <div class="menuscopediv" @click="tovideoDetails(scope.row)">
              {{ scope.row.name }}
            </div>
          </template>
        </el-table-column>
        <!-- 操作流程 -->
        <el-table-column :width="185">
          <template slot="header">
            <div class="tableheaderdiv">
              <p class="fontSize12">
                {{ $t("ProjectDetails.Operationprocess") }}
              </p>
              <div class="icondiv">
                <el-dropdown
                  @command="Operationprocesscommand"
                  trigger="click"
                  placement="bottom-end"
                >
                  <span class="el-dropdown-link">
                    <svg-icon icon-class="Filter" class="iconsize"></svg-icon>
                  </span>
                  <el-dropdown-menu slot="dropdown" class="noshowtriangle">
                    <el-dropdown-item
                      v-for="item in OperationprocessdropdownList"
                      :key="item.id"
                      :command="{ name: item.name, value: item.id }"
                    >
                      <span>{{ item.name }}</span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <div class="tableheader"></div>
              </div>
            </div>
          </template>
          <template slot-scope="scope">
            <div class="setings">
              <el-tooltip
                v-for="(i, index) in scope.row.set"
                :key="index"
                :disabled="i.nickname == ''"
                :content="i.nickname"
                placement="top"
              >
                <!--                showbadge-->
                <el-badge is-dot class="item" :hidden="!i.showbadge">
                  <div
                    class="setClass"
                    :class="i.nickname == '' ? i.class + '1' : i.class + '2'"
                  >
                    <p>
                      {{ i.name }}
                    </p>
                  </div>
                </el-badge>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <!-- 状态 -->
        <el-table-column :width="118">
          <template slot="header">
            <div class="tableheaderdiv">
              <p class="fontSize12">{{ $t("CommonName.status") }}</p>
              <div class="icondiv">
                <el-dropdown
                  @command="statuscommand"
                  trigger="click"
                  placement="bottom-end"
                >
                  <span class="el-dropdown-link">
                    <svg-icon icon-class="Filter" class="iconsize"></svg-icon>
                  </span>
                  <el-dropdown-menu slot="dropdown" class="noshowtriangle">
                    <el-dropdown-item
                      v-for="item in statusdropdownList"
                      :key="item.id"
                      :command="{ name: item.name, value: item.id }"
                    >
                      <span>{{ item.name }}</span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <div class="tableheader"></div>
              </div>
            </div>
          </template>
          <template slot-scope="scope">
            <div class="statusClass">
              <div v-if="!scope.row.newstatus.class">
                {{ scope.row.newstatus.name }}
              </div>
              <div
                v-else
                class="statusClass1"
                :class="
                  scope.row.newstatus.class ? scope.row.newstatus.class : ''
                "
              >
                <p>{{ scope.row.newstatus.name }}</p>
              </div>
              <p
                v-if="
                  scope.row.newstatus.value &&
                  scope.row.newstatus.gpt_progress == 0
                "
              >
                {{ scope.row.newstatus.value }}
              </p>
              <p v-if="scope.row.newstatus.gpt_progress == 1">
                {{ "AIT" }}
              </p>
            </div>
          </template>
        </el-table-column>
        <!-- 源语言 -->
        <el-table-column prop="slang_name" show-overflow-tooltip :width="170">
          <template slot="header">
            <div class="tableheaderdiv">
              <p class="fontSize12">
                {{ $t("CommonName.sourcelanguagetable") }}
              </p>
              <div class="icondiv">
                <el-dropdown
                  @command="sourcelanguagecommand"
                  trigger="click"
                  placement="bottom-end"
                >
                  <span class="el-dropdown-link">
                    <svg-icon icon-class="Filter" class="iconsize"></svg-icon>
                  </span>
                  <el-dropdown-menu
                    slot="dropdown"
                    class="tabledropdowndiv noshowtriangle"
                  >
                    <el-dropdown-item
                      v-for="item in sourcelanguagedropdownList"
                      :key="item.language_id"
                      :command="{ name: item.ename, value: item.language_id }"
                    >
                      <span>{{ item.ename }}</span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <div class="tableheader"></div>
              </div>
            </div>
          </template>
        </el-table-column>
        <!-- 目标语言 -->
        <el-table-column prop="tlang_name" show-overflow-tooltip :width="170">
          <template slot="header">
            <div class="tableheaderdiv">
              <p class="fontSize12">
                {{ $t("CommonName.targetlanguagetable") }}
              </p>
              <div class="icondiv">
                <el-dropdown
                  @command="targetlanguagecommand"
                  trigger="click"
                  placement="bottom-end"
                >
                  <span class="el-dropdown-link">
                    <svg-icon icon-class="Filter" class="iconsize"></svg-icon>
                  </span>
                  <el-dropdown-menu
                    slot="dropdown"
                    class="tabledropdowndiv noshowtriangle"
                  >
                    <el-dropdown-item
                      v-for="item in targetlanguagedropdownList"
                      :key="item.language_id"
                      :command="{ name: item.ename, value: item.language_id }"
                    >
                      <span>{{ item.ename }}</span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <div class="tableheader"></div>
              </div>
            </div>
          </template>
        </el-table-column>
        <!-- 时长 -->
        <el-table-column prop="duration" :width="110">
          <template slot="header">
            <div class="tableheaderdiv">
              <p class="fontSize12">{{ $t("CommonName.duration") }}</p>
              <div class="icondiv">
                <div class="tableheader"></div>
              </div>
            </div>
          </template>
          <template slot-scope="scope">
            <el-tooltip
              :disabled="
                +scope.row.duration_srt_time <= +scope.row.duration_video_time
              "
              :content="'SRT Duration: ' + scope.row.duration_srt"
              placement="top"
            >
              <p
                :style="{
                  color:
                    +scope.row.duration_srt_time >
                    +scope.row.duration_video_time
                      ? '#ef7373'
                      : '#cbd7e8',
                }"
              >
                {{ scope.row.duration }}
              </p>
            </el-tooltip>
          </template>
        </el-table-column>
        <!--句段数  -->
        <el-table-column prop="segment" :width="100">
          <template slot="header">
            <div class="tableheaderdiv">
              <p class="fontSize12">
                {{ $t("CommonName.Numberofsentencesegments") }}
              </p>
              <div class="icondiv">
                <div class="tableheader"></div>
              </div>
            </div>
          </template>
        </el-table-column>
        <!-- 字数 -->
        <el-table-column prop="word_count" :width="110">
          <template slot="header">
            <div class="tableheaderdiv">
              <p class="fontSize12">{{ $t("CommonName.Numberofwords") }}</p>
              <div class="icondiv">
                <div class="tableheader"></div>
              </div>
            </div>
          </template>
        </el-table-column>
        <!-- 截止日期 -->
        <el-table-column
          prop="deadline"
          :width="160"
          sortable="custom"
          :sort-orders="['ascending', 'descending']"
        >
          <template slot="header">
            <span>{{ $t("ProjectDetails.closingdate") }}</span>
            <span class="tableheaderspan"></span>
          </template>
        </el-table-column>
        <el-table-column width="40">
          <template slot-scope="scope">
            <div class="operationClass">
              <el-dropdown @command="commandClicks" trigger="click">
                <span class="el-dropdown-link">
                  <svg-icon
                    icon-class="MoreOperations"
                    style="color: #cbd7e8"
                  ></svg-icon>
                </span>
                <el-dropdown-menu
                  slot="dropdown"
                  class="tabledropdowndiv"
                  style="max-height: 400px"
                >
                  <el-dropdown-item
                    v-for="item in dropdownList"
                    :key="item.id"
                    :command="{ command: item.id, row: scope.row }"
                    v-show="
                      item.id == '5'
                        ? item.status && scope.row.youtube_task !== 1
                        : item.id == '6' && scope.row.split_list.length > 0
                        ? false
                        : item.status
                    "
                  >
                    <template
                      v-if="
                        item.id == '5'
                          ? item.status && scope.row.youtube_task !== 1
                          : item.status
                      "
                    >
                      <svg-icon
                        :icon-class="item.icon"
                        style="margin-right: 8px; font-size: 18px"
                      ></svg-icon>
                      <span>{{ item.name }}</span></template
                    >
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <DialogComVue
      :dialogVisible="dialogVisible"
      @determineClick="determineClick"
      @changeDialogVisible="changeDialogVisible"
      :width="dialogWidth ? dialogWidth : '888px'"
      :top="showTop ? '3%' : '10%'"
      :title="dialogtitle"
      :showTitle="showTitle"
      :footerbtnName="$t('CommonName.Sure')"
      :closefooterbtnName="$t('CommonName.Cancel')"
      v-if="dialogVisible"
      :showfooter="showfooter"
      :buttonLoading="buttonLoading"
    >
      <div
        v-if="dialogtitle == $t('ProjectManagement.ExcelToContent')"
        class="content_center"
      >
        <div class="content_center_4">
          <div class="content_center_div">
            <div class="uploadClass" style="margin-bottom: 6px">
              <!-- <p>上传excel文件（.xlsx）</p> -->
              <div style="color: #adb0bc">
                {{ $t("toolset.UploadExcelFile") }}
              </div>
              <el-button type="text" @click="DownloadExcel">
                <!-- 下载excel模板 (.xlsx) -->
                {{ $t("ProjectManagement.DownloadExcelTemplate") }}
              </el-button>
            </div>
            <el-input
              v-model="xlsupload"
              class="input-with-select xlsxinput"
              @input="xlsupload = ''"
            >
              <el-upload
                slot="append"
                class="upload-demo"
                ref="xlsupload"
                name="excel_file"
                :data="uploadData"
                :action="$uploadURL + '/videojob/gateway'"
                :on-change="uploadChange"
                :show-file-list="false"
                :on-success="uploadSuccess"
                :on-progress="uploadProgress"
                :auto-upload="false"
                :with-credentials="true"
                :limit="1"
              >
                <el-button @click="srtupload('xlsupload', 1)" type="text">
                  <div class="uploadbtn">
                    <svg-icon icon-class="upload" class="icon"></svg-icon>
                    <!-- 上传 -->
                    <span class="uploadspan">{{
                      $t("CommonName.Upload")
                    }}</span>
                  </div>
                </el-button>
              </el-upload>
            </el-input>
          </div>
        </div>
      </div>
      <div
        v-if="dialogtitle == $t('ProjectDetails.dropdownList.name8')"
        class="content_center"
      >
        <div class="content_center_4">
          <div class="content_center_div">
            <div class="uploadClass" style="margin-bottom: 6px">
              <!-- <p>上传excel文件（.xlsx）</p> -->
              <div style="color: #adb0bc">
                {{ $t("toolset.UploadExcelFile") }}
              </div>
              <el-button type="text" @click="DownloadAITExcel">
                <!-- 下载excel模板 (.xlsx) -->
                {{ $t("ProjectManagement.DownloadExcelTemplate") }}
              </el-button>
            </div>
            <el-input
              v-model="aitUpload"
              class="input-with-select xlsxinput"
              @input="aitUpload = ''"
            >
              <el-upload
                slot="append"
                class="upload-demo"
                ref="aitUpload"
                name="excel_file"
                :data="uploadData"
                :action="$uploadURL + '/videojob/gateway'"
                :on-change="uploadChange"
                :show-file-list="false"
                :on-success="uploadSuccess"
                :on-progress="uploadProgress"
                :auto-upload="false"
                :with-credentials="true"
                :limit="1"
              >
                <el-button @click="srtupload('aitUpload', 4)" type="text">
                  <div class="uploadbtn">
                    <svg-icon icon-class="upload" class="icon"></svg-icon>
                    <!-- 上传 -->
                    <span class="uploadspan">{{
                      $t("CommonName.Upload")
                    }}</span>
                  </div>
                </el-button>
              </el-upload>
            </el-input>
          </div>
        </div>
      </div>
      <!--      重命名-->
      <div
        v-if="dialogtitle == $t('ProjectDetails.dropdownList.name7')"
        class="content_center"
      >
        <div class="content_center_4">
          <div class="content_center_div">
            <p>{{ $t("CommonName.taskname") }}</p>
            <el-input
              v-model="renameInput"
              class="input-with-select rename-input"
              maxlength="200"
            >
            </el-input>
          </div>
        </div>
      </div>
      <!--      拆分详情-->
      <div
        v-if="dialogtitle === internalRowData.job_name"
        class="content_center"
      >
        <div class="content_center_internalRowData_div">
          <div class="top">
            {{
              internalRowData.workflow === "1"
                ? $t("ProjectManagement.STTname")
                : internalRowData.workflow === "2"
                ? $t("ProjectManagement.TRAname")
                : internalRowData.workflow === "3"
                ? $t("ProjectManagement.REVname")
                : internalRowData.workflow === "5"
                ? $t("ProjectManagement.STQname")
                : $t("ProjectManagement.STTname")
            }}
          </div>
          <div class="btm">
            <div class="btm_left">
              <div class="iconp">
                <p class="title_p">{{ $t("ProjectManagement.Waterprint1") }}</p>
                <el-dropdown
                  trigger="click"
                  placement="bottom-start"
                  @command="handleCommand"
                  @visible-change="visibleChange"
                >
                  <span class="el-dropdown-link">
                    <svg-icon icon-class="Edit" class="icon"></svg-icon>
                  </span>
                  <!-- 任务执行人 -->
                  <el-dropdown-menu slot="dropdown" class="tabledropdowndiv">
                    <el-input
                      v-model="searchText"
                      :placeholder="$t('OverwriteOperation.Searchsource1')"
                      class="iconp_input"
                    ></el-input>
                    <el-dropdown-item
                      v-if="dropdownLists.length === 0"
                      command="-1"
                      >{{ $t("OverwriteOperation.warningmessage14") }}
                    </el-dropdown-item>
                    <el-dropdown-item
                      v-for="j in filteredOptions"
                      :key="j.member_id"
                      :command="{ id: j.member_id, name: j.nickname }"
                      >{{ j.nickname }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <div class="nickname_div">
                <p class="btmp overflowEllipsis">
                  {{
                    internalRowData.nickname === ""
                      ? $t("ProjectManagement.notSet")
                      : internalRowData.nickname
                  }}
                </p>

                <el-button
                  type="text"
                  @click="handleloseClick"
                  v-if="internalRowData.nickname"
                  ><i class="el-icon-circle-close close_i"></i
                ></el-button>
              </div>
            </div>
            <div class="btm_center">
              <!-- Completed on -->
              <p class="title_p">{{ $t("ProjectManagement.Completedon") }}</p>
              <p class="btmp">
                {{
                  !internalRowData.delivery_time ||
                  internalRowData.delivery_time == "0"
                    ? "--"
                    : internalRowData.delivery_time
                }}
              </p>
            </div>
            <div class="btm_right">
              <!-- Task URL -->
              <p class="title_p">{{ $t("ProjectManagement.TaskURL") }}</p>
              <div class="TaskURL_div">
                <a
                  :href="internalRowData.newTaskUrl"
                  target="_blank"
                  rel="noopener noreferrer"
                  id="internala"
                  @click="changeDialogVisible(false)"
                  >{{ internalRowData.task_url }}</a
                >
                <svg-icon
                  icon-class="Copy"
                  class="icon"
                  @click="copyClick(internalRowData)"
                ></svg-icon>
              </div>
            </div>
          </div>
          <!--      转写提交对比报告    -->
          <div class="last_div" v-if="internalRowData.workflow === '1'">
            <p class="title_p">{{ $t("ProjectManagement.STTUrl1") }}</p>
            <div class="TaskURL_div">
              <a
                :href="internalRowData.sttUrl"
                target="_blank"
                rel="noopener noreferrer"
                id="internala"
                @click="changeDialogVisible(false)"
                >{{ internalRowData.task_url }}</a
              >
              <svg-icon
                icon-class="Copy"
                class="icon"
                @click="copyClick(internalRowData.sttUrl, -1)"
              ></svg-icon>
            </div>
          </div>
          <!--      GPT翻译报告    -->
          <div class="last_div" v-if="internalRowData.workflow === '2'">
            <p class="title_p">{{ $t("ProjectManagement.ProofUrl1") }}</p>
            <div class="TaskURL_div">
              <a
                :href="internalRowData.gatUrl"
                target="_blank"
                rel="noopener noreferrer"
                id="internala"
                @click="changeDialogVisible(false)"
                >{{ internalRowData.task_url }}</a
              >
              <svg-icon
                icon-class="Copy"
                class="icon"
                @click="copyClick(internalRowData.gatUrl, -1)"
              ></svg-icon>
            </div>
          </div>
          <!--      翻译质量报告    -->
          <div
            class="last_div"
            v-if="
              internalRowData.workflow == '2' || internalRowData.workflow == '3'
            "
          >
            <p class="title_p">{{ $t("ProjectManagement.ReportURL1") }}</p>
            <div class="TaskURL_div">
              <a
                :href="internalRowData.reportUrl"
                target="_blank"
                rel="noopener noreferrer"
                id="internala"
                @click="changeDialogVisible(false)"
                >{{ internalRowData.task_url }}</a
              >
              <svg-icon
                icon-class="Copy"
                class="icon"
                @click="copyClick(internalRowData.reportUrl, -1)"
              ></svg-icon>
            </div>
          </div>
          <!--      校轴对比报告    -->
          <div class="last_div" v-if="internalRowData.workflow == '5'">
            <p class="title_p">{{ $t("ProjectManagement.STQUrl1") }}</p>
            <div class="TaskURL_div">
              <a
                :href="internalRowData.stqUrl"
                target="_blank"
                rel="noopener noreferrer"
                id="internala"
                @click="changeDialogVisible(false)"
                >{{ internalRowData.task_url }}</a
              >
              <svg-icon
                icon-class="Copy"
                class="icon"
                @click="copyClick(internalRowData.stqUrl, -1)"
              ></svg-icon>
            </div>
          </div>
        </div>
      </div>
      <SplitTask
        v-if="dialogtitle === $t('ProjectDetails.dropdownList.name9')"
        ref="SplitTask"
        :jobid="commandRow.job_id"
        :projectid="project_id"
        :jobtype="commandRow.job_type"
        @splitDeleteMethod="split_delete_method"
      ></SplitTask>
      <ProjectInformation
        v-if="dialogtitle === $t('ProjectDetails.buttonList.name4')"
      ></ProjectInformation>
      <template
        v-slot:title
        v-if="
          dialogtitle === $t('ProjectDetails.buttonList.name5') ||
          dialogtitle === rowData.name ||
          dialogtitle === internalRowData.job_name
        "
      >
        <div
          v-if="dialogtitle === internalRowData.job_name"
          class="tasknameslotTitle"
        >
          <p class="title_p overflowEllipsis">
            <el-tooltip
              class="item"
              effect="dark"
              :content="dialogtitle"
              placement="top-start"
              :visible-arrow="false"
            >
              <span>{{ dialogtitle }}</span>
            </el-tooltip>
          </p>
        </div>
        <div
          class="slottitle"
          v-if="dialogtitle === $t('ProjectDetails.buttonList.name5')"
        >
          <p>{{ dialogtitle }}</p>
          <!-- 上传新的术语表 -->
          <!-- <el-button type="text" @click="showInnerDialog(4)"
            >{{ $t("ProjectDetails.Uploadanewglossary") }}
          </el-button> -->
        </div>
        <div class="tasknameslotTitle" v-if="dialogtitle === rowData.name">
          <p class="title_p overflowEllipsis">
            <el-tooltip
              class="item"
              effect="dark"
              :content="dialogtitle"
              placement="top-start"
              :visible-arrow="false"
            >
              <span>{{ dialogtitle }}</span>
            </el-tooltip>
          </p>
          <div class="tasktitlebtn">
            <!-- 项目详情 二次弹窗 -->
            <div>
              <!--  全局内容替换 -->
              <el-button type="text" @click="showInnerDialog(8)" v-if="isOwer1">
                <div class="svgbtn">
                  <svg-icon icon-class="Edit" class="icon"></svg-icon>
                  <p>{{ $t("ProjectDetails.text4") }}</p>
                </div>
              </el-button>
              <!--  清除画面字 -->
              <el-button type="text" @click="showInnerDialog(6)" v-if="isOwer1">
                <div class="svgbtn">
                  <svg-icon icon-class="Edit" class="icon"></svg-icon>
                  <p>{{ $t("ProjectDetails.text3") }}</p>
                </div>
              </el-button>
              <!--  编辑术语 -->
              <el-button
                type="text"
                @click="showInnerDialog(7)"
                v-if="
                  isOwer1 &&
                  rowData.job_type !== '1' &&
                  rowData.job_type !== '6'
                "
              >
                <div class="svgbtn">
                  <svg-icon icon-class="Edit" class="icon"></svg-icon>
                  <p>{{ $t("ProjectDetails.text2") }}</p>
                </div>
              </el-button>
              <!--  View Log -->
              <el-button type="text" @click="showInnerDialog(1)">
                <div class="svgbtn">
                  <svg-icon icon-class="TextVoice" class="icon"></svg-icon>
                  <p>{{ $t("ProjectDetails.ViewLog") }}</p>
                </div>
              </el-button>
              <!--    Edit TM-->
              <el-button
                type="text"
                @click="showInnerDialog(2)"
                v-if="rowData.job_type !== '1' && rowData.job_type !== '6'"
              >
                <div class="svgbtn">
                  <svg-icon icon-class="Edit" class="icon"></svg-icon>
                  <p>{{ $t("ProjectDetails.EditTM") }}</p>
                </div>
              </el-button>
              <el-button type="text" @click="showInnerDialog(3)" v-if="isOwer">
                <div class="svgbtn">
                  <svg-icon icon-class="delete" class="icon"></svg-icon>
                  <p>{{ $t("ProjectDetails.DeleteTask") }}</p>
                </div>
              </el-button>
            </div>
            <div class="lastdiv"></div>
          </div>
        </div>
      </template>
      <!-- 术语 -->
      <TermDialog
        v-if="dialogtitle == $t('ProjectDetails.buttonList.name5')"
        @updateClick="updateClick"
      ></TermDialog>
      <!-- 指派/更改任务处理人 -->
      <AppointedTask
        v-if="
          dialogtitle == $t('ProjectDetails.AssignChangeTaskHandler') ||
          dialogtitle == $t('ProjectDetails.AssignChangeTaskHandler1')
        "
        :jobid="isProject ? '0' : commandRow.job_id"
        :jobType="isProject ? '7' : commandRow.job_type"
        :projectid="project_id"
        :isProject="isProject"
        ref="AppointedTask"
      ></AppointedTask>
      <ProjectDetails
        v-if="dialogtitle === rowData.name"
        ref="ProjectDetails"
        :Project_info="rowData"
        @ProjectDetailsgetData="ProjectDetailsgetData"
        @claseDialog="claseDialog"
      ></ProjectDetails>
      <!-- 批量替换任务原文SRT -->
      <div
        v-if="dialogtitle == $t('ProjectDetails.dropdownBatchList.name36')"
        class="content_center"
      >
        <div class="content_center_4">
          <div class="content_center_div">
            <div class="uploadClass" style="margin-bottom: 6px">
              <div style="color: #adb0bc">
                {{ $t("ProjectDetails.uploadText") }}
              </div>
            </div>
            <el-input
              v-model="zipUpload"
              class="input-with-select xlsxinput"
              @input="zipUpload = ''"
            >
              <el-upload
                slot="append"
                class="upload-demo"
                ref="zipUpload"
                name="file"
                :data="uploadData"
                :action="$uploadURL + '/videojob/upload_replace_srt_zip_file'"
                :on-change="uploadZipChange"
                :show-file-list="false"
                :on-success="uploadSuccess"
                :on-progress="uploadProgress"
                :auto-upload="false"
                :with-credentials="true"
                :limit="1"
              >
                <el-button @click="srtupload('zipUpload', 5)" type="text">
                  <div class="uploadbtn">
                    <svg-icon icon-class="upload" class="icon"></svg-icon>
                    <!-- 上传 -->
                    <span class="uploadspan">{{
                      $t("CommonName.Upload")
                    }}</span>
                  </div>
                </el-button>
              </el-upload>
            </el-input>
          </div>
        </div>
      </div>
    </DialogComVue>
    <!--    创建任务-->
    <ExternalCreateTaskVue
      v-if="showCreateTaskdialog"
      :showdialog="showCreateTaskdialog"
      :DetermineClickCreateTask="DetermineClickCreateTask"
      :Clonejobinfo="Clonejobinfo"
      @claseCreateTaskDialog="claseCreateTaskDialog"
      @Creationcompleted="Creationcompleted"
    ></ExternalCreateTaskVue>
    <!--    批量创建 硬字幕识别 任务-->
    <BatchCreateHardSubtitleRecognitionTasks
      v-if="showBatchCreateHardSubtitleRecognitiondialog"
      :showdialog="showBatchCreateHardSubtitleRecognitiondialog"
      :languageoptions="languageoptions1"
      @closeHardSubtitleRecognitiondialog="closeHardSubtitleRecognitiondialog"
      @CreatHardSubtitleRecognitionTasks="CreatHardSubtitleRecognitionTasks"
    >
    </BatchCreateHardSubtitleRecognitionTasks>
    <!--    批量创建 硬字幕识别（画面字） 任务-->
    <BatchCreateHardSubHua
      v-if="showBatchCreateHardSubHuadialog"
      :showdialog="showBatchCreateHardSubHuadialog"
      :languageoptions="languageoptions1"
      @closeHardSubtitleRecognitiondialog="closeHardSubtitleRecognitiondialog"
      @CreatHardSubtitleRecognitionTasks="CreatHardSubtitleRecognitionTasks"
    >
    </BatchCreateHardSubHua>
    <!--    导入任务-->
    <ImportTask
      v-if="showImportTask"
      :showdialog="showImportTask"
      @clickImportTaskDialog="clickImportTaskDialog"
      @requestImportTask="requestImportTask"
    ></ImportTask>
    <BatchCreationTaskVue
      v-if="showBatchCreatingTask"
      :showdialog="showBatchCreatingTask"
      @clickBatchCreatingTask="clickBatchCreatingTask"
      @createdBatchTask="createdBatchTask"
    ></BatchCreationTaskVue>
    <!-- 字幕设置 -->
    <CaptionSettings
      v-if="showCaptionSettings"
      :showdialog="showCaptionSettings"
      :projectInfo="projectInfo"
      @clickCaptionSettings="clickCaptionSettings"
    ></CaptionSettings>
    <!-- 批量指派任务执行人 -->
    <OperatorDialog
      v-if="btnchId === '6_1'"
      :showdialog="btnchId === '6_1'"
      :showFlag="1"
      :multipleSelection="multipleSelection"
      @closeOperator="closeOperator"
      @clickOperator="clickOperator"
    ></OperatorDialog>
    <!--    批量更换任务执行人-->
    <ReplaceExecutor
      v-if="btnchId === '6_2'"
      :showdialog="btnchId === '6_2'"
      :multipleSelection="multipleSelection"
      @closeOperator="closeOperator"
      @clickOperator="clickOperator"
    ></ReplaceExecutor>
    <!-- 二次 弹窗 -->
    <el-dialog
      :width="[4, 5, 7, 8].includes(this.showInnerDialogs) ? '888px' : '1024px'"
      :title="innerVisibleTitle"
      :visible.sync="innerVisible"
      @close="CloseinnerVisible"
      append-to-body
      :top="[4, 5].includes(this.showInnerDialogs) ? '10%' : '3%'"
      center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :destroy-on-close="true"
    >
      <div
        class="content_center"
        :class="showInnerDialogs == 4 ? 'margintop0' : ''"
      >
        <div class="content_center_1" v-if="showInnerDialogs == 1">
          <div
            class="tableborderClass tableClass borderTableStyle"
            :class="dialogtableData.length == 0 ? 'tablebtmborder' : ''"
          >
            <el-table
              :row-style="{
                height: 32 + 'px',
                background: '#393c4e',
                color: '#ffffff',
              }"
              :cell-style="{ padding: 0 + 'px' }"
              :header-cell-style="{
                height: 32 + 'px',
                padding: '0px',
                color: '#adb0bc',
                background: '#454758',
              }"
              :data="dialogtableData"
              tooltip-effect="dark"
              style="width: 100%"
              :max-height="500"
              border
            >
              <!-- <div slot="empty" class="newnoDataDiv">
                <svg-icon icon-class="NoData" class="noDataicon"></svg-icon>
                <p>{{ $t("CommonName.noData") }}</p>
              </div> -->
              <!--操作时间  -->
              <el-table-column
                prop="type"
                :label="$t('ProjectDetails.version')"
              >
              </el-table-column>
              <el-table-column
                prop="create_time"
                :label="$t('ProjectDetails.versionCreateOn')"
                width="180"
              >
              </el-table-column>
              <!-- 操作 -->
              <el-table-column width="37">
                <template slot-scope="scope">
                  <div
                    class="operationClass"
                    @click="job_view_log_revert_method(scope.row.job_log_id)"
                  >
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :enterable="false"
                      :content="$t('ProjectDetails.revivification')"
                      placement="top-start"
                    >
                      <svg-icon
                        icon-class="RestoreTargetText"
                        style="color: #cbd7e8"
                      ></svg-icon>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!-- Main TM -->
        <div class="content_center_2" v-if="showInnerDialogs == 2">
          <!-- <div
            class="content_center_div"
            v-if="['2', '3', '4', '5'].includes(rowData.job_type)"
          >
            <p>{{ $t("CommonName.MainTMoptional") }}</p>
            <el-select v-model="select" class="selectclass" value-key="tm_id">
              <el-option
                v-for="item in selectList"
                :key="item.tm_id"
                :label="item.name"
                :value="item"
                :value-key="item.tm_id"
              >
              </el-option>
            </el-select>
          </div> -->
          <div
            class="content_center_div"
            v-if="['2', '3', '4', '5', '7'].includes(rowData.job_type)"
          >
            <p>{{ $t("CommonName.TempTMToptional") }}</p>
            <el-select v-model="select1" class="selectclass" value-key="tm_id">
              <el-option
                v-for="item in selectList1"
                :key="item.tm_id"
                :label="item.name"
                :value="item"
                :value-key="item.tm_id"
              >
              </el-option>
            </el-select>
          </div>
          <div
            class="content_center_div"
            v-if="['4', '5', '7'].includes(rowData.job_type)"
          >
            <p>{{ $t("CommonName.TempTMEoptional") }}</p>
            <el-select v-model="select2" class="selectclass" value-key="tm_id">
              <el-option
                v-for="item in selectList1"
                :key="item.tm_id"
                :label="item.name"
                :value="item"
                :value-key="item.tm_id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="content_center_4" v-if="showInnerDialogs == 4">
          <el-form
            label-position="top"
            :model="formLabelAlign"
            label-width="100%"
            @keydown.enter.prevent
          >
            <!-- 术语表名称 -->
            <el-form-item :label="$t('TermBaseManagement.GlossaryName')">
              <el-input v-model="formLabelAlign.name" class="input1"></el-input>
            </el-form-item>
            <el-form-item>
              <div slot="label" class="selectClassp">
                <p>{{ $t("CommonName.Sourcelanguage") }}</p>
                <!-- <p style="margin-left: 40px">
                  {{ $t("CommonName.Targetlanguage") }}
                </p> -->
              </div>
              <el-select
                v-model="formLabelAlign.select1"
                :placeholder="$t('CommonName.Pleaseselect')"
                class="input1"
                filterable
              >
                <el-option
                  v-for="item in languageoptions1"
                  :key="item.language_id"
                  :label="item.cname"
                  :value="item.language_id"
                >
                </el-option>
              </el-select>
              <!-- <el-select
                v-model="formLabelAlign.select2"
                :placeholder="$t('CommonName.Pleaseselect')"
                class="selectclass selectClass1"
                filterable
              >
                <el-option
                  v-for="item in languageoptions1"
                  :key="item.language_id"
                  :label="item.cname"
                  :value="item.language_id"
                >
                </el-option>
              </el-select> -->
            </el-form-item>
            <el-form-item label="">
              <div slot="label" class="uploadClass">
                <!-- <p>上传术语文件（.xlsx）</p> -->
                <div>{{ $t("TermBaseManagement.Uploadtermfile") }}</div>
                <el-button type="text" @click="Download">
                  <!-- 下载术语表模板 (.xlsx) -->
                  {{ $t("TermBaseManagement.DownloadGlossaryTemplate") }}
                </el-button>
              </div>
              <el-input
                v-model="formLabelAlign.upload"
                class="input-with-select xlsxinput"
                @input="formLabelAlign.upload = ''"
              >
                <el-upload
                  slot="append"
                  class="upload-demo"
                  ref="upload"
                  name="term_file"
                  :data="uploadData"
                  :action="$uploadURL + '/term/create_term_set'"
                  :on-change="uploadChange"
                  :show-file-list="false"
                  :on-success="uploadTermSuccess"
                  :on-progress="uploadProgress"
                  :auto-upload="false"
                  :with-credentials="true"
                  :limit="1"
                >
                  <el-button @click="srtupload('upload', 2)" type="text">
                    <div class="uploadbtn">
                      <svg-icon icon-class="upload" class="icon"></svg-icon>
                      <!-- 上传 -->
                      <span class="uploadspan">{{
                        $t("CommonName.Upload")
                      }}</span>
                    </div>
                  </el-button>
                </el-upload>
              </el-input>
            </el-form-item>
            <el-form-item :label="$t('CommonName.remarks')">
              <el-input
                type="textarea"
                class="textAreaClassHeight"
                :maxlength="150"
                v-model="formLabelAlign.comments"
                resize="none"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="content_center_4" v-if="showInnerDialogs == 5">
          <el-form
            label-position="top"
            :model="formLabelAlign"
            label-width="100%"
            @keydown.enter.prevent
          >
            <!-- 术语表名称 -->
            <el-form-item label="">
              <div slot="label" class="uploadClass">
                <!-- <p>上传术语文件（.xlsx）</p> -->
                <div>{{ $t("TermBaseManagement.Uploadtermfile") }}</div>
                <el-button type="text" @click="Download">
                  <!-- 下载术语表模板 (.xlsx) -->
                  {{ $t("TermBaseManagement.DownloadGlossaryTemplate") }}
                </el-button>
              </div>
              <el-input
                v-model="formTermData.upload"
                class="input-with-select xlsxinput"
                @input="formTermData.upload = ''"
              >
                <el-upload
                  slot="append"
                  class="upload-demo"
                  ref="uploadUpdate"
                  name="term_file"
                  :data="uploadData"
                  :action="$uploadURL + '/term/upload_set_term'"
                  :on-change="uploadChange"
                  :show-file-list="false"
                  :on-success="uploadTermSuccess"
                  :on-progress="uploadProgress"
                  :auto-upload="false"
                  :with-credentials="true"
                  :limit="1"
                >
                  <el-button @click="srtupload('uploadUpdate', 3)" type="text">
                    <div class="uploadbtn">
                      <svg-icon icon-class="upload" class="icon"></svg-icon>
                      <!-- 上传 -->
                      <span class="uploadspan">{{
                        $t("CommonName.Upload")
                      }}</span>
                    </div>
                  </el-button>
                </el-upload>
              </el-input>
            </el-form-item>
            <el-form-item :label="''">
              <div class="step1Center_center_1 radioClass radioClass50">
                <el-radio-group v-model="formTermData.mode">
                  <!-- <el-radio label="0" border
                    >{{ $t("TermBaseManagement.updateTermRadio1") }}
                  </el-radio> -->
                  <el-radio label="0" border
                    >{{ $t("TermBaseManagement.updateTermRadio2") }}
                  </el-radio>
                </el-radio-group>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <!-- 编辑术语 -->
        <div class="content_center_4" v-if="showInnerDialogs == 7">
          <div
            class="step1Center_center_1 radioClass radioClass50"
            style="margin-bottom: 10px"
          >
            <el-radio-group v-model="term_file_mode">
              <el-radio label="2" border style="width: 50%"
                >{{ $t("ExternalCreateTask.termText2") }}
              </el-radio>
              <el-radio label="1" border style="width: 50%"
                >{{ $t("ExternalCreateTask.termText3") }}
              </el-radio>
            </el-radio-group>
          </div>
          <div class="content_center_div" v-show="term_file_mode == '2'">
            <p>{{ $t("ExternalCreateTask.termText1") }}</p>
            <el-select
              v-model="term_select"
              filterable
              class="selectclass input1"
            >
              <el-option
                v-for="item in termData"
                :key="item.term_file_id"
                :label="item.name"
                :value="item.term_file_id"
              >
              </el-option>
            </el-select>
          </div>
          <div
            class="content_center_div"
            style="margin-bottom: 10px"
            v-show="term_file_mode == '1'"
          >
            <p>{{ $t("ExternalCreateTask.termText4") }}</p>
            <el-input
              v-model="term_name"
              :maxlength="100"
              class="input1"
            ></el-input>
          </div>
          <div
            class="content_center_div"
            style="margin-bottom: 10px"
            v-show="term_file_mode == '1'"
          >
            <p>{{ $t("ExternalCreateTask.termText5") }}</p>
            <div class="contentClass">
              <el-select
                v-model="term_lang"
                :placeholder="$t('TMManagement.select')"
                filterable
                class="selectclass input1"
              >
                <el-option
                  v-for="item in languageoptions1"
                  :key="item.language_id"
                  :label="item.cname"
                  :value="item.language_id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="content_center_div" v-show="term_file_mode == '1'">
            <p>{{ $t("CommonName.Targetlanguage") }}</p>
            <div class="contentClass">
              <el-select
                v-model="tlangs"
                multiple
                :placeholder="$t('TMManagement.select')"
                filterable
                class="selectclass input1"
              >
                <el-option
                  v-for="item in languageoptions1"
                  :key="item.language_id"
                  :label="item.cname"
                  :value="item.language_id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <!-- 全局内容替换 -->
        <div class="content_center_4" v-if="showInnerDialogs == 8">
          <div
            class="step1Center_center_1 radioClass radioClass50"
            style="margin-bottom: 10px"
          >
            <el-radio-group v-model="find_replace_mode">
              <el-radio label="1" border style="width: 50%"
                >{{ $t("CommonName.lookupText1") }}
              </el-radio>
              <el-radio label="2" border style="width: 50%"
                >{{ $t("CommonName.lookupText2") }}
              </el-radio>
            </el-radio-group>
          </div>
          <div class="content_center_div" style="margin-bottom: 10px">
            <p>{{ $t("OverwriteOperation.Find") }}</p>
            <el-input
              v-model="find_input"
              :maxlength="300"
              class="input1"
            ></el-input>
            <div class="loockUpandReplaceDiv">
              <span v-if="find_input !== find_input.trim()">
                {{ $t("CommonName.loockUptext") }}</span
              >
            </div>
          </div>
          <div class="content_center_div" style="margin-bottom: 10px">
            <p>{{ $t("OverwriteOperation.Replacewith") }}</p>
            <el-input
              v-model="replace_input"
              :maxlength="300"
              class="input1"
            ></el-input>
            <div class="loockUpandReplaceDiv">
              <span v-if="replace_input !== replace_input.trim()">{{
                $t("CommonName.Replacetext")
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
        style="margin-top: 24px; margin-right: 24px"
        v-if="[2, 4, 5, 7, 8].includes(this.showInnerDialogs)"
      >
        <el-button type="info" @click="CancelClick" class="setpadding23"
          >{{ $t("CommonName.Cancel") }}
        </el-button>
        <el-button type="primary" @click="SureClick" class="setpadding23"
          >{{
            [4, 5, 8].includes(this.showInnerDialogs)
              ? $t("ProjectManagement.confirm")
              : $t("CommonName.Sure")
          }}
        </el-button>
      </div>
    </el-dialog>
    <!-- 下载所有任务字幕文件 -->
    <el-dialog
      width="525px"
      :title="$t('ProjectManagement.DownloadSelectedTaskFiles')"
      :visible.sync="innerDownloadVisible"
      @close="closeinnerDialog"
      v-if="innerDownloadVisible"
      append-to-body
      top="10%"
      center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="content_center">
        <div class="content_center_3">
          <div class="content_center_div">
            <div class="checkboxClass">
              <el-checkbox-group v-model="checkboxDownload">
                <div>
                  <el-checkbox
                    v-for="i in checkboxDownloadList"
                    :label="i.id"
                    :key="i.id"
                    name="type"
                    border
                    >{{ i.name }}
                  </el-checkbox>
                </div>
              </el-checkbox-group>
            </div>
          </div>
        </div>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
        style="margin-top: 24px; margin-right: 24px"
      >
        <el-button type="info" @click="CanceldownloadClick" class="setpadding23"
          >{{ $t("CommonName.Cancel") }}
        </el-button>
        <el-button
          type="primary"
          @click="downloadSelectedSure"
          class="setpadding23"
          >{{ $t("CommonName.download") }}
        </el-button>
      </div>
    </el-dialog>
    <!-- 导出所有任务文件内容信息 -->
    <el-dialog
      width="525px"
      :title="$t('ProjectDetails.dropdownBatchList.name6')"
      :visible.sync="innerExportVisible"
      @close="closeinnerDialog"
      v-if="innerExportVisible"
      append-to-body
      top="10%"
      center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="content_center">
        <div class="content_center_3">
          <div class="content_center_div">
            <div class="checkboxClass">
              <el-checkbox-group v-model="checkboxExport">
                <div>
                  <el-checkbox
                    v-for="i in checkboxExportList"
                    :label="i.id"
                    :key="i.id"
                    name="type"
                    border
                    >{{ i.name }}
                  </el-checkbox>
                </div>
              </el-checkbox-group>
            </div>
            <!-- 备注类型 -->
            <p>{{ $t("CommonName.remarks") }}</p>
            <el-select
              v-model="commentsSelect"
              multiple
              class="content_center_div1 selectclass100"
              :placeholder="$t('CommonName.Pleaseselect')"
              filterable
            >
              <el-option
                v-for="item in ProjectCommentsData"
                :key="item.comment_id"
                :label="item.comment_name"
                :value="item.comment_id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
        style="margin-top: 24px; margin-right: 24px"
      >
        <el-button type="info" @click="CanceldownloadClick" class="setpadding23"
          >{{ $t("CommonName.Cancel") }}
        </el-button>
        <el-button
          type="primary"
          @click="ExportSelectedSure"
          class="setpadding23"
          >{{ $t("CommonName.download") }}
        </el-button>
      </div>
    </el-dialog>
    <div
      class="progressdiv"
      v-if="percentage > 0"
      v-loading.fullscreen.lock="loading"
      element-loading-custom-class="disaplaynoneicon"
      element-loading-background="rgba(0, 0, 0, 0.3)"
    >
      <el-progress
        type="circle"
        :percentage="percentage"
        :stroke-width="10"
      ></el-progress>
    </div>
    <!-- 移动任务 -->
    <DialogComVue
      :dialogVisible="dialogMoveVisible"
      @determineClick="determineMoveClick"
      @changeDialogVisible="changeDialogMoveVisible"
      :footerbtnName="$t('CommonName.OK')"
      :closefooterbtnName="$t('CommonName.Cancel')"
      :width="'650px'"
      :top="'5%'"
      :title="$t('ProjectManagement.MoveToFileTitle')"
      v-if="dialogMoveVisible"
    >
      <div>
        <div class="moveProjectSearch">
          <el-input
            v-model="filterText"
            :placeholder="$t('CommonName.search')"
            maxlength="100"
            clearable
          ></el-input>
        </div>
        <div class="moveFolder langselectclass">
          <el-tree
            ref="tree"
            :data="moveFolderList"
            :props="defaultProps"
            @node-click="handleNodeClick"
            :filter-node-method="filterNode"
            empty-text=""
          >
            <span class="custom-tree-node" slot-scope="{ node }">
              <svg-icon
                :icon-class="'project2'"
                class="icon svgiconclass"
              ></svg-icon>
              <span class="folderp">{{ node.label }}</span>
            </span>
          </el-tree>
        </div>
      </div>
    </DialogComVue>
    <!-- 批量修改任务名称 -->
    <el-dialog
      width="610px"
      :title="$t('ProjectDetails.dropdownBatchList.name23')"
      :visible.sync="dialogReNameVisible"
      @close="closeinnerDialog"
      v-if="dialogReNameVisible"
      append-to-body
      top="10%"
      center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="content_center">
        <div class="content_center_3">
          <div class="content_center_div">
            <div class="step1Center_center_1 radioClass">
              <p>{{ $t("ProjectDetails.labelText") }}</p>
              <el-radio-group v-model="radio2">
                <el-radio
                  v-for="i in radioList2"
                  :key="i.label"
                  :label="i.label"
                  border
                  >{{ i.name }}</el-radio
                >
              </el-radio-group>
            </div>
          </div>
          <div class="content_center_div" v-if="radio2 == '0'">
            <div class="content_center_div1">
              <p>
                {{ $t("ProjectDetails.labelText2") }}
              </p>
              <el-input
                v-model="replaceinput3"
                maxlength="50"
                placeholder=""
                class="selectclass"
              >
              </el-input>
            </div>
          </div>
          <div class="content_center_div" v-if="radio2 == '1'">
            <div class="content_center_div1">
              <p>
                {{ $t("CommonName.replace1") }}
              </p>
              <el-input
                v-model="replaceinput1"
                maxlength="50"
                placeholder=""
                class="selectclass"
              >
              </el-input>
            </div>
          </div>
          <div class="content_center_div" v-if="radio2 == '1'">
            <div class="content_center_div1">
              <!-- Subtitle translation deadline -->
              <p>
                {{ $t("CommonName.replace2") }}
              </p>
              <el-input
                v-model="replaceinput2"
                maxlength="50"
                placeholder=""
                class="selectclass"
              >
              </el-input>
            </div>
          </div>
        </div>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
        style="margin-top: 24px; margin-right: 24px"
      >
        <el-button type="info" @click="closeinnerDialog" class="setpadding23"
          >{{ $t("CommonName.Cancel") }}
        </el-button>
        <el-button
          type="primary"
          @click="dialogReNameMethod"
          class="setpadding23"
          >{{ $t("CommonName.OK") }}
        </el-button>
      </div>
    </el-dialog>
    <!--字幕样式设置-->
    <SubtitleStyle
      :dialogVisible="btnchId === '5_2'"
      :project_id="project_id"
      :job_list="tableData"
      :project_type="0"
      @changeAutoVisible="closeOperator"
      v-if="btnchId === '5_2'"
    ></SubtitleStyle>
    <!-- 下载合成视频 -->
    <MediaDownload
      v-if="dialogDownloadVisible"
      :showDialog="dialogDownloadVisible"
      :jobIds="jobIds"
      @closeDialog="closeDialogDownload"
    ></MediaDownload>
    <!-- 从其他项目导入 -->
    <DialogComVue
      :dialogVisible="dialogImportVisible"
      @determineClick="determineImportClick"
      @changeDialogVisible="changedialogImportVisible"
      :footerbtnName="$t('CommonName.OK')"
      :closefooterbtnName="$t('CommonName.Cancel')"
      :width="'650px'"
      :top="'5%'"
      :title="import_title"
      v-if="dialogImportVisible"
    >
      <div class="content_center">
        <div class="content_center_3">
          <div
            class="content_center_div"
            v-if="['1', '2', '3', '4', '5'].includes(import_type)"
          >
            <p>
              {{
                import_type == "5"
                  ? $t("ProjectDetails.name3")
                  : $t("ProjectManagement.projectName")
              }}
            </p>
            <el-select
              v-model="projectSelected"
              @change="projectSelectedChange"
              class="content_center_div1 selectclass100"
              :placeholder="$t('CommonName.Pleaseselect')"
              filterable
            >
              <el-option
                v-for="item in projectList"
                :key="item.project_id"
                :label="item.project_name"
                :value="item.project_id"
              >
              </el-option>
            </el-select>
          </div>
          <div
            class="content_center_div"
            v-if="['2', '4'].includes(import_type)"
          >
            <p>{{ $t("CommonName.TaskName") }}</p>
            <el-select
              v-model="jobSelected"
              class="content_center_div1 selectclass100"
              :placeholder="$t('CommonName.Pleaseselect')"
              @change="jobSelectedChange"
              filterable
            >
              <el-option
                v-for="item in jobList"
                :key="item.job_id"
                :label="item.job_name"
                :value="item.job_id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="content_center_div" v-if="['4'].includes(import_type)">
            <p>{{ $t("ProjectDetails.sworkflow") }}</p>
            <el-select
              v-model="sWorkflowSelected"
              class="content_center_div1 selectclass100"
              :placeholder="$t('CommonName.Pleaseselect')"
              filterable
            >
              <el-option
                v-for="(item, index) in sWorkflows"
                :key="index"
                :label="item.name"
                :value="item.workflow_id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="content_center_div" v-if="['4'].includes(import_type)">
            <p>{{ $t("ProjectDetails.tworkflow") }}</p>
            <el-select
              v-model="tWorkflowSelected"
              class="content_center_div1 selectclass100"
              :placeholder="$t('CommonName.Pleaseselect')"
              filterable
            >
              <el-option
                v-for="(item, index) in tWorkflows"
                :key="index"
                :label="item.name"
                :value="item.workflow_id"
              >
              </el-option>
            </el-select>
          </div>
          <div
            style="margin-top: 24px; color: rgb(255, 255, 255)"
            class="content_center_div"
            v-if="['5'].includes(import_type)"
          >
            <p>{{ $t("ProjectDetails.name2") }}</p>

            <div class="content_center_1">
              <div
                class="tableborderClass tableClass borderTableStyle"
                :class="dialogtableData.length == 0 ? 'tablebtmborder' : ''"
              >
                <el-table
                  :row-style="{
                    height: 32 + 'px',
                    background: '#393c4e',
                    color: '#ffffff',
                  }"
                  :cell-style="{ padding: 0 + 'px' }"
                  :header-cell-style="{
                    height: 32 + 'px',
                    padding: '0px',
                    color: '#adb0bc',
                    background: '#454758',
                  }"
                  :data="multipleSelection"
                  tooltip-effect="dark"
                  style="width: 100%"
                  :max-height="200"
                  border
                >
                  <el-table-column
                    prop="name"
                    :label="$t('CommonName.taskname')"
                  >
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
          <div
            class="content_center_div radioClass"
            v-if="['6'].includes(import_type)"
          >
            <!-- "Main ASR Engine" -->
            <p>{{ $t("CommonName.AITMode.title") }}</p>
            <el-radio-group v-model="radio6" style="width: 100%">
              <el-radio
                v-for="i in radioList6"
                :key="i.label"
                :label="i.label"
                :disabled="i.disabled"
                style="width: 33.3%; margin-bottom: 8px"
                border
                >{{ i.name }}
              </el-radio>
            </el-radio-group>
          </div>
          <div style="margin-top: 24px; color: rgb(255, 255, 255)">
            {{ import_tips }}
          </div>
        </div>
      </div>
    </DialogComVue>
  </div>
</template>
<script>
import DialogComVue from "@/components/DialogCom.vue";
import ProjectInformation from "./Dialogs/ProjectInformation.vue";
import TermDialog from "./Dialogs/TermDialog.vue";
import CaptionSettings from "./Dialogs/CaptionSettings.vue";
import AppointedTask from "./Dialogs/AppointedTask.vue";
import ProjectDetails from "./Dialogs/ProjectDetails.vue";
import ExternalCreateTaskVue from "./Dialogs/ExternalCreateTask.vue";
import ImportTask from "./Dialogs/ImportTask.vue";
import BatchCreationTaskVue from "./Dialogs/BatchCreationTask.vue";
import OperatorDialog from "./Dialogs/OperatorDialog.vue";
import BatchCreateHardSubtitleRecognitionTasks from "@/views/TeamMain/ProjectManagement/Dialogs/BatchCreateHardSubtitleRecognitionTasks.vue";
import SplitTask from "@/views/TeamMain/ProjectManagement/Dialogs/SplitTask.vue";
import ReplaceExecutor from "@/views/TeamMain/ProjectManagement/Dialogs/ReplaceExecutor.vue";
import BatchCreateHardSubHua from "@/views/TeamMain/ProjectManagement/Dialogs/BatchCreateHardSubHua.vue";
import SubtitleStyle from "./Dialogs/SubtitleStyle.vue";
import MediaDownload from "./Dialogs/MediaDownload.vue";
import $ from "jquery";
import {
  onShowNameTipsMouseenter,
  addAndreplaceTags,
} from "@/assets/js/Publicmethods";
import {
  get_team_job_list,
  get_project_info,
  delete_job,
  get_pro_tm_list,
  get_language,
  set_job_user_batch,
  get_job_clone_info,
  update_job_name,
  get_job_tm_list,
  update_job_tm_list,
  rebuild_generate_waveform,
  run_gpt_jobs,
  set_job_close,
  get_job_view_log,
  job_view_log_revert,
  reject_job,
  run_mt_jobs,
  update_split_job,
  get_job_split_info,
  get_member_list,
  set_job_split_user,
  reject_split_job,
  add_term_set,
  get_project_termfile_list,
  get_project_comments,
} from "@/utils/https";
import { hideLoading, showLoading } from "@/utils/loading";
import config from "@/config";

export default {
  components: {
    SplitTask, //拆分
    DialogComVue,
    ProjectInformation, //项目信息
    TermDialog, //术语
    CaptionSettings, //字幕设置
    AppointedTask, //指派/更改任务处理人.
    ProjectDetails, //任务详情
    ExternalCreateTaskVue, //创建任务
    ImportTask, //导入任务
    BatchCreationTaskVue, //批量创建 弹窗
    OperatorDialog, //批量指派任务执行人
    BatchCreateHardSubtitleRecognitionTasks, //批量创建 硬字幕识别任务
    ReplaceExecutor, //批量更换任务执行人
    SubtitleStyle, //字幕样式设置
    MediaDownload, //下载合成视频
    BatchCreateHardSubHua, //批量创建 硬字幕识别画面字任务
  },
  created() {
    // this.title = this.$route.query.name;
    this.project_id = this.$route.query.id;
    this.folder_pid = this.$route.query.fid;
  },
  computed: {
    // 筛选 任务执行人
    filteredOptions() {
      return this.dropdownLists.filter((option) =>
        option.nickname.toLowerCase().includes(this.searchText.toLowerCase())
      );
    },
  },
  mounted() {
    // console.log(111111);
    this.get_language();
    hideLoading();
    showLoading(this.$t("CommonName.loadingmessage"));
    let info = JSON.parse(sessionStorage.getItem("info"));
    // this.user_id = info.user_id;
    if (info.role == 3) {
      this.isOwer1 = true;
    }
    this.get_project_info(this.project_id).then((res) => {
      // console.log(res);
      this.projectInfo = res;
      this.sort = res.sort_mode === "0" ? "asc" : "desc";
      this.title = res.project_name;
      this.get_team_job_list();
      this.isOwer = res.create_id == info.user_id;

      this.buttonList.forEach((item) => {
        if (this.isOwer1) {
          if (["0", "6"].includes(item.id)) {
            item.status = true;
          }
        }
        if (this.isOwer) {
          if (["1", "2"].includes(item.id)) {
            item.status = true;
          }
        }
      });

      this.dropdownBatchList.forEach((item) => {
        if (this.isOwer1) {
          if (["5", "6", "7", "9", "10", "16", "17", "20"].includes(item.id)) {
            item.status = true;
          }
          if (parseInt(res.wiitube_type) !== 0 && item.id === "8") {
            item.status = false;
          }
        }
      });
      this.dropdownList.forEach((item) => {
        if (this.isOwer1) {
          if (["1", "2", "8"].includes(item.id)) {
            item.status = true;
          }
        }
        if (this.isOwer) {
          if (["4", "5"].includes(item.id)) {
            item.status = true;
          }
        }
      });
    });
    this.$nextTick(() => {
      this.tableHeight = window.innerHeight - 250;
    });
    window.onresize = () => {
      this.tableHeight = window.innerHeight - 250;
    };
    window.addEventListener("keyup", this.windowkeyup, true);
    window.addEventListener("keydown", this.windowkeydown, true);
  },
  data() {
    return {
      btnchId: "", //批量更换任务执行人 6_2
      projectInfo: {}, //任务数据
      buttonLoading: false, //弹窗 确定按钮 loading
      innerVisible: false, //内层弹窗 开关
      innerVisibleTitle: "", //二次弹窗 标题
      showInnerDialogs: 0, //显示 哪个弹窗内容
      dialogtableData: [], //弹窗内表格
      selectList: [],
      selectList1: [],
      xlsupload: "", // 导入 excel
      showUpload: 0,
      select: {}, //主tm
      select1: {}, //临时tm t
      select2: {}, //临时tm e
      project_id: "",
      tags: [],
      title: "",
      popoverInput: "",
      visible: false,
      OperationprocessdropdownList: [
        {
          id: "1",
          name: this.$t("CommonName.statusName1"), //"转写",
        },
        {
          id: "2",
          name: this.$t("CommonName.statusName2"), // "翻译",
        },
        {
          id: "3",
          name:
            this.$t("CommonName.statusName1") +
            " > " +
            this.$t("CommonName.statusName2"), //"转写 翻译",
        },
        {
          id: "4",
          name:
            this.$t("CommonName.statusName2") +
            " > " +
            this.$t("CommonName.statusName3"), //"翻译 校对",
        },
        {
          id: "5",
          name:
            this.$t("CommonName.statusName1") +
            " > " +
            this.$t("CommonName.statusName2") +
            " > " +
            this.$t("CommonName.statusName3"), //" 转写 翻译 校对",
        },
        {
          id: "6",
          name:
            this.$t("CommonName.statusName1") +
            " > " +
            this.$t("CommonName.statusName12"), //" 转写 sqt",
        },
        {
          id: "7",
          name:
            this.$t("CommonName.statusName1") +
            " > " +
            this.$t("CommonName.statusName12") +
            " > " +
            this.$t("CommonName.statusName2") +
            " > " +
            this.$t("CommonName.statusName3"), //" 转写 sqt 翻译 校对",
        },
      ], //操作流程下拉框
      statusdropdownList: [
        {
          id: "10",
          name: this.$t("CommonName.statusName4"), //"Pending",
          class: "blueClass",
        },
        {
          id: "20",
          name: this.$t("CommonName.statusName1"), // "转写",
          class: "greenClass",
        },
        {
          id: "25",
          name: this.$t("CommonName.statusName12"), // "sqt",
          class: "greenClass",
        },
        {
          id: "30",
          name: this.$t("CommonName.statusName2"), //"翻译",
          class: "yellowClass",
        },
        {
          id: "40",
          name: this.$t("CommonName.statusName3"), //"校对",
          class: "yellowClass",
        },
        {
          id: "50",
          name: this.$t("CommonName.statusName5"), //"Delivered",
          class: "yellowClass",
        },
        {
          id: "60",
          name: this.$t("CommonName.statusName6"), //"Delivered",
          class: "yellowClass",
        },
      ], //状态 下拉框
      sourcelanguagedropdownList: [], //源语言下拉框
      targetlanguagedropdownList: [], //目标语言 下拉框

      showTop: false,
      dialogWidth: false, //把弹窗的宽度改成1024px
      showTitle: false, //是否自定义 头部
      tableHeight: 0, //表格高度
      dialogVisible: false, //弹窗 开关
      dialogtitle: "", //弹窗标题
      multipleSelection: [], //全选
      ShiftDown: false, //shift 按下
      CtrlDown: false, // ctrl 按下
      startrow: {}, //选中 按下第一个
      showfooter: false, //是否显示弹窗按钮
      showCreateTaskdialog: false, //显示创建任务弹窗
      showBatchCreateHardSubtitleRecognitiondialog: false, //显示 批量创建硬字幕识别任务
      DetermineClickCreateTask: "1", //判断是从哪里点击 进入创建任务的
      Clonejobinfo: {}, //克隆任务 默认选项
      showImportTask: false, //显示导入任务弹窗
      showBatchCreatingTask: false, //显示批量创建 弹窗
      showCaptionSettings: false, //显示 字幕设置
      buttonList: [
        {
          id: "6",
          name: this.$t("ProjectDetails.buttonList.name21"),
          icon: "assign",
          status: false,
          ischildren: true,
          children: [
            {
              id: "6_1",
              name: this.$t("ProjectDetails.buttonList.name7"),
              status: true,
            },
            {
              id: "6_2",
              name: this.$t("ProjectDetails.buttonList.name22"),
              status: true,
            },
          ],
        },
        {
          id: "0",
          name: this.$t("ProjectDetails.buttonList.name1"),
          icon: "Import",
          status: false,
          ischildren: false,
        },
        {
          id: "1",
          name: this.$t("ProjectDetails.buttonList.name2"),
          icon: "create",
          status: false,
          ischildren: false,
        },
        {
          id: "2",
          name: this.$t("ProjectDetails.buttonList.name3"),
          icon: "ChannelManagement",
          status: false,
          ischildren: true,
          children: [
            {
              id: "2_1",
              name: this.$t("ProjectDetails.buttonList.name3"),
              status: true,
            },
            {
              id: "2_2",
              name: this.$t("ProjectDetails.buttonList.name8"),
              status: true,
            },
            {
              id: "2_3",
              name: this.$t("ProjectDetails.buttonList.name26"),
              status: true,
            },
          ],
        },
        // {
        //   id: "7",
        //   name: this.$t("ProjectDetails.buttonList.name8"),
        //   icon: "create",
        //   status: true,
        //   ischildren: false,
        // },
        // {
        //   id: "2",
        //   name: this.$t("ProjectDetails.buttonList.name3"),
        //   icon: "BatchAdd",
        //   status: false,
        //   ischildren: false,
        // },
        // {
        //   id: "3",
        //   name: this.$t("ProjectDetails.buttonList.name4"),
        //   icon: "Details",
        // },
        {
          id: "4",
          name: this.$t("ProjectDetails.buttonList.name5"),
          icon: "Glossaries",
          status: true,
          ischildren: false,
        },
        {
          id: "5",
          name: this.$t("ProjectDetails.buttonList.name6"),
          icon: "ChannelManagement",
          status: true,
          ischildren: true,
          children: [
            {
              id: "5_1",
              name: this.$t("ProjectDetails.buttonList.name24"),
              status: true,
            },
            {
              id: "5_2",
              name: this.$t("ProjectDetails.buttonList.name23"),
              status: true,
            },
          ],
        },
        {
          id: "8",
          name: this.$t("ProjectDetails.buttonList.name25"),
          icon: "file_open",
          status: true,
          ischildren: true,
          children: [
            {
              id: "8_1",
              name: this.$t("ProjectDetails.buttonList.name25"),
              status: true,
            },
            {
              id: "8_2",
              name: this.$t("ProjectDetails.dropdownBatchList.name32"),
              status: true,
            },
          ],
        },
      ],
      // 表格内部 操作
      dropdownList: [
        {
          name: this.$t("ProjectDetails.dropdownList.name1"),
          icon: "export",
          id: "1", // 导出
          status: false,
        },
        {
          name: this.$t("ProjectDetails.dropdownList.name2"),
          icon: "Import",
          id: "2", // 导入
          status: false,
        },
        {
          name: this.$t("ProjectDetails.dropdownList.name8"),
          icon: "Import",
          id: "8", //导入AIT
          status: false,
        },
        {
          name: this.$t("ProjectDetails.dropdownList.name3"),
          icon: "Dubbing",
          id: "3", //生成波形
          status: true,
        },
        {
          name: this.$t("ProjectDetails.dropdownList.name4"),
          icon: "Copy",
          id: "4", //克隆任务
          status: false,
        },
        {
          name: this.$t("ProjectDetails.dropdownList.name5"),
          icon: "delete",
          id: "5", //删除任务
          status: false,
        },
        {
          name: this.$t("ProjectDetails.dropdownList.name6"),
          icon: "assign",
          id: "6", //指派 更改人
          status: true,
        },
        {
          name: this.$t("ProjectDetails.dropdownList.name7"),
          icon: "Edit",
          id: "7", //重命名
          status: true,
        },
        {
          name: this.$t("ProjectDetails.dropdownList.name9"),
          icon: "Split",
          id: "9", //拆分
          status: true,
        },
        {
          name: this.$t("ProjectDetails.dropdownList.name10"),
          icon: "Import",
          id: "10", //从其他任务导入AIT
          status: true,
        },
        {
          name: this.$t("ProjectDetails.dropdownList.name11"),
          icon: "Import",
          id: "11", //从其他任务导入备注
          status: true,
        },
      ],
      dropdownBatchList: [
        {
          name: this.$t("ProjectDetails.dropdownBatchList.name7"),
          id: "1", // Download All Subtitle File (DOCX)
          status: true,
        },
        {
          name: this.$t("ProjectDetails.downloadText8"),
          id: "21",
          status: true,
        },
        // {
        //   name: this.$t("ProjectDetails.dropdownBatchList.name2"),
        //   id: "2", // Download All Subtitle File (SRT Source)
        //   status: true,
        // },
        // {
        //   name: this.$t("ProjectDetails.dropdownBatchList.name3"),
        //   id: "3", // Download All Subtitle File (SRT Target)
        //   status: true,
        // },
        // {
        //   name: this.$t("ProjectDetails.dropdownBatchList.name4"),
        //   id: "4", // Download All ttml File (SRT Target)
        //status:true
        // },
        {
          name: this.$t("ProjectDetails.dropdownBatchList.name5"),
          id: "5", // Export Task URLs
          status: false,
        },
        {
          name: this.$t("ProjectDetails.dropdownBatchList.name6"),
          id: "6", // Export Excel Files
          status: false,
        },
        {
          name: this.$t("ProjectDetails.dropdownBatchList.name28"),
          id: "20", // 导出软字幕错误检查报告
          status: false,
        },
        {
          name: this.$t("ProjectDetails.dropdownBatchList.name22"),
          id: "16", // move job
          status: false,
        },
        {
          name: this.$t("ProjectDetails.dropdownBatchList.name23"),
          id: "17", // update name
          status: false,
        },
        {
          name: this.$t("ProjectDetails.dropdownBatchList.name8"),
          id: "7", // Run AIT
          status: false,
        },
        {
          name: this.$t("ProjectDetails.dropdownBatchList.name10"),
          id: "9", // Close AIT
          status: false,
        },
        // {
        //   name: this.$t("ProjectDetails.dropdownBatchList.name13"),
        //   id: "10", // Stop AIT
        //   status: false,
        // },
        {
          name: this.$t("ProjectDetails.dropdownBatchList.name16"),
          id: "12", // Run MT
          status: true,
        },
        {
          name: this.$t("ProjectDetails.dropdownBatchList.name9"),
          id: "8", // Close Task
          status: true,
        },
        {
          name: this.$t("ProjectDetails.dropdownBatchList.name24"),
          id: "18", // Delete Task
          status: true,
        },
        {
          name: this.$t("ProjectDetails.dropdownBatchList.name14"),
          id: "11", //批量打回
          status: true,
        },
        {
          name: this.$t("ProjectDetails.dropdownBatchList.name20"),
          id: "14", //  批量打开转写任务
          status: false,
        },
        {
          name: this.$t("ProjectDetails.dropdownBatchList.name21"),
          id: "15", //  批量打开翻译任务
          status: false,
        },
        {
          name: this.$t("ProjectDetails.dropdownBatchList.name17"),
          id: "13", //  批量打开校审流程
          status: false,
        },
        {
          name: this.$t("ProjectDetails.dropdownBatchList.name27"),
          id: "19", //  批量打开校轴任务
          status: false,
        },
        {
          name: this.$t("ProjectDetails.dropdownBatchList.name30"),
          id: "22", //  从其他项目导入AIT
          status: true,
        },
        {
          name: this.$t("ProjectDetails.dropdownBatchList.name33"),
          id: "23", //  从其他项目导入画面字
          status: true,
        },
        {
          name: this.$t("ProjectDetails.dropdownBatchList.name36"),
          id: "24", //  批量替换任务原文SRT
          status: true,
        },
        {
          name: this.$t("ProjectDetails.dropdownBatchList.name37"),
          id: "25", //  批量更新任务时间轴和原文
          status: true,
        },
        {
          name: this.$t("ProjectDetails.AssignChangeTaskHandler1"),
          id: "26", //  设置项目默认人员
          status: true,
        },
      ],
      tableData: [],
      rowData: {},
      internalRowData: {}, //内部表格弹窗
      dropdownLists: [], //任务处理人数据
      searchText: "", //搜索 任务处理人
      // 上传 新的术语表  数据
      loading: false,
      percentage: 0,
      uploadData: {},
      formLabelAlign: {
        name: "",
        select1: "",
        upload: "",
        comments: "",
      },
      languageoptions1: [],
      commandRow: {}, //获取 点击表格 行数据
      older: "job_name",
      sort: "",
      baseUrl: config.baseUrl.dev, //请求地址
      renameInput: "", // 重命名
      formTermData: {
        name: "",
        upload: "",
        term_file_id: "",
        mode: "0",
      },
      time: null,
      isOwer: false,
      isOwer1: false, //内部
      innerDownloadVisible: false, //内部
      checkboxDownload: ["3"],
      checkboxDownloadList: [
        { id: "1", name: this.$t("ProjectDetails.dropdownBatchList.name1") },
        { id: "2", name: this.$t("ProjectDetails.dropdownBatchList.name2") },
        { id: "3", name: this.$t("ProjectDetails.dropdownBatchList.name3") },
        { id: "4", name: this.$t("ProjectDetails.dropdownBatchList.name11") },
        { id: "5", name: this.$t("ProjectDetails.dropdownBatchList.name12") },
        { id: "6", name: this.$t("ProjectDetails.dropdownBatchList.name25") },
        { id: "7", name: this.$t("ProjectDetails.dropdownBatchList.name26") },
        { id: "8", name: this.$t("ProjectDetails.dropdownBatchList.name29") },
        { id: "9", name: this.$t("ProjectDetails.dropdownBatchList.name34") },
        { id: "10", name: this.$t("ProjectDetails.dropdownBatchList.name35") },
      ],
      folder_pid: 0,
      innerExportVisible: false, //内部
      checkboxExport: ["2"],
      checkboxExportList: [
        { id: "1", name: this.$t("ProjectDetails.dropExportBatchList.name1") },
        { id: "2", name: this.$t("ProjectDetails.dropExportBatchList.name2") },
      ],
      aitUpload: "", // 导入 AIT
      mediaUrl: config.mediaUrl.dev, //跳转地址
      moveFolderList: [], //移动项目全部数据
      dialogMoveVisible: false,
      defaultProps: {
        children: "children",
        label: "label",
      },
      move_project_id: "0", //移动项目id
      moveProjectSearchInput: "", //移动搜索内容
      timeOut: null,
      filterText: "",
      dialogReNameVisible: false, //改名弹窗 开关
      radio2: "0",
      radioList2: [
        {
          label: "0",
          name: this.$t("ProjectDetails.labelText0"),
        },
        {
          label: "1",
          name: this.$t("ProjectDetails.labelText1"),
        },
      ],
      replaceinput1: "", //被替换
      replaceinput2: "", //替换成
      replaceinput3: "", //追加
      term_select: "",
      term_file_mode: "2",
      term_name: "",
      term_lang: "",
      tlangs: [],
      termData: [],
      ProjectCommentsData: [],
      commentsSelect: [],
      find_replace_mode: "1",
      find_input: "",
      replace_input: "",
      dialogDownloadVisible: false,
      jobIds: "",
      dialogImportVisible: false,
      projectList: [],
      projectSelected: "",
      jobSelected: "",
      jobList: [],
      import_type: "1", //1：从项目导入；2：从任务导入
      import_job_id: "", //被导入任务id
      showBatchCreateHardSubHuadialog: false, //显示 批量创建硬字幕识别画面字任务
      import_title: "",
      import_tips: "",
      zipUpload: "", // 导入 zip
      sWorkflowSelected: "", //其他任务流程选择
      tWorkflowSelected: "", //当前任务流程选择
      sWorkflows: [],
      tWorkflows: [],
      differentArr: [],
      jobSelected1: "", //包含标准时间轴和原文的任务
      isProject: false, //是否是项目层级的操作
      radio6: "2", //AIT服务 0表示gpt，1表示deepseek阿里，2表示deepseek字节
      radioList6: [
        {
          label: "0",
          name: this.$t("CommonName.AITMode.label0"),
          disabled: false,
        },
        {
          label: "1",
          name: this.$t("CommonName.AITMode.label1"),
          disabled: false,
        },
        {
          label: "2",
          name: this.$t("CommonName.AITMode.label2"),
          disabled: false,
        },
        {
          label: "3",
          name: this.$t("CommonName.AITMode.label3"),
          disabled: false,
        },
      ],
      // 拆分表格内部 操作
      dropdownList1: [
        {
          name: this.$t("ProjectManagement.refuse"),
          icon: "export",
          id: "1", // 打回
          status: true,
        },
        {
          name: this.$t("ProjectDetails.dropdownBatchList.name8"),
          icon: "Import",
          id: "2", // run ait
          status: true,
        },
        {
          name: this.$t("ProjectDetails.dropdownBatchList.name10"),
          icon: "Dubbing",
          id: "3", // clean up ait
          status: true,
        },
      ],
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  beforeDestroy() {
    window.removeEventListener("keyup ", this.windowkeyup, true);
    window.removeEventListener("keydown", this.windowkeydown, true);
  },
  methods: {
    /**
     *
     * @param {* 指定项目执行人（批量）} id
     */
    set_project_user_batch(arr) {
      // console.log(arr);
      arr.forEach((i) => {
        if (i.user_id == "") {
          i.user_id = "0";
        }
      });
      return set_job_user_batch({
        action: "set_project_user_batch",
        project_id: this.project_id,
        list: arr,
      }).then((res) => {
        if (!res) {
          this.buttonLoading = false;
          return;
        }
      });
    },
    //任务流程处理
    workflowMethod(job_type) {
      let array = [];
      switch (job_type) {
        case "1":
          array = [
            {
              name: this.$t("CommonName.statusName1"), //转写
              workflow_id: "1",
            },
          ];
          break;
        case "2":
          array = [
            {
              name: this.$t("CommonName.statusName2"), //"翻译",
              workflow_id: "2",
            },
          ];
          break;
        case "3":
          array = [
            {
              name: this.$t("CommonName.statusName1"), //"转写",
              workflow_id: "1",
            },
            {
              name: this.$t("CommonName.statusName2"), // "翻译",
              workflow_id: "2",
            },
          ];
          break;
        case "4":
          array = [
            {
              name: this.$t("CommonName.statusName2"), // "翻译",
              workflow_id: "2",
            },
            {
              name: this.$t("CommonName.statusName3"), //"校对",
              workflow_id: "3",
            },
          ];
          break;
        case "5":
          array = [
            {
              name: this.$t("CommonName.statusName1"), // "转写",
              workflow_id: "1",
            },
            {
              name: this.$t("CommonName.statusName2"), // "翻译",
              workflow_id: "2",
            },
            {
              name: this.$t("CommonName.statusName3"), //"校对",
              workflow_id: "3",
            },
          ];
          break;
        case "6":
          array = [
            {
              name: this.$t("CommonName.statusName1"), //"转写",
              workflow_id: "1",
            },
            {
              name: this.$t("CommonName.statusName12"), // "SQT",
              workflow_id: "5",
            },
          ];
          break;
        case "7":
          array = [
            {
              name: this.$t("CommonName.statusName1"), //"转写",
              workflow_id: "1",
            },
            {
              name: this.$t("CommonName.statusName12"), // "SQT",
              workflow_id: "5",
            },
            {
              name: this.$t("CommonName.statusName2"), // "翻译",
              workflow_id: "2",
            },
            {
              name: this.$t("CommonName.statusName3"), //"校对",
              workflow_id: "3",
            },
          ];
          break;
        default:
          array = [];
          break;
      }
      return array;
    },
    //选中任务
    jobSelectedChange(row) {
      console.log(row);
      //导入备注
      if (this.import_type == "4") {
        //需要更新目标任务流程
        let findItem = this.jobList.find((item) => item.job_id == row);
        if (findItem) {
          console.log("findItem", findItem.job_type);
          this.sWorkflows = this.workflowMethod(findItem.job_type);
        }
      }
    },
    //选中导入项目
    projectSelectedChange() {
      console.log(this.projectSelected);
      let data = {
        action: "get_job_alllist",
        project_id: this.projectSelected,
        job_id: this.import_job_id,
        mode: this.import_type == "4" ? "1" : "0",
      };
      this.jobList = [];
      this.jobSelected = "";
      get_team_job_list(data).then((res) => {
        if (res) {
          this.jobList = res.list;
        }
      });
    },
    determineImportClick() {
      if (
        this.projectSelected == "" &&
        ["1", "2", "3", "4", "5"].includes(this.import_type)
      ) {
        this.$messages("warning", this.$t("CommonName.selectMessage"));
        return;
      }
      //从其他项目导入AIT
      if (this.import_type == "1") {
        showLoading(this.$t("CommonName.loadingmessage"));
        let job_ids = [];
        this.multipleSelection.forEach((item) => {
          job_ids.push(item.job_id);
        });
        set_job_close({
          action: "import_project_ait_result",
          project_id: this.project_id,
          ait_project_id: this.projectSelected,
          job_ids,
        }).then((res) => {
          hideLoading();
          if (!res) {
            return;
          }
          this.get_team_job_list().then((res) => {
            this.dialogImportVisible = false;
          });
        });
      } else if (this.import_type == "2") {
        //从其他任务导入AIT
        if (this.jobSelected == "") {
          this.$messages("warning", this.$t("CommonName.selectMessage"));
          return;
        }
        showLoading(this.$t("CommonName.loadingmessage"));
        set_job_close({
          action: "import_job_ait_result",
          project_id: this.project_id,
          ait_project_id: this.projectSelected,
          ait_job_id: this.jobSelected,
          job_id: this.import_job_id,
        }).then((res) => {
          hideLoading();
          if (!res) {
            return;
          }
          this.get_team_job_list().then((res) => {
            this.dialogImportVisible = false;
          });
        });
      } else if (this.import_type == "3") {
        //从其他项目导入画面字
        let job_ids = [];
        this.multipleSelection.forEach((item) => {
          job_ids.push(item.job_id);
        });
        showLoading(this.$t("CommonName.loadingmessage"));
        set_job_close({
          action: "import_frame_jobs_content",
          project_id: this.project_id,
          tproject_id: this.projectSelected,
          job_ids,
        }).then((res) => {
          hideLoading();
          if (!res) {
            return;
          }
          this.get_team_job_list().then((res) => {
            this.dialogImportVisible = false;
          });
        });
      } else if (this.import_type == "4") {
        //从其他任务导入备注
        if (
          this.jobSelected == "" ||
          this.sWorkflowSelected == "" ||
          this.tWorkflowSelected == ""
        ) {
          this.$messages("warning", this.$t("CommonName.selectMessage"));
          return;
        }
        showLoading(this.$t("CommonName.loadingmessage"));
        set_job_close({
          action: "import_job_comments",
          project_id: this.project_id,
          sproject_id: this.projectSelected,
          sjob_id: this.jobSelected,
          job_id: this.import_job_id,
          swork_flow: this.sWorkflowSelected,
          work_flow: this.tWorkflowSelected,
        }).then((res) => {
          hideLoading();
          if (!res) {
            return;
          }
          this.get_team_job_list().then((res) => {
            this.dialogImportVisible = false;
          });
        });
      } else if (this.import_type == "5") {
        //批量更新任务时间轴和原文
        this.$confirm(
          this.$t("ProjectDetails.message"),
          this.$t("CommonName.deleteMessageTitle"),
          {
            confirmButtonText: this.$t("CommonName.deleteMessagebutton"),
            cancelButtonText: this.$t("CommonName.deleteMessageClosebutton"),
            closeOnClickModal: false,
            cancelButtonClass: "closeButton",
            // confirmButtonClass: "confirmButton",
          }
        ).then(() => {
          let job_ids = [];
          this.multipleSelection.forEach((item) => {
            job_ids.push(item.job_id);
          });
          showLoading(this.$t("CommonName.loadingmessage"));
          set_job_close({
            action: "syn_jobs_content",
            sproject_id: this.projectSelected,
            tjob_ids: job_ids,
          }).then((res) => {
            hideLoading();
            if (!res) {
              return;
            }
            this.get_team_job_list().then((res) => {
              this.dialogImportVisible = false;
            });
          });
        });
      } else if (this.import_type == "6") {
        showLoading(this.$t("CommonName.loadingmessage"));
        this.run_gpt_jobs(this.project_id);
      }
    },
    /**全局内容替换
     *
     * @param {*}
     */
    replace_content_method() {
      return update_job_tm_list({
        action: "replace_job_content",
        type: this.find_replace_mode,
        replace_source: this.find_input,
        replace_target: this.replace_input,
        job_id: this.rowData.job_id,
      }).then((res) => {
        if (!res) {
          return res;
        }
        return res;
      });
    },
    //删除拆分任务
    split_delete_method(job_id) {
      this.get_team_job_list().then((res) => {
        this.dialogVisible = false;
      });
    },
    /**
     *
     * @param {*获取项目已添加术语列表信息} id
     */
    get_project_termfile_list() {
      return get_project_termfile_list({
        action: "get_usable_termfile_list",
        project_id: 0,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.termData = res.list;
        hideLoading();
      });
    },

    windowkeyup(e) {
      if (e.keyCode === 16) this.ShiftDown = false;
      if (e.keyCode === 17) this.CtrlDown = false;
    },
    windowkeydown(e) {
      // console.log(e.keyCode);
      if (e.keyCode === 16 && !e.ctrlKey && !e.altKey) {
        e.stopPropagation();
        e.returnValue = false;
        this.ShiftDown = true;
      }
      if (e.keyCode === 17 && !e.shiftKey && !e.altKey) {
        e.stopPropagation();
        e.returnValue = false;
        this.CtrlDown = true;
      }
    },
    //  !========================================通用方法================================
    /**
     *
     * @param {* 整理数据} i
     */
    setTableData(i) {
      switch (i.job_type) {
        case "1":
          i.set = [
            {
              name: this.$t("CommonName.statusName1"), //转写
              class: "blueClass",
              nickname: i.member_stt,
              showbadge: +i.comments_error[1] > 0,
            },
          ];
          break;
        case "2":
          i.set = [
            {
              name: this.$t("CommonName.statusName2"), //"翻译",
              class: i.gpt_flag == 0 ? "greenClass" : "greenClass_other",
              nickname: i.member_tra,
              showbadge: +i.comments_error[2] > 0,
            },
          ];
          break;
        case "3":
          console.log(i.gpt_flag);
          i.set = [
            {
              name: this.$t("CommonName.statusName1"), //"转写",
              class: "blueClass",
              nickname: i.member_stt,
              showbadge: +i.comments_error[1] > 0,
            },
            {
              name: this.$t("CommonName.statusName2"), // "翻译",
              class: i.gpt_flag == 0 ? "greenClass" : "greenClass_other",
              nickname: i.member_tra,
              showbadge: +i.comments_error[2] > 0,
            },
          ];
          break;
        case "4":
          i.set = [
            {
              name: this.$t("CommonName.statusName2"), // "翻译",
              class: i.gpt_flag == 0 ? "greenClass" : "greenClass_other",
              nickname: i.member_tra,
              showbadge: +i.comments_error[2] > 0,
            },
            {
              name: this.$t("CommonName.statusName3"), //"校对",
              class: "yellowClass",
              nickname: i.member_rev,
              showbadge: +i.comments_error[3] > 0,
            },
          ];
          break;
        case "6":
          console.log(i.gpt_flag);
          i.set = [
            {
              name: this.$t("CommonName.statusName1"), //"转写",
              class: "blueClass",
              nickname: i.member_stt,
              showbadge: +i.comments_error[1] > 0,
            },
            {
              name: this.$t("CommonName.statusName12"), // "SQT",
              class: "sqtClass",
              nickname: i.member_sqt,
              showbadge: +i.comments_error[5] > 0,
            },
          ];
          break;
        case "7":
          i.set = [
            {
              name: this.$t("CommonName.statusName1"), //"转写",
              class: "blueClass",
              nickname: i.member_stt,
              showbadge: +i.comments_error[1] > 0,
            },
            {
              name: this.$t("CommonName.statusName12"), // "SQT",
              class: "sqtClass",
              nickname: i.member_sqt,
              showbadge: +i.comments_error[5] > 0,
            },
            {
              name: this.$t("CommonName.statusName2"), // "翻译",
              class: i.gpt_flag == 0 ? "greenClass" : "greenClass_other",
              nickname: i.member_tra,
              showbadge: +i.comments_error[2] > 0,
            },
            {
              name: this.$t("CommonName.statusName3"), //"校对",
              class: "yellowClass",
              nickname: i.member_rev,
              showbadge: +i.comments_error[3] > 0,
            },
          ];
          break;
        default:
          i.set = [
            {
              name: this.$t("CommonName.statusName1"), //"转写",
              class: "blueClass",
              nickname: i.member_stt,
              showbadge: +i.comments_error[1] > 0,
            },
            {
              name: this.$t("CommonName.statusName2"), // "翻译",
              class: i.gpt_flag == 0 ? "greenClass" : "greenClass_other",
              nickname: i.member_tra,
              showbadge: +i.comments_error[2] > 0,
            },
            {
              name: this.$t("CommonName.statusName3"), //"校对",
              class: "yellowClass",
              nickname: i.member_rev,
              showbadge: +i.comments_error[3] > 0,
            },
          ];
          break;
      }
      switch (i.status) {
        case "10":
          i.newstatus = {
            name: this.$t("CommonName.statusName4"), //padding
            value: " 0%",
            gpt_progress: i.gpt_progress,
          };
          break;
        case "20":
          i.newstatus = {
            name: this.$t("CommonName.statusName1"), //转写
            class: "blueClass",
            gpt_progress: i.gpt_progress,
          };
          break;
        case "25":
          i.newstatus = {
            name: this.$t("CommonName.statusName12"), //STQ
            class: "sqtClass",
            gpt_progress: i.gpt_progress,
          };
          break;
        case "30":
          i.newstatus = {
            name: this.$t("CommonName.statusName2"), //翻译
            class: i.gpt_flag == 0 ? "greenClass" : "greenClass_other",
            value: i.progress + "%",
            gpt_progress: i.gpt_progress,
          };
          break;
        case "40":
          i.newstatus = {
            name: this.$t("CommonName.statusName3"), //校对
            class: "yellowClass",
            value: i.rprogress ? i.rprogress + "%" : "",
            gpt_progress: i.gpt_progress,
          };
          break;
        case "50":
          i.newstatus = {
            name: this.$t("CommonName.statusName5"), //Delivered
            gpt_progress: i.gpt_progress,
          };
          break;
        case "60":
          i.newstatus = {
            name: this.$t("CommonName.statusName6"), //Closed
          };
          break;
        default:
          i.newstatus = {
            name: this.$t("CommonName.statusName3"), //校对
          };
          break;
      }
      return i;
    },
    /**
     *
     * @param {*内部弹窗 关闭按钮} data
     */
    CancelClick() {
      this.innerVisible = false;
    },
    /**
     *
     * @param {内部弹窗 确定按钮} data
     */
    SureClick() {
      // 上传 新的术语表
      if (this.showInnerDialogs == 4) {
        if (!this.formLabelAlign.name) {
          this.$messages("warning", this.$t("CommonName.warningmessage3"));
          return;
        }
        if (!this.formLabelAlign.select1) {
          this.$messages("warning", this.$t("CommonName.warningmessage1"));
          return;
        }

        // else if (this.formLabelAlign.select1 == this.formLabelAlign.select2) {
        //   this.$messages("warning", this.$t("CommonName.warningmessage2"));
        //   return;
        // }
        if (!this.formLabelAlign.upload) {
          // this.$messages("warning", this.$t("CommonName.warningmessage5"));
          // return;
          add_term_set({
            action: "create_term_set_base",
            name: this.formLabelAlign.name,
            slang: this.formLabelAlign.select1,
            comments: this.formLabelAlign.comments,
          }).then((res) => {
            if (!res) {
              return;
            }
            this.get_team_job_list().then(() => {
              this.innerVisible = false;
              this.dialogVisible = false;
              this.showfooter = false;
              this.percentage = 0;
              this.loading = false;
              this.$messages("success", this.$t("CommonName.successmessage1"));
            });
          });
        } else {
          this.uploadData = {
            name: this.formLabelAlign.name,
            slang: this.formLabelAlign.select1,
            comments: this.formLabelAlign.comments,
          };
          this.$nextTick(() => {
            this.$refs.upload.submit();
          });
        }
      }
      // changeTM
      if (this.showInnerDialogs == 2) {
        // console.log(this.select, this.select1, this.select2);
        if (this.select1.name && this.select2.name) {
          if (this.select1.tm_id == this.select2.tm_id) {
            this.$messages(
              "warning",
              this.$t("ProjectManagement.warningmessage6")
            );
            return;
          }
        }
        let arr = [
          {
            type: 0,
            tm_id: this.select.tm_id ? this.select.tm_id : "0",
            name: this.select.name ? this.select.name : "",
          },
          {
            type: 1,
            tm_id: this.select1.tm_id ? this.select1.tm_id : "0",
            name: this.select1.name ? this.select1.name : "",
          },
          {
            type: 2,
            tm_id: this.select2.tm_id ? this.select2.tm_id : "0",
            name: this.select2.name ? this.select2.name : "",
          },
        ];

        showLoading(this.$t("CommonName.loadingmessage"));
        // console.log(arr);
        this.update_job_tm_list(arr).then(() => {
          hideLoading();
          this.innerVisible = false;
          //调用子组件方法 及时更新数据显示
          this.$refs.ProjectDetails.get_project_job_info(this.rowData.job_id);
        });
      }
      // 更新术语表
      if (this.showInnerDialogs == 5) {
        if (!this.formTermData.upload) {
          this.$messages("warning", this.$t("CommonName.warningmessage5"));
          return;
        }
        this.uploadData = {
          name: this.formTermData.name,
          mode: this.formTermData.mode,
          term_set_id: this.formTermData.term_file_id,
        };
        this.$nextTick(() => {
          this.$refs.uploadUpdate.submit();
        });
      }
      // 术语设置
      if (this.showInnerDialogs == 7) {
        // console.log(this.select, this.select1, this.select2);
        if (this.term_file_mode == "1") {
          if (!this.term_name.trim() || this.term_lang == "") {
            this.$messages(
              "warning",
              this.$t("ExternalCreateTask.warningmessage8")
            );
            return;
          }
          if (this.tlangs.length > 0 && this.tlangs.includes(this.term_lang)) {
            this.$messages("warning", this.$t("CommonName.selectMessage1"));
            return;
          }
        }

        showLoading(this.$t("CommonName.loadingmessage"));
        // console.log(arr);
        this.set_job_term(this.term_select).then(() => {
          hideLoading();
          this.innerVisible = false;
          //调用子组件方法 及时更新数据显示
        });
      }
      if (this.showInnerDialogs == 8) {
        showLoading(this.$t("CommonName.loadingmessage"));
        // console.log(arr);
        this.replace_content_method().then((res) => {
          if (+res.count == 0) {
            this.$messages("warning", this.$t("CommonName.findText1"));
          } else {
            this.$messages(
              "success",
              this.$t("CommonName.findText2") + res.count
            );
          }

          hideLoading();
          this.innerVisible = false;
          //调用子组件方法 及时更新数据显示
        });
      }
    },
    /**
     *
     * @param {*内部弹窗 关闭} data
     */
    CloseinnerVisible() {
      this.innerVisible = false;
    },
    /**
     *
     * @param {*下拉菜单 出现 清空输入框内容} val
     */
    visibleChange(val) {
      if (val) {
        this.searchText = "";
      }
    },
    /**
     *
     * @param {*修改执行人} command
     */
    handleCommand(command) {
      if (command === "-1") {
        return;
      }
      let obj = {};
      obj.user_id = command.id;
      obj.nickname = command.name;
      obj.job_id = this.internalRowData.job_id;
      obj.job_split_id = this.internalRowData.job_split_id;
      showLoading(this.$t("CommonName.loadingmessage"));
      this.set_job_split_user(obj);
    },
    /**
     * 点击清除 指派人
     * @param obj
     */
    handleloseClick() {
      let obj = {};
      obj.user_id = "0";
      obj.job_id = this.internalRowData.job_id;
      obj.job_split_id = this.internalRowData.job_split_id;
      showLoading(this.$t("CommonName.loadingmessage"));
      this.set_job_split_user(obj);
    },
    /**
     * 复制链接
     * @param url
     */
    copyClick(url, data = 0) {
      // data == -1 复制 链接
      console.log(url);
      if (data == "-1") {
        let input = document.createElement("input"); // js创建一个input输入框
        input.value = url; // 将需要复制的文本赋值到创建的input输入框中
        document.body.appendChild(input); // 将输入框暂时创建到实例里面
        input.select(); // 选中输入框中的内容
        document.execCommand("Copy"); // 执行复制操作
        document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
        this.$messages("success", this.$t("CommonName.Copysucceeded"));
      } else {
        let input = document.createElement("textarea"); // js创建一个input输入框
        input.value =
          this.$t("CommonName.TaskName") +
          " : " +
          url.job_name +
          "\n" +
          this.$t("CommonName.WorkflowNode") +
          " : " +
          (url.workflow === "1"
            ? this.$t("ProjectManagement.STTname")
            : url.workflow === "2"
            ? this.$t("ProjectManagement.TRAname")
            : url.workflow === "5"
            ? this.$t("ProjectManagement.STQname")
            : this.$t("ProjectManagement.REVname")) +
          "\n" +
          this.$t("ProjectManagement.TaskURL") +
          " : " +
          url.newTaskUrl +
          "\n" +
          this.$t("OverwriteOperation.verif_text6") +
          " : " +
          url.check_code; // 将需要复制的文本赋值到创建的input输入框中
        document.body.appendChild(input); // 将输入框暂时创建到实例里面
        input.select(); // 选中输入框中的内容
        document.execCommand("Copy"); // 执行复制操作
        document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
        this.$messages("success", this.$t("CommonName.Copysucceeded"));
      }
    },
    /**
     *
     * @param {*点击 二次弹窗} data
     */
    showInnerDialog(data) {
      // data -> 1View Log 2Edit TM
      this.showInnerDialogs = data;
      switch (data) {
        case 1:
          this.innerVisibleTitle = this.$t("ProjectDetails.ViewLog");
          this.innerVisible = true;
          this.get_job_view_log().then((res) => {
            res.forEach((item) => {
              switch (item.type) {
                case "1":
                  item.type = this.$t("ProjectDetails.versiontext1");
                  break;
                case "2":
                  item.type = this.$t("ProjectDetails.versiontext2");
                  break;
                case "3":
                  item.type = this.$t("ProjectDetails.versiontext3");
                  break;
                case "4":
                  item.type = this.$t("ProjectDetails.dropdownList.name8");
                  break;
                case "5":
                  item.type = this.$t("ProjectDetails.versiontext5");
                  break;
                case "6":
                  item.type = this.$t("ProjectDetails.versiontext6");
                  break;
                case "7":
                  item.type = this.$t("ProjectDetails.versiontext7");
                  break;
                default:
                  item.type =
                    this.$t("ProjectManagement.Replace") +
                    this.$t("ProjectDetails.versiontext");
                  break;
              }
            });
            this.dialogtableData = res;
          });
          break;
        case 2:
          showLoading(this.$t("CommonName.loadingmessage"));
          this.select = {};
          this.select1 = {};
          this.select2 = {};
          this.get_job_tm_list().then((res) => {
            // console.log(res);
            res.forEach((i) => {
              if (i.type == 0) {
                this.select = i;
              } else if (i.type == 1) {
                this.select1 = i;
              } else {
                this.select2 = i;
              }
            });
            hideLoading();
          });

          this.get_tm_list(1);
          this.get_tm_list(2);
          this.innerVisibleTitle = this.$t("ProjectManagement.Changetms");
          this.innerVisible = true;
          break;
        case 3:
          this.$confirm(
            this.$t("CommonName.deleteMessage1"),
            this.$t("CommonName.deleteMessageTitle"),
            {
              confirmButtonText: this.$t("CommonName.deleteMessagebutton"),
              cancelButtonText: this.$t("CommonName.deleteMessageClosebutton"),
              closeOnClickModal: false,
              cancelButtonClass: "closeButton",
              // confirmButtonClass: "confirmButton",
            }
          ).then(() => {
            showLoading(this.$t("CommonName.loadingmessage"));
            this.delete_job(this.rowData.job_id).then(() => {
              this.innerVisible = false;
              this.dialogVisible = false;
            });
          });
          break;
        // 上传 新的术语表
        case 4:
          this.formLabelAlign = {
            name: "",
            select1: "",
            upload: "",
            comments: "",
          };
          this.innerVisibleTitle = this.$t("ProjectDetails.Uploadanewglossary");
          this.innerVisible = true;
          break;
        case 7:
          showLoading(this.$t("CommonName.loadingmessage"));
          this.term_select = "";
          this.get_project_termfile_list().then((res) => {
            this.get_job_term_info(this.rowData.job_id).then((res1) => {
              this.innerVisibleTitle = this.$t("ProjectDetails.text2");
              this.innerVisible = true;
              this.term_select =
                res1.term_file_id == "0" ? "" : res1.term_file_id;
              this.term_file_mode = "2";
              this.term_name = "";
              this.term_lang = "";
            });
          });
          break;
        case 6:
          this.$confirm(
            this.$t("CommonName.deleteMessage5"),
            this.$t("CommonName.deleteMessageTitle"),
            {
              confirmButtonText: this.$t("CommonName.deleteMessagebutton"),
              cancelButtonText: this.$t("CommonName.deleteMessageClosebutton"),
              closeOnClickModal: false,
              cancelButtonClass: "closeButton",
              // confirmButtonClass: "confirmButton",
            }
          ).then(() => {
            showLoading(this.$t("CommonName.loadingmessage"));
            this.remove_job_frame_content(this.rowData.job_id).then(() => {
              this.innerVisible = false;
              this.dialogVisible = false;
            });
          });
          break;
        case 8:
          this.innerVisibleTitle = this.$t("ProjectDetails.text4");
          this.innerVisible = true;
          this.find_replace_mode = "1";
          this.find_input = "";
          this.replace_input = "";
          break;
        default:
          break;
      }
    },
    // ！======================================== 接口调用===============================
    /**
     * 获取任务列表信息
     */
    get_team_job_list() {
      // this.multipleSelection = [];
      let multipleSelection = $.extend(true, [], this.multipleSelection);
      this.multipleSelection = [];
      let start_job_id = this.startrow.job_id;
      let data = { action: "get_job_list", project_id: this.project_id };
      if (this.older != "" && this.sort != "") {
        data["older"] = this.older;
        data["sort"] = this.sort;
      }
      this.tags.forEach((item, index) => {
        data[item.key] = item.value;
      });
      // this.tableData = [];
      return get_team_job_list(data).then((res) => {
        if (res) {
          // console.log(res.list);
          this.$refs.multipleTable.clearSelection();
          let arr = res.list;
          let ids = [];
          this.startrow = {};
          // console.log("multipleSelection", multipleSelection);
          arr.forEach((i, index) => {
            if (multipleSelection.length > 0) {
              const targetItem = multipleSelection.find(
                (item) => item.job_id == i.job_id
              );
              // console.log("targetItem", targetItem);
              if (targetItem) {
                this.$refs.multipleTable.toggleRowSelection(i);
                this.multipleSelection.push(i);
              }
            }
            if (!!start_job_id == true && start_job_id == i.job_id) {
              this.startrow = i;
            }
            i.index = index;
            if (["1", "3", "5", "6", "7"].includes(i.job_type)) {
              i.activeName = "1";
              i.tableData = i.split_list.filter((i) => i.workflow === "1");
            } else if (["2", "3", "4", "5", "7"].includes(i.job_type)) {
              i.activeName = "2";
              i.tableData = i.split_list.filter((i) => i.workflow === "2");
            }

            if (["1", "2", "5", "7"].includes(i.job_type)) {
              if (i.status == "10") {
                ids.push(i.job_id);
              }
            }
            i = this.setTableData(i);
          });
          // console.log(this.multipleSelection, "this.multipleSelection");
          // console.log(ids);
          if (ids.length > 0) {
            this.getTranscribingJobs(ids);
          } else {
            clearTimeout(this.timer);
          }
          this.tableData = res.list;
          hideLoading();
        }
      });
    },
    /**
     *
     * @param {*获取项目详情信息} id
     */
    get_project_info(id) {
      return get_project_info({
        action: "get_project_info",
        project_id: id,
      }).then((res) => {
        if (!res) {
          return;
        }
        return res.info;
      });
    },
    /**
     *
     * @param {*删除任务} id
     */
    delete_job(id) {
      return delete_job({
        action: "delete_job",
        job_id: id,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.get_team_job_list();
      });
    },
    /**
     *
     * @param {*一键移除任务画面字内容} id
     */
    remove_job_frame_content(id) {
      return get_project_termfile_list({
        action: "remove_job_frame_content",
        job_id: id,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.get_team_job_list();
      });
    },
    /**
     *
     * @param {*获取任务术语表信息} id
     */
    get_job_term_info(id) {
      return get_project_termfile_list({
        action: "get_job_term_info",
        job_id: id,
      }).then((res) => {
        if (!res) {
          return;
        }
        return res.info;
      });
    },
    /**
     *
     * @param {*获取TM列表} data
     */
    get_tm_list(data) {
      get_pro_tm_list({
        action: "get_tm_list",
        type: data, //1主TM，2临时TM
        slang_id: this.rowData.slang_id,
        tlang_id: this.rowData.tlang_id,
        job_id: this.rowData.job_id,
      }).then((res) => {
        if (!res) {
          return;
        }
        if (data == "1") {
          this.selectList = res.list;
        } else {
          this.selectList1 = res.list;
        }
      });
    },
    /**
     * 获取语言接口
     */
    get_language() {
      get_language({
        action: "get_language",
        lang:
          sessionStorage.getItem("lang") == "cn" ||
          sessionStorage.getItem("lang") == null
            ? 1
            : 2,
      }).then((res) => {
        if (res) {
          this.languageoptions1 = res.list;
          this.sourcelanguagedropdownList = res.list;
          this.targetlanguagedropdownList = res.list;
        }
      });
    },

    /**
     *
     * @param {* 指定任务执行人（批量）} id
     */
    set_job_user_batch(arr) {
      // console.log(arr);
      arr.forEach((i) => {
        if (i.user_id == "") {
          i.user_id = "0";
        }
      });
      return set_job_user_batch({
        action: "set_job_user_batch",
        job_id: this.commandRow.job_id,
        list: arr,
      }).then((res) => {
        if (!res) {
          this.buttonLoading = false;
          return;
        }
      });
    },
    /**
     *
     * @param {*导出任务内容（Excel）} row
     */
    export_excel_task_content(row) {
      let url = this.baseUrl + "/videojob/export_excel_task_content?";
      url += "project_id=" + this.project_id;
      url += "&job_id=" + row.job_id;
      url += "&job_file_id=" + row.job_file_id;
      url += "&time=" + new Date().getTime();
      window.open(url);
      // export_excel_task_content({
      //   action: "export_excel_task_content",
      //   project_id: this.project_id,
      //   job_id: row.job_id,
      //   job_file_id: row.job_file_id,
      // }).then((res) => {
      //   if (res.result == "FAILED") {
      //     this.$messages("error", res.msg);
      //     return;
      //   }
      //   const blob = new Blob([res], {
      //     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
      //   });
      //   let downloadElement = document.createElement("a");
      //   let href = window.URL.createObjectURL(blob); //创建下载的链接
      //   downloadElement.href = href;
      //   downloadElement.download = `${row.name}.xlsx`;
      //   document.body.appendChild(downloadElement);
      //   downloadElement.click(); //点击下载
      //   document.body.removeChild(downloadElement); //下载完成移除元素
      //   window.URL.revokeObjectURL(href);
      // });
    },
    /**
     *
     * @param {获取任务TM选择信息} data
     */
    get_job_tm_list() {
      return get_job_tm_list({
        action: "get_job_tm_list",
        project_id: this.project_id,
        job_id: this.rowData.job_id,
      }).then((res) => {
        if (!res) {
          return;
        }
        return res.list;
      });
    },
    /**
     *
     * @param {*更新任务TM选择信息} arr
     */
    update_job_tm_list(arr) {
      return update_job_tm_list({
        action: "update_job_tm_list",
        project_id: this.project_id,
        job_id: this.rowData.job_id,
        list: arr,
      }).then((res) => {
        if (!res) {
          return;
        }
      });
    },
    /**
     *
     * @param {*设置任务术语表信息} arr
     */
    set_job_term(id) {
      return update_job_tm_list({
        action: "set_job_term",
        term_file_id: id,
        term_file_mode: this.term_file_mode,
        term_name: this.term_name,
        term_lang: this.term_lang,
        tlangs: this.tlangs.join(","),
        job_id: this.rowData.job_id,
      }).then((res) => {
        if (!res) {
          return;
        }
      });
    },
    /**
     *
     * @param {*克隆任务} id
     */
    get_job_clone_info(id) {
      return get_job_clone_info({
        action: "get_job_clone_info",
        job_id: id,
      }).then((res) => {
        if (!res) {
          return;
        }
        return res.info;
      });
    },
    /**
     * 执行GPT翻译
     * @param id
     */
    run_gpt_jobs(id) {
      let job_ids = [];
      this.multipleSelection.forEach((item) => {
        job_ids.push(item.job_id);
      });
      //  console.log(job_ids);
      run_gpt_jobs({
        action: "run_gpt_jobs",
        project_id: id,
        job_list: job_ids,
        ait_type: this.radio6,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.get_team_job_list().then(() => {
          this.dialogImportVisible = false;
          // this.$refs.multipleTable.clearSelection();
          // this.multipleSelection = [];
          // this.startrow = {};
        });
      });
    },
    /**
     * 保存拆分结果
     * @param
     */
    update_split_job(obj) {
      update_split_job({
        action: "update_split_job",
        project_id: this.project_id,
        job_id: this.commandRow.job_id,
        split_mode: obj.radio,
        split_count: obj.input,
        workflow: obj.activeName,
        list: obj.tableData,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.get_team_job_list();
        this.dialogVisible = false;
      });
    },
    /**
     * 指定拆分任务人员
     * @param obj
     */
    set_job_split_user(obj) {
      set_job_split_user({
        action: "set_job_split_user",
        job_id: obj.job_id,
        job_split_id: obj.job_split_id,
        user_id: obj.user_id,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.get_job_split_info(obj).then((res) => {
          this.internalRowData.task_url = res.task_url;
          this.internalRowData.delivery_time = res.delivery_time;
          this.internalRowData.member_id = res.member_id;
          this.internalRowData.nickname = res.nickname;
          // console.log(this.internalRowData.nickname);
          hideLoading();
        });
      });
    },
    /**
     * 获取 任务信息
     * @param row
     * @returns {*}
     */
    get_job_split_info(row) {
      return get_job_split_info({
        action: "get_job_split_info",
        job_id: row.job_id,
        job_split_id: row.job_split_id,
      }).then((res) => {
        if (!res) {
          return;
        }
        if (this.internalRowData.workflow === "1") {
          this.internalRowData.newTaskUrl =
            this.mediaUrl +
            "ContainerHome/OverwriteOperation?" +
            res.info.task_url;
        } else if (this.internalRowData.workflow === "2") {
          this.internalRowData.newTaskUrl =
            this.mediaUrl +
            "ContainerHome/TranslationOperation?" +
            res.info.task_url;
        } else if (this.internalRowData.workflow === "5") {
          this.internalRowData.newTaskUrl =
            this.mediaUrl +
            "ContainerHome/OverwriteOperationNew?" +
            res.info.task_url;
        } else {
          this.internalRowData.newTaskUrl =
            this.mediaUrl +
            "ContainerHome/ProofingOperation?" +
            res.info.task_url;
        }
        this.internalRowData.gatUrl =
          this.mediaUrl + "ContainerHome/SubmitReport?" + res.info.task_url;
        this.internalRowData.sttUrl =
          this.mediaUrl + "ContainerHome/SubmitReport1?" + res.info.task_url;
        this.internalRowData.reportUrl =
          this.mediaUrl +
          "ContainerHome/TranslationReport?job_id=" +
          row.job_id +
          "&" +
          res.info.task_url;
        this.internalRowData.stqUrl =
          this.mediaUrl +
          "ContainerHome/TranscriptionReport?job_id=" +
          row.job_id +
          "&" +
          res.info.task_url;
        return res.info;
      });
    },
    /**
     *
     * @param {*获取成员列表信息} data
     */
    get_member_list(id) {
      get_member_list({
        action: "get_member_list",
        team_user_status: id,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.dropdownLists = res.list;
      });
    },
    // ！======================================== 其他 操作方法===============================
    /**
     * 子表格 tab 页切换
     * @param item
     */
    handleTabClick(item) {
      item.tableData = item.split_list.filter(
        (i) => i.workflow === item.activeName
      );
      this.$forceUpdate();
    },
    /**
     *
     * @param {*筛选 tab 删除} tag
     */
    handleClose(tag) {
      this.tags.splice(this.tags.indexOf(tag), 1);
      this.get_team_job_list();
    },
    /**
     *
     * @param {超出溢出 显示} i
     */
    onShowNameTipsMouseenter(i) {
      onShowNameTipsMouseenter(i);
    },
    /**
     * 表头 项目名称 筛选
     */
    searchdetermineClick() {
      if (this.popoverInput) {
        this.tags = addAndreplaceTags(
          this.$t("CommonName.taskname"),
          this.popoverInput,
          this.tags,
          "name",
          this.popoverInput
        );
      }
      this.get_team_job_list();
      this.visible = false;
      this.popoverInput = "";
    },
    /**
     *
     * @param {* 设置表格 key} row
     */
    getRowKey(row) {
      return row.job_id;
    },
    /**
     *
     * @param row
     */
    expandChange(row) {
      // this.handleTabClick(row);
      this.$set(row, "tableData", row.tableData);
      this.$nextTick(() => {
        // this.$refs.childTable.doLayout();
      });
    },
    /**
     *
     * @param {*操作流程筛选} command
     */
    Operationprocesscommand(command) {
      this.tags = addAndreplaceTags(
        this.$t("ProjectDetails.Operationprocess"),
        command.name,
        this.tags,
        "job_type",
        command.value
      );
      this.get_team_job_list();
    },
    /**
     *
     * @param {*状态筛选下拉框} command
     */
    statuscommand(command) {
      this.tags = addAndreplaceTags(
        this.$t("CommonName.status"),
        command.name,
        this.tags,
        "status",
        command.value
      );
      this.get_team_job_list();
    },
    /**
     *
     * @param {*源语言 下拉框筛选} command
     */
    sourcelanguagecommand(command) {
      this.tags = addAndreplaceTags(
        this.$t("CommonName.sourcelanguagetable"),
        command.name,
        this.tags,
        "slang_id",
        command.value
      );
      this.get_team_job_list();
    },
    /**
     *
     * @param {*目标语言 下拉框筛选} command
     */
    targetlanguagecommand(command) {
      this.tags = addAndreplaceTags(
        this.$t("CommonName.targetlanguagetable"),
        command.name,
        this.tags,
        "tlang_id",
        command.value
      );
      this.get_team_job_list();
    },
    headerClick() {
      // this.multipleSelection = [];
      // this.startrow = {};
    },
    /**
     * 选中表格 行
     * @param row
     */
    rowClick(row) {
      // 按住shift
      if (this.ShiftDown) {
        // console.log("this.ShiftDown", this.ShiftDown);
        this.multipleSelection.push(row);
        let data;
        if (this.startrow.index < row.index) {
          data = this.tableData.slice(this.startrow.index, row.index + 1);
        } else {
          data = this.tableData.slice(row.index, this.startrow.index + 1);
        }
        this.multipleSelection = data;
        this.multipleSelection.forEach((rows) => {
          this.$refs.multipleTable.toggleRowSelection(rows);
        });
        return;
      }
      // 按住Ctrl
      if (this.CtrlDown) {
        let a = this.multipleSelection.find((i) => i.job_id === row.job_id);
        if (a) {
          this.multipleSelection.forEach((i, index) => {
            if (i.job_id === a.job_id) {
              this.multipleSelection.splice(index, 1);
            }
          });
          return;
        }
        this.multipleSelection.push(row);
        // this.multipleSelection.forEach((rows) => {
        //   this.$refs.multipleTable.toggleRowSelection(rows);
        // });
        return;
      }
      //   什么都不按
      if (this.multipleSelection.length === 1) {
        let a = this.multipleSelection.find((i) => i.job_id === row.job_id);
        if (a) {
          this.multipleSelection = [];
          // this.$refs.multipleTable.clearSelection();
          return;
        }
      }
      if (this.multipleSelection.length > 1) {
        let a = this.multipleSelection.find((i) => i.job_id === row.job_id);
        if (a) {
          this.startrow = {};
          this.multipleSelection = [];
          this.startrow = row;
          this.multipleSelection.push(row);
          // this.multipleSelection.forEach((rows) => {
          //   this.$refs.multipleTable.toggleRowSelection(rows);
          // });
          return;
        }
      }
      this.startrow = {};
      this.multipleSelection = [];
      this.startrow = row;
      this.multipleSelection.push(row);
      // this.multipleSelection.forEach((rows) => {
      //   this.$refs.multipleTable.toggleRowSelection(rows);
      // });
    },
    /**
     *
     * @param {设置选中 表格背景颜色} param0
     */
    tableRowClassName({ row }) {
      // console.log(row, "row");
      let color = "";
      // console.log(this.multipleSelection);
      for (let index = 0; index < this.multipleSelection.length; index++) {
        if (this.multipleSelection[index].job_id === row.job_id) {
          color = "table-SelectedRow-bgcolor";
          // color = "success-row";
        }
      }
      if (row.split_list.length === 0) {
        color += " hide-expand";
      }
      return color;
    },
    /**
     *
     * @param {*弹窗关闭} val
     */
    changeDialogVisible(val) {
      this.dialogVisible = val;
      this.showfooter = false;
      this.dialogWidth = false;
      this.showTitle = false;
      this.showTop = false;
      this.buttonLoading = false;
    },
    /**
     * 弹窗 确定按钮
     */
    determineClick() {
      //  更改指派人
      if (
        this.dialogtitle ===
          this.$t("ProjectDetails.AssignChangeTaskHandler") ||
        this.dialogtitle === this.$t("ProjectDetails.AssignChangeTaskHandler1")
      ) {
        this.buttonLoading = true;
        let obj = this.$refs.AppointedTask.form;
        let arr = [
          {
            type: 1,
            user_id: obj.select1_id,
          },
          {
            type: 2,
            user_id: obj.select2_id,
          },
          {
            type: 3,
            user_id: obj.select3_id,
          },
          {
            type: 5,
            user_id: obj.select5_id,
          },
        ];
        if (this.isProject) {
          this.set_project_user_batch(arr).then(() => {
            this.$messages("success", this.$t("CommonName.successmessage6"));
            this.closeinfo();
            this.buttonLoading = false;
          });
        } else {
          this.set_job_user_batch(arr).then(() => {
            this.$messages("success", this.$t("CommonName.successmessage6"));
            this.get_team_job_list();
            this.closeinfo();
            this.buttonLoading = false;
          });
        }
      } else if (
        this.dialogtitle === this.$t("ProjectDetails.dropdownBatchList.name36")
      ) {
        //批量替换任务原文SRT
        if (!this.zipUpload) {
          this.$messages(
            "warning",
            this.$t("ExternalCreateTask.warningmessage6")
          );
          return;
        }
        let job_ids = [];
        this.multipleSelection.forEach((item) => {
          job_ids.push(item.job_id);
        });
        this.uploadData = {
          project_id: this.project_id,
          job_ids,
        };
        this.$nextTick(() => {
          this.$refs.zipUpload.submit();
        });
      } else if (
        this.dialogtitle === this.$t("ProjectManagement.ExcelToContent")
      ) {
        //导入Excel内容
        if (!this.xlsupload) {
          this.$messages(
            "warning",
            this.$t("ExternalCreateTask.warningmessage6")
          );
          return;
        }
        this.uploadData = {
          action: "upload_excel_file_to_content",
          job_id: this.commandRow.job_id,
        };
        this.$nextTick(() => {
          this.$refs.xlsupload.submit();
        });
      } else if (
        //导入 AIT 文件
        this.dialogtitle === this.$t("ProjectDetails.dropdownList.name8")
      ) {
        if (!this.aitUpload) {
          this.$messages(
            "warning",
            this.$t("ExternalCreateTask.warningmessage6")
          );
          return;
        }
        this.uploadData = {
          action: "upload_excel_ait_file_to_content",
          job_id: this.commandRow.job_id,
        };
        this.$nextTick(() => {
          this.$refs.aitUpload.submit();
        });
      } else if (
        //重命名
        this.dialogtitle === this.$t("ProjectDetails.dropdownList.name7")
      ) {
        if (this.renameInput.trim() == "") {
          this.$messages("warning", this.$t("CommonName.warningmessage3"));
          return;
        }
        update_job_name({
          action: "update_job_name",
          job_id: this.commandRow.job_id,
          job_name: this.renameInput,
        }).then((res) => {
          if (!res) {
            return;
          }
          this.$messages("success", this.$t("CommonName.successmessage3"));
          this.get_team_job_list();
          this.closeinfo();
        });
      } else if (
        this.dialogtitle === this.$t("ProjectDetails.dropdownList.name9")
      ) {
        // 拆分句段
        let obj = {};
        let obj1 = this.$refs.SplitTask;
        obj.input = obj1.newArr[obj1.activeName].input;
        obj.radio = obj1.newArr[obj1.activeName].radio;
        obj.activeName = obj1.activeName;
        obj.tableData = obj1.newArr[obj1.activeName].tableData;
        if (obj.tableData.length === 0) {
          this.$messages("warning", this.$t("SplitTask.name5"));
          return;
        }
        if (obj.tableData.some((i) => i.job_name === "")) {
          return;
        }
        showLoading(this.$t("CommonName.loadingmessage"));
        this.update_split_job(obj);
      } else {
        this.closeinfo();
      }
    },
    /**
     * 关闭弹窗 方法
     */
    closeinfo() {
      this.dialogVisible = false;
      this.showfooter = false;
      this.dialogWidth = false;
      this.showTitle = false;
      this.showTop = false;
    },
    /**
     * 批量处理 下拉框
     */
    commandClick() {},

    /**
     * 返回按钮
     */
    signOut() {
      this.$router.push({
        name: "ProjectManagement",
        query: {
          id: this.folder_pid,
        },
      });
    },
    /**
     *
     * @param {*点击的 头部 按钮} name
     */
    headerBtnClick(name, id) {
      this.dialogtitle = name;
      // 术语
      this.showTitle = name === this.$t("ProjectDetails.buttonList.name5");
      // 创建任务
      if (name == this.$t("ProjectDetails.buttonList.name2")) {
        this.DetermineClickCreateTask = "1";
        this.showCreateTaskdialog = true;
        return;
      }
      // 导入任务
      if (name == this.$t("ProjectDetails.buttonList.name1")) {
        this.showImportTask = true;
        return;
      }
      /**
       * 批量创建 硬字幕识别任务
       */
      // if (name == this.$t("ProjectDetails.buttonList.name8")) {
      //   // console.log(11111);
      //   this.showBatchCreateHardSubtitleRecognitiondialog = true;
      //   return;
      // }
      if (id == "2") {
        this.showBatchCreatingTask = true;
        return;
      }
      // 指派执行人
      // if (name == this.$t("ProjectDetails.buttonList.name7")) {
      //   return;
      // }
      this.dialogVisible = true;
    },
    /**
     * 点击关闭创建任务 弹窗
     */
    claseCreateTaskDialog(val) {
      // console.log(val);
      this.showCreateTaskdialog = val;
    },
    claseDialog(val) {
      this.dialogVisible = val;
    },
    /**
     * 点击关闭 批量创建 硬字幕识别任务
     * @param val
     */
    closeHardSubtitleRecognitiondialog(val) {
      this.showBatchCreateHardSubtitleRecognitiondialog = val;
      this.showBatchCreateHardSubHuadialog = val;
    },
    /**
     *  点击创建 批量创建 硬字幕识别任务
     * @param val
     * @constructor
     */
    CreatHardSubtitleRecognitionTasks(val) {
      this.get_team_job_list().then(() => {
        this.showBatchCreateHardSubtitleRecognitiondialog = val;
        this.showBatchCreateHardSubHuadialog = val;
        this.$messages(
          "success",
          this.$t("ExternalCreateTask.successmessage1")
        );
      });
    },
    /**
     *
     * @param {导入任务 关闭弹窗按钮} val
     */
    clickImportTaskDialog(val) {
      this.showImportTask = val;
    },
    /**
     *
     * @param {* 导入任务 成功 调用 方法} val
     */
    requestImportTask(val) {
      this.get_team_job_list().then(() => {
        this.showImportTask = val;
        this.$messages("success", this.$t("ImportTask.successmessage1"));
      });
    },
    /**
     *
     * @param {*创建任务 成功} val
     */
    Creationcompleted(val) {
      this.get_team_job_list().then(() => {
        this.showCreateTaskdialog = val;
        this.$messages(
          "success",
          this.$t("ExternalCreateTask.successmessage1")
        );
      });
    },
    /**
     *
     * @param {*批量创建 任务 弹窗} val
     */
    clickBatchCreatingTask(val) {
      this.showBatchCreatingTask = val;
    },
    /**
     *
     * @param {* 字幕设置  弹窗} val
     */
    clickCaptionSettings(val, data = 0) {
      this.showCaptionSettings = val;
      if (data == 1) {
        this.get_project_info(this.project_id).then((res) => {
          this.projectInfo = res;
        });
      }
    },
    /**
     *
     * @param {* 批量创建 任务 弹窗 获取数据} val
     */
    createdBatchTask(val) {
      this.buttonLoading = true;
      this.get_team_job_list().then(() => {
        this.showBatchCreatingTask = val;
        this.$messages(
          "success",
          this.$t("ExternalCreateTask.successmessage1")
        );
      });
    },
    /**
     *
     * @param {*任务详情 打回 到上一步} val
     */
    ProjectDetailsgetData(val) {
      this.get_team_job_list();
    },
    /**
     *
     * @param {确认按钮} row
     */
    confirmClick(row) {
      console.log(row);
    },

    //--------------------------------------------------------头部按钮操作-----------------------------------------------------------------------------------------
    /**
     * 表格复选框 选中
     * @param selection
     * @param row
     */
    handleSelect(selection, row) {
      if (this.ShiftDown) {
        // this.multipleSelection.push(row);
        if (!!this.startrow.job_id == false) {
          this.multipleSelection = selection;
          this.$refs.multipleTable.clearSelection();
          this.$refs.multipleTable.toggleRowSelection(row);
          this.startrow = row;
        } else {
          let data;
          if (this.startrow.index < row.index) {
            data = this.tableData.slice(this.startrow.index, row.index + 1);
          } else {
            data = this.tableData.slice(row.index, this.startrow.index + 1);
          }
          this.multipleSelection = data;
          this.$refs.multipleTable.clearSelection();
          data.forEach((rows) => {
            this.$refs.multipleTable.toggleRowSelection(rows);
          });
        }

        return;
      }
      this.startrow = {};
      this.startrow = row;
      this.multipleSelection = selection;
    },
    /**
     * 表格复选框 全选/全不选
     * @param selection
     * @param row
     */
    handleALLSelect(selection) {
      // if (this.ShiftDown) {
      //   this.multipleSelection.push(row);
      //   let data;
      //   if (this.startrow.index < row.index) {
      //     data = this.tableData.slice(this.startrow.index, row.index + 1);
      //   } else {
      //     data = this.tableData.slice(row.index, this.startrow.index + 1);
      //   }
      //   this.multipleSelection = data;
      //   this.$refs.multipleTable.clearSelection();
      //   data.forEach((rows) => {
      //     this.$refs.multipleTable.toggleRowSelection(rows);
      //   });
      //   return;
      // }
      this.startrow = {};
      // this.startrow = row;
      this.multipleSelection = selection;
    },
    /**
     *
     * @param {*点击表格内任务名称} row
     */
    tovideoDetails(row) {
      // console.log(row);
      this.rowData = row;
      // console.log(this.rowData);
      this.showTop = true;
      this.showfooter = false;
      this.dialogWidth = "1024px";
      this.dialogtitle = row.name;
      this.showTitle = true;
      this.dialogVisible = true;
    },
    /**
     * 内部表格 名称 点击 展示
     * @param row
     */
    tovideoDetails1(row) {
      this.internalRowData = row;
      this.get_job_split_info(row).then((res) => {
        this.get_member_list(2);
        this.internalRowData.task_url = res.task_url;
        this.internalRowData.member_id = res.member_id;
        this.internalRowData.delivery_time = res.delivery_time;
        this.internalRowData.check_code = res.check_code;
        this.dialogtitle = row.job_name;
        this.dialogVisible = true;
        this.showTitle = true;
        this.showfooter = false;
      });
    },
    /**
     * 打回
     * @param row
     */
    rejectjob(row) {
      // console.log(row);
      showLoading(this.$t("CommonName.loadingmessage"));
      reject_split_job({
        action: "reject_split_job",
        job_id: row.job_id,
        job_split_id: row.job_split_id,
      }).then((res) => {
        if (!res) {
          console.log(row);
          hideLoading();
          return;
        }
        let obj = this.tableData.find((item) => item.job_id === row.job_id);
        let activeName = obj.activeName;
        this.get_team_job_list().then((res) => {
          let obj1 = this.tableData.find((item) => item.job_id === row.job_id);
          obj1.activeName = activeName;
          obj1.tableData = obj1.split_list.filter(
            (i) => i.workflow === obj1.activeName
          );
        });
      });
    },
    /**
     *
     * @param {*拆分表格 操作} row
     */
    commandSpiltClicks(row) {
      console.log(row);
      switch (row.command) {
        case "1":
          this.rejectjob(row.row);
          break;
        case "2":
          this.runSplitAITJob(row.row);
          break;
        case "3":
          this.CleanSplitAITJob(row.row);
          break;
        default:
          break;
      }
    },
    /**
     *
     * @param {*表格 操作} row
     * row.command 1.导出Excel文件 2.导入Excel文件
     * 3.生成波形 4.克隆任务 5.删除任务 6.指派/更改人员
     */
    commandClicks(row) {
      console.log(row);
      this.commandRow = row.row;
      this.dialogWidth = false;
      switch (row.command) {
        case "6":
          this.dialogtitle = this.$t("ProjectDetails.AssignChangeTaskHandler");
          this.isProject = false;
          this.showfooter = true;
          this.dialogVisible = true;
          break;
        case "5":
          this.$confirm(
            this.$t("CommonName.deleteMessage1"),
            this.$t("CommonName.deleteMessageTitle"),
            {
              confirmButtonText: this.$t("CommonName.deleteMessagebutton"),
              cancelButtonText: this.$t("CommonName.deleteMessageClosebutton"),
              closeOnClickModal: false,
              cancelButtonClass: "closeButton",
              // confirmButtonClass: "confirmButton",
            }
          ).then(() => {
            showLoading(this.$t("CommonName.loadingmessage"));
            this.delete_job(row.row.job_id);
          });
          break;
        case "1":
          this.export_excel_task_content(row.row);
          break;
        case "2":
          this.xlsupload = "";
          this.dialogtitle = this.$t("ProjectManagement.ExcelToContent");
          this.showfooter = true;
          this.dialogVisible = true;
          break;
        case "3":
          //生成波形
          this.generate_waveform(row.row);
          break;
        case "4":
          // 克隆任务
          this.get_job_clone_info(row.row.job_id).then((res) => {
            // console.log(res);

            this.DetermineClickCreateTask = "2";
            this.Clonejobinfo = res;
            this.showCreateTaskdialog = true;
          });
          break;
        //   重命名
        case "7":
          this.renameInput = this.commandRow.name;
          this.dialogtitle = this.$t("ProjectDetails.dropdownList.name7");
          this.showfooter = true;
          this.dialogVisible = true;
          break;
        //   导入 AIT 文件
        case "8":
          this.aitUpload = "";
          this.dialogtitle = this.$t("ProjectDetails.dropdownList.name8");
          this.showfooter = true;
          this.dialogVisible = true;
          break;
        //   拆分
        case "9":
          this.dialogtitle = this.$t("ProjectDetails.dropdownList.name9");
          this.showfooter = true;
          this.showTop = true;
          this.dialogVisible = true;
          this.dialogWidth = "1100px";
          this.$refs.multipleTable.toggleRowExpansion(row.row, false);
          break;
        //从其他任务导入项目id
        case "10":
          showLoading(this.$t("CommonName.loadingmessage"));
          get_team_job_list({
            action: "get_project_alllist",
          }).then((res) => {
            hideLoading();
            if (!res) {
              return;
            }
            this.projectList = res.list;
            this.projectSelected = "";
            this.jobSelected = "";
            this.import_job_id = row.row.job_id;
            this.import_type = "2";
            this.import_title = this.$t("ProjectDetails.dropdownList.name10");
            this.import_tips = this.$t("ProjectDetails.AITTips1");
            this.dialogImportVisible = true;
          });
          break;
        //从其他任务导入备注
        case "11":
          showLoading(this.$t("CommonName.loadingmessage"));
          get_team_job_list({
            action: "get_project_alllist",
          }).then((res) => {
            hideLoading();
            if (!res) {
              return;
            }
            this.projectList = res.list;
            this.projectSelected = "";
            this.jobSelected = "";
            this.sWorkflows = [];
            this.tWorkflows = [];
            this.sWorkflowSelected = "";
            this.tWorkflowSelected = "";
            this.tWorkflows = this.workflowMethod(row.row.job_type);
            this.import_job_id = row.row.job_id;
            this.import_type = "4";
            this.import_title = this.$t("ProjectDetails.dropdownList.name11");
            this.import_tips = "";
            this.dialogImportVisible = true;
          });
          break;
        default:
          break;
      }
    },
    // ===================================上传 新的术语表=================================================
    /**
     * 点击下载 术语表模板
     */
    Download() {
      window.location.href = "/resourcefile/Glossary_Temp.xlsx";
    },
    /**
     *
     * @param {*点击重新上传} ref
     */
    srtupload(ref, data) {
      this.showUpload = data;
      if (data == 1) {
        this.xlsupload = "";
      } else if (data == 3) {
        this.formTermData.upload = "";
      } else if (data == 4) {
        this.aitUpload = "";
      } else if (data == 5) {
        this.zipUpload = "";
      } else {
        this.formLabelAlign.upload = "";
      }
      let uploadFilesArr = this.$refs[ref].uploadFiles; //上传字幕文件
      if (uploadFilesArr.length == 0) {
        return;
      } else {
        this.$refs[ref].uploadFiles = [];
      }
    },
    /**
     *
     * @param {*上传前  做判断是否能上传} file
     */
    uploadChange(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testmsg === "xlsx";
      if (!extension) {
        this.$messages(
          "warning",
          this.$t("TermBaseManagement.warningmessage1")
        );
        if (this.showUpload == 1) {
          this.xlsupload = "";
          this.$refs.xlsupload.clearFiles();
        } else if (this.showUpload == 3) {
          this.formTermData.upload = "";
          this.$refs.uploadUpdate.clearFiles();
        } else if (this.showUpload == 4) {
          this.aitUpload = "";
          this.$refs.aitUpload.clearFiles();
        } else {
          this.formLabelAlign.upload = "";
          this.$refs.upload.clearFiles();
        }
        return false;
      } else {
        if (this.showUpload == 1) {
          this.xlsupload = file.name;
        } else if (this.showUpload == 3) {
          this.formTermData.upload = file.name;
        } else if (this.showUpload == 4) {
          this.aitUpload = file.name;
        } else {
          this.formLabelAlign.upload = file.name;
        }
        return true;
      }
    },
    /**
     *
     * @param {*上传前  做判断是否能上传} file
     */
    uploadZipChange(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testmsg === "zip";
      if (!extension) {
        this.$messages("warning", this.$t("ProjectManagement.warningmessage2"));
        this.zipUpload = "";
        this.$refs.zipUpload.clearFiles();
        return false;
      } else {
        this.zipUpload = file.name;
        return true;
      }
    },
    /**
     *
     * @param {*上传成功} response
     */
    uploadSuccess(response) {
      if (response.result == "SUCCESS") {
        this.get_team_job_list().then(() => {
          this.innerVisible = false;
          this.dialogVisible = false;
          this.showfooter = false;
          this.percentage = 0;
          this.loading = false;
          this.$messages("success", this.$t("CommonName.successmessage5"));
        });
      } else if (response.result == "LOGIN_FAILED") {
        this.$messages("warning", this.$t("CommonName.warningmessage4"));
        this.$router.push("/Login");
        this.$Cookie.remove("token_code");
        sessionStorage.clear();
      } else {
        this.percentage = 0;
        this.loading = false;
        this.innerVisible = false;
        this.dialogVisible = false;
        this.showfooter = false;
        this.$messages("error", response.msg);
      }
    },
    /**
     *
     * @param {*上传成功} response
     */
    uploadTermSuccess(response) {
      if (response.result == "SUCCESS") {
        // this.get_team_job_list().then(() => {
        this.innerVisible = false;
        this.dialogVisible = false;
        this.percentage = 0;
        this.loading = false;
        // if (this.showInnerDialogs == 5) {
        //   this.$messages("success", this.$t("CommonName.successmessage4"));
        // } else {
        //   this.$messages("success", this.$t("CommonName.successmessage1"));
        // }

        this.$messages(
          "success",
          this.$t("CommonName.uploadTermTip1") +
            response.count +
            this.$t("CommonName.uploadTermTip2") +
            response.count +
            this.$t("CommonName.uploadTermTip3"),
          4000
        );
        // });
      } else if (response.result == "LOGIN_FAILED") {
        this.$messages("warning", this.$t("CommonName.warningmessage4"));
        this.$router.push("/Login");
        this.$Cookie.remove("token_code");
        sessionStorage.clear();
      } else {
        this.percentage = 0;
        this.loading = false;
        this.innerVisible = false;
        this.dialogVisible = false;
        this.$messages("error", response.msg);
      }
    },
    /**
     *
     * @param {*上传过程中} file
     */
    uploadProgress(file) {
      if (file.percent > 0) {
        this.percentage = Number(file.percent.toFixed());
        this.loading = true;
      }
    },
    sortChange(column) {
      // console.log(column);
      showLoading(this.$t("CommonName.loadingmessage"));
      if (column.order == "ascending") {
        this.older = column.prop;
        this.sort = "asc";
      } else {
        this.older = column.prop;
        this.sort = "desc";
      }
      this.get_team_job_list();
    },
    /**
     * 隐藏菜单 按钮
     * @param comments
     */
    commandButtonClicks(comments) {
      // 指派执行人
      //
      // if (comments.id === "6_2" || comments.id === "6_1") {
      //   if (this.multipleSelection.length === 0) {
      //     return;
      //   }
      // }
      this.btnchId = comments.id;

      // 字幕设置
      if (this.btnchId == "5_1") {
        this.showCaptionSettings = true;
        return;
        // this.showTop = true;
      }
      if (this.btnchId == "2_1") {
        /**
         * 批量创建任务
         */
        this.dialogtitle = comments.name;
        this.showBatchCreatingTask = true;
      } else if (this.btnchId == "2_2") {
        /**
         * 批量创建 硬字幕识别任务
         */
        this.dialogtitle = comments.name;
        this.showBatchCreateHardSubtitleRecognitiondialog = true;
      } else if (this.btnchId == "2_3") {
        /**
         * 批量创建 硬字幕识别画面字任务
         */
        this.dialogtitle = comments.name;
        this.showBatchCreateHardSubHuadialog = true;
      }
      if (this.btnchId == "8_1") {
        // 批量打开 校审 转写 翻译页面
        if (this.multipleSelection.length === 0) {
          return;
        }

        let arr = [];
        let path = "";
        let localName = "";
        let first_slang = this.multipleSelection[0]["slang_id"];
        let first_tlang = this.multipleSelection[0]["tlang_id"];
        let first_status = this.multipleSelection[0]["status"];
        // console.log("first_slang", first_slang);
        // console.log("first_tlang", first_tlang);
        // console.log("multipleSelection", this.multipleSelection);
        if (!this.multipleSelection.every((i) => i.status !== "50")) {
          this.$messages("warning", this.$t("MyFiles.Projecttask12"));
          return;
        }
        if (first_status === "40") {
          path = "/ContainerHome/BatchProofreading";
          localName = "projectList";
          if (!this.multipleSelection.every((i) => i.status === "40")) {
            this.$messages("warning", this.$t("MyFiles.Projecttask10"));
            return;
          }
          if (
            !this.multipleSelection.every(
              (i) => i.slang_id === first_slang && i.tlang_id === first_tlang
            )
          ) {
            this.$messages("warning", this.$t("MyFiles.Projecttask6"));
            return;
          }
        }
        if (first_status === "20") {
          path = "/ContainerHome/BatchTranscription";
          localName = "projectList_Transcription";
          if (!this.multipleSelection.every((i) => i.status === "20")) {
            this.$messages("warning", this.$t("MyFiles.Projecttask10"));
            return;
          }
          if (
            !this.multipleSelection.every((i) => i.slang_id === first_slang)
          ) {
            this.$messages("warning", this.$t("MyFiles.Projecttask6"));
            return;
          }
        }
        if (first_status === "30") {
          path = "/ContainerHome/BatchTranslation";
          localName = "projectList_translation";
          if (!this.multipleSelection.every((i) => i.status === "30")) {
            this.$messages("warning", this.$t("MyFiles.Projecttask10"));
            return;
          }
          if (
            !this.multipleSelection.every(
              (i) => i.slang_id === first_slang && i.tlang_id === first_tlang
            )
          ) {
            this.$messages("warning", this.$t("MyFiles.Projecttask6"));
            return;
          }
        }
        if (first_status === "25") {
          path = "/ContainerHome/BatchTranscriptionQC";
          localName = "projectList_TranscriptionQC";
          if (!this.multipleSelection.every((i) => i.status === "25")) {
            this.$messages("warning", this.$t("MyFiles.Projecttask10"));
            return;
          }
          if (
            !this.multipleSelection.every((i) => i.slang_id === first_slang)
          ) {
            this.$messages("warning", this.$t("MyFiles.Projecttask6"));
            return;
          }
        }
        this.multipleSelection.forEach((i, index) => {
          arr.push({
            name: i.name,
            id: i.job_id,
            pid: i.task_url.split("=")[1].split("&")[0],
            fid: i.task_url.split("=")[2],
            task: "",
          });
        });

        localStorage.setItem(localName, JSON.stringify(arr));
        let pathInfo = this.$router.resolve({
          path,
          query: {
            pid: arr[0].pid,
            fid: arr[0].fid,
            id: arr[0].id,
            lang: sessionStorage.getItem("lang"),
          },
        });
        window.open(pathInfo.href, "_blank");
        return false;
      } else if (this.btnchId == "8_2") {
        // 批量打开质量报告
        // 仅有校审或者已交付的状态可以打开
        if (this.multipleSelection.length === 0) {
          return;
        }

        let arr = [];
        let path = "";
        let localName = "";
        let first_slang = this.multipleSelection[0]["slang_id"];
        let first_tlang = this.multipleSelection[0]["tlang_id"];
        let first_status = this.multipleSelection[0]["status"];
        let first_type = this.multipleSelection[0]["job_type"];
        // console.log("first_slang", first_slang);
        // console.log("first_tlang", first_tlang);
        console.log("multipleSelection", this.multipleSelection);
        if (
          !this.multipleSelection.every((i) =>
            ["2", "3", "4", "5", "7"].includes(i.job_type)
          )
        ) {
          this.$messages("warning", this.$t("MyFiles.Projecttask13"));
          return;
        }
        // return;
        if (["40", "50"].includes(first_status)) {
          path = "/ContainerHome/BatchTranslationReport";
          localName = "projectList_report";
          if (
            !this.multipleSelection.every((i) =>
              ["40", "50"].includes(i.status)
            )
          ) {
            this.$messages("warning", this.$t("MyFiles.Projecttask14"));
            return;
          }
          if (
            !this.multipleSelection.every(
              (i) => i.slang_id === first_slang && i.tlang_id === first_tlang
            )
          ) {
            this.$messages("warning", this.$t("MyFiles.Projecttask6"));
            return;
          }
        } else {
          this.$messages("warning", this.$t("MyFiles.Projecttask14"));
          return;
        }
        // return;
        this.multipleSelection.forEach((i, index) => {
          arr.push({
            name: i.name,
            id: i.job_id,
            pid: i.task_url.split("=")[1].split("&")[0],
            fid: i.task_url.split("=")[2],
            task: "",
          });
        });

        localStorage.setItem(localName, JSON.stringify(arr));
        let pathInfo = this.$router.resolve({
          path,
          query: {
            pid: arr[0].pid,
            fid: arr[0].fid,
            id: arr[0].id,
            lang: sessionStorage.getItem("lang"),
          },
        });
        window.open(pathInfo.href, "_blank");
        return false;
      }
      // 指派执行人
      // if (name == this.$t("ProjectDetails.buttonList.name7")) {
      //   return;
      // }
      // this.dialogVisible = true;
      // if (comments.id === "6_1") {
      //   this.dialogtitle = this.$t("ProjectDetails.buttonList.name7");
      //   this.showOperator = true;
      // }
    },
    /**
     *
     * @param {*查看项目Comments备注信息} id
     */
    get_project_comments(id) {
      return get_project_comments({
        action: "get_project_comments",
        project_id: id,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.ProjectCommentsData = res.list;
      });
    },
    /**
     *
     * @param row
     * @returns {boolean}
     */
    commandBatchClicks(row) {
      if (row.command === "26") {
        this.dialogtitle = this.$t("ProjectDetails.AssignChangeTaskHandler1");
        this.isProject = true;
        this.showfooter = true;
        this.dialogVisible = true;
        return false;
      }
      if (this.tableData.length === 0) {
        return false;
      }
      console.log(this.multipleSelection);
      if (row.command === "1") {
        this.checkboxDownload = ["3"];
        this.innerDownloadVisible = true;
      } else if (row.command === "5") {
        this.export_task_urls_method();
      } else if (row.command === "6") {
        this.checkboxDownload = ["2"];
        this.get_project_comments(this.project_id).then(() => {
          this.commentsSelect = [];
          this.innerExportVisible = true;
        });
      } else if (row.command === "7") {
        //Run AIT
        if (this.multipleSelection.length === 0) {
          return;
        }
        this.radio6 = "2";
        this.import_type = "6";
        this.import_title = "Run AIT";
        this.import_tips = "";
        this.dialogImportVisible = true;
        // showLoading(this.$t("CommonName.loadingmessage"));
        // console.log(row);
        // this.run_gpt_jobs(this.project_id);
      } else if (row.command === "8") {
        //Close
        this.close_job_method();
      } else if (row.command === "9") {
        //Clean up AIT
        showLoading(this.$t("CommonName.loadingmessage"));
        // console.log(row);
        this.clean_up_gpt_jobs(this.project_id);
      } else if (row.command === "10") {
        //Stop AIT
        showLoading(this.$t("CommonName.loadingmessage"));
        this.stop_gpt_jobs(this.project_id);
      } else if (row.command === "11") {
        if (this.multipleSelection.length === 0) {
          return;
        }

        let srt = "";
        const isNameBob = this.multipleSelection.every(
          (item) => item.status === "20"
        );
        const isNameBob1 = this.multipleSelection.every(
          (item) => item.status === "30"
        );
        const isNameBob2 = this.multipleSelection.every(
          (item) => item.status === "40"
        );
        const isNameBob3 = this.multipleSelection.every(
          (item) => item.status === "50"
        );
        if (isNameBob || isNameBob1 || isNameBob2 || isNameBob3) {
          srt = this.$t("ProjectDetails.repulseMessage");
        } else {
          srt = this.$t("ProjectDetails.repulseMessage1");
        }
        this.$confirm(srt, this.$t("CommonName.deleteMessageTitle"), {
          confirmButtonText: this.$t("CommonName.deleteMessagebutton"),
          cancelButtonText: this.$t("CommonName.deleteMessageClosebutton"),
          closeOnClickModal: false,
          cancelButtonClass: "closeButton",
        })
          .then(() => {
            showLoading(this.$t("CommonName.loadingmessage"));
            this.reject_job();
          })
          .catch(() => {});
        //  run mt
      } else if (row.command === "12") {
        if (this.multipleSelection.length === 0) {
          return;
        }
        showLoading(this.$t("CommonName.loadingmessage"));
        this.run_mt_jobs(this.project_id);
      } else if (
        row.command === "13" ||
        row.command === "14" ||
        row.command === "15" ||
        row.command === "19"
      ) {
        // 批量打开 校审 转写 翻译页面
        if (this.multipleSelection.length === 0) {
          return;
        }

        let arr = [];
        let path = "";
        let localName = "";
        let first_slang = this.multipleSelection[0]["slang_id"];
        let first_tlang = this.multipleSelection[0]["tlang_id"];
        // console.log("first_slang", first_slang);
        // console.log("first_tlang", first_tlang);
        // console.log("multipleSelection", this.multipleSelection);
        if (row.command === "13") {
          path = "/ContainerHome/BatchProofreading";
          localName = "projectList";
          if (!this.multipleSelection.every((i) => i.status === "40")) {
            this.$messages("warning", this.$t("MyFiles.Projecttask1"));
            return;
          }
          if (
            !this.multipleSelection.every(
              (i) => i.slang_id === first_slang && i.tlang_id === first_tlang
            )
          ) {
            this.$messages("warning", this.$t("MyFiles.Projecttask6"));
            return;
          }
        }
        if (row.command === "14") {
          path = "/ContainerHome/BatchTranscription";
          localName = "projectList_Transcription";
          if (!this.multipleSelection.every((i) => i.status === "20")) {
            this.$messages("warning", this.$t("MyFiles.Projecttask4"));
            return;
          }
          if (
            !this.multipleSelection.every((i) => i.slang_id === first_slang)
          ) {
            this.$messages("warning", this.$t("MyFiles.Projecttask6"));
            return;
          }
        }
        if (row.command === "15") {
          path = "/ContainerHome/BatchTranslation";
          localName = "projectList_translation";
          if (!this.multipleSelection.every((i) => i.status === "30")) {
            this.$messages("warning", this.$t("MyFiles.Projecttask5"));
            return;
          }
          if (
            !this.multipleSelection.every(
              (i) => i.slang_id === first_slang && i.tlang_id === first_tlang
            )
          ) {
            this.$messages("warning", this.$t("MyFiles.Projecttask6"));
            return;
          }
        }
        if (row.command === "19") {
          path = "/ContainerHome/BatchTranscriptionQC";
          localName = "projectList_TranscriptionQC";
          if (!this.multipleSelection.every((i) => i.status === "25")) {
            this.$messages("warning", this.$t("MyFiles.Projecttask8"));
            return;
          }
          if (
            !this.multipleSelection.every((i) => i.slang_id === first_slang)
          ) {
            this.$messages("warning", this.$t("MyFiles.Projecttask6"));
            return;
          }
        }
        this.multipleSelection.forEach((i, index) => {
          arr.push({
            name: i.name,
            id: i.job_id,
            pid: i.task_url.split("=")[1].split("&")[0],
            fid: i.task_url.split("=")[2],
            task: "",
          });
        });

        localStorage.setItem(localName, JSON.stringify(arr));
        let pathInfo = this.$router.resolve({
          path,
          query: {
            pid: arr[0].pid,
            fid: arr[0].fid,
            id: arr[0].id,
            lang: sessionStorage.getItem("lang"),
          },
        });
        window.open(pathInfo.href, "_blank");
      } else if (row.command === "16") {
        //移动任务
        if (this.multipleSelection.length === 0) {
          return;
        }
        this.moveToFile();
      } else if (row.command === "17") {
        //批量修改任务名称
        if (this.multipleSelection.length === 0) {
          return;
        }
        this.dialogReNameVisible = true;
        this.radio2 = "0";
        this.replaceinput1 = "";
        this.replaceinput2 = "";
        this.replaceinput3 = "";
      } else if (row.command === "18") {
        //Del
        this.delete_job_method();
      } else if (row.command === "20") {
        //Del
        this.export_subtitles_report_method();
      } else if (row.command === "21") {
        if (this.multipleSelection.length === 0) {
          return;
        }
        let job_ids = [];
        this.multipleSelection.forEach((item) => {
          job_ids.push(item.job_id);
        });
        job_ids = job_ids.join(",");
        this.jobIds = job_ids;
        this.dialogDownloadVisible = true;
      } else if (row.command === "22") {
        if (this.multipleSelection.length === 0) {
          return;
        }
        showLoading(this.$t("CommonName.loadingmessage"));
        get_team_job_list({
          action: "get_project_alllist",
        }).then((res) => {
          hideLoading();
          if (!res) {
            return;
          }
          this.projectList = res.list.filter(
            (i) => i.project_id != this.project_id
          );
          this.projectSelected = "";
          this.import_type = "1";
          this.import_title = this.$t(
            "ProjectDetails.dropdownBatchList.name30"
          );
          this.import_tips = this.$t("ProjectDetails.AITTips");
          this.dialogImportVisible = true;
        });
      } else if (row.command === "23") {
        if (this.multipleSelection.length === 0) {
          return;
        }
        showLoading(this.$t("CommonName.loadingmessage"));
        get_team_job_list({
          action: "get_project_alllist",
        }).then((res) => {
          hideLoading();
          if (!res) {
            return;
          }
          this.projectList = res.list.filter(
            (i) => i.project_id != this.project_id
          );
          this.projectSelected = "";
          this.import_type = "3";
          this.dialogImportVisible = true;
          this.import_title = this.$t(
            "ProjectDetails.dropdownBatchList.name33"
          );
          this.import_tips = this.$t("ProjectDetails.AITTips2");
        });
      } else if (row.command === "24") {
        if (this.multipleSelection.length === 0) {
          return;
        }
        this.dialogtitle = this.$t("ProjectDetails.dropdownBatchList.name36");
        this.dialogVisible = true;
        this.dialogWidth = false;
        this.zipUpload = "";
        this.showfooter = true;
      } else if (row.command === "25") {
        if (this.multipleSelection.length === 0) {
          return;
        }
        // console.log(this.multipleSelection);
        // console.log(this.tableData);
        showLoading(this.$t("CommonName.loadingmessage"));
        get_team_job_list({
          action: "get_project_alllist",
        }).then((res) => {
          hideLoading();
          if (!res) {
            return;
          }
          this.projectList = res.list.filter(
            (i) => i.project_id != this.project_id
          );
          this.projectSelected = "";
          this.import_type = "5";
          this.jobSelected1 = "";
          this.import_tips = "";
          this.dialogImportVisible = true;
          this.import_title = this.$t(
            "ProjectDetails.dropdownBatchList.name37"
          );
        });
      }
    },

    export_excel_task_method(checkbox, job_ids, commentsSelect) {
      let url = this.baseUrl + "/videojob/export_excel_task?";
      url += "project_id=" + this.project_id;
      url += "&job_ids=" + job_ids;
      url += "&modes=" + checkbox;
      if (commentsSelect != "") {
        url += "&comment_ids=" + commentsSelect;
      }
      if (this.older != "" && this.sort != "") {
        url += "&older=" + this.older;
        url += "&sort=" + this.sort;
      }
      this.tags.forEach((item, index) => {
        if (item.key == "name") {
          url += "&" + item.key + "=" + encodeURIComponent(item.value);
        } else {
          url += "&" + item.key + "=" + item.value;
        }
      });
      url += "&time=" + new Date().getTime();
      window.open(url);
    },
    download_all_job_zip_file(checkbox, job_ids) {
      let url = this.baseUrl + "/videojob/download_all_zip_file?";
      url += "project_id=" + this.project_id;
      url += "&job_ids=" + job_ids;
      url += "&modes=" + checkbox;
      if (this.older != "" && this.sort != "") {
        url += "&older=" + this.older;
        url += "&sort=" + this.sort;
      }
      this.tags.forEach((item, index) => {
        if (item.key == "name") {
          url += "&" + item.key + "=" + encodeURIComponent(item.value);
        } else {
          url += "&" + item.key + "=" + item.value;
        }
      });
      url += "&time=" + new Date().getTime();
      window.open(url);
    },
    export_task_urls_method() {
      let job_ids = [];
      this.multipleSelection.forEach((item) => {
        job_ids.push(item.job_id);
      });
      job_ids = job_ids.join(",");
      let url = this.baseUrl + "/videojob/export_task_urls?";
      url += "project_id=" + this.project_id;
      url += "&job_ids=" + job_ids;
      if (this.older != "" && this.sort != "") {
        url += "&older=" + this.older;
        url += "&sort=" + this.sort;
      }
      this.tags.forEach((item, index) => {
        if (item.key == "name") {
          url += "&" + item.key + "=" + encodeURIComponent(item.value);
        } else {
          url += "&" + item.key + "=" + item.value;
        }
      });
      url += "&time=" + new Date().getTime();
      window.open(url);
    },
    //导出软字幕错误检查报告
    export_subtitles_report_method() {
      let job_ids = [];
      if (this.multipleSelection.length === 0) {
        return false;
      }
      this.multipleSelection.forEach((item) => {
        job_ids.push(item.job_id);
      });
      job_ids = job_ids.join(",");
      let url = this.baseUrl + "/videojob/export_subtitles_report?";
      url += "project_id=" + this.project_id;
      url += "&job_ids=" + job_ids;
      url += "&time=" + new Date().getTime();
      window.open(url);
    },
    /**
     * 批量关闭任务
     */
    close_job_method() {
      if (this.multipleSelection.length === 0) {
        return false;
      }
      let job_ids = [];
      this.multipleSelection.forEach((item) => {
        job_ids.push(item.job_id);
      });
      this.$confirm(
        this.$t("CommonName.closeMessage"),
        this.$t("CommonName.deleteMessageTitle"),
        {
          confirmButtonText: this.$t("CommonName.deleteMessagebutton"),
          cancelButtonText: this.$t("CommonName.deleteMessageClosebutton"),
          closeOnClickModal: false,
          cancelButtonClass: "closeButton",
          // confirmButtonClass: "confirmButton",
        }
      ).then(() => {
        showLoading(this.$t("CommonName.loadingmessage"));
        set_job_close({
          action: "set_job_close",
          project_id: this.project_id,
          job_ids: job_ids,
        }).then((res) => {
          if (!res) {
            return;
          }
          this.get_team_job_list();
        });
      });
    },
    /**
     * 批量删除任务
     */
    delete_job_method() {
      if (this.multipleSelection.length === 0) {
        return false;
      }
      let job_ids = [];
      this.multipleSelection.forEach((item) => {
        job_ids.push(item.job_id);
      });
      this.$confirm(
        this.$t("CommonName.deleteMessage1"),
        this.$t("CommonName.deleteMessageTitle"),
        {
          confirmButtonText: this.$t("CommonName.deleteMessagebutton"),
          cancelButtonText: this.$t("CommonName.deleteMessageClosebutton"),
          closeOnClickModal: false,
          cancelButtonClass: "closeButton",
          // confirmButtonClass: "confirmButton",
        }
      ).then(() => {
        showLoading(this.$t("CommonName.loadingmessage"));
        delete_job({
          action: "delete_job_batch",
          job_ids: job_ids,
        }).then((res) => {
          if (!res) {
            return;
          }
          this.get_team_job_list();
        });
      });
    },
    /**
     * 点击下载 模板
     */
    DownloadExcel() {
      window.location.href = "/resourcefile/Excel_Template.xlsx";
    },
    /**
     *
     * @param {* 指派执行人  弹窗} val
     */
    clickOperator(val) {
      this.get_team_job_list().then(() => {
        //清除已选
        this.$refs.multipleTable.clearSelection();
        this.multipleSelection = [];
        this.startrow = {};
        this.btnchId = "";
      });
    },
    /**
     *
     * @param {* 关闭 指派执行人  弹窗} val
     */
    closeOperator(val) {
      this.btnchId = "";
    },
    updateClick(data) {
      console.log(data);
      this.showInnerDialogs = 5;
      this.formTermData = {
        name: data.name,
        upload: "",
        term_file_id: data.term_file_id,
        mode: "0",
      };
      this.innerVisibleTitle = this.$t("ProjectDetails.UploadUpdateglossary");
      this.innerVisible = true;
    },
    /**
     *
     * @param {* 生成波形} row
     */
    generate_waveform(row) {
      // console.log(row.job_id);
      showLoading(this.$t("CommonName.loadingmessage"));
      this.$messages("warning", this.$t("ProjectDetails.GenerateWaveform"));
      return rebuild_generate_waveform({
        action: "rebuild_generate_waveform",
        job_id: row.job_id,
      }).then((res) => {
        hideLoading();
        if (!res) {
          return;
        }
        this.$messages("success", this.$t("ProjectDetails.GenerateWaveform1"));
        this.get_team_job_list();
      });
    },
    /**
     * 查询正在转写任务的状态
     */
    getTranscribingJobs(ids) {
      if (ids.length == 0) {
        return false;
      }
      return get_team_job_list({
        action: "get_job_list_status",
        job_ids: ids,
      }).then((res) => {
        if (res) {
          clearTimeout(this.timer);
          if (res.mode == 1) {
            //还有任务在转写中
            let that = this;
            let job_ids = [];
            res.list.forEach((i) => {
              for (let index = 0; index < that.tableData.length; index++) {
                if (that.tableData[index].job_id == i.job_id) {
                  if (i.status == "10") {
                    job_ids.push(i.job_id);
                    that.tableData[index].newstatus.value =
                      " " + i.task_progress + "%";
                  } else {
                    that.tableData[index].status = i.status;
                    that.tableData[index].newstatus = {
                      name: this.$t("CommonName.statusName1"), //转写
                      class: "blueClass",
                    };
                  }
                  break;
                }
              }
            });
            if (job_ids.length > 0) {
              // clearTimeout(this.timer);
              this.timer = setTimeout(() => {
                this.getTranscribingJobs(job_ids);
              }, 30000);
            }
          } else if (res.mode == 2) {
            //全部转写完成
            this.timer = setTimeout(() => {
              this.get_team_job_list();
            }, 5000);
          }
        }
      });
    },
    closeinnerDialog() {
      this.dialogReNameVisible = false;
    },
    dialogReNameMethod() {
      if (this.radio2 == "0") {
        // if (this.replaceinput3 == "") {
        //   return;
        // }
      } else if (this.radio2 == "1") {
        if (this.replaceinput1 == "") {
          this.$messages("warning", this.$t("ProjectDetails.labelText3"));
          return;
        }
      }
      let job_ids = [];
      this.multipleSelection.forEach((item) => {
        job_ids.push(item.job_id);
      });
      reject_job({
        action: "change_job_name_batch",
        job_ids: job_ids,
        mode: this.radio2 == "1" ? 2 : 1,
        str1: this.replaceinput1,
        str2: this.radio2 == "1" ? this.replaceinput2 : this.replaceinput3,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.dialogReNameVisible = false;
        this.get_team_job_list().then(() => {
          this.$refs.multipleTable.clearSelection();
          this.multipleSelection = [];
          this.startrow = {};
        });
      });
    },
    downloadSelectedSure() {
      // this.multipleSelection
      if (this.checkboxDownload.length === 0) {
        return false;
      }
      let job_ids = [];
      this.multipleSelection.forEach((item) => {
        job_ids.push(item.job_id);
      });
      job_ids = job_ids.join(",");
      let checkbox = this.checkboxDownload.join(",");
      this.download_all_job_zip_file(checkbox, job_ids);
      this.innerDownloadVisible = false;
    },
    ExportSelectedSure() {
      // this.multipleSelection
      if (this.checkboxExport.length === 0) {
        return false;
      }
      let job_ids = [];
      this.multipleSelection.forEach((item) => {
        job_ids.push(item.job_id);
      });
      job_ids = job_ids.join(",");
      let checkbox = this.checkboxExport.join(",");
      let commentsSelect = this.commentsSelect.join(",");
      this.export_excel_task_method(checkbox, job_ids, commentsSelect);
    },
    CanceldownloadClick() {
      this.innerDownloadVisible = false;
      this.innerExportVisible = false;
    },
    jobNameClick() {},
    /**
     * 清除GPT翻译
     * @param id
     */
    clean_up_gpt_jobs(id) {
      let job_ids = [];
      this.multipleSelection.forEach((item) => {
        job_ids.push(item.job_id);
      });
      //  console.log(job_ids);
      run_gpt_jobs({
        action: "clean_up_gpt_jobs",
        project_id: id,
        job_list: job_ids,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.get_team_job_list().then(() => {
          // this.$refs.multipleTable.clearSelection();
          // this.multipleSelection = [];
          // this.startrow = {};
        });
      });
    },
    /**
     *批量打回
     * @param id
     */
    reject_job() {
      let job_ids = [];
      this.multipleSelection.forEach((item) => {
        job_ids.push(item.job_id);
      });
      reject_job({
        action: "reject_job",
        job_id: job_ids.join(","),
      }).then((res) => {
        if (!res) {
          return;
        }
        this.get_team_job_list().then(() => {
          // this.$refs.multipleTable.clearSelection();
          // this.multipleSelection = [];
          // this.startrow = {};
        });
      });
    },
    /**
     * 执行MT翻译
     * @param id
     */
    run_mt_jobs(id) {
      let job_ids = [];
      this.multipleSelection.forEach((item) => {
        job_ids.push(item.job_id);
      });
      run_mt_jobs({
        action: "run_mt_jobs",
        project_id: id,
        job_list: job_ids,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.get_team_job_list().then(() => {
          // this.$refs.multipleTable.clearSelection();
          // this.multipleSelection = [];
          // this.startrow = {};
        });
      });
    },
    /**
     * 暂停GPT翻译
     * @param id
     */
    stop_gpt_jobs(id) {
      let job_ids = [];
      this.multipleSelection.forEach((item) => {
        job_ids.push(item.job_id);
      });
      //  console.log(job_ids);
      run_gpt_jobs({
        action: "stop_gpt_jobs",
        project_id: id,
        job_list: job_ids,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.get_team_job_list().then(() => {
          // this.$refs.multipleTable.clearSelection();
          // this.multipleSelection = [];
          // this.startrow = {};
        });
      });
    },
    /**
     * 点击下载 AIT模板
     */
    DownloadAITExcel() {
      window.location.href = "/resourcefile/Excel_AIT_Template.xlsx";
    },
    /**
     *
     * @param {获取任务操作日志} data
     */
    get_job_view_log() {
      return get_job_view_log({
        action: "get_job_view_log",
        job_id: this.rowData.job_id,
      }).then((res) => {
        if (!res) {
          return;
        }
        return res.list;
      });
    },
    job_view_log_revert_method(job_log_id) {
      this.$confirm(
        this.$t("CommonName.revertMessage"),
        this.$t("CommonName.deleteMessageTitle"),
        {
          confirmButtonText: this.$t("CommonName.deleteMessagebutton"),
          cancelButtonText: this.$t("CommonName.deleteMessageClosebutton"),
          closeOnClickModal: false,
          cancelButtonClass: "closeButton",
          // confirmButtonClass: "confirmButton",
        }
      ).then(() => {
        showLoading(this.$t("CommonName.loadingmessage"));
        this.job_view_log_revert(job_log_id).then((res) => {
          this.dialogVisible = false;
          this.innerVisible = false;
          this.get_team_job_list();
        });
      });
    },
    /**
     *
     * @param {根据任务操作日志还原数据} data
     */
    job_view_log_revert(job_log_id) {
      return job_view_log_revert({
        action: "job_view_log_revert",
        job_id: this.rowData.job_id,
        job_log_id: job_log_id,
      }).then((res) => {
        if (!res) {
          return;
        }
        return res.result;
      });
    },
    moveToFile() {
      set_job_user_batch({
        action: "get_move_project_list",
        project_id: this.project_id,
      }).then((res) => {
        if (!res) {
          return;
        }
        res.list.forEach((item) => {
          this.moveFolderList.push({
            id: item.project_id,
            label: item.project_name,
          });
        });
        this.dialogMoveVisible = true;
      });
    },
    determineMoveClick() {
      let job_ids = [];
      if (this.move_project_id == "0") {
        return;
      }
      if (this.multipleSelection.length > 0) {
        this.multipleSelection.forEach((item) => {
          job_ids.push(item.job_id);
        });
        set_job_user_batch({
          action: "move_job",
          project_id: this.move_project_id,
          job_ids,
        }).then((res) => {
          if (!res) {
            return;
          }
          this.$messages("success", this.$t("ProjectManagement.JobMoveTip"));
          this.dialogMoveVisible = false;
          this.get_team_job_list().then(() => {});
        });
      }
    },
    changeDialogMoveVisible(val) {
      this.dialogMoveVisible = false;
    },
    changedialogImportVisible(val) {
      this.dialogImportVisible = false;
    },
    handleNodeClick(val) {
      this.move_project_id = val.id;
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    /**
     * 拆分任务执行AIT
     * @param row
     */
    runSplitAITJob(row) {
      // console.log(row);
      showLoading(this.$t("CommonName.loadingmessage"));
      reject_split_job({
        action: "run_split_gpt_job",
        project_id: this.project_id,
        job_split_id: row.job_split_id,
      }).then((res) => {
        if (!res) {
          console.log(row);
          hideLoading();
          return;
        }
        let obj = this.tableData.find((item) => item.job_id === row.job_id);
        let activeName = obj.activeName;
        this.get_team_job_list().then((res) => {
          let obj1 = this.tableData.find((item) => item.job_id === row.job_id);
          obj1.activeName = activeName;
          obj1.tableData = obj1.split_list.filter(
            (i) => i.workflow === obj1.activeName
          );
        });
      });
    },
    /**
     * 拆分任务 clean up AIT
     * @param row
     */
    CleanSplitAITJob(row) {
      // console.log(row);
      showLoading(this.$t("CommonName.loadingmessage"));
      reject_split_job({
        action: "clean_up_gpt_split_job",
        project_id: this.project_id,
        job_split_id: row.job_split_id,
      }).then((res) => {
        if (!res) {
          console.log(row);
          hideLoading();
          return;
        }
        let obj = this.tableData.find((item) => item.job_id === row.job_id);
        let activeName = obj.activeName;
        this.get_team_job_list().then((res) => {
          let obj1 = this.tableData.find((item) => item.job_id === row.job_id);
          obj1.activeName = activeName;
          obj1.tableData = obj1.split_list.filter(
            (i) => i.workflow === obj1.activeName
          );
        });
      });
    },
    closeDialogDownload() {
      this.dialogDownloadVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/dialogStyle.scss";

.margintop0 {
  margin-top: 0 !important;
}
.iconp_input {
  ::v-deep .el-input__inner {
    width: auto !important;
    margin: 0 5px 8px 5px !important;
  }
}
.content_center {
  margin: 24px 24px 24px 24px;
  .content_center_internalRowData_div {
    width: 100%;
    min-height: 160px;
    background-color: #3d3f50;
    border: 1px solid #515464;

    .top {
      height: 30px;
      line-height: 30px;
      border-bottom: 1px solid #515464;
      text-align: center;
      color: #fff;
    }
    .last_div {
      width: 100%;
      box-sizing: border-box;
      padding: 0 24px;
      height: 50px;
      .title_p {
        color: #adb0bc;
        font-size: 12px;
        margin-bottom: 4px;
      }
      .TaskURL_div {
        display: flex;
        a {
          font-size: 10px;
          color: #53b3ff;
          text-decoration: none;
        }
        .icon {
          margin-left: 6px;
          font-size: 16px;
          color: #53b3ff;
          cursor: pointer;
        }
      }
    }
    .last_div + .last_div {
      margin-top: 8px;
    }
    .btm {
      display: flex;
      justify-content: space-between;
      height: 70px;
      width: 100%;
      align-items: center;

      .btm_left {
        .title_p {
          color: #adb0bc;
          font-size: 12px;
          //margin-bottom: 4px;
        }

        .btmp {
          color: #ffffff;
          margin: 0;
          margin-top: 4px;
          font-size: 12px;
          padding-right: 3px;
        }
        width: 20%;
        padding-left: 24px;
        box-sizing: border-box;
        .iconp {
          display: flex;
          align-items: center;
          .icon {
            font-size: 16px;
            margin-left: 6px;
            color: #adb0bc;
            cursor: pointer;
          }

          .el-dropdown {
            width: 16px !important;
          }
        }
        .nickname_div {
          display: flex;
          align-items: center;

          ::v-deep .el-button {
            padding: 0 !important;
            margin-top: 4px;
          }

          .close_i {
            color: #c0c4cc;
          }
        }
      }

      .btm_center {
        width: 20%;
        box-sizing: border-box;
        .title_p {
          color: #adb0bc;
          font-size: 12px;
          margin-bottom: 4px;
        }
        .btmp {
          color: #ffffff;
          margin: 0;
          margin-top: 4px;
          font-size: 12px;
          padding-right: 3px;
        }
      }
      .btm_right {
        .title_p {
          color: #adb0bc;
          font-size: 12px;
          margin-bottom: 4px;
        }
        width: 60%;
        box-sizing: border-box;
        .TaskURL_div {
          display: flex;

          a {
            font-size: 10px;
            color: #53b3ff;
            text-decoration: none;
          }
          .icon {
            margin-left: 6px;
            font-size: 16px;
            color: #53b3ff;
            cursor: pointer;
          }
        }
      }
    }
  }
  .content_center_div {
    p {
      color: #adb0bc;
      font-size: 14px;
      margin-bottom: 6px;
    }
  }

  .content_center_2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .selectclass {
      ::v-deep .el-input__inner {
        width: 476px;
      }
    }
  }

  .content_center_4 {
    ::v-deep .el-form--label-top .el-form-item__label {
      padding: 0;
    }

    ::v-deep .el-form-item {
      margin-bottom: 0;
    }

    ::v-deep .el-form-item__label {
      width: 100%;
      color: #adb0bc;
      font-size: 14px;
      line-height: normal;
      margin-top: 16px;
      margin-bottom: 6px;
    }

    .uploadClass {
      display: flex;
      justify-content: space-between;

      .el-button {
        padding: 0;
      }
    }

    .selectClassp {
      display: flex;
      width: 100%;

      p {
        width: 50%;
      }
    }

    .selectClass1 {
      margin-left: 40px;
    }

    .xlsxinput {
      ::v-deep .el-input__inner {
        width: 755px;
      }

      ::v-deep .el-input-group__append,
      .el-input-group__prepend {
        padding: 0;
        width: 84px;
        text-align: center;
        background: none;
        border-left: none;
        border-color: #7d7f8b;
      }
    }

    ::v-deep .el-input__icon {
      line-height: normal;
    }

    ::v-deep .el-select .el-input__inner {
      width: 400px;
    }

    ::v-deep .el-input__suffix {
      line-height: 35px;
    }

    .rename-input {
      ::v-deep .el-input__inner {
        width: 100%;
      }
    }
    .input1 {
      ::v-deep .el-input__inner {
        width: 840px;
      }
      ::v-deep .el-input__suffix {
        line-height: 32px;
      }
    }
    .textAreaClassHeight {
      ::v-deep .el-textarea__inner {
        height: 80px;
        border: 1px solid !important;
        border-color: #7d7f8b !important;
        padding: 5px 15px !important;

        &:focus {
          background: none !important;
          color: #ffffff !important;
        }
      }
    }
    .loockUpandReplaceDiv {
      height: 16px;
      color: #ef7373;
      font-size: 12px;
      text-align: left;
    }
  }
}

.mainCenter {
  height: calc(100% - 38px);
  width: 100%;
  box-sizing: border-box;

  .mainCenterbtm {
    height: calc(100% - 76px);
    // ::v-deep .el-table th.el-table__cell > .cell {
    //   padding-left: 0 !important;
    // }
    // ::v-deep .el-table .cell {
    //   padding-left: 0 !important;
    // }
    .menuscopediv {
      cursor: pointer;
      color: #46adff;
    }

    ::v-deep .el-table__expanded-cell {
      background: #393c4e;
      padding: 0 !important;
    }
    ::v-deep .el-table__expand-icon {
      color: #fff !important;
    }
    //::v-deep .el-table__expand-icon > .el-icon {
    //  left: 87% !important;
    //}
    //::v-deep .expand_class {
    //  .cell {
    //    padding: 0 !important;
    //  }
    //}
    .expand_div {
      padding: 0;
      background: #393c4e;
      display: flex;

      .left_div {
        width: 30px;
        height: 100%;
      }

      .right_div {
        width: calc(100% - 30px);

        .right_div_top {
          width: 100%;

          ::v-deep .el-tabs__nav-wrap::after {
            background: #515464;
            height: 1px;
          }

          ::v-deep .el-tabs__nav {
            padding-left: 24px;
          }

          ::v-deep .el-tabs__active-bar {
            margin-left: 24px;
          }

          ::v-deep .el-tabs__nav-scroll {
            background: #454758;
            border-left: 1px solid #515464;
          }

          ::v-deep .el-tabs__header {
            margin: 0;
          }

          ::v-deep .el-tabs__item {
            color: #fff;
            height: 32px !important;
            line-height: 32px !important;

            &.is-active {
              color: #409eff;
            }
          }
        }

        ::v-deep .labClassName {
          border-right: none !important;
        }
      }
    }
  }
}

.mainHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 18px 10px 18px 24px;

  .mainHeader_left {
    display: flex;

    .signoutClass {
      width: 22px;
      height: 22px;
      border: 1px solid;
      border-color: #757784;
      border-radius: 2px;
      line-height: 22px;
      cursor: pointer;

      .icon {
        font-size: 16px;
      }
    }

    .titleClass {
      display: flex;
      align-items: center;
      margin-left: 24px;
      text-align: left;

      .overflowp {
        width: 350px;
      }

      div {
        width: 4px;
        height: 16px;
        background-color: #46adff;
        border-radius: 2px;
      }

      p {
        font-weight: 700;
        color: #ffffff;
        font-size: 16px;
        margin-left: 8px;
      }
    }

    .dropdowndiv {
      display: flex;
      align-items: center;
      margin-left: 40px;

      .dropdownbutton {
        p {
          color: #46adff;
          font-size: 14px;
        }
      }
    }
  }

  .mainHeader_right {
    display: flex;
    align-items: center;

    .lastdiv {
      width: 1px;
      height: 12px;
      background-color: #757784;
      margin-right: 14px;
    }

    // .lastbtn {
    //   margin-right: 10px;
    // }
    .el-button {
      .buttondiv {
        display: flex;
        align-items: center;
        margin-right: 14px;

        p {
          margin-left: 6px;
          font-size: 14px;
        }

        .icon {
          font-size: 16px;
        }
      }
    }
  }
}

.mainCenterbtm {
  // ::v-deep .el-table__body {
  // font-size: 12px !important;
  // }
  .setings {
    display: flex;
    align-items: center;
    height: 32px;

    .setClass {
      width: 36px;
      height: 22px;
      border-radius: 2px;
      text-align: center;
      margin-right: 8px;

      p {
        color: #ffffff;
        font-size: 14px;
      }
    }
    ::v-deep .el-badge__content.is-fixed.is-dot {
      right: 14px !important;
    }
    ::v-deep .el-badge__content.is-fixed {
      top: 1px !important;
    }
    ::v-deep .el-badge__content {
      border-color: #393c4e !important;
      background-color: #c6e6ff !important ;
    }
  }

  .statusClass {
    display: flex;
    align-items: center;

    .statusClass1 {
      width: 36px;
      height: 22px;
      border-radius: 2px;
      text-align: center;
      margin-right: 8px;

      p {
        color: #ffffff;
        margin-left: 0 !important;
      }
    }

    p {
      color: #cbd7e8;
      margin-left: 2px;
    }
  }
}

.tabledropdowndiv {
  max-height: 252px;
  overflow-y: auto;
  overflow-x: hidden;

  ::v-deep .popper__arrow {
    left: 140px !important;
  }
}

.blueClass {
  background-color: #3b99e3;
}
.sqtClass {
  background-color: #b888c4;
}
.greenClass {
  background-color: #41b293;
}

.greenClass_other {
  background-color: #94be6f;
}

.yellowClass {
  background-color: #d48d47;
}

.blueClass1 {
  border: 1px solid #3b99e3;
  line-height: 22px;
}
.sqtClass1 {
  border: 1px solid #b888c4;
  line-height: 22px;
}
.greenClass1 {
  border: 1px solid #41b293;
  line-height: 22px;
}

.greenClass_other1 {
  border: 1px solid #94be6f;
  line-height: 22px;
}

.yellowClass1 {
  border: 1px solid #d48d47;
  line-height: 22px;
}

.blueClass2 {
  background-color: #3b99e3;
}
.sqtClass2 {
  background-color: #b888c4;
}
.greenClass2 {
  background-color: #41b293;
}

.greenClass_other2 {
  background-color: #94be6f;
}

.yellowClass2 {
  background-color: #d48d47;
}

.slottitle {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    margin-left: 24px;
  }

  .el-button {
    margin-right: 75px;
  }
}

.tasknameslotTitle {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title_p {
    width: 240px;
    text-align: left;
  }

  p {
    margin-left: 24px;
  }

  .tasktitlebtn {
    display: flex;
    align-items: center;
    margin-right: 40px;

    .svgbtn {
      margin-right: 14px;
    }
  }

  .lastdiv {
    width: 1px;
    height: 12px;
    background-color: #757784;
    margin: 0 24px 0 10px;
  }
}

::v-deep .el-table--border,
.el-table--group {
  border-color: #515464;
}

::v-deep .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-color: #515464;
}

::v-deep .el-table--border .el-table__cell,
.el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  border-right-color: #515464 !important;
}

::v-deep .el-table__header colgroup col[name="gutter"] {
  display: table-cell !important;
}

.popoverDiv {
  ::v-deep .el-input__inner {
    width: auto;
  }
}

.tableheaderdiv {
  .fontSize12 {
    font-size: 14px;
  }
}

.radioClass50 {
  .el-radio-group {
    width: 100%;
  }

  .el-radio {
    width: 51%;
    margin-top: 8px;
  }
}

.content_center {
  .content_center_3 {
    .content_center_div {
      p {
        color: #adb0bc;
        font-size: 14px;
        margin-bottom: 6px;
      }

      .checkboxClass {
        margin-top: 0px;

        .el-button + .el-button,
        .el-checkbox.is-bordered + .el-checkbox.is-bordered {
          margin-left: 0px;
        }
      }

      .checkboxClass .el-checkbox.is-bordered {
        width: 476px !important;
        margin-bottom: 6px;
      }
      .selectclass100 {
        width: 100%;
      }
      .content_center_div1 {
        ::v-deep .el-input__inner {
          width: 100%;
        }
      }
    }
    .content_center_div + .content_center_div {
      margin-top: 12px;
    }
  }
}

.operationClass {
  cursor: pointer;
}
.tabledropdowndiv {
  max-height: 210px;
  overflow-y: auto;
}
.buttonList_class + .buttonList_class {
  margin-left: 10px;
}
.setUserSelect {
  user-select: none;
}
::v-deep .el-table__body tr.current-row > td.el-table__cell {
  background: rgba(61, 122, 176, 0);
}
::v-deep .el-table .success-row {
  background: rgba(61, 122, 176, 0.5) !important;
  // position: sticky;
  td {
    color: #ffffff !important;
  }

  &:hover {
    td {
      background-color: #3d7ab0 !important;
    }
  }
}
.moveProjectSearch {
  margin: 24px;
  ::v-deep .el-input__inner {
    width: 100%;
  }
}
.moveFolder {
  margin: 24px;
  height: 300px;
  overflow: auto;
  border: 1px solid #7d7f8b;
  border-radius: 2px;
  padding: 16px;

  .allProject {
    display: flex;
    height: 27px;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: #484a5a;
    }

    .svgiconclass1 {
      color: #cbd7e8;
      width: 18px !important;
      height: 21px !important;
      margin-right: 5px;
    }

    p {
      color: #cbd7e8;
      margin-bottom: 0px;
    }
  }

  .allProject.active {
    background-color: #46adff; //背景色
  }

  p {
    color: #adb0bc;
    font-size: 14px;
    margin-bottom: 6px;
  }

  ::v-deep .el-tree {
    background-color: #393c4e;
    .is-current {
      background-color: #46adff; //背景色
    }
    .el-tree-node:focus > .el-tree-node__content {
      background-color: #46adff; //背景色
    }

    .el-tree-node__content {
      &:hover {
        background: #484a5a;
      }
    }
  }

  .custom-tree-node {
    display: flex;
    align-items: center;
  }

  .folderp {
    color: #cbd7e8;
    font-size: 14px;
    white-space: pre;
  }

  .svgiconclass {
    color: #f5df86;
    width: 18px !important;
    height: 21px !important;
    margin-right: 5px;
  }
}
</style>
