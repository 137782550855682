<!--
 * @Description: 指派/更改任务处理人
 * @version: 
 * @Author: Carful
 * @Date: 2022-11-14 15:51:40
 * @LastEditors: Carful
 * @LastEditTime: 2023-06-28 16:52:05
-->
<template>
  <div class="center">
    <div v-if="['1', '3', '5', '6', '7'].includes(this.jobType)">
      <!-- 转写任务处理人 -->
      <p>{{ $t("ExternalCreateTask.Taskprocessor") }}</p>
      <el-select
        v-model="form.select1"
        :placeholder="$t('CommonName.Pleaseselect')"
        class="selectclass selectClass1"
        @change="select1Change"
        clearable
        filterable
      >
        <!-- <el-option label=" " value="0"></el-option> -->
        <el-option
          v-for="item in selectOptions"
          :key="item.member_id"
          :label="item.nickname"
          :value="item.member_id"
        >
        </el-option>
      </el-select>
    </div>
    <div v-if="['6', '7'].includes(this.jobType)">
      <!-- SQT任务处理人 -->
      <p>{{ $t("ExternalCreateTask.STQprocessor") }}</p>
      <el-select
        v-model="form.select5"
        :placeholder="$t('CommonName.Pleaseselect')"
        class="selectclass selectClass1"
        @change="select5Change"
        clearable
        filterable
      >
        <!-- <el-option label=" " value="0"></el-option> -->
        <el-option
          v-for="item in selectOptions"
          :key="item.member_id"
          :label="item.nickname"
          :value="item.member_id"
        >
        </el-option>
      </el-select>
    </div>
    <div v-if="['2', '3', '4', '5', '7'].includes(this.jobType)">
      <!-- 翻译任务处理人 -->
      <p>{{ $t("ExternalCreateTask.Translator") }}</p>
      <el-select
        v-model="form.select2"
        :placeholder="$t('CommonName.Pleaseselect')"
        class="selectclass selectClass1"
        @change="select2Change"
        clearable
        filterable
      >
        <!-- <el-option label=" " value="0"></el-option> -->
        <el-option
          v-for="item in selectOptions"
          :key="item.member_id"
          :label="item.nickname"
          :value="item.member_id"
        >
        </el-option>
      </el-select>
    </div>
    <div v-if="['4', '5', '7'].includes(this.jobType)">
      <!-- 校对任务处理人 -->
      <p>{{ $t("ExternalCreateTask.ProofreadTaskHandler") }}</p>
      <el-select
        v-model="form.select3"
        :placeholder="$t('CommonName.Pleaseselect')"
        class="selectclass selectClass1"
        @change="select3Change"
        clearable
        filterable
      >
        <!-- <el-option label=" " value="0"></el-option> -->
        <el-option
          v-for="item in selectOptions"
          :key="item.member_id"
          :label="item.nickname"
          :value="item.member_id"
        >
        </el-option>
      </el-select>
    </div>
  </div>
</template>
<script>
import { get_job_member, get_member_list } from "@/utils/https";
export default {
  props: {
    jobid: {
      type: String,
      default: "",
    },
    jobType: {
      type: String,
      default: "",
    },
    projectid: {
      type: String,
      default: "",
    },
    isProject: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        select1: "",
        select1_id: "",
        select2: "",
        select2_id: "",
        select3: "",
        select3_id: "",
        select5: "",
        select5_id: "",
      },
      selectOptions: [],
    };
  },
  mounted() {
    if (this.isProject) {
      this.get_project_member(this.projectid);
    } else {
      this.get_job_member(this.jobid);
    }
    this.get_member_list();
  },
  created() {},
  methods: {
    select1Change(select) {
      this.form.select1_id = select;
    },
    select2Change(select) {
      this.form.select2_id = select;
    },
    select3Change(select) {
      this.form.select3_id = select;
    },
    select5Change(select) {
      this.form.select5_id = select;
    },
    /**
     *
     * @param {*获取成员列表信息} data
     */
    get_member_list(data = 2) {
      get_member_list({
        action: "get_member_list",
        team_user_status: data,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.selectOptions = res.list;
      });
    },
    /**
     *
     * @param {* 获取 任务 转写人 信息} id
     */
    get_job_member(id) {
      get_job_member({
        action: "get_job_member",
        job_id: id,
      }).then((res) => {
        if (!res) {
          return;
        }
        let arr = res.list;
        arr.forEach((i) => {
          if (i.type == "1") {
            this.form.select1 = i.nickname;
            this.form.select1_id = i.user_id;
          } else if (i.type == "2") {
            this.form.select2 = i.nickname;
            this.form.select2_id = i.user_id;
          } else if (i.type == "5") {
            this.form.select5 = i.nickname;
            this.form.select5_id = i.user_id;
          } else {
            this.form.select3 = i.nickname;
            this.form.select3_id = i.user_id;
          }
        });
        // console.log(arr);
      });
    },
    /**
     *
     * @param {* 获取 任务 转写人 信息} id
     */
    get_project_member(id) {
      get_job_member({
        action: "get_project_member",
        project_id: id,
      }).then((res) => {
        if (!res) {
          return;
        }
        let arr = res.list;
        arr.forEach((i) => {
          if (i.type == "1") {
            this.form.select1 = i.nickname;
            this.form.select1_id = i.user_id;
          } else if (i.type == "2") {
            this.form.select2 = i.nickname;
            this.form.select2_id = i.user_id;
          } else if (i.type == "5") {
            this.form.select5 = i.nickname;
            this.form.select5_id = i.user_id;
          } else {
            this.form.select3 = i.nickname;
            this.form.select3_id = i.user_id;
          }
        });
        console.log(this.form);

        // console.log(arr);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 0 36px 0;
  p {
    color: #adb0bc;
    font-size: 14px;
    margin: 16px 0 6px 0;
  }
  .el-input__icon {
    line-height: 32px;
  }
}
</style>
