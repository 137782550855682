<!--
 * @Description: 术语库管理
 * @version: 
 * @Author: Carful
 * @Date: 2022-11-07 10:11:06
 * @LastEditors: Carful
 * @LastEditTime: 2023-02-24 11:34:25
-->
<template>
  <div class="mainCenter">
    <div class="mainCenterTop titleHeader">
      <div class="mainHeader_left">
        <div class="signoutClass" v-if="folder_pid != 0" @click="backClick">
          <svg-icon icon-class="Aright" class="icon"></svg-icon>
        </div>
        <div class="titleClass">
          <div class="line1"></div>
          <el-breadcrumb separator="/" class="breadcrumb1">
            <el-breadcrumb-item :class="0 == folder_pid ? 'current' : ''">
              <template v-if="0 != folder_pid">
                <a @click="breadcrumbClick(0)">{{
                  $t("TermBaseManagement.title")
                }}</a>
              </template>
              <template v-else>{{ $t("TermBaseManagement.title") }} </template>
            </el-breadcrumb-item>
            <el-breadcrumb-item
              v-for="item in Breadcrumb"
              :key="item.id"
              :class="parseInt(item.id) == folder_pid ? 'current' : ''"
            >
              <template v-if="parseInt(item.id) != folder_pid">
                <a @click="breadcrumbClick(item.id)">{{ item.label }}</a>
              </template>
              <template v-else>{{ item.label }}</template>
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
      <div class="titleOption">
        <!--        创建文件夹-->
        <el-button type="text" @click="createFolder">
          <div class="svgbtn">
            <svg-icon icon-class="NewFolder" class="icon"></svg-icon>
            <p>{{ $t("ProjectManagement.CreateFolder") }}</p>
          </div>
        </el-button>
        <el-button type="text" @click="addNewmember">
          <div class="svgbtn">
            <!-- headerbtn2 -->
            <svg-icon icon-class="create" class="icon"></svg-icon>
            <p>{{ $t("TermBaseManagement.NewGlossary") }}</p>
          </div>
        </el-button>
        <!--        移动-->
        <el-button type="text" @click="moveToFile">
          <div class="svgbtn">
            <svg-icon icon-class="ShiftIn" class="icon"></svg-icon>
            <p>{{ $t("ProjectManagement.MoveToFile") }}</p>
          </div>
        </el-button>
      </div>
    </div>
    <!-- 筛选 -->
    <div class="contentCenter_tags screenStyle">
      <p>{{ $t("CommonName.screen") }}</p>
      <el-tag
        v-for="tag in tags"
        :key="tag.name"
        closable
        :type="tag.type"
        @close="handleClose(tag)"
      >
        <p
          class="screenp"
          :id="'tag' + tag.id"
          @mouseover="onShowNameTipsMouseenter(tag)"
        >
          <el-tooltip
            class="item"
            effect="dark"
            :content="tag.name"
            placement="top-start"
            :disabled="!tag.showdropdown"
          >
            <span>{{ tag.name }}</span>
          </el-tooltip>
        </p>
      </el-tag>
    </div>
    <div class="mainCenterbtm tableClass">
      <el-table
        :row-style="{
          height: 48 + 'px',
          background: '#393c4e',
          color: '#cbd7e8',
        }"
        :cell-style="{ padding: 0 + 'px' }"
        :header-cell-style="{
          height: 48 + 'px',
          padding: '0px',
          color: '#adb0bc',
          background: '#393c4e',
        }"
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        :max-height="tableHeight"
        row-key="id"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="65"
          align="center"
          :selectable="selectable"
          :reserve-selection="true"
        ></el-table-column>
        <!-- TM Type -->
        <el-table-column :min-width="100" prop="TMName" show-overflow-tooltip>
          <template slot="header">
            <!-- TM 名称 -->
            <div class="tableheaderdiv">
              <p class="namep">{{ $t("TermBaseManagement.GlossaryName") }}</p>
              <div class="icondiv">
                <el-popover
                  placement="bottom-end"
                  width="278"
                  v-model="visible"
                  :visible-arrow="false"
                >
                  <div class="popoverDiv">
                    <el-input
                      v-model="popoverInput"
                      :placeholder="$t('CommonName.search')"
                      maxlength="100"
                      @keyup.enter.native="searchdetermineClick"
                    ></el-input>
                    <el-button
                      type="primary"
                      class="setpadding16"
                      @click="searchdetermineClick"
                      >{{ $t("CommonName.determine") }}
                    </el-button>
                  </div>
                  <svg-icon
                    icon-class="Filter"
                    class="iconsize"
                    slot="reference"
                  ></svg-icon>
                </el-popover>

                <div class="tableheader"></div>
              </div>
            </div>
          </template>
          <template slot-scope="scope">
            <div
              class="menuscopediv overflowEllipsis"
              style="cursor: pointer"
              @click="tovideoDetails(scope.row)"
            >
              <div style="display: flex; width: 100%">
                <svg-icon
                  :icon-class="scope.row.is_folder == 1 ? 'Folder' : 'project2'"
                  class="icon"
                  :class="
                    scope.row.is_folder == 1 ? 'svgiconclass' : 'svgiconclass1'
                  "
                ></svg-icon>
                <p
                  style="width: calc(100% - 23px)"
                  class="overflowEllipsis"
                  :class="scope.row.is_folder == 1 ? 'folderp' : 'filep'"
                  v-text="scope.row.name"
                ></p>
              </div>
            </div>
          </template>
        </el-table-column>
        <!-- 术语库数 -->
        <el-table-column prop="file_count" width="150">
          <template slot="header">
            <div class="tableheaderdiv">
              <p>{{ $t("CommonName.GlossaryCount") }}</p>
              <div class="icondiv">
                <div class="tableheader"></div>
              </div>
            </div>
          </template>
        </el-table-column>
        <!-- 术语类型 -->
        <el-table-column width="200" prop="type_name">
          <template slot="header">
            <div class="tableheaderdiv">
              <p>{{ $t("TermBaseManagement.termType") }}</p>
              <div class="icondiv">
                <el-dropdown
                  @command="termTypeCommand"
                  trigger="click"
                  placement="bottom-end"
                >
                  <span class="el-dropdown-link">
                    <svg-icon icon-class="Filter" class="iconsize"></svg-icon>
                  </span>
                  <el-dropdown-menu
                    slot="dropdown"
                    class="tabledropdowndiv noshowtriangle"
                  >
                    <el-dropdown-item
                      v-for="item in termTypeList"
                      :key="item.id"
                      :command="{ name: item.name, value: item.id }"
                    >
                      <span>{{ item.name }}</span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <div class="tableheader"></div>
              </div>
            </div>
          </template>
        </el-table-column>
        <!-- 源语言 -->
        <el-table-column width="200" prop="slang_name">
          <template slot="header">
            <div class="tableheaderdiv">
              <p>{{ $t("CommonName.sourcelanguagetable") }}</p>
              <div class="icondiv">
                <el-dropdown
                  @command="sourcelanguagecommand"
                  trigger="click"
                  placement="bottom-end"
                >
                  <span class="el-dropdown-link">
                    <svg-icon icon-class="Filter" class="iconsize"></svg-icon>
                  </span>
                  <el-dropdown-menu
                    slot="dropdown"
                    class="tabledropdowndiv noshowtriangle"
                  >
                    <el-dropdown-item
                      v-for="item in selectList"
                      :key="item.language_id"
                      :command="{ name: item.ename, value: item.language_id }"
                    >
                      <span>{{ item.ename }}</span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <div class="tableheader"></div>
              </div>
            </div>
          </template>
        </el-table-column>
        <!-- 句段数 -->
        <el-table-column prop="number" width="150">
          <template slot="header">
            <div class="tableheaderdiv">
              <p>{{ $t("CommonName.Numberofsentencesegments") }}</p>
              <div class="icondiv">
                <div class="tableheader"></div>
              </div>
            </div>
          </template>
        </el-table-column>
        <!-- 备注 -->
        <el-table-column prop="comments" show-overflow-tooltip>
          <template slot="header">
            <div class="tableheaderdiv">
              <p>{{ $t("CommonName.remarks") }}</p>
              <div class="icondiv">
                <div class="tableheader"></div>
              </div>
            </div>
          </template>
        </el-table-column>
        <!-- 操作 -->
        <el-table-column width="100" label="">
          <template slot-scope="scope">
            <div class="operationStyle">
              <el-dropdown @command="operationcommandClick">
                <span class="el-dropdown-link">
                  <svg-icon
                    icon-class="MoreOperations"
                    style="color: #cbd7e8"
                  ></svg-icon>
                </span>
                <el-dropdown-menu slot="dropdown" class="dropdowndiv">
                  <el-dropdown-item
                    v-for="item in scope.row.is_folder == 1
                      ? dropdownList1
                      : dropdownList"
                    :key="item.command"
                    :command="{ type: item.command, id: scope.row }"
                  >
                    <svg-icon
                      :icon-class="item.icon"
                      style="margin-right: 8px; font-size: 18px"
                    ></svg-icon>
                    <span>{{ item.name }}</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
        <div slot="empty" class="noDataDiv">
          <svg-icon icon-class="NoData" class="noDataicon"></svg-icon>
          <p>{{ $t("CommonName.noData") }}</p>
        </div>
      </el-table>
      <div
        style="margin-top: 20px; float: right; margin-right: 20px"
        v-if="tableData.length > 0"
      >
        <PaginationComVue
          :tableData="[]"
          :pageSize="pageSize"
          :currentPage="currentPage"
          :total="tableTotal"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        ></PaginationComVue>
      </div>
      <DialogComVue
        :dialogVisible="dialogVisible"
        @determineClick="determineClick"
        @changeDialogVisible="changeDialogVisible"
        :footerbtnName="$t('CommonName.Sure')"
        :closefooterbtnName="$t('CommonName.Cancel')"
        :width="'888px'"
        :top="'10%'"
        :title="dialogtitle"
        v-if="dialogVisible"
      >
        <div class="dialogContent">
          <!-- 新建术语 -->
          <div v-if="dialogtitle == $t('TermBaseManagement.NewGlossary')">
            <el-form
              ref="form"
              :model="form"
              label-position="top"
              class="formStyleClass dialogformStyleClass"
              @keydown.enter.prevent
            >
              <el-form-item :label="$t('TermBaseManagement.GlossaryName')">
                <el-input
                  v-model="form.name"
                  placeholder=""
                  :maxlength="45"
                ></el-input>
              </el-form-item>
              <!-- 源语言 -->
              <div class="languageClass">
                <div>
                  <el-form-item :label="$t('CommonName.Sourcelanguage')">
                    <el-select
                      v-model="form.select1"
                      class="selectclass"
                      @change="changeselectLanguage1"
                      :placeholder="$t('TMManagement.select')"
                      filterable
                    >
                      <el-option
                        v-for="item in sourcelanguagedropdownList"
                        :key="item.language_id"
                        :label="item.cname"
                        :value="item.language_id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </div>
              <!-- 目标语言 -->
              <div class="languageClass">
                <div>
                  <el-form-item :label="$t('CommonName.Targetlanguage')">
                    <el-select
                      v-model="form.select2"
                      multiple
                      @change="changeselectLanguage2"
                      class="selectclass"
                      :placeholder="$t('TMManagement.select')"
                      filterable
                    >
                      <el-option
                        v-for="item in targetlanguagedropdownList"
                        :key="item.language_id"
                        :label="item.cname"
                        :value="item.language_id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </div>
              <!-- 术语类型 -->
              <div class="languageClass">
                <div>
                  <el-form-item :label="$t('TermBaseManagement.termType')">
                    <el-select
                      v-model="form.select3"
                      class="selectclass"
                      :placeholder="$t('TMManagement.select')"
                      filterable
                    >
                      <el-option
                        v-for="item in termTypeList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </div>
              <el-form-item>
                <div class="content">
                  <div class="uploadp" slot="label">
                    <p>{{ $t("TermBaseManagement.Uploadtermfile") }}</p>
                    <el-button type="text" @click="Download">
                      {{ $t("TermBaseManagement.DownloadGlossaryTemplate") }}
                    </el-button>
                  </div>
                  <el-input
                    v-model="form.uploadinput1"
                    class="input-with-select srtUnput"
                    @input="form.uploadinput1 = ''"
                  >
                    <el-upload
                      slot="append"
                      class="upload-demo"
                      ref="upload"
                      name="term_file"
                      :data="uploadData"
                      :action="$uploadURL + '/term/create_term_set'"
                      :on-change="uploadChange"
                      :show-file-list="false"
                      :on-success="uploadSuccess"
                      :on-progress="uploadProgress"
                      :auto-upload="false"
                      :with-credentials="true"
                      :limit="1"
                    >
                      <el-button type="text" @click="updateNewfile(1)">
                        <div class="uploadbtn">
                          <svg-icon icon-class="upload" class="icon"></svg-icon>
                          <!-- 上传 -->
                          <span>{{ $t("CommonName.Upload") }}</span>
                        </div>
                      </el-button>
                    </el-upload>
                  </el-input>
                </div>
              </el-form-item>
              <el-form-item :label="$t('CommonName.remarks')">
                <el-input
                  type="textarea"
                  class="textAreaClassHeight"
                  :maxlength="150"
                  v-model="form.desc"
                  resize="none"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
          <!-- 导入 Term -->
          <div v-if="dialogtitle == $t('TermBaseManagement.Importtermfile')">
            <div class="content">
              <div class="uploadp">
                <p>{{ $t("TermBaseManagement.Uploadtermfile") }}</p>
                <el-button type="text" @click="Download">
                  {{ $t("TermBaseManagement.DownloadGlossaryTemplate") }}
                </el-button>
              </div>
              <el-input
                v-model="form1.uploadinput1"
                class="input-with-select srtUnput"
                @input="form1.uploadinput1 = ''"
              >
                <el-upload
                  slot="append"
                  class="upload-demo"
                  ref="upload"
                  name="term_file"
                  :data="uploadData"
                  :action="$uploadURL + '/term/upload_set_term'"
                  :on-change="uploadChange"
                  :show-file-list="false"
                  :on-success="uploadSuccess"
                  :on-progress="uploadProgress"
                  :auto-upload="false"
                  :with-credentials="true"
                  :limit="1"
                >
                  <el-button type="text" @click="updateNewfile(2)">
                    <div class="uploadbtn">
                      <svg-icon icon-class="upload" class="icon"></svg-icon>
                      <!-- 上传 -->
                      <span class="uploadspan">{{
                        $t("CommonName.Upload")
                      }}</span>
                    </div>
                  </el-button>
                </el-upload>
              </el-input>
            </div>
          </div>
          <!-- 更新 Term -->
          <div v-if="showform == '3'">
            <el-form
              ref="form2"
              :model="form2"
              label-position="top"
              class="formStyleClass dialogformStyleClass"
              @keydown.enter.prevent
            >
              <el-form-item :label="$t('TermBaseManagement.GlossaryName')">
                <el-input
                  v-model="form2.name"
                  placeholder=""
                  :maxlength="45"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('CommonName.remarks')">
                <el-input
                  type="textarea"
                  class="textAreaClassHeight"
                  :maxlength="150"
                  v-model="form2.desc"
                  resize="none"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </DialogComVue>
    </div>
    <div
      class="progressdiv"
      v-if="percentage > 0"
      v-loading.fullscreen.lock="loading"
      element-loading-custom-class="disaplaynoneicon"
      element-loading-background="rgba(0, 0, 0, 0.3)"
    >
      <el-progress
        type="circle"
        :percentage="percentage"
        :stroke-width="10"
      ></el-progress>
    </div>
    <DialogComVue
      :dialogVisible="dialogFolderVisible"
      @determineClick="determineFolderClick"
      @changeDialogVisible="changeDialogFolderVisible"
      :footerbtnName="$t('CommonName.Sure')"
      :closefooterbtnName="$t('CommonName.Cancel')"
      :width="'888px'"
      :top="'10%'"
      :title="
        update_folder_id == 0
          ? $t('ProjectManagement.CreateFolder')
          : $t('ProjectManagement.RenameFolder')
      "
      v-if="dialogFolderVisible"
    >
      <div class="addFolder langselectclass">
        <p>{{ $t("ProjectManagement.FolderName") }}</p>
        <el-input
          v-model="FolderName"
          maxlength="100"
          :placeholder="$t('ProjectManagement.FolderNameTip')"
        ></el-input>
      </div>
    </DialogComVue>
    <DialogComVue
      :dialogVisible="dialogMoveVisible"
      @determineClick="determineMoveClick"
      @changeDialogVisible="changeDialogMoveVisible"
      :footerbtnName="$t('CommonName.OK')"
      :closefooterbtnName="$t('CommonName.Cancel')"
      :width="'650px'"
      :top="'5%'"
      :title="$t('ProjectManagement.MoveToFileTitle')"
      v-if="dialogMoveVisible"
    >
      <div class="moveFolder langselectclass">
        <div
          class="allProject"
          :class="selectAllProject ? 'active' : ''"
          @click="selectAllProject = true"
        >
          <svg-icon icon-class="project2" class="icon svgiconclass1"></svg-icon>
          <p>{{ $t("TermBaseManagement.title") }}</p>
        </div>
        <el-tree
          ref="tree"
          :data="moveFolderList"
          :props="defaultProps"
          @node-click="handleNodeClick"
          class="move_tree"
          empty-text=""
        >
          <span class="custom-tree-node" slot-scope="{ node }">
            <svg-icon
              :icon-class="node.expanded ? 'file_open' : 'Folder'"
              class="icon svgiconclass"
            ></svg-icon>
            <span class="folderp">{{ node.label }}</span>
            <!-- <el-button type="text" size="mini" @click="() => append(node)">
              Append
            </el-button> -->
          </span>
        </el-tree>
      </div>
    </DialogComVue>
  </div>
</template>
<script>
import {
  onShowNameTipsMouseenter,
  addAndreplaceTags,
} from "@/assets/js/Publicmethods";
import PaginationComVue from "@/components/PaginationCom.vue";
import DialogComVue from "@/components/DialogCom.vue";
import {
  get_termfile_list,
  get_language,
  delete_termfile,
  add_term_set,
} from "@/utils/https";
import { showLoading, hideLoading } from "@/utils/loading";

export default {
  components: {
    PaginationComVue,
    DialogComVue,
  },
  data() {
    return {
      loading: false,
      percentage: 0,
      showform: "1",
      uploadData: {},
      rowData: {},
      // 新建
      form: {
        name: "",
        uploadinput1: "",
        desc: "",
        select1: "",
        select2: [],
        select3: 0,
      },
      // 导入
      form1: {
        uploadinput1: "",
      },
      // 更新
      form2: {
        name: "",
        term_set_id: 0,
        desc: "",
      },
      tags: [], //筛选数组
      popoverInput: "", //筛选内部 弹出框 输入
      visible: false,
      dialogVisible: false,
      dialogtitle: "",
      dialogInput: "",
      selectList: [], //语言数组
      tableData: [],
      // 目标语言
      targetlanguagedropdownList: [],
      // 源语言
      sourcelanguagedropdownList: [],
      // TM Type
      TMTypedropdownList: [],
      dropdownList: [
        {
          command: "0",
          name: this.$t("CommonName.import"),
          icon: "Import",
        },
        {
          command: "1",
          name: this.$t("CommonName.export"),
          icon: "export",
        },
        {
          command: "2",
          name: this.$t("TermBaseManagement.Editglossaryinformation"),
          icon: "Edit",
        },
        {
          command: "3",
          name: this.$t("CommonName.delete"),
          icon: "delete",
        },
        // {
        //   command: "4",
        //   name: this.$t("CommonName.info"),
        //   icon: "info",
        // },
      ],
      dropdownList1: [
        {
          name: this.$t("ProjectManagement.dropdownList.name3"),
          icon: "Edit",
          command: "5", // 重命名
        },
        {
          name: this.$t("ProjectManagement.dropdownList.name4"),
          icon: "delete",
          command: "6", // 删除
        },
      ],
      pageSize: 20,
      currentPage: 1,
      tableHeight: 0,
      update_folder_id: 0,
      dialogFolderVisible: false,
      FolderName: "",
      moveFolderList: [],
      dialogMoveVisible: false,
      defaultProps: {
        children: "children",
        label: "label",
      },
      selectAllProject: false,
      move_folder_id: "0", //移动文件夹id
      tableTotal: 0,
      folder_pid: 0,
      Breadcrumb: [], //面包屑
      exportFolderList: [],
      multipleSelection: [],
      termTypeList: [
        {
          id: 0,
          name: this.$t("TermBaseManagement.termTypename"),
        },
        {
          id: 1,
          name: this.$t("TermBaseManagement.termTypename1"),
        },
      ],
    };
  },
  created() {
    this.folder_pid = this.$route.query.id ? this.$route.query.id : 0;
  },
  mounted() {
    hideLoading();
    showLoading(this.$t("CommonName.loadingmessage"));
    //将储存的搜索条件，赋值
    if (
      sessionStorage.getItem("menuIndex") != null &&
      sessionStorage.getItem("menuIndex") ==
        "/ContainerHome/ExTeam/TermBaseManagement"
    ) {
      if (
        sessionStorage.getItem("menuTags") != "" &&
        sessionStorage.getItem("menuTags") != null
      ) {
        let menuTags = JSON.parse(sessionStorage.getItem("menuTags"));
        if (menuTags.currentPage != undefined) {
          this.currentPage = menuTags.currentPage;
        }
        if (menuTags.pageSize != undefined) {
          this.pageSize = menuTags.pageSize;
        }
        if (menuTags.tags != undefined) {
          this.tags = menuTags.tags;
        }
      }
    }
    this.get_language().then((res) => {
      this.get_termfile_list();
    });

    this.$nextTick(() => {
      this.tableHeight = window.innerHeight - 300;
    });
    window.onresize = () => {
      this.tableHeight = window.innerHeight - 300;
    };
  },
  methods: {
    /**
     *
     * @param {*术语类型} command
     */
    termTypeCommand(command) {
      this.tags = addAndreplaceTags(
        this.$t("TermBaseManagement.termType"),
        command.name,
        this.tags,
        "term_type",
        command.value
      );
      this.currentPage = 1;
      this.handleMenuTags();
      this.get_termfile_list();
    },
    /**
     *
     * @param {* 源语言 下拉选 语言 与目标语言 互斥} select
     */ changeselectLanguage1(select) {
      let arr = JSON.parse(JSON.stringify(this.selectList));
      this.targetlanguagedropdownList = arr.filter(
        (i) => i.language_id !== select
      );
      // console.log(select, "select1");
    },
    /**
     *
     * @param {*目标语言 下拉选 语言 与源语言 互斥} select
     */
    changeselectLanguage2(select) {
      let arr = JSON.parse(JSON.stringify(this.selectList));
      this.sourcelanguagedropdownList = arr.filter(
        (i) => !select.includes(i.language_id)
      );
    },
    backClick() {
      sessionStorage.setItem("menuTags", "");
      this.currentPage = 1;
      this.tags = [];
      let pid = this.folder_pid;
      const index = this.Breadcrumb.findIndex(
        (item) => parseInt(item.id) == pid
      );
      let id = 0;
      if (index > 0) {
        id = this.Breadcrumb[index - 1]["id"];
      }
      this.folder_pid = id;

      this.$router.push({
        name: "TermBaseManagement",
        query: { id },
      });
      // setTimeout(() => {
      //   this.$refs.multipleTable.sort(this.older, this.defaultSort.order);
      // }, 10);
      this.get_termfile_list();
      // this.$router.go(0);
    },
    breadcrumbClick(folder_id) {
      this.folder_pid = folder_id;
      this.$router.push({
        name: "TermBaseManagement",
        query: { id: folder_id },
      });
      sessionStorage.setItem("menuTags", "");
      this.currentPage = 1;
      this.tags = [];
      this.get_termfile_list();
    },
    selectable(row, index) {
      if (row.owner == 0) {
        return false;
      } else {
        return true;
      }
    },
    handleSelectionChange(val) {
      // console.log(val);
      this.multipleSelection = val;
    },
    createFolder() {
      this.update_folder_id = 0;
      this.dialogFolderVisible = true;
      this.FolderName = "";
    },
    changeDialogFolderVisible(val) {
      this.dialogFolderVisible = false;
    },
    determineFolderClick() {
      if (this.FolderName.trim() == "") {
        this.$messages("warning", this.$t("ProjectManagement.FolderNameTip"));
        return false;
      }
      showLoading(this.$t("CommonName.loadingmessage"));
      if (this.update_folder_id != 0) {
        add_term_set({
          action: "service_termfile_renamefolder",
          id: this.update_folder_id,
          name: this.FolderName.trim(),
        }).then((res) => {
          hideLoading();
          if (!res) {
            return;
          }
          this.dialogFolderVisible = false;
          this.$messages(
            "success",
            this.$t("ProjectManagement.FolderNameTip2")
          );
          this.get_termfile_list();
        });
      } else {
        add_term_set({
          action: "service_termfile_addfolder",
          p_id: this.folder_pid,
          name: this.FolderName,
        }).then((res) => {
          hideLoading();
          if (!res) {
            return;
          }
          this.dialogFolderVisible = false;
          this.$messages(
            "success",
            this.$t("ProjectManagement.FolderNameTip1")
          );
          this.get_termfile_list();
        });
      }
    },
    /**
     * 点击下载 术语表模板
     */
    Download() {
      window.location.href = "/resourcefile/Glossary_Temp.xlsx";
    },
    /**
     * 初始化数据
     */
    initData() {
      this.form = {
        name: "",
        uploadinput1: "",
        desc: "",
        select1: "",
        select3: 0,
      };
      // 导入
      this.form1 = {
        uploadinput1: "",
        resource: "0",
      };
    },
    /**
     * 获取语言接口
     */
    get_language() {
      return get_language({
        action: "get_language",
        lang:
          sessionStorage.getItem("lang") == "cn" ||
          sessionStorage.getItem("lang") == null
            ? 1
            : 2,
      }).then((res) => {
        if (res) {
          this.selectList = res.list;
          this.sourcelanguagedropdownList = res.list;
          this.targetlanguagedropdownList = res.list;
        }
      });
    },
    /**
     * 获取术语表数据
     */
    get_termfile_list() {
      let data = { action: "service_get_termfile_list" };
      this.tags.forEach((item, index) => {
        data[item.key] = item.value;
      });
      data["page_size"] = this.pageSize;
      data["page_num"] = this.currentPage;
      data["folder_pid"] = this.folder_pid;
      data["folder_pid"] = this.folder_pid;
      return get_termfile_list(data).then((res) => {
        if (res) {
          // console.log(res.list);
          this.tableData = this.setTableData(res.data.list);
          this.tableTotal = res.data.total;
          this.Breadcrumb = res.data.Breadcrumb;
          hideLoading();
          this.multipleSelection = [];
          this.$refs.multipleTable.clearSelection();
        }
      });
    },
    setTableData(array) {
      for (let index = 0; index < array.length; index++) {
        let findItem = this.selectList.find(
          (item) => item.language_id == array[index].slang_id
        );
        if (findItem) {
          array[index]["slang_name"] = findItem["ename"];
        }
        let findItem1 = this.termTypeList.find(
          (item) => +item.id == +array[index].term_type
        );
        if (findItem1) {
          array[index]["type_name"] = findItem1["name"];
        }
        console.log(array[index]);
      }
      return array;
    },
    /**
     * 弹窗确认按钮
     */
    determineClick() {
      // console.log(this.form);
      if (this.showform == "1") {
        if (!this.form.name.trim()) {
          this.$messages("warning", this.$t("CommonName.warningmessage3"));
          return;
        }
        if (!this.form.select1) {
          this.$messages("warning", this.$t("CommonName.warningmessage1"));
          return;
        }
        showLoading(this.$t("CommonName.loadingmessage"));
        if (!this.form.uploadinput1) {
          add_term_set({
            action: "create_term_set_base",
            name: this.form.name.trim(),
            slang: this.form.select1,
            tlangs: this.form.select2.join(","),
            comments: this.form.desc,
            folder_id: this.folder_pid,
            term_type: this.form.select3,
          }).then((res) => {
            if (!res) {
              return;
            }
            sessionStorage.setItem("menuTags", "");
            this.currentPage = 1;
            this.pageSize = 20;
            this.tags = [];
            this.get_termfile_list().then(() => {
              this.dialogVisible = false;
              this.percentage = 0;
              this.loading = false;
              if (this.showform == "1") {
                this.$messages(
                  "success",
                  this.$t("CommonName.successmessage1")
                );
              } else {
                this.$messages(
                  "success",
                  this.$t("CommonName.successmessage4")
                );
              }
              // this.$messages(
              //   "success",
              //   this.$t("CommonName.uploadTermTip1") +
              //     response.total +
              //     this.$t("CommonName.uploadTermTip2") +
              //     response.success_count +
              //     this.$t("CommonName.uploadTermTip3"),
              //   4000
              // );
            });
          });
        } else {
          this.uploadData = {
            name: this.form.name.trim(),
            slang: this.form.select1,
            tlangs: this.form.select2.join(","),
            comments: this.form.desc,
            folder_id: this.folder_pid,
            term_type: this.form.select3,
          };
          this.$nextTick(() => {
            this.$refs.upload.submit();
          });
        }
      } else if (this.showform == "2") {
        if (!this.form1.uploadinput1) {
          this.$messages("warning", this.$t("CommonName.warningmessage5"));
          return;
        }
        this.uploadData = {
          term_set_id: this.rowData.id,
        };
        this.$nextTick(() => {
          this.$refs.upload.submit();
        });
      } else if (this.showform == "3") {
        if (!this.form2.name.trim()) {
          this.$messages("warning", this.$t("CommonName.warningmessage3"));
          return;
        }
        showLoading(this.$t("CommonName.loadingmessage"));
        add_term_set({
          action: "update_termset_info",
          name: this.form2.name.trim(),
          comments: this.form2.desc,
          term_set_id: this.form2.term_set_id,
        }).then((res) => {
          if (!res) {
            return;
          }
          this.get_termfile_list().then(() => {
            this.dialogVisible = false;
            this.percentage = 0;
            this.loading = false;
            this.$messages("success", this.$t("CommonName.successmessage3"));
          });
        });
      }
    },
    /**
     * 点击重新上传
     */
    updateNewfile(data) {
      if (data == 1) {
        this.form.uploadinput1 = "";
      } else {
        this.form1.uploadinput1 = "";
      }
      let uploadFilesArr = this.$refs["upload"].uploadFiles; //上传字幕文件
      if (uploadFilesArr.length == 0) {
        return;
      } else {
        this.$refs["upload"].uploadFiles = [];
      }
    },
    /**
     *
     * @param {*弹窗关闭按钮} val
     */
    changeDialogVisible(val) {
      this.dialogVisible = val;
      this.initData();
    },
    /**
     *
     * @param {*表格操作 下拉框} command
     */
    operationcommandClick(command) {
      console.log(command);

      switch (command.type) {
        case "0":
          this.rowData = command.id;
          this.dialogtitle = this.$t("TermBaseManagement.Importtermfile");
          this.dialogVisible = true;
          this.showform = "2";
          this.form1.uploadinput1 = "";
          break;
        case "1":
          this.dialogVisible = false;
          // 导出
          window.location.href = `${this.$uploadURL}/term/export_term?term_file_id=${command.id.id}`;
          break;
        case "2":
          this.rowData = command.id;
          this.dialogtitle = this.$t(
            "TermBaseManagement.Editglossaryinformation"
          );
          this.dialogVisible = true;
          this.showform = "3";
          this.form2.name = command.id.name;
          this.form2.desc = command.id.comments;
          this.form2.term_set_id = command.id.id;
          break;
        case "3":
          this.$confirm(
            this.$t("CommonName.deleteMessage3"),
            this.$t("CommonName.deleteMessageTitle"),
            {
              confirmButtonText: this.$t("CommonName.deleteMessagebutton"),
              cancelButtonText: this.$t("CommonName.deleteMessageClosebutton"),
              closeOnClickModal: false,
              cancelButtonClass: "closeButton",
              // confirmButtonClass: "confirmButton",
            }
          )
            .then(() => {
              showLoading(this.$t("CommonName.loadingmessage"));
              delete_termfile({
                action: "delete_term_set",
                term_set_id: command.id.id,
              }).then((res) => {
                if (res) {
                  sessionStorage.setItem("menuTags", "");
                  this.currentPage = 1;
                  this.tags = [];
                  this.get_termfile_list().then(() => {
                    this.$messages(
                      "success",
                      this.$t("CommonName.successmessage2")
                    );
                  });
                }
              });
            })
            .catch(() => {});

          break;
        // case "4":
        //   let pathInfo = this.$router.resolve({
        //     path: "/ContainerHome/ExTeam/TermBaseManagement/LuckySheet",
        //     query: {
        //       id: command.id.term_set_id,
        //       name: command.id.name,
        //     },
        //   });
        //   window.open(pathInfo.href, "_blank");
        //   break;
        //重命名
        case "5":
          this.update_folder_id = command.id.id;
          this.dialogFolderVisible = true;
          this.FolderName = command.id.name;
          break;
        //删除
        case "6":
          this.handleFolderDelete(command.id);
          break;
        default:
      }
    },
    handleFolderDelete(data) {
      let that = this;
      let next = true;
      if (+data.file_count > 0) {
        that.$messages("warning", this.$t("ProjectManagement.FolderDeleteTip"));
        next = false;
        return false;
      }
      if (next) {
        // console.log(jobids);
        // console.log(folderids);
        let title = "";
        title = this.$t("ProjectManagement.FolderDeleteTip1");
        this.$confirm(title, this.$t("CommonName.deleteMessageTitle"), {
          confirmButtonText: this.$t("CommonName.deleteMessagebutton"),
          cancelButtonText: this.$t("CommonName.deleteMessageClosebutton"),
          cancelButtonClass: "el-button--info",
          iconClass: "none",
          closeOnClickModal: false,
          distinguishCancelAndClose: true,
          closeOnPressEscape: false,
          type: "warning",
        })
          .then(() => {
            showLoading(this.$t("CommonName.loadingmessage"));

            add_term_set({
              action: "service_termfile_delfolder",
              id: data.id,
            }).then((res) => {
              if (res) {
                this.get_termfile_list().then((res) => {
                  this.$messages(
                    "success",
                    this.$t("CommonName.successmessage2")
                  );
                });
              }
            });
          })
          .catch((action) => {
            if (action === "cancel") {
              console.log("cancel");
            }
          });
      }
    },
    /**
     *
     * @param {*上传前  做判断是否能上传} file
     */
    uploadChange(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testmsg === "xlsx";
      if (!extension) {
        this.$messages(
          "warning",
          this.$t("TermBaseManagement.warningmessage1")
        );
        this.form1.uploadinput1 = "";
        this.form.uploadinput1 = "";
        this.$refs.upload.clearFiles();
        return false;
      } else {
        if (this.showform == "1") {
          this.form.uploadinput1 = file.name;
        } else {
          this.form1.uploadinput1 = file.name;
        }

        return true;
      }
    },
    /**
     *
     * @param {*上传成功} response
     */
    uploadSuccess(response) {
      if (response.result == "SUCCESS") {
        sessionStorage.setItem("menuTags", "");
        this.currentPage = 1;
        this.pageSize = 20;
        this.tags = [];
        this.get_termfile_list().then(() => {
          this.dialogVisible = false;
          this.percentage = 0;
          this.loading = false;
          // if (this.showform == "1") {
          //   this.$messages("success", this.$t("CommonName.successmessage1"));
          // } else {
          //   this.$messages("success", this.$t("CommonName.successmessage4"));
          // }
          this.$messages(
            "success",
            this.$t("CommonName.uploadTermTip1") +
              response.count +
              this.$t("CommonName.uploadTermTip2") +
              response.count +
              this.$t("CommonName.uploadTermTip3"),
            4000
          );
        });
      } else if (response.result == "LOGIN_FAILED") {
        this.$messages("warning", this.$t("CommonName.warningmessage4"));
        this.$router.push("/Login");
        this.$Cookie.remove("token_code");
        sessionStorage.clear();
      } else {
        this.percentage = 0;
        this.loading = false;
        this.dialogVisible = false;
        this.$messages("error", response.msg);
      }
    },
    /**
     *
     * @param {*上传过程中} file
     */
    uploadProgress(file) {
      if (file.percent > 0) {
        this.percentage = Number(file.percent.toFixed());
        this.loading = true;
      }
    },
    /**
     * 新建 TM
     */
    addNewmember() {
      this.initData();
      this.dialogtitle = this.$t("TermBaseManagement.NewGlossary");
      this.dialogVisible = true;
      this.showform = "1";
    },

    /**
     *
     * @param {超出溢出 显示} i
     */
    onShowNameTipsMouseenter(i) {
      onShowNameTipsMouseenter(i);
    },
    /**
     *
     * @param {*筛选 tab 删除} tag
     */
    handleClose(tag) {
      this.tags.splice(this.tags.indexOf(tag), 1);
      this.currentPage = 1;
      this.handleMenuTags();
      this.get_termfile_list();
    },
    /**
     * 表格表头 名称 筛选 按钮
     */
    searchdetermineClick() {
      if (this.popoverInput) {
        this.tags = addAndreplaceTags(
          this.$t("TermBaseManagement.GlossaryName"),
          this.popoverInput,
          this.tags,
          "name",
          this.popoverInput
        );
        this.currentPage = 1;
        this.handleMenuTags();
      }
      this.get_termfile_list();
      this.visible = false;
      this.popoverInput = "";
    },

    /**
     *
     * @param {*目标语言} command
     */
    targetlanguagecommand(command) {
      this.tags = addAndreplaceTags(
        this.$t("CommonName.targetlanguagetable"),
        command.name,
        this.tags,
        "tlang_id",
        command.value
      );
      this.currentPage = 1;
      this.handleMenuTags();
      this.get_termfile_list();
    },
    /**
     *
     * @param {*源语言} command
     */
    sourcelanguagecommand(command) {
      this.tags = addAndreplaceTags(
        this.$t("CommonName.sourcelanguagetable"),
        command.name,
        this.tags,
        "slang_id",
        command.value
      );
      this.currentPage = 1;
      this.handleMenuTags();
      this.get_termfile_list();
    },
    /**
     *
     * @param {点击表格 项目名称 进入项目详情页面} row
     */
    tovideoDetails(row) {
      if (row.is_folder === 1) {
        this.folder_pid = row.id;
        sessionStorage.setItem("menuTags", "");
        this.currentPage = 1;
        this.tags = [];
        // this.$refs.multipleTable.sort(this.older, this.defaultSort.order);
        this.$router.push({
          name: "TermBaseManagement",
          query: {
            id: row.id,
          },
        });
        this.get_termfile_list();
        // this.$router.go(0);
      } else {
        let pathInfo = this.$router.resolve({
          path: "/ContainerHome/ExTeam/TermBaseManagement/LuckySheet",
          query: {
            id: row.id,
            lang_mode: 1,
          },
        });
        window.open(pathInfo.href, "_blank");
      }
    },
    /**
     * 分页器
     * @param {} val 页数
     */
    handleSizeChange(val) {
      this.pageSize = val;
      this.handleMenuTags();
      this.get_termfile_list();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.handleMenuTags();
      this.get_termfile_list();
    },
    handleMenuTags() {
      let menuTags = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        tags: this.tags,
      };
      sessionStorage.setItem("menuTags", JSON.stringify(menuTags));
    },
    moveToFile() {
      if (this.multipleSelection.length > 0) {
        showLoading(this.$t("CommonName.loadingmessage"));
        let folder_ids = [];
        this.multipleSelection.forEach((item) => {
          if (item.is_folder == 1) {
            folder_ids.push(item.id);
          }
        });
        add_term_set({
          action: "service_get_movelist",
          folder_ids,
        }).then((res) => {
          hideLoading();
          if (!res) {
            return;
          }
          this.moveFolderList = res.data;
          this.dialogMoveVisible = true;
        });
      }
    },
    determineMoveClick() {
      let term_set_ids = [];
      let folder_ids = [];
      if (this.multipleSelection.length > 0) {
        this.multipleSelection.forEach((item) => {
          if (item.is_folder == 1) {
            folder_ids.push(item.id);
          } else {
            term_set_ids.push(item.id);
          }
        });
        if (this.selectAllProject) {
          this.move_folder_id = "0";
          //移动到根目录 也就是folder_id为0
        }
        add_term_set({
          action: "service_termfile_move",
          term_set_ids,
          folder_ids,
          id: this.move_folder_id,
        }).then((res) => {
          if (!res) {
            return;
          }
          if (res.error > 0) {
            this.$messages(
              "error",
              this.$t("ProjectManagement.FolderMoveTip1")
            );
          } else {
            this.$messages(
              "success",
              this.$t("ProjectManagement.FolderMoveTip")
            );
            this.dialogMoveVisible = false;
            this.get_termfile_list();
          }
        });
      }
    },
    changeDialogMoveVisible(val) {
      this.dialogMoveVisible = false;
    },
    handleNodeClick(val) {
      this.selectAllProject = false;
      this.move_folder_id = val.id;
    },
  },
};
</script>
<style scoped lang="scss">
.mainCenter {
  height: calc(100% - 38px);
  width: 100%;
  box-sizing: border-box;

  .mainCenterbtm {
    height: calc(100% - 76px);
  }
}

.mainCenterTop {
  // float: right;

  margin: 14px 24px 14px 18px;

  .titleOption {
    .svgbtn {
      margin-right: 14px;
    }
  }

  .mainHeader_left {
    display: flex;
    width: calc(100% - 700px);

    .signoutClass {
      width: 22px;
      height: 22px;
      border: 1px solid;
      border-color: #757784;
      border-radius: 2px;
      line-height: 22px;
      margin-right: 24px;
      cursor: pointer;

      .icon {
        font-size: 16px;
      }
    }

    .titleClass {
      display: flex;
      align-items: center;
      text-align: left;
      width: calc(100% - 48px);
      justify-content: space-between;

      .overflowp {
        width: 350px;
      }

      .line1 {
        width: 4px;
        height: 16px;
        background-color: #46adff;
        border-radius: 2px;
      }

      .breadcrumb1 {
        width: calc(100% - 12px);

        ::v-deep .el-breadcrumb__item .el-breadcrumb__inner {
          color: #adb0bc;
        }

        ::v-deep .el-breadcrumb__item.current .el-breadcrumb__inner {
          color: #fff;
        }

        ::v-deep .el-breadcrumb__inner a {
          color: #adb0bc;

          &:hover {
            color: #46adff;
          }
        }
      }

      p {
        font-weight: 700;
        color: #ffffff;
        font-size: 16px;
        margin-left: 8px;
      }
    }

    .dropdowndiv {
      display: flex;
      align-items: center;
      margin-left: 40px;

      .dropdownbutton {
        p {
          color: #46adff;
          font-size: 14px;
        }
      }
    }
  }
}

.tableBtnClass {
  display: flex;

  div {
    width: 50%;
  }
}

.menuscopediv {
  // padding-left: 14px;
  color: #46adff;
  display: flex;
  align-content: center;

  .folderp {
    color: #cbd7e8;
    font-size: 14px;
    white-space: pre;
  }

  .filep {
    color: #46adff;
    font-size: 14px;
  }

  .svgiconclass {
    color: #f5df86;
    width: 18px !important;
    height: 23px !important;
    margin-right: 5px;
  }

  .svgiconclass1 {
    width: 18px !important;
    height: 23px !important;
    color: #46adff;
    margin-right: 5px;
  }
}

.namep {
  margin-left: 14px;
}

.dialogContent {
  margin: 24px;

  p {
    color: #adb0bc;
    font-size: 14px;
    margin-bottom: 6px;
  }

  ::v-deep .el-input__inner {
    width: 840px;
  }

  .content {
    p {
      margin-bottom: 0;
    }

    .uploadp {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 6px;

      .el-button {
        padding: 0;
      }
    }

    .srtUnput {
      ::v-deep .el-input__inner {
        width: 755px !important;
      }

      ::v-deep .el-input-group__append,
      .el-input-group__prepend {
        width: 84px;
        background: none;
        border: 1px solid #7d7f8b;
        border-left: none;
        text-align: center;
        padding: 0;
      }

      .icon {
        margin-right: 6px;
      }
    }
  }

  .content_btm {
    margin-top: 16px;

    p {
      margin-bottom: 0;
    }
  }
}

.dropdowndiv {
  ::v-deep .popper__arrow {
    left: 80px !important;
  }
}

.dialogformStyleClass {
  .languageClass {
    display: flex;

    .selectclass {
      ::v-deep .el-input__inner {
        width: 840px !important;
      }

      ::v-deep .el-input__icon {
        line-height: 32px;
      }
    }

    .languageClass_right {
      margin-left: 40px;
    }
  }
}

.step1Center_left {
  .el-radio-button__inner,
  .el-radio-group {
    display: flex;
    flex-direction: column;
  }

  .el-radio.is-bordered {
    width: 400px;
    margin-top: 6px;
  }
}

.form3_top {
  .setSelectClass {
    ::v-deep .el-input__inner {
      width: 480px !important;
    }

    ::v-deep .el-input__icon {
      line-height: 32px;
    }
  }
}

.tabledropdowndiv {
  max-height: 210px;
  overflow-y: auto;
}
.addFolder {
  margin: 24px;

  p {
    color: #adb0bc;
    font-size: 14px;
    margin-bottom: 6px;
  }
}

.moveFolder {
  margin: 24px;
  height: 300px;
  overflow: auto;
  border: 1px solid #7d7f8b;
  border-radius: 2px;
  padding: 16px;

  .allProject {
    display: flex;
    height: 27px;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: #484a5a;
    }

    .svgiconclass1 {
      color: #cbd7e8;
      width: 18px !important;
      height: 21px !important;
      margin-right: 5px;
    }

    p {
      color: #cbd7e8;
      margin-bottom: 0px;
    }
  }

  .allProject.active {
    background-color: #46adff; //背景色
  }

  p {
    color: #adb0bc;
    font-size: 14px;
    margin-bottom: 6px;
  }

  ::v-deep .el-tree {
    background-color: #393c4e;

    .el-tree-node:focus > .el-tree-node__content {
      background-color: #46adff; //背景色
    }

    .el-tree-node__content {
      &:hover {
        background: #484a5a;
      }
    }
  }

  .custom-tree-node {
    display: flex;
    align-items: center;
  }

  .folderp {
    color: #cbd7e8;
    font-size: 14px;
    white-space: pre;
  }

  .svgiconclass {
    color: #f5df86;
    width: 18px !important;
    height: 21px !important;
    margin-right: 5px;
  }
  .svgiconclass1 {
    color: #46adff;
    width: 18px !important;
    height: 21px !important;
    margin-right: 5px;
  }
}
</style>
