<!--
 * @Description: 创建任务 弹窗
 * @version: 
 * @Author: Carful
 * @Date: 2022-11-16 09:48:24
 * @LastEditors: Carful
 * @LastEditTime: 2023-07-07 11:00:25
-->
<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    :width="'888px'"
    :top="'3%'"
    center
    :close-on-click-modal="false"
    @close="closeDialog"
  >
    <!--    第一步-->
    <div v-if="step == '1'" class="step1Center">
      <div class="step1Center_top">
        <!--        左侧 任务流程 单选-->
        <div class="radioClass step1Center_left">
          <p>{{ $t("CommonName.Taskworkflow.name") }}</p>
          <el-radio-group v-model="radio1" @change="radiochange1">
            <el-radio
              v-for="i in radioList1"
              :key="i.label"
              :label="i.label"
              border
              >{{ i.name }}
            </el-radio>
          </el-radio-group>
        </div>
        <!--        右侧 选项 单选-->
        <div class="radioClass step1Center_right">
          <!-- <div class="verification"> -->
          <p>{{ $t("CommonName.PreprocessOptions.name") }}</p>
          <!-- <svg-icon
              icon-class="presentation"
              class="verificationIcon"
              v-if="radio2 == null"
            ></svg-icon> -->
          <!-- </div> -->

          <el-radio-group v-model="radio2" @change="radiochange2">
            <el-radio
              v-for="i in radioList2"
              :key="i.label"
              :label="i.label"
              :disabled="i.disabled"
              border
              >{{ i.name }}
            </el-radio>
          </el-radio-group>
        </div>
      </div>
      <div
        class="step1Center_center radioClass"
        v-if="radio2 == '0'"
        v-show="info.role == 3"
      >
        <!-- "Main ASR Engine" -->
        <p>{{ $t("CommonName.ASREngine.name") }}</p>
        <el-radio-group v-model="radio3" @change="radiochange3">
          <el-radio
            v-for="i in radioList3"
            :key="i.label"
            :label="i.label"
            :disabled="i.disabled"
            border
            >{{ i.name }}
          </el-radio>
        </el-radio-group>
      </div>
      <div
        class="step1Center_center_1 radioClass"
        v-if="['1', '4', '6'].includes(radio1) && ['4'].includes(radio2)"
      >
        <!-- "识别选项" -->
        <p>{{ $t("CommonName.OcrMode.name") }}</p>
        <el-radio-group v-model="radio5" @change="radiochange5">
          <el-radio
            v-for="i in radioList5"
            :key="i.label"
            :label="i.label"
            border
            >{{ i.name }}
          </el-radio>
        </el-radio-group>
      </div>
      <div
        class="step1Center_center_1 radioClass"
        v-if="
          ['1', '2', '3', '4', '6'].includes(radio1) && ['2'].includes(radio2)
        "
      >
        <!-- Order of Texts in Subtitle File -->
        <p>{{ $t("CommonName.Orderoftextsinsubtitlefile.name") }}</p>
        <el-radio-group v-model="radio4">
          <el-radio
            v-for="i in radioList4"
            :key="i.label"
            :label="i.label"
            border
            >{{ i.name }}
          </el-radio>
        </el-radio-group>
      </div>
      <div class="step1Center_bottom">
        <div class="step1Center_left">
          <div class="verification">
            <!-- Source language-->
            <p>{{ $t("CommonName.Sourcelanguage") }}</p>
            <svg-icon
              icon-class="presentation"
              class="verificationIcon"
              v-if="select1 == null"
            ></svg-icon>
          </div>
          <!-- 源语言 -->
          <el-select
            v-model="select1"
            class="selectclass"
            @change="changeselectLanguage1"
            :placeholder="$t('CommonName.Pleaseselect')"
            filterable
          >
            <el-option
              v-for="item in languageObj.selectLanguage1"
              :key="item.language_id"
              :label="item.cname"
              :value="item.language_id"
            >
            </el-option>
          </el-select>
        </div>
        <div
          class="step1Center_right"
          v-if="
            ['1', '2', '3', '4', '6'].includes(radio1) &&
            ['0', '1', '2', '3', '4', '5'].includes(radio2)
          "
        >
          <div class="verification">
            <!-- Target language-->
            <p>{{ $t("CommonName.Targetlanguage") }}</p>
            <svg-icon
              icon-class="presentation"
              class="verificationIcon"
              v-if="select2_error == '0'"
            ></svg-icon>
          </div>
          <el-select
            v-model="select2"
            multiple
            @change="changeselectLanguage2"
            class="selectclass"
            :placeholder="$t('CommonName.Pleaseselect')"
            filterable
          >
            <el-option
              v-for="item in languageObj.selectLanguage2"
              :key="item.language_id"
              :label="item.cname"
              :value="item.language_id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div
        class="step1Center_center checkboxClass"
        v-show="['1', '2', '3', '4', '6'].includes(radio1)"
      >
        <!-- "AIT" -->
        <el-checkbox
          v-model="AITCheck"
          border
          :disabled="radio2 == '3'"
          style="width: 400px"
          >{{ $t("CommonName.AITText") }}</el-checkbox
        >
      </div>
      <div
        class="step1Center_center radioClass"
        v-if="AITCheck"
        v-show="['1', '2', '3', '4', '6'].includes(radio1)"
      >
        <!-- "Main ASR Engine" -->
        <!-- <p>{{ $t("CommonName.ASREngine.name") }}</p> -->
        <el-radio-group v-model="radio6" style="width: 100%">
          <el-radio
            v-for="i in radioList6"
            :key="i.label"
            :label="i.label"
            :disabled="i.disabled"
            style="width: 33.3%"
            border
            >{{ i.name }}
          </el-radio>
        </el-radio-group>
      </div>
    </div>
    <!--    第二步-->
    <div v-show="step == '2'" class="step2Center">
      <div class="content">
        <div class="radioClass content_1">
          <p>{{ $t("ExternalCreateTask.Sourceofmediafile.name") }}</p>
          <el-radio-group v-model="form.radio" @change="radiochange">
            <el-radio
              v-for="i in radioList"
              :key="i.label"
              :label="i.label"
              border
              >{{ i.name }}
            </el-radio>
          </el-radio-group>
        </div>
        <!-- 内部radio 小判断    上传 MP4-->
        <div class="content_center">
          <div v-show="form.radio == '0'">
            <div class="content_center_1">
              <div class="uploadp">
                <p>{{ $t("ExternalCreateTask.UploadMediaFile") }}</p>
                <el-tooltip placement="top-start" popper-class="uploadptooltip">
                  <div slot="content">
                    {{ $t("ProjectManagement.changeVideo1") }}<br />
                    {{ $t("ProjectManagement.changeVideo2") }}<br />
                    {{ $t("ProjectManagement.changeVideo3") }}
                  </div>
                  <svg-icon
                    icon-class="presentation"
                    class="icon"
                    style="cursor: pointer"
                  ></svg-icon>
                </el-tooltip>

                <svg-icon
                  icon-class="presentation"
                  class="verificationIcon"
                  v-if="form.uploadinput1 == null"
                ></svg-icon>
              </div>
              <el-input
                v-model="form.uploadinput1"
                class="input-with-select srtUnput"
                @input="form.uploadinput1 = ''"
              >
                <el-upload
                  slot="append"
                  class="upload-demo"
                  ref="upload_media_file"
                  :with-credentials="true"
                  :action="$uploadURL + '/videojob/gateway'"
                  name="media_file"
                  :data="uploadData"
                  :on-change="uploadChange"
                  :on-progress="uploadProgress"
                  :on-success="uploadSuccess"
                  :on-error="uploadError"
                  :auto-upload="false"
                  :show-file-list="false"
                  :limit="1"
                >
                  <el-button
                    type="text"
                    @click="srtupload('upload_media_file', '-1')"
                  >
                    <div class="uploadbtn">
                      <svg-icon icon-class="upload" class="icon"></svg-icon>
                      <!-- 上传 -->
                      <span>{{ $t("CommonName.Upload") }}</span>
                    </div>
                  </el-button>
                </el-upload>
              </el-input>
            </div>
            <div class="content_center_2" v-show="info.role === '3'">
              <!-- File Storage location -->
              <p>{{ $t("ExternalCreateTask.FileStoragelocation") }}</p>
              <el-select
                v-model="form.select1"
                class="selectclass"
                value-key="id"
              >
                <el-option
                  v-for="item in select1List"
                  :key="item.id"
                  :label="item.name"
                  :value="item"
                  :value-key="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div v-show="form.radio == '1'">
            <div class="radio_content_2">
              <p>{{ $t("CommonName.Selectproject") }}</p>
              <el-select
                v-model="form.select8"
                class="selectclass"
                @change="select8Change"
                filterable
              >
                <el-option
                  v-for="item in select8List"
                  :key="item.project_id"
                  :label="item.project_name"
                  :value="item.project_id"
                >
                </el-option>
              </el-select>
            </div>
            <div class="radio_content_3">
              <div class="verification">
                <!--Select media file in project-->
                <p>{{ $t("CommonName.Selectmediafileinproject") }}</p>
                <svg-icon
                  icon-class="presentation"
                  class="verificationIcon"
                  v-if="form.select9 == null"
                ></svg-icon>
              </div>
              <el-select
                v-model="form.select9"
                class="langselectclass langselectclass1"
                :disabled="select9Disabled"
                placeholder=""
                filterable
              >
                <el-option
                  v-for="item in select9List"
                  :key="item.job_id"
                  :label="item.job_name"
                  :value="item.job_id"
                  @click.native="select9Change(item)"
                >
                </el-option>
              </el-select>
            </div>
            <div class="radio_content_3">
              <div class="verification">
                <!--Select media file in project-->
                <p>{{ $t("CommonName.NewJobName") }}</p>
                <svg-icon
                  icon-class="presentation"
                  class="verificationIcon"
                  v-if="form.newjobname == null"
                ></svg-icon>
              </div>
              <el-input v-model="form.newjobname" class="langselectclass1">
              </el-input>
            </div>
          </div>
          <!-- 上传 srt  文件  -->
          <div v-if="showOneupload == '1'">
            <div class="content_center_3">
              <p>{{ $t("ExternalCreateTask.UploadFile") }}</p>
              <el-input
                v-model="form.uploadinput4"
                class="input-with-select srtUnput"
                style="width: 100%"
                @input="form.uploadinput4 = ''"
              >
                <el-upload
                  slot="append"
                  class="upload-demo"
                  ref="srtupload"
                  :action="$uploadURL + '/job/upload_srt_file'"
                  name="srt_file"
                  :with-credentials="true"
                  :before-upload="srtbeforeUpload"
                  :show-file-list="false"
                  :on-success="srtuploadSuccess"
                  :auto-upload="true"
                  :limit="1"
                >
                  <el-button type="text" @click="srtupload('srtupload', '0')">
                    <div class="uploadbtn">
                      <svg-icon icon-class="upload" class="icon"></svg-icon>
                      <!-- 上传 -->
                      <span>{{ $t("CommonName.Upload") }}</span>
                    </div>
                  </el-button>
                </el-upload>
              </el-input>
            </div>
          </div>
          <!-- 上传 源语言 srt 文件 -->
          <div v-if="showOneupload == '2'">
            <div class="content_center_3">
              <p>{{ $t("ExternalCreateTask.UploadSourceFile") }}</p>
              <el-input
                v-model="form.uploadinput2"
                class="input-with-select srtUnput"
                @input="form.uploadinput2 = ''"
              >
                <el-upload
                  slot="append"
                  class="upload-demo"
                  ref="srtupload1"
                  :action="$uploadURL + '/job/upload_srt_file'"
                  name="srt_file"
                  :with-credentials="true"
                  :before-upload="srtbeforeUpload"
                  :show-file-list="false"
                  :on-success="srtuploadSuccess"
                  :auto-upload="true"
                  :limit="1"
                >
                  <el-button type="text" @click="srtupload('srtupload1', '1')">
                    <div class="uploadbtn">
                      <svg-icon icon-class="upload" class="icon"></svg-icon>
                      <!-- 上传 -->
                      <span>{{ $t("CommonName.Upload") }}</span>
                    </div>
                  </el-button>
                </el-upload>
              </el-input>
            </div>
            <!-- 上传 目标语言 -->
            <div class="content_center_4">
              <p>{{ $t("ExternalCreateTask.UploadTargetFile") }}</p>
              <el-input
                v-model="form.uploadinput3"
                class="input-with-select srtUnput"
                @input="form.uploadinput3 = ''"
              >
                <el-upload
                  slot="append"
                  class="upload-demo"
                  ref="srtupload2"
                  :action="$uploadURL + '/job/upload_srt_file'"
                  name="srt_file"
                  :with-credentials="true"
                  :before-upload="srtbeforeUpload"
                  :show-file-list="false"
                  :on-success="srtuploadSuccess"
                  :auto-upload="true"
                  :limit="1"
                >
                  <el-button type="text" @click="srtupload('srtupload2', '2')">
                    <div class="uploadbtn">
                      <svg-icon icon-class="upload" class="icon"></svg-icon>
                      <!-- 上传 -->
                      <span>{{ $t("CommonName.Upload") }}</span>
                    </div>
                  </el-button>
                </el-upload>
              </el-input>
            </div>
          </div>
          <!-- 上传 txt  文件  -->
          <div v-if="showOneupload == '3'">
            <div class="content_center_3">
              <p>{{ $t("ExternalCreateTask.UploadFile1") }}</p>
              <el-input
                v-model="form.uploadinput5"
                class="input-with-select srtUnput"
                style="width: 100%"
                @input="form.uploadinput5 = ''"
              >
                <el-upload
                  slot="append"
                  class="upload-demo"
                  ref="txtupload"
                  :action="$uploadURL + '/job/upload_srt_file'"
                  name="srt_file"
                  :with-credentials="true"
                  :before-upload="txtbeforeUpload"
                  :show-file-list="false"
                  :on-success="txtuploadSuccess"
                  :auto-upload="true"
                  :limit="1"
                >
                  <el-button type="text" @click="srtupload('txtupload', '3')">
                    <div class="uploadbtn">
                      <svg-icon icon-class="upload" class="icon"></svg-icon>
                      <!-- 上传 -->
                      <span>{{ $t("CommonName.Upload") }}</span>
                    </div>
                  </el-button>
                </el-upload>
              </el-input>
            </div>
          </div>
        </div>
        <!-- Speech transcription deadline                    4-->
        <div
          class="content_center_footer"
          v-if="['0', '1', '4', '5', '6'].includes(radio1)"
        >
          <div class="content__footer_left">
            <p>{{ $t("CommonName.Speechtranscriptiondeadline") }}</p>
            <el-date-picker
              v-model="form.time1"
              type="datetime"
              :format="time_format"
              value-format="yyyy-MM-dd HH:mm"
              :placeholder="$t('ProjectManagement.SelectDateTime')"
              class="selectclass"
            >
            </el-date-picker>
          </div>
          <!-- 转写任务处理人 -->
          <div class="content__footer_right">
            <p>
              {{
                $t("ExternalCreateTask.Taskprocessor") +
                " " +
                $t("CommonName.optional")
              }}
            </p>
            <el-select
              v-model="form.select2"
              clearable
              class="selectclass"
              filterable
            >
              <el-option
                v-for="item in select2List"
                :key="item.member_id"
                :label="item.nickname"
                :value="item.member_id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="content_center_footer" v-if="['5', '6'].includes(radio1)">
          <div class="content__footer_left">
            <p>{{ $t("CommonName.STQDeadline") }}</p>
            <el-date-picker
              v-model="form.time4"
              type="datetime"
              :format="time_format"
              value-format="yyyy-MM-dd HH:mm"
              :placeholder="$t('ProjectManagement.SelectDateTime')"
              class="selectclass"
            >
            </el-date-picker>
          </div>
          <!-- SQT任务处理人 -->
          <div class="content__footer_right">
            <p>
              {{
                $t("ExternalCreateTask.STQprocessor") +
                " " +
                $t("CommonName.optional")
              }}
            </p>
            <el-select
              v-model="form.select10"
              clearable
              class="selectclass"
              filterable
            >
              <el-option
                v-for="item in select2List"
                :key="item.member_id"
                :label="item.nickname"
                :value="item.member_id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <!-- 根据外面选择 radio 组合进行大判断 -->
        <!-- Subtitle translation deadline                         5-->
        <div
          class="content_center_footer"
          v-if="['1', '2', '3', '4', '6'].includes(radio1)"
        >
          <div class="content__footer_left">
            <p>{{ $t("CommonName.Subtitletranslationdeadline") }}</p>
            <el-date-picker
              v-model="form.time2"
              type="datetime"
              :format="time_format"
              value-format="yyyy-MM-dd HH:mm"
              :placeholder="$t('ProjectManagement.SelectDateTime')"
              class="selectclass"
            >
            </el-date-picker>
          </div>
          <!-- 翻译任务处理人 -->
          <div class="content__footer_right">
            <p>
              {{
                $t("ExternalCreateTask.Translator") +
                " " +
                $t("CommonName.optional")
              }}
            </p>
            <el-select
              v-model="form.select3"
              clearable
              class="selectclass"
              filterable
            >
              <el-option
                v-for="item in select2List"
                :key="item.member_id"
                :label="item.nickname"
                :value="item.member_id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <!-- Subtitle review deadline                                6-->
        <div
          class="content_center_footer"
          v-if="['3', '4', '6'].includes(radio1)"
        >
          <div class="content__footer_left">
            <p>{{ $t("CommonName.Subtitlereviewdeadline") }}</p>
            <el-date-picker
              v-model="form.time3"
              type="datetime"
              :format="time_format"
              value-format="yyyy-MM-dd HH:mm"
              :placeholder="$t('ProjectManagement.SelectDateTime')"
              class="selectclass"
            >
            </el-date-picker>
          </div>
          <!-- 校对任务处理人 -->
          <div class="content__footer_right">
            <p>
              {{
                $t("ExternalCreateTask.ProofreadTaskHandler") +
                " " +
                $t("CommonName.optional")
              }}
            </p>
            <el-select
              v-model="form.select4"
              clearable
              class="selectclass"
              filterable
            >
              <el-option
                v-for="item in select2List"
                :key="item.member_id"
                :label="item.nickname"
                :value="item.member_id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <template v-if="['2', '3', '4', '5', '6'].includes(radio1)">
          <div
            class="content_center_footer"
            v-for="(itemL, index) in selectTM"
            :key="index"
          >
            <!-- Temp TM T(optional)           select6 -->
            <div class="content__footer_left">
              <div class="verification">
                <p>
                  {{ itemL.lang_name + " " + $t("CommonName.TempTMToptional") }}
                </p>
              </div>
              <el-select v-model="selectTM[index]['t']" class="selectclass">
                <el-option
                  v-for="item in select6List"
                  :key="item.tm_id"
                  :label="item.name"
                  :value="item.tm_id"
                  v-show="item.tlang == itemL.lang_id"
                >
                </el-option>
              </el-select>
            </div>
            <!-- Temp TM E(optional)-------------select7 -->
            <div
              class="content__footer_right"
              v-if="['3', '4', '6'].includes(radio1)"
            >
              <div class="verification">
                <p>
                  {{ itemL.lang_name + " " + $t("CommonName.TempTMEoptional") }}
                </p>
              </div>
              <el-select v-model="selectTM[index]['e']" class="selectclass">
                <el-option
                  v-for="item in select6List"
                  :key="item.tm_id"
                  :label="item.name"
                  :value="item.tm_id"
                  v-show="item.tlang == itemL.lang_id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </template>
      </div>

      <!-- <Radio1_0Radio2_1Vue
        :radio1="radio1"
        :radio2="radio2"
        :radioList="radioList"
        ref="step2conrent"
      ></Radio1_0Radio2_1Vue> -->
    </div>
    <!--    第三步 / 第四步-->
    <div v-if="step == '3' || step == '4'" class="step3Center innerDialog_div">
      <div class="innerDialog_div_top_p">
        <p>
          {{ $t("PersonalEndLanguage.warningmessage35") }}
          <el-popover
            placement="bottom-end"
            trigger="hover"
            :visible-arrow="false"
            popper-class="innerDialogPopover"
          >
            <div class="innerDialogPopover_div">
              <div>
                <p>
                  <!--                      错误示例-->
                  <svg-icon icon-class="icon_No" class="cuoWu"></svg-icon>
                  {{ $t("PersonalEndLanguage.ViewExamples1") }}
                </p>
                <el-image
                  :src="require('@/assets/imgs/cuoWu_shiLi.png')"
                  fit="fill"
                ></el-image>
                <p class="btmp" style="word-break: break-word">
                  {{ $t("PersonalEndLanguage.ViewExamples3") }}
                </p>
              </div>
              <div>
                <p>
                  <svg-icon icon-class="Yes" class="zhengQue"></svg-icon>
                  {{ $t("PersonalEndLanguage.ViewExamples2") }}
                </p>
                <el-image
                  :src="require('@/assets/imgs/zhengQue_shiLi.png')"
                  fit="fill"
                ></el-image>
                <p class="btmp" style="word-break: break-word">
                  {{ $t("PersonalEndLanguage.ViewExamples4") }}
                </p>
              </div>
            </div>
            <el-button slot="reference" type="text"
              >{{ $t("PersonalEndLanguage.ViewExamples") }}
            </el-button>
          </el-popover>
        </p>
      </div>
      <div class="innerDialog_video_div" id="innerDialog_video_div">
        <!-- 视频展示 -->
        <video
          :class="'innerDialog_video_class'"
          id="dialogVideo"
          ref="videoref"
          :src="videoUrl"
          type="video/mp4"
          oncontextmenu="return false"
        >
          <!-- <source /> -->
        </video>
        <canvas id="canvas" :width="1" :height="360"></canvas>
      </div>
      <div class="sliderClass">
        <el-slider
          v-model="slidervalue"
          :show-tooltip="true"
          :step="steps"
          @change="sliderInput"
          :max="silderMax"
          :marks="marks"
          :disabled="sliderChange"
          :format-tooltip="formatTooltip"
        ></el-slider>
      </div>
      <div class="left_videobom_div left_videobom_div_play">
        <div class="left"></div>
        <div class="center">
          <!-- 回退 -->
          <div @click="rew" class="btndiv">
            <!-- ! todo -->
            <svg-icon icon-class="VideoBack" class="svgiconclass"></svg-icon>
          </div>
          <!-- 开始时间 -->
          <p>{{ videoStartTime }}</p>
          <!-- 暂停播放按钮 -->

          <div @click="playvideo" v-if="player" class="btndiv" id="step-5">
            <svg-icon icon-class="VideoPlay" class="svgiconclass"></svg-icon>
          </div>
          <div @click="closevideo" v-else class="btndiv" id="step-5">
            <svg-icon icon-class="VideoPause" class="svgiconclass"></svg-icon>
          </div>

          <!-- 结束时间 -->
          <p>{{ videolangtime }}</p>
          <!-- 前进 -->
          <div @click="speek" class="btndiv">
            <!-- ! todo -->
            <svg-icon icon-class="VideoForward" class="svgiconclass"></svg-icon>
          </div>
        </div>
        <div class="right">
          <!-- 播放倍数 -->
          <!-- <el-dropdown @command="handleCommand" placement="top" trigger="click">
            <p
              class="el-dropdown-link"
              style="text-align: end; cursor: pointer"
            >
              {{ ds + "X" }}
            </p>
            <el-dropdown-menu slot="dropdown" :append-to-body="false">
              <el-dropdown-item command="0.5">0.5X</el-dropdown-item>
              <el-dropdown-item command="0.75">0.75X</el-dropdown-item>
              <el-dropdown-item command="1">1X</el-dropdown-item>
              <el-dropdown-item command="1.25">1.25X</el-dropdown-item>
              <el-dropdown-item command="1.5">1.5X</el-dropdown-item>
              <el-dropdown-item command="1.75">1.75X</el-dropdown-item>
              <el-dropdown-item command="2">2X</el-dropdown-item>
              <el-dropdown-item command="2.5">2.5X</el-dropdown-item>
              <el-dropdown-item command="3">3X</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown> -->
        </div>
      </div>
      <div class="time_div">
        <div class="start_time_div">
          <div>
            <!--                片头时间点-->
            <span>{{ $t("PersonalEndLanguage.openingTime") }}</span>
            <el-input
              v-model="startTime"
              placeholder="00:00:00"
              clearable
              suffix-icon="el-icon-time"
              @clear="clearInput(0)"
              @blur="inputBlur(0)"
            >
            </el-input>
          </div>
          <div class="icon_div" @click="TimeIconClick(0, videoDom.currentTime)">
            <i class="el-icon-location icon"></i>
          </div>
        </div>
        <div class="end_time_div">
          <div>
            <!--                片尾时间点-->
            <span>{{ $t("PersonalEndLanguage.endingTime") }}</span>
            <el-input
              v-model="endTime"
              placeholder="00:00:00"
              clearable
              suffix-icon="el-icon-time"
              @clear="clearInput(1)"
              @blur="inputBlur(1)"
            >
            </el-input>
          </div>
          <div class="icon_div" @click="TimeIconClick(1, videoDom.currentTime)">
            <i class="el-icon-location icon1"></i>
          </div>
        </div>
      </div>
      <p class="tips_p">
        <!--        您可以手动选择要识别的片头时间点和片尾时间，选择后将仅对所选时间范围内进行硬字幕识别。-->
        {{ $t("PersonalEndLanguage.ViewExamples7") }}
      </p>
    </div>
    <!--    确定 取消 按钮-->
    <div slot="footer" class="dialog-footer" style="margin-top: 24px">
      <!--      返回上一步 按钮-->
      <div class="Previousbtn" v-if="step !== '1'">
        <el-button @click="PreviousClick" type="info" class="setpadding16">
          <div class="btnicon">
            <svg-icon icon-class="Aright" class="icon"></svg-icon>
            <p class="footerleftbuttonp">{{ $t("CommonName.Previous") }}</p>
          </div>
        </el-button>
      </div>
      <div>
        <!--        关闭 按钮-->
        <el-button @click="closeDialog" type="info" class="setpadding23"
          ><p class="footerleftbuttonp">
            {{ $t("CommonName.Cancel") }}
          </p></el-button
        >
        <!--        下一步 按钮-->
        <el-button
          type="primary"
          @click="NextClick"
          class="setpadding23"
          v-if="
            step == '1' ||
            (step == '2' && radio2 == '4') ||
            (step == '3' && radio5 == '1')
          "
        >
          <p class="footerrightbuttonp2" style="">
            {{ $t("CommonName.Next") }}
          </p>
        </el-button>
        <!--        保存 按钮-->
        <el-button
          type="primary"
          @click="determineClick"
          class="setpadding23"
          :loading="loadingSure"
          v-else
        >
          {{ $t("CommonName.Sure") }}
        </el-button>
      </div>
    </div>
    <div
      class="progressdiv"
      v-if="percentage > 0"
      v-loading.fullscreen.lock="loading"
      element-loading-custom-class="disaplaynoneicon"
      element-loading-background="rgba(0, 0, 0, 0.3)"
    >
      <el-progress
        type="circle"
        :percentage="percentage"
        :stroke-width="10"
      ></el-progress>
    </div>
  </el-dialog>
</template>
<script>
import { getCurrentTime, TimeHandle } from "@/api/setTime";
import {
  get_language,
  get_member_list, //获取成员 列表信息
  get_tm_list, //获取TM列表
  get_import_project_list,
  get_import_project_job_list,
  create_job_file,
  get_oss_list,
  get_ocr_last_job,
} from "@/utils/https";
import { hideLoading, showLoading } from "@/utils/loading";
import { fabric } from "fabric";
import { getTimeString } from "@/utils/tools";
// import Radio1_0Radio2_1Vue from "./ExternalCreateTask/Radio1_0Radio2_1.vue";
import $ from "jquery";
export default {
  // components: {
  //   Radio1_0Radio2_1Vue,
  // },
  props: {
    showdialog: {
      type: Boolean,
      default: false,
    },
    DetermineClickCreateTask: {
      type: String,
      default: "1",
    },
    Clonejobinfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isMoveLeft: true,
      maxScaleX: 1,
      isMoveTop: true,
      maxScaleY: 1,
      sliderChange: true, //是否能拖拽 youtube 滚动条
      canvas: null, //canvas
      rect: null, //创建矩形
      videoDom: {}, //创建 视频对象
      slidervalue: 0, //当前步
      startTime: "", //片头时间点
      endTime: "", //片头时间点
      old_time: "",
      old_time1: "",
      steps: 0.01, //步长
      // 硬字幕识别 滑块 标记 时间范围
      marks: {},
      silderMax: 0, //总步长
      videoUrl: "", //内部弹窗 中的视频
      videoHeight: 0, //获取视频原始 高度
      videoWidth: 0, //获取视频 原始高度
      showVideoClass: false,
      info: {},
      project_id: "",
      languageoptions: [], //所有 语言  数据
      languageObj: {
        //源语言 目标语言 创建任务时 互斥 关系
        selectLanguage1: [],
        selectLanguage2: [],
      },
      dialogVisible: false,
      title: this.$t("ExternalCreateTask.title1"),
      step: "1",
      // 第一页数据
      radio1: "0",
      // 任务流程 单选数据
      radioList1: [
        { label: "0", name: this.$t("CommonName.Taskworkflow.label0") },
        { label: "1", name: this.$t("CommonName.Taskworkflow.label1") },
        { label: "2", name: this.$t("CommonName.Taskworkflow.label2") },
        { label: "3", name: this.$t("CommonName.Taskworkflow.label3") },
        {
          label: "4",
          name: this.$t("CommonName.Taskworkflow.label4"),
        },
        {
          label: "5",
          name: this.$t("CommonName.Taskworkflow.label5"),
        },
        {
          label: "6",
          name: this.$t("CommonName.Taskworkflow.label6"),
        },
      ],
      radio2: "-1",
      // 流程 单选数据
      radioList2: [
        {
          label: "0",
          name: this.$t("CommonName.PreprocessOptions.label0"),
          disabled: false,
        },
        {
          label: "1",
          name: this.$t("CommonName.PreprocessOptions.label1"),
          disabled: false,
        },
        {
          label: "2",
          name: this.$t("CommonName.PreprocessOptions.label2"),
          disabled: false,
        },
        {
          label: "3",
          name: this.$t("CommonName.PreprocessOptions.label3"),
          disabled: false,
        },
        {
          label: "4",
          name: this.$t("CommonName.PreprocessOptions.label4"),
          disabled: false,
        },
        {
          label: "5",
          name: this.$t("CommonName.PreprocessOptions.label5"),
          disabled: false,
        },
      ],
      radio3: "1", //asr_mode_type
      radioList3: [
        {
          label: "1",
          name: this.$t("CommonName.ASREngine.label0"),
          disabled: false,
        },
        {
          label: "2",
          name: this.$t("CommonName.ASREngine.label1"),
          disabled: false,
        },
        {
          label: "3",
          name: this.$t("CommonName.ASREngine.label2"),
          disabled: false,
        },
        {
          label: "4",
          name: this.$t("CommonName.ASREngine.label3"),
          disabled: false,
        },
      ],
      radio4: "0", //Order of texts in subtitle file  	0目标语言在上, 1原语言在上
      radioList4: [
        {
          label: "0",
          name: this.$t("CommonName.Orderoftextsinsubtitlefile.label0"),
        },
        {
          label: "1",
          name: this.$t("CommonName.Orderoftextsinsubtitlefile.label1"),
        },
      ],
      select1: "", //Source language
      select2: [], //Target language
      radioList: [],
      // Radio1_0Radio2_1Vue  第二页 数据
      form: {
        radio: "0", //源文件来源方式0 上传,1 借调， 2 本地
        uploadinput1: "", // 上传 的mp4 名
        file: "", //上传得 文件
        select1: {},
        uploadinput2: "", //上传 源语言 source srt文件
        uploadinput2_show: "", // 上传成功 后 获取到后端返回的srt_source_name 文件名称
        uploadinput3: "", //上传 目标语言 srt_target_name 文件
        uploadinput3_show: "", //上传成功 后 获取到后端返回的srt_target_name 文件名称
        time1: "", //	转写预计交付时间
        select2: "", //转写任务处理人 转写任务执行人id
        time2: "", //翻译预计交付时间 按照任务类型 没有该节点就传空
        select3: "", //翻译任务处理人 翻译任务执行人id
        time3: "", //	校审预计交付时间 按照任务类型 没有该节点就传空
        select4: "", //校对任务处理人 校对任务执行人id
        select5: "", //主TM
        select6: "", //临时TM T
        select7: "", //临时TM E
        select8: "", //	源文件来源方式选择1时 选择的项目id 其他情况传空
        select9: "", //源文件来源方式选择1时 选择的任务id 其他情况传空
        uploadinput4: "", // 上传 srt 文件srt_show_name
        uploadinput4_show: "", // 上传成功 后 获取的 文件名srt_name
        newjobname: "", // 借调任务文件名 job_name_1
        uploadinput5: "", // 上传 txt 文件txt_show_name
        uploadinput5_show: "", // 上传成功 后 获取的 文件名txt_name
        time4: "", //	SQT预计交付时间 按照任务类型 没有该节点就传空
        select10: "", //SQT任务处理人 SQT任务执行人id
      },
      languageSrt: "-1", // -1 上传 视频等文件 上传 srt 文件 区分 上传的是哪个  0-srt_name 1-srt_source_name 2-srt_target_name
      showOneupload: "0",
      uploadData: {}, //上传 文件 需要上传 的 其他数据
      loading: false, //开启 上传进度
      percentage: 0, //上传进度
      select1List: [], //oss_mode_type
      select2List: [], // 成员列表 转写 翻译 校对 处理人

      select5List: [], //主TM
      select6List: [], //临时TM
      select8List: [], //Select Project 	源文件来源方式选择1时 选择的项目id 其他情况传空
      select9List: [], //Select Media File in Project 源文件来源方式选择1时 选择的任务id 其他情况传空
      select9Disabled: true,
      time_format:
        sessionStorage.getItem("lang") == "en"
          ? "MMM dd yyyy HH:mm"
          : "yyyy-MM-dd HH:mm",
      loadingSure: false,
      HardlanguageList: [
        "2",
        "1",
        "3",
        "8",
        "9",
        "5",
        "11",
        "13",
        "15",
        "17",
        "24",
        "25",
      ], //硬字幕识别支持的源语言
      SubtitleAlignlanguageList: ["2", "1", "13", "25"], //字幕对齐支持的源语言
      select2_error: "1",
      selectTM: [], //TM选择集合
      AITCheck: false,
      radio5: "0", //硬字幕识别设置0-原文，1-原文与译文
      radioList5: [
        {
          label: "0",
          name: this.$t("CommonName.OcrMode.label0"),
        },
        {
          label: "1",
          name: this.$t("CommonName.OcrMode.label1"),
        },
      ],
      source_data: {}, //硬字幕原文数据
      target_data: {}, //硬字幕译文数据
      base_position: {}, //上次ocr框信息
      videoStartTime: "00:00:00.00",
      videolangtime: "00:00:00.00", //总时长
      player: true,
      ds: 1.0, // 倍数
      radio6: "2", //AIT服务 0表示gpt，1表示deepseek阿里，2表示deepseek字节
      radioList6: [
        {
          label: "0",
          name: this.$t("CommonName.AITMode.label0"),
          disabled: false,
        },
        {
          label: "1",
          name: this.$t("CommonName.AITMode.label1"),
          disabled: false,
        },
        {
          label: "2",
          name: this.$t("CommonName.AITMode.label2"),
          disabled: false,
        },
      ],
    };
  },
  methods: {
    /**
     *
     * @param {*键盘 按下事件} e
     */
    windowkeydown(e) {
      const nodeName = e.target.nodeName;
      //空格
      if (e.keyCode == 0 || e.keyCode == 32) {
        if (
          (nodeName && nodeName.toUpperCase() == "INPUT") ||
          (nodeName && nodeName.toUpperCase() == "TEXTAREA")
        ) {
          return;
        }
        e.preventDefault();
        if (this.player) {
          this.playvideo();
        } else {
          this.closevideo();
        }
      }
    },
    // =============================================接口调用==============================
    /**
     * 获取语言接口
     */
    get_language() {
      get_language({
        action: "get_language",
        lang:
          sessionStorage.getItem("lang") == "cn" ||
          sessionStorage.getItem("lang") == null
            ? 1
            : 2,
      }).then((res) => {
        if (res) {
          this.languageoptions = res.list;
          this.languageObj.selectLanguage1 = res.list;
          this.languageObj.selectLanguage2 = res.list;
        }
      });
    },
    /**
     *
     * @param {*获取TM列表} data
     */
    get_tm_list(data) {
      get_tm_list({
        action: "get_tm_list",
        type: data, //1主TM，2临时TM
        slang_id: this.select1,
        tlang_ids: this.select2,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.select6List = res.list;
        if (this.DetermineClickCreateTask == "2") {
          this.select6List.map((i) => {
            if (this.Clonejobinfo.trans_tm.id == i.tm_id) {
              const targetIndex = this.selectTM.findIndex(
                (item1) => item1.lang_id === i.tlang
              );
              if (targetIndex > -1) {
                this.selectTM[targetIndex]["t"] = i.tm_id;
              }
            }
            if (this.Clonejobinfo.edit_tm.id == i.tm_id) {
              const targetIndex = this.selectTM.findIndex(
                (item1) => item1.lang_id === i.tlang
              );
              if (targetIndex > -1) {
                this.selectTM[targetIndex]["e"] = i.tm_id;
              }
            }
          });
        }
      });
    },
    /**
     *
     * @param {*获取成员列表信息} data
     */
    get_member_list(data = 2) {
      get_member_list({
        action: "get_member_list",
        team_user_status: data,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.select2List = res.list;
      });
    },
    /**
     *
     * @param {*获取可以导入的项目列表信息} data
     */
    get_import_project_list(data) {
      get_import_project_list({
        action: "get_import_project_list",
      }).then((res) => {
        if (!res) {
          return;
        }
        this.select8List = res.list;
      });
    },
    /**
     *
     * @param {*获取项目下可以导入的任务列表信息} id
     */
    get_import_project_job_list(id) {
      get_import_project_job_list({
        action: "get_import_project_job_list",
        project_id: id, //项目id
      }).then((res) => {
        if (!res) {
          return;
        }
        this.select9List = res.list;
        this.form.select9 = "";
        this.form.newjobname = "";
        this.form.file = "";
      });
    },
    /**
     *
     * @param {*创建多媒体任务} item
     */
    create_job_file(item) {
      create_job_file(item).then((res) => {
        this.loadingSure = false;
        if (!res) {
          return;
        }
        this.dialogVisible = false;
        this.$emit("Creationcompleted", this.dialogVisible);
      });
    },
    /**
     *
     * @param {*获取OSS节点列表信息} data
     */
    get_oss_list() {
      get_oss_list({
        action: "get_oss_list",
      }).then((res) => {
        if (!res) {
          return;
        }
        this.select1List = res.list;
        if (this.DetermineClickCreateTask == "1") {
          res.list.map((i) => {
            if (i.default == true) {
              this.form.select1 = i;
            }
          });
        }

        // console.log(this.form.select1);
      });
    },
    // ============================================第三步 硬字幕识别 方法=======================
    /**
     * 滑块滑动  修改视频时间
     * @param slider
     */
    sliderInput(slider) {
      this.slidervalue = slider;
      this.videoDom.currentTime = slider;
    },
    /**
     * 格式化显示 时间
     * @param val
     */
    formatTooltip(val) {
      return getTimeString(val, 1, 2).replace(".", ",");
    },
    /**
     * 清除 输入框里得内容
     * @param data
     */
    clearInput(data) {
      for (let Key in this.marks) {
        if (data == 0) {
          if (this.marks[Key].id == 1) {
            delete this.marks[Key];
          }
        } else {
          if (this.marks[Key].id == 2) {
            delete this.marks[Key];
          }
        }
      }
      this.marks = Object.assign({}, this.marks);
    },
    /**
     *  片头片尾 时间点 失去焦点
     * @param data
     */
    inputBlur(data) {
      let timeRegExp = /^([01][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/;
      if (data == 0) {
        if (!this.startTime) {
          return;
        }
        //判断时间格式是否正确的正则表达式
        if (!timeRegExp.test(this.startTime)) {
          this.$messages(
            "warning",
            this.$t("OverwriteOperation.warningmessage1")
          );
          return;
        }
        let time = this.formattedTime(this.startTime);
        if (this.endTime) {
          let time1 = this.formattedTime(this.endTime);
          if (+time >= +time1) {
            this.old_time = this.startTime;
            this.startTime = "";
            for (const marks in this.marks) {
              if (this.marks[marks].id == 1) {
                delete this.marks[marks];
              }
            }
            this.marks = Object.assign({}, this.marks);
            return;
          }
        }
        if (+time > this.silderMax) {
          this.old_time = this.startTime;
          this.startTime = "";

          for (const marks in this.marks) {
            if (this.marks[marks].id == 1) {
              delete this.marks[marks];
            }
          }
          this.marks = Object.assign({}, this.marks);
          this.$messages("warning", this.$t("PersonalEndLanguage.timeMessage"));
          return;
        }
        this.old_time = this.startTime;
        this.TimeIconClick(data, time);
      } else {
        if (!this.endTime) {
          return;
        }
        if (!timeRegExp.test(this.endTime)) {
          this.$messages(
            "warning",
            this.$t("OverwriteOperation.warningmessage1")
          );
          return;
        }
        let time = this.formattedTime(this.endTime);
        if (this.startTime) {
          let time1 = this.formattedTime(this.startTime);
          if (+time <= +time1) {
            this.old_time1 = this.endTime;
            this.endTime = "";
            for (const marks in this.marks) {
              if (this.marks[marks].id == 2) {
                delete this.marks[marks];
              }
            }
            this.marks = Object.assign({}, this.marks);
            return;
          }
        }
        if (+time > this.silderMax) {
          this.old_time1 = this.endTime;
          this.endTime = "";
          for (const marks in this.marks) {
            if (this.marks[marks].id == 2) {
              delete this.marks[marks];
            }
          }
          this.marks = Object.assign({}, this.marks);
          this.$messages("warning", this.$t("PersonalEndLanguage.timeMessage"));
          return;
        }
        this.old_time1 = this.endTime;
        this.TimeIconClick(data, time);
      }
    },
    /**
     * 点击 片头片尾 按钮  获取当前时间 赋值片头片尾时间
     * @param data 0：片头 1：片尾
     * @constructor
     */
    TimeIconClick(data, time) {
      // console.log(this.marks, "this.marks");
      if (data == 0) {
        if (Object.keys(this.marks).length != 0) {
          let obj = {};
          for (let Key in this.marks) {
            if (this.marks[Key].id == 2) {
              obj = this.marks[Key];
            }
          }
          if (time >= obj.time) {
            return;
          }

          for (let Key in this.marks) {
            if (this.marks[Key].id == 1) {
              delete this.marks[Key];
              this.marks[time] = this.setObjData(1, time);
            } else {
              if (this.marks[Key].id == 2) {
                if (time >= this.marks[Key].time) {
                  return;
                }
              }
              this.marks[time] = this.setObjData(1, time);
            }
          }
        } else {
          this.marks[time] = this.setObjData(1, time);
        }
        this.startTime = this.formatTime(time);
        this.marks = Object.assign({}, this.marks);
      } else {
        if (Object.keys(this.marks).length != 0) {
          let obj = {};
          for (let Key in this.marks) {
            if (this.marks[Key].id == 1) {
              obj = this.marks[Key];
            }
          }
          if (time <= obj.time) {
            return;
          }
          for (let Key in this.marks) {
            if (this.marks[Key].id == 2) {
              delete this.marks[Key];
              this.marks[time] = this.setObjData(2, time);
            } else {
              if (this.marks[Key].id == 1) {
                if (time <= this.marks[Key].time) {
                  return;
                }
              }
              this.marks[time] = this.setObjData(2, time);
            }
          }
        } else {
          this.marks[time] = this.setObjData(2, time);
        }
        this.endTime = this.formatTime(time);
        this.marks = Object.assign({}, this.marks);
      }
    },
    /**
     * 往对象中添加 属性值
     * @param id
     */
    setObjData(id, time) {
      return {
        id: id,
        time: time,
        label: this.$createElement("i", {
          class: {
            "el-icon-location": true,
          },
          style: {
            transform: "rotate(180deg)",
            color: id == 1 ? "#98FF6C" : "#FFB655",
          },
        }),
      };
    },
    /**
     * 时间转换
     * @param time
     * @returns {string}
     */
    formatTime(time) {
      const milliseconds = parseInt(time * 1000);
      const date = new Date(milliseconds);
      const hours = date.getUTCHours().toString().padStart(2, "0");
      const minutes = date.getUTCMinutes().toString().padStart(2, "0");
      const seconds = date.getUTCSeconds().toString().padStart(2, "0");
      return `${hours}:${minutes}:${seconds}`;
    },
    /**
     *
     * @param time
     * @returns {string}
     */
    formattedTime(time) {
      const [hours, minutes, seconds] = time.split(":").map(Number);
      const totalSeconds = hours * 3600 + minutes * 60 + seconds;
      return totalSeconds; // 返回总秒数
    },
    /**
     * 初始化 视频
     * @param data
     */
    initVideo() {
      this.$nextTick(() => {
        this.videoDom = document.getElementById("dialogVideo"); // 获取视频dom元素
        var v = this.$refs.videoref;

        this.videoDom.addEventListener("loadedmetadata", () => {
          this.videoWidth = Number(
            (
              this.videoDom.videoWidth /
              Number((this.videoDom.videoHeight / v.clientHeight).toFixed(2))
            ).toFixed(2)
          );
          this.videoHeight = v.clientHeight;
          console.log(this.videoHeight, "this.videoHeight", this.videoWidth);
          this.showVideoClass =
            this.videoDom.videoHeight > this.videoDom.videoWidth;
          this.silderMax = this.videoDom.duration;
          this.videolangtime = TimeHandle(this.videoDom.duration);
          this.endTime = this.formatTime(this.silderMax);
          this.marks[this.silderMax] = this.setObjData(2, this.silderMax);
          this.marks = Object.assign({}, this.marks);
          this.initCanvas(); //创建canvas
          this.sliderChange = false;
        });
        this.videoDom.ontimeupdate = () => {
          // 播放走动时，实时获取播放时间
          this.videoStartTime = TimeHandle(this.videoDom.currentTime);
          this.slidervalue = this.videoDom.currentTime;
        };
        // 播放 结束
        this.videoDom.addEventListener("ended", () => {
          this.player = true;
        });
        this.videoDom.addEventListener("error", () => {
          this.$messages("warning", this.$t("CommonName.videoWarning"));
          this.sliderChange = true;
        });
      });
    },
    /**
     *
     * @param {* 回退} data
     */
    rew() {
      this.videoDom.currentTime = this.videoDom.currentTime - 0.1;
      this.videoStartTime = TimeHandle(this.videoDom.currentTime);
    },
    /**
     *
     * @param {*播放} data
     */
    playvideo() {
      this.player = false;
      this.videoDom.play();
    },
    /**
     *
     * @param {* 暂停播放} data
     */
    closevideo() {
      this.player = true;
      this.videoDom.pause();
    },
    /**
     *
     * @param {* 快进} data
     */
    speek() {
      if (this.videoDom.currentTime == this.videoDom.duration) {
        return;
      }
      this.videoDom.currentTime = this.videoDom.currentTime + 0.1;
      this.videoStartTime = TimeHandle(this.videoDom.currentTime);
    },
    /**
     *
     * @param {* 调整播放速度} command
     */
    handleCommand(command) {
      this.ds = command;
      this.videoDom.playbackRate = command;
    },
    initCanvas() {
      this.canvas = new fabric.Canvas("canvas");
      this.canvas.setWidth(this.videoDom.clientWidth);
      this.canvas.wrapperEl.style.position = "absolute";
      this.createRect();
    },
    createRect(obj = {}, mode = 0) {
      let left = 0;
      let videoDom = {};
      let option = {
        top: 0,
        left: 0,
        width: 0,
        height: 0,
        // fill: "transparent", // 填充色：透明
        fill: "blue", // 填充色：透明
        // fill: "rgba(255,255,255,0.1)",
        stroke: "#46adff", // 边框颜色：黑色
        strokeWidth: 0.5, // 边框宽度
        // strokeUniform: true,
        cornerSize: 5,
        cornerColor: "#fff",
        lockRotation: true,
        strokeDashArray: [5, 7],
      };
      videoDom = this.videoDom;
      if (mode == 1) {
        option.top = obj["top"];
        option.left = obj["left"];
        option.width = obj["width"];
        option.height = obj["height"];
        option.scaleX = obj["scaleX"];
        option.scaleY = obj["scaleY"];
      } else {
        obj = this.base_position;
        if (+obj.position_w + +obj.position_x > videoDom.clientWidth) {
          left = this.canvas.width / 2 - this.canvas.width / 4;
        }
        option.top = Object.values(obj).every((i) => i == "0")
          ? this.canvas.height - 100
          : Number(obj.position_y);
        option.left = Object.values(obj).every((i) => i == "0")
          ? this.canvas.width / 2 - this.canvas.width / 4
          : +obj.position_w + +obj.position_x > videoDom.clientWidth
          ? this.canvas.width / 2 - this.canvas.width / 4
          : Number(obj.position_x);
        option.width = Object.values(obj).every((i) => i == "0")
          ? this.canvas.width / 2
          : +obj.position_w > videoDom.clientWidth
          ? this.canvas.width / 2
          : Number(obj.position_w);
        option.height = Object.values(obj).every((i) => i == "0")
          ? 40
          : Number(obj.position_h);
        // console.log(this.videoDom.clientHeight, this.videoDom.clientWidth);
      }

      // console.log(obj);

      // 矩形对象
      this.rect = new fabric.Rect(option);
      this.canvas.add(this.rect);
      const mask = new fabric.Rect({
        left: 0,
        top: 0,
        width: this.canvas.width,
        height: this.canvas.height,
        // backgroundVpt: false,
        // height: 370,
        fill: "rgba(0,0,0,0.4)",
        // fill: "rgba(255,255,255,0.5)",
        selectable: false,
      });

      // 将矩形添加到画布上

      this.canvas.add(mask);
      // mask.globalCompositeOperation = "source-over";
      // mask.globalCompositeOperation = "destination-out";
      this.rect.globalCompositeOperation = "destination-out";
      // this.rect.globalCompositeOperation = "source-over";
      this.canvas.bringToFront(this.rect);
      this.canvas.setActiveObject(this.rect);
      this.canvas.selection = true; // 允许框选
      this.canvas.selectionColor = "rgba(100, 100, 255, 0.3)"; // 选框填充色：半透明的蓝色
      this.canvas.selectionBorderColor = "rgba(255, 255, 255, 0.3)"; // 选框边框颜色：半透明灰色
      this.canvas.skipTargetFind = false; // 允许选中
      this.canvas.on("selection:cleared", () => {
        this.canvas.setActiveObject(this.rect);
      });
      // 监听 矩形移动事件
      this.canvas.off("object:moving").on("object:moving", (e) => {
        this.isMoveLeft = true;
        this.isMoveTop = true;
        var target = e.target;
        var canvasWidth = this.canvas.width;
        var canvasHeight = this.canvas.height;
        var left = target.left;
        var top = target.top;
        var maxLeft = canvasWidth - target.width * target.scaleX;
        var maxTop = canvasHeight - target.height * target.scaleY;
        if (left < 0) {
          target.left = 3;
        } else if (left > maxLeft) {
          target.left = maxLeft - 3;
        }
        if (top < 0) {
          target.top = 3;
        } else if (top > maxTop) {
          target.top = maxTop - 3;
        }
      });
      this.rect.off("scaling").on("scaling", (e) => {
        var rect = e.transform.target;
        // 获取矩形的当前位置和大小
        var left = rect.left;
        var top = rect.top;
        var width = rect.width * rect.scaleX;
        var height = rect.height * rect.scaleY;
        // 获取画布的尺寸
        var canvasWidth = this.canvas.width;
        var canvasHeight = this.canvas.height;

        // 计算矩形边界
        var right = left + width;
        var bottom = top + height;
        // 检查是否超出画布边界，如果是，则限制大小
        if (height < 10) {
          rect.scaleY = 10 / rect.height;
        }
        if (width < 10) {
          rect.scaleX = 10 / rect.width;
        }
        if (left > 0 && right < canvasWidth) {
          this.isMoveLeft = true;
        }
        if (top > 0 && bottom < canvasHeight) {
          this.isMoveTop = true;
        }
        if (left < 0) {
          rect.left = 3;
          if (this.isMoveLeft) {
            rect.scaleX = (width - 3 + left) / rect.width;
            this.maxScaleX = rect.scaleX;

            this.isMoveLeft = false;
          } else {
            rect.scaleX = this.maxScaleX;
          }
        }
        if (right > canvasWidth) {
          rect.scaleX = (canvasWidth - 5 - left) / rect.width;
        }
        if (top < 0) {
          rect.top = 3;
          if (this.isMoveTop) {
            rect.scaleY = (height - 3 + top) / rect.height;
            this.maxScaleY = rect.scaleY;
            this.isMoveTop = false;
          } else {
            rect.scaleY = this.maxScaleY;
          }
        }
        if (bottom > canvasHeight) {
          rect.scaleY = (canvasHeight - 3 - top) / rect.height;
        }
      });
    },
    // ============================================= 上传所有方法 srt mp4 =================
    // 点击上传文件（srt） 清空已上传文件
    srtupload(refs, data) {
      if (data == "0") {
        this.form.uploadinput4 = "";
      } else if (data == "1") {
        this.form.uploadinput2 = "";
      } else if (data == "2") {
        this.form.uploadinput3 = "";
      } else if (data == "3") {
        this.form.uploadinput5 = "";
      } else {
        this.form.uploadinput1 = "";
      }
      this.languageSrt = data;
      let uploadFilesArr = this.$refs[refs].uploadFiles; //上传字幕文件
      if (uploadFilesArr.length == 0) {
        return;
      } else {
        this.$refs[refs].uploadFiles = [];
      }
    },
    /**
     *
     * @param {*判断是否是 上传的对应后缀文件} file
     */
    srtbeforeUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testmsg.toLowerCase() === "vtt";
      const extension1 = testmsg.toLowerCase() === "srt";
      const extension2 = testmsg.toLowerCase() === "ass";

      if (!extension && !extension1 && !extension2) {
        this.$messages(
          "warning",
          this.$t("ExternalCreateTask.warningmessage1")
        );
        // this.$refs[refs].uploadFiles = [];
        return false;
      } else {
        showLoading(this.$t("CommonName.loadingmessage"));
        return true;
      }
    },
    /**
     *
     * @param {*上传成功code} response
     * @param {*上传成功文件} file
     */
    srtuploadSuccess(response, file) {
      // console.log(response);
      if (response.result == "SUCCESS") {
        // radio2 未更改 以0 开始
        // console.log(response.name);
        if (this.languageSrt == "0") {
          this.form.uploadinput4_show = response.name;
          this.form.uploadinput4 = file.name;
        } else if (this.languageSrt == "1") {
          this.form.uploadinput2_show = response.name;
          this.form.uploadinput2 = file.name;
        } else {
          this.form.uploadinput3_show = response.name;
          this.form.uploadinput3 = file.name;
        }
        hideLoading();
      } else if (response.result == "LOGIN_FAILED") {
        this.$messages("warning", this.$t("CommonName.warningmessage4"));
        this.$router.push("/Login");
        this.$Cookie.remove("token_code");
        sessionStorage.clear();
        hideLoading();
      } else {
        this.$messages("error", response.msg);
        hideLoading();
      }
    },
    /**
     *
     * @param {*判断是否是 上传的对应后缀文件} file
     */
    txtbeforeUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testmsg.toLowerCase() === "txt";

      if (!extension) {
        this.$messages(
          "warning",
          this.$t("ExternalCreateTask.warningmessage7")
        );
        // this.$refs[refs].uploadFiles = [];
        return false;
      } else {
        showLoading(this.$t("CommonName.loadingmessage"));
        return true;
      }
    },
    /**
     *
     * @param {*上传成功code} response
     * @param {*上传成功文件} file
     */
    txtuploadSuccess(response, file) {
      // console.log(response);
      if (response.result == "SUCCESS") {
        // radio2 未更改 以0 开始
        // console.log(response.name);
        this.form.uploadinput5_show = response.name;
        this.form.uploadinput5 = file.name;
        hideLoading();
      } else if (response.result == "LOGIN_FAILED") {
        this.$messages("warning", this.$t("CommonName.warningmessage4"));
        this.$router.push("/Login");
        this.$Cookie.remove("token_code");
        sessionStorage.clear();
        hideLoading();
      } else {
        this.$messages("error", response.msg);
        hideLoading();
      }
    },
    /**
     *
     * @param {*上传视频 改变视频} file
     */
    uploadChange(file) {
      if (!file) return;
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      let arr = [
        "aac",
        "ac3",
        "ape",
        "flac",
        "m4a",
        "mp3",
        "ogg",
        "wav",
        "wma",
        "mp4",
        "webm",
      ];
      const extension = testmsg.toLowerCase();
      const isLt2M = file.size / 1024 / 1024 / 1024 < 2;
      if (!arr.includes(extension)) {
        this.$messages(
          "warning",
          this.$t("ExternalCreateTask.warningmessage2")
        );
        this.form.uploadinput1 = "";
        this.$refs.upload_media_file.clearFiles();
        return false;
      } else if (!isLt2M) {
        this.$messages(
          "warning",
          this.$t("ExternalCreateTask.warningmessage3")
        );
        this.form.uploadinput1 = "";
        this.$refs.upload_media_file.clearFiles();
        return false;
      } else {
        this.form.uploadinput1 = file.name;
        this.form.file = file;
        return true;
      }
    },
    /**
     *
     * @param {*上传过程中} file
     */
    uploadProgress(file) {
      if (file.percent > 0) {
        this.percentage = Number(file.percent.toFixed());
        this.loading = true;
      }
      if (file.percent == 100) {
        this.percentage = 0;
        this.loading = false;
        showLoading(this.$t("CommonName.warningmessage6"));
      }
    },
    /**
     *
     * @param {*上传成功} response
     * @param {*} file
     */
    uploadSuccess(response, file) {
      this.loadingSure = false;
      if (response.result == "SUCCESS") {
        this.dialogVisible = false;
        this.$emit("Creationcompleted", this.dialogVisible);
        // hideLoading();
      } else if (response.result == "LOGIN_FAILED") {
        this.percentage = 0;
        this.loading = false;
        hideLoading();
        this.$messages("warning", this.$t("CommonName.warningmessage4"));
        this.$router.push("/Login");
        this.$Cookie.remove("token_code");
        sessionStorage.clear();
      } else {
        this.percentage = 0;
        this.loading = false;
        hideLoading();
        this.$messages("error", response.msg);
      }
    },
    /**
     *
     * @param {*上传失败} response
     * @param {*} file
     */
    uploadError(err, file, fileList) {
      this.loadingSure = false;
    },
    // =============================================通用方法===============================
    /**
     *
     * @param {* 设置Task Workflow 任务类型 与 Preprocess Options 传输类型 得值 } data
     */
    setRadio1andRadio2(data) {
      let obj = {};
      obj.a = this.radio1;
      obj.b = this.radio2;
      obj.b = String(+obj.b + 1);
      obj.a = String(+obj.a + 1);
      if (obj.a == "2") {
        obj.a = "3";
      } else if (obj.a == "3") {
        obj.a = "2";
      }
      return obj;
    },
    /**
     *
     * @param {*设置 创建任务 所需上传的数据} item
     */
    setUploadData(item) {
      if (this.radio2 == "4") {
        let num = this.videoHeight / 360;
        var x = Math.ceil(this.rect.left * num);
        var y = Math.ceil(this.rect.top * num);
        var width = Math.ceil(this.rect.width * this.rect.scaleX * num);
        var height = Math.ceil(this.rect.height * this.rect.scaleY * num);
      }
      let obj = {
        action: "create_job_file",
        project_id: this.project_id,
        slang: this.select1,
        tlang: ["2", "3", "4", "5", "7"].includes(item.a)
          ? this.select2.join(",")
          : "",
        task_type: item.a, //	任务类型，1转写、2翻译、3转写+翻译，4翻译+校审，5转写+翻译+校审，6转写+SQT
        // job_name: "", //this.form.radio=>1,2 时 为空
        transfer_type: item.b, //1自动，2单语，3双语，4双srt文件 一个原文一个译文
        file_mode_type: item.b == "3" ? this.radio4 : "", //0目标语言在上, 1原语言在上  radio3->3 时选
        oss_mode_type: this.form.radio == "0" ? this.form.select1.id : "", //radio3->3 时选
        asr_mode_type: item.b == "1" ? this.radio3 : "",
        source_media_type: this.form.radio, //	源文件来源方式0 上传,1 借调， 2 本地
        // media_file: "", //this.form.radio=>1,2 时 为空"
        tm_list: JSON.stringify(this.selectTM), //tm集合
        date_dead_stt: ["1", "3", "5", "6", "7"].includes(item.a)
          ? this.form.time1
          : "", //转写预计交付时间
        date_dead_tra: ["2", "3", "4", "5", "7"].includes(item.a)
          ? this.form.time2
          : "", //翻译预计交付时间 按照任务类型 没有该节点就传空
        date_dead_rev: ["4", "5", "7"].includes(item.a) ? this.form.time3 : "", //校审预计交付时间 按照任务类型 没有该节点就传空
        user_stt: ["1", "3", "5", "6", "7"].includes(item.a)
          ? this.form.select2
          : "", //转写任务执行人id
        user_tra: ["2", "3", "4", "5", "7"].includes(item.a)
          ? this.form.select3
          : "", //翻译任务执行人id
        user_rev: ["4", "5", "7"].includes(item.a) ? this.form.select4 : "", //	校对任务执行人id
        date_dead_sqt: ["6", "7"].includes(item.a) ? this.form.time4 : "", //SQT预计交付时间 按照任务类型 没有该节点就传空
        user_sqt: ["6", "7"].includes(item.a) ? this.form.select10 : "", //	SQT任务执行人id
        srt_name: ["2", "3"].includes(item.b)
          ? this.form.uploadinput4_show
          : item.b == "6"
          ? this.form.uploadinput5_show
          : "", //上传srt文件名-服务器返回路径
        srt_show_name: ["2", "3"].includes(item.b)
          ? this.form.uploadinput4
          : item.b == "6"
          ? this.form.uploadinput5
          : "", //上传srt文件名
        srt_source_name: item.b == "4" ? this.form.uploadinput2_show : "", //双srt文件时 传上传原文srt文件名-服务器返回路径
        srt_source_show_name: item.b == "4" ? this.form.uploadinput2 : "", //双srt文件时 传上传原文srt文件名
        srt_target_name: item.b == "4" ? this.form.uploadinput3_show : "", //双srt文件时 传上传译文srt文件名-服务器返回路径
        srt_target_show_name: item.b == "4" ? this.form.uploadinput3 : "", //双srt文件时 传上传译文srt文件名
        select_pid: this.form.radio == "1" ? this.form.select8 : "", //源文件来源方式选择1时 选择的项目id 其他情况传空
        select_jid: this.form.radio == "1" ? this.form.select9 : "", //源文件来源方式选择1时 选择的任务id 其他情况传空
        new_job_name: this.form.newjobname, //借调任务时，需填写新任务名
        ocr_mode: this.radio2 == "4" ? 1 : 0, //是否硬字幕识别，0语音转写，1字幕识别
        position_x:
          this.radio2 == "4" && this.radio5 == "1"
            ? this.target_data.rect.left
            : "",
        position_y:
          this.radio2 == "4" && this.radio5 == "1"
            ? this.target_data.rect.top
            : "",
        position_w:
          this.radio2 == "4" && this.radio5 == "1"
            ? this.target_data.rect.width * this.target_data.rect.scaleX
            : "",
        position_h:
          this.radio2 == "4" && this.radio5 == "1"
            ? this.target_data.rect.height * this.target_data.rect.scaleY
            : "",
        t_time_start: this.target_data.startTime
          ? this.target_data.startTime
          : "",
        t_time_end: this.target_data.endTime
          ? this.target_data.endTime
          : this.formatTime(this.silderMax),
        position_original_x:
          this.radio2 == "4" ? this.source_data.rect.left : "",
        position_original_y:
          this.radio2 == "4" ? this.source_data.rect.top : "",
        position_original_w:
          this.radio2 == "4"
            ? this.source_data.rect.width * this.source_data.rect.scaleX
            : "",
        position_original_h:
          this.radio2 == "4"
            ? this.source_data.rect.height * this.source_data.rect.scaleY
            : "",
        time_start: this.source_data.startTime
          ? this.source_data.startTime
          : "",
        time_end: this.source_data.endTime
          ? this.source_data.endTime
          : this.formatTime(this.silderMax),
        original_width: this.videoWidth,
        original_height: this.videoHeight,
        ait_mode: this.AITCheck ? 1 : 0,
        ait_type: this.radio6,
        ocr_type: this.radio5 == "1" ? 2 : 1, //硬字幕识别选项：1：单原文 ；2：原文与译文
      };
      return obj;
    },
    // ！===========================================其他方法==============================
    /**
     *
     * @param {*第二页 单选 展示页面} radio
     */
    radiochange(radio) {
      // console.log(radio, "radio");
      if (radio == "1") {
        this.get_import_project_list();
      }
    },

    /**
     * select8，9 通过选择 显示
     */
    select8Change() {
      this.select9Disabled = false;
      if (this.form.select8) {
        this.get_import_project_job_list(this.form.select8);
      }
    },
    /**
     * select9
     */
    select9Change(obj) {
      console.log(obj, "select9Change");
      this.form.file = obj.video_path;
      this.form.newjobname = obj.job_name;
    },
    /**
     * 关闭弹窗
     */
    closeDialog() {
      this.dialogVisible = false;
      this.$emit("claseCreateTaskDialog", this.dialogVisible);
    },
    /**
     *
     * @param {*确定按钮-------------------} data
     */
    determineClick(data) {
      let obj = this.setRadio1andRadio2(); //a=>task_type b=>transfer_type
      // 判断  视频是否上传
      if (this.form.radio == "0") {
        if (!this.form.uploadinput1) {
          this.form.uploadinput1 = null;
          this.$messages("warning", this.$t("CommonName.selectMessage"));
          return;
        }
      }
      // 判断 来源方式为 借调 时 是否选择了 所借调的 文件
      if (this.form.radio == "1") {
        if (!this.form.select9) {
          this.form.select9 = null;
          this.$messages("warning", this.$t("CommonName.selectMessage"));
          return;
        }
        if (this.form.newjobname == null || this.form.newjobname.trim() == "") {
          this.form.newjobname = null;
          this.$messages("warning", this.$t("CommonName.selectMessage2"));
          return;
        }
      }
      // 判断转写 时间 是否填写
      if (["1", "3", "5", "7"].includes(obj.a)) {
        if (!this.form.time1) {
          this.$messages(
            "warning",
            this.$t("ExternalCreateTask.warningmessage5")
          );
          return;
        }
      }
      // 判断翻译时间 是否填写
      if (["2", "3", "4", "5", "7"].includes(obj.a)) {
        if (!this.form.time2) {
          this.$messages(
            "warning",
            this.$t("ExternalCreateTask.warningmessage5")
          );
          return;
        }
      }
      // 判断 校对时间 是否填写
      if (["4", "5", "7"].includes(obj.a)) {
        if (!this.form.time3) {
          this.$messages(
            "warning",
            this.$t("ExternalCreateTask.warningmessage5")
          );
          return;
        }
      }
      // 判断 SQT时间 是否填写
      if (["6", "7"].includes(obj.a)) {
        if (!this.form.time4) {
          this.$messages(
            "warning",
            this.$t("ExternalCreateTask.warningmessage5")
          );
          return;
        }
      }
      // 未上传 srt 文件
      if (["2", "3"].includes(obj.b)) {
        if (!this.form.uploadinput4 || !this.form.uploadinput4_show) {
          this.$messages(
            "warning",
            this.$t("ExternalCreateTask.warningmessage6")
          );
          return;
        }
      }
      // 上传 源语言目标语言 srt 文件
      if (obj.b == "4") {
        if (
          !this.form.uploadinput2 ||
          !this.form.uploadinput2_show ||
          !this.form.uploadinput3 ||
          !this.form.uploadinput3_show
        ) {
          this.$messages(
            "warning",
            this.$t("ExternalCreateTask.warningmessage6")
          );
          return;
        }
      }
      if (obj.b == "5") {
        let timeRegExp = /^([01][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/;
        if (this.startTime && !timeRegExp.test(this.startTime)) {
          return;
        }
        if (this.endTime && !timeRegExp.test(this.endTime)) {
          return;
        }
        let startTime = this.formattedTime(this.old_time);
        let endTime = this.formattedTime(this.old_time1);

        if (startTime && endTime) {
          if (startTime >= endTime) {
            // this.old_time = "";
            // this.old_time1 = "";
            this.$messages(
              "warning",
              this.$t("PersonalEndLanguage.timeMessage1")
            );
            return;
          }
        }

        if (startTime > this.silderMax) {
          this.old_time = "";
          return;
        }

        if (endTime > this.silderMax) {
          this.old_time1 = "";
          return;
        }
      }
      // 未上传 txt 文件
      if (obj.b == "6") {
        if (!this.form.uploadinput5 || !this.form.uploadinput5_show) {
          this.$messages(
            "warning",
            this.$t("ExternalCreateTask.warningmessage6")
          );
          return;
        }
      }
      this.loadingSure = true;
      if (this.step == "3") {
        //将原文数据储存至 source_data
        let source_data = {
          slidervalue: this.slidervalue,
          startTime: this.startTime,
          endTime: this.endTime,
          marks: this.marks,
          rect: this.rect,
        };
        this.source_data = $.extend(true, {}, source_data);
      } else if (this.step == "4") {
        //将译文数据储存至 source_data
        let target_data = {
          videoHeight: this.videoHeight,
          videoWidth: this.videoWidth,
          slidervalue: this.slidervalue,
          startTime: this.startTime,
          endTime: this.endTime,
          marks: this.marks,
          rect: this.rect,
          canvas: this.canvas,
        };
        this.target_data = $.extend(true, {}, target_data);
      }
      // 判断 上传 方式
      if (this.form.radio == "0") {
        this.uploadData = this.setUploadData(obj);
        this.uploadData.job_name = this.form.uploadinput1;
        this.$nextTick(() => {
          this.$refs.upload_media_file.submit();
        });
      } else {
        let newObj = this.setUploadData(obj);
        newObj.job_name = "";
        newObj.media_file = "";
        showLoading(this.$t("ExternalCreateTask.warningmessage4"));
        this.create_job_file(newObj);
      }
    },
    /**
     * 点击 下一步
     */
    NextClick() {
      if (this.step == "1") {
        this.select1 = this.select1 || null;
        if (this.radio1 !== "0" && this.radio1 !== "5") {
          this.select2_error = this.select2.length > 0 ? "1" : "0";
        }
        if (this.radio1 == "-1") {
          this.$messages("warning", this.$t("CommonName.selectMessage"));
          return;
        }
        if (this.radio2 == "-1") {
          this.$messages("warning", this.$t("CommonName.selectMessage"));
          return;
        }
        if (this.select2.length > 1 && ["0", "2", "3"].includes(this.radio2)) {
          this.$messages("warning", this.$t("CommonName.selectMessage3"), 3000);
          return;
        }
        if (this.radio1 !== "0" && this.radio1 !== "5") {
          if (!this.select1 || this.select2.length == 0) {
            this.$messages("warning", this.$t("CommonName.selectMessage"));
            return;
          }
          if (this.select2.includes(this.select1)) {
            this.$messages("warning", this.$t("CommonName.selectMessage1"));
            return;
          }
        } else {
          if (!this.select1) {
            this.$messages("warning", this.$t("CommonName.selectMessage"));
            return;
          }
        }
        // 当选项选成自动语音识别 与硬字幕识别时 不提供本地
        if (this.radio2 == "0" || this.radio2 == "4") {
          this.radioList = [
            {
              label: "0",
              name: this.$t("ExternalCreateTask.Sourceofmediafile.label0"),
            },
            {
              label: "1",
              name: this.$t("ExternalCreateTask.Sourceofmediafile.label1"),
            },
          ];
        } else {
          this.radioList = [
            {
              label: "0",
              name: this.$t("ExternalCreateTask.Sourceofmediafile.label0"),
            },
            {
              label: "1",
              name: this.$t("ExternalCreateTask.Sourceofmediafile.label1"),
            },
            {
              label: "2",
              name: this.$t("ExternalCreateTask.Sourceofmediafile.label2"),
            },
          ];
        }
        this.step = "2";
        if (this.radio2 == "4") {
          this.title = this.$t("ExternalCreateTask.title3");
        } else {
          this.title = this.$t("CommonName.title2");
        }
        this.form.radio = "0"; //初始 选择 上传方式

        this.selectTM = [];
        if (this.select2.length > 0) {
          this.select2.forEach((item) => {
            const targetItem = this.languageoptions.find(
              (item1) => item1.language_id === item
            );
            if (targetItem) {
              this.selectTM.push({
                lang_id: item,
                t: "",
                e: "",
                lang_name: targetItem.cname,
              });
            }
          });
        }

        // console.log(this.selectTM);
        this.form.time1 = getCurrentTime("0");
        this.form.time2 = getCurrentTime("1");
        this.form.time3 = getCurrentTime("2");
        this.form.time4 = getCurrentTime("1");
        // 获取成员列表信息
        this.get_member_list(2);
        //想选择TM需要目标语言只选一种
        if (this.radio1 !== "0" && this.radio1 !== "5") {
          this.get_tm_list("2");
        }
        if (this.DetermineClickCreateTask == "2") {
          this.select1List.map((i) => {
            if (+this.Clonejobinfo.oss_mode == i.id) {
              this.form.select1 = i;
            }
          });
        }
        // console.log("***", this.radio2);
        if (["1", "2"].includes(this.radio2)) {
          this.showOneupload = "1";
        } else if (["3"].includes(this.radio2)) {
          this.showOneupload = "2";
        } else if (["5"].includes(this.radio2)) {
          this.showOneupload = "3";
        } else if (this.radio2 == "0") {
          this.showOneupload = "0";
        }
      } else if (this.step == "2") {
        if (this.form.radio == "0") {
          if (!this.form.uploadinput1) {
            this.$messages("warning", this.$t("CommonName.warningmessage5"));
            return;
          }
        } else {
          // console.log(this.form);
          if (!this.form.file) {
            this.$messages("warning", this.$t("CommonName.selectMessage"));
            return;
          }
        }
        this.source_data = {};
        this.target_data = {};
        if (this.source_data.rect != undefined) {
          this.step = "3";
          if (this.radio5 == "1") {
            this.title = this.$t("ExternalCreateTask.title7");
          } else {
            this.title = this.$t("ExternalCreateTask.title4");
          }

          //将原文数据还原
          let source_data = $.extend(true, {}, this.source_data);
          this.slidervalue = source_data.slidervalue;
          this.startTime = source_data.startTime;
          this.endTime = source_data.endTime;
          this.marks = source_data.marks;
          this.rect = source_data.rect;
          this.canvas.clear();
          //重新画一个rect
          this.createRect(this.rect, 1);
        } else {
          showLoading(this.$t("PersonalEndLanguage.loadingMessage"));
          get_ocr_last_job({ action: "get_ocr_last_job" }).then((res) => {
            if (res) {
              this.step = "3";
              if (this.radio5 == "1") {
                this.title = this.$t("ExternalCreateTask.title7");
              } else {
                this.title = this.$t("ExternalCreateTask.title4");
              }

              this.videoHeight = 0;
              this.videoWidth = 0;
              this.slidervalue = 0;
              this.startTime = "";
              this.endTime = "";
              this.marks = {};
              this.rect = null;
              this.canvas = null;
              if (this.form.radio == "1") {
                this.videoUrl = this.form.file;
              } else {
                this.videoUrl = URL.createObjectURL(this.form.file.raw);
              }
              // console.log(this.videoUrl, " this.videoUrl");
              this.base_position = res.position;
              this.initVideo();
              hideLoading();
            }
          });
        }
      } else if (this.step == "3") {
        this.closevideo();
        if (this.form.radio == "0") {
          if (!this.form.uploadinput1) {
            this.$messages("warning", this.$t("CommonName.warningmessage5"));
            return;
          }
        } else {
          // console.log(this.form);
          if (!this.form.file) {
            this.$messages("warning", this.$t("CommonName.selectMessage"));
            return;
          }
        }
        //将原文数据储存至 source_data
        let source_data = {
          slidervalue: this.slidervalue,
          startTime: this.startTime,
          endTime: this.endTime,
          marks: this.marks,
          rect: this.rect,
        };
        this.source_data = $.extend(true, {}, source_data);
        this.canvas.clear();
        console.log("source_data", this.source_data);
        this.step = "4";
        this.title = this.$t("ExternalCreateTask.title8");
        if (this.target_data.rect != undefined) {
          //将译文数据还原
          let target_data = $.extend(true, {}, this.target_data);
          this.slidervalue = target_data.slidervalue;
          this.startTime = target_data.startTime;
          this.endTime = target_data.endTime;
          this.marks = target_data.marks;
          this.rect = target_data.rect;
          this.videoDom.currentTime = this.slidervalue;
          //重新画一个rect
          this.createRect(this.rect, 1);
        } else {
          this.slidervalue = 0;
          this.rect = null;
          this.startTime = "";
          this.marks = {};
          this.endTime = this.formatTime(this.silderMax);
          this.marks[this.silderMax] = this.setObjData(2, this.silderMax);
          this.marks = Object.assign({}, this.marks);
          this.videoDom.currentTime = this.slidervalue;
          //重新画一个rect
          this.createRect();
        }
      }
    },
    /**
     * 上一步
     */
    PreviousClick() {
      if (this.step == "3") {
        this.closevideo();
        this.step = "2";
        if (this.radio2 == "4") {
          if (this.radio5 == "1") {
            this.title = this.$t("ExternalCreateTask.title6");
          } else {
            this.title = this.$t("ExternalCreateTask.title3");
          }
          //将原文数据储存至 source_data
          let source_data = {
            slidervalue: this.slidervalue,
            startTime: this.startTime,
            endTime: this.endTime,
            marks: this.marks,
            rect: this.rect,
          };
          this.source_data = $.extend(true, {}, source_data);
        } else {
          this.title = this.$t("CommonName.title2");
        }
      } else if (this.step == "4") {
        this.closevideo();
        //将译文数据储存至 target_data
        let target_data = {
          slidervalue: this.slidervalue,
          startTime: this.startTime,
          endTime: this.endTime,
          marks: this.marks,
          rect: this.rect,
        };
        this.target_data = $.extend(true, {}, target_data);
        console.log("target_data", this.target_data);
        //将原文数据还原
        let source_data = $.extend(true, {}, this.source_data);
        this.slidervalue = source_data.slidervalue;
        this.startTime = source_data.startTime;
        this.endTime = source_data.endTime;
        this.marks = source_data.marks;
        this.rect = source_data.rect;
        this.videoDom.currentTime = this.slidervalue;
        this.canvas.clear();
        //重新画一个rect
        this.createRect(this.rect, 1);
        this.step = "3";
        this.title = this.$t("ExternalCreateTask.title7");
      } else if (this.step == "2") {
        this.step = "1";
        if (this.radio2 == "4") {
          if (this.radio5 == "1") {
            this.title = this.$t("ExternalCreateTask.title5");
          } else {
            this.title = this.$t("ExternalCreateTask.title2");
          }
        } else {
          this.title = this.$t("ExternalCreateTask.title1");
        }
      }

      // this.form.uploadinput4 = "";
      // this.form.uploadinput2 = "";
      // this.form.uploadinput3 = "";
      // this.form.uploadinput1 = "";
    },
    /**
     *
     * @param {*左侧单选框} val
     */
    radiochange1(val) {
      // console.log(val, "val");
      // this.select2 = "";
      let a = this.radioList2;
      a.forEach((i) => {
        if (val == "0" || val == "5") {
          this.radio2 = "-1";
          this.radio5 = "0";
          if (i.label == "2" || i.label == "3") {
            i.disabled = true;
          } else {
            i.disabled = false;
          }
        } else if (val == "2" || val == "3") {
          if (this.radio2 == "0" || this.radio2 == "4" || this.radio2 == "5") {
            this.radio2 = "-1";
          }
          if (i.label == "0" || i.label == "4" || i.label == "5") {
            i.disabled = true;
          } else {
            i.disabled = false;
          }
        } else if (val == "1" || val == "4" || val == "6") {
          // this.radio2 = "0";
          this.radio2 = "-1";
          // console.log(1111111);
          // if (!["2", "1", "13", "25"].includes(this.select1)) {
          //   if (i.label == "4") {
          //     if (this.select1) {
          //       i.disabled = true;
          //     }
          //   } else {
          //     i.disabled = false;
          //   }
          // } else {
          i.disabled = false;
          // }
        }
      });
      this.radioList2 = a;
      if (val == "0") {
        let arr = JSON.parse(JSON.stringify(this.languageoptions));
        this.languageObj.selectLanguage1 = arr;
        this.select2 = [];
      }
      // this.form.radio = "0";
    },
    /**
     * 第一步 右侧 点击 选项 选择 硬字幕识别
     * @param val
     */
    radiochange2(val) {
      if (val == "4") {
        if (["1", "4", "6"].includes(this.radio1) && this.radio5 == "1") {
          this.title = this.$t("ExternalCreateTask.title5");
        } else {
          this.title = this.$t("ExternalCreateTask.title2");
        }
        this.languageObj.selectLanguage1 = this.setLanguage();
        if (!this.HardlanguageList.includes(this.select1)) {
          this.select1 = "";
        }
      } else if (val == "5") {
        this.title = this.$t("ExternalCreateTask.title1");
        this.languageObj.selectLanguage1 = this.setsubLanguage();
        if (!this.SubtitleAlignlanguageList.includes(this.select1)) {
          this.select1 = "";
        }
      } else {
        if (val == "3") {
          this.AITCheck = false;
        }
        this.languageObj.selectLanguage1 = this.languageoptions;
        this.title = this.$t("ExternalCreateTask.title1");
      }
    },
    /**
     * 过滤 硬字幕识别 源语言
     * @returns {*[]}
     */
    setLanguage() {
      return this.languageoptions.filter((i) => {
        return this.HardlanguageList.includes(i.language_id);
      });
    },
    /**
     * 过滤 字幕对齐 源语言
     * @returns {*[]}
     */
    setsubLanguage() {
      return this.languageoptions.filter((i) => {
        return this.SubtitleAlignlanguageList.includes(i.language_id);
      });
    },
    /**
     *
     * @param {*右侧单选框} val
     */
    radiochange3(val) {
      console.log(val);
    },
    /**
     *
     * @param {*右侧单选框} val
     */
    radiochange5(val) {
      if (val == "1") {
        this.title = this.$t("ExternalCreateTask.title5");
      } else {
        this.title = this.$t("ExternalCreateTask.title2");
      }
      console.log(val);
    },
    /**
     *
     * @param {* 源语言 下拉选 语言 与目标语言 互斥} select
     */
    changeselectLanguage1(select) {
      // var obj = this.radioList2.find((j) => j.label == "4");
      // if (!["2", "1", "13", "25"].includes(select)) {
      //   obj.disabled = true;
      //   if (this.radio2 == "4") {
      //     this.radio2 = "-1";
      //   }
      // } else {
      //   obj.disabled = false;
      // }
      let arr = JSON.parse(JSON.stringify(this.languageoptions));
      this.languageObj.selectLanguage2 = arr.filter(
        (i) => i.language_id !== select
      );
      // console.log(select, "select1");
    },
    /**
     *
     * @param {*目标语言 下拉选 语言 与源语言 互斥} select
     */
    changeselectLanguage2(select) {
      this.select2_error = "1";
      let arr = JSON.parse(JSON.stringify(this.languageoptions));
      //硬字幕识别需要筛选源语言
      if (this.radio2 == "4") {
        arr = arr.filter((i) => {
          return this.HardlanguageList.includes(i.language_id);
        });
      }
      //字幕对齐需要筛选源语言
      if (this.radio2 == "5") {
        arr = arr.filter((i) => {
          return this.SubtitleAlignlanguageList.includes(i.language_id);
        });
      }
      this.languageObj.selectLanguage1 = arr.filter(
        (i) => !select.includes(i.language_id)
      );
    },
  },
  computed: {
    setVideoHeight() {
      let video = null;
      let videoHeight = 0;
      let videoWidth = 0;
      video = document.getElementById("dialogVideo");
      return videoHeight > videoWidth;
    },
  },
  mounted() {
    this.get_language();
    this.get_oss_list();
    this.info = JSON.parse(sessionStorage.getItem("info"));
    // 第二页
    if (this.DetermineClickCreateTask == "2") {
      console.log(this.Clonejobinfo);
      let a = "";
      if (this.Clonejobinfo.job_type == "3") {
        a = "2";
      } else if (this.Clonejobinfo.job_type == "2") {
        a = "3";
      } else {
        a = this.Clonejobinfo.job_type;
      }
      this.radio1 = String(+a - 1);
      // console.log(this.radio1);
      this.select1 = this.Clonejobinfo.slang_id;
      this.select2 =
        this.Clonejobinfo.tlang_id !== "0" ? [this.Clonejobinfo.tlang_id] : ""; //目标语言
    }
    this.project_id = this.$route.query.id;
    this.dialogVisible = this.showdialog;
    // 第一页
    this.radioList2.forEach((i) => {
      if (this.radio1 == "0" || this.radio1 == "5") {
        if (i.label == "2" || i.label == "3") {
          i.disabled = true;
        }
      }
    });
    window.addEventListener("keydown", this.windowkeydown, true);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.windowkeydown, true);
  },
};
</script>
<style scoped lang="scss">
@import "./AllDialogradio.scss";

.step1Center {
  margin: 4px 24px 24px 24px;

  p {
    color: #adb0bc;
    font-size: 14px;
    margin-top: 20px;
  }

  .el-radio.is-bordered.is-disabled {
    border-color: #5b5d6c;
  }

  ::v-deep .el-radio__input.is-disabled + span.el-radio__label {
    color: #595d69 !important;
  }

  ::v-deep .el-radio__input.is-disabled .el-radio__inner {
    background: none;
    border-color: #595d69;
  }

  .step1Center_top {
    display: flex;

    .step1Center_left {
      .el-radio-button__inner,
      .el-radio-group {
        display: flex;
        flex-direction: column;
      }

      .el-radio.is-bordered {
        width: 400px;
        margin-top: 6px;
      }
    }

    .step1Center_right {
      margin-left: 40px;

      .el-radio-button__inner,
      .el-radio-group {
        display: flex;
        flex-direction: column;
      }

      .el-radio.is-bordered {
        width: 400px;
        margin-top: 6px;
      }
    }
  }

  .step1Center_center {
    .el-radio.is-bordered {
      width: 210px;
      margin-top: 6px;
    }
  }

  .step1Center_bottom {
    display: flex;

    .step1Center_right {
      margin-left: 40px;
    }
  }
}

.selectclass {
  margin-top: 6px;

  ::v-deep .el-input__inner {
    width: 400px !important;
  }

  ::v-deep .el-input__icon {
    line-height: 32px;
  }
}

.step2Center {
  margin: 4px 24px 24px 24px;
}

.content {
  .content_1 {
    .el-radio.is-bordered {
      width: 200px;
      margin-top: 6px;
    }
  }

  .content_center_1 {
    .uploadp {
      display: flex;
      align-items: center;

      .icon {
        color: #adb0bc;
        margin-top: 20px;
        margin-left: 6px;
      }
    }
  }

  p {
    color: #adb0bc;
    font-size: 14px;
    margin-top: 20px;
  }

  .langselectclass {
    margin-top: 6px;

    ::v-deep .el-input__inner {
      width: 840px !important;
    }

    ::v-deep .el-input.is-disabled .el-input__inner {
      background-color: #404253 !important;
      border-color: #4a4c5d !important;
    }

    ::v-deep .el-input.is-disabled .el-input__icon {
      color: #4a4c5d !important;
    }
  }
}
</style>
