<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    :width="'1100px'"
    :top="'3%'"
    center
    :close-on-click-modal="false"
    @close="closeDialog"
  >
    <el-dialog
      width="30%"
      :title="innerTitle"
      :visible.sync="innerVisible"
      append-to-body
      :close-on-click-modal="false"
      :top="'10%'"
      @close="closeinnerVisible"
      center
      v-if="innerVisible"
    >
      <div class="dialogContent innerDialog_class">
        <template v-if="buttonId != 7">
          <p class="innerDialog_class_p">
            {{ innerTitle1 }}
          </p>
          <el-input
            v-model="innerInput"
            :placeholder="$t('OverwriteOperation.placeholder1')"
            type="text"
            :maxlength="20"
          ></el-input>
          <div
            v-if="buttonId == 3"
            style="color: #adb0bc; font-size: 14px; margin-top: 6px"
          >
            {{ $t("VideoDubbingClone.dubText74") }}
          </div>
        </template>
        <div
          v-else
          class="content_bottom borderTableStyle tableClass"
          :class="logTableData.length == 0 ? 'tablebtmborder' : ''"
        >
          <el-table
            :row-style="{
              height: 52 + 'px',
              background: '#393c4e',
              color: '#cbd7e8',
            }"
            :cell-style="{ padding: 0 + 'px' }"
            :header-cell-style="{
              height: 40 + 'px',
              padding: '0px',
              color: '#adb0bc',
              background: '#454758',
              fontSize: '12px',
            }"
            :data="logTableData"
            tooltip-effect="dark"
            style="width: 100%; overflow-y: auto; border-right: 1px solid"
            max-height="500px"
            border
          >
            <el-table-column
              prop="log_name"
              width="135"
              :label="$t('VideoDubbingClone.dubText73')"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column :label="$t('VideoDubbingClone.dubText75')">
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.job_ids"
                  multiple
                  collapse-tags
                  class="selectclass"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.job_dubbing_id"
                    :label="item.name"
                    :value="item.job_dubbing_id"
                    :disabled="true"
                    v-show="scope.row.job_ids.includes(item.job_dubbing_id)"
                  >
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div slot="footer" class="dialog-footer" style="margin-top: 24px">
        <el-button @click="closeinnerVisible" type="info" class="setpadding23"
          ><p class="footerleftbuttonp">
            {{ $t("CommonName.Cancel") }}
          </p></el-button
        >
        <el-button type="primary" @click="AddWords" class="setpadding23">
          <p class="footerrightbuttonp2" style="">
            {{ $t("toolset.confirm") }}
          </p>
        </el-button>
      </div>
    </el-dialog>
    <div class="dialogContent">
      <div class="content_top">
        <el-button
          v-for="item in topButton"
          :key="item.id"
          type="text"
          @click="handleClick(item.id)"
        >
          {{ item.name }}
        </el-button>
      </div>
      <div
        class="content_bottom borderTableStyle tableClass"
        :class="tableData.length == 0 ? 'tablebtmborder' : ''"
      >
        <el-table
          :row-style="{
            height: 52 + 'px',
            background: '#393c4e',
            color: '#cbd7e8',
          }"
          :cell-style="{ padding: 0 + 'px' }"
          :header-cell-style="{
            height: 40 + 'px',
            padding: '0px',
            color: '#adb0bc',
            background: '#454758',
            fontSize: '12px',
          }"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%; overflow-y: auto; border-right: 1px solid"
          max-height="500px"
          @selection-change="handleSelectionChange"
          border
        >
          <el-table-column type="selection" width="39"></el-table-column>
          <!-- <p>角色名称</p> -->
          <el-table-column
            prop="role_name"
            width="156"
            :label="$t('VideoDubbingClone.dubText22')"
          >
            <template slot-scope="scope">
              <div v-if="!scope.row.inputEditing" class="role_name_class">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="scope.row.role_name"
                  :enterable="false"
                  :visible-arrow="false"
                  placement="top"
                >
                  <p class="overflowEllipsis">
                    {{ scope.row.role_name }}
                  </p>
                </el-tooltip>
                <svg-icon
                  iconClass="Edit"
                  className="edit"
                  class="icon"
                  @click.stop="role_name_input_show($event, scope.row)"
                ></svg-icon>
              </div>
              <div
                @click.stop="(e) => e.preventDefault()"
                v-else
                class="role_name_class"
              >
                <el-input
                  type="text"
                  v-model="scope.row.role_name"
                  :maxlength="20"
                  @blur="roleNameInputModelBlur($event, scope.row)"
                  @keyup.enter.native="
                    roleNameInputModelBlur($event, scope.row)
                  "
                  v-focus="true"
                >
                </el-input>
              </div>
            </template>
          </el-table-column>
          <!-- 角色标签 -->
          <el-table-column
            prop="role_name"
            width="180"
            :label="$t('VideoDubbingClone.dubText40')"
          >
            <template slot-scope="scope">
              <div class="role_attr_class">
                <div v-if="scope.row.roleAttrGroup.length == 4">
                  <svg-icon
                    :iconClass="
                      scope.row.roleAttrGroup[0] == '1' ? 'female' : 'male'
                    "
                    className="svg-gender"
                  ></svg-icon>
                </div>
                <div
                  class="role_attr_class_div"
                  v-if="scope.row.roleAttrGroup.length == 4"
                >
                  <span>{{
                    $t(
                      "VideoDubbingClone.roleText_age_" +
                        scope.row.roleAttrGroup[1]
                    )
                  }}</span>
                  <span>{{
                    $t(
                      "VideoDubbingClone.roleText_position_" +
                        scope.row.roleAttrGroup[2]
                    )
                  }}</span>
                  <span>{{
                    $t(
                      "VideoDubbingClone.roleText_character_" +
                        scope.row.roleAttrGroup[3]
                    )
                  }}</span>
                </div>
                <span
                  v-if="scope.row.roleAttrGroup.length != 4"
                  class="no_set"
                  >{{ $t("VideoDubbingClone.dubText41") }}</span
                >
                <div
                  style="display: inline-block; margin-left: 5px"
                  @click.stop="(e) => e.preventDefault()"
                >
                  <el-popover
                    :visible-arrow="false"
                    popper-class="roleAttrClass"
                    placement="bottom-start"
                    trigger="click"
                    :ref="'popover-' + scope.row.role_id"
                  >
                    <div slot="reference">
                      <svg-icon
                        iconClass="Edit"
                        className="edit"
                        style="cursor: pointer"
                        @click="show_role_attr(scope.row)"
                      ></svg-icon>
                    </div>
                    <div
                      v-if="scope.row.showRoleAttr"
                      style="margin-top: 12px; padding: 12px"
                    >
                      <div
                        class="role_attr"
                        v-for="(item1, index1) in roleAttrData"
                        :key="index1"
                      >
                        <div class="role_attr_div">
                          <div class="role_attr_div_left">
                            {{ $t("VideoDubbingClone.roleText_" + index1) }}
                          </div>
                          <div class="role_attr_div_right">
                            <span
                              class="role_attr_div_span"
                              :class="
                                scope.row.roleAttrSelect[index1] == i.Id
                                  ? 'active'
                                  : ''
                              "
                              v-for="i in item1"
                              :key="i.Id"
                              @click="
                                edit_role_attr_click(scope.row, index1, i.Id)
                              "
                              >{{
                                $t(
                                  "VideoDubbingClone.roleText_" +
                                    index1 +
                                    "_" +
                                    i.Id
                                )
                              }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style="border-top: 1px solid #515464"></div>
                    <div class="role_attr_footer">
                      <el-button
                        type="info"
                        @click="CancelRoleAttrClick(scope.row)"
                        class="setpadding23"
                        >{{ $t("CommonName.Cancel") }}
                      </el-button>
                      <el-button
                        type="primary"
                        @click="SureRoleAttrClick(scope.row)"
                        class="setpadding23"
                        >{{ $t("CommonName.OK") }}
                      </el-button>
                    </div>
                  </el-popover>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="voice_name"
            width="135"
            :label="$t('VideoDubbingClone.dubText_25')"
          >
            <template slot-scope="scope">
              <div style="display: flex; align-items: center">
                <div class="dub_multiple_anchor_info">
                  <div
                    class="play_div"
                    @click.stop="SeeMoreGender(scope.row, scope.$index)"
                  >
                    <!-- <div class="play_div"> -->
                    <img :src="scope.row.src" class="play_head" />
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="scope.row.voice_name"
                      :enterable="false"
                      :visible-arrow="false"
                      placement="bottom-start"
                    >
                      <span
                        class="span1 title_tooltip"
                        :class="scope.row.class"
                        >{{ scope.row.voice_name }}</span
                      >
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="segments"
            width="135"
            :label="$t('VideoDubbingClone.dubText_26')"
          >
            <template slot-scope="scope">
              {{ scope.row.segments + "(" + scope.row.duration + ")" }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('VideoDubbingClone.dubText_27')">
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.job_dubbing_ids"
                multiple
                collapse-tags
                class="selectclass"
              >
                <el-option
                  v-for="item in options"
                  :key="item.job_dubbing_id"
                  :label="item.name"
                  :value="item.job_dubbing_id"
                  :disabled="true"
                  v-show="
                    scope.row.job_dubbing_ids.includes(item.job_dubbing_id)
                  "
                >
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            width="58"
            :label="$t('VideoDubbingClone.dubText_28')"
          >
          </el-table-column>
        </el-table>
      </div>
    </div>
    <ProjectDubbingSetAnchors
      :dialogVisible="SetAnchorsVisible"
      v-if="SetAnchorsVisible"
      :project_id="this.project_id"
      :MyCollectRoles="MyCollectRolesList"
      :AnchorAll="AnchorAll"
      :AnchorId="CurrentAnchorId"
      :RoleType="CurrentAnchorRoleType"
      @SaveClick="SetAnchorsClick"
      @changeDialogVisible="closeAnchorsVisible"
    ></ProjectDubbingSetAnchors>
  </el-dialog>
</template>
<script>
import { customer_api_method } from "@/utils/https";
import { hideLoading, showLoading } from "@/utils/loading";
import ProjectDubbingSetAnchors from "./ProjectDubbingSetAnchors.vue";

export default {
  name: "DubbingProjectRoles.vue",
  components: {
    ProjectDubbingSetAnchors,
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    project_id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      buttonId: 0,
      SetAnchorsVisible: false,
      dialogVisible: false,
      innerVisible: false,
      innerInput: "",
      title: "",
      tableData: [],
      options: [],
      selectTableData: [],
      innerTitle: "",
      innerTitle1: "",
      topButton: [
        {
          id: 1,
          name: this.$t("ProjectDetails.topButton.name"), //添加角色
        },
        {
          id: 2,
          name: this.$t("ProjectDetails.topButton.name2"), //一键克隆
        },
        {
          id: 3,
          name: this.$t("ProjectDetails.topButton.name3"), //一键配音
        },
        // {
        //   id: 4,
        //   name: this.$t("ProjectDetails.topButton.name4"), //合并角色
        // },
        // {
        //   id: 5,
        //   name: this.$t("ProjectDetails.topButton.name5"), //锁定/解锁
        // },
        {
          id: 6,
          name: this.$t("ProjectDetails.topButton.name6"), //删除角色
        },
        {
          id: 7,
          name: this.$t("ProjectDetails.topButton.name7"), //一键配音还原
        },
      ],
      voices_src: [
        require("@/assets/imgs/Portrait_Man.png"),
        require("@/assets/imgs/Portrait_Man.png"),
        require("@/assets/imgs/Portrait_Woman.png"),
        require("@/assets/imgs/Portrait_child.png"),
        require("@/assets/imgs/Portrait_child2.png"),
        require("@/assets/imgs/AI_ChatHead.png"),
        require("@/assets/imgs/clone_no.png"),
      ],
      voices_index: 0, //当前主播
      voices_current: {}, //当前主播
      MyCollectRolesList: [],
      AnchorAll: [],
      CurrentAnchorId: 0,
      CurrentAnchorName: "",
      CurrentAnchorRoleType: "0",
      roleAttrData: {},
      roleAttrSelect: {
        gender: "1",
        age: "1",
        position: "1",
        character: "1",
      },
      logTableData: [],
      project_type: "11",
    };
  },
  mounted() {
    showLoading(this.$t("CommonName.loadingmessage"));
    this.GetRoleAttr();
    this.get_dubbing_job_list();
    // hideLoading();
    this.get_project_roles();
    this.dialogVisible = this.showDialog;
    this.title = this.$t("ProjectDetails.buttonList.name29");
  },
  methods: {
    // ==========================请求 调用=================================
    get_dubbing_job_list() {
      customer_api_method({
        action: "get_dubbing_job_list",
        project_id: this.project_id,
      }).then((res) => {
        if (!res) {
          hideLoading();
          return;
        }
        this.options = res.list;
      });
    },
    /**
     * 获取项目角色
     * @param id
     */
    get_project_roles(id) {
      // console.log(this.project_type, "this.buttonID");
      return customer_api_method({
        action: "get_project_roles",
        project_id: this.project_id,
        job_type: this.project_type === "10" ? 0 : 1,
      }).then((res) => {
        if (!res) {
          hideLoading();
          return;
        }
        if (res.list.length > 0) {
          res.list.forEach((i) => {
            i.inputEditing = false;
            i.role_name_update = i.role_name;
          });
        }
        this.AnchorAll = res.dict_voice_list;
        this.tableData = this.anchorsInit(res.list);
        // console.log("this.tableData", this.tableData);
        hideLoading();
      });
    },
    //角色数据初始化
    anchorsInit(voice_info) {
      // this.voice_info = voice_info;
      // this.AnchorAll = this.voice_info.dict_voice_list;
      if (voice_info.length == 0) {
        return voice_info;
      }
      voice_info.forEach((item) => {
        if (item.role_type == "0") {
          item.class = "";
          item.src = this.voices_src[Number(item.gender)];
        } else {
          switch (item.status) {
            case 1:
              item.gender = 6;
              item.name = "";
              item.class = "";
              break;
            case 2:
              item.gender = 5;
              item.name = this.$t("VideoDubbingClone.dubText8");
              item.class = "clone";
              break;
            case 3:
              item.gender = 5;
              break;
            case 4:
              item.gender = 5;
              break;
            case 5:
              item.gender = 5;
              item.class = "clone";
              break;
            case 6:
              item.gender = 5;
              item.class = "clone";
              break;
            default:
              break;
          }

          item.src = this.voices_src[Number(item.gender)];
          item.showgender = true; //性别 男
        }
        item.showRoleAttr = false; //显示标签弹出框
        item.roleAttrGroup = [];
        item.roleAttrSelect = {
          gender: "0",
          age: "0",
          position: "0",
          character: "0",
        }; //标签选项
        if (item.role_attr != null && item.role_attr != "") {
          let arr = item.role_attr.split(",");
          item.roleAttrGroup = arr;
          if (arr.length == 4) {
            item.roleAttrSelect = {
              gender: arr[0],
              age: arr[1],
              position: arr[2],
              character: arr[3],
            };
          }
        }
      });
      return voice_info;
    },
    /**
     * 关闭
     */
    closeDialog() {
      this.dialogVisible = false;
      this.$emit("closeDialog", this.dialogVisible);
    },
    /**
     * 顶部按钮 点击事件
     * @param id
     */
    handleClick(id) {
      this.buttonId = id;
      if (id === 1) {
        this.innerVisible = true;
        this.innerTitle = this.$t("ProjectDetails.topButton.name");
        this.innerTitle1 = this.$t("VideoDubbingClone.dubText22");
      }
      // 一键克隆
      if (id === 2) {
        if (this.selectTableData.length === 0) {
          return;
        }
        this.$confirm(
          this.$t("VideoDubbingClone.dubText_38"),
          this.$t("CommonName.deleteMessageTitle"),
          {
            confirmButtonText: this.$t("message.confirm"),
            cancelButtonText: this.$t("message.cancel"),
            closeOnClickModal: false,
            cancelButtonClass: "closeButton",
            // confirmButtonClass: "confirmButton",
          }
        )
          .then(() => {
            this.clone_method();
          })
          .catch(() => {});
        // this.innerVisible = true;
        // this.innerTitle = this.$t("ProjectDetails.topButton.name2");
        // this.innerTitle1 = this.$t("VideoDubbingClone.dubText2");
        // this.innerInput = this.selectTableData[0]["role_name"];
      }
      if (id === 6) {
        if (this.selectTableData.length === 0) {
          return;
        }
        let arr = [];
        this.selectTableData.forEach((i) => {
          arr.push(i.role_name);
        });
        this.$confirm(
          this.$t("VideoDubbingClone.dubText_32"),
          this.$t("CommonName.deleteMessageTitle"),
          {
            confirmButtonText: this.$t("message.confirm"),
            cancelButtonText: this.$t("message.cancel"),
            closeOnClickModal: false,
            cancelButtonClass: "closeButton",
            // confirmButtonClass: "confirmButton",
          }
        )
          .then(() => {
            showLoading(this.$t("CommonName.loadingmessage"));
            customer_api_method({
              action: "delete_project_roles",
              project_id: this.project_id,
              job_type: this.project_type === "10" ? 0 : 1,
              role_names: arr,
            }).then((res) => {
              if (!res) {
                hideLoading();
                return;
              }
              this.get_project_roles();
              hideLoading();
            });
          })
          .catch(() => {});
      }
      // 一键配音
      if (id === 3) {
        if (this.selectTableData.length === 0) {
          return;
        }
        this.innerVisible = true;
        this.innerTitle = this.$t("ProjectDetails.topButton.name3");
        this.innerTitle1 = this.$t("VideoDubbingClone.dubText73");
        this.innerInput = "";
        return;
      }
      // 一键配音还原
      if (id === 7) {
        showLoading(this.$t("CommonName.loadingmessage"));
        customer_api_method({
          action: "get_project_dubbing_log",
          project_id: this.project_id,
          job_type: this.project_type === "10" ? 0 : 1,
        }).then((res) => {
          hideLoading();
          if (!res) {
            return;
          }
          // console.log(this.MyCollectRolesList);
          this.innerVisible = true;
          this.innerTitle = this.$t("ProjectDetails.topButton.name7");
          this.logTableData = res.info;
        });

        return;
      }
    },
    // 表格多选
    handleSelectionChange(val) {
      this.selectTableData = val;
    },
    /**
     *
     * @param e
     * @param item
     */
    role_name_input_show(e, item) {
      item.inputEditing = true;
    },
    /**
     * 克隆方法
     *
     */
    clone_method() {
      if (this.selectTableData.some((item) => item.segments === 0)) {
        this.$messages("warning", this.$t("VideoDubbingClone.dubText_33"));
        return;
      }
      //   一键克隆
      let arr = [];
      this.selectTableData.forEach((i) => {
        arr.push(i.role_name);
      });
      showLoading(this.$t("CommonName.loadingmessage"));
      customer_api_method({
        action: "project_roles_clone",
        project_id: this.project_id,
        job_type: this.project_type === "10" ? 0 : 1,
        role_names: arr,
      }).then((res) => {
        if (!res) {
          hideLoading();
          return;
        }
        this.$messages("success", this.$t("VideoDubbingClone.dubText_30"));
        this.get_project_roles();
        hideLoading();
      });
    },
    /**
     * 修改角色名称
     * @param e
     * @param item
     */
    roleNameInputModelBlur(e, item) {
      if (item.role_name.trim() == "") {
        this.$messages("warning", this.$t("VideoDubbing.roleText10"));
        item.role_name = item.role_name_update;
        item.inputEditing = false;
        return;
      }
      if (item.role_name.trim() == item.role_name_update) {
        item.inputEditing = false;
        return;
      }
      customer_api_method({
        action: "set_project_roles_name",
        project_id: this.project_id,
        job_type: this.project_type === "10" ? 0 : 1,
        role_name: item.role_name,
        old_role_name: item.role_name_update,
      }).then((res) => {
        if (!res) {
          item.role_name = item.role_name_update;
          return;
        }
        item.role_name_update = item.role_name;
        item.inputEditing = false;
      });
    },
    closeinnerVisible() {
      this.innerVisible = false;
      this.innerInput = "";
    },
    closeAnchorsVisible() {
      this.SetAnchorsVisible = false;
    },
    AddWords() {
      if (this.buttonId === 1) {
        if (this.innerInput.trim() == "") {
          this.$messages("warning", this.$t("VideoDubbing.roleText10"));
          return;
        }
        showLoading(this.$t("CommonName.loadingmessage"));
        customer_api_method({
          action: "add_project_roles",
          project_id: this.project_id,
          job_type: this.project_type === "10" ? 0 : 1,
          role_name: this.innerInput,
        }).then((res) => {
          if (!res) {
            hideLoading();
            return;
          }
          this.innerInput = "";
          this.innerVisible = false;
          this.get_project_roles();
          hideLoading();
        });
      } else if (this.buttonId === 2) {
        // if (this.innerInput.trim() == "") {
        //   this.$messages("warning", this.$t("VideoDubbing.roleText10"));
        //   return;
        // }
      } else if (this.buttonId === 3) {
        if (this.innerInput.trim() == "") {
          this.$messages(
            "warning",
            this.$t("VideoDubbingClone.warningmessage30")
          );
          return;
        }
        // alert("一键配音:" + this.innerInput);
        // return;
        let arr = [];
        this.selectTableData.forEach((i) => {
          arr.push(i.role_name);
        });
        showLoading(this.$t("CommonName.loadingmessage"));
        customer_api_method({
          action: "project_roles_dubbing",
          project_id: this.project_id,
          job_type: this.project_type === "10" ? 0 : 1,
          role_names: arr,
          log_name: this.innerInput,
        }).then((res) => {
          if (!res) {
            hideLoading();
            return;
          }
          this.innerInput = "";
          this.innerVisible = false;
          this.$messages("success", this.$t("VideoDubbingClone.dubText_29"));
          hideLoading();
        });
      } else if (this.buttonId === 7) {
        //一键配音还原
        if (this.logTableData.length == 0) {
          return;
        }
        this.$confirm(
          this.$t("VideoDubbingClone.dubText76"),
          this.$t("CommonName.deleteMessageTitle"),
          {
            confirmButtonText: this.$t("message.confirm"),
            cancelButtonText: this.$t("message.cancel"),
            closeOnClickModal: false,
            cancelButtonClass: "closeButton",
            // confirmButtonClass: "confirmButton",
          }
        )
          .then(() => {
            showLoading(this.$t("CommonName.loadingmessage"));
            customer_api_method({
              action: "reset_project_dubbing_log",
              project_id: this.project_id,
              job_type: this.project_type === "10" ? 0 : 1,
            }).then((res) => {
              hideLoading();
              if (!res) {
                return;
              }
              this.innerInput = "";
              this.innerVisible = false;
              this.$messages("success", this.$t("VideoDubbingClone.dubText77"));
            });
          })
          .catch(() => {});
      }
    },
    SeeMoreGender(item, index) {
      console.log(item, index);
      this.voices_current = item;
      this.voices_index = index;
      this.CurrentAnchorName = item.role_name;
      this.CurrentAnchorId =
        item.role_type == "0" ? item.voice_id : item.role_collect_id;
      // this.SetAnchorsMode = "1";
      this.CurrentAnchorRoleType = item.role_type + "";
      customer_api_method({
        action: "get_clone_customized_role_list",
        project_id: this.project_id,
        job_id: 0,
      }).then((res) => {
        hideLoading();
        if (!res) {
          return;
        }
        // this.allData = res.my_list;
        let role_type = this.project_type === "10" ? "1" : "2";
        this.MyCollectRolesList = res.my_list.filter(
          (i) => i.role_type === role_type
        );
        // console.log(this.MyCollectRolesList);
        this.SetAnchorsVisible = true;
      });
    },
    /**
     *
     * @param {*确定 按钮} data
     */
    SetAnchorsClick(data) {
      // console.log(data);
      // console.log(this.project_id);
      // console.log(this.CurrentAnchorName);
      // console.log(this.project_type === "10" ? 0 : 1);
      // return false;
      // this.SetAnchorsVisible = false;

      if (data.Mode == "1") {
        if (data.AnchorId == "0") {
          return false;
        }
        showLoading(this.$t("VideoDubbing.loadingmessage"));
        //角色修改
        this.update_private_dubbing_voice(data).then((res_up) => {
          this.SetAnchorsVisible = false;
          this.CurrentAnchorName = "";
          this.get_project_roles();
        });
      }

      // let arr = this.$store.state.VideoDubbinganchor.collectAnchorGroup;
      // if (arr.length > 0) {
      //   arr = arr.join(",");
      // } else {
      //   arr = "";
      // }
      // data.arr = arr;
    },
    /**
     *
     * @param {*更新主播设置} item
     */
    update_private_dubbing_voice(item) {
      // console.log(this.voices_current);
      return customer_api_method({
        action: "update_project_clone_private_dubbing_voice",
        project_id: this.project_id,
        role_name: this.CurrentAnchorName,
        voice_id: item.AnchorId,
        AnchorsTab: item.AnchorsTab,
        job_type: this.project_type === "10" ? 0 : 1,
      }).then((res) => {
        if (!res) {
          hideLoading();
          return;
        }
        return res;
      });
    },
    GetRoleAttr() {
      customer_api_method({
        action: "get_role_attr",
      }).then((res) => {
        if (!res) {
          hideLoading();
          return;
        }
        this.roleAttrData = res.list;
      });
    },
    role_attr_click(index, id) {
      this.roleAttrSelect[index] = id;
    },
    edit_role_attr_click(item, index1, id) {
      item.roleAttrSelect[index1] = id;
      let index = this.tableData.findIndex(
        (value) => value.role_id == item.role_id
      );
      this.$set(this.tableData, index, item);
    },
    show_role_attr(item) {
      item.roleAttrSelect = {
        gender: "0",
        age: "0",
        position: "0",
        character: "0",
      }; //标签选项
      if (item.role_attr != null && item.role_attr != "") {
        let arr = item.role_attr.split(",");
        if (arr.length == 4) {
          item.roleAttrSelect = {
            gender: arr[0],
            age: arr[1],
            position: arr[2],
            character: arr[3],
          };
        }
      }
      item.showRoleAttr = true;
      for (let index = 0; index < this.tableData.length; index++) {
        if (
          this.tableData[index].showRoleAttr == true &&
          this.tableData[index].role_id != item.role_id
        ) {
          this.tableData[index].showRoleAttr = false;
          this.$refs["popover-" + this.tableData[index].role_id].doClose();
        }
      }
    },
    CancelRoleAttrClick(item) {
      item.showRoleAttr = false;
      this.$refs["popover-" + item.role_id].doClose();
    },
    SureRoleAttrClick(item) {
      if (
        item.roleAttrSelect.gender == "0" ||
        item.roleAttrSelect.age == "0" ||
        item.roleAttrSelect.position == "0" ||
        item.roleAttrSelect.character == "0"
      ) {
        this.$messages(
          "warning",
          this.$t("VideoDubbingClone.warningmessage19")
        );
        return false;
      }
      let index = this.tableData.findIndex(
        (value) => value.role_id == item.role_id
      );
      let role_attr =
        item.roleAttrSelect.gender +
        "," +
        item.roleAttrSelect.age +
        "," +
        item.roleAttrSelect.position +
        "," +
        item.roleAttrSelect.character;
      showLoading(this.$t("CommonName.loadingmessage"));
      customer_api_method({
        action: "set_project_roles_attr",
        project_id: this.project_id,
        job_type: this.project_type === "10" ? 0 : 1,
        role_name: item.role_name,
        role_attr,
      }).then((res) => {
        hideLoading();
        if (!res) {
          return;
        }
        this.$refs["popover-" + item.role_id].doClose();
        item.roleAttrGroup = [
          item.roleAttrSelect.gender,
          item.roleAttrSelect.age,
          item.roleAttrSelect.position,
          item.roleAttrSelect.character,
        ];
        item.showRoleAttr = false;
        item.role_attr = role_attr;
        this.$set(this.tableData, index, item);
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "./AllDialogradio.scss";

.dialogContent {
  margin: 24px;

  .content_top {
    display: flex;
    margin-bottom: 13px;
  }

  .role_name_class {
    display: flex;
    align-items: center;

    .icon {
      cursor: pointer;
    }

    p {
      width: 120px;
    }

    ::v-deep .el-input__inner {
      width: 100%;
    }
  }
  .role_attr_class {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 0;
    .role_attr_class_div {
      span {
        display: inline-block;
        background: #51546a;
        border-radius: 12px;
        font-size: 12px;
        padding: 0px 5px;
        margin: 2px 0 2px 4px;
        height: 23px;
      }
    }
    .no_set {
      color: #6d6f78;
      background: #383a4b;
      border-radius: 12px;
      font-size: 12px;
      padding: 0px 5px;
      margin-left: 4px;
      height: 23px;
    }
    .icon {
      cursor: pointer;
    }
  }
  .selectclass {
    width: 100%;

    ::v-deep .el-tag.el-tag--info {
      background: #5d5e6e !important;
      color: #ffffff !important;
      border: none !important;
      max-width: 170px !important;
    }

    ::v-deep .el-tag.el-tag--info .el-tag__close {
      display: none !important;
    }

    ::v-deep .el-input__inner {
      width: 100% !important;
    }
  }

  .dub_multiple_anchor_info {
    margin-right: 16px;

    .play_div {
      background-color: #51546a;
      border-radius: 18px;
      padding: 3px 5px;
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    .play_head {
      width: 22px;
      height: 22px;
    }

    .span1 {
      margin: 0px 5px;
      color: #ffffff;
      font-size: 12px;
      width: 50px;
      overflow: hidden;
    }

    .span1.clone {
      color: #adb0bc;
    }
  }
}

.innerDialog_class {
  .innerDialog_class_p {
    color: #adb0bc;
    font-size: 14px;
    margin-bottom: 6px;
  }
}

.title_tooltip {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.role_attr {
  .role_attr_div + .role_attr_div {
    margin-top: 16px;
  }
  .role_attr_div {
    display: flex;
    .role_attr_div_left {
      color: #adb0bc;
      width: 70px;
    }
    .role_attr_div_span + .role_attr_div_span {
      margin-left: 16px;
    }
    .role_attr_div_span {
      padding: 0px 12px;
      border: 1px solid #b7d8f2;
      color: #b7d8f2;
      background: none;
      border-radius: 12px;
      font-size: 12px;
      cursor: pointer;
    }
    .role_attr_div_span:hover {
      padding: 0px 12px;
      border: 1px solid #46adff;
      background-color: #46adff;
      color: #ffffff;
    }
    .role_attr_div_span.active {
      padding: 0px 12px;
      border: 1px solid #46adff;
      background-color: #46adff;
      color: #ffffff;
    }
  }
}
.role_attr + .role_attr {
  margin-top: 24px;
}
</style>
<style lang="scss">
.el-popover.roleAttrClass {
  padding: 0;
  .role_attr_footer {
    margin-top: 12px;
    margin-right: 24px;
    text-align: right;
    margin-bottom: 12px;
    .el-button {
      font-size: 12px;
      height: 24px !important;
    }
  }
}
</style>
