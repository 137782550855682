<!--创建声音-->
<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    :width="'888px'"
    :top="'3%'"
    center
    :close-on-click-modal="false"
    @close="closeDialog"
  >
    <div class="dialogContent">
      <div class="dialogContent_top">
        <div class="dialogContent_top_left">
          <!--          音频来源-->
          <p class="titlep">{{ $t("CustomizedAnchor.name1") }}</p>
          <div class="radioClass">
            <el-radio-group v-model="radio">
              <!--                        上传配音 SRT 文件-->
              <el-radio label="1" border
                >{{ $t("ProjectManagement.UploadSubtitleFile") }}
              </el-radio>
              <!--              选择已有任务角色-->
              <!--                        无配音 SRT 文件-->
              <el-radio label="2" border
                >{{ $t("CustomizedAnchor.name2") }}
              </el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="dialogContent_top_right">
          <!--          主播名称-->
          <p class="titlep">{{ $t("VideoDubbingClone.dubText2") }}</p>
          <el-input maxlength="80" v-model="name"></el-input>
        </div>
      </div>
      <div class="dialogContent_btm">
        <el-upload
          v-show="radio === '1'"
          class="upload-demo"
          drag
          ref="upload"
          :with-credentials="true"
          :action="actionURL"
          :name="uploadName"
          :data="uploadData"
          :on-change="uploadChange"
          :on-progress="uploadProgress"
          :on-success="uploadSuccess"
          :auto-upload="false"
          :show-file-list="false"
          :limit="1"
        >
          <div
            class="uploaddiv"
            slot="trigger"
            @click="AudiotranscriptionClick"
          >
            <div v-if="videoData.name" class="videoDatanamediv">
              <div class="svgdivs">
                <svg-icon :icon-class="videoData.icon" class="icon"></svg-icon>
              </div>
              <p class="namep">{{ videoData.name }}</p>
              <!--                          <p class="topp">重新上传</p>-->
              <p class="topp">
                {{ $t("PersonalEndLanguage.Reupload") }}
              </p>
            </div>
            <div v-else>
              <i class="el-icon-plus"></i>
              <!--                          <p class="topp">点击选择或拖动视频到这里</p>-->
              <p class="topp">
                {{ $t("PersonalEndLanguage.DragVideo2") }}
              </p>
              <!--                          支持mp4格式，视频-->
              <p class="botp">
                {{ $t("VideoDubbingClone.fileText") }}
              </p>
              <p class="botp">
                {{ $t("VideoDubbingClone.fileText1") }}
              </p>
            </div>
          </div>
        </el-upload>
        <div v-show="radio === '2'" class="dialogContent_btm_div">
          <div class="dialogContent_btm_div_top">
            <!--      任务名称      -->
            <p>{{ $t("PersonalEndLanguage.youtubeTitle") }}</p>
            <p>{{ $t("VideoDubbing.segmentText2") }}</p>
          </div>
          <div class="dialogContent_btm_div_center">
            <div
              v-for="obj in selectArr"
              :key="obj.id"
              class="dialogContent_btm_div_center_div"
            >
              <div class="dialogContent_btm_div_center_div">
                <el-select
                  v-model="obj.select1"
                  filterable
                  :placeholder="$t('CommonName.Pleaseselect')"
                  class="selectclass"
                  @change="select1Change(obj.select1, obj.id, obj)"
                >
                  <el-option
                    v-for="item in setSelect1options"
                    :key="item.job_dubbing_id"
                    :label="item.job_name"
                    :value="item.job_dubbing_id"
                    :disabled="item.disable"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="dialogContent_btm_div_center_div">
                <el-select
                  v-model="obj.select2"
                  multiple
                  collapse-tags
                  :placeholder="$t('CommonName.Pleaseselect')"
                  class="selectclass"
                  :disabled="!obj.select1"
                >
                  <el-option
                    v-for="item in obj.select2options"
                    :key="item.role_id"
                    :label="item.role_name"
                    :value="item.role_id"
                  >
                  </el-option>
                </el-select>
              </div>
              <el-button
                type="text"
                style="font-size: 16px"
                @click="deleteArr(obj.id)"
              >
                <svg-icon icon-class="delete"></svg-icon>
              </el-button>
            </div>
          </div>
          <div class="dialogContent_btm_div_bottomr">
            <!--            添加更多-->
            <el-button type="text" @click="addArr"
              >+ {{ $t("CustomizedAnchor.name3") }}
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <!--    确定 取消 按钮-->
    <div slot="footer" class="dialog-footer" style="margin-top: 24px">
      <div>
        <!--        关闭 按钮-->
        <el-button @click="closeDialog" type="info" class="setpadding23"
          ><p class="footerleftbuttonp">
            {{ $t("CommonName.Cancel") }}
          </p></el-button
        >
        <!--        保存 按钮-->
        <el-button
          type="primary"
          @click="determineClick"
          class="setpadding23"
          :loading="loadingSure"
        >
          {{ $t("CommonName.OK") }}
        </el-button>
      </div>
    </div>
    <div
      class="progressdiv"
      v-if="percentage > 0"
      v-loading.fullscreen.lock="loading"
      element-loading-custom-class="disaplaynoneicon"
      element-loading-background="rgba(0, 0, 0, 0.3)"
    >
      <el-progress
        type="circle"
        :percentage="percentage"
        :stroke-width="10"
      ></el-progress>
    </div>
  </el-dialog>
</template>
<script>
import { customer_api_method } from "@/utils/https";
import { hideLoading, showLoading } from "@/utils/loading";

export default {
  name: "CustomizedAnchor",
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    project_id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dialogVisible: false,
      title: this.$t("ProjectDetails.buttonList.name27"),
      loadingSure: false,
      loading: false,
      percentage: 0,
      radio: "1",
      name: "",
      uploadName: "", //上传文件类型
      uploadData: {}, //上传文件 数据
      actionURL: "", //创建任务 路径
      videoData: {
        //获取上传文件
        name: "",
        icon: "",
        file: "",
      },
      selectArr: [
        {
          select1: "",
          select2: [],
          select2options: [],
          id: 0,
        },
      ],
      select1options: [],
      project_type: "4",
    };
  },
  mounted() {
    this.dialogVisible = this.showDialog;
    this.get_clone_customized_job_list();
  },
  computed: {
    /**
     * 设置 任务显示
     * @returns {*[]}
     */
    setSelect1options() {
      // return this.select1options;
      // let arr = JSON.parse(JSON.stringify(this.select1options));
      this.select1options.filter((item) => {
        item.disable = this.selectArr.some(
          (item1) => item.job_dubbing_id === item1.select1
        );
      });
      return this.select1options;
    },
  },
  methods: {
    /**
     * 获取 任务列表
     */
    get_clone_customized_job_list() {
      customer_api_method({
        action: "get_clone_customized_job_list",
        project_id: this.project_id,
        job_type: this.project_type === "3" ? 0 : 1,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.select1options = res.list;
        if (this.select1options.length > 0) {
          this.select1options.forEach((i) => {
            i.disable = false;
          });
        }
      });
    },
    /**
     *获取人员列表
     * @param id
     */
    select1Change(id, ids, obj) {
      obj.select2 = [];
      // console.log(obj.select1);
      customer_api_method({
        action: "get_clone_customized_job_role_list",
        job_id: id,
      }).then((res) => {
        if (!res) {
          return;
        }
        let obj = this.selectArr.find((i) => i.id === ids);
        obj.select2options = res.my_list;
        // console.log(res.my_list, "11111111");
      });
    },

    /**
     * 添加
     */
    addArr() {
      this.selectArr.push({
        id: this.selectArr[this.selectArr.length - 1]
          ? this.selectArr[this.selectArr.length - 1].id + 1
          : 0,
        select1: "",
        select2: [],
        select2options: [],
      });
    },
    /**
     * 删除
     * @param id
     */
    deleteArr(id) {
      this.selectArr.forEach((i, index) => {
        // console.log(i.id);
        if (i.id == id) {
          this.selectArr.splice(index, 1);
        }
      });
    },
    /**
     * 限制只能上传一个文件
     */
    AudiotranscriptionClick() {
      this.videoData.name = "";
      // this.$refs.upload.clearFiles();
      let uploadFilesArr = this.$refs["upload"].uploadFiles; //上传字幕文件
      // console.log(uploadFilesArr);
      if (uploadFilesArr.length == 0) {
        return;
      } else {
        this.$refs["upload"].uploadFiles = [];
      }
    },
    /**
     *
     * @param {*上传视频 改变视频} file
     */
    uploadChange(file) {
      if (!file) return;
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      // 视频配音
      const isLt2M = file.size / 1024 / 1024 < 50;
      const extension = testmsg.toLowerCase() === "wav";
      if (!extension) {
        this.$messages("warning", this.$t("VideoDubbingClone.warningmessage2"));
        this.videoData.name = "";
        this.videotime = 0;
        this.$refs.upload.clearFiles();
      } else if (!isLt2M) {
        this.$messages("warning", this.$t("VideoDubbingClone.warningmessage7"));
        this.videoData.name = "";
        this.videotime = 0;
        this.$refs.upload.clearFiles();
      } else if (file.name.length > 80) {
        this.$messages(
          "warning",
          this.$t("PersonalEndLanguage.warningmessage36")
        );
        this.videoData.name = "";
        this.videotime = 0;
        this.$refs.upload.clearFiles();
      } else {
        this.getFileTime(file)
          .then((res) => {
            if (res / 60 > 10 || res / 60 < 1) {
              this.$messages(
                "warning",
                this.$t("VideoDubbingClone.warningmessage1")
              );
              this.videoData.name = "";
              this.videotime = 0;
              this.$refs.upload.clearFiles();
            } else {
              // console.log(123456);
              this.videotime = res;
              this.videoData.name = file.name;
              this.videoData.icon = "audio";
              this.videoData.file = file;
              this.uploadName = "media_file";
              this.totalChunks = Math.ceil(file.size / this.chunkSize);
              this.currentChunk = 0;
            }

            // console.log(res);
          })
          .catch(() => {
            this.$messages(
              "error",
              this.$t("PersonalEndLanguage.errormessage1")
            );
          });
      }
    },
    /**
     *
     * @param {*上传过程中} file
     */
    uploadProgress(file) {
      // console.log(file);
      // showLoading();
      if (file.percent > 0) {
        this.percentage = Number(file.percent.toFixed());
        this.loading = true;
      }
      if (file.percent == 100) {
        this.percentage = 0;
        this.loading = false;
        showLoading(this.$t("PersonalEndLanguage.loadingMessage"));
      }
    },
    /**
     *
     * @param {*上传视频、音频、图片成功} response
     * @param {*} file
     */
    uploadSuccess(response, file) {
      // console.log(response, file);
      if (response.result == "SUCCESS") {
        this.$messages("success", "定制成功");
        hideLoading();
        this.dialogVisible = false;
        this.loadingSure = false;
        this.$emit("closeDialog", this.dialogVisible);
        // hideLoading();
      } else if (response.result == "LOGIN_FAILED") {
        this.percentage = 0;
        this.loading = false;
        hideLoading();
        this.$messages("warning", this.$t("CommonName.tokenMessage"));
        this.$router.push("/Login");
        this.$Cookie.remove("token_code");
        sessionStorage.clear();
      } else if (response.result == "QUOTE_FAILED") {
        file.status = "ready";
        this.percentage = 0;
        this.loading = false;
        hideLoading();
        this.quotetype = response.type;
        this.loadingSure = false;
      } else {
        this.percentage = 0;
        this.loading = false;
        file.status = "ready";
        // console.log(22222222);
        // this.quotetype = response.type;
        hideLoading();
        this.$messages("error", response.msg);
        this.loadingSure = false;
      }
    },
    /**
     * 关闭
     */
    closeDialog() {
      this.dialogVisible = false;
      this.$emit("closeDialog", this.dialogVisible);
    },
    /**
     * 确定
     */
    determineClick() {
      if (!this.name.trim()) {
        this.$messages("warning", this.$t("CommonName.warningmessage3"));
        return;
      }
      if (this.radio === "1") {
        if (!this.videoData.name) {
          return;
        }
        this.uploadData = {
          action: "clone_customized_anchor",
          name: this.name,
          project_id: this.project_id,
          job_type: this.project_type === "3" ? 0 : 1,
          mode: this.radio === "1" ? 0 : 1,
          roles: [],
        };

        this.loadingSure = true;
        this.actionURL = `${this.$uploadURL}/Videoteamdubbing/gateway`;
        this.$nextTick(() => {
          this.$refs.upload.submit();
        });
      } else {
        if (this.selectArr.length === 0) {
          return;
        }
        let arr = [];
        this.selectArr.forEach((i) => {
          arr.push(...i.select2);
        });
        if (this.selectArr.some((j) => j.select2.length === 0)) {
          return;
        }
        showLoading(this.$t("CommonName.loadingmessage"));
        customer_api_method({
          action: "clone_customized_anchor",
          name: this.name,
          project_id: this.project_id,
          job_type: this.project_type === "3" ? 0 : 1,
          mode: this.radio === "1" ? 0 : 1,
          roles: arr,
        }).then((res) => {
          if (!res) {
            hideLoading();
            return;
          }
          this.$messages("success", this.$t("CustomizedAnchor.name4"));
          hideLoading();
          this.dialogVisible = false;
          this.loadingSure = false;
          this.$emit("closeDialog", this.dialogVisible);
        });
      }
    },
    /**
     *
     * @param {*获取音频时长} file
     */
    async getFileTime(file) {
      // console.log(file);
      let content = file.raw;
      let a = await new Promise((resolve) => {
        var url = URL.createObjectURL(content);
        var audioElement = new Audio(url);
        audioElement.addEventListener("loadedmetadata", () => {
          resolve(audioElement.duration.toFixed(3)); //playTime就是当前视频长度
          // this.playTime = audioElement.duration;
        });
      });
      return a;
    },
  },
};
</script>

<style scoped lang="scss">
@import "./AllDialogradio.scss";

.dialogContent {
  margin: 24px;

  .dialogContent_top {
    display: flex;
    justify-content: space-between;

    p {
      margin-bottom: 6px;
      color: #adb0bc;
      font-size: 14px;
    }

    .radioClass .el-radio.is-bordered {
      width: 200px;
    }
  }

  .dialogContent_btm {
    margin-top: 24px;

    ::v-deep .el-upload-dragger {
      width: 840px !important;
    }

    .dialogContent_btm_div {
      width: 100%;
      height: 226px;
      background: #454758;
      border-radius: 2px;
      padding: 16px 20px;
      box-sizing: border-box;

      .dialogContent_btm_div_top {
        p {
          width: 390px !important;
          color: #adb0bc;
          font-size: 14px;
        }

        display: flex;
        align-items: center;
        //justify-content: space-between;
        margin-bottom: 6px;
      }

      .dialogContent_btm_div_center {
        max-height: 150px;
        overflow-y: auto;

        .dialogContent_btm_div_center_div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          //margin-bottom: 4px;
          overflow: hidden;

          .selectclass {
            ::v-deep .el-input__inner {
              width: 380px !important;
            }

            //::v-deep .el-select__tags {
            //  z-index: auto !important;
            //}
          }
        }
      }
    }
  }
}
</style>
