<!--
 * @Description: 对外项目详情页面
-->
<template>
  <div class="mainCenter">
    <div class="mainHeader">
      <div class="mainHeader_left">
        <div class="signoutClass" @click="signOut">
          <svg-icon icon-class="Aright" class="icon"></svg-icon>
        </div>
        <div class="titleClass">
          <div class="vertical"></div>
          <el-tooltip
            class="item"
            effect="dark"
            :content="title"
            :enterable="false"
            placement="top"
            :disabled="title.length < 20"
          >
            <p :class="title.length > 20 ? 'overflowp overflowEllipsis' : ''">
              {{ title }}
            </p>
          </el-tooltip>
        </div>
      </div>
      <div class="mainHeader_right">
        <div class="service" v-for="item in createJobList" :key="item.id">
          <div
            class="bgservice"
            @click="createJobClick(item)"
            :class="item.id != 1 ? 'bgdisabled' : ''"
          >
            <svg-icon
              :icon-class="item.icon"
              class="serviceicon"
              :style="{ color: item.id != 1 ? '#747886' : item.color }"
            ></svg-icon>

            <div class="servicetitle">
              <p class="servicetitle_name">{{ item.name }}</p>
              <p class="servicetitle_explain" v-html="item.explain"></p>
            </div>
            <div v-if="item.id != 1" class="servicetitle1 bgdisabled1">
              <!--                  即将上线，敬请期待-->
              {{ $t("PersonalEndLanguage.Comingsoonstaytuned") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mainTabs">
      <div class="mainHeader_left">
        <!-- <el-tabs
          v-model="tabname"
          @tab-click="handleClicktab"
          @keydown.native.capture.stop
        >
          <el-tab-pane
            :label="item.task_name + '(' + item.task_num + ')'"
            v-for="item in createJobList"
            :key="item.id"
            :name="item.tab_name"
          >
          </el-tab-pane>
        </el-tabs> -->
      </div>
      <div class="mainHeader_right">
        <div
          v-for="(i, index) in topButtonList"
          :key="index"
          class="buttonList_class"
          v-show="i.status"
        >
          <el-button type="text" @click="headerBtnClick(i.name, i.id)">
            <div class="buttondiv">
              <svg-icon :icon-class="i.icon" class="icon"></svg-icon>
              <p>{{ i.name }}</p>
            </div>
          </el-button>
        </div>
      </div>
    </div>
    <!-- 筛选 -->
    <div class="contentCenter_tags screenStyle">
      <p>{{ $t("CommonName.screen") }}</p>
      <el-tag
        v-for="tag in tags"
        :key="tag.name"
        closable
        :type="tag.type"
        @close="handleClose(tag)"
      >
        <p
          class="screenp"
          :id="'tag' + tag.id"
          @mouseover="onShowNameTipsMouseenter(tag)"
        >
          <el-tooltip
            class="item"
            effect="dark"
            :content="tag.name"
            placement="top-start"
            :disabled="!tag.showdropdown"
          >
            <span>{{ tag.name }}</span>
          </el-tooltip>
        </p>
      </el-tag>
    </div>
    <div class="mainCenterbtm tableClass">
      <el-table
        :row-style="{
          height: 48 + 'px',
          background: '#393c4e',
          color: '#cbd7e8',
        }"
        :cell-style="{ padding: 0 + 'px' }"
        :header-cell-style="{
          height: 48 + 'px',
          padding: '0px',
          color: '#adb0bc',
          background: '#393c4e',
        }"
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        :max-height="tableHeight"
        :row-class-name="tableRowClassName"
        @header-click="headerClick"
        @select="handleSelect"
        @select-all="handleALLSelect"
        :row-key="getRowKey"
        @expand-change="expandChange"
        :default-sort="{
          prop: 'job_name',
          order: projectInfo.sort_mode === '0' ? 'ascending' : 'descending',
        }"
        @sort-change="sortChange"
      >
        <!--          @row-click="rowClick"-->
        <div slot="empty" class="noDataDiv">
          <svg-icon icon-class="NoData" class="noDataicon"></svg-icon>
          <p>{{ $t("CommonName.noData") }}</p>
        </div>
        <template v-if="tabname == 'first'">
          <el-table-column
            type="selection"
            width="65"
            align="center"
            :reserve-selection="true"
          ></el-table-column>
          <!-- 任务名称 -->
          <el-table-column
            :min-width="140"
            prop="job_name"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot="header">
              <span class="fontSize12">{{ $t("CommonName.taskname") }}</span>
              <div
                class="icondiv"
                style="
                  font-size: 17px;
                  position: absolute;
                  right: 15px;
                  top: 8px;
                "
              >
                <el-popover
                  placement="bottom-end"
                  width="260"
                  v-model="visible"
                  :visible-arrow="false"
                >
                  <div class="popoverDiv">
                    <el-input
                      v-model="popoverInput"
                      :placeholder="$t('CommonName.search')"
                      maxlength="100"
                      @keyup.enter.native="searchdetermineClick"
                    ></el-input>
                    <el-button
                      type="primary"
                      class="setpadding16"
                      @click="searchdetermineClick"
                      >{{ $t("ProjectManagement.query") }}
                    </el-button>
                  </div>
                  <svg-icon
                    icon-class="Filter"
                    class="iconsize"
                    slot="reference"
                    @click.stop="jobNameClick"
                  ></svg-icon>
                </el-popover>
                <div class="tableheader"></div>
              </div>
              <span class="tableheaderspan" style="margin-top: 12px"></span>
            </template>
            <template slot-scope="scope">
              <div class="menuscopediv" @click="tovideoDetails(scope.row)">
                {{ scope.row.name }}
              </div>
            </template>
          </el-table-column>
          <!-- 类型 -->
          <el-table-column :width="100">
            <template slot="header">
              <div class="tableheaderdiv">
                <p class="fontSize12">
                  {{ $t("MyFiles.service") }}
                </p>
                <div class="icondiv">
                  <el-dropdown
                    @command="Operationprocesscommand"
                    trigger="click"
                    placement="bottom-end"
                  >
                    <span class="el-dropdown-link">
                      <svg-icon icon-class="Filter" class="iconsize"></svg-icon>
                    </span>
                    <el-dropdown-menu slot="dropdown" class="noshowtriangle">
                      <el-dropdown-item
                        v-for="item in OperationprocessdropdownList"
                        :key="item.id"
                        :command="{ name: item.name, value: item.id }"
                      >
                        <span>{{ item.name }}</span>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                  <div class="tableheader"></div>
                </div>
              </div>
            </template>
            <template slot-scope="scope">
              <div>{{ scope.row.type_name }}</div>
            </template>
          </el-table-column>
          <!-- 状态 -->
          <el-table-column :width="118">
            <template slot="header">
              <div class="tableheaderdiv">
                <p class="fontSize12">{{ $t("CommonName.status") }}</p>
                <div class="icondiv">
                  <el-dropdown
                    @command="statuscommand"
                    trigger="click"
                    placement="bottom-end"
                  >
                    <span class="el-dropdown-link">
                      <svg-icon icon-class="Filter" class="iconsize"></svg-icon>
                    </span>
                    <el-dropdown-menu slot="dropdown" class="noshowtriangle">
                      <el-dropdown-item
                        v-for="item in statusdropdownList"
                        :key="item.id"
                        :command="{ name: item.name, value: item.id }"
                      >
                        <span>{{ item.name }}</span>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                  <div class="tableheader"></div>
                </div>
              </div>
            </template>
            <template slot-scope="scope">
              <div>
                {{ scope.row.status_name }}
              </div>
            </template>
          </el-table-column>
          <!-- 源语言 -->
          <el-table-column prop="slang_name" show-overflow-tooltip :width="170">
            <template slot="header">
              <div class="tableheaderdiv">
                <p class="fontSize12">
                  {{ $t("CommonName.sourcelanguagetable") }}
                </p>
                <div class="icondiv">
                  <el-dropdown
                    @command="sourcelanguagecommand"
                    trigger="click"
                    placement="bottom-end"
                  >
                    <span class="el-dropdown-link">
                      <svg-icon icon-class="Filter" class="iconsize"></svg-icon>
                    </span>
                    <el-dropdown-menu
                      slot="dropdown"
                      class="tabledropdowndiv noshowtriangle"
                    >
                      <el-dropdown-item
                        v-for="item in sourcelanguagedropdownList"
                        :key="item.language_id"
                        :command="{ name: item.ename, value: item.language_id }"
                      >
                        <span>{{ item.ename }}</span>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                  <div class="tableheader"></div>
                </div>
              </div>
            </template>
          </el-table-column>
          <!-- 目标语言 -->
          <el-table-column prop="tlang_name" show-overflow-tooltip :width="170">
            <template slot="header">
              <div class="tableheaderdiv">
                <p class="fontSize12">
                  {{ $t("CommonName.targetlanguagetable") }}
                </p>
                <div class="icondiv">
                  <el-dropdown
                    @command="targetlanguagecommand"
                    trigger="click"
                    placement="bottom-end"
                  >
                    <span class="el-dropdown-link">
                      <svg-icon icon-class="Filter" class="iconsize"></svg-icon>
                    </span>
                    <el-dropdown-menu
                      slot="dropdown"
                      class="tabledropdowndiv noshowtriangle"
                    >
                      <el-dropdown-item
                        v-for="item in targetlanguagedropdownList"
                        :key="item.language_id"
                        :command="{ name: item.ename, value: item.language_id }"
                      >
                        <span>{{ item.ename }}</span>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                  <div class="tableheader"></div>
                </div>
              </div>
            </template>
          </el-table-column>
          <!-- 时长 -->
          <el-table-column prop="duration" :width="110">
            <template slot="header">
              <div class="tableheaderdiv">
                <p class="fontSize12">{{ $t("CommonName.duration") }}</p>
                <div class="icondiv">
                  <div class="tableheader"></div>
                </div>
              </div>
            </template>
            <template slot-scope="scope">
              <el-tooltip
                :disabled="
                  +scope.row.duration_srt_time <= +scope.row.duration_video_time
                "
                :content="'SRT Duration: ' + scope.row.duration_srt"
                placement="top"
              >
                <p
                  :style="{
                    color:
                      +scope.row.duration_srt_time >
                      +scope.row.duration_video_time
                        ? '#ef7373'
                        : '#cbd7e8',
                  }"
                >
                  {{ scope.row.duration }}
                </p>
              </el-tooltip>
            </template>
          </el-table-column>
          <!--句段数  -->
          <el-table-column prop="segment" :width="100">
            <template slot="header">
              <div class="tableheaderdiv">
                <p class="fontSize12">
                  {{ $t("CommonName.Numberofsentencesegments") }}
                </p>
                <div class="icondiv">
                  <div class="tableheader"></div>
                </div>
              </div>
            </template>
          </el-table-column>
          <!-- 任务执行人 -->
          <el-table-column prop="member" :width="110" show-overflow-tooltip>
            <template slot="header">
              <div class="tableheaderdiv">
                <p class="fontSize12">
                  {{ $t("ProjectManagement.Waterprint1") }}
                </p>
                <div class="icondiv">
                  <div class="tableheader"></div>
                </div>
              </div>
            </template>
          </el-table-column>
          <!-- 视频涂抹结果 -->
          <el-table-column :width="150">
            <template slot="header">
              <span>{{ $t("ProjectDetails.subtitleSmearing") }}</span>
              <span class="tableheaderspan"></span>
            </template>
            <template slot-scope="scope">
              <div style="display: flex; justify-content: space-between">
                <div
                  @click="toSubtitleDownload(scope.row, 6)"
                  :class="scope.row.class6"
                >
                  <svg-icon
                    v-if="scope.row.class6 !== 'inProgress'"
                    :icon-class="scope.row.icon6"
                  ></svg-icon>
                  <i v-else :class="scope.row.icon6"></i>
                  {{ scope.row.text6 }}
                </div>
              </div>
            </template>
          </el-table-column>
          <!-- 字幕压制结果 -->
          <el-table-column :width="150">
            <template slot="header">
              <span>{{ $t("ProjectDetails.subtitleSuppression") }}</span>
              <span class="tableheaderspan"></span>
            </template>
            <template slot-scope="scope">
              <div style="display: flex; justify-content: space-between">
                <div
                  @click="toSubtitleDownload(scope.row, 5)"
                  :class="scope.row.class5"
                >
                  <svg-icon
                    v-if="scope.row.class5 !== 'inProgress'"
                    :icon-class="scope.row.icon5"
                  ></svg-icon>
                  <i v-else :class="scope.row.icon5"></i>
                  {{ scope.row.text5 }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column width="40">
            <template slot-scope="scope">
              <div class="operationClass">
                <el-dropdown @command="commandClicks" trigger="click">
                  <span class="el-dropdown-link">
                    <svg-icon
                      icon-class="MoreOperations"
                      style="color: #cbd7e8"
                    ></svg-icon>
                  </span>
                  <el-dropdown-menu
                    slot="dropdown"
                    class="tabledropdowndiv"
                    style="max-height: 400px"
                  >
                    <el-dropdown-item
                      v-for="item in dropdownList"
                      :key="item.id"
                      :command="{ command: item.id, row: scope.row }"
                    >
                      <template>
                        <svg-icon
                          :icon-class="item.icon"
                          style="margin-right: 8px; font-size: 18px"
                        ></svg-icon>
                        <span>{{ item.name }}</span></template
                      >
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </template>
          </el-table-column>
        </template>
        <template v-else>
          <el-table-column
            type="selection"
            width="65"
            align="center"
            :reserve-selection="true"
          ></el-table-column>
          <el-table-column
            :min-width="140"
            prop="job_name"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot="header">
              <span class="fontSize12">{{ $t("CommonName.taskname") }}</span>
              <div
                class="icondiv"
                style="
                  font-size: 17px;
                  position: absolute;
                  right: 15px;
                  top: 8px;
                "
              >
                <el-popover
                  placement="bottom-end"
                  width="260"
                  v-model="visible"
                  :visible-arrow="false"
                >
                  <div class="popoverDiv">
                    <el-input
                      v-model="popoverInput"
                      :placeholder="$t('CommonName.search')"
                      maxlength="100"
                      @keyup.enter.native="searchdetermineClick"
                    ></el-input>
                    <el-button
                      type="primary"
                      class="setpadding16"
                      @click="searchdetermineClick"
                      >{{ $t("ProjectManagement.query") }}
                    </el-button>
                  </div>
                  <svg-icon
                    icon-class="Filter"
                    class="iconsize"
                    slot="reference"
                    @click.stop="jobNameClick"
                  ></svg-icon>
                </el-popover>
                <div class="tableheader"></div>
              </div>
              <span class="tableheaderspan" style="margin-top: 12px"></span>
              <!-- 任务名称 -->
            </template>
            <template slot-scope="scope">
              <div class="menuscopediv" @click="tovideoDetails(scope.row)">
                {{ scope.row.name }}
              </div>
            </template>
          </el-table-column>
          <!-- 状态 -->
          <el-table-column :width="118">
            <template slot="header">
              <div class="tableheaderdiv">
                <p class="fontSize12">{{ $t("CommonName.status") }}</p>
                <div class="icondiv">
                  <el-dropdown
                    @command="statuscommand"
                    trigger="click"
                    placement="bottom-end"
                  >
                    <span class="el-dropdown-link">
                      <svg-icon icon-class="Filter" class="iconsize"></svg-icon>
                    </span>
                    <el-dropdown-menu slot="dropdown" class="noshowtriangle">
                      <el-dropdown-item
                        v-for="item in statusdropdownList"
                        :key="item.id"
                        :command="{ name: item.name, value: item.id }"
                      >
                        <span>{{ item.name }}</span>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                  <div class="tableheader"></div>
                </div>
              </div>
            </template>
            <template slot-scope="scope">
              <div>{{ scope.row.status_name }}</div>
            </template>
          </el-table-column>
          <!-- 配音语言 -->
          <el-table-column prop="tlang_name" show-overflow-tooltip :width="170">
            <template slot="header">
              <div class="tableheaderdiv">
                <p class="fontSize12">
                  {{ $t("PersonalEndLanguage.VideoDubbingText") }}
                </p>
                <div class="icondiv">
                  <el-dropdown
                    @command="targetlanguagecommand"
                    trigger="click"
                    placement="bottom-end"
                  >
                    <span class="el-dropdown-link">
                      <svg-icon icon-class="Filter" class="iconsize"></svg-icon>
                    </span>
                    <el-dropdown-menu
                      slot="dropdown"
                      class="tabledropdowndiv noshowtriangle"
                    >
                      <el-dropdown-item
                        v-for="item in targetlanguagedropdownList"
                        :key="item.language_id"
                        :command="{ name: item.ename, value: item.language_id }"
                      >
                        <span>{{ item.ename }}</span>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                  <div class="tableheader"></div>
                </div>
              </div>
            </template>
          </el-table-column>
          <!-- 时长 -->
          <el-table-column prop="duration" :width="110">
            <template slot="header">
              <div class="tableheaderdiv">
                <p class="fontSize12">{{ $t("CommonName.duration") }}</p>
                <div class="icondiv">
                  <div class="tableheader"></div>
                </div>
              </div>
            </template>
            <template slot-scope="scope">
              <el-tooltip
                :disabled="
                  +scope.row.duration_srt_time <= +scope.row.duration_video_time
                "
                :content="'SRT Duration: ' + scope.row.duration_srt"
                placement="top"
              >
                <p
                  :style="{
                    color:
                      +scope.row.duration_srt_time >
                      +scope.row.duration_video_time
                        ? '#ef7373'
                        : '#cbd7e8',
                  }"
                >
                  {{ scope.row.duration }}
                </p>
              </el-tooltip>
            </template>
          </el-table-column>
          <!-- 任务执行人 -->
          <el-table-column prop="member" :width="110">
            <template slot="header">
              <div class="tableheaderdiv">
                <p class="fontSize12">
                  {{ $t("ProjectManagement.Waterprint1") }}
                </p>
                <div class="icondiv">
                  <div class="tableheader"></div>
                </div>
              </div>
            </template>
          </el-table-column>
          <!-- 视频配音结果 -->
          <el-table-column prop="deadline" :width="160">
            <template slot="header">
              <span>{{ $t("ProjectDetails.VideoDubbingResults") }}</span>
              <span class="tableheaderspan"></span>
            </template>
            <template slot-scope="scope">
              <div style="display: flex; justify-content: space-between">
                <div
                  @click="toSubtitleDownload(scope.row)"
                  :class="scope.row.class5"
                >
                  <svg-icon
                    v-if="scope.row.class5 !== 'inProgress'"
                    :icon-class="scope.row.icon5"
                  ></svg-icon>
                  <i v-else :class="scope.row.icon5"></i>
                  {{ scope.row.text5 }}
                </div>
                <div
                  @click="toSubtitleDownload(scope.row)"
                  :class="scope.row.class5"
                >
                  <svg-icon
                    v-if="scope.row.class5 !== 'inProgress'"
                    :icon-class="scope.row.icon5"
                  ></svg-icon>
                  <i v-else :class="scope.row.icon5"></i>
                  {{ scope.row.text5 }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column width="40">
            <template slot-scope="scope">
              <div class="operationClass">
                <el-dropdown @command="commandClicks" trigger="click">
                  <span class="el-dropdown-link">
                    <svg-icon
                      icon-class="MoreOperations"
                      style="color: #cbd7e8"
                    ></svg-icon>
                  </span>
                  <el-dropdown-menu
                    slot="dropdown"
                    class="tabledropdowndiv"
                    style="max-height: 400px"
                  >
                    <el-dropdown-item
                      v-for="item in dropdownList"
                      :key="item.id"
                      :command="{ command: item.id, row: scope.row }"
                      v-show="item.id !== '3'"
                    >
                      <template>
                        <svg-icon
                          :icon-class="item.icon"
                          style="margin-right: 8px; font-size: 18px"
                        ></svg-icon>
                        <span>{{ item.name }}</span></template
                      >
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <div class="mainBottom">
      <div class="mainHeader_right">
        <div style="margin-right: 16px">
          <el-checkbox
            v-model="jobAllSelect"
            :indeterminate="indeterminateJobAll"
            @change="jobAllChange"
            >{{ $t("CommonName.SelectAllItem") }}</el-checkbox
          >
        </div>
        <div
          v-for="(i, index) in bottomButtonList"
          :key="index"
          class="buttonList_class"
          v-show="i.status"
        >
          <el-dropdown
            @command="commandButtonClicks"
            trigger="hover"
            v-if="i.ischildren && i.status"
          >
            <el-button type="text">
              <div class="buttondiv">
                <svg-icon
                  :icon-class="i.icon"
                  class="icon"
                  style="margin-right: 5px"
                ></svg-icon>
                {{ i.name }}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </div>
            </el-button>
            <el-dropdown-menu slot="dropdown" class="tabledropdowndiv">
              <el-dropdown-item
                v-for="item in i.children"
                :key="item.id"
                :command="{ id: item.id }"
                v-show="item.status"
              >
                <span v-if="item.status">{{ item.name }}</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button type="text" @click="bottomBtnClick(i.name, i.id)" v-else>
            <div class="buttondiv">
              <svg-icon :icon-class="i.icon" class="icon"></svg-icon>
              <p>{{ i.name }}</p>
            </div>
          </el-button>
        </div>
      </div>
    </div>
    <DialogComVue
      :dialogVisible="dialogVisible"
      @determineClick="determineClick"
      @changeDialogVisible="changeDialogVisible"
      :width="dialogWidth ? dialogWidth : '888px'"
      :top="showTop ? '3%' : '10%'"
      :title="dialogtitle"
      :showTitle="showTitle"
      :footerbtnName="$t('CommonName.Sure')"
      :closefooterbtnName="$t('CommonName.Cancel')"
      v-if="dialogVisible"
      :showfooter="showfooter"
      :buttonLoading="buttonLoading"
    >
      <!--      重命名-->
      <div
        v-if="dialogtitle == $t('ProjectDetails.dropdownList.name7')"
        class="content_center"
      >
        <div class="content_center_4">
          <div class="content_center_div">
            <p>{{ $t("CommonName.taskname") }}</p>
            <el-input
              v-model="renameInput"
              class="input-with-select rename-input"
              maxlength="200"
            >
            </el-input>
          </div>
        </div>
      </div>
      <template
        v-slot:title
        v-if="
          dialogtitle === $t('ProjectDetails.buttonList.name5') ||
          dialogtitle === rowData.name ||
          dialogtitle === internalRowData.job_name
        "
      >
        <div
          v-if="dialogtitle === internalRowData.job_name"
          class="tasknameslotTitle"
        >
          <p class="title_p overflowEllipsis">
            <el-tooltip
              class="item"
              effect="dark"
              :content="dialogtitle"
              placement="top-start"
              :visible-arrow="false"
            >
              <span>{{ dialogtitle }}</span>
            </el-tooltip>
          </p>
        </div>
        <div
          class="slottitle"
          v-if="dialogtitle === $t('ProjectDetails.buttonList.name5')"
        >
          <p>{{ dialogtitle }}</p>
        </div>
        <div class="tasknameslotTitle" v-if="dialogtitle === rowData.name">
          <p class="title_p overflowEllipsis">
            <el-tooltip
              class="item"
              effect="dark"
              :content="dialogtitle"
              placement="top-start"
              :visible-arrow="false"
            >
              <span>{{ dialogtitle }}</span>
            </el-tooltip>
          </p>
          <div class="tasktitlebtn">
            <!-- 项目详情 二次弹窗 -->
            <div>
              <el-button type="text" @click="showInnerDialog(3)" v-if="isOwer">
                <div class="svgbtn">
                  <svg-icon icon-class="delete" class="icon"></svg-icon>
                  <p>{{ $t("ProjectDetails.DeleteTask") }}</p>
                </div>
              </el-button>
            </div>
            <div class="lastdiv"></div>
          </div>
        </div>
      </template>
      <!-- 术语 -->
      <TermDialog
        v-if="dialogtitle == $t('ProjectDetails.buttonList.name5')"
        @updateClick="updateClick"
      ></TermDialog>
      <!-- 指派/更改任务处理人 -->
      <AppointedTask
        v-if="dialogtitle == $t('ProjectDetails.AssignChangeTaskHandler')"
        :jobid="commandRow.job_id"
        :jobType="commandRow.job_type"
        :projectid="project_id"
        ref="AppointedTask"
      ></AppointedTask>
      <ProjectDetails
        v-if="dialogtitle === rowData.name"
        ref="ProjectDetails"
        :Project_info="rowData"
        @ProjectDetailsgetData="ProjectDetailsgetData"
        @claseDialog="claseDialog"
      ></ProjectDetails>
    </DialogComVue>
    <!-- 字幕设置 -->
    <CaptionSettings
      v-if="showCaptionSettings"
      :showdialog="showCaptionSettings"
      :projectInfo="projectInfo"
      @clickCaptionSettings="clickCaptionSettings"
    ></CaptionSettings>
    <!-- 批量指派任务执行人 -->
    <OperatorDialog
      v-if="bottomButtonId === '2'"
      :showdialog="bottomButtonId === '2'"
      :showFlag="1"
      :multipleSelection="multipleSelection"
      @closeOperator="closeOperator"
      @clickOperator="clickOperator"
    ></OperatorDialog>
    <!--    批量更换任务执行人-->
    <ReplaceExecutor
      v-if="bottomButtonId === '3'"
      :showdialog="bottomButtonId === '3'"
      :multipleSelection="multipleSelection"
      @closeOperator="closeOperator"
      @clickOperator="clickOperator"
    ></ReplaceExecutor>
    <!-- 二次 弹窗 -->
    <el-dialog
      :width="[8].includes(this.showInnerDialogs) ? '888px' : '1024px'"
      :title="innerVisibleTitle"
      :visible.sync="innerVisible"
      @close="CloseinnerVisible"
      append-to-body
      :top="[4, 5].includes(this.showInnerDialogs) ? '10%' : '3%'"
      center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :destroy-on-close="true"
    >
      <div
        class="content_center"
        :class="showInnerDialogs == 4 ? 'margintop0' : ''"
      >
        <!-- 全局内容替换 -->
        <div class="content_center_4" v-if="showInnerDialogs == 8">
          <div
            class="step1Center_center_1 radioClass radioClass50"
            style="margin-bottom: 10px"
          >
            <el-radio-group v-model="find_replace_mode">
              <el-radio label="1" border style="width: 50%"
                >{{ $t("CommonName.lookupText1") }}
              </el-radio>
              <el-radio label="2" border style="width: 50%"
                >{{ $t("CommonName.lookupText2") }}
              </el-radio>
            </el-radio-group>
          </div>
          <div class="content_center_div" style="margin-bottom: 10px">
            <p>{{ $t("OverwriteOperation.Find") }}</p>
            <el-input
              v-model="find_input"
              :maxlength="300"
              class="input1"
            ></el-input>
            <div class="loockUpandReplaceDiv">
              <span v-if="find_input !== find_input.trim()">
                {{ $t("CommonName.loockUptext") }}</span
              >
            </div>
          </div>
          <div class="content_center_div" style="margin-bottom: 10px">
            <p>{{ $t("OverwriteOperation.Replacewith") }}</p>
            <el-input
              v-model="replace_input"
              :maxlength="300"
              class="input1"
            ></el-input>
            <div class="loockUpandReplaceDiv">
              <span v-if="replace_input !== replace_input.trim()">{{
                $t("CommonName.Replacetext")
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
        style="margin-top: 24px; margin-right: 24px"
        v-if="[8].includes(this.showInnerDialogs)"
      >
        <el-button type="info" @click="CancelClick" class="setpadding23"
          >{{ $t("CommonName.Cancel") }}
        </el-button>
        <el-button type="primary" @click="SureClick" class="setpadding23"
          >{{
            [4, 5, 8].includes(this.showInnerDialogs)
              ? $t("ProjectManagement.confirm")
              : $t("CommonName.Sure")
          }}
        </el-button>
      </div>
    </el-dialog>
    <!-- 下载所有任务字幕文件 -->
    <el-dialog
      width="525px"
      :title="$t('ProjectManagement.DownloadSelectedTaskFiles')"
      :visible.sync="innerDownloadVisible"
      @close="closeinnerDialog"
      v-if="innerDownloadVisible"
      append-to-body
      top="10%"
      center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="content_center">
        <div class="content_center_3">
          <div class="content_center_div">
            <div class="checkboxClass">
              <el-checkbox-group v-model="checkboxDownload">
                <div>
                  <el-checkbox
                    v-for="i in checkboxDownloadList"
                    :label="i.id"
                    :key="i.id"
                    name="type"
                    border
                    >{{ i.name }}
                  </el-checkbox>
                </div>
              </el-checkbox-group>
            </div>
          </div>
        </div>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
        style="margin-top: 24px; margin-right: 24px"
      >
        <el-button type="info" @click="CanceldownloadClick" class="setpadding23"
          >{{ $t("CommonName.Cancel") }}
        </el-button>
        <el-button
          type="primary"
          @click="downloadSelectedSure"
          class="setpadding23"
          >{{ $t("CommonName.download") }}
        </el-button>
      </div>
    </el-dialog>
    <!-- 导出所有任务文件内容信息 -->
    <el-dialog
      width="525px"
      :title="$t('ProjectDetails.dropdownBatchList.name6')"
      :visible.sync="innerExportVisible"
      @close="closeinnerDialog"
      v-if="innerExportVisible"
      append-to-body
      top="10%"
      center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="content_center">
        <div class="content_center_3">
          <div class="content_center_div">
            <div class="checkboxClass">
              <el-checkbox-group v-model="checkboxExport">
                <div>
                  <el-checkbox
                    v-for="i in checkboxExportList"
                    :label="i.id"
                    :key="i.id"
                    name="type"
                    border
                    >{{ i.name }}
                  </el-checkbox>
                </div>
              </el-checkbox-group>
            </div>
            <!-- 备注类型 -->
            <p>{{ $t("CommonName.remarks") }}</p>
            <el-select
              v-model="commentsSelect"
              multiple
              class="content_center_div1 selectclass100"
              :placeholder="$t('CommonName.Pleaseselect')"
              filterable
            >
              <el-option
                v-for="item in ProjectCommentsData"
                :key="item.comment_id"
                :label="item.comment_name"
                :value="item.comment_id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
        style="margin-top: 24px; margin-right: 24px"
      >
        <el-button type="info" @click="CanceldownloadClick" class="setpadding23"
          >{{ $t("CommonName.Cancel") }}
        </el-button>
        <el-button
          type="primary"
          @click="ExportSelectedSure"
          class="setpadding23"
          >{{ $t("CommonName.download") }}
        </el-button>
      </div>
    </el-dialog>
    <div
      class="progressdiv"
      v-if="percentage > 0"
      v-loading.fullscreen.lock="loading"
      element-loading-custom-class="disaplaynoneicon"
      element-loading-background="rgba(0, 0, 0, 0.3)"
    >
      <el-progress
        type="circle"
        :percentage="percentage"
        :stroke-width="10"
      ></el-progress>
    </div>
    <!--字幕样式设置-->
    <SubtitleStyle
      :dialogVisible="topButtonId === '2'"
      :project_id="project_id"
      :job_list="tableData"
      :project_type="0"
      @changeAutoVisible="closeOperator"
      v-if="topButtonId === '2'"
    ></SubtitleStyle>
    <!-- 创建声音 -->
    <CustomizedAnchor
      v-if="topButtonId === '4'"
      :showDialog="topButtonId === '4'"
      :project_id="this.project_id"
      @closeDialog="closeOperator"
      @requireDialog="clickOperator"
    ></CustomizedAnchor>
    <!-- 项目声音管理 -->
    <VideoDubbingSetAnchors
      :dialogVisible="topButtonId === '5'"
      v-if="topButtonId === '5'"
      :project_id="this.project_id"
      @changeDialogVisible="closeOperator"
    ></VideoDubbingSetAnchors>
    <!-- 项目角色管理 -->
    <DubbingProjectRoles
      v-if="topButtonId === '6'"
      :showDialog="topButtonId === '6'"
      :project_id="this.project_id"
      @closeDialog="closeOperator"
      @requireDialog="clickOperator"
    ></DubbingProjectRoles>
    <!-- 视频AI字幕任务 -->
    <CreateSubTask
      v-if="showCreateTasks"
      :showdialog="showCreateTasks"
      :languageoptions="languageoptions"
      @closeCreateDialog="closeTaskDialog"
      @CreatTasks="CreateTasks"
    >
    </CreateSubTask>
    <!-- 视频配音任务 -->
    <CreateDubbingTask
      v-if="showCreateDubbingTasks"
      :showdialog="showCreateDubbingTasks"
      :createId="createId"
      :createType="createType"
      :tableData="tableData"
      :multipleSelectionArray="multipleSelection"
      @closeCreateDialog="closeTaskDialog"
      @CreatTasks="CreateTasks"
    >
    </CreateDubbingTask>
  </div>
</template>
<script>
import DialogComVue from "@/components/DialogCom.vue";
import TermDialog from "./Dialogs/TermDialog.vue";
import CaptionSettings from "./Dialogs/CaptionSettings.vue";
import AppointedTask from "./Dialogs/AppointedTask.vue";
import ProjectDetails from "./Dialogs/ProjectDetails.vue";
import OperatorDialog from "./Dialogs/OperatorDialog.vue";
import ReplaceExecutor from "./Dialogs/ReplaceExecutor.vue";
import SubtitleStyle from "./Dialogs/SubtitleStyle.vue";
import CustomizedAnchor from "./Dialogs/CustomizedAnchor.vue";
import DubbingProjectRoles from "./Dialogs/DubbingProjectRoles.vue";
import VideoDubbingSetAnchors from "./Dialogs/VideoDubbingSetAnchors.vue";
import CreateSubTask from "./Dialogs/CreateSubTask.vue";
import CreateDubbingTask from "./Dialogs/CreateDubbingTask.vue";
import $ from "jquery";
import {
  onShowNameTipsMouseenter,
  addAndreplaceTags,
} from "@/assets/js/Publicmethods";
import { get_language, customer_api_method } from "@/utils/https";
import { hideLoading, showLoading } from "@/utils/loading";
import config from "@/config";
import JSZip from "jszip";
import FileSaver from "file-saver";

export default {
  components: {
    DialogComVue,
    TermDialog, //术语
    CaptionSettings, //字幕设置
    AppointedTask, //指派/更改任务处理人.
    ProjectDetails, //任务详情
    OperatorDialog, //批量指派任务执行人
    ReplaceExecutor, //批量更换任务执行人
    SubtitleStyle, //字幕样式设置
    CustomizedAnchor, //创建声音
    DubbingProjectRoles, //项目角色管理
    VideoDubbingSetAnchors, //项目声音管理
    CreateSubTask, //创建视频AI字幕任务
    CreateDubbingTask, //创建视频配音字幕任务
  },
  created() {
    // this.title = this.$route.query.name;
    this.project_id = this.$route.query.id;
    this.folder_pid = this.$route.query.fid;
  },
  computed: {
    // 筛选 任务执行人
    filteredOptions() {
      return this.dropdownLists.filter((option) =>
        option.nickname.toLowerCase().includes(this.searchText.toLowerCase())
      );
    },
  },
  mounted() {
    // console.log(111111);
    this.get_language();
    hideLoading();
    showLoading(this.$t("CommonName.loadingmessage"));
    let info = JSON.parse(sessionStorage.getItem("info"));
    // this.user_id = info.user_id;
    if (info.role == 3) {
      this.isOwer1 = true;
    }
    this.get_project_info(this.project_id).then((res) => {
      // console.log(res);
      this.projectInfo = res;
      this.sort = res.sort_mode === "0" ? "asc" : "desc";
      this.title = res.project_name;
      this.get_team_job_list();
      //底部功能栏筛选
      // this.handleClicktab();
      this.isOwer = res.create_id == info.user_id;

      this.buttonList.forEach((item) => {
        if (this.isOwer1) {
          if (["0", "6"].includes(item.id)) {
            item.status = true;
          }
        }
        if (this.isOwer) {
          if (["1", "2"].includes(item.id)) {
            item.status = true;
          }
        }
      });

      this.dropdownBatchList.forEach((item) => {
        if (this.isOwer1) {
          if (["5", "6", "7", "9", "10", "16", "17", "20"].includes(item.id)) {
            item.status = true;
          }
          if (parseInt(res.wiitube_type) !== 0 && item.id === "8") {
            item.status = false;
          }
        }
      });
      this.dropdownList.forEach((item) => {
        if (this.isOwer1) {
          if (["1", "2", "8"].includes(item.id)) {
            item.status = true;
          }
        }
        if (this.isOwer) {
          if (["4", "5"].includes(item.id)) {
            item.status = true;
          }
        }
      });
    });
    this.$nextTick(() => {
      this.tableHeight = window.innerHeight - 270;
    });
    window.onresize = () => {
      this.tableHeight = window.innerHeight - 270;
    };
    window.addEventListener("keyup", this.windowkeyup, true);
    window.addEventListener("keydown", this.windowkeydown, true);
  },
  data() {
    return {
      createJobList: [
        //任务类型列表
        {
          id: 1,
          icon: "VideoTranslation",
          // 视频AI字幕
          name: this.$t("PersonalEndLanguage.Videotranscription"),
          explain: this.$t("PersonalEndLanguage.illustrate1"),
          color: "#FFB860FF",
          tab_name: "first",
          task_name: this.$t("ProjectDetails.Videotranscription"),
          task_num: 0,
        },
        {
          id: 2,
          icon: "shipinpeiyin_AI",
          // 视频配音
          name: this.$t("PersonalEndLanguage.Videodubbing"),
          explain: this.$t("PersonalEndLanguage.illustrate2"),
          color: "#4EEDFF",
          tab_name: "second",
          task_name: this.$t("ProjectDetails.Videodubbing"),
          task_num: 0,
        },
        {
          id: 3,
          icon: "shipinkelongpeiyin_damoxing",
          //视频克隆配音大模型
          name: this.$t("PersonalEndLanguage.VideoCloningDubbing"),
          explain: this.$t("PersonalEndLanguage.illustrate3"),
          color: "#747886",
          tab_name: "third",
          task_name: this.$t("ProjectDetails.VideoCloningDubbing"),
          task_num: 0,
        },
      ],
      tabname: "first", //tab页切换
      topButtonList: [
        //顶部按钮列表
        {
          id: "1", //字幕规则设置
          name: this.$t("ProjectDetails.buttonList.name24"),
          icon: "Font_style",
          status: true,
          ischildren: false,
        },
        {
          id: "2", //字幕样式设置
          name: this.$t("ProjectDetails.buttonList.name23"),
          icon: "Title_style_setting",
          status: true,
          ischildren: false,
        },
        {
          id: "3", //术语管理
          name: this.$t("ProjectDetails.buttonList.name5"),
          icon: "Glossaries",
          status: true,
          ischildren: false,
        },
        {
          id: "4", //创建声音
          name: this.$t("ProjectDetails.buttonList.name27"),
          icon: "Single_Dubbing",
          status: false,
          ischildren: false,
        },
        {
          id: "5", //项目声音管理
          name: this.$t("ProjectDetails.buttonList.name28"),
          icon: "List_of_anchors",
          status: false,
          ischildren: false,
        },
        {
          id: "6", //项目角色管理
          name: this.$t("ProjectDetails.buttonList.name29"),
          icon: "List_of_anchors",
          status: false,
          ischildren: false,
        },
      ],
      bottomButtonList: [
        //底部按钮列表
        {
          id: "1", //打开任务
          name: this.$t("ProjectDetails.buttonList.name25"),
          icon: "file_open",
          status: true,
          ischildren: false,
        },
        {
          id: "2", //指定执行人
          name: this.$t("ProjectDetails.bottomButtonList.name14"),
          icon: "assign",
          status: true,
          ischildren: false,
        },
        {
          id: "3", //更换执行人
          name: this.$t("ProjectDetails.bottomButtonList.name15"),
          icon: "assign",
          status: true,
          ischildren: false,
        },
        {
          id: "4", //取消提交任务
          name: this.$t("ProjectDetails.bottomButtonList.name1"),
          icon: "RestoreTargetText",
          status: true,
          ischildren: false,
        },
        {
          id: "5", //导出字幕
          name: this.$t("ProjectDetails.bottomButtonList.name2"),
          icon: "download",
          status: true,
          ischildren: false,
        },
        {
          id: "13", //导出视频(已涂抹)
          name: this.$t("ProjectDetails.bottomButtonList.name16"),
          icon: "download",
          status: true,
          ischildren: false,
        },
        {
          id: "6", //导出视频(带字幕)
          name: this.$t("ProjectDetails.bottomButtonList.name3"),
          icon: "download",
          status: true,
          ischildren: false,
        },
        {
          id: "7", //为字幕配音(AI)
          name: this.$t("ProjectDetails.bottomButtonList.name4"),
          icon: "Single_Dubbing",
          status: false,
          ischildren: false,
        },
        {
          id: "8", //为字幕配音(克隆原声)
          name: this.$t("ProjectDetails.bottomButtonList.name5"),
          icon: "Single_Dubbing",
          status: false,
          ischildren: false,
        },
        {
          id: "9", //导出台词(按角色)
          name: this.$t("ProjectDetails.bottomButtonList.name6"),
          icon: "download",
          status: false,
          ischildren: false,
        },
        {
          id: "10", //导出背景音
          name: this.$t("ProjectDetails.bottomButtonList.name7"),
          icon: "download",
          status: false,
          ischildren: false,
        },
        {
          id: "11", //导出配音结果
          name: this.$t("ProjectDetails.bottomButtonList.name8"),
          icon: "download",
          status: false,
          ischildren: true,
          children: [
            {
              id: "11_1", //导出音频(按音轨)
              name: this.$t("ProjectDetails.bottomButtonList.name9"),
              status: true,
            },
            {
              id: "11_2", //导出音频(按角色)
              name: this.$t("ProjectDetails.bottomButtonList.name10"),
              status: true,
            },
            {
              id: "11_3", //导出音频(混音)
              name: this.$t("ProjectDetails.bottomButtonList.name11"),
              status: true,
            },
            {
              id: "11_4", //导出视频
              name: this.$t("ProjectDetails.bottomButtonList.name12"),
              status: true,
            },
            {
              id: "11_5", //导出视频(带字幕)
              name: this.$t("ProjectDetails.bottomButtonList.name3"),
              status: true,
            },
          ],
        },
        {
          id: "12", //删除任务
          name: this.$t("ProjectDetails.dropdownList.name5"),
          icon: "delete3",
          status: true,
          ischildren: false,
        },
      ],
      topButtonId: "", //头部按钮弹窗ID
      bottomButtonId: "", //低部按钮弹窗ID
      btnchId: "", //批量更换任务执行人 6_2
      createId: "", //创建任务类型ID
      createType: "0", //创建任务类型
      showCreateTasks: false, //创建任务窗口开关
      showCreateDubbingTasks: false, //创建配音任务窗口开关

      projectInfo: {}, //任务数据
      buttonLoading: false, //弹窗 确定按钮 loading
      innerVisible: false, //内层弹窗 开关
      innerVisibleTitle: "", //二次弹窗 标题
      showInnerDialogs: 0, //显示 哪个弹窗内容
      dialogtableData: [], //弹窗内表格
      selectList: [],
      selectList1: [],
      xlsupload: "", // 导入 excel
      showUpload: 0,
      select: {}, //主tm
      select1: {}, //临时tm t
      select2: {}, //临时tm e
      project_id: "",
      tags: [],
      title: "",
      popoverInput: "",
      visible: false,
      OperationprocessdropdownList: [
        {
          id: "1",
          name: this.$t("MyFiles.jobType1"), //"视频转写",
        },
        {
          id: "2",
          name: this.$t("MyFiles.jobType2"), // "字幕翻译",
        },
      ], //操作流程下拉框
      statusdropdownList: [
        {
          id: "20",
          name: this.$t("ProjectManagement.haveinhand"), //进行中,
          class: "blueClass",
        },
        {
          id: "50",
          name: this.$t("CommonName.Completed"), // 已完成,
          class: "greenClass",
        },
      ], //状态 下拉框
      sourcelanguagedropdownList: [], //源语言下拉框
      targetlanguagedropdownList: [], //目标语言 下拉框
      showTop: false,
      dialogWidth: false, //把弹窗的宽度改成1024px
      showTitle: false, //是否自定义 头部
      tableHeight: 0, //表格高度
      dialogVisible: false, //弹窗 开关
      dialogtitle: "", //弹窗标题
      multipleSelection: [], //全选
      ShiftDown: false, //shift 按下
      CtrlDown: false, // ctrl 按下
      startrow: {}, //选中 按下第一个
      showfooter: false, //是否显示弹窗按钮
      showCreateTaskdialog: false, //显示创建任务弹窗
      DetermineClickCreateTask: "1", //判断是从哪里点击 进入创建任务的
      Clonejobinfo: {}, //克隆任务 默认选项
      showImportTask: false, //显示导入任务弹窗
      showCaptionSettings: false, //显示 字幕设置
      buttonList: [
        {
          id: "6",
          name: this.$t("ProjectDetails.buttonList.name21"),
          icon: "assign",
          status: false,
          ischildren: true,
          children: [
            {
              id: "6_1",
              name: this.$t("ProjectDetails.buttonList.name7"),
              status: true,
            },
            {
              id: "6_2",
              name: this.$t("ProjectDetails.buttonList.name22"),
              status: true,
            },
          ],
        },
        {
          id: "0",
          name: this.$t("ProjectDetails.buttonList.name1"),
          icon: "Import",
          status: false,
          ischildren: false,
        },
        {
          id: "1",
          name: this.$t("ProjectDetails.buttonList.name2"),
          icon: "create",
          status: false,
          ischildren: false,
        },
        {
          id: "2",
          name: this.$t("ProjectDetails.buttonList.name3"),
          icon: "ChannelManagement",
          status: false,
          ischildren: true,
          children: [
            {
              id: "2_1",
              name: this.$t("ProjectDetails.buttonList.name3"),
              status: true,
            },
            {
              id: "2_2",
              name: this.$t("ProjectDetails.buttonList.name8"),
              status: true,
            },
            {
              id: "2_3",
              name: this.$t("ProjectDetails.buttonList.name26"),
              status: true,
            },
          ],
        },
        // {
        //   id: "7",
        //   name: this.$t("ProjectDetails.buttonList.name8"),
        //   icon: "create",
        //   status: true,
        //   ischildren: false,
        // },
        // {
        //   id: "2",
        //   name: this.$t("ProjectDetails.buttonList.name3"),
        //   icon: "BatchAdd",
        //   status: false,
        //   ischildren: false,
        // },
        // {
        //   id: "3",
        //   name: this.$t("ProjectDetails.buttonList.name4"),
        //   icon: "Details",
        // },
        {
          id: "4",
          name: this.$t("ProjectDetails.buttonList.name5"),
          icon: "Glossaries",
          status: true,
          ischildren: false,
        },
        {
          id: "5",
          name: this.$t("ProjectDetails.buttonList.name6"),
          icon: "ChannelManagement",
          status: true,
          ischildren: true,
          children: [
            {
              id: "5_1",
              name: this.$t("ProjectDetails.buttonList.name24"),
              status: true,
            },
            {
              id: "5_2",
              name: this.$t("ProjectDetails.buttonList.name23"),
              status: true,
            },
          ],
        },
        {
          id: "8",
          name: this.$t("ProjectDetails.buttonList.name25"),
          icon: "file_open",
          status: true,
          ischildren: true,
          children: [
            {
              id: "8_1",
              name: this.$t("ProjectDetails.buttonList.name25"),
              status: true,
            },
            {
              id: "8_2",
              name: this.$t("ProjectDetails.dropdownBatchList.name32"),
              status: true,
            },
          ],
        },
      ],
      // 表格内部 操作
      dropdownList: [
        {
          name: this.$t("ProjectDetails.dropdownList.name7"),
          icon: "Edit",
          id: "1", //重命名
          status: true,
        },
        {
          name: this.$t("ProjectDetails.dropdownList.name6"),
          icon: "assign",
          id: "2", //更改指派人
          status: true,
        },
        // {
        //   name: this.$t("ProjectDetails.dropdownList.name4"),
        //   icon: "Copy",
        //   id: "3", //克隆任务
        //   status: false,
        // },
        {
          name: this.$t("ProjectDetails.dropdownList.name5"),
          icon: "delete",
          id: "4", //删除任务
          status: false,
        },
      ],
      dropdownBatchList: [
        {
          name: this.$t("ProjectDetails.dropdownBatchList.name7"),
          id: "1", // Download All Subtitle File (DOCX)
          status: true,
        },
        {
          name: this.$t("ProjectDetails.downloadText8"),
          id: "21",
          status: true,
        },
        // {
        //   name: this.$t("ProjectDetails.dropdownBatchList.name2"),
        //   id: "2", // Download All Subtitle File (SRT Source)
        //   status: true,
        // },
        // {
        //   name: this.$t("ProjectDetails.dropdownBatchList.name3"),
        //   id: "3", // Download All Subtitle File (SRT Target)
        //   status: true,
        // },
        // {
        //   name: this.$t("ProjectDetails.dropdownBatchList.name4"),
        //   id: "4", // Download All ttml File (SRT Target)
        //status:true
        // },
        {
          name: this.$t("ProjectDetails.dropdownBatchList.name5"),
          id: "5", // Export Task URLs
          status: false,
        },
        {
          name: this.$t("ProjectDetails.dropdownBatchList.name6"),
          id: "6", // Export Excel Files
          status: false,
        },
        {
          name: this.$t("ProjectDetails.dropdownBatchList.name28"),
          id: "20", // 导出软字幕错误检查报告
          status: false,
        },
        {
          name: this.$t("ProjectDetails.dropdownBatchList.name22"),
          id: "16", // move job
          status: false,
        },
        {
          name: this.$t("ProjectDetails.dropdownBatchList.name23"),
          id: "17", // update name
          status: false,
        },
        {
          name: this.$t("ProjectDetails.dropdownBatchList.name8"),
          id: "7", // Run AIT
          status: false,
        },
        {
          name: this.$t("ProjectDetails.dropdownBatchList.name10"),
          id: "9", // Close AIT
          status: false,
        },
        // {
        //   name: this.$t("ProjectDetails.dropdownBatchList.name13"),
        //   id: "10", // Stop AIT
        //   status: false,
        // },
        {
          name: this.$t("ProjectDetails.dropdownBatchList.name16"),
          id: "12", // Run MT
          status: true,
        },
        {
          name: this.$t("ProjectDetails.dropdownBatchList.name9"),
          id: "8", // Close Task
          status: true,
        },
        {
          name: this.$t("ProjectDetails.dropdownBatchList.name24"),
          id: "18", // Delete Task
          status: true,
        },
        {
          name: this.$t("ProjectDetails.dropdownBatchList.name14"),
          id: "11", //批量打回
          status: true,
        },
        {
          name: this.$t("ProjectDetails.dropdownBatchList.name20"),
          id: "14", //  批量打开转写任务
          status: false,
        },
        {
          name: this.$t("ProjectDetails.dropdownBatchList.name21"),
          id: "15", //  批量打开翻译任务
          status: false,
        },
        {
          name: this.$t("ProjectDetails.dropdownBatchList.name17"),
          id: "13", //  批量打开校审流程
          status: false,
        },
        {
          name: this.$t("ProjectDetails.dropdownBatchList.name27"),
          id: "19", //  批量打开校轴任务
          status: false,
        },
        {
          name: this.$t("ProjectDetails.dropdownBatchList.name30"),
          id: "22", //  从其他项目导入AIT
          status: true,
        },
        {
          name: this.$t("ProjectDetails.dropdownBatchList.name33"),
          id: "23", //  从其他项目导入画面字
          status: true,
        },
        {
          name: this.$t("ProjectDetails.dropdownBatchList.name36"),
          id: "24", //  批量替换任务原文SRT
          status: true,
        },
        {
          name: this.$t("ProjectDetails.dropdownBatchList.name37"),
          id: "25", //  批量更新任务时间轴和原文
          status: true,
        },
        {
          name: this.$t("ProjectDetails.AssignChangeTaskHandler1"),
          id: "26", //  设置项目默认人员
          status: true,
        },
      ],
      tableData: [],
      rowData: {},
      internalRowData: {}, //内部表格弹窗
      dropdownLists: [], //任务处理人数据
      searchText: "", //搜索 任务处理人
      // 上传 新的术语表  数据
      loading: false,
      percentage: 0,
      uploadData: {},
      formLabelAlign: {
        name: "",
        select1: "",
        upload: "",
        comments: "",
      },
      languageoptions: [],
      commandRow: {}, //获取 点击表格 行数据
      older: "job_name",
      sort: "",
      baseUrl: config.baseUrl.dev, //请求地址
      renameInput: "", // 重命名
      formTermData: {
        name: "",
        upload: "",
        term_file_id: "",
        mode: "0",
      },
      time: null,
      isOwer: false,
      isOwer1: false, //内部
      innerDownloadVisible: false, //内部
      checkboxDownload: ["3"],
      checkboxDownloadList: [
        { id: "1", name: this.$t("ProjectDetails.dropdownBatchList.name1") },
        { id: "2", name: this.$t("ProjectDetails.dropdownBatchList.name2") },
        { id: "3", name: this.$t("ProjectDetails.dropdownBatchList.name3") },
        { id: "4", name: this.$t("ProjectDetails.dropdownBatchList.name11") },
        { id: "5", name: this.$t("ProjectDetails.dropdownBatchList.name12") },
        { id: "6", name: this.$t("ProjectDetails.dropdownBatchList.name25") },
        { id: "7", name: this.$t("ProjectDetails.dropdownBatchList.name26") },
        { id: "8", name: this.$t("ProjectDetails.dropdownBatchList.name29") },
        { id: "9", name: this.$t("ProjectDetails.dropdownBatchList.name34") },
        { id: "10", name: this.$t("ProjectDetails.dropdownBatchList.name35") },
      ],
      folder_pid: 0,
      innerExportVisible: false, //内部
      checkboxExport: ["2"],
      checkboxExportList: [
        { id: "1", name: this.$t("ProjectDetails.dropExportBatchList.name1") },
        { id: "2", name: this.$t("ProjectDetails.dropExportBatchList.name2") },
      ],
      aitUpload: "", // 导入 AIT
      mediaUrl: config.mediaUrl.dev, //跳转地址
      defaultProps: {
        children: "children",
        label: "label",
      },
      move_project_id: "0", //移动项目id
      moveProjectSearchInput: "", //移动搜索内容
      timeOut: null,
      filterText: "",
      radio2: "0",
      radioList2: [
        {
          label: "0",
          name: this.$t("ProjectDetails.labelText0"),
        },
        {
          label: "1",
          name: this.$t("ProjectDetails.labelText1"),
        },
      ],
      replaceinput1: "", //被替换
      replaceinput2: "", //替换成
      replaceinput3: "", //追加
      term_select: "",
      term_file_mode: "2",
      term_name: "",
      term_lang: "",
      tlangs: [],
      termData: [],
      ProjectCommentsData: [],
      commentsSelect: [],
      find_replace_mode: "1",
      find_input: "",
      replace_input: "",
      jobIds: "",
      projectList: [],
      projectSelected: "",
      jobSelected: "",
      jobList: [],
      import_type: "1", //1：从项目导入；2：从任务导入
      import_job_id: "", //被导入任务id
      import_title: "",
      import_tips: "",
      zipUpload: "", // 导入 zip
      jobAllSelect: false,
      indeterminateJobAll: false,
      timer: null,
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  beforeDestroy() {
    window.removeEventListener("keyup ", this.windowkeyup, true);
    window.removeEventListener("keydown", this.windowkeydown, true);
  },
  methods: {
    /**
     * 点击关闭 创建任务
     * @param val
     */
    closeTaskDialog(val) {
      this.showCreateTasks = val;
      this.showCreateDubbingTasks = val;
    },
    /**
     *  点击创建 任务
     * @param val
     * @constructor
     */
    CreateTasks(val) {
      this.get_team_job_list().then(() => {
        this.showCreateTasks = val;
        this.showCreateDubbingTasks = val;
        this.$messages(
          "success",
          this.$t("ExternalCreateTask.successmessage1")
        );
      });
    },
    /**
     * 压制结果导出
     */
    toSubtitleDownload(row, mode) {
      console.log(row);
      let path = "";
      let name = row.name;
      if (name.lastIndexOf(".") > -1) {
        name = name.slice(0, name.lastIndexOf(".")) + ".mp4";
      } else {
        name = name + ".mp4";
      }
      switch (mode) {
        case 5:
          break;
        case 6:
          path = row.file_smearing_path;
          this.download_method(path, name);
          break;
      }
    },
    /**
     * 底部全选按钮
     */
    jobAllChange() {
      this.$refs.multipleTable.toggleAllSelection();
    },
    /**
     * 创建任务按钮
     */
    createJobClick(item) {
      // console.log(item);
      if (item.id != 1) {
        return false;
      }
      this.createId = item.id;
      if (item.id == 1) {
        this.showCreateTasks = true;
      } else {
        this.createType = "0";
        this.showCreateDubbingTasks = true;
      }
    },
    /**
     * 任务类型切换
     */
    handleClicktab() {
      // console.log(this.tabname);
      showLoading(this.$t("CommonName.loadingmessage"));
      let taskType = 1;
      if (this.tabname == "second") {
        taskType = 2;
      } else if (this.tabname == "third") {
        taskType = 3;
      }
      this.multipleSelection = [];
      this.get_team_job_list(taskType).then(() => {
        this.jobAllSelect = false;
        this.indeterminateJobAll = false;
        this.bottomButtonList.forEach((item) => {
          if (this.tabname == "first") {
            if (["1", "6", "7", "8"].includes(item.id)) {
              item.status = true;
            } else if (["9", "10", "11"].includes(item.id)) {
              item.status = false;
            }
          } else {
            if (["1", "6", "7", "8"].includes(item.id)) {
              item.status = false;
            } else if (["9", "10", "11"].includes(item.id)) {
              item.status = true;
            }
          }
        });
        hideLoading();
      });
    },
    /**
     *
     * @param {* 指定项目执行人（批量）} id
     */
    set_project_user_batch(arr) {
      // console.log(arr);
      arr.forEach((i) => {
        if (i.user_id == "") {
          i.user_id = "0";
        }
      });
      return customer_api_method({
        action: "set_project_user_batch",
        project_id: this.project_id,
        list: arr,
      }).then((res) => {
        if (!res) {
          this.buttonLoading = false;
          return;
        }
      });
    },
    /**全局内容替换
     *
     * @param {*}
     */
    replace_content_method() {
      return customer_api_method({
        action: "replace_job_content",
        type: this.find_replace_mode,
        replace_source: this.find_input,
        replace_target: this.replace_input,
        job_id: this.rowData.job_id,
      }).then((res) => {
        if (!res) {
          return res;
        }
        return res;
      });
    },
    windowkeyup(e) {
      if (e.keyCode === 16) this.ShiftDown = false;
      if (e.keyCode === 17) this.CtrlDown = false;
    },
    windowkeydown(e) {
      // console.log(e.keyCode);
      if (e.keyCode === 16 && !e.ctrlKey && !e.altKey) {
        e.stopPropagation();
        e.returnValue = false;
        this.ShiftDown = true;
      }
      if (e.keyCode === 17 && !e.shiftKey && !e.altKey) {
        e.stopPropagation();
        e.returnValue = false;
        this.CtrlDown = true;
      }
    },
    //  !========================================通用方法================================
    /**
     *
     * @param {* 整理数据} i
     */
    setTableData(i) {
      //类型
      switch (i.job_type) {
        case "1":
          //视频转写
          i.type_name = this.$t("MyFiles.jobType1");
          break;
        case "2":
          //视频翻译
          i.type_name = this.$t("MyFiles.jobType2");
          break;
        case "3":
          i.type_name = this.$t("MyFiles.jobType3");
          break;
        case "9":
          i.type_name = this.$t("MyFiles.jobType9");
          break;
        case "11":
          i.type_name = this.$t("MyFiles.jobType11");
          break;
        default:
          i.type_name = this.$t("MyFiles.jobType1");
      }
      if (i.status == "10") {
        i.status_name = this.$t("ProjectDetails.Pending");
      } else if (i.status == "50") {
        i.status_name = this.$t("CommonName.Completed");
      } else {
        i.status_name = this.$t("ProjectManagement.haveinhand");
      }
      //视频（带字幕）
      i.text5 = this.$t("ProjectDetails.tableText");
      i.icon5 = "IconParkOutlineMovie";
      i.class5 = "disabled";
      // i.icon1 = "IconParkOutlineMovie";
      if (i.dubbing_suppression_path) {
        i.class5 = "done";
      } else if (i.file_suppression_status == "1") {
        i.class5 = "inProgress";
        i.icon5 = "el-icon-loading";
      }
      //视频（已涂抹）
      i.text6 = "N/A";
      i.icon6 = "";
      i.class6 = "disabled";
      // i.icon1 = "IconParkOutlineMovie";
      if (i.file_smearing_path) {
        i.class6 = "done";
        i.icon6 = "IconParkOutlineMovie";
        i.text6 = this.$t("ProjectDetails.tableText1");
      } else if (i.file_smearing_status == "1") {
        i.class6 = "inProgress";
        i.icon6 = "el-icon-loading";
        i.text6 = this.$t("ProjectDetails.tableText1");
      }

      return i;
    },
    /**
     *
     * @param {*内部弹窗 关闭按钮} data
     */
    CancelClick() {
      this.innerVisible = false;
    },
    /**
     *
     * @param {内部弹窗 确定按钮} data
     */
    SureClick() {
      if (this.showInnerDialogs == 8) {
        showLoading(this.$t("CommonName.loadingmessage"));
        // console.log(arr);
        this.replace_content_method().then((res) => {
          if (+res.count == 0) {
            this.$messages("warning", this.$t("CommonName.findText1"));
          } else {
            this.$messages(
              "success",
              this.$t("CommonName.findText2") + res.count
            );
          }

          hideLoading();
          this.innerVisible = false;
          //调用子组件方法 及时更新数据显示
        });
      }
    },
    /**
     *
     * @param {*内部弹窗 关闭} data
     */
    CloseinnerVisible() {
      this.innerVisible = false;
    },
    /**
     *
     * @param {*下拉菜单 出现 清空输入框内容} val
     */
    visibleChange(val) {
      if (val) {
        this.searchText = "";
      }
    },
    /**
     * 复制链接
     * @param url
     */
    copyClick(url, data = 0) {
      // data == -1 复制 链接
      console.log(url);
      if (data == "-1") {
        let input = document.createElement("input"); // js创建一个input输入框
        input.value = url; // 将需要复制的文本赋值到创建的input输入框中
        document.body.appendChild(input); // 将输入框暂时创建到实例里面
        input.select(); // 选中输入框中的内容
        document.execCommand("Copy"); // 执行复制操作
        document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
        this.$messages("success", this.$t("CommonName.Copysucceeded"));
      } else {
        let input = document.createElement("textarea"); // js创建一个input输入框
        input.value =
          this.$t("CommonName.TaskName") +
          " : " +
          url.job_name +
          "\n" +
          this.$t("CommonName.WorkflowNode") +
          " : " +
          (url.workflow === "1"
            ? this.$t("ProjectManagement.STTname")
            : url.workflow === "2"
            ? this.$t("ProjectManagement.TRAname")
            : url.workflow === "5"
            ? this.$t("ProjectManagement.STQname")
            : this.$t("ProjectManagement.REVname")) +
          "\n" +
          this.$t("ProjectManagement.TaskURL") +
          " : " +
          url.newTaskUrl +
          "\n" +
          this.$t("OverwriteOperation.verif_text6") +
          " : " +
          url.check_code; // 将需要复制的文本赋值到创建的input输入框中
        document.body.appendChild(input); // 将输入框暂时创建到实例里面
        input.select(); // 选中输入框中的内容
        document.execCommand("Copy"); // 执行复制操作
        document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
        this.$messages("success", this.$t("CommonName.Copysucceeded"));
      }
    },
    /**
     *
     * @param {*点击 二次弹窗} data
     */
    showInnerDialog(data) {
      this.showInnerDialogs = data;
      switch (data) {
        case 3:
          this.$confirm(
            this.$t("CommonName.deleteMessage1"),
            this.$t("CommonName.deleteMessageTitle"),
            {
              confirmButtonText: this.$t("CommonName.deleteMessagebutton"),
              cancelButtonText: this.$t("CommonName.deleteMessageClosebutton"),
              closeOnClickModal: false,
              cancelButtonClass: "closeButton",
              // confirmButtonClass: "confirmButton",
            }
          ).then(() => {
            showLoading(this.$t("CommonName.loadingmessage"));
            this.delete_job(this.rowData.job_id).then(() => {
              this.innerVisible = false;
              this.dialogVisible = false;
            });
          });
          break;
        default:
          break;
      }
    },
    // ！======================================== 接口调用===============================
    /**
     * 获取任务列表信息
     *  @param {*任务类型}
     *
     */
    get_team_job_list() {
      let multipleSelection = $.extend(true, [], this.multipleSelection);
      this.multipleSelection = [];
      let start_job_id = this.startrow.job_id;
      let data = { action: "get_job_list", project_id: this.project_id };
      if (this.tabname == "second") {
        data.action = "get_job_list";
      } else if (this.tabname == "third") {
        data.action = "get_job_list";
      }
      if (this.older != "" && this.sort != "") {
        data["older"] = this.older;
        data["sort"] = this.sort;
      }
      this.tags.forEach((item, index) => {
        data[item.key] = item.value;
      });
      // this.tableData = [];
      return customer_api_method(data).then((res) => {
        if (res) {
          // console.log(res.list);
          this.$refs.multipleTable.clearSelection();
          let arr = res.list;
          let ids = []; //压制中的id集合
          this.startrow = {};
          // console.log("multipleSelection", multipleSelection);
          arr.forEach((i, index) => {
            if (multipleSelection.length > 0) {
              const targetItem = multipleSelection.find(
                (item) => item.job_id == i.job_id
              );
              // console.log("targetItem", targetItem);
              if (targetItem) {
                this.$refs.multipleTable.toggleRowSelection(i);
                this.multipleSelection.push(i);
              }
            }
            if (!!start_job_id == true && start_job_id == i.job_id) {
              this.startrow = i;
            }
            i.index = index;
            if (i.file_smearing_status == "1" && i.file_smearing_path == "") {
              ids.push(i.job_id);
            }
            // if (["1", "2", "5", "7"].includes(i.job_type)) {
            //   if (i.status == "10") {
            //     ids.push(i.job_id);
            //   }
            // }
            i = this.setTableData(i);
          });
          // console.log(this.multipleSelection, "this.multipleSelection");
          console.log(ids);
          if (ids.length > 0) {
            // this.getTranscribingJobs(ids);
          } else {
            clearTimeout(this.timer);
          }
          this.tableData = res.list;
          hideLoading();
        }
      });
    },
    /**
     *
     * @param {*获取项目详情信息} id
     */
    get_project_info(id) {
      return customer_api_method({
        action: "get_project_info",
        project_id: id,
      }).then((res) => {
        if (!res) {
          return;
        }
        return res.info;
      });
    },
    /**
     *
     * @param {*删除任务} id
     */
    delete_job(id) {
      return customer_api_method({
        action: "delete_job",
        job_id: id,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.get_team_job_list();
      });
    },
    /**
     * 获取语言接口
     */
    get_language() {
      get_language({
        action: "get_language",
        lang:
          sessionStorage.getItem("lang") == "cn" ||
          sessionStorage.getItem("lang") == null
            ? 1
            : 2,
      }).then((res) => {
        if (res) {
          this.languageoptions = res.list;
          this.sourcelanguagedropdownList = res.list;
          this.targetlanguagedropdownList = res.list;
        }
      });
    },

    /**
     *
     * @param {* 指定任务执行人（批量）} id
     */
    set_job_user_batch(id) {
      // console.log(arr);
      return customer_api_method({
        action: "set_job_user",
        job_id: this.commandRow.job_id,
        type: 1, //任务流程，1STT，2TRA，3REV
        user_id: id,
      }).then((res) => {
        if (!res) {
          this.buttonLoading = false;
          return;
        }
      });
    },
    /**
     *
     * @param {*导出任务内容（Excel）} row
     */
    export_excel_task_content(row) {
      let url = this.baseUrl + "/videojob/export_excel_task_content?";
      url += "project_id=" + this.project_id;
      url += "&job_id=" + row.job_id;
      url += "&job_file_id=" + row.job_file_id;
      url += "&time=" + new Date().getTime();
      window.open(url);
      // export_excel_task_content({
      //   action: "export_excel_task_content",
      //   project_id: this.project_id,
      //   job_id: row.job_id,
      //   job_file_id: row.job_file_id,
      // }).then((res) => {
      //   if (res.result == "FAILED") {
      //     this.$messages("error", res.msg);
      //     return;
      //   }
      //   const blob = new Blob([res], {
      //     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
      //   });
      //   let downloadElement = document.createElement("a");
      //   let href = window.URL.createObjectURL(blob); //创建下载的链接
      //   downloadElement.href = href;
      //   downloadElement.download = `${row.name}.xlsx`;
      //   document.body.appendChild(downloadElement);
      //   downloadElement.click(); //点击下载
      //   document.body.removeChild(downloadElement); //下载完成移除元素
      //   window.URL.revokeObjectURL(href);
      // });
    },
    /**
     *
     * @param {获取任务TM选择信息} data
     */
    get_job_tm_list() {
      return customer_api_method({
        action: "get_job_tm_list",
        project_id: this.project_id,
        job_id: this.rowData.job_id,
      }).then((res) => {
        if (!res) {
          return;
        }
        return res.list;
      });
    },
    /**
     *
     * @param {*更新任务TM选择信息} arr
     */
    update_job_tm_list(arr) {
      return customer_api_method({
        action: "update_job_tm_list",
        project_id: this.project_id,
        job_id: this.rowData.job_id,
        list: arr,
      }).then((res) => {
        if (!res) {
          return;
        }
      });
    },
    /**
     *
     * @param {*设置任务术语表信息} arr
     */
    set_job_term(id) {
      return customer_api_method({
        action: "set_job_term",
        term_file_id: id,
        term_file_mode: this.term_file_mode,
        term_name: this.term_name,
        term_lang: this.term_lang,
        tlangs: this.tlangs.join(","),
        job_id: this.rowData.job_id,
      }).then((res) => {
        if (!res) {
          return;
        }
      });
    },
    /**
     *
     * @param {*克隆任务} id
     */
    get_job_clone_info(id) {
      return customer_api_method({
        action: "get_job_clone_info",
        job_id: id,
      }).then((res) => {
        if (!res) {
          return;
        }
        return res.info;
      });
    },
    /**
     * 保存拆分结果
     * @param
     */
    update_split_job(obj) {
      customer_api_method({
        action: "update_split_job",
        project_id: this.project_id,
        job_id: this.commandRow.job_id,
        split_mode: obj.radio,
        split_count: obj.input,
        workflow: obj.activeName,
        list: obj.tableData,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.get_team_job_list();
        this.dialogVisible = false;
      });
    },
    /**
     *
     * @param {*获取成员列表信息} data
     */
    get_member_list(id) {
      customer_api_method({
        action: "get_member_list",
        team_user_status: id,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.dropdownLists = res.list;
      });
    },
    // ！======================================== 其他 操作方法===============================
    /**
     *
     * @param {*筛选 tab 删除} tag
     */
    handleClose(tag) {
      this.tags.splice(this.tags.indexOf(tag), 1);
      this.get_team_job_list();
    },
    /**
     *
     * @param {超出溢出 显示} i
     */
    onShowNameTipsMouseenter(i) {
      onShowNameTipsMouseenter(i);
    },
    /**
     * 表头 项目名称 筛选
     */
    searchdetermineClick() {
      if (this.popoverInput) {
        this.tags = addAndreplaceTags(
          this.$t("CommonName.taskname"),
          this.popoverInput,
          this.tags,
          "name",
          this.popoverInput
        );
      }
      this.get_team_job_list();
      this.visible = false;
      this.popoverInput = "";
    },
    /**
     *
     * @param {* 设置表格 key} row
     */
    getRowKey(row) {
      return row.job_id;
    },
    /**
     *
     * @param row
     */
    expandChange(row) {
      // this.handleTabClick(row);
      this.$set(row, "tableData", row.tableData);
      this.$nextTick(() => {
        // this.$refs.childTable.doLayout();
      });
    },
    /**
     *
     * @param {*操作流程筛选} command
     */
    Operationprocesscommand(command) {
      this.tags = addAndreplaceTags(
        this.$t("ProjectDetails.Operationprocess"),
        command.name,
        this.tags,
        "job_type",
        command.value
      );
      this.get_team_job_list();
    },
    /**
     *
     * @param {*状态筛选下拉框} command
     */
    statuscommand(command) {
      this.tags = addAndreplaceTags(
        this.$t("CommonName.status"),
        command.name,
        this.tags,
        "status",
        command.value
      );
      this.get_team_job_list();
    },
    /**
     *
     * @param {*源语言 下拉框筛选} command
     */
    sourcelanguagecommand(command) {
      this.tags = addAndreplaceTags(
        this.$t("CommonName.sourcelanguagetable"),
        command.name,
        this.tags,
        "slang_id",
        command.value
      );
      this.get_team_job_list();
    },
    /**
     *
     * @param {*目标语言 下拉框筛选} command
     */
    targetlanguagecommand(command) {
      this.tags = addAndreplaceTags(
        this.$t("CommonName.targetlanguagetable"),
        command.name,
        this.tags,
        "tlang_id",
        command.value
      );
      this.get_team_job_list();
    },
    headerClick() {
      // this.multipleSelection = [];
      // this.startrow = {};
    },
    /**
     * 选中表格 行
     * @param row
     */
    rowClick(row) {
      // 按住shift
      if (this.ShiftDown) {
        // console.log("this.ShiftDown", this.ShiftDown);
        this.multipleSelection.push(row);
        let data;
        if (this.startrow.index < row.index) {
          data = this.tableData.slice(this.startrow.index, row.index + 1);
        } else {
          data = this.tableData.slice(row.index, this.startrow.index + 1);
        }
        this.multipleSelection = data;
        this.multipleSelection.forEach((rows) => {
          this.$refs.multipleTable.toggleRowSelection(rows);
        });
        return;
      }
      // 按住Ctrl
      if (this.CtrlDown) {
        let a = this.multipleSelection.find((i) => i.job_id === row.job_id);
        if (a) {
          this.multipleSelection.forEach((i, index) => {
            if (i.job_id === a.job_id) {
              this.multipleSelection.splice(index, 1);
            }
          });
          return;
        }
        this.multipleSelection.push(row);
        // this.multipleSelection.forEach((rows) => {
        //   this.$refs.multipleTable.toggleRowSelection(rows);
        // });
        return;
      }
      //   什么都不按
      if (this.multipleSelection.length === 1) {
        let a = this.multipleSelection.find((i) => i.job_id === row.job_id);
        if (a) {
          this.multipleSelection = [];
          // this.$refs.multipleTable.clearSelection();
          return;
        }
      }
      if (this.multipleSelection.length > 1) {
        let a = this.multipleSelection.find((i) => i.job_id === row.job_id);
        if (a) {
          this.startrow = {};
          this.multipleSelection = [];
          this.startrow = row;
          this.multipleSelection.push(row);
          // this.multipleSelection.forEach((rows) => {
          //   this.$refs.multipleTable.toggleRowSelection(rows);
          // });
          return;
        }
      }
      this.startrow = {};
      this.multipleSelection = [];
      this.startrow = row;
      this.multipleSelection.push(row);
      // this.multipleSelection.forEach((rows) => {
      //   this.$refs.multipleTable.toggleRowSelection(rows);
      // });
    },
    /**
     *
     * @param {设置选中 表格背景颜色} param0
     */
    tableRowClassName({ row }) {
      // console.log(row, "row");
      let color = "";
      // console.log(this.multipleSelection);
      for (let index = 0; index < this.multipleSelection.length; index++) {
        if (this.multipleSelection[index].job_id === row.job_id) {
          color = "table-SelectedRow-bgcolor";
          // color = "success-row";
        }
      }
      return color;
    },
    /**
     *
     * @param {*弹窗关闭} val
     */
    changeDialogVisible(val) {
      this.dialogVisible = val;
      this.showfooter = false;
      this.dialogWidth = false;
      this.showTitle = false;
      this.showTop = false;
      this.buttonLoading = false;
    },
    /**
     * 弹窗 确定按钮
     */
    determineClick() {
      //  更改指派人
      if (
        this.dialogtitle === this.$t("ProjectDetails.AssignChangeTaskHandler")
      ) {
        this.buttonLoading = true;
        let obj = this.$refs.AppointedTask.form;
        this.set_job_user_batch(obj.select1_id).then(() => {
          this.$messages("success", this.$t("CommonName.successmessage6"));
          this.get_team_job_list();
          this.closeinfo();
          this.buttonLoading = false;
        });
      } else if (
        this.dialogtitle === this.$t("ProjectManagement.ExcelToContent")
      ) {
        //导入Excel内容
        if (!this.xlsupload) {
          this.$messages(
            "warning",
            this.$t("ExternalCreateTask.warningmessage6")
          );
          return;
        }
        this.uploadData = {
          action: "upload_excel_file_to_content",
          job_id: this.commandRow.job_id,
        };
        this.$nextTick(() => {
          this.$refs.xlsupload.submit();
        });
      } else if (
        //导入 AIT 文件
        this.dialogtitle === this.$t("ProjectDetails.dropdownList.name8")
      ) {
        if (!this.aitUpload) {
          this.$messages(
            "warning",
            this.$t("ExternalCreateTask.warningmessage6")
          );
          return;
        }
        this.uploadData = {
          action: "upload_excel_ait_file_to_content",
          job_id: this.commandRow.job_id,
        };
        this.$nextTick(() => {
          this.$refs.aitUpload.submit();
        });
      } else if (
        //重命名
        this.dialogtitle === this.$t("ProjectDetails.dropdownList.name7")
      ) {
        if (this.renameInput.trim() == "") {
          this.$messages("warning", this.$t("CommonName.warningmessage3"));
          return;
        }
        customer_api_method({
          action: "update_job_name",
          job_id: this.commandRow.job_id,
          job_name: this.renameInput,
        }).then((res) => {
          if (!res) {
            return;
          }
          this.$messages("success", this.$t("CommonName.successmessage3"));
          this.get_team_job_list();
          this.closeinfo();
        });
      } else {
        this.closeinfo();
      }
    },
    /**
     * 关闭弹窗 方法
     */
    closeinfo() {
      this.dialogVisible = false;
      this.showfooter = false;
      this.dialogWidth = false;
      this.showTitle = false;
      this.showTop = false;
    },
    /**
     * 批量处理 下拉框
     */
    commandClick() {},

    /**
     * 返回按钮
     */
    signOut() {
      this.$router.push({
        name: "ProjectManagement",
        query: {
          id: this.folder_pid,
        },
      });
    },
    /**
     *
     * @param {*点击的 头部 按钮} name
     */
    headerBtnClick(name, id) {
      // this.dialogtitle = name;
      if (id == "2") {
        if (this.tableData.length == 0) {
          this.$messages("warning", this.$t("ProjectDetails.warningmessage1"));
          return false;
        }
      }
      this.topButtonId = id;
      switch (id) {
        case "1":
          //字幕规则设置
          this.showCaptionSettings = true;
          break;
        case "2":
          //字幕样式设置
          break;
        case "3":
          //术语管理
          this.dialogtitle = name;
          this.showTitle = true;
          this.dialogVisible = true;
          break;
        case "4":
          //创建声音
          break;
        case "5":
          //项目声音管理
          break;
        case "6":
          //项目角色管理
          break;
        default:
          break;
      }
      // this.dialogVisible = true;
      // 术语
      // this.showTitle = name === this.$t("ProjectDetails.buttonList.name5");
      // 创建任务
      // if (name == this.$t("ProjectDetails.buttonList.name2")) {
      //   this.DetermineClickCreateTask = "1";
      //   this.showCreateTaskdialog = true;
      //   return;
      // }
    },
    /**
     * 依据 bottomButtonList
     * @param {*点击的 底部 按钮} name
     */
    bottomBtnClick(name, id) {
      if (this.multipleSelection.length == 0) {
        this.$messages("warning", this.$t("ProjectManagement.warningmessage4"));
        return false;
      }
      switch (id) {
        case "1":
          // 打开任务
          this.batchOpenJobs();
          break;
        case "2":
          // 指定执行人
          this.bottomButtonId = id;
          break;
        case "3":
          // 更换执行人
          this.bottomButtonId = id;
          break;
        case "4":
          // 取消提交任务 将不可编辑的任务变为可编辑
          this.cancelSubmitTask();
          break;
        case "5":
          // 导出字幕
          this.exportSubtitle();
          break;
        case "6":
          // 导出视频(带字幕)
          this.downloadClick(6);
          break;
        case "7":
          // 为字幕配音(AI)
          this.dubSubtitleWithAI();
          break;
        case "8":
          // 为字幕配音(克隆原声)
          this.dubSubtitleWithClone();
          break;
        case "9":
          // 导出台词(按角色)
          this.exportScriptByRole();
          break;
        case "10":
          // 导出背景音
          this.exportBackgroundAudio();
          break;
        case "11":
          // 导出配音结果
          this.exportDubbingResult();
          break;
        case "12":
          // 删除任务
          this.delete_job_method();
          break;
        case "13":
          // 导出视频(已涂抹)
          this.exportSmearingVideo();
          break;
        default:
          console.log(`未处理的底部按钮点击事件: ${id}`);
          break;
      }
    },
    /**
     * 批量打开任务
     */
    batchOpenJobs() {
      // 批量打开 校审 转写 翻译页面
      if (this.multipleSelection.length === 0) {
        return;
      }

      let arr = [];
      let path = "";
      let localName = "";
      let first_slang = this.multipleSelection[0]["slang_id"];
      let first_tlang = this.multipleSelection[0]["tlang_id"];
      let first_status = this.multipleSelection[0]["status"];
      // console.log("first_slang", first_slang);
      // console.log("first_tlang", first_tlang);
      // console.log("multipleSelection", this.multipleSelection);
      if (!this.multipleSelection.every((i) => i.status !== "50")) {
        this.$messages("warning", this.$t("MyFiles.Projecttask12"));
        return;
      }
      if (first_status === "40") {
        path = "/ContainerHome/BatchProofreading";
        localName = "projectList";
        if (!this.multipleSelection.every((i) => i.status === "40")) {
          this.$messages("warning", this.$t("MyFiles.Projecttask10"));
          return;
        }
        if (
          !this.multipleSelection.every(
            (i) => i.slang_id === first_slang && i.tlang_id === first_tlang
          )
        ) {
          this.$messages("warning", this.$t("MyFiles.Projecttask6"));
          return;
        }
      }
      if (first_status === "20") {
        path = "/ContainerHome/BatchTranscription";
        localName = "projectList_Transcription";
        if (!this.multipleSelection.every((i) => i.status === "20")) {
          this.$messages("warning", this.$t("MyFiles.Projecttask10"));
          return;
        }
        if (!this.multipleSelection.every((i) => i.slang_id === first_slang)) {
          this.$messages("warning", this.$t("MyFiles.Projecttask6"));
          return;
        }
      }
      if (first_status === "30") {
        path = "/ContainerHome/BatchTranslation";
        localName = "projectList_translation";
        if (!this.multipleSelection.every((i) => i.status === "30")) {
          this.$messages("warning", this.$t("MyFiles.Projecttask10"));
          return;
        }
        if (
          !this.multipleSelection.every(
            (i) => i.slang_id === first_slang && i.tlang_id === first_tlang
          )
        ) {
          this.$messages("warning", this.$t("MyFiles.Projecttask6"));
          return;
        }
      }
      if (first_status === "25") {
        path = "/ContainerHome/BatchTranscriptionQC";
        localName = "projectList_TranscriptionQC";
        if (!this.multipleSelection.every((i) => i.status === "25")) {
          this.$messages("warning", this.$t("MyFiles.Projecttask10"));
          return;
        }
        if (!this.multipleSelection.every((i) => i.slang_id === first_slang)) {
          this.$messages("warning", this.$t("MyFiles.Projecttask6"));
          return;
        }
      }
      this.multipleSelection.forEach((i, index) => {
        arr.push({
          name: i.name,
          id: i.job_id,
          pid: i.task_url.split("=")[1].split("&")[0],
          fid: i.task_url.split("=")[2],
          task: "",
        });
      });

      localStorage.setItem(localName, JSON.stringify(arr));
      let pathInfo = this.$router.resolve({
        path,
        query: {
          pid: arr[0].pid,
          fid: arr[0].fid,
          id: arr[0].id,
          lang: sessionStorage.getItem("lang"),
        },
      });
      window.open(pathInfo.href, "_blank");
    },
    /**
     *   取消提交任务
     */
    cancelSubmitTask() {
      let job_ids = [];
      this.multipleSelection.forEach((item) => {
        job_ids.push(item.job_id);
      });
      this.$confirm(
        this.$t("ProjectDetails.warningmessage"),
        this.$t("CommonName.deleteMessageTitle"),
        {
          confirmButtonText: this.$t("CommonName.deleteMessagebutton"),
          cancelButtonText: this.$t("CommonName.deleteMessageClosebutton"),
          closeOnClickModal: false,
          cancelButtonClass: "closeButton",
          // confirmButtonClass: "confirmButton",
        }
      ).then(() => {
        showLoading(this.$t("CommonName.loadingmessage"));
        customer_api_method({
          action: "reject_job",
          job_ids: job_ids,
        }).then((res) => {
          if (!res) {
            return;
          }
          this.get_team_job_list();
        });
      });
    },
    /**
     *   导出字幕
     */
    exportSubtitle() {
      let job_ids = [];
      this.multipleSelection.forEach((item) => {
        job_ids.push(item.job_id);
      });
      job_ids = job_ids.join(",");
      let checkbox = "2,3,4,5";
      let url = this.baseUrl + "/videojobcustomer/download_all_zip_file?";
      url += "project_id=" + this.project_id;
      url += "&job_ids=" + job_ids;
      url += "&modes=" + checkbox;
      if (this.older != "" && this.sort != "") {
        url += "&older=" + this.older;
        url += "&sort=" + this.sort;
      }
      this.tags.forEach((item, index) => {
        if (item.key == "name") {
          url += "&" + item.key + "=" + encodeURIComponent(item.value);
        } else {
          url += "&" + item.key + "=" + item.value;
        }
      });
      url += "&time=" + new Date().getTime();
      window.open(url);
    },
    /**
     *   导出视频(已涂抹)
     */
    exportSmearingVideo() {
      let list = [];
      let no_url_job_ids = [];
      this.multipleSelection.forEach((item) => {
        let path = "";
        let name = item.name;

        if (item.file_smearing_path) {
          path = item.file_smearing_path;
          if (name.lastIndexOf(".") > -1) {
            name = name.slice(0, name.lastIndexOf(".")) + ".mp4";
          } else {
            name = name + ".mp4";
          }
        } else {
          no_url_job_ids.push(item.job_id);
        }
        list.push({ name, path });
      });
      // console.log("list", list)
      if (no_url_job_ids.length > 0) {
        this.$messages("warning", this.$t("MyFiles.downloadTips2"));
      } else {
        if (list.length > 1) {
          this.downloadMethod(list);
        } else if (list.length == 1) {
          this.download_method(list[0].path, list[0].name);
        }
      }
    },
    /**
     *   为字幕配音(AI)
     */
    dubSubtitleWithAI() {
      this.createId = 2;
      this.createType = "1";
      this.showCreateDubbingTasks = true;
    },
    /**
     *  为字幕配音(克隆原声)
     */
    dubSubtitleWithClone() {
      this.createId = 3;
      this.createType = "1";
      this.showCreateDubbingTasks = true;
    },
    /**
     *  导出台词(按角色)
     */
    exportScriptByRole() {
      let job_ids = [];
      this.multipleSelection.forEach((item) => {
        job_ids.push(item.job_id);
      });
      job_ids = job_ids.join(",");
      let url = this.baseUrl + "/videojob/export_task_urls?";
      url += "project_id=" + this.project_id;
      url += "&job_ids=" + job_ids;
      if (this.older != "" && this.sort != "") {
        url += "&older=" + this.older;
        url += "&sort=" + this.sort;
      }
      this.tags.forEach((item, index) => {
        if (item.key == "name") {
          url += "&" + item.key + "=" + encodeURIComponent(item.value);
        } else {
          url += "&" + item.key + "=" + item.value;
        }
      });
      url += "&time=" + new Date().getTime();
      window.open(url);
    },
    /**
     *  导出背景音
     */
    exportBackgroundAudio() {
      let job_ids = [];
      this.multipleSelection.forEach((item) => {
        job_ids.push(item.job_id);
      });
      job_ids = job_ids.join(",");
      let url = this.baseUrl + "/videojob/export_task_urls?";
      url += "project_id=" + this.project_id;
      url += "&job_ids=" + job_ids;
      if (this.older != "" && this.sort != "") {
        url += "&older=" + this.older;
        url += "&sort=" + this.sort;
      }
      this.tags.forEach((item, index) => {
        if (item.key == "name") {
          url += "&" + item.key + "=" + encodeURIComponent(item.value);
        } else {
          url += "&" + item.key + "=" + item.value;
        }
      });
      url += "&time=" + new Date().getTime();
      window.open(url);
    },
    /**
     *  导出配音结果
     */
    exportDubbingResult() {},
    /**
     * 批量删除任务
     */
    delete_job_method() {
      let job_ids = [];
      this.multipleSelection.forEach((item) => {
        job_ids.push(item.job_id);
      });
      this.$confirm(
        this.$t("CommonName.deleteMessage1"),
        this.$t("CommonName.deleteMessageTitle"),
        {
          confirmButtonText: this.$t("CommonName.deleteMessagebutton"),
          cancelButtonText: this.$t("CommonName.deleteMessageClosebutton"),
          closeOnClickModal: false,
          cancelButtonClass: "closeButton",
          // confirmButtonClass: "confirmButton",
        }
      ).then(() => {
        showLoading(this.$t("CommonName.loadingmessage"));
        customer_api_method({
          action: "delete_job_batch",
          job_ids: job_ids,
        }).then((res) => {
          if (!res) {
            return;
          }
          this.get_team_job_list();
        });
      });
    },

    /**
     * 点击关闭创建任务 弹窗
     */
    claseCreateTaskDialog(val) {
      // console.log(val);
      this.showCreateTaskdialog = val;
    },
    claseDialog(val) {
      this.dialogVisible = val;
    },
    /**
     *
     * @param {*创建任务 成功} val
     */
    Creationcompleted(val) {
      this.get_team_job_list().then(() => {
        this.showCreateTaskdialog = val;
        this.$messages(
          "success",
          this.$t("ExternalCreateTask.successmessage1")
        );
      });
    },
    /**
     *
     * @param {* 字幕设置  弹窗} val
     */
    clickCaptionSettings(val, data = 0) {
      this.showCaptionSettings = val;
      if (data == 1) {
        this.get_project_info(this.project_id).then((res) => {
          this.projectInfo = res;
        });
      }
    },
    /**
     *
     * @param {*任务详情 打回 到上一步} val
     */
    ProjectDetailsgetData(val) {
      this.get_team_job_list();
    },
    /**
     *
     * @param {确认按钮} row
     */
    confirmClick(row) {
      console.log(row);
    },

    //--------------------------------------------------------头部按钮操作-----------------------------------------------------------------------------------------
    /**
     * 表格复选框 选中
     * @param selection
     * @param row
     */
    handleSelect(selection, row) {
      if (this.ShiftDown) {
        // this.multipleSelection.push(row);
        if (!!this.startrow.job_id == false) {
          this.multipleSelection = selection;
          this.$refs.multipleTable.clearSelection();
          this.$refs.multipleTable.toggleRowSelection(row);
          this.startrow = row;
        } else {
          let data;
          if (this.startrow.index < row.index) {
            data = this.tableData.slice(this.startrow.index, row.index + 1);
          } else {
            data = this.tableData.slice(row.index, this.startrow.index + 1);
          }
          this.multipleSelection = data;
          this.$refs.multipleTable.clearSelection();
          data.forEach((rows) => {
            this.$refs.multipleTable.toggleRowSelection(rows);
          });
        }

        return;
      }
      this.startrow = {};
      this.startrow = row;
      this.multipleSelection = selection;
      if (selection.length == 0) {
        this.jobAllSelect = false;
        this.indeterminateJobAll = false;
      } else if (selection.length == this.tableData.length) {
        this.jobAllSelect = true;
        this.indeterminateJobAll = false;
      } else {
        this.jobAllSelect = false;
        this.indeterminateJobAll = true;
      }
    },
    /**
     * 表格复选框 全选/全不选
     * @param selection
     * @param row
     */
    handleALLSelect(selection) {
      // if (this.ShiftDown) {
      //   this.multipleSelection.push(row);
      //   let data;
      //   if (this.startrow.index < row.index) {
      //     data = this.tableData.slice(this.startrow.index, row.index + 1);
      //   } else {
      //     data = this.tableData.slice(row.index, this.startrow.index + 1);
      //   }
      //   this.multipleSelection = data;
      //   this.$refs.multipleTable.clearSelection();
      //   data.forEach((rows) => {
      //     this.$refs.multipleTable.toggleRowSelection(rows);
      //   });
      //   return;
      // }
      this.startrow = {};
      // this.startrow = row;
      this.multipleSelection = selection;
      this.indeterminateJobAll = false;
      if (selection.length == 0) {
        this.jobAllSelect = false;
      } else {
        this.jobAllSelect = true;
      }
    },
    /**
     *
     * @param {*点击表格内任务名称} row
     */
    tovideoDetails(row) {
      // console.log(row);
      this.rowData = row;
      // console.log(this.rowData);
      this.showTop = true;
      this.showfooter = false;
      this.dialogWidth = "1024px";
      this.dialogtitle = row.name;
      this.showTitle = true;
      this.dialogVisible = true;
    },
    /**
     * 打回
     * @param row
     */
    rejectjob(row) {
      // console.log(row);
      showLoading(this.$t("CommonName.loadingmessage"));
      customer_api_method({
        action: "reject_split_job",
        job_id: row.job_id,
        job_split_id: row.job_split_id,
      }).then((res) => {
        if (!res) {
          console.log(row);
          hideLoading();
          return;
        }
        let obj = this.tableData.find((item) => item.job_id === row.job_id);
        let activeName = obj.activeName;
        this.get_team_job_list().then((res) => {
          let obj1 = this.tableData.find((item) => item.job_id === row.job_id);
          obj1.activeName = activeName;
        });
      });
    },
    /**
     *
     * @param {*表格 操作} row
     * row.command 1.重命名 2.指派/更改人员
     * 3.克隆任务 4.删除任务
     */
    commandClicks(row) {
      console.log(row);
      this.commandRow = row.row;
      this.dialogWidth = false;
      switch (row.command) {
        //   重命名
        case "1":
          this.renameInput = this.commandRow.name;
          this.dialogtitle = this.$t("ProjectDetails.dropdownList.name7");
          this.showfooter = true;
          this.dialogVisible = true;
          break;
        case "2":
          this.dialogtitle = this.$t("ProjectDetails.AssignChangeTaskHandler");
          this.showfooter = true;
          this.dialogVisible = true;
          break;
        case "3":
          // 克隆任务
          // this.get_job_clone_info(row.row.job_id).then((res) => {
          //   // console.log(res);

          //   this.DetermineClickCreateTask = "2";
          //   this.Clonejobinfo = res;
          //   this.showCreateTaskdialog = true;
          // });
          break;
        case "4":
          this.$confirm(
            this.$t("CommonName.deleteMessage1"),
            this.$t("CommonName.deleteMessageTitle"),
            {
              confirmButtonText: this.$t("CommonName.deleteMessagebutton"),
              cancelButtonText: this.$t("CommonName.deleteMessageClosebutton"),
              closeOnClickModal: false,
              cancelButtonClass: "closeButton",
              // confirmButtonClass: "confirmButton",
            }
          ).then(() => {
            showLoading(this.$t("CommonName.loadingmessage"));
            this.delete_job(row.row.job_id);
          });
          break;
        default:
          break;
      }
    },
    // ===================================上传 新的术语表=================================================
    /**
     * 点击下载 术语表模板
     */
    Download() {
      window.location.href = "/resourcefile/Glossary_Temp.xlsx";
    },
    /**
     *
     * @param {*点击重新上传} ref
     */
    srtupload(ref, data) {
      this.showUpload = data;
      if (data == 1) {
        this.xlsupload = "";
      } else if (data == 3) {
        this.formTermData.upload = "";
      } else if (data == 4) {
        this.aitUpload = "";
      } else if (data == 5) {
        this.zipUpload = "";
      } else {
        this.formLabelAlign.upload = "";
      }
      let uploadFilesArr = this.$refs[ref].uploadFiles; //上传字幕文件
      if (uploadFilesArr.length == 0) {
        return;
      } else {
        this.$refs[ref].uploadFiles = [];
      }
    },
    /**
     *
     * @param {*上传前  做判断是否能上传} file
     */
    uploadChange(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testmsg === "xlsx";
      if (!extension) {
        this.$messages(
          "warning",
          this.$t("TermBaseManagement.warningmessage1")
        );
        if (this.showUpload == 1) {
          this.xlsupload = "";
          this.$refs.xlsupload.clearFiles();
        } else if (this.showUpload == 3) {
          this.formTermData.upload = "";
          this.$refs.uploadUpdate.clearFiles();
        } else if (this.showUpload == 4) {
          this.aitUpload = "";
          this.$refs.aitUpload.clearFiles();
        } else {
          this.formLabelAlign.upload = "";
          this.$refs.upload.clearFiles();
        }
        return false;
      } else {
        if (this.showUpload == 1) {
          this.xlsupload = file.name;
        } else if (this.showUpload == 3) {
          this.formTermData.upload = file.name;
        } else if (this.showUpload == 4) {
          this.aitUpload = file.name;
        } else {
          this.formLabelAlign.upload = file.name;
        }
        return true;
      }
    },
    /**
     *
     * @param {*上传前  做判断是否能上传} file
     */
    uploadZipChange(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testmsg === "zip";
      if (!extension) {
        this.$messages("warning", this.$t("ProjectManagement.warningmessage2"));
        this.zipUpload = "";
        this.$refs.zipUpload.clearFiles();
        return false;
      } else {
        this.zipUpload = file.name;
        return true;
      }
    },
    /**
     *
     * @param {*上传成功} response
     */
    uploadSuccess(response) {
      if (response.result == "SUCCESS") {
        this.get_team_job_list().then(() => {
          this.innerVisible = false;
          this.dialogVisible = false;
          this.showfooter = false;
          this.percentage = 0;
          this.loading = false;
          this.$messages("success", this.$t("CommonName.successmessage5"));
        });
      } else if (response.result == "LOGIN_FAILED") {
        this.$messages("warning", this.$t("CommonName.warningmessage4"));
        this.$router.push("/Login");
        this.$Cookie.remove("token_code");
        sessionStorage.clear();
      } else {
        this.percentage = 0;
        this.loading = false;
        this.innerVisible = false;
        this.dialogVisible = false;
        this.showfooter = false;
        this.$messages("error", response.msg);
      }
    },
    /**
     *
     * @param {*上传成功} response
     */
    uploadTermSuccess(response) {
      if (response.result == "SUCCESS") {
        // this.get_team_job_list().then(() => {
        this.innerVisible = false;
        this.dialogVisible = false;
        this.percentage = 0;
        this.loading = false;
        this.$messages(
          "success",
          this.$t("CommonName.uploadTermTip1") +
            response.count +
            this.$t("CommonName.uploadTermTip2") +
            response.count +
            this.$t("CommonName.uploadTermTip3"),
          4000
        );
        // });
      } else if (response.result == "LOGIN_FAILED") {
        this.$messages("warning", this.$t("CommonName.warningmessage4"));
        this.$router.push("/Login");
        this.$Cookie.remove("token_code");
        sessionStorage.clear();
      } else {
        this.percentage = 0;
        this.loading = false;
        this.innerVisible = false;
        this.dialogVisible = false;
        this.$messages("error", response.msg);
      }
    },
    /**
     *
     * @param {*上传过程中} file
     */
    uploadProgress(file) {
      if (file.percent > 0) {
        this.percentage = Number(file.percent.toFixed());
        this.loading = true;
      }
    },
    sortChange(column) {
      // console.log(column);
      showLoading(this.$t("CommonName.loadingmessage"));
      if (column.order == "ascending") {
        this.older = column.prop;
        this.sort = "asc";
      } else {
        this.older = column.prop;
        this.sort = "desc";
      }
      this.get_team_job_list();
    },
    /**
     * 隐藏菜单按钮
     * @param comments
     */
    commandButtonClicks(comments) {
      // this.btnchId = comments.id;
      console.log(comments.id);
      // let action = ""
      switch (comments.id) {
        case "11_1":
          //导出音频(按音轨)
          break;
        case "11_2":
          //导出音频(按角色)
          break;
        case "11_3":
          //导出音频(混音)
          break;
        case "11_4":
          //导出视频
          break;
        case "11_5":
          //导出视频(带字幕)
          break;
        default:
          break;
      }
    },
    /**
     *
     * @param {*查看项目Comments备注信息} id
     */
    get_project_comments(id) {
      return customer_api_method({
        action: "get_project_comments",
        project_id: id,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.ProjectCommentsData = res.list;
      });
    },
    export_excel_task_method(checkbox, job_ids, commentsSelect) {
      let url = this.baseUrl + "/videojob/export_excel_task?";
      url += "project_id=" + this.project_id;
      url += "&job_ids=" + job_ids;
      url += "&modes=" + checkbox;
      if (commentsSelect != "") {
        url += "&comment_ids=" + commentsSelect;
      }
      if (this.older != "" && this.sort != "") {
        url += "&older=" + this.older;
        url += "&sort=" + this.sort;
      }
      this.tags.forEach((item, index) => {
        if (item.key == "name") {
          url += "&" + item.key + "=" + encodeURIComponent(item.value);
        } else {
          url += "&" + item.key + "=" + item.value;
        }
      });
      url += "&time=" + new Date().getTime();
      window.open(url);
    },
    download_all_job_zip_file(checkbox, job_ids) {
      let url = this.baseUrl + "/videojob/download_all_zip_file?";
      url += "project_id=" + this.project_id;
      url += "&job_ids=" + job_ids;
      url += "&modes=" + checkbox;
      if (this.older != "" && this.sort != "") {
        url += "&older=" + this.older;
        url += "&sort=" + this.sort;
      }
      this.tags.forEach((item, index) => {
        if (item.key == "name") {
          url += "&" + item.key + "=" + encodeURIComponent(item.value);
        } else {
          url += "&" + item.key + "=" + item.value;
        }
      });
      url += "&time=" + new Date().getTime();
      window.open(url);
    },
    export_task_urls_method() {
      let job_ids = [];
      this.multipleSelection.forEach((item) => {
        job_ids.push(item.job_id);
      });
      job_ids = job_ids.join(",");
      let url = this.baseUrl + "/videojob/export_task_urls?";
      url += "project_id=" + this.project_id;
      url += "&job_ids=" + job_ids;
      if (this.older != "" && this.sort != "") {
        url += "&older=" + this.older;
        url += "&sort=" + this.sort;
      }
      this.tags.forEach((item, index) => {
        if (item.key == "name") {
          url += "&" + item.key + "=" + encodeURIComponent(item.value);
        } else {
          url += "&" + item.key + "=" + item.value;
        }
      });
      url += "&time=" + new Date().getTime();
      window.open(url);
    },
    /**
     * 批量关闭任务
     */
    close_job_method() {
      if (this.multipleSelection.length === 0) {
        return false;
      }
      let job_ids = [];
      this.multipleSelection.forEach((item) => {
        job_ids.push(item.job_id);
      });
      this.$confirm(
        this.$t("CommonName.closeMessage"),
        this.$t("CommonName.deleteMessageTitle"),
        {
          confirmButtonText: this.$t("CommonName.deleteMessagebutton"),
          cancelButtonText: this.$t("CommonName.deleteMessageClosebutton"),
          closeOnClickModal: false,
          cancelButtonClass: "closeButton",
          // confirmButtonClass: "confirmButton",
        }
      ).then(() => {
        showLoading(this.$t("CommonName.loadingmessage"));
        customer_api_method({
          action: "set_job_close",
          project_id: this.project_id,
          job_ids: job_ids,
        }).then((res) => {
          if (!res) {
            return;
          }
          this.get_team_job_list();
        });
      });
    },
    /**
     * 点击下载 模板
     */
    DownloadExcel() {
      window.location.href = "/resourcefile/Excel_Template.xlsx";
    },
    /**
     *
     * @param {* 指派执行人  弹窗} val
     */
    clickOperator(val) {
      this.get_team_job_list().then(() => {
        //清除已选
        this.$refs.multipleTable.clearSelection();
        this.multipleSelection = [];
        this.startrow = {};
        this.btnchId = "";
        this.bottomButtonId = "";
        this.topButtonId = "";
      });
    },
    /**
     *
     * @param {* 关闭 指派执行人  弹窗} val
     */
    closeOperator(val) {
      this.topButtonId = "";
      this.bottomButtonId = "";
      this.btnchId = "";
    },
    updateClick(data) {
      console.log(data);
      this.showInnerDialogs = 5;
      this.formTermData = {
        name: data.name,
        upload: "",
        term_file_id: data.term_file_id,
        mode: "0",
      };
      this.innerVisibleTitle = this.$t("ProjectDetails.UploadUpdateglossary");
      this.innerVisible = true;
    },
    /**
     *
     * @param {* 生成波形} row
     */
    generate_waveform(row) {
      // console.log(row.job_id);
      showLoading(this.$t("CommonName.loadingmessage"));
      this.$messages("warning", this.$t("ProjectDetails.GenerateWaveform"));
      return customer_api_method({
        action: "rebuild_generate_waveform",
        job_id: row.job_id,
      }).then((res) => {
        hideLoading();
        if (!res) {
          return;
        }
        this.$messages("success", this.$t("ProjectDetails.GenerateWaveform1"));
        this.get_team_job_list();
      });
    },
    /**
     * 查询正在压制中任务的状态
     */
    getTranscribingJobs(ids) {
      if (ids.length == 0) {
        return false;
      }
      return customer_api_method({
        action: "get_job_list_status",
        job_ids: ids,
      }).then((res) => {
        if (res) {
          clearTimeout(this.timer);
          if (res.mode == 1) {
            //还有任务在转写中
            let that = this;
            let job_ids = [];
            res.list.forEach((i) => {
              for (let index = 0; index < that.tableData.length; index++) {
                if (that.tableData[index].job_id == i.job_id) {
                  if (i.status == "10") {
                    job_ids.push(i.job_id);
                    that.tableData[index].newstatus.value =
                      " " + i.task_progress + "%";
                  } else {
                    that.tableData[index].status = i.status;
                    that.tableData[index].newstatus = {
                      name: this.$t("CommonName.statusName1"), //转写
                      class: "blueClass",
                    };
                  }
                  break;
                }
              }
            });
            if (job_ids.length > 0) {
              // clearTimeout(this.timer);
              this.timer = setTimeout(() => {
                this.getTranscribingJobs(job_ids);
              }, 30000);
            }
          } else if (res.mode == 2) {
            //全部转写完成
            this.timer = setTimeout(() => {
              this.get_team_job_list();
            }, 5000);
          }
        }
      });
    },
    downloadSelectedSure() {
      // this.multipleSelection
      if (this.checkboxDownload.length === 0) {
        return false;
      }
      let job_ids = [];
      this.multipleSelection.forEach((item) => {
        job_ids.push(item.job_id);
      });
      job_ids = job_ids.join(",");
      let checkbox = this.checkboxDownload.join(",");
      this.download_all_job_zip_file(checkbox, job_ids);
      this.innerDownloadVisible = false;
    },
    ExportSelectedSure() {
      // this.multipleSelection
      if (this.checkboxExport.length === 0) {
        return false;
      }
      let job_ids = [];
      this.multipleSelection.forEach((item) => {
        job_ids.push(item.job_id);
      });
      job_ids = job_ids.join(",");
      let checkbox = this.checkboxExport.join(",");
      let commentsSelect = this.commentsSelect.join(",");
      this.export_excel_task_method(checkbox, job_ids, commentsSelect);
    },
    CanceldownloadClick() {
      this.innerDownloadVisible = false;
      this.innerExportVisible = false;
    },
    jobNameClick() {},
    /**
     *批量打回
     * @param id
     */
    reject_job() {
      let job_ids = [];
      this.multipleSelection.forEach((item) => {
        job_ids.push(item.job_id);
      });
      customer_api_method({
        action: "reject_job",
        job_id: job_ids.join(","),
      }).then((res) => {
        if (!res) {
          return;
        }
        this.get_team_job_list().then(() => {
          // this.$refs.multipleTable.clearSelection();
          // this.multipleSelection = [];
          // this.startrow = {};
        });
      });
    },
    /**
     * 执行MT翻译
     * @param id
     */
    run_mt_jobs(id) {
      let job_ids = [];
      this.multipleSelection.forEach((item) => {
        job_ids.push(item.job_id);
      });
      customer_api_method({
        action: "run_mt_jobs",
        project_id: id,
        job_list: job_ids,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.get_team_job_list().then(() => {
          // this.$refs.multipleTable.clearSelection();
          // this.multipleSelection = [];
          // this.startrow = {};
        });
      });
    },
    /**
     * 点击下载 AIT模板
     */
    DownloadAITExcel() {
      window.location.href = "/resourcefile/Excel_AIT_Template.xlsx";
    },
    /**
     *
     * @param {获取任务操作日志} data
     */
    get_job_view_log() {
      return customer_api_method({
        action: "get_job_view_log",
        job_id: this.rowData.job_id,
      }).then((res) => {
        if (!res) {
          return;
        }
        return res.list;
      });
    },
    job_view_log_revert_method(job_log_id) {
      this.$confirm(
        this.$t("CommonName.revertMessage"),
        this.$t("CommonName.deleteMessageTitle"),
        {
          confirmButtonText: this.$t("CommonName.deleteMessagebutton"),
          cancelButtonText: this.$t("CommonName.deleteMessageClosebutton"),
          closeOnClickModal: false,
          cancelButtonClass: "closeButton",
          // confirmButtonClass: "confirmButton",
        }
      ).then(() => {
        showLoading(this.$t("CommonName.loadingmessage"));
        this.job_view_log_revert(job_log_id).then((res) => {
          this.dialogVisible = false;
          this.innerVisible = false;
          this.get_team_job_list();
        });
      });
    },
    /**
     *
     * @param {根据任务操作日志还原数据} data
     */
    job_view_log_revert(job_log_id) {
      return customer_api_method({
        action: "job_view_log_revert",
        job_id: this.rowData.job_id,
        job_log_id: job_log_id,
      }).then((res) => {
        if (!res) {
          return;
        }
        return res.result;
      });
    },
    /**
     *
     * @param data
     */
    get_download_job_list() {
      return customer_api_method({
        action: "get_download_job_list",
        job_ids: this.jobIds,
      }).then((res) => {
        if (!res) {
          hideLoading();
          return;
        }
        // this.tableData = this.setTableData(res.list);
        return res;
      });
    },
    downloadClick(mode) {
      showLoading(this.$t("MyFiles.downloadLoading"));
      let list = [];
      let no_url_job_ids = [];
      let status_error_job_ids = []; //状态不为0 的任务
      // console.log("res", this.tableData);
      this.multipleSelection.forEach((item) => {
        let path = "";
        let name = item.name;
        let urls = [];
        if (item.dubbing_status != "0") {
          status_error_job_ids.push(item.job_id);
        }
        switch (mode) {
          case 1:
            //国际音轨
            path = item.dubbing_novocals_path;
            if (name.lastIndexOf(".") > -1) {
              name =
                name.slice(0, name.lastIndexOf(".")) +
                "_M&E_" +
                item.tlang_code +
                ".wav";
            } else {
              name = name + "_M&E_" + item.tlang_code + ".wav";
            }

            break;
          case 2:
            //配音音轨
            if (item.dubbing_file_path) {
              path = item.dubbing_file_path;
              urls = path.split("/");
              name = urls[urls.length - 1].split("?t=")[0];
            } else {
              no_url_job_ids.push(item.job_id);
            }

            break;
          case 3:
            //角色音轨
            if (item.dubbing_role_path) {
              path = item.dubbing_role_path;
              urls = path.split("/");
              name = urls[urls.length - 1].split("?t=")[0];
            } else {
              no_url_job_ids.push(item.job_id);
            }
            break;
          case 4:
            //混音音轨
            if (item.dubbing_mix_path) {
              path = item.dubbing_mix_path;
              urls = path.split("/");
              name = urls[urls.length - 1].split("?t=")[0];
            } else {
              no_url_job_ids.push(item.job_id);
            }
            break;
          case 5:
            //合成音轨
            if (item.dubbing_video_path) {
              path = item.dubbing_video_path;
              urls = path.split("/");
              name = urls[urls.length - 1].split("?t=")[0];
            } else {
              no_url_job_ids.push(item.job_id);
            }
            break;
          case 6:
            //压制视频
            if (item.dubbing_suppression_path) {
              path = item.dubbing_suppression_path;
              if (name.lastIndexOf(".") > -1) {
                name = name.slice(0, name.lastIndexOf(".")) + ".mp4";
              } else {
                name = name + ".mp4";
              }
            } else {
              no_url_job_ids.push(item.job_id);
            }
            break;
          default:
            break;
        }
        list.push({ name, path });
      });
      if (no_url_job_ids.length == 0) {
        // console.log("list", list);
        if (list.length == 1) {
          this.download_method(list[0].path, list[0].name);
        } else {
          this.downloadMethod(list);
        }
      } else {
        //请求 将没有url的id重新生成音轨
        //如果有状态不为0的任务无法生成音轨
        if (status_error_job_ids.length == 0) {
          this.$messages("success", this.$t("ProjectDetails.downloadTips1"));
          customer_api_method({
            action: "download_dubbing_sound_track_batch",
            job_ids: no_url_job_ids,
            mode: mode - 1,
          }).then((res) => {
            hideLoading();
            if (!res) {
              return;
            }
            // this.get_team_job_list()();
          });
        } else {
          this.$messages("warning", this.$t("ProjectDetails.downloadTips"));
          hideLoading();
          // console.log("333");
        }
      }
      // console.log("no_url_job_ids", no_url_job_ids);
    },
    //单文件下载
    download_method(url, file_name) {
      let name = file_name.split("?t=");
      const oReq = new XMLHttpRequest();
      const URL = url; // URL 为下载的URL地址
      oReq.open("GET", URL, true);
      oReq.responseType = "blob";
      let that = this;
      oReq.onload = function () {
        const file = new Blob([oReq.response], {
          type: "blob",
        });
        FileSaver.saveAs(file, name[0]); // fileName为文件名
        hideLoading();
      };
      oReq.send();
    },
    //多文件打包
    downloadMethod(list) {
      var blogTitle = this.$t("MyFiles.downloadTitle") + new Date().getTime(); // 下载后压缩包的命名
      var zip = new JSZip();
      var promises = [];
      let cache = {};
      let arrImg = list;
      for (let item of arrImg) {
        if (item.name != "" && item.path != "") {
          // item.path为文件链接地址
          const promise = this.getImgArrayBuffer(item.path).then((data) => {
            // 下载文件, 并存成ArrayBuffer对象(blob)
            zip.file(item.name, data, { binary: true }); // 逐个添加文件
            // cache[item.name] = data;
          });
          promises.push(promise);
        }
      }
      if (promises.length > 0) {
        Promise.all(promises)
          .then(() => {
            zip
              .generateAsync({
                type: "blob",
                compression: "DEFLATE", // STORE：默认不压缩 DEFLATE：需要压缩
                compressionOptions: {
                  level: 9, // 压缩等级1~9    1压缩速度最快，9最优压缩方式
                },
              })
              .then((content) => {
                // 生成二进制流
                FileSaver.saveAs(content, blogTitle); // 利用file-saver保存文件  自定义文件名
                hideLoading();
                promises = null;
              });
          })
          .catch((res) => {
            hideLoading();
            this.$messages("error", this.$t("MyFiles.downloadTips1"));
          });
      }
    },
    //文件以流的形式获取（参数url为文件链接地址）
    getImgArrayBuffer(url) {
      return new Promise((resolve, reject) => {
        //通过请求获取文件blob格式
        let xmlhttp = new XMLHttpRequest();
        xmlhttp.open("GET", url, true);
        xmlhttp.responseType = "blob";
        xmlhttp.onload = function () {
          if (this.status == 200) {
            resolve(this.response);
          } else {
            reject(this.status);
          }
        };
        xmlhttp.send();
        xmlhttp = null;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/dialogStyle.scss";

.margintop0 {
  margin-top: 0 !important;
}
.iconp_input {
  ::v-deep .el-input__inner {
    width: auto !important;
    margin: 0 5px 8px 5px !important;
  }
}
.content_center {
  margin: 24px 24px 24px 24px;
  .content_center_internalRowData_div {
    width: 100%;
    min-height: 160px;
    background-color: #3d3f50;
    border: 1px solid #515464;

    .top {
      height: 30px;
      line-height: 30px;
      border-bottom: 1px solid #515464;
      text-align: center;
      color: #fff;
    }
    .last_div {
      width: 100%;
      box-sizing: border-box;
      padding: 0 24px;
      height: 50px;
      .title_p {
        color: #adb0bc;
        font-size: 12px;
        margin-bottom: 4px;
      }
      .TaskURL_div {
        display: flex;
        a {
          font-size: 10px;
          color: #53b3ff;
          text-decoration: none;
        }
        .icon {
          margin-left: 6px;
          font-size: 16px;
          color: #53b3ff;
          cursor: pointer;
        }
      }
    }
    .last_div + .last_div {
      margin-top: 8px;
    }
    .btm {
      display: flex;
      justify-content: space-between;
      height: 70px;
      width: 100%;
      align-items: center;

      .btm_left {
        .title_p {
          color: #adb0bc;
          font-size: 12px;
          //margin-bottom: 4px;
        }

        .btmp {
          color: #ffffff;
          margin: 0;
          margin-top: 4px;
          font-size: 12px;
          padding-right: 3px;
        }
        width: 20%;
        padding-left: 24px;
        box-sizing: border-box;
        .iconp {
          display: flex;
          align-items: center;
          .icon {
            font-size: 16px;
            margin-left: 6px;
            color: #adb0bc;
            cursor: pointer;
          }

          .el-dropdown {
            width: 16px !important;
          }
        }
        .nickname_div {
          display: flex;
          align-items: center;

          ::v-deep .el-button {
            padding: 0 !important;
            margin-top: 4px;
          }

          .close_i {
            color: #c0c4cc;
          }
        }
      }

      .btm_center {
        width: 20%;
        box-sizing: border-box;
        .title_p {
          color: #adb0bc;
          font-size: 12px;
          margin-bottom: 4px;
        }
        .btmp {
          color: #ffffff;
          margin: 0;
          margin-top: 4px;
          font-size: 12px;
          padding-right: 3px;
        }
      }
      .btm_right {
        .title_p {
          color: #adb0bc;
          font-size: 12px;
          margin-bottom: 4px;
        }
        width: 60%;
        box-sizing: border-box;
        .TaskURL_div {
          display: flex;

          a {
            font-size: 10px;
            color: #53b3ff;
            text-decoration: none;
          }
          .icon {
            margin-left: 6px;
            font-size: 16px;
            color: #53b3ff;
            cursor: pointer;
          }
        }
      }
    }
  }
  .content_center_div {
    p {
      color: #adb0bc;
      font-size: 14px;
      margin-bottom: 6px;
    }
  }

  .content_center_2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .selectclass {
      ::v-deep .el-input__inner {
        width: 476px;
      }
    }
  }

  .content_center_4 {
    ::v-deep .el-form--label-top .el-form-item__label {
      padding: 0;
    }

    ::v-deep .el-form-item {
      margin-bottom: 0;
    }

    ::v-deep .el-form-item__label {
      width: 100%;
      color: #adb0bc;
      font-size: 14px;
      line-height: normal;
      margin-top: 16px;
      margin-bottom: 6px;
    }

    .uploadClass {
      display: flex;
      justify-content: space-between;

      .el-button {
        padding: 0;
      }
    }

    .selectClassp {
      display: flex;
      width: 100%;

      p {
        width: 50%;
      }
    }

    .selectClass1 {
      margin-left: 40px;
    }

    .xlsxinput {
      ::v-deep .el-input__inner {
        width: 755px;
      }

      ::v-deep .el-input-group__append,
      .el-input-group__prepend {
        padding: 0;
        width: 84px;
        text-align: center;
        background: none;
        border-left: none;
        border-color: #7d7f8b;
      }
    }

    ::v-deep .el-input__icon {
      line-height: normal;
    }

    ::v-deep .el-select .el-input__inner {
      width: 400px;
    }

    ::v-deep .el-input__suffix {
      line-height: 35px;
    }

    .rename-input {
      ::v-deep .el-input__inner {
        width: 100%;
      }
    }
    .input1 {
      ::v-deep .el-input__inner {
        width: 840px;
      }
      ::v-deep .el-input__suffix {
        line-height: 32px;
      }
    }
    .textAreaClassHeight {
      ::v-deep .el-textarea__inner {
        height: 80px;
        border: 1px solid !important;
        border-color: #7d7f8b !important;
        padding: 5px 15px !important;

        &:focus {
          background: none !important;
          color: #ffffff !important;
        }
      }
    }
    .loockUpandReplaceDiv {
      height: 16px;
      color: #ef7373;
      font-size: 12px;
      text-align: left;
    }
  }
}

.mainCenter {
  height: calc(100% - 0px);
  width: 100%;
  box-sizing: border-box;

  .mainCenterbtm {
    height: calc(100% - 200px);
    // ::v-deep .el-table th.el-table__cell > .cell {
    //   padding-left: 0 !important;
    // }
    // ::v-deep .el-table .cell {
    //   padding-left: 0 !important;
    // }
    .menuscopediv {
      cursor: pointer;
      color: #46adff;
    }

    ::v-deep .el-table__expanded-cell {
      background: #393c4e;
      padding: 0 !important;
    }
    ::v-deep .el-table__expand-icon {
      color: #fff !important;
    }
    //::v-deep .el-table__expand-icon > .el-icon {
    //  left: 87% !important;
    //}
    //::v-deep .expand_class {
    //  .cell {
    //    padding: 0 !important;
    //  }
    //}
    .expand_div {
      padding: 0;
      background: #393c4e;
      display: flex;

      .left_div {
        width: 30px;
        height: 100%;
      }

      .right_div {
        width: calc(100% - 30px);

        .right_div_top {
          width: 100%;

          ::v-deep .el-tabs__nav-wrap::after {
            background: #515464;
            height: 1px;
          }

          ::v-deep .el-tabs__nav {
            padding-left: 24px;
          }

          ::v-deep .el-tabs__active-bar {
            margin-left: 24px;
          }

          ::v-deep .el-tabs__nav-scroll {
            background: #454758;
            border-left: 1px solid #515464;
          }

          ::v-deep .el-tabs__header {
            margin: 0;
          }

          ::v-deep .el-tabs__item {
            color: #fff;
            height: 32px !important;
            line-height: 32px !important;

            &.is-active {
              color: #409eff;
            }
          }
        }

        ::v-deep .labClassName {
          border-right: none !important;
        }
      }
    }
    .done {
      cursor: pointer;
      color: #46adff;
    }
    .disabled {
      color: #6f7176;
    }
    .inProgress {
      color: #46acff70;
    }
  }
  ::v-deep .el-checkbox .el-checkbox__label {
    color: #adb0bc;
  }
  ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #409eff;
  }
}

.mainHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px 0px 24px;
  border-bottom: 3px solid #2c2d37;
  height: 60px;
  box-sizing: border-box;
  .mainHeader_left {
    display: flex;

    .signoutClass {
      width: 22px;
      height: 22px;
      border: 1px solid;
      border-color: #757784;
      border-radius: 2px;
      line-height: 22px;
      cursor: pointer;

      .icon {
        font-size: 16px;
      }
    }

    .titleClass {
      display: flex;
      align-items: center;
      margin-left: 24px;
      text-align: left;

      .overflowp {
        width: 350px;
      }

      .vertical {
        width: 4px;
        height: 16px;
        background-color: #46adff;
        border-radius: 2px;
      }

      p {
        font-weight: 700;
        color: #ffffff;
        font-size: 16px;
        margin-left: 8px;
      }
    }

    .dropdowndiv {
      display: flex;
      align-items: center;
      margin-left: 40px;

      .dropdownbutton {
        p {
          color: #46adff;
          font-size: 14px;
        }
      }
    }
  }

  .mainHeader_right {
    display: flex;
    align-items: center;

    .lastdiv {
      width: 1px;
      height: 12px;
      background-color: #757784;
      margin-right: 14px;
    }

    // .lastbtn {
    //   margin-right: 10px;
    // }
    .el-button {
      .buttondiv {
        display: flex;
        align-items: center;
        margin-right: 12px;

        p {
          margin-left: 6px;
          font-size: 14px;
        }

        .icon {
          font-size: 16px;
        }
      }
    }
    .service {
      //flex: calc((100% / 4));
      //width: 25%;
      // width: calc(50% - 12.5% - 12.5%);
      //margin: 12.5%;
      // height: 30%;
      //min-height: 95px;
      box-sizing: border-box;

      .bgservice {
        //width: 70%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        //padding: 10px 24px;
        padding: 5px;
        &:hover {
          background-color: #474b69;
          border-radius: 4px;
        }

        .serviceicon {
          font-size: 40px;
        }

        .servicetitle {
          text-align: left;
          margin-left: 8px;
          width: 202px;

          .servicetitle_name {
            height: 20px;
            //font-family: Microsoft YaHei;
            color: #ffffff;
            font-size: 14px;
            margin: 0;
          }

          .servicetitle_explain {
            width: 200px;
            height: 18px;
            //font-family: Microsoft YaHei;
            color: #adb0bc;
            font-size: 12px;
          }
        }
      }

      .bgdisabled1 {
        display: none;
      }
      .bgdisabled {
        &:hover {
          background-color: rgba(45, 48, 61, 0.5);
          border-radius: 4px;
          position: relative;

          .bgdisabled1 {
            display: block;
            position: absolute;
            color: #ffffff;
            //font-size: 18px;
          }
        }

        .servicetitle_name {
          height: 20px;
          //font-family: Microsoft YaHei;
          color: #747886 !important;
          //font-size: 18px;
          margin: 0 0 8px 0;
        }

        .servicetitle_explain {
          width: 200px;
          height: 18px;
          //font-family: Microsoft YaHei;
          color: #747886 !important;
          font-size: 12px;
        }
      }
    }
    .service + .service {
      margin-left: 5px;
    }
  }
}
.mainTabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px 0px 24px;
  height: 50px;
  box-sizing: border-box;
  border-bottom: 2px solid #2c2d37;
  width: 100%;
  overflow-x: auto;
  .mainHeader_left {
    display: flex;
    .el-tabs {
      ::v-deep .el-tabs__nav-wrap::after {
        background: #515464;
        height: 0px;
      }
      ::v-deep .el-tabs__header {
        margin: 0;
      }
      ::v-deep .el-tabs__item {
        color: #adb0bc;
        height: 0px;
      }
      ::v-deep .el-tabs__nav {
        height: 47px;
        line-height: 47px;
      }
      ::v-deep .el-tabs__active-bar {
        top: 45px;
      }
      ::v-deep .el-tabs__item.is-active {
        color: #409eff;
      }
    }

    .signoutClass {
      width: 22px;
      height: 22px;
      border: 1px solid;
      border-color: #757784;
      border-radius: 2px;
      line-height: 22px;
      cursor: pointer;

      .icon {
        font-size: 16px;
      }
    }

    .titleClass {
      display: flex;
      align-items: center;
      margin-left: 24px;
      text-align: left;

      .overflowp {
        width: 350px;
      }

      div {
        width: 4px;
        height: 16px;
        background-color: #46adff;
        border-radius: 2px;
      }

      p {
        font-weight: 700;
        color: #ffffff;
        font-size: 16px;
        margin-left: 8px;
      }
    }

    .dropdowndiv {
      display: flex;
      align-items: center;
      margin-left: 40px;

      .dropdownbutton {
        p {
          color: #46adff;
          font-size: 14px;
        }
      }
    }
  }

  .mainHeader_right {
    display: flex;
    align-items: center;

    .lastdiv {
      width: 1px;
      height: 12px;
      background-color: #757784;
      margin-right: 14px;
    }

    // .lastbtn {
    //   margin-right: 10px;
    // }
    .el-button {
      .buttondiv {
        display: flex;
        align-items: center;
        margin-right: 12px;

        p {
          margin-left: 6px;
          font-size: 14px;
        }

        .icon {
          font-size: 16px;
        }
      }
    }
  }
}
.mainBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px 0px 26px;
  height: 50px;
  box-sizing: border-box;
  border-top: 1px solid #2c2d37;
  box-shadow: 0px 2px 6px rgba(2, 3, 4, 0.6);
  width: 100%;
  overflow-x: auto;
  .mainHeader_left {
    display: flex;
    .signoutClass {
      width: 22px;
      height: 22px;
      border: 1px solid;
      border-color: #757784;
      border-radius: 2px;
      line-height: 22px;
      cursor: pointer;

      .icon {
        font-size: 16px;
      }
    }

    .titleClass {
      display: flex;
      align-items: center;
      margin-left: 24px;
      text-align: left;

      .overflowp {
        width: 350px;
      }

      div {
        width: 4px;
        height: 16px;
        background-color: #46adff;
        border-radius: 2px;
      }

      p {
        font-weight: 700;
        color: #ffffff;
        font-size: 16px;
        margin-left: 8px;
      }
    }
  }
  .mainHeader_right {
    display: flex;
    align-items: center;

    .lastdiv {
      width: 1px;
      height: 12px;
      background-color: #757784;
      margin-right: 14px;
    }

    // .lastbtn {
    //   margin-right: 10px;
    // }
    .el-button {
      .buttondiv {
        display: flex;
        align-items: center;
        margin-right: 12px;

        p {
          margin-left: 6px;
          font-size: 14px;
        }

        .icon {
          font-size: 16px;
        }
      }
    }
  }
}
.contentCenter_tags {
  margin-top: 8px;
}
.mainCenterbtm {
  // ::v-deep .el-table__body {
  // font-size: 12px !important;
  // }
  .setings {
    display: flex;
    align-items: center;
    height: 32px;

    .setClass {
      width: 36px;
      height: 22px;
      border-radius: 2px;
      text-align: center;
      margin-right: 8px;

      p {
        color: #ffffff;
        font-size: 14px;
      }
    }
    ::v-deep .el-badge__content.is-fixed.is-dot {
      right: 14px !important;
    }
    ::v-deep .el-badge__content.is-fixed {
      top: 1px !important;
    }
    ::v-deep .el-badge__content {
      border-color: #393c4e !important;
      background-color: #c6e6ff !important ;
    }
  }

  .statusClass {
    display: flex;
    align-items: center;

    .statusClass1 {
      width: 36px;
      height: 22px;
      border-radius: 2px;
      text-align: center;
      margin-right: 8px;

      p {
        color: #ffffff;
        margin-left: 0 !important;
      }
    }

    p {
      color: #cbd7e8;
      margin-left: 2px;
    }
  }
}

.tabledropdowndiv {
  max-height: 252px;
  overflow-y: auto;
  overflow-x: hidden;

  ::v-deep .popper__arrow {
    left: 140px !important;
    display: none;
  }
}

.blueClass {
  background-color: #3b99e3;
}
.sqtClass {
  background-color: #b888c4;
}
.greenClass {
  background-color: #41b293;
}

.greenClass_other {
  background-color: #94be6f;
}

.yellowClass {
  background-color: #d48d47;
}

.blueClass1 {
  border: 1px solid #3b99e3;
  line-height: 22px;
}
.sqtClass1 {
  border: 1px solid #b888c4;
  line-height: 22px;
}
.greenClass1 {
  border: 1px solid #41b293;
  line-height: 22px;
}

.greenClass_other1 {
  border: 1px solid #94be6f;
  line-height: 22px;
}

.yellowClass1 {
  border: 1px solid #d48d47;
  line-height: 22px;
}

.blueClass2 {
  background-color: #3b99e3;
}
.sqtClass2 {
  background-color: #b888c4;
}
.greenClass2 {
  background-color: #41b293;
}

.greenClass_other2 {
  background-color: #94be6f;
}

.yellowClass2 {
  background-color: #d48d47;
}

.slottitle {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    margin-left: 24px;
  }

  .el-button {
    margin-right: 75px;
  }
}

.tasknameslotTitle {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title_p {
    width: 240px;
    text-align: left;
  }

  p {
    margin-left: 24px;
  }

  .tasktitlebtn {
    display: flex;
    align-items: center;
    margin-right: 40px;

    .svgbtn {
      margin-right: 14px;
    }
  }

  .lastdiv {
    width: 1px;
    height: 12px;
    background-color: #757784;
    margin: 0 24px 0 10px;
  }
}

::v-deep .el-table--border,
.el-table--group {
  border-color: #515464;
}

::v-deep .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-color: #515464;
}

::v-deep .el-table--border .el-table__cell,
.el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  border-right-color: #515464 !important;
}

::v-deep .el-table__header colgroup col[name="gutter"] {
  display: table-cell !important;
}

.popoverDiv {
  ::v-deep .el-input__inner {
    width: auto;
  }
}

.tableheaderdiv {
  .fontSize12 {
    font-size: 14px;
  }
}

.radioClass50 {
  .el-radio-group {
    width: 100%;
  }

  .el-radio {
    width: 51%;
    margin-top: 8px;
  }
}

.content_center {
  .content_center_3 {
    .content_center_div {
      p {
        color: #adb0bc;
        font-size: 14px;
        margin-bottom: 6px;
      }

      .checkboxClass {
        margin-top: 0px;

        .el-button + .el-button,
        .el-checkbox.is-bordered + .el-checkbox.is-bordered {
          margin-left: 0px;
        }
      }

      .checkboxClass .el-checkbox.is-bordered {
        width: 476px !important;
        margin-bottom: 6px;
      }
      .selectclass100 {
        width: 100%;
      }
      .content_center_div1 {
        ::v-deep .el-input__inner {
          width: 100%;
        }
      }
    }
    .content_center_div + .content_center_div {
      margin-top: 12px;
    }
  }
}

.operationClass {
  cursor: pointer;
}
.tabledropdowndiv {
  max-height: 210px;
  overflow-y: auto;
}
.buttonList_class + .buttonList_class {
  margin-left: 10px;
}
.setUserSelect {
  user-select: none;
}
::v-deep .el-table__body tr.current-row > td.el-table__cell {
  background: rgba(61, 122, 176, 0);
}
::v-deep .el-table .success-row {
  background: rgba(61, 122, 176, 0.5) !important;
  // position: sticky;
  td {
    color: #ffffff !important;
  }

  &:hover {
    td {
      background-color: #3d7ab0 !important;
    }
  }
}
.moveProjectSearch {
  margin: 24px;
  ::v-deep .el-input__inner {
    width: 100%;
  }
}
.moveFolder {
  margin: 24px;
  height: 300px;
  overflow: auto;
  border: 1px solid #7d7f8b;
  border-radius: 2px;
  padding: 16px;

  .allProject {
    display: flex;
    height: 27px;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: #484a5a;
    }

    .svgiconclass1 {
      color: #cbd7e8;
      width: 18px !important;
      height: 21px !important;
      margin-right: 5px;
    }

    p {
      color: #cbd7e8;
      margin-bottom: 0px;
    }
  }

  .allProject.active {
    background-color: #46adff; //背景色
  }

  p {
    color: #adb0bc;
    font-size: 14px;
    margin-bottom: 6px;
  }

  ::v-deep .el-tree {
    background-color: #393c4e;
    .is-current {
      background-color: #46adff; //背景色
    }
    .el-tree-node:focus > .el-tree-node__content {
      background-color: #46adff; //背景色
    }

    .el-tree-node__content {
      &:hover {
        background: #484a5a;
      }
    }
  }

  .custom-tree-node {
    display: flex;
    align-items: center;
  }

  .folderp {
    color: #cbd7e8;
    font-size: 14px;
    white-space: pre;
  }

  .svgiconclass {
    color: #f5df86;
    width: 18px !important;
    height: 21px !important;
    margin-right: 5px;
  }
}
</style>
