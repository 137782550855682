<!--
 * @Description: 指定/更改任务执行人
-->
<template>
  <div class="center">
    <div>
      <!-- 任务执行人 -->
      <p>{{ $t("ProjectManagement.Waterprint1") }}</p>
      <el-select
        v-model="form.select1"
        :placeholder="$t('CommonName.Pleaseselect')"
        class="selectclass selectClass1"
        @change="select1Change"
        clearable
        filterable
      >
        <!-- <el-option label=" " value="0"></el-option> -->
        <el-option
          v-for="item in selectOptions"
          :key="item.member_id"
          :label="item.nickname"
          :value="item.member_id"
        >
        </el-option>
      </el-select>
    </div>
  </div>
</template>
<script>
import { get_job_member, get_member_list } from "@/utils/https";
export default {
  props: {
    jobid: {
      type: String,
      default: "",
    },
    jobType: {
      type: String,
      default: "",
    },
    projectid: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      form: {
        select1: "",
        select1_id: "",
      },
      selectOptions: [],
    };
  },
  mounted() {
    this.get_job_member(this.jobid);
    this.get_member_list();
  },
  created() {},
  methods: {
    select1Change(select) {
      this.form.select1_id = select;
    },
    /**
     *
     * @param {*获取成员列表信息} data
     */
    get_member_list(data = 2) {
      get_member_list({
        action: "get_member_list",
        team_user_status: data,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.selectOptions = res.list;
      });
    },
    /**
     *
     * @param {* 获取 任务 转写人 信息} id
     */
    get_job_member(id) {
      get_job_member({
        action: "get_job_member",
        job_id: id,
      }).then((res) => {
        if (!res) {
          return;
        }
        let arr = res.list;
        arr.forEach((i) => {
          if (i.type == "1") {
            this.form.select1 = i.nickname;
            this.form.select1_id = i.user_id;
          }
        });
        // console.log(arr);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 0 36px 0;
  p {
    color: #adb0bc;
    font-size: 14px;
    margin: 16px 0 6px 0;
  }
  .el-input__icon {
    line-height: 32px;
  }
}
</style>
