<!--
 * @Description: 术语
 * @version: 
 * @Author: Carful
 * @Date: 2022-11-15 08:41:36
 * @LastEditors: Carful
 * @LastEditTime: 2023-03-08 17:27:32
-->
<template>
  <div class="GlossariesDialog">
    <div class="glo_top">
      <p
        :class="Glossariestabs == 1 ? 'setbackground' : ''"
        @click="changeGlossariestabs(1)"
      >
        <!-- 管理术语文件 -->
        {{ $t("ProjectManagement.Managementtermfile") }}
        <i
          class="el-icon-caret-bottom tabsbtnicon"
          v-if="Glossariestabs == 1"
        ></i>
      </p>
      <!-- 术语查询 -->
      <p
        :class="Glossariestabs == 0 ? 'setbackground' : ''"
        @click="changeGlossariestabs(0)"
      >
        {{ $t("ProjectManagement.Termquery") }}
        <i
          class="el-icon-caret-bottom tabsbtnicon"
          v-if="Glossariestabs == 0"
        ></i>
      </p>
      <p
        :class="Glossariestabs == 2 ? 'setbackground' : ''"
        @click="changeGlossariestabs(2)"
      >
        <!-- 术语类别 -->
        {{ $t("ProjectManagement.TermCategory") }}
        <i
          class="el-icon-caret-bottom tabsbtnicon"
          v-if="Glossariestabs == 2"
        ></i>
      </p>
    </div>
    <div class="glo_bottom">
      <div v-if="Glossariestabs == 0">
        <div class="tabs1_top">
          <div class="tabs1_top_left">
            <div>
              <!-- 源语言 -->
              <p>{{ $t("CommonName.sourcelanguagetable") }}</p>
              <el-select
                v-model="Languageselect1"
                :placeholder="$t('CommonName.Pleaseselect')"
                class="selectclass"
                filterable
              >
                <el-option
                  v-for="item in languageoptions"
                  :key="item.language_id"
                  :label="item.cname"
                  :value="item.language_id"
                >
                </el-option>
              </el-select>
            </div>
            <div>
              <!-- <p>目标语言</p> -->
              <p>{{ $t("CommonName.targetlanguagetable") }}</p>
              <el-select
                v-model="Languageselect2"
                :placeholder="$t('CommonName.Pleaseselect')"
                class="selectclass"
                filterable
              >
                <el-option
                  v-for="item in languageoptions"
                  :key="item.language_id"
                  :label="item.cname"
                  :value="item.language_id"
                >
                </el-option>
              </el-select>
            </div>
            <div>
              <!-- <p>术语</p> -->
              <p>{{ $t("CommonName.lookupText1") }}</p>
              <el-input
                v-model="Languageselect3"
                @keyup.enter.native="searchClick"
              ></el-input>
            </div>
          </div>
          <div class="tabs1_top_right">
            <el-button
              type="primary"
              plain
              class="setpadding23"
              @click="searchClick"
            >
              <!-- 查询 -->
              {{ $t("ProjectManagement.query") }}
            </el-button>
          </div>
        </div>
        <div
          class="tabs1_bottom tableClass borderTableStyle"
          :class="tableDataglo.length == 0 ? 'tablebtmborder' : ''"
        >
          <el-table
            :row-style="{
              height: 40 + 'px',
              background: '#393c4e',
              color: '#cbd7e8',
            }"
            :cell-style="{ padding: 0 + 'px' }"
            :header-cell-style="{
              height: 32 + 'px',
              padding: '0px',
              color: '#adb0bc',
              background: '#393c4e',
            }"
            :data="tableDataglo"
            tooltip-effect="dark"
            style="width: 100%; overflow-y: auto"
            max-height="320px"
            border
          >
            <el-table-column type="index" width="64" label="#" center>
              <template slot-scope="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <!-- <p>术语</p> -->
            <el-table-column prop="term" :label="$t('CommonName.lookupText1')">
            </el-table-column>
            <!-- 译文 -->
            <el-table-column
              prop="meaning"
              :label="$t('ProjectManagement.translation')"
              label-class-name="labelClassName"
            >
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div v-if="Glossariestabs == 1">
        <div
          class="tabs2 tableClass"
          :class="tableDataglo2.length == 0 ? 'tablebtmborder' : ''"
        >
          <!-- <p>当前项目术语库</p> -->
          <p>{{ $t("ProjectManagement.Currentprojectglossary") }}</p>
          <el-table
            :row-style="{
              height: 40 + 'px',
              background: '#393c4e',
              color: '#cbd7e8',
            }"
            :cell-style="{ padding: 0 + 'px' }"
            :header-cell-style="{
              height: 32 + 'px',
              padding: '0px',
              color: '#adb0bc',
              background: '#393c4e',
            }"
            ref="tableDataglo2"
            :data="tableDataglo2"
            tooltip-effect="dark"
            style="width: 100%"
            max-height="320px"
            border
          >
            <el-table-column
              prop="name"
              :label="$t('ProjectManagement.Filename')"
              width="420"
            >
              <template slot-scope="scope">
                <div class="setSelectClass">
                  <el-select
                    v-model="scope.row.term_file_id"
                    :placeholder="$t('CommonName.Pleaseselect')"
                    popper-class="selectclass1"
                    filterable
                    @change="selectChange(scope.row)"
                  >
                    <el-option
                      v-for="item in filenameoptions"
                      :key="item.term_file_id"
                      :label="item.name"
                      :value="item.term_file_id"
                    >
                      <div class="selectContentClass">
                        <p class="onep overflowEllipsis">
                          <span>{{ item.name }}</span>
                        </p>
                        <p class="twop overflowEllipsis">
                          <span>{{ item.slang_name }}</span>
                        </p>
                        <!-- <p class="overflowEllipsis">
                        <span>{{ item.tlang_name }}</span>
                      </p> -->
                        <p>
                          <span>{{ item.segment }}</span>
                        </p>
                      </div>
                    </el-option>
                  </el-select>
                </div>
              </template>
            </el-table-column>
            <!-- 源语言 -->
            <el-table-column
              prop="slang_name"
              show-overflow-tooltip
              :label="$t('CommonName.sourcelanguagetable')"
            >
            </el-table-column>
            <!-- 目标语言 -->
            <!-- <el-table-column
            prop="tlang_name"
            show-overflow-tooltip
            :label="$t('CommonName.targetlanguagetable')"
          >
          </el-table-column> -->
            <!-- 术语数量 -->
            <el-table-column
              prop="segment"
              width="85"
              :label="$t('ProjectManagement.Numberofterms')"
            >
            </el-table-column>
            <!-- 操作 -->
            <el-table-column
              label=""
              width="150"
              label-class-name="labelClassName"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.showbtn">
                  <el-button type="text" @click="cancelClick(scope.$index)"
                    >{{ $t("CommonName.deleteMessageClosebutton") }}
                  </el-button>
                  <!-- 确认 -->
                  <el-button
                    type="text"
                    style="margin-left: 0; margin-right: 12px; float: right"
                    @click="confirmClick(scope.row, 1)"
                    >{{ $t("ProjectManagement.confirm") }}
                  </el-button>
                </div>
                <!-- 移除 -->
                <div v-else>
                  <el-button type="text" @click="confirmClick(scope.row, 2)"
                    >{{ $t("ProjectManagement.remove") }}
                  </el-button>
                  <!-- 查看 -->
                  <el-button
                    type="text"
                    style="margin-left: 0; margin-right: 12px; float: right"
                    @click="CheckClick(scope.row)"
                    >{{ $t("ProjectManagement.CheckInfo") }}
                  </el-button>
                  <!-- <el-button
                  type="text"
                  style="margin-left: 0; margin-right: 12px; float: right"
                  @click="updateClick(scope.row)"
                  v-if="scope.row.create_id == user_id"
                  >{{ $t("ProjectManagement.Update") }}
                </el-button> -->
                </div>
              </template>
            </el-table-column>
            <!-- 从术语库添加其他术语 -->
          </el-table>
          <div class="tableappend" v-if="tableDataglo2.length == 0">
            <el-button type="text" @click="addtableData(0)">
              +{{ $t("ProjectManagement.addTerms") }}
            </el-button>
          </div>
        </div>
        <div
          style="margin-top: 16px"
          class="tabs2 tableClass"
          :class="tableDataglo3.length == 0 ? 'tablebtmborder' : ''"
        >
          <!-- <p>当前通用术语库</p> -->
          <p>{{ $t("TermBaseManagement.termname1") }}</p>
          <el-table
            :row-style="{
              height: 40 + 'px',
              background: '#393c4e',
              color: '#cbd7e8',
            }"
            :cell-style="{ padding: 0 + 'px' }"
            :header-cell-style="{
              height: 32 + 'px',
              padding: '0px',
              color: '#adb0bc',
              background: '#393c4e',
            }"
            ref="tableDataglo2"
            :data="tableDataglo3"
            tooltip-effect="dark"
            style="width: 100%"
            max-height="320px"
            border
          >
            <el-table-column
              prop="name"
              :label="$t('ProjectManagement.Filename')"
              width="420"
            >
              <template slot-scope="scope">
                <div class="setSelectClass">
                  <el-select
                    v-model="scope.row.term_file_id"
                    :placeholder="$t('CommonName.Pleaseselect')"
                    popper-class="selectclass1"
                    filterable
                    @change="selectChange(scope.row, 2)"
                  >
                    <el-option
                      v-for="item in base_term_list"
                      :key="item.term_file_id"
                      :label="item.name"
                      :value="item.term_file_id"
                    >
                      <div class="selectContentClass">
                        <p class="onep overflowEllipsis">
                          <span>{{ item.name }}</span>
                        </p>
                        <p class="twop overflowEllipsis">
                          <span>{{ item.slang_name }}</span>
                        </p>
                        <!-- <p class="overflowEllipsis">
                        <span>{{ item.tlang_name }}</span>
                      </p> -->
                        <p>
                          <span>{{ item.segment }}</span>
                        </p>
                      </div>
                    </el-option>
                  </el-select>
                </div>
              </template>
            </el-table-column>
            <!-- 源语言 -->
            <el-table-column
              prop="slang_name"
              show-overflow-tooltip
              :label="$t('CommonName.sourcelanguagetable')"
            >
            </el-table-column>
            <!-- 目标语言 -->
            <!-- <el-table-column
            prop="tlang_name"
            show-overflow-tooltip
            :label="$t('CommonName.targetlanguagetable')"
          >
          </el-table-column> -->
            <!-- 术语数量 -->
            <el-table-column
              prop="segment"
              width="85"
              :label="$t('ProjectManagement.Numberofterms')"
            >
            </el-table-column>
            <!-- 操作 -->
            <el-table-column
              label=""
              width="150"
              label-class-name="labelClassName"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.showbtn">
                  <el-button type="text" @click="cancelClick(scope.$index, 1)"
                    >{{ $t("CommonName.deleteMessageClosebutton") }}
                  </el-button>
                  <!-- 确认 -->
                  <el-button
                    type="text"
                    style="margin-left: 0; margin-right: 12px; float: right"
                    @click="confirmClick(scope.row, 1, 1)"
                    >{{ $t("ProjectManagement.confirm") }}
                  </el-button>
                </div>
                <!-- 移除 -->
                <div v-else>
                  <el-button type="text" @click="confirmClick(scope.row, 2, 1)"
                    >{{ $t("ProjectManagement.remove") }}
                  </el-button>
                  <!-- 查看 -->
                  <el-button
                    type="text"
                    style="margin-left: 0; margin-right: 12px; float: right"
                    @click="CheckClick(scope.row)"
                    >{{ $t("ProjectManagement.CheckInfo") }}
                  </el-button>
                  <!-- <el-button
                  type="text"
                  style="margin-left: 0; margin-right: 12px; float: right"
                  @click="updateClick(scope.row)"
                  v-if="scope.row.create_id == user_id"
                  >{{ $t("ProjectManagement.Update") }}
                </el-button> -->
                </div>
              </template>
            </el-table-column>
            <!-- 从术语库添加其他术语 -->
          </el-table>
          <div class="tableappend" v-if="tableDataglo3.length == 0">
            <el-button type="text" @click="addtableData(1)">
              +{{ $t("ProjectManagement.addTerms") }}
            </el-button>
          </div>
        </div>
      </div>

      <div v-if="Glossariestabs == 2" class="Glossariestabs4">
        <div
          class="tabs2 tableClass"
          :class="Glossariestabs3TableData.length == 0 ? 'tablebtmborder' : ''"
        >
          <el-table
            :row-style="{
              height: 40 + 'px',
              background: '#393c4e',
              color: '#cbd7e8',
            }"
            :cell-style="{ padding: 0 + 'px' }"
            :header-cell-style="{
              height: 32 + 'px',
              padding: '0px',
              color: '#adb0bc',
              background: '#454758',
            }"
            ref="Glossariestabs3TableData"
            :data="Glossariestabs3TableData"
            tooltip-effect="dark"
            style="width: 100%"
            max-height="320"
            border
          >
            <el-table-column
              prop="name"
              :label="$t('ProjectManagement.TermCategoryName')"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <el-input
                  class="inputClassdisable"
                  v-model="scope.row.category"
                  v-show="!scope.row.disabled"
                  @input="handleInput(scope.row)"
                ></el-input>
                {{ scope.row.disabled ? scope.row.category : "" }}
              </template>
            </el-table-column>
            <!-- 操作 -->
            <el-table-column
              label-class-name="labelClassName"
              width="88"
              align="center"
            >
              <template slot-scope="scope">
                <div v-show="scope.row.other != 1">
                  <el-button
                    type="text"
                    @click="deleteClick(scope.$index, scope.row)"
                    v-if="scope.row.showbtn"
                  >
                    <div class="svgbtn">
                      <svg-icon icon-class="delete" class="icon"></svg-icon>
                    </div>
                  </el-button>
                  <el-button
                    type="text"
                    @click="createClick(scope.$index, scope.row)"
                    v-if="scope.row.showAdd"
                  >
                    <div class="svgbtn">
                      <svg-icon icon-class="save" class="icon"></svg-icon>
                    </div>
                  </el-button>
                </div>
              </template>
            </el-table-column>
            <!-- 从术语库添加其他术语 -->
          </el-table>
          <div class="tableappend">
            <el-button type="text" @click="addtableData_new">
              +{{ $t("ProjectManagement.addTermCategory") }}
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  customer_api_method,
  get_language,
  get_terms_list,
} from "@/utils/https";
import { showLoading, hideLoading } from "@/utils/loading";

export default {
  data() {
    return {
      project_id: "",
      maxheight1: 300,
      Glossariestabs: 1,
      tableDataglo2: [],
      filenameoptions: [],
      Languageselect1: "", //源语言 下拉框
      languageoptions: [], //源语言下拉框数据
      Languageselect2: "", //目标语言 下拉框

      Languageselect3: "", //术语
      selectrowData: {}, //术语弹窗 表格 下拉框使用
      tableDataglo: [], //术语弹窗 表格
      old_term_file_ids: [], //已有术语id集合
      user_id: "",
      Glossariestabs3TableData: [],
      base_term_list: [],
      tableDataglo3: [],
    };
  },
  created() {
    this.project_id = this.$route.query.id;
  },
  mounted() {
    // console.log(11111);
    let info = JSON.parse(sessionStorage.getItem("info"));
    this.user_id = info.user_id;
    showLoading(this.$t("CommonName.loadingmessage"));
    this.get_language();
    this.get_project_termfile_list();
    this.get_usable_termfile_list(this.project_id);
    this.get_termcategory_list(this.project_id);
  },
  methods: {
    //================================ 调用接口=======================================
    /**
     * 获取语言接口
     */
    get_language() {
      get_language({
        action: "get_language",
        lang:
          sessionStorage.getItem("lang") == "cn" ||
          sessionStorage.getItem("lang") == null
            ? 1
            : 2,
      }).then((res) => {
        if (res) {
          this.languageoptions = res.list;
        }
      });
    },

    /**
     *
     * @param {*获取项目已添加术语列表信息} id
     */
    get_project_termfile_list(mode = -1) {
      return customer_api_method({
        action: "get_termfile_list",
        project_id: this.project_id,
      }).then((res) => {
        if (!res) {
          return;
        }
        // console.log(res.list);
        let old_term_file_ids = [];
        res.list.forEach((i) => {
          i.showbtn = false;
          i.old_term_file_id = i.term_file_id;
          old_term_file_ids.push(i.term_file_id);
        });
        this.old_term_file_ids = old_term_file_ids;
        if (mode == 0) {
          this.tableDataglo2 = res.list;
        } else if (mode == 1) {
          this.tableDataglo3 = res.base_list;
        } else {
          this.tableDataglo2 = res.list;
          this.tableDataglo3 = res.base_list;
        }
        hideLoading();
      });
    },
    /**
     *
     * @param {*获取项目可添加术语列表信息} id
     */
    get_usable_termfile_list(id) {
      customer_api_method({
        action: "get_usable_termfile_list",
        project_id: id,
      }).then((res) => {
        hideLoading();
        if (!res) {
          return;
        }
        this.base_term_list = res.base_list;
        this.filenameoptions = res.list;
      });
    },
    /**
     *
     * @param {*获取项目下-术语类别列表} id
     */
    get_termcategory_list(id) {
      customer_api_method({
        action: "get_termcategory_list",
        project_id: id,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.Glossariestabs3TableData = res.list;
        this.Glossariestabs3TableData.forEach((i) => {
          i.disabled = true;
          i.showbtn = true;
        });
      });
    },
    /**
     *
     * @param {*添加术语表} id
     */
    add_termfile(id, mode) {
      customer_api_method({
        action: "add_termfile",
        project_id: this.project_id,
        term_file_id: id,
        term_type: mode,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.get_project_termfile_list(mode);
      });
    },
    /**
     *
     * @param {*移除术语表} id
     */
    remove_termfile(id, mode) {
      return customer_api_method({
        action: "remove_termfile",
        project_id: this.project_id,
        term_file_id: id,
        term_type: mode,
      }).then((res) => {
        if (!res) {
          return;
        }
      });
    },
    /**
     *
     * @param {*获取 术语表数据} data
     */
    get_terms_list(data) {
      return get_terms_list({
        action: "get_terms_list_for_langs",
        term_file_id: data,
        term: this.Languageselect3,
        slang_id: this.Languageselect1,
        tlang_id: this.Languageselect2,
      }).then((res) => {
        if (res) {
          // console.log(res.list);
          this.tableDataglo = res.list;
          hideLoading();
        }
      });
    },
    // =========================================其他 方法========================================================
    /**
     * 术语按钮 弹窗 内部的 tab页切换
     */
    changeGlossariestabs(str) {
      this.Glossariestabs = str;
    },
    /**
     *
     * @param {取消按钮,移除按钮} row
     */
    cancelClick(index, mode = 0) {
      if (mode == 0) {
        this.tableDataglo2.splice(index, 1);
      } else {
        this.tableDataglo3.splice(index, 1);
      }
      showLoading(this.$t("CommonName.loadingmessage"));
      this.get_project_termfile_list(mode);
    },
    /**
     *
     * @param {*点击保存 某个术语} row
     */
    confirmClick(row, data, mode = 0) {
      if (data == 1) {
        if (!row.term_file_id) {
          this.$messages("warning", this.$t("CommonName.selectMessage"));
          return;
        }
        // console.log(row);
        this.remove_termfile(row.old_term_file_id, mode).then(() => {
          showLoading(this.$t("CommonName.loadingmessage"));
          this.add_termfile(row.term_file_id, mode);
        });
      } else {
        this.$confirm(
          this.$t("CommonName.deleteMessage4"),
          this.$t("CommonName.deleteMessageTitle"),
          {
            confirmButtonText: this.$t("CommonName.deleteMessagebutton"),
            cancelButtonText: this.$t("CommonName.deleteMessageClosebutton"),
            closeOnClickModal: false,
            cancelButtonClass: "closeButton",
            // confirmButtonClass: "confirmButton",
          }
        ).then(() => {
          showLoading(this.$t("CommonName.loadingmessage"));
          this.remove_termfile(row.term_file_id, mode).then(() => {
            this.get_project_termfile_list();
          });
        });
        // console.log(row);
      }
    },
    /**
     *
     * @param {*下拉框选中} item
     */
    selectChange(item, mode = 1) {
      // console.log(item);
      if (mode == 2) {
        this.base_term_list.forEach((i) => {
          if (i.term_file_id == item.term_file_id) {
            item.name = i.name;
            item.slang_name = i.slang_name;
            item.tlang_name = i.tlang_name;
            item.segment = i.segment;
            item.slang_id = i.slang_id;
            item.tlang_id = i.tlang_id;
            item.showbtn = true;
          }
        });
      } else {
        this.filenameoptions.forEach((i) => {
          if (i.term_file_id == item.term_file_id) {
            item.name = i.name;
            item.slang_name = i.slang_name;
            item.tlang_name = i.tlang_name;
            item.segment = i.segment;
            item.slang_id = i.slang_id;
            item.tlang_id = i.tlang_id;
            item.showbtn = true;
          }
        });
      }
    },
    /**
     * 术语弹窗内 点击添加表格数据
     *
     */
    addtableData(mode) {
      if (mode == 1) {
        this.tableDataglo3.push({
          term_file_id: "",
          name: "",
          slang_name: "--",
          slang_id: "",
          tlang_name: "--",
          segment: "--",
          tlang_id: "",
          showbtn: true,
        });
      } else {
        this.tableDataglo2.push({
          term_file_id: "",
          name: "",
          slang_name: "--",
          slang_id: "",
          tlang_name: "--",
          segment: "--",
          tlang_id: "",
          showbtn: true,
        });
      }

      // this.$nextTick(() => {
      //   this.$refs.tableDataglo2.bodyWrapper.scrollTop =
      //     this.$refs.tableDataglo2.bodyWrapper.scrollHeight;
      // });
    },
    /**
     *
     * @param {*查询 术语表} data
     */
    searchClick() {
      if (!this.Languageselect1 || !this.Languageselect2) {
        this.$messages("warning", this.$t("CommonName.selectMessage"));
        return;
      }
      if (this.Languageselect1 == this.Languageselect2) {
        this.$messages("warning", this.$t("CommonName.selectMessage1"));
        return;
      }
      // console.log(this.Languageselect1, this.Languageselect2);
      if (this.tableDataglo2.length > 0 || this.tableDataglo3.length > 0) {
        showLoading(this.$t("CommonName.loadingmessage"));
        let ids = [];
        if (this.tableDataglo2.length > 0) {
          ids.push(this.tableDataglo2[0].term_file_id);
        }
        if (this.tableDataglo3.length > 0) {
          ids.push(this.tableDataglo3[0].term_file_id);
        }
        this.get_terms_list(ids);
      } else {
        this.tableDataglo = [];
      }
      // this.tableDataglo2.forEach((i) => {
      // console.log(i.term_file_id);
      // if (
      //   this.Languageselect1 == i.slang_id &&
      //   this.Languageselect2 == i.tlang_id
      // ) {
      //   showLoading(this.$t("CommonName.loadingmessage"));
      //   // console.log(i.term_file_id);
      //   this.get_terms_list(i.term_file_id);
      // } else {
      //   this.tableDataglo = [];
      // }
      // });
    },
    updateClick(data) {
      this.$emit("updateClick", data);
    },
    CheckClick(data) {
      let pathInfo = this.$router.resolve({
        path: "/ContainerHome/ExTeam/TermBaseManagement/LuckySheet",
        query: {
          id: data.term_file_id,
          lang_mode: 1,
        },
      });
      window.open(pathInfo.href, "_blank");
    },
    /**
     *
     * @param {取消按钮,移除按钮} row
     */
    deleteClick(index, i) {
      if (i.term_category_id == "") {
        this.Glossariestabs3TableData.splice(index, 1);
        return;
      }
      customer_api_method({
        action: "remove_term_category",
        project_id: this.project_id,
        term_category_id: i.term_category_id,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.Glossariestabs3TableData.splice(index, 1);
      });
    },
    /**
     *
     * @param {取消按钮,移除按钮} row
     */
    createClick(index, i) {
      if (i.category.trim() == "") {
        return;
      }
      customer_api_method({
        action: "add_term_category",
        project_id: this.project_id,
        category: i.category.trim(),
      }).then((res) => {
        if (!res) {
          return;
        }
        i.term_category_id = res.term_category_id;
        i.showAdd = false;
        i.disabled = true;
      });
    },
    /**
     *
     * @param {* 表格添加} data
     */
    addtableData_new() {
      this.Glossariestabs3TableData.push({
        term_category_id: "",
        category: "",
        showbtn: true,
        showAdd: true,
        disabled: false,
      });
      this.$nextTick(() => {
        this.$refs.Glossariestabs3TableData.bodyWrapper.scrollTop =
          this.$refs.Glossariestabs3TableData.bodyWrapper.scrollHeight;
      });
    },
  },
};
</script>
<style scoped lang="scss">
.GlossariesDialog {
  margin: 24px;

  .glo_top {
    width: 840px;
    height: 32px;
    display: flex;
    line-height: 32px;
    background-color: #51546a;
    border-radius: 3px;
    color: #b3d3ec;
    font-size: 14px;
    position: relative;

    p {
      padding: 0 15px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }

    .setbackground {
      background-color: #46adff;
      border-radius: 3px;
      font-weight: 700;
      color: #ffffff;
    }

    .tabsbtnicon {
      position: absolute;
      color: #46adff;
      z-index: 99;
      top: 27px;
    }
  }

  .glo_bottom {
    margin-top: 22px;

    .tabs1_top {
      display: flex;
      justify-content: space-between;

      .tabs1_top_left {
        display: flex;

        p {
          color: #adb0bc;
          font-size: 14px;
          margin-bottom: 5px;
        }

        .selectclass {
          margin-right: 24px;
        }

        ::v-deep .el-input__inner {
          width: 220px !important;
        }

        ::v-deep .el-input__icon {
          line-height: 32px;
        }
      }

      .tabs1_top_right {
        margin-top: 24px;
      }
    }

    .tabs1_bottom {
      // height: 300px;
      margin-top: 24px;
    }

    .tabs2 {
      p {
        color: #adb0bc;
        font-size: 14px;
        margin-bottom: 10px;
      }

      .setSelectClass {
        ::v-deep .el-input__inner {
          width: 400px !important;
        }

        ::v-deep .el-input__icon {
          line-height: 32px;
        }
      }

      .tableappend {
        height: 40px;
        background-color: #393c4e;
        line-height: 40px;
        padding-left: 12px;
        border-left: 1px solid #515464;
        border-bottom: 1px solid #515464;
        border-right: 1px solid #515464;
      }

      ::v-deep .el-table {
        overflow: initial;
      }
    }
  }

  ::v-deep .el-table--border .el-table__cell {
    .cell {
      word-break: break-word;
    }
  }
}
</style>
