<!--
 * @Description: 主播单体
 * @version: 
 * @Author: Tom
 * @Date: 2022-10-27 14:25:20
 * @LastEditors: Carful
 * @LastEditTime: 2023-03-21 10:42:20
-->
<template>
  <div
    class="tts-model-anchor"
    :id="anchorSelected && mode == 1 ? 'anchor_active' : ''"
    :class="anchorSelected && mode == 1 ? 'active' : ''"
    @click="anchorClick()"
  >
    <div class="tts-model-anchor-head">
      <img :src="anchorSrc" />
    </div>
    <div class="tts-model-anchor-text">
      <div class="tts-model-anchor-text-1">
        <div
          v-show="!role_name_input"
          style="
            display: flex;
            align-items: center;
            width: 210px;
            justify-content: space-between;
          "
        >
          <el-tooltip
            :content="anchorName"
            placement="top-start"
            effect="dark"
            :visible-arrow="false"
          >
            <span class="role_name_span title_tooltip"> {{ anchorName }}</span>
          </el-tooltip>
          <template v-if="mode == 0">
            <el-tooltip
              :content="$t('VideoDubbingClone.dubText25')"
              placement="top"
              effect="dark"
              :visible-arrow="false"
            >
              <svg-icon
                style="font-size: 19px"
                :iconClass="auditionStatus ? 'Audition_Stop' : 'Audition'"
                className="edit"
                @click="auditionClick($event)"
              ></svg-icon>
            </el-tooltip>
            <el-tooltip
              :content="$t('VideoDubbingClone.dubText9')"
              placement="top"
              effect="dark"
              :visible-arrow="false"
            >
              <svg-icon
                iconClass="Edit"
                className="edit"
                @click="role_name_input_show($event)"
              ></svg-icon>
            </el-tooltip>
            <el-tooltip
              :content="$t('VideoDubbingClone.dubText19')"
              placement="top"
              effect="dark"
              :visible-arrow="false"
            >
              <svg-icon
                style="font-size: 19px"
                iconClass="delete3"
                className="edit"
                @click="role_delete($event)"
              ></svg-icon>
            </el-tooltip>
          </template>
          <template v-if="mode == 1">
            <el-tooltip
              :content="$t('VideoDubbingClone.dubText25')"
              placement="top"
              effect="dark"
              :visible-arrow="false"
            >
              <svg-icon
                style="font-size: 19px"
                :iconClass="auditionStatus ? 'Audition_Stop' : 'Audition'"
                className="edit"
                @click="auditionClick($event)"
              ></svg-icon>
            </el-tooltip>
          </template>
        </div>
        <el-input
          type="text"
          v-model="role_name"
          :maxlength="20"
          v-show="role_name_input"
          @blur="roleNameInputModelBlur()"
          @keyup.enter.native="roleNameInputModelBlur()"
          v-focus="true"
        >
        </el-input>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "anchor-com",
  props: {
    anchorId: {
      type: String,
      default: "",
    },
    anchorName: {
      type: String,
      default: "",
    },
    anchorNameUpdate: {
      type: String,
      default: "",
    },
    anchorNameInput: {
      type: Boolean,
      default: false,
    },
    anchorGender: {
      type: String,
      default: "1",
    },
    anchorUrl: {
      type: String,
      default: require("@/assets/imgs/AI_ChatHead.png"),
    },
    anchorIndex: {
      type: Number,
      default: 0,
    },
    updateNumber: {
      type: Number,
      default: 0,
    },
    mode: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      //角色头像集合1：男声； 2：女声；3：童声（女）；4：童声（男）
      //src：常态图片地址；hover:鼠标滑动图片地址； play：播放时图片地址
      anchorSrcs: {
        1: {
          src: require("@/assets/imgs/AI_ChatHead.png"),
          hover: require("@/assets/imgs/AI_ChatHead.png"),
          play: require("@/assets/imgs/AI_ChatHead.png"),
        },
        2: {
          src: require("@/assets/imgs/Portrait_Woman.png"),
          hover: require("@/assets/imgs/Portrait_WomanPlay.png"),
          play: require("@/assets/imgs/Portrait_WomanPause.png"),
        },
        3: {
          src: require("@/assets/imgs/Portrait_child.png"),
          hover: require("@/assets/imgs/Portrait_childPlay.png"),
          play: require("@/assets/imgs/Portrait_childPause.png"),
        },
        4: {
          src: require("@/assets/imgs/Portrait_child2.png"),
          hover: require("@/assets/imgs/Portrait_child2.png"),
          play: require("@/assets/imgs/Portrait_child2.png"),
        },
      },
      auditionStatus: false,
      collectStatus: false,
      anchorSelected: false,
      role_name: "",
      role_name_input: this.anchorNameInput,
      roleDeleteing: false,
    };
  },
  computed: {
    anchorSrc() {
      let src;
      src = this.anchorSrcs[this.anchorGender]["src"];
      return src;
    },
    ...mapState({
      selectedAnchorId: (state) => state.VideoDubbingClone.selectedAnchorId,
      playAnchorId: (state) => state.VideoDubbingClone.playAnchorId,
    }),
  },
  watch: {
    selectedAnchorId(value) {
      if (this.anchorId === value) {
        this.anchorSelected = true;
      } else {
        this.anchorSelected = false;
      }
    },
    playAnchorId(value) {
      if (this.anchorId === value) {
        this.auditionStatus = true;
      } else {
        this.auditionStatus = false;
      }
    },
    updateNumber(value) {
      this.role_name = this.anchorName;
    },
  },
  methods: {
    auditionClick(e) {
      e.stopPropagation();
      this.$emit("anchor-audition", [
        this.anchorId,
        this.anchorIndex,
        !this.auditionStatus,
      ]);
    },
    anchorClick() {
      if (this.mode == 1) {
        this.$emit("anchor-click", [this.anchorId, this.anchorIndex]);
        this.$store.dispatch(
          "VideoDubbingClone/updateSelectedAnchorId",
          this.anchorId
        );
      }
    },
    role_name_input_show(e) {
      e.stopPropagation();
      this.role_name_input = true;
    },
    roleNameInputModelBlur() {
      if (this.role_name_input == false) {
        return;
      }
      this.role_name_input = false;
      // this.role_name = this.role_name.trim();
      // console.log("anchorNameUpdate", this.anchorNameUpdate);
      // if (this.role_name == this.anchorNameUpdate) {
      //   return;
      // }

      this.$emit("anchor-update", [
        this.anchorId,
        this.anchorIndex,
        this.role_name,
      ]);
      if (this.role_name == "") {
        this.role_name = this.anchorName;
      }
    },
    role_delete(e) {
      e.stopPropagation();
      if (this.roleDeleteing == false) {
        this.roleDeleteing = true;
        let title = "";
        title = this.$t("VideoDubbingClone.comfirmTips1");
        this.$confirm(title, this.$t("VideoDubbing.comfirmTips1"), {
          confirmButtonText: this.$t("VideoDubbing.comfirmTips2"),
          cancelButtonText: this.$t("VideoDubbing.comfirmTips3"),
          cancelButtonClass: "el-button--info",
          iconClass: "none",
          closeOnClickModal: false,
          distinguishCancelAndClose: false,
          closeOnPressEscape: false,
          type: "warning",
        })
          .then(() => {
            this.roleDeleteing = false;
            this.$emit("anchor-delete", [this.anchorId, this.anchorIndex]);
          })
          .catch((action) => {
            if (action === "cancel") {
              console.log("cancel");
              this.roleDeleteing = false;
            }
          });
      }
    },
  },
  mounted() {
    this.role_name = this.anchorName;
    if (this.anchorId === this.selectedAnchorId) {
      this.anchorSelected = true;
    } else {
      this.anchorSelected = false;
    }
  },
};
</script>
<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.ts-model-main-right-anchors-tab-pane .tts-model-anchor {
  margin: 0 10px 8px;
}

.tts-model-anchor {
  width: 296px;
  height: 64px;
  border: 1px solid;
  border-color: #7d7f8b;
  border-radius: 4px;
  padding: 12px 8px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.tts-model-anchor:hover {
  background-color: #212334;
}

.tts-model-anchor.active {
  border-color: #46adff;
  background-color: #2b3649;
}

.tts-model-anchor-head {
  width: 40px;
  height: 40px;
  float: left;
}

.tts-model-anchor-text {
  color: #fff;
  float: left;
  margin-left: 12px;
  text-align: left;
  height: 38px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 210px;

  ::v-deep .el-input__inner {
    width: 210px;
  }
}

.tts-model-anchor-text-1 {
  font-size: 14px;

  .role_name_span {
    width: 140px;
    height: 22px;
  }

  .svg-icon {
    margin-left: 8px;
    font-size: 17px;
  }

  .svg-icon.edit:hover {
    color: #409eff;
  }
}

.tts-model-anchor-head img {
  width: 100%;
}

.tts-model-anchor .collect-svg {
  position: absolute;
  right: 5px;
  top: 5px;
  color: #51546a;
}

.tts-model-anchor .collect-svg:hover {
  color: #fff784;
}

.tts-model-anchor .collect-svg.active {
  color: #fff784;
}

.svg-gender {
  margin-left: 5px;
}

.tts-model-anchor-flag {
  position: absolute;
  height: 27px;
  line-height: 34px;
  text-align: center;
  width: 70px;
  background-color: #da9813;
  color: #fff;
  font-size: 10px;
}

.tts-model-anchor .flag-left {
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: -25px;
  top: -5px;
}

.title_tooltip {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
