<!--
 * @Description: 导入任务 弹窗
 * @version: 
 * @Author: Carful
 * @Date: 2022-11-16 09:48:24
 * @LastEditors: Carful
 * @LastEditTime: 2023-06-27 16:09:22
-->
<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    :width="'900px'"
    :top="'3%'"
    center
    :close-on-click-modal="false"
    @close="closeDialog"
  >
    <!-- ------------------------------------------------------步骤一----------------------------------------------------- -->
    <div v-if="step == '1'" class="step1Center">
      <div class="step1Center_top">
        <div class="radioClass step1Center_left">
          <div class="verification">
            <!-- Task workflow -->
            <p>{{ $t("CommonName.Taskworkflow.name") }}</p>
            <svg-icon
              icon-class="presentation"
              class="verificationIcon"
              v-if="radio1 == '-1'"
            ></svg-icon>
          </div>
          <el-radio-group v-model="radio1" @change="radiochange1">
            <el-radio
              v-for="i in radioList1"
              :key="i.label"
              :label="i.label"
              border
              >{{ i.name }}</el-radio
            >
          </el-radio-group>
        </div>
      </div>
      <div class="step1Center_bottom">
        <div class="step1Center_left">
          <div class="verification">
            <!-- Source language-->
            <p>{{ $t("CommonName.Sourcelanguage") }}</p>
            <svg-icon
              icon-class="presentation"
              class="verificationIcon"
              v-if="select1 == null"
            ></svg-icon>
          </div>
          <el-select
            v-model="select1"
            class="selectclass"
            @change="SourcelanguageChange"
            filterable
          >
            <el-option
              v-for="item in languageoptions"
              :key="item.language_id"
              :label="item.cname"
              :value="item.language_id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="step1Center_right" v-if="radio1 !== '1'">
          <div class="verification">
            <!-- Target language-->
            <p>{{ $t("CommonName.Targetlanguage") }}</p>
            <svg-icon
              icon-class="presentation"
              class="verificationIcon"
              v-if="select2_error == '0'"
            ></svg-icon>
          </div>
          <el-select
            v-model="select2"
            multiple
            class="selectclass"
            @change="select2_error = '1'"
            filterable
          >
            <el-option
              v-for="item in languageoptions"
              :key="item.language_id"
              :label="item.cname"
              :value="item.language_id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div
        class="step1Center_center checkboxClass"
        v-show="['2', '3', '4', '5'].includes(radio1)"
      >
        <!-- "AIT" -->
        <el-checkbox v-model="AITCheck" border style="width: 400px">{{
          $t("CommonName.AITText")
        }}</el-checkbox>
      </div>
      <div
        class="step1Center_center radioClass"
        v-if="AITCheck"
        v-show="['1', '2', '3', '4', '5'].includes(radio1)"
      >
        <!-- "Main ASR Engine" -->
        <!-- <p>{{ $t("CommonName.ASREngine.name") }}</p> -->
        <el-radio-group v-model="radio6" style="width: 100%">
          <el-radio
            v-for="i in radioList6"
            :key="i.label"
            :label="i.label"
            :disabled="i.disabled"
            style="width: 33.3%"
            border
            >{{ i.name }}
          </el-radio>
        </el-radio-group>
      </div>
    </div>
    <!-- ------------------------------------------------------步骤二----------------------------------------------------- -->
    <div v-if="step == '2'" class="step2Center">
      <!-- Select project  下拉框-->
      <div>
        <div class="radio_content_2">
          <p>{{ $t("CommonName.Selectproject") }}</p>
          <el-select
            v-model="select3"
            class="selectclass"
            @change="select3Change"
            placeholder=""
            filterable
          >
            <el-option
              v-for="item in select3List"
              :key="item.project_id"
              :label="item.project_name"
              :value="item.project_id"
            >
            </el-option>
          </el-select>
        </div>
        <!-- Order of texts in subtitle file -->
        <div class="step1Center_center_1 radioClass">
          <p>{{ $t("CommonName.Orderoftextsinsubtitlefile.name") }}</p>
          <el-radio-group v-model="radio2" @change="radiochange2">
            <el-radio
              v-for="i in radioList2"
              :key="i.label"
              :label="i.label"
              border
              >{{ i.name }}</el-radio
            >
          </el-radio-group>
        </div>
        <div class="radio_content_3">
          <div class="verification">
            <!--Select media file in project-->
            <p>{{ $t("CommonName.Selectmediafileinproject") }}</p>
            <svg-icon
              icon-class="presentation"
              class="verificationIcon"
              v-if="select4 == null"
            ></svg-icon>
          </div>
          <el-select
            v-model="select4"
            class="multipleSelect langselectclass"
            :disabled="select4Disabled"
            multiple
            filterable
            :filter-method="filterValue"
            @change="changeSelect"
            @visible-change="visibleChangeSelect"
            :reserve-keyword="true"
            collapse-tags
            placeholder=""
          >
            <li style="padding: 5px 20px">
              <el-checkbox
                v-model="selectAllChecked"
                @change="selectAll"
                v-if="this.select4List.length == this.select4BaseList.length"
                >{{ $t("CommonName.SelectAllItem") }}</el-checkbox
              >
            </li>

            <el-option
              v-for="item in select4List"
              :key="item.job_id"
              :label="item.job_name"
              :value="item.job_id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <!-- Select Task Mode -->
      <!-- <div class="step1Center_center_1 radioClass">
        <p>{{ $t("ImportTask.Selecttaskmode.name") }}</p>
        <el-radio-group v-model="step2radio1">
          <el-radio
            v-for="i in step2radioList1"
            :key="i.label"
            :label="i.label"
            border
            >{{ i.name }}</el-radio
          >
        </el-radio-group>
      </div> -->
      <!-- =========== Speech transcription deadline  step2time1,2-->
      <div class="content_center_footer">
        <div class="checkboxClass">
          <el-checkbox v-model="huaCheck" border @change="huaCheckChange">
            {{ $t("OverwriteOperation.hua_text") }}
          </el-checkbox>
        </div>
      </div>
      <div class="content_center_footer">
        <div class="content__footer_left">
          <p>{{ $t("CommonName.replace1") }}{{ $t("CommonName.optional") }}</p>
          <el-input v-model="replaceinput1" placeholder="" class="selectclass">
          </el-input>
        </div>
        <div class="content__footer_right">
          <!-- Subtitle translation deadline -->
          <p>{{ $t("CommonName.replace2") }}{{ $t("CommonName.optional") }}</p>
          <el-input v-model="replaceinput2" placeholder="" class="selectclass">
          </el-input>
        </div>
      </div>
      <div class="content_center_footer">
        <div
          class="content__footer_left"
          v-if="['1', '2', '5'].includes(radio1)"
        >
          <p>{{ $t("CommonName.Speechtranscriptiondeadline") }}</p>
          <el-date-picker
            v-model="step2time1"
            type="datetime"
            placeholder=""
            :format="time_format"
            value-format="yyyy-MM-dd HH:mm:ss"
            class="selectclass"
          >
          </el-date-picker>
        </div>
        <div
          class="content__footer_right"
          v-if="['2', '3', '4', '5'].includes(radio1)"
        >
          <!-- Subtitle translation deadline -->
          <p>{{ $t("CommonName.Subtitletranslationdeadline") }}</p>
          <el-date-picker
            v-model="step2time2"
            type="datetime"
            placeholder=""
            :format="time_format"
            value-format="yyyy-MM-dd HH:mm:ss"
            class="selectclass"
          >
          </el-date-picker>
        </div>
      </div>
      <!-----------------------------Subtitle Review Deadline------step2time3  -->
      <div v-if="['4', '5'].includes(radio1)">
        <div class="content__footer_left">
          <!-- Subtitle review deadline-->
          <div class="verification">
            <p>{{ $t("CommonName.Subtitlereviewdeadline") }}</p>
            <svg-icon
              icon-class="presentation"
              class="verificationIcon"
              v-if="step2time3 == null"
            ></svg-icon>
          </div>
          <el-date-picker
            v-model="step2time3"
            type="datetime"
            :format="time_format"
            value-format="yyyy-MM-dd HH:mm:ss"
            class="selectclass"
          >
          </el-date-picker>
        </div>
      </div>
      <template v-if="['2', '3', '4', '5'].includes(radio1)">
        <div
          class="content_center_footer"
          v-for="(itemL, index) in selectTM"
          :key="index"
        >
          <!-- Temp TM T(optional)           select6 -->
          <div class="content__footer_left">
            <div class="verification">
              <p>
                {{ itemL.lang_name + " " + $t("CommonName.TempTMToptional") }}
              </p>
            </div>
            <el-select v-model="selectTM[index]['t']" class="selectclass">
              <el-option
                v-for="item in select6List"
                :key="item.tm_id"
                :label="item.name"
                :value="item.tm_id"
                v-show="item.tlang == itemL.lang_id"
              >
              </el-option>
            </el-select>
          </div>
          <!-- Temp TM E(optional)-------------select7 -->
          <div class="content__footer_right" v-if="['4', '5'].includes(radio1)">
            <div class="verification">
              <p>
                {{ itemL.lang_name + " " + $t("CommonName.TempTMEoptional") }}
              </p>
            </div>
            <el-select v-model="selectTM[index]['e']" class="selectclass">
              <el-option
                v-for="item in select6List"
                :key="item.tm_id"
                :label="item.name"
                :value="item.tm_id"
                v-show="item.tlang == itemL.lang_id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </template>
    </div>
    <div slot="footer" class="dialog-footer" style="margin-top: 24px">
      <div class="Previousbtn" v-if="step !== '1'">
        <el-button @click="PreviousClick" type="info" class="setpadding16">
          <div class="btnicon">
            <svg-icon icon-class="Aright" class="icon"></svg-icon>
            <p class="footerleftbuttonp">{{ $t("CommonName.Previous") }}</p>
          </div>
        </el-button>
      </div>
      <div>
        <el-button @click="closeDialog" type="info" class="setpadding23"
          ><p class="footerleftbuttonp">
            {{ $t("CommonName.Cancel") }}
          </p></el-button
        >
        <el-button
          type="primary"
          @click="NextClick"
          class="setpadding23"
          v-if="step == '1'"
        >
          <p class="footerrightbuttonp2" style="">
            {{ $t("CommonName.Next") }}
          </p>
        </el-button>
        <el-button
          type="primary"
          @click="determineClick"
          class="setpadding23"
          v-else
        >
          <p class="footerrightbuttonp2" style="">
            {{ $t("CommonName.Sure") }}
          </p>
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { getCurrentTime } from "@/api/setTime";
import {
  get_language,
  import_job_method,
  get_import_project_list,
  get_import_project_job_list,
  get_tm_list,
} from "@/utils/https";
import { showLoading } from "@/utils/loading";
export default {
  components: {},
  props: {
    showdialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      project_id: "",
      languageoptions: [], //视频语言下拉框数据
      dialogVisible: false,
      title: this.$t("ImportTask.title1"),
      step: "1",
      radio1: "",
      radioList1: [
        { label: "1", name: this.$t("CommonName.Taskworkflow.label0") }, //转写
        { label: "2", name: this.$t("CommonName.Taskworkflow.label1") }, //转写加翻译
        { label: "3", name: this.$t("CommonName.Taskworkflow.label2") }, //翻译
        { label: "4", name: this.$t("CommonName.Taskworkflow.label3") }, //翻译加校审
        { label: "5", name: this.$t("CommonName.Taskworkflow.label4") }, //转写+翻译+校审
      ],
      radio2: "0", //Order of texts in subtitle file
      radioList2: [
        {
          label: "0",
          name: this.$t("CommonName.Orderoftextsinsubtitlefile.label1"),
        },
        {
          label: "1",
          name: this.$t("CommonName.Orderoftextsinsubtitlefile.label0"),
        },
      ],
      step2radio1: "0", //Select Task Mode
      step2radioList1: [
        { label: "0", name: this.$t("ImportTask.Selecttaskmode.label0") },
        { label: "1", name: this.$t("ImportTask.Selecttaskmode.label1") },
      ],
      select1: "", //Source language
      select2: [], //Target language
      select2_error: "1",
      select3: "", //Select Project
      select3List: [],
      select4: [], //Select Media File in Project
      select4BaseList: [],
      select4List: [],
      select5: "", //Main TM (optional)
      select5List: [],
      select6: "", //Temp TM T(optional)
      select6List: [],
      select7: "", //Temp TM E(optional)
      select4Disabled: true,
      step2time1: "", //Speech transcription deadline
      step2time2: "", //Subtitle translation deadline
      step2time3: "", //Subtitle Review Deadline
      currentVal: "",
      selectAllChecked: false,
      replaceinput1: "", //被替换
      replaceinput2: "", //替换成
      time_format:
        sessionStorage.getItem("lang") == "en"
          ? "MMM dd yyyy HH:mm"
          : "yyyy-MM-dd HH:mm",
      selectTM: [], //TM选择集合
      AITCheck: false,
      huaCheck: false,
      radio6: "2", //AIT服务 0表示gpt，1表示deepseek阿里，2表示deepseek字节
      radioList6: [
        {
          label: "0",
          name: this.$t("CommonName.AITMode.label0"),
          disabled: false,
        },
        {
          label: "1",
          name: this.$t("CommonName.AITMode.label1"),
          disabled: false,
        },
        {
          label: "2",
          name: this.$t("CommonName.AITMode.label2"),
          disabled: false,
        },
      ],
    };
  },
  methods: {
    // !==========================================通用方法============================================================

    // !==========================================请求方法============================================================
    /**
     * 获取语言接口
     */
    get_language() {
      get_language({
        action: "get_language",
        lang:
          sessionStorage.getItem("lang") == "cn" ||
          sessionStorage.getItem("lang") == null
            ? 1
            : 2,
      }).then((res) => {
        if (res) {
          this.languageoptions = res.list;
        }
      });
    },
    /**
     *
     * @param {*导入任务} data
     */
    import_job_method(data) {
      let a = this.radio1;
      if (this.radio1 == "2") {
        a = "3";
      } else if (this.radio1 == "3") {
        a = "2";
      }

      import_job_method({
        action: "import_job_method",
        project_id: data, //项目id
        slang: this.select1, //源语言id
        tlang: this.select2, //目标语言id
        task_type: a, //任务类型，1转写、2翻译、3转写+翻译，4翻译+校审，5转写+翻译+校审
        file_mode_type: this.radio2, //0源语言在上, 1目标语言在上
        job_mode_type: this.step2radio1, //选择的任务来源方式 0：自选， 1：项目下全部delivered
        select_pid: this.select3, //选择的项目id
        select_jid: this.select4.join(","), //选择的任务id 字符串 多个用逗号分隔
        tm_list: this.selectTM, //tm集合
        date_dead_stt: this.step2time1, //转写预计交付时间
        date_dead_tra: this.step2time2, //翻译预计交付时间 按照任务类型 没有该节点就空
        date_dead_rev: this.step2time3, //校审预计交付时间 按照任务类型 没有该节点就空
        replace1: this.replaceinput1,
        replace2: this.replaceinput2,
        ait_mode: this.AITCheck ? 1 : 0,
        frame_mode: this.huaCheck ? 1 : 0,
        ait_type: this.radio6,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.$emit("requestImportTask", false);
        // this.$emit("clickImportTaskDialog", false);
      });
    },
    /**
     *
     * @param {*获取可以导入的项目列表信息} data
     */
    get_import_project_list(data) {
      get_import_project_list({
        action: "get_import_project_list",
      }).then((res) => {
        if (!res) {
          return;
        }
        this.select3List = res.list;
      });
    },
    /**
     *
     * @param {*获取项目下可以导入的任务列表信息} id
     */
    get_import_project_job_list() {
      if (this.select3 != "" && this.select1 != "") {
        get_import_project_job_list({
          action: "get_import_project_job_list_new",
          project_id: this.select3, //项目id
          lang_first: this.radio2, //原还是目标在上
          lang_id: this.select1,
          frame: this.huaCheck ? 1 : 0,
        }).then((res) => {
          if (!res) {
            return;
          }
          this.select4 = [];
          this.selectAllChecked = false;
          this.select4BaseList = res.list;
          this.select4List = res.list;
        });
      }
    },
    huaCheckChange() {
      this.get_import_project_job_list();
    },
    // 搜索过滤方法
    filterValue(currentVal) {
      if (currentVal.length > 0) {
        this.select4List = this.select4BaseList.filter((item) => {
          if (
            item.job_name.toLowerCase().indexOf(currentVal.toLowerCase()) > -1
          ) {
            return true;
          }
        });
        this.currentVal = currentVal;
      } else {
        this.select4List = this.select4BaseList;
        this.currentVal = "";
      }
    },
    selectAll() {
      this.select4 = [];
      if (this.selectAllChecked) {
        this.select4List.forEach((item) => {
          this.select4.push(item.job_id);
        });
      } else {
        this.select4 = [];
      }
    },
    changeSelect(val) {
      if (
        val.length === this.select4BaseList.length &&
        this.select4BaseList.length > 0
      ) {
        this.selectAllChecked = true;
      } else {
        this.selectAllChecked = false;
      }
    },
    visibleChangeSelect(val) {
      // console.log(val);
      if (val == false) {
        this.select4List = this.select4BaseList;
        if (
          this.select4.length == this.select4BaseList.length &&
          this.select4BaseList.length > 0
        ) {
          this.selectAllChecked = true;
        } else {
          this.selectAllChecked = false;
        }
      }
    },
    /**
     *
     * @param {*获取TM列表} data
     */
    get_tm_list(data) {
      get_tm_list({
        action: "get_tm_list",
        type: data, //1主TM，2临时TM
        slang_id: this.select1,
        tlang_ids: this.select2,
      }).then((res) => {
        if (!res) {
          return;
        }
        if (data == "1") {
          this.select5List = res.list;
        } else {
          this.select6List = res.list;
        }
      });
    },
    // !==========================================其他方法============================================================
    /**
     * 关闭弹窗
     */
    closeDialog() {
      this.dialogVisible = false;
      this.$emit("clickImportTaskDialog", this.dialogVisible);
    },
    /**
     * 确定按钮
     */
    determineClick() {
      //   console.log(this.select4);

      if (!this.select4) {
        this.$messages("warning", this.$t("CommonName.selectMessage"));
        return;
      }
      if (this.select4.length < 1) {
        this.select4 = null;
        this.$messages("warning", this.$t("CommonName.selectMessage"));
        return;
      }

      showLoading(this.$t("CommonName.loadingmessage"));
      this.import_job_method(this.project_id);
    },
    /**
     * 点击下一步
     */
    NextClick() {
      this.radio1 = this.radio1 || "-1";
      this.select1 = this.select1 || null;
      if (this.radio1 !== "1") {
        this.select2_error = this.select2.length > 0 ? "1" : "0";
      }
      if (this.radio1 === "-1") {
        this.$messages("warning", this.$t("CommonName.selectMessage"));
        return;
      }
      if (this.radio1 !== "1") {
        if (!this.select1 || this.select2.length == 0) {
          this.$messages("warning", this.$t("CommonName.selectMessage"));
          return;
        }
        if (this.select2.includes(this.select1)) {
          this.$messages("warning", this.$t("ImportTask.selectMessages"));
          return;
        }
      } else {
        if (!this.select1) {
          this.$messages("warning", this.$t("CommonName.selectMessage"));
          return;
        }
      }
      this.selectTM = [];
      if (this.select2.length > 0) {
        this.select2.forEach((item) => {
          const targetItem = this.languageoptions.find(
            (item1) => item1.language_id === item
          );
          if (targetItem) {
            this.selectTM.push({
              lang_id: item,
              t: "",
              e: "",
              lang_name: targetItem.cname,
            });
          }
        });
      }
      console.log(this.selectTM);
      // return;
      this.title = this.$t("CommonName.title2");
      this.step = "2";
      this.get_import_project_list();
      if (this.radio1 !== "1") {
        this.get_tm_list("2");
      }
      // this.step2time1 =
      // console.log(new Date());
      this.step2time1 = getCurrentTime("0");
      this.step2time2 = getCurrentTime("1");
      this.step2time3 = getCurrentTime("2");
    },
    /**
     * 上一步
     */
    PreviousClick() {
      this.title = this.$t("ImportTask.title1");
      this.step = "1";
    },
    /**
     *
     * @param {*左侧单选框} val
     */
    radiochange1(val) {
      if (val == "0") {
        this.select2 = "";
      }
      this.get_import_project_job_list();
    },
    /**
     * 只有Select Project 下拉选框有数据才能选下一个
     */
    select3Change() {
      this.select4Disabled = false;
      if (this.select3) {
        this.get_import_project_job_list();
      }
    },
    SourcelanguageChange(value) {
      this.get_import_project_job_list();
    },
    radiochange2(val) {
      this.get_import_project_job_list();
    },
  },
  mounted() {
    this.dialogVisible = this.showdialog;
    this.project_id = this.$route.query.id;
    this.get_language();
  },
};
</script>
<style scoped lang="scss">
@import "./AllDialogradio.scss";
.step1Center {
  margin: 4px 24px 24px 24px;
  p {
    color: #adb0bc;
    font-size: 14px;
    margin-top: 20px;
  }
  .el-radio.is-bordered.is-disabled {
    border-color: #5b5d6c;
  }
  ::v-deep .el-radio__input.is-disabled + span.el-radio__label {
    color: #595d69 !important;
  }
  ::v-deep .el-radio__input.is-disabled .el-radio__inner {
    background: none;
    border-color: #595d69;
  }
  .step1Center_top {
    display: flex;
    .step1Center_left {
      .el-radio-button__inner,
      .el-radio-group {
        display: flex;
        flex-direction: column;
      }
      .el-radio.is-bordered {
        width: 400px;
        margin-top: 6px;
      }
    }
    .step1Center_right {
      margin-left: 40px;
      .el-radio-button__inner,
      .el-radio-group {
        display: flex;
        flex-direction: column;
      }
      .el-radio.is-bordered {
        width: 400px;
        margin-top: 6px;
      }
    }
  }

  .step1Center_center {
    .el-radio.is-bordered {
      width: 210px;
      margin-top: 6px;
    }
  }
  .step1Center_bottom {
    display: flex;
    .step1Center_right {
      margin-left: 40px;
    }
  }
}

.step2Center {
  margin: 4px 24px 24px 24px;
  p {
    color: #adb0bc;
    font-size: 14px;
    margin-top: 20px;
  }
}
.checkboxClass1 {
  margin-top: 0px;

  .el-button + .el-button,
  .el-checkbox.is-bordered + .el-checkbox.is-bordered {
    margin-left: 0px;
  }
  .el-checkbox.is-bordered {
    width: 400px !important;
    margin-top: 6px;
    height: 32px;
    border: 1px solid;
    border-color: #7d7f8b;
    border-radius: 2px;
    color: #ffffff;
    padding: 0 0 0 12px;
    line-height: 32px;
  }
  .el-checkbox__inner {
    background: none !important;
    line-height: 0;
  }

  .el-checkbox__inner:hover {
    border-color: #ffffff;
  }

  .el-checkbox__input {
    line-height: 1px;
  }

  .el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: #46adff;
  }

  &.active {
    color: #46adff;
  }
}
</style>
