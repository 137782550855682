<!--
 * @Description: 工作台
 * @version: 
 * @Author: Tom
 * @Date: 2022-10-20 10:00:10
 * @LastEditors: Tom
 * @LastEditTime: 2024-10-14 14:03:40
-->
<template>
  <div class="contentdiv">
    <div class="titlediv">
      <p>
        <svg-icon icon-class="worktable" class="svgClass"></svg-icon
        ><span>{{ title }}</span>
      </p>
      <div>
        <div
          class="showGiftBagbtn"
          style="width: 120px"
          @click="handlePackageClick"
        >
          <!--        礼包码兑换-->
          <p>{{ $t("PersonalEndLanguage.GiftPackCodeRedemption") }}</p>
        </div>
        <div
          class="showGiftBagbtn"
          @click="showGiftBagbtn"
          v-if="showGiftBagbtns"
        >
          <!--        领取本月免费礼包-->
          <p>{{ $t("PersonalCenter.giftText") }}</p>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="content_content">
        <!-- 全部服务 -->
        <div class="topdiv">
          <div class="toptitlediv">
            <div></div>
            <p>{{ $t("PersonalEndLanguage.Allservices") }}</p>
          </div>
          <div class="servicebotdiv">
            <div class="service" v-for="item in serviceList" :key="item.id">
              <div
                class="bgservice"
                @click="serviceClick(item)"
                :class="
                  [6].includes(item.id) &&
                  user_info.video_dubbing_bigmodel === '0'
                    ? 'bgdisabled'
                    : ''
                "
              >
                <svg-icon
                  :icon-class="item.icon"
                  class="serviceicon"
                  :style="{ color: item.color }"
                ></svg-icon>

                <div class="servicetitle">
                  <p class="servicetitle_name">{{ item.name }}</p>
                  <p class="servicetitle_explain" v-html="item.explain"></p>
                </div>
                <div
                  v-if="
                    [6].includes(item.id) &&
                    user_info.video_dubbing_bigmodel === '0'
                  "
                  class="servicetitle1 bgdisabled1"
                >
                  <!--                  即将上线，敬请期待-->
                  <!--                  {{ $t("PersonalEndLanguage.Comingsoonstaytuned") }}-->
                  <!--                  仅对VIP客户开放定制服务-->
                  {{ $t("PersonalEndLanguage.vip") }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="borderdiv"></div>
        <!-- 最近文件 -->
        <div class="bottomdiv">
          <div class="toptitlediv">
            <div></div>
            <p>{{ $t("PersonalEndLanguage.RecentFiles") }}</p>
          </div>
          <div
            :class="imgData.length < 5 ? 'imagediv1' : 'imagediv'"
            v-if="imgData.length > 0"
          >
            <div
              v-for="(item, index) in setImageData"
              :key="index"
              class="imagediv_div"
            >
              <div v-if="item">
                <!--                处理中-->
                <div
                  v-loading="item.showLoadingnow"
                  :element-loading-text="
                    $t('PersonalEndLanguage.Processing') + item.progress
                  "
                  element-loading-spinner="el-icon-loading"
                  element-loading-background="rgba(0, 0, 0, 0.5)"
                  Class="customClassLoading"
                >
                  <img
                    @click="imageClick(item)"
                    :src="!item.image ? setImageUrl(item) : item.image"
                    alt=""
                  />
                </div>
                <p
                  class="title overflowEllipsis"
                  :id="'tag' + item.id"
                  @mouseover="onShowNameTipsMouseenter(item)"
                >
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="item.job_name"
                    placement="top-start"
                    :disabled="!item.showdropdown"
                    :visible-arrow="false"
                  >
                    <span>{{ item.job_name }}</span>
                  </el-tooltip>
                </p>
                <p class="rightspan">
                  <span>{{ item.private_job_cname }}</span
                  ><span class="rightspan">{{ item.create_time }}</span>
                </p>
              </div>
            </div>
            <div class="seeMore" @click="seemore" v-show="setLookMore">
              <!--              查看更多-->
              <p>{{ $t("PersonalEndLanguage.Viewmore") }}</p>
            </div>
          </div>
          <div v-else class="onData">
            <!--            <p>暂无数据</p>-->
            <p>{{ $t("PersonalEndLanguage.NoData") }}</p>
          </div>
        </div>
      </div>
    </div>
    <DialogComVue
      :dialogVisible="dialogVisible"
      @determineClick="determineClick"
      @changeDialogVisible="changeDialogVisible"
      :width="'888px'"
      :top="'10%'"
      :title="dialogtitle"
      v-if="dialogVisible"
      :show-close="showClose"
      :footerbtn-name="footerbtnName"
      :buttonLoading="buttonLoading"
    >
      <!--      硬字幕识别-->
      <el-dialog
        width="888px"
        :visible.sync="innerVisible"
        @close="CloseinnerVisible(1)"
        custom-class="innerDialogClass"
        append-to-body
        top="5%"
        center
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :destroy-on-close="true"
        v-if="innerVisible"
      >
        <div slot="title" class="dialogTitle_div overflowEllipsis">
          {{ dialogtitle }}
        </div>
        <div class="innerDialog_div">
          <div class="innerDialog_div_top_p">
            <p>
              {{ $t("PersonalEndLanguage.warningmessage35") }}
              <el-popover
                placement="bottom-end"
                trigger="hover"
                :visible-arrow="false"
                popper-class="innerDialogPopover"
              >
                <div class="innerDialogPopover_div">
                  <div>
                    <p>
                      <!--                      错误示例-->
                      <svg-icon icon-class="icon_No" class="cuoWu"></svg-icon
                      >{{ $t("PersonalEndLanguage.ViewExamples1") }}
                    </p>
                    <el-image
                      :src="require('@/assets/imgs/cuoWu_shiLi.png')"
                      fit="fill"
                    ></el-image>
                    <p class="btmp" style="word-break: break-word">
                      {{ $t("PersonalEndLanguage.ViewExamples3") }}
                    </p>
                  </div>
                  <div>
                    <p>
                      <svg-icon icon-class="Yes" class="zhengQue"></svg-icon>
                      {{ $t("PersonalEndLanguage.ViewExamples2") }}
                    </p>
                    <el-image
                      :src="require('@/assets/imgs/zhengQue_shiLi.png')"
                      fit="fill"
                    ></el-image>
                    <p class="btmp" style="word-break: break-word">
                      {{ $t("PersonalEndLanguage.ViewExamples4") }}
                    </p>
                  </div>
                </div>
                <el-button slot="reference" type="text">{{
                  $t("PersonalEndLanguage.ViewExamples")
                }}</el-button>
              </el-popover>
            </p>
            <div v-if="HardSubtitleRecognitionTab == 'second'">
              <!--              开启遮罩-->
              <el-button
                type="primary"
                class="setpadding16"
                @click="OpenCanvas"
                v-if="showOpen"
                >{{ $t("PersonalEndLanguage.ViewExamples5") }}</el-button
              >
              <!--              关闭遮罩-->
              <el-button
                type="primary"
                class="setpadding16"
                @click="closeCanvas"
                v-else
                >{{ $t("PersonalEndLanguage.ViewExamples6") }}</el-button
              >
            </div>
          </div>

          <div class="innerDialog_video_div" id="innerDialog_video_div">
            <!-- 视频展示 -->
            <video
              class="innerDialog_video_class"
              id="dialogVideo"
              ref="videoref"
              :src="videoUrl"
              type="video/mp4"
              oncontextmenu="return false"
              v-if="
                HardSubtitleRecognitionTab === 'first' ||
                HardSubtitleRecognitionTab === 'third' ||
                HardSubtitleRecognitionTab === 'six'
              "
            >
              <!-- <source /> -->
            </video>
            <youtube
              v-else
              :video-id="youtubeId"
              id="dialogVideo"
              ref="youtubePlayer"
              :player-vars="playerVars"
            ></youtube>
            <canvas
              id="canvas"
              :width="1"
              :height="
                HardSubtitleRecognitionTab === 'first' ||
                HardSubtitleRecognitionTab === 'six' ||
                HardSubtitleRecognitionTab === 'third'
                  ? 360
                  : this.youtubeHeight_new === 0
                  ? 360
                  : this.youtubeHeight_new
              "
            ></canvas>
          </div>
          <div class="sliderClass">
            <el-slider
              v-model="slidervalue"
              :show-tooltip="true"
              :step="step"
              @input="sliderInput"
              :max="silderMax"
              :marks="isShowTime ? marks : {}"
              :disabled="
                HardSubtitleRecognitionTab !== 'first' &&
                HardSubtitleRecognitionTab !== 'third' &&
                HardSubtitleRecognitionTab !== 'six' &&
                sliderChange
              "
              :format-tooltip="formatTooltip"
            ></el-slider>
          </div>
          <div class="time_div" v-if="isShowTime">
            <div class="start_time_div">
              <div>
                <!--                片头时间点-->
                <span>{{ $t("PersonalEndLanguage.openingTime") }}</span>
                <el-input
                  v-model="startTime"
                  placeholder="00:00:00.00"
                  clearable
                  @clear="clearInput(0)"
                  @blur="inputBlur(0)"
                >
                </el-input>
              </div>
              <div
                class="icon_div"
                @click="
                  TimeIconClick(
                    0,
                    HardSubtitleRecognitionTab === 'first' ||
                      HardSubtitleRecognitionTab === 'third'
                      ? videoDom.currentTime
                      : -1
                  )
                "
              >
                <i class="el-icon-location icon"></i>
              </div>
            </div>
            <div class="end_time_div">
              <div>
                <!--                片尾时间点-->
                <span>{{ $t("PersonalEndLanguage.endingTime") }}</span>
                <el-input
                  v-model="endTime"
                  placeholder="00:00:00.00"
                  clearable
                  @clear="clearInput(1)"
                  @blur="inputBlur(1)"
                >
                </el-input>
              </div>
              <div
                class="icon_div"
                @click="
                  TimeIconClick(
                    1,
                    HardSubtitleRecognitionTab == 'first' ||
                      HardSubtitleRecognitionTab == 'third'
                      ? videoDom.currentTime
                      : -1
                  )
                "
              >
                <i class="el-icon-location icon1"></i>
              </div>
            </div>
          </div>
          <p class="tips_p" v-if="isShowTime">
            {{ $t("PersonalEndLanguage.ViewExamples7") }}
          </p>
        </div>
        <div slot="footer" class="dialog-footer" style="margin-top: 24px">
          <el-button
            type="info"
            @click="CloseinnerVisible(2)"
            class="setpadding23"
            >{{
              hardSubtitleRecognition_fileList.length > 1
                ? row_id == 0
                  ? $t("CommonName.Previous")
                  : $t("CommonName.Prev")
                : $t("CommonName.Previous")
            }}</el-button
          >
          <el-button
            type="primary"
            @click="SureClick"
            class="setpadding23"
            :loading="buttonLoading"
            >{{
              hardSubtitleRecognition_fileList.length > 1
                ? this.row_id + 1 ===
                  this.hardSubtitleRecognition_fileList.length
                  ? $t("CommonName.OK")
                  : $t("CommonName.Nexts")
                : $t("CommonName.OK")
            }}</el-button
          >
          <el-button
            type="primary"
            @click="immediateClick"
            class="setpadding23"
            :loading="buttonLoading"
            v-show="hardSubtitleRecognition_fileList.length > 1"
            >{{ $t("CommonName.immediateClick") }}</el-button
          >
        </div>
      </el-dialog>
      <div class="dialogcondiv">
        <div :class="setLeftClass">
          <!--        左侧 上传-->
          <!--        视频转写  -->
          <div class="left" v-if="[100].includes(project_id)">
            <el-upload
              class="upload-demo upload-demo11"
              drag
              ref="upload"
              :with-credentials="true"
              action="#"
              :name="uploadName"
              :data="uploadData"
              :before-upload="beforeUpload"
              :on-change="uploadChange"
              :on-progress="uploadProgress"
              :on-success="uploadSuccess"
              :on-error="uploadError"
              :auto-upload="false"
              :show-file-list="false"
              :limit="1"
            >
              <div
                class="uploaddiv"
                slot="trigger"
                @click="AudiotranscriptionClick"
              >
                <div v-if="videoData.name" class="videoDatanamediv">
                  <div class="svgdivs">
                    <svg-icon
                      :icon-class="videoData.icon"
                      class="icon"
                    ></svg-icon>
                  </div>
                  <p class="namep">{{ videoData.name }}</p>
                  <p class="topp">{{ $t("PersonalEndLanguage.Reupload") }}</p>
                </div>
                <div v-else>
                  <i class="el-icon-plus"></i>
                  <p class="topp">{{ $t("PersonalEndLanguage.DragVideo") }}</p>
                  <p class="botp">
                    {{
                      $t("PersonalEndLanguage.SupportsMP4formatvideo") + " ≤"
                    }}
                    {{ user_info.video_filesize_limit }}GB，{{
                      $t("PersonalEndLanguage.duration")
                    }}
                    ≤ <span>{{ user_info.video_duration_limit }}</span
                    >{{ $t("PersonalEndLanguage.minute") }}
                  </p>
                </div>
              </div>
            </el-upload>
            <div style="display: flex; justify-content: space-between">
              <!--              硬字幕涂抹-->
              <el-checkbox
                v-model="form.checkbox1"
                :disabled="form.checkboxDisabled"
                >{{ $t("PersonalEndLanguage.VideoDubbingText20") }}
                <el-tooltip placement="top-start" popper-class="uploadptooltip">
                  <div slot="content">
                    <div>
                      {{
                        $t("PersonalEndLanguage.warningmessage41") +
                        user_info.video_daub_minute_limit +
                        $t("PersonalEndLanguage.warningmessage50")
                      }}
                    </div>
                  </div>
                  <svg-icon
                    icon-class="presentation"
                    class="icon"
                    style="cursor: pointer"
                  ></svg-icon> </el-tooltip
              ></el-checkbox>
              <!--              硬字幕压制-->
              <el-checkbox
                v-model="form.checkbox2"
                :disabled="form.checkboxDisabled"
                >{{ $t("PersonalEndLanguage.VideoDubbingText21") }}
                <el-tooltip placement="top-start" popper-class="uploadptooltip">
                  <div slot="content">
                    <div>
                      {{
                        $t("PersonalEndLanguage.warningmessage41") +
                        user_info.video_daub_minute_limit +
                        $t("PersonalEndLanguage.warningmessage50")
                      }}
                    </div>
                  </div>
                  <svg-icon
                    icon-class="presentation"
                    class="icon"
                    style="cursor: pointer"
                  ></svg-icon> </el-tooltip
              ></el-checkbox>
            </div>
          </div>
          <!--  音频转写/字幕对齐-->
          <div class="left" v-if="[4, 8].includes(project_id)">
            <el-upload
              class="upload-demo upload-demo11"
              drag
              ref="upload"
              :with-credentials="true"
              :action="'#'"
              :name="uploadName"
              :data="uploadData"
              :before-upload="beforeUpload"
              :on-change="uploadChange"
              :on-progress="uploadProgress"
              :on-success="uploadSuccess"
              :on-error="uploadError"
              :auto-upload="false"
              :show-file-list="false"
              :limit="1"
            >
              <!--              音频转写-->
              <div
                class="uploaddiv"
                v-if="
                  dialogtitle == $t('PersonalEndLanguage.Audiotranscription')
                "
                slot="trigger"
                @click="AudiotranscriptionClick"
              >
                <div v-if="videoData.name" class="videoDatanamediv">
                  <div class="svgdivs">
                    <svg-icon
                      :icon-class="videoData.icon"
                      class="icon"
                    ></svg-icon>
                  </div>
                  <p class="namep">{{ videoData.name }}</p>
                  <p class="topp">{{ $t("PersonalEndLanguage.Reupload") }}</p>
                </div>
                <div v-else>
                  <i class="el-icon-plus"></i>
                  <p class="topp">{{ $t("PersonalEndLanguage.DragVideo2") }}</p>
                  <p class="botp">
                    {{
                      $t("PersonalEndLanguage.fileText") +
                      user_info.audio_duration_limit +
                      $t("PersonalEndLanguage.minute")
                    }}
                  </p>
                </div>
              </div>
              <!--              漫画快翻上传-->
              <div
                class="uploaddiv"
                v-else-if="
                  dialogtitle == $t('PersonalEndLanguage.ComicQuickFlip')
                "
                slot="trigger"
                @click="AudiotranscriptionClick"
              >
                <div v-if="videoData.name" class="videoDatanamediv">
                  <div class="svgdivs">
                    <svg-icon
                      :icon-class="videoData.icon"
                      class="icon"
                    ></svg-icon>
                  </div>
                  <p class="namep">{{ videoData.name }}</p>
                  <p class="topp">{{ $t("PersonalEndLanguage.Reupload") }}</p>
                </div>
                <div v-else>
                  <i class="el-icon-plus"></i>
                  <p class="topp">{{ $t("PersonalEndLanguage.DragVideo1") }}</p>
                  <p class="botp">{{ $t("PersonalEndLanguage.fileText1") }}</p>
                  <p class="botp" style="white-space: pre-line">
                    {{
                      $t("PersonalEndLanguage.fileText2") +
                      user_info.comic_filesize_limit
                    }}M
                  </p>
                </div>
              </div>
              <div
                class="uploaddiv"
                v-else
                slot="trigger"
                @click="AudiotranscriptionClick"
              >
                <div v-if="videoData.name" class="videoDatanamediv">
                  <div class="svgdivs">
                    <svg-icon
                      :icon-class="videoData.icon"
                      class="icon"
                    ></svg-icon>
                  </div>
                  <p class="namep">{{ videoData.name }}</p>
                  <p class="topp">{{ $t("PersonalEndLanguage.Reupload") }}</p>
                </div>
                <div v-else>
                  <i class="el-icon-plus"></i>
                  <p class="topp">{{ $t("PersonalEndLanguage.DragVideo") }}</p>
                  <p class="botp">
                    {{
                      $t("PersonalEndLanguage.SupportsMP4formatvideo") + " ≤"
                    }}
                    {{ user_info.video_filesize_limit }}GB，{{
                      $t("PersonalEndLanguage.duration")
                    }}
                    ≤ <span>{{ user_info.video_duration_limit }}</span
                    >{{ $t("PersonalEndLanguage.minute") }}
                  </p>
                </div>
              </div>
            </el-upload>
          </div>

          <!--        右侧 功能区-->
          <!--          视频转写-->
          <div
            class="right VideoDubbing"
            style="margin-left: 48px"
            v-if="false"
          >
            <div class="radio_tag_div_first_class">
              <div class="uploadsrt uploadsrt1">
                <!--              视频语言-->
                <p>{{ $t("PersonalEndLanguage.Transcriptionmethod3") }}</p>
                <el-select
                  v-model="form.videoLanguage"
                  filterable
                  :placeholder="$t('PersonalEndLanguage.warningmessage1')"
                  class="selectclass"
                >
                  <el-option
                    v-for="item in form.radio2 === '2'
                      ? setLanguage
                      : languageoptions"
                    :key="item.language_id"
                    :label="item.cname"
                    :value="item.language_id"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="uploadsrt radio2_div1">
                <el-tabs v-model="form.activeName" type="border-card">
                  <!--                        有源语言 SRT 文件-->
                  <el-tab-pane
                    name="1"
                    :label="$t('PersonalEndLanguage.VideoDubbingText5')"
                  >
                    <div class="uploadsrt uploadsrt2">
                      <!-- 上传字幕文件 -->
                      <el-input
                        v-model="form.srtinput"
                        :placeholder="
                          $t('PersonalEndLanguage.uploadFile') +
                          $t('PersonalEndLanguage.uploadFile1')
                        "
                        class="input-with-select srtUnput"
                        @input="form.srtinput = ''"
                      >
                        <el-upload
                          slot="append"
                          class="upload-demo"
                          ref="srtupload1"
                          :action="$uploadURL + '/job/upload_srt_file'"
                          name="srt_file"
                          :with-credentials="true"
                          :before-upload="srtbeforeUpload"
                          :show-file-list="false"
                          :on-success="srtuploadSuccess"
                          :auto-upload="true"
                          :limit="1"
                        >
                          <el-button
                            @click="srtupload('srtupload1')"
                            type="text"
                          >
                            <div class="svgbtn">
                              <svg-icon
                                icon-class="upload"
                                class="icon"
                              ></svg-icon>
                              <!--                              上传-->
                              <div>
                                {{ $t("PersonalEndLanguage.upload") }}
                              </div>
                            </div>
                          </el-button>
                        </el-upload>
                      </el-input>
                    </div>
                  </el-tab-pane>
                  <!--                        无源语言 SRT 文件-->
                  <el-tab-pane
                    name="2"
                    :label="$t('PersonalEndLanguage.VideoDubbingText8')"
                  >
                    <div class="uploadsrt radioClass">
                      <el-radio-group
                        v-model="form.radio2"
                        @input="formRadio2Change"
                      >
                        <!--                              语音转写-->
                        <el-radio label="1" border>{{
                          $t("PersonalEndLanguage.VideoDubbingText9")
                        }}</el-radio>
                        <!--                              硬字幕转写-->
                        <el-radio label="2" border>{{
                          $t("PersonalEndLanguage.VideoDubbingText10")
                        }}</el-radio>
                      </el-radio-group>
                    </div>
                  </el-tab-pane>
                </el-tabs>
              </div>
              <div
                :class="
                  form.checked
                    ? 'upload_right_div upload_right_div2'
                    : 'upload_right_div upload_right_div1'
                "
              >
                <!--              需要翻译字幕-->
                <el-checkbox v-model="form.checked">{{
                  $t("PersonalEndLanguage.Transcriptionmethod8")
                }}</el-checkbox>
                <div class="uploadsrt" v-if="form.checked">
                  <el-select
                    v-model="form.targetlanguage"
                    filterable
                    :placeholder="$t('PersonalEndLanguage.warningmessage30')"
                    class="selectclass"
                  >
                    <el-option
                      v-for="item in languageoptions"
                      :key="item.language_id"
                      :label="item.cname"
                      :value="item.language_id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </div>

          <!--          音频转写-->
          <div
            class="right"
            v-if="dialogtitle === $t('PersonalEndLanguage.Audiotranscription')"
          >
            <!--            音频语言-->
            <div class="uploadsrt uploadsrt1">
              <p>{{ $t("PersonalEndLanguage.Transcriptionmethod4") }}</p>
              <el-select
                v-model="form4.videoLanguage"
                filterable
                :placeholder="$t('PersonalEndLanguage.warningmessage5')"
                class="selectclass"
              >
                <el-option
                  v-for="item in languageoptions"
                  :key="item.language_id"
                  :label="item.cname"
                  :value="item.language_id"
                >
                </el-option>
              </el-select>
            </div>
            <!-- 选择目标文件夹 -->
            <div class="uploadsrt">
              <div class="AssignFolder">
                <p>{{ $t("CommonName.targetFolder") }}</p>
                <el-checkbox v-model="isAddFolder">{{
                  $t("PersonalEndLanguage.AssignText2")
                }}</el-checkbox>
              </div>
              <el-select
                v-model="AddFolderID"
                v-show="!isAddFolder"
                filterable
                clearable
                :placeholder="$t('CommonName.Pleaseselect')"
                class="selectclass"
              >
                <el-option
                  v-for="item in userFolderList"
                  :key="item.folder_id"
                  :label="item.folder_name"
                  :value="item.folder_id"
                >
                </el-option>
              </el-select>
              <el-input
                class="selectclass"
                v-model="AddFolderName"
                :maxlength="60"
                v-show="isAddFolder"
              ></el-input>
            </div>
          </div>

          <!--          字幕对齐-->
          <div
            class="right"
            v-if="dialogtitle == $t('PersonalEndLanguage.Subtitlealignment')"
          >
            <div class="uploadsrt uploadsrt1">
              <p>{{ $t("MyFiles.sourceLanguage") }}</p>
              <el-select
                v-model="form6.videoLanguage"
                filterable
                :placeholder="$t('PersonalEndLanguage.warningmessage31')"
                class="selectclass"
              >
                <el-option
                  v-for="item in setLanguage1"
                  :key="item.language_id"
                  :label="item.cname"
                  :value="item.language_id"
                >
                </el-option>
              </el-select>
            </div>
            <div class="uploadsrt">
              <p class="titlep">
                {{ $t("PersonalEndLanguage.warningmessage32") }}
                <el-tooltip placement="top-start" popper-class="uploadptooltip">
                  <div slot="content">
                    <div>{{ $t("PersonalEndLanguage.warningmessage38") }}</div>
                  </div>
                  <svg-icon
                    icon-class="presentation"
                    class="icon"
                    style="cursor: pointer"
                  ></svg-icon>
                </el-tooltip>
              </p>
              <!-- 上传对齐字幕文本 -->
              <el-input
                v-model="form6.srtinput"
                class="input-with-select srtUnput"
                @input="form6.srtinput = ''"
                :placeholder="$t('PersonalEndLanguage.warningmessage33')"
              >
                <el-upload
                  slot="append"
                  class="upload-demo"
                  ref="srtupload"
                  :action="$uploadURL + '/job/upload_srt_file'"
                  name="srt_file"
                  :with-credentials="true"
                  :before-upload="srtbeforeUpload"
                  :show-file-list="false"
                  :on-success="srtuploadSuccess"
                  :auto-upload="true"
                  :limit="1"
                >
                  <el-button @click="srtupload('srtupload')" type="text">
                    <div class="svgbtn">
                      <svg-icon icon-class="upload" class="icon"></svg-icon>
                      <div>{{ $t("PersonalEndLanguage.upload") }}</div>
                    </div>
                  </el-button>
                </el-upload>
              </el-input>
            </div>
            <!-- 选择目标文件夹 -->
            <div class="uploadsrt">
              <div class="AssignFolder">
                <p>{{ $t("CommonName.targetFolder") }}</p>
                <el-checkbox v-model="isAddFolder">{{
                  $t("PersonalEndLanguage.AssignText2")
                }}</el-checkbox>
              </div>
              <el-select
                v-model="AddFolderID"
                v-show="!isAddFolder"
                clearable
                filterable
                :placeholder="$t('CommonName.Pleaseselect')"
                class="selectclass"
              >
                <el-option
                  v-for="item in userFolderList"
                  :key="item.folder_id"
                  :label="item.folder_name"
                  :value="item.folder_id"
                >
                </el-option>
              </el-select>
              <el-input
                class="selectclass"
                v-model="AddFolderName"
                :maxlength="60"
                v-show="isAddFolder"
              ></el-input>
            </div>
          </div>
          <!--         漫画快翻-->
          <div
            class="right"
            v-if="dialogtitle == $t('PersonalEndLanguage.ComicQuickFlip')"
          >
            <div class="uploadsrt uploadsrt1">
              <p>{{ $t("MyFiles.sourceLanguage") }}</p>
              <el-select
                v-model="form7.sourcelanguage"
                filterable
                :placeholder="$t('PersonalEndLanguage.warningmessage34')"
                class="selectclass"
              >
                <el-option
                  v-for="item in languageoptions"
                  :key="item.language_id"
                  :label="item.cname"
                  :value="item.language_id"
                >
                </el-option>
              </el-select>
            </div>
            <div class="uploadsrt">
              <p>{{ $t("MyFiles.targetLanguage") }}</p>
              <el-select
                v-model="form7.targetlanguage"
                filterable
                :placeholder="$t('PersonalEndLanguage.warningmessage30')"
                class="selectclass"
              >
                <el-option
                  v-for="item in languageoptions"
                  :key="item.language_id"
                  :label="item.cname"
                  :value="item.language_id"
                >
                </el-option>
              </el-select>
            </div>
            <div class="radioClass">
              <p>{{ $t("PersonalEndLanguage.Transcriptionmethod5") }}</p>
              <el-radio-group v-model="form7.textdirection">
                <el-radio label="0" border>{{
                  $t("PersonalEndLanguage.Transcriptionmethod6")
                }}</el-radio>
                <el-radio label="1" border>{{
                  $t("PersonalEndLanguage.Transcriptionmethod7")
                }}</el-radio>
              </el-radio-group>
            </div>
          </div>
          <!--    视频转写 自带左右 -->
          <div class="VideoDubbing" v-if="[1].includes(project_id)">
            <el-tabs v-model="HardSubtitleRecognitionTab">
              <!--             上传视频-->
              <el-tab-pane
                :label="$t('PersonalEndLanguage.uploadvideo')"
                name="first"
              >
                <div
                  class="tag_div radio_tag_div_first_class"
                  v-if="HardSubtitleRecognitionTab === 'first'"
                >
                  <div class="tag_left">
                    <el-upload
                      class="upload-demo upload-demo11"
                      drag
                      ref="upload"
                      :with-credentials="true"
                      action="#"
                      :name="uploadName"
                      :data="uploadData"
                      :before-upload="beforeUpload"
                      :on-change="uploadChange"
                      :on-progress="uploadProgress"
                      :on-success="uploadSuccess"
                      :on-error="uploadError"
                      :auto-upload="false"
                      :show-file-list="false"
                      :limit="1"
                    >
                      <div
                        class="uploaddiv"
                        slot="trigger"
                        @click="AudiotranscriptionClick"
                      >
                        <div v-if="videoData.name" class="videoDatanamediv">
                          <div class="svgdivs">
                            <svg-icon
                              :icon-class="videoData.icon"
                              class="icon"
                            ></svg-icon>
                          </div>
                          <p class="namep">{{ videoData.name }}</p>
                          <p class="topp">
                            {{ $t("PersonalEndLanguage.Reupload") }}
                          </p>
                        </div>
                        <div v-else>
                          <i class="el-icon-plus"></i>
                          <p class="topp">
                            {{ $t("PersonalEndLanguage.DragVideo") }}
                          </p>
                          <p class="botp">
                            {{
                              $t("PersonalEndLanguage.SupportsMP4formatvideo") +
                              " ≤"
                            }}
                            {{ user_info.video_filesize_limit }}GB，{{
                              $t("PersonalEndLanguage.duration")
                            }}
                            ≤ <span>{{ user_info.video_duration_limit }}</span
                            >{{ $t("PersonalEndLanguage.minute") }}
                          </p>
                        </div>
                      </div>
                    </el-upload>
                    <div style="display: flex; justify-content: space-between">
                      <!--              硬字幕涂抹-->
                      <el-checkbox
                        v-model="form.checkbox1"
                        @change="checkboxChange(0, 1)"
                        :disabled="form.checkboxDisabled"
                        >{{ $t("PersonalEndLanguage.VideoDubbingText20") }}
                        <el-tooltip
                          placement="top-start"
                          popper-class="uploadptooltip"
                        >
                          <div slot="content">
                            <div>
                              {{
                                $t("PersonalEndLanguage.warningmessage41") +
                                user_info.video_daub_minute_limit +
                                $t("PersonalEndLanguage.warningmessage50")
                              }}
                            </div>
                          </div>
                          <svg-icon
                            icon-class="presentation"
                            class="icon"
                            style="cursor: pointer"
                          ></svg-icon> </el-tooltip
                      ></el-checkbox>
                      <!--              硬字幕压制-->
                      <el-checkbox
                        v-model="form.checkbox2"
                        :disabled="form.checkboxDisabled"
                        >{{ $t("PersonalEndLanguage.VideoDubbingText21") }}
                        <el-tooltip
                          placement="top-start"
                          popper-class="uploadptooltip"
                        >
                          <div slot="content">
                            <div>
                              {{
                                $t("PersonalEndLanguage.warningmessage41") +
                                user_info.video_daub_minute_limit +
                                $t("PersonalEndLanguage.warningmessage50")
                              }}
                            </div>
                          </div>
                          <svg-icon
                            icon-class="presentation"
                            class="icon"
                            style="cursor: pointer"
                          ></svg-icon> </el-tooltip
                      ></el-checkbox>
                    </div>
                    <div style="display: flex; justify-content: space-between">
                      <!--              硬字幕涂抹 高级-->
                      <el-checkbox
                        v-model="form.checkbox3"
                        :disabled="form.checkboxDisabled"
                        v-if="form.checkbox1 && user_info.video_daub_mode == 1"
                        >{{ $t("PersonalEndLanguage.VideoDubbingText24") }}
                        <el-popover
                          placement="top"
                          trigger="hover"
                          :visible-arrow="false"
                        >
                          <div>
                            <div
                              style="color: #fff; white-space: pre-wrap"
                              v-html="
                                $t('PersonalEndLanguage.VideoDubbingText25')
                              "
                            ></div>
                            <div class="innerDialogPopover_div1">
                              <div>
                                <el-image
                                  :src="require('@/assets/imgs/tumo.jpg')"
                                  fit="fill"
                                  initial-index="0"
                                  :preview-src-list="srcList"
                                ></el-image>
                                <p class="btmp" style="word-break: break-word">
                                  {{ $t("PersonalEndLanguage.ViewExamples8") }}
                                </p>
                              </div>
                              <div>
                                <el-image
                                  :src="require('@/assets/imgs/tumo_1.jpg')"
                                  fit="fill"
                                  initial-index="1"
                                  :preview-src-list="srcList"
                                ></el-image>
                                <p class="btmp" style="word-break: break-word">
                                  {{ $t("PersonalEndLanguage.ViewExamples9") }}
                                </p>
                              </div>
                              <div>
                                <el-image
                                  :src="require('@/assets/imgs/tumo_2.jpg')"
                                  fit="fill"
                                  initial-index="2"
                                  :preview-src-list="srcList"
                                ></el-image>
                                <p class="btmp" style="word-break: break-word">
                                  {{ $t("PersonalEndLanguage.ViewExamples10") }}
                                </p>
                              </div>
                            </div>
                          </div>

                          <svg-icon
                            slot="reference"
                            icon-class="presentation"
                            class="icon"
                            style="cursor: pointer"
                          ></svg-icon>
                        </el-popover>
                      </el-checkbox>
                    </div>
                  </div>
                  <div class="right" style="margin-left: 48px">
                    <div class="radio_tag_div_first_class">
                      <div class="uploadsrt uploadsrt1">
                        <!--              视频语言-->
                        <p>
                          {{ $t("PersonalEndLanguage.Transcriptionmethod3") }}
                        </p>
                        <el-select
                          v-model="form.videoLanguage"
                          filterable
                          :placeholder="
                            $t('PersonalEndLanguage.warningmessage1')
                          "
                          class="selectclass"
                        >
                          <el-option
                            v-for="item in form.radio2 === '2'
                              ? setLanguage
                              : languageoptions"
                            :key="item.language_id"
                            :label="item.cname"
                            :value="item.language_id"
                          >
                          </el-option>
                        </el-select>
                      </div>
                      <div class="uploadsrt radio2_div1">
                        <el-tabs v-model="form.activeName" type="border-card">
                          <!--                        有源语言 SRT 文件-->
                          <el-tab-pane
                            name="1"
                            :label="$t('PersonalEndLanguage.VideoDubbingText5')"
                          >
                            <div class="uploadsrt uploadsrt2">
                              <!-- 上传字幕文件 -->
                              <el-input
                                v-model="form.srtinput"
                                :placeholder="
                                  $t('PersonalEndLanguage.uploadFile') +
                                  $t('PersonalEndLanguage.uploadFile1')
                                "
                                class="input-with-select srtUnput"
                                @input="form.srtinput = ''"
                              >
                                <el-upload
                                  slot="append"
                                  class="upload-demo"
                                  ref="srtupload1"
                                  :action="$uploadURL + '/job/upload_srt_file'"
                                  name="srt_file"
                                  :with-credentials="true"
                                  :before-upload="srtbeforeUpload"
                                  :show-file-list="false"
                                  :on-success="srtuploadSuccess"
                                  :auto-upload="true"
                                  :limit="1"
                                >
                                  <el-button
                                    @click="srtupload('srtupload1')"
                                    type="text"
                                  >
                                    <div class="svgbtn">
                                      <svg-icon
                                        icon-class="upload"
                                        class="icon"
                                      ></svg-icon>
                                      <!--                              上传-->
                                      <div>
                                        {{ $t("PersonalEndLanguage.upload") }}
                                      </div>
                                    </div>
                                  </el-button>
                                </el-upload>
                              </el-input>
                            </div>
                          </el-tab-pane>
                          <!--                        无源语言 SRT 文件-->
                          <el-tab-pane
                            name="2"
                            :label="$t('PersonalEndLanguage.VideoDubbingText8')"
                          >
                            <div class="uploadsrt radioClass">
                              <el-radio-group
                                v-model="form.radio2"
                                @input="formRadio2Change"
                              >
                                <!--                              语音转写-->
                                <el-radio label="1" border>{{
                                  $t("PersonalEndLanguage.VideoDubbingText9")
                                }}</el-radio>
                                <!--                              硬字幕转写-->
                                <el-radio label="2" border>{{
                                  $t("PersonalEndLanguage.VideoDubbingText10")
                                }}</el-radio>
                              </el-radio-group>
                            </div>
                          </el-tab-pane>
                        </el-tabs>
                      </div>
                      <div
                        :class="
                          form.checked
                            ? 'upload_right_div upload_right_div2'
                            : 'upload_right_div upload_right_div1'
                        "
                      >
                        <!--              需要翻译字幕-->
                        <el-checkbox v-model="form.checked">{{
                          $t("PersonalEndLanguage.Transcriptionmethod8")
                        }}</el-checkbox>
                        <div class="uploadsrt" v-if="form.checked">
                          <el-select
                            v-model="form.targetlanguage"
                            filterable
                            :placeholder="
                              $t('PersonalEndLanguage.warningmessage30')
                            "
                            class="selectclass"
                          >
                            <el-option
                              v-for="item in languageoptions"
                              :key="item.language_id"
                              :label="item.cname"
                              :value="item.language_id"
                            >
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                      <!-- 选择目标文件夹 -->
                      <div class="uploadsrt">
                        <div class="AssignFolder">
                          <p>{{ $t("CommonName.targetFolder") }}</p>
                          <el-checkbox v-model="isAddFolder">{{
                            $t("PersonalEndLanguage.AssignText2")
                          }}</el-checkbox>
                        </div>
                        <el-select
                          v-model="AddFolderID"
                          clearable
                          v-show="!isAddFolder"
                          filterable
                          :placeholder="$t('CommonName.Pleaseselect')"
                          class="selectclass"
                        >
                          <el-option
                            v-for="item in userFolderList"
                            :key="item.folder_id"
                            :label="item.folder_name"
                            :value="item.folder_id"
                          >
                          </el-option>
                        </el-select>
                        <el-input
                          class="selectclass"
                          v-model="AddFolderName"
                          :maxlength="60"
                          v-show="isAddFolder"
                        ></el-input>
                      </div>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              <!--           批量上传   -->
              <el-tab-pane
                :label="$t('PersonalEndLanguage.batchupload')"
                name="third"
              >
                <div
                  class="tag_div radio_tag_div_first_class"
                  v-if="HardSubtitleRecognitionTab === 'third'"
                >
                  <div class="tag_left">
                    <el-upload
                      class="upload-demo"
                      drag
                      ref="upload"
                      :with-credentials="true"
                      :action="actionURL"
                      :name="uploadName"
                      :data="uploadData"
                      :before-upload="beforeUpload"
                      :on-change="videoAI_change_third"
                      :on-exceed="hardSubtitleRecognition_Exceed_third"
                      :on-progress="uploadProgress"
                      :on-success="uploadSuccess"
                      :on-error="uploadError"
                      :auto-upload="false"
                      :show-file-list="false"
                      :limit="10"
                      :file-list="hardSubtitleRecognition_fileList"
                      multiple
                    >
                      <div
                        class="uploaddiv"
                        slot="trigger"
                        @click="AudiotranscriptionClick_third(3)"
                        @mouseenter="AudiotranscriptionClick_third_enter"
                      >
                        <div
                          v-if="hardSubtitleRecognition_fileList.length > 0"
                          class="filelist_div"
                        >
                          <div
                            class="upload_table_div"
                            @click="
                              (e) => {
                                return e.stopPropagation();
                              }
                            "
                          >
                            <div class="videoDatanamediv_body_list1">
                              <div
                                class="videoDatanamediv_body"
                                v-for="item in hardSubtitleRecognition_fileList"
                                :key="item.id"
                              >
                                <div class="left_div left_div1">
                                  <div class="svgdivs">
                                    <svg-icon
                                      icon-class="video"
                                      class="icon"
                                    ></svg-icon>
                                  </div>
                                  <div
                                    class="body_p"
                                    :id="'tag' + item.id"
                                    @mouseover="onShowNameTipsMouseenter(item)"
                                  >
                                    <el-tooltip
                                      class="item"
                                      effect="dark"
                                      :content="item.name"
                                      placement="top-start"
                                      :disabled="!item.showdropdown"
                                      :visible-arrow="false"
                                    >
                                      <span>{{ item.name }}</span>
                                    </el-tooltip>
                                  </div>
                                </div>
                                <div class="last_delete last_delete1">
                                  <el-button
                                    type="text"
                                    @click="videoAI_Delete_Click($event, item)"
                                  >
                                    <svg-icon
                                      icon-class="delete"
                                      class="icon"
                                    ></svg-icon>
                                  </el-button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="p_div">
                            <i class="el-icon-plus"></i>
                            <p style="color: #2f9efd">
                              <!--                            点击选择或拖动视频到这里-->
                              {{ $t("PersonalEndLanguage.DragVideo") }}
                            </p>
                          </div>
                          <!--                        <div class="svgdivs">-->
                          <!--                          <svg-icon-->
                          <!--                            :icon-class="videoData.icon"-->
                          <!--                            class="icon"-->
                          <!--                          ></svg-icon>-->
                          <!--                        </div>-->
                          <!--                        <p class="namep">{{ videoData.name }}</p>-->
                          <!--                        <p class="topp">重新上传</p>-->
                        </div>
                        <div v-else>
                          <i class="el-icon-plus"></i>
                          <p class="topp">
                            {{ $t("PersonalEndLanguage.DragVideo") }}
                          </p>
                          <p class="botp">
                            {{
                              $t("PersonalEndLanguage.SupportsMP4formatvideo") +
                              " ≤"
                            }}
                            {{ user_info.video_filesize_limit }}GB，{{
                              $t("PersonalEndLanguage.duration")
                            }}
                            ≤ <span>{{ user_info.video_duration_limit }}</span>
                            {{
                              $t("PersonalEndLanguage.minute") +
                              $t("PersonalEndLanguage.moreLangth")
                            }}
                          </p>
                        </div>
                      </div>
                    </el-upload>
                    <div style="display: flex; justify-content: space-between">
                      <!--              硬字幕涂抹-->
                      <el-checkbox
                        v-model="form1.checkbox1"
                        :disabled="form1.checkboxDisabled"
                        >{{ $t("PersonalEndLanguage.VideoDubbingText20") }}
                        <el-tooltip
                          placement="top-start"
                          popper-class="uploadptooltip"
                        >
                          <div slot="content">
                            <div>
                              {{
                                $t("PersonalEndLanguage.warningmessage41") +
                                user_info.video_daub_minute_limit +
                                $t("PersonalEndLanguage.warningmessage50")
                              }}
                            </div>
                          </div>
                          <svg-icon
                            icon-class="presentation"
                            class="icon"
                            style="cursor: pointer"
                          ></svg-icon> </el-tooltip
                      ></el-checkbox>
                      <!--              硬字幕压制-->
                      <el-checkbox
                        v-model="form1.checkbox2"
                        :disabled="form1.checkboxDisabled"
                        >{{ $t("PersonalEndLanguage.VideoDubbingText21") }}
                        <el-tooltip
                          placement="top-start"
                          popper-class="uploadptooltip"
                        >
                          <div slot="content">
                            <div>
                              {{
                                $t("PersonalEndLanguage.warningmessage41") +
                                user_info.video_daub_minute_limit +
                                $t("PersonalEndLanguage.warningmessage50")
                              }}
                            </div>
                          </div>
                          <svg-icon
                            icon-class="presentation"
                            class="icon"
                            style="cursor: pointer"
                          ></svg-icon> </el-tooltip
                      ></el-checkbox>
                    </div>
                    <div style="display: flex; justify-content: space-between">
                      <!--              硬字幕涂抹 高级-->
                      <el-checkbox
                        v-model="form1.checkbox3"
                        :disabled="form1.checkboxDisabled"
                        v-if="form1.checkbox1 && user_info.video_daub_mode == 1"
                        >{{ $t("PersonalEndLanguage.VideoDubbingText24") }}
                        <el-popover
                          placement="top"
                          trigger="hover"
                          :visible-arrow="false"
                        >
                          <div>
                            <div
                              style="color: #fff; white-space: pre-wrap"
                              v-html="
                                $t('PersonalEndLanguage.VideoDubbingText25')
                              "
                            ></div>
                            <div class="innerDialogPopover_div1">
                              <div>
                                <el-image
                                  :src="require('@/assets/imgs/tumo.jpg')"
                                  fit="fill"
                                  initial-index="0"
                                  :preview-src-list="srcList"
                                ></el-image>
                                <p class="btmp" style="word-break: break-word">
                                  {{ $t("PersonalEndLanguage.ViewExamples8") }}
                                </p>
                              </div>
                              <div>
                                <el-image
                                  :src="require('@/assets/imgs/tumo_1.jpg')"
                                  fit="fill"
                                  initial-index="1"
                                  :preview-src-list="srcList"
                                ></el-image>
                                <p class="btmp" style="word-break: break-word">
                                  {{ $t("PersonalEndLanguage.ViewExamples9") }}
                                </p>
                              </div>
                              <div>
                                <el-image
                                  :src="require('@/assets/imgs/tumo_2.jpg')"
                                  fit="fill"
                                  initial-index="2"
                                  :preview-src-list="srcList"
                                ></el-image>
                                <p class="btmp" style="word-break: break-word">
                                  {{ $t("PersonalEndLanguage.ViewExamples10") }}
                                </p>
                              </div>
                            </div>
                          </div>

                          <svg-icon
                            slot="reference"
                            icon-class="presentation"
                            class="icon"
                            style="cursor: pointer"
                          ></svg-icon>
                        </el-popover>
                      </el-checkbox>
                    </div>
                  </div>
                  <div class="right" style="margin-left: 48px">
                    <div class="radio_tag_div_first_class">
                      <div class="uploadsrt uploadsrt1">
                        <!--              视频语言-->
                        <p>
                          {{ $t("PersonalEndLanguage.Transcriptionmethod3") }}
                        </p>
                        <el-select
                          v-model="form1.videoLanguage"
                          filterable
                          :placeholder="
                            $t('PersonalEndLanguage.warningmessage1')
                          "
                          class="selectclass"
                        >
                          <el-option
                            v-for="item in form1.radio2 === '2'
                              ? setLanguage
                              : languageoptions"
                            :key="item.language_id"
                            :label="item.cname"
                            :value="item.language_id"
                          >
                          </el-option>
                        </el-select>
                      </div>
                      <div class="uploadsrt radio2_div1" style="height: 33px">
                        <div class="uploadsrt radioClass">
                          <el-radio-group
                            v-model="form1.radio2"
                            @input="form1Radio2Change"
                          >
                            <!--                              语音转写-->
                            <el-radio label="1" border>{{
                              $t("PersonalEndLanguage.VideoDubbingText9")
                            }}</el-radio>
                            <!--                              硬字幕转写-->
                            <el-radio label="2" border>{{
                              $t("PersonalEndLanguage.VideoDubbingText10")
                            }}</el-radio>
                          </el-radio-group>
                        </div>
                      </div>
                      <div
                        :class="
                          form1.checked
                            ? 'upload_right_div upload_right_div2'
                            : 'upload_right_div upload_right_div1'
                        "
                      >
                        <!--              需要翻译字幕-->
                        <el-checkbox v-model="form1.checked">{{
                          $t("PersonalEndLanguage.Transcriptionmethod8")
                        }}</el-checkbox>
                        <div class="uploadsrt" v-if="form1.checked">
                          <el-select
                            v-model="form1.targetlanguage"
                            filterable
                            :placeholder="
                              $t('PersonalEndLanguage.warningmessage30')
                            "
                            class="selectclass"
                          >
                            <el-option
                              v-for="item in languageoptions"
                              :key="item.language_id"
                              :label="item.cname"
                              :value="item.language_id"
                            >
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                      <!-- 选择目标文件夹 -->
                      <div class="uploadsrt">
                        <div class="AssignFolder">
                          <p>{{ $t("CommonName.targetFolder") }}</p>
                          <el-checkbox v-model="isAddFolder">{{
                            $t("PersonalEndLanguage.AssignText2")
                          }}</el-checkbox>
                        </div>
                        <el-select
                          v-model="AddFolderID"
                          clearable
                          v-show="!isAddFolder"
                          filterable
                          :placeholder="$t('CommonName.Pleaseselect')"
                          class="selectclass"
                        >
                          <el-option
                            v-for="item in userFolderList"
                            :key="item.folder_id"
                            :label="item.folder_name"
                            :value="item.folder_id"
                          >
                          </el-option>
                        </el-select>
                        <el-input
                          class="selectclass"
                          v-model="AddFolderName"
                          :maxlength="60"
                          v-show="isAddFolder"
                        ></el-input>
                      </div>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              <!--      批量上传SRT   -->
              <el-tab-pane
                :label="$t('PersonalEndLanguage.batchuploadSRT')"
                name="six"
              >
                <div
                  class="tag_left_third"
                  v-if="HardSubtitleRecognitionTab === 'six'"
                >
                  <el-upload
                    class="upload-demo"
                    drag
                    ref="upload"
                    :with-credentials="true"
                    :action="actionURL"
                    :name="uploadName"
                    :data="uploadData"
                    :before-upload="beforeUpload"
                    :on-change="uploadChangeAI_six"
                    :on-exceed="uploadExceed_third"
                    :on-progress="uploadProgress"
                    :on-success="uploadSuccess"
                    :on-error="uploadError"
                    :auto-upload="false"
                    :show-file-list="false"
                    :limit="10"
                    :file-list="hardSubtitleRecognition_fileList"
                    multiple
                  >
                    <div
                      class="uploaddiv"
                      slot="trigger"
                      @click="AudiotranscriptionClick_third(4)"
                      @mouseenter="AudiotranscriptionClick_third_enter"
                    >
                      <div
                        v-if="hardSubtitleRecognition_fileList.length > 0"
                        class="filelist_div"
                      >
                        <div
                          class="upload_table_div"
                          @click="
                            (e) => {
                              return e.stopPropagation();
                            }
                          "
                        >
                          <div class="videoDatanamediv_header">
                            <p
                              style="padding-left: 17px; box-sizing: border-box"
                            >
                              <!--                              视频标题-->
                              {{ $t("PersonalEndLanguage.videoTitle") }}
                            </p>
                            <p style="padding-left: 30px"></p>
                            <p style="padding-left: 20px">
                              <!--                              SRT 文件-->
                              {{ $t("PersonalEndLanguage.VideoDubbingText6") }}
                            </p>
                          </div>
                          <div class="videoDatanamediv_body_list">
                            <div
                              class="videoDatanamediv_body"
                              v-for="item in hardSubtitleRecognition_fileList"
                              :key="item.id"
                            >
                              <div class="left_div2">
                                <div class="svgdivs">
                                  <svg-icon
                                    icon-class="video"
                                    class="icon"
                                  ></svg-icon>
                                </div>
                                <div
                                  class="body_p"
                                  :id="'tag' + item.id"
                                  @mouseover="onShowNameTipsMouseenter(item)"
                                >
                                  <el-tooltip
                                    class="item"
                                    effect="dark"
                                    :content="item.name"
                                    placement="top-start"
                                    :disabled="!item.showdropdown"
                                    :visible-arrow="false"
                                  >
                                    <span>{{ item.name }}</span>
                                  </el-tooltip>
                                </div>
                              </div>
                              <div class="center_input">
                                <el-input
                                  :placeholder="
                                    $t('PersonalEndLanguage.warningmessage2')
                                  "
                                  v-model="item.srtinput"
                                  class="input-with-select srtUnput"
                                  @input="item.srtinput = ''"
                                >
                                  <el-upload
                                    slot="append"
                                    class="upload-demo"
                                    :ref="item.id + 'srtupload'"
                                    :action="
                                      $uploadURL + '/job/upload_srt_file'
                                    "
                                    name="srt_file"
                                    :with-credentials="true"
                                    :before-upload="srtbeforeUpload"
                                    :show-file-list="false"
                                    :on-success="srtuploadSuccess"
                                    :auto-upload="true"
                                    :limit="1"
                                  >
                                    <el-button
                                      @click="
                                        srtupload(
                                          item.id + 'srtupload',
                                          item,
                                          1
                                        )
                                      "
                                      type="text"
                                    >
                                      <div class="svgbtn">
                                        <svg-icon
                                          icon-class="upload"
                                          class="icon"
                                        ></svg-icon>
                                        <!--                              上传-->
                                        <div>
                                          {{ $t("PersonalEndLanguage.upload") }}
                                        </div>
                                      </div>
                                    </el-button>
                                  </el-upload>
                                </el-input>
                              </div>
                              <div class="last_delete">
                                <el-button
                                  type="text"
                                  @click="
                                    UploadListDeleteClick($event, item, 4)
                                  "
                                >
                                  <svg-icon
                                    icon-class="delete"
                                    class="icon"
                                  ></svg-icon>
                                </el-button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="p_div">
                          <i class="el-icon-plus"></i>
                          <p style="color: #2f9efd">
                            <!--                            点击选择或拖动视频到这里-->
                            {{ $t("PersonalEndLanguage.DragVideo") }}
                          </p>
                        </div>
                        <!--                        <div class="svgdivs">-->
                        <!--                          <svg-icon-->
                        <!--                            :icon-class="videoData.icon"-->
                        <!--                            class="icon"-->
                        <!--                          ></svg-icon>-->
                        <!--                        </div>-->
                        <!--                        <p class="namep">{{ videoData.name }}</p>-->
                        <!--                        <p class="topp">重新上传</p>-->
                      </div>
                      <div v-else>
                        <i class="el-icon-plus"></i>
                        <p class="topp">
                          {{ $t("PersonalEndLanguage.DragVideo") }}
                        </p>
                        <p class="botp">
                          {{
                            $t("PersonalEndLanguage.SupportsMP4formatvideo") +
                            "≤" +
                            user_info.video_filesize_limit +
                            "GB，" +
                            $t("PersonalEndLanguage.duration") +
                            "≤"
                          }}
                          <span>{{ user_info.video_duration_limit }}</span>
                          {{
                            $t("PersonalEndLanguage.minute") +
                            $t("PersonalEndLanguage.moreLangth1")
                          }}
                        </p>
                      </div>
                    </div>
                  </el-upload>
                  <div style="display: flex; justify-content: space-between">
                    <div>
                      <!--              硬字幕涂抹-->
                      <el-checkbox
                        v-model="form10.checkbox1"
                        :disabled="form10.checkboxDisabled"
                        >{{ $t("PersonalEndLanguage.VideoDubbingText20") }}
                        <el-tooltip
                          placement="top-start"
                          popper-class="uploadptooltip"
                        >
                          <div slot="content">
                            <div>
                              {{
                                $t("PersonalEndLanguage.warningmessage41") +
                                user_info.video_daub_minute_limit +
                                $t("PersonalEndLanguage.warningmessage50")
                              }}
                            </div>
                          </div>
                          <svg-icon
                            icon-class="presentation"
                            class="icon"
                            style="cursor: pointer"
                          ></svg-icon> </el-tooltip
                      ></el-checkbox>
                      <!--              硬字幕涂抹 高级-->
                      <el-checkbox
                        v-model="form10.checkbox3"
                        :disabled="form10.checkboxDisabled"
                        v-if="
                          form10.checkbox1 && user_info.video_daub_mode == 1
                        "
                        >{{ $t("PersonalEndLanguage.VideoDubbingText24") }}
                        <el-popover
                          placement="top"
                          trigger="hover"
                          :visible-arrow="false"
                        >
                          <div>
                            <div
                              style="color: #fff; white-space: pre-wrap"
                              v-html="
                                $t('PersonalEndLanguage.VideoDubbingText25')
                              "
                            ></div>
                            <div class="innerDialogPopover_div1">
                              <div>
                                <el-image
                                  :src="require('@/assets/imgs/tumo.jpg')"
                                  fit="fill"
                                  initial-index="0"
                                  :preview-src-list="srcList"
                                ></el-image>
                                <p class="btmp" style="word-break: break-word">
                                  {{ $t("PersonalEndLanguage.ViewExamples8") }}
                                </p>
                              </div>
                              <div>
                                <el-image
                                  :src="require('@/assets/imgs/tumo_1.jpg')"
                                  fit="fill"
                                  initial-index="1"
                                  :preview-src-list="srcList"
                                ></el-image>
                                <p class="btmp" style="word-break: break-word">
                                  {{ $t("PersonalEndLanguage.ViewExamples9") }}
                                </p>
                              </div>
                              <div>
                                <el-image
                                  :src="require('@/assets/imgs/tumo_2.jpg')"
                                  fit="fill"
                                  initial-index="2"
                                  :preview-src-list="srcList"
                                ></el-image>
                                <p class="btmp" style="word-break: break-word">
                                  {{ $t("PersonalEndLanguage.ViewExamples10") }}
                                </p>
                              </div>
                            </div>
                          </div>

                          <svg-icon
                            slot="reference"
                            icon-class="presentation"
                            class="icon"
                            style="cursor: pointer"
                          ></svg-icon>
                        </el-popover>
                      </el-checkbox>
                    </div>
                    <!--              硬字幕压制-->
                    <el-checkbox
                      v-model="form10.checkbox2"
                      :disabled="form10.checkboxDisabled"
                      >{{ $t("PersonalEndLanguage.VideoDubbingText21") }}
                      <el-tooltip
                        placement="top-start"
                        popper-class="uploadptooltip"
                      >
                        <div slot="content">
                          <div>
                            {{
                              $t("PersonalEndLanguage.warningmessage41") +
                              user_info.video_daub_minute_limit +
                              $t("PersonalEndLanguage.warningmessage50")
                            }}
                          </div>
                        </div>
                        <svg-icon
                          icon-class="presentation"
                          class="icon"
                          style="cursor: pointer"
                        ></svg-icon> </el-tooltip
                    ></el-checkbox>
                  </div>
                  <div
                    style="
                      width: 100%;
                      display: flex;
                      justify-content: space-between;
                    "
                  >
                    <!-- 左侧功能区 -->
                    <div style="width: 48%">
                      <div class="uploadsrt uploadsrt1">
                        <!--              视频语言-->
                        <p>
                          {{ $t("PersonalEndLanguage.Transcriptionmethod3") }}
                        </p>
                        <el-select
                          v-model="form10.videoLanguage"
                          filterable
                          :placeholder="
                            $t('PersonalEndLanguage.warningmessage1')
                          "
                          class="selectclass"
                        >
                          <el-option
                            v-for="item in languageoptions"
                            :key="item.language_id"
                            :label="item.cname"
                            :value="item.language_id"
                          >
                          </el-option>
                        </el-select>
                      </div>
                      <!-- 选择目标文件夹 -->
                      <div class="uploadsrt" style="margin-top: 0px">
                        <div class="AssignFolder">
                          <p>{{ $t("CommonName.targetFolder") }}</p>
                          <el-checkbox v-model="isAddFolder">{{
                            $t("PersonalEndLanguage.AssignText2")
                          }}</el-checkbox>
                        </div>
                        <el-select
                          v-model="AddFolderID"
                          v-show="!isAddFolder"
                          clearable
                          filterable
                          :placeholder="$t('CommonName.Pleaseselect')"
                          class="selectclass100"
                        >
                          <el-option
                            v-for="item in userFolderList"
                            :key="item.folder_id"
                            :label="item.folder_name"
                            :value="item.folder_id"
                          >
                          </el-option>
                        </el-select>
                        <el-input
                          class="selectclass100"
                          v-model="AddFolderName"
                          :maxlength="60"
                          v-show="isAddFolder"
                        ></el-input>
                      </div>
                    </div>
                    <div style="width: 48%">
                      <div
                        :class="
                          form10.checked
                            ? 'upload_right_div upload_right_div2'
                            : 'upload_right_div upload_right_div1'
                        "
                        style="margin-top: 24px"
                      >
                        <!--              需要翻译字幕-->
                        <el-checkbox v-model="form10.checked">{{
                          $t("PersonalEndLanguage.Transcriptionmethod8")
                        }}</el-checkbox>
                        <div class="uploadsrt" v-if="form10.checked">
                          <el-select
                            v-model="form10.targetlanguage"
                            filterable
                            :placeholder="
                              $t('PersonalEndLanguage.warningmessage30')
                            "
                            class="selectclass"
                          >
                            <el-option
                              v-for="item in languageoptions"
                              :key="item.language_id"
                              :label="item.cname"
                              :value="item.language_id"
                            >
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
          <!--    硬字幕识别 自带左右 -->
          <div
            class="HardSubtitleRecognition_div"
            v-if="
              dialogtitle === $t('PersonalEndLanguage.Hardsubtitlerecognition')
            "
          >
            <el-tabs
              v-model="HardSubtitleRecognitionTab"
              @tab-click="HardSubtitleRecognitionTabClick"
            >
              <!--             本地视频-->
              <el-tab-pane
                :label="$t('PersonalEndLanguage.fileAddress3')"
                name="first"
              >
                <div
                  class="HardSubtitleRecognition_div_div"
                  v-if="HardSubtitleRecognitionTab === 'first'"
                >
                  <el-upload
                    class="upload-demo"
                    drag
                    ref="upload"
                    :with-credentials="true"
                    :action="'#'"
                    :name="uploadName"
                    :data="uploadData"
                    :before-upload="beforeUpload"
                    :on-change="uploadChange"
                    :on-progress="uploadProgress"
                    :on-success="uploadSuccess"
                    :on-error="uploadError"
                    :auto-upload="false"
                    :show-file-list="false"
                    :limit="1"
                  >
                    <div
                      class="uploaddiv"
                      slot="trigger"
                      @click="AudiotranscriptionClick"
                    >
                      <div v-if="videoData.name" class="videoDatanamediv">
                        <div class="svgdivs">
                          <svg-icon
                            :icon-class="videoData.icon"
                            class="icon"
                          ></svg-icon>
                        </div>
                        <p class="namep">{{ videoData.name }}</p>
                        <p class="topp">
                          {{ $t("PersonalEndLanguage.Reupload") }}
                        </p>
                      </div>
                      <div v-else>
                        <i class="el-icon-plus"></i>
                        <p class="topp">
                          {{ $t("PersonalEndLanguage.DragVideo") }}
                        </p>
                        <p class="botp">
                          {{
                            $t("PersonalEndLanguage.SupportsMP4formatvideo") +
                            " ≤"
                          }}
                          {{
                            [2, 6, 9].includes(project_id)
                              ? user_info.video_dubbing_filesize_limit
                              : user_info.video_filesize_limit
                          }}GB，{{ $t("PersonalEndLanguage.duration") }} ≤
                          <span v-if="[2, 6, 9].includes(project_id)">{{
                            user_info.video_dubbing_duration_limit
                          }}</span
                          ><span v-else>{{
                            user_info.video_duration_limit
                          }}</span
                          >{{ $t("PersonalEndLanguage.minute") }}
                        </p>
                      </div>
                    </div>
                  </el-upload>
                  <div>
                    <div class="uploadsrt uploadsrt1">
                      <!--            源语言  -->
                      <p>{{ $t("MyFiles.sourceLanguage") }}</p>
                      <el-select
                        v-model="form5.sourcelanguage"
                        filterable
                        :placeholder="$t('PersonalEndLanguage.warningmessage1')"
                        class="selectclass"
                      >
                        <el-option
                          v-for="item in setLanguage"
                          :key="item.language_id"
                          :label="item.cname"
                          :value="item.language_id"
                        >
                        </el-option>
                      </el-select>
                    </div>
                    <!-- 选择目标文件夹 -->
                    <div class="uploadsrt">
                      <div class="AssignFolder">
                        <p>{{ $t("CommonName.targetFolder") }}</p>
                        <el-checkbox v-model="isAddFolder">{{
                          $t("PersonalEndLanguage.AssignText2")
                        }}</el-checkbox>
                      </div>
                      <el-select
                        v-model="AddFolderID"
                        clearable
                        v-show="!isAddFolder"
                        filterable
                        :placeholder="$t('CommonName.Pleaseselect')"
                        class="selectclass"
                      >
                        <el-option
                          v-for="item in userFolderList"
                          :key="item.folder_id"
                          :label="item.folder_name"
                          :value="item.folder_id"
                        >
                        </el-option>
                      </el-select>
                      <el-input
                        class="selectclass"
                        v-model="AddFolderName"
                        :maxlength="60"
                        v-show="isAddFolder"
                      ></el-input>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              <!--           批量上传   -->
              <el-tab-pane
                :label="$t('PersonalEndLanguage.batchupload')"
                name="third"
              >
                <div
                  class="tag_left_third"
                  v-if="HardSubtitleRecognitionTab === 'third'"
                >
                  <el-upload
                    class="upload-demo"
                    drag
                    ref="upload"
                    :with-credentials="true"
                    :action="actionURL"
                    :name="uploadName"
                    :data="uploadData"
                    :before-upload="beforeUpload"
                    :on-change="hardSubtitleRecognition_change_third"
                    :on-exceed="hardSubtitleRecognition_Exceed_third"
                    :on-progress="uploadProgress"
                    :on-success="uploadSuccess"
                    :on-error="uploadError"
                    :auto-upload="false"
                    :show-file-list="false"
                    :limit="10"
                    :file-list="hardSubtitleRecognition_fileList"
                    multiple
                  >
                    <div
                      class="uploaddiv"
                      slot="trigger"
                      @click="AudiotranscriptionClick_third(1)"
                      @mouseenter="AudiotranscriptionClick_third_enter"
                    >
                      <div
                        v-if="hardSubtitleRecognition_fileList.length > 0"
                        class="filelist_div"
                      >
                        <div
                          class="upload_table_div"
                          @click="
                            (e) => {
                              return e.stopPropagation();
                            }
                          "
                        >
                          <div
                            class="videoDatanamediv_header videoDatanamediv_header1"
                          >
                            <p style="" class="first_p">
                              <!--                              视频标题-->
                              {{ $t("PersonalEndLanguage.videoTitle") }}
                            </p>
                            <p style="" class="last_p">
                              <!--                              源语言-->
                              {{ $t("MyFiles.sourceLanguage") }}
                            </p>
                          </div>
                          <div class="videoDatanamediv_body_list">
                            <div
                              class="videoDatanamediv_body"
                              v-for="item in hardSubtitleRecognition_fileList"
                              :key="item.id"
                            >
                              <div class="left_div left_div1">
                                <div class="svgdivs">
                                  <svg-icon
                                    icon-class="video"
                                    class="icon"
                                  ></svg-icon>
                                </div>
                                <div
                                  class="body_p"
                                  :id="'tag' + item.id"
                                  @mouseover="onShowNameTipsMouseenter(item)"
                                >
                                  <el-tooltip
                                    class="item"
                                    effect="dark"
                                    :content="item.name"
                                    placement="top-start"
                                    :disabled="!item.showdropdown"
                                    :visible-arrow="false"
                                  >
                                    <span>{{ item.name }}</span>
                                  </el-tooltip>
                                </div>
                              </div>
                              <div class="right_select">
                                <el-select
                                  v-model="item.videoLanguage"
                                  filterable
                                  :placeholder="
                                    $t('PersonalEndLanguage.warningmessage1')
                                  "
                                  class="selectclass"
                                  @change="SelectChange"
                                >
                                  <el-option
                                    v-for="items in setLanguage"
                                    :key="items.language_id"
                                    :label="items.cname"
                                    :value="items.language_id"
                                  >
                                  </el-option>
                                </el-select>
                              </div>
                              <div class="last_delete last_delete1">
                                <el-button
                                  type="text"
                                  @click="
                                    hardSubtitleRecognitionList_Delete_Click(
                                      $event,
                                      item
                                    )
                                  "
                                >
                                  <svg-icon
                                    icon-class="delete"
                                    class="icon"
                                  ></svg-icon>
                                </el-button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="p_div">
                          <i class="el-icon-plus"></i>
                          <p style="color: #2f9efd">
                            <!--                            点击选择或拖动视频到这里-->
                            {{ $t("PersonalEndLanguage.DragVideo") }}
                          </p>
                        </div>
                        <!--                        <div class="svgdivs">-->
                        <!--                          <svg-icon-->
                        <!--                            :icon-class="videoData.icon"-->
                        <!--                            class="icon"-->
                        <!--                          ></svg-icon>-->
                        <!--                        </div>-->
                        <!--                        <p class="namep">{{ videoData.name }}</p>-->
                        <!--                        <p class="topp">重新上传</p>-->
                      </div>
                      <div v-else>
                        <i class="el-icon-plus"></i>
                        <p class="topp">
                          {{ $t("PersonalEndLanguage.DragVideo") }}
                        </p>
                        <p class="botp">
                          {{
                            $t("PersonalEndLanguage.SupportsMP4formatvideo") +
                            "≤" +
                            user_info.video_dubbing_filesize_limit +
                            "GB，" +
                            $t("PersonalEndLanguage.duration") +
                            "≤"
                          }}
                          <span>{{
                            user_info.video_dubbing_duration_limit
                          }}</span>
                          {{
                            $t("PersonalEndLanguage.minute") +
                            $t("PersonalEndLanguage.moreLangth")
                          }}
                        </p>
                      </div>
                    </div>
                  </el-upload>
                  <!-- 选择目标文件夹 -->
                  <div class="uploadsrt">
                    <div class="AssignFolder">
                      <p>{{ $t("CommonName.targetFolder") }}</p>
                      <el-checkbox v-model="isAddFolder">{{
                        $t("PersonalEndLanguage.AssignText2")
                      }}</el-checkbox>
                    </div>
                    <el-select
                      v-model="AddFolderID"
                      clearable
                      v-show="!isAddFolder"
                      filterable
                      :placeholder="$t('CommonName.Pleaseselect')"
                      class="selectclass100"
                    >
                      <el-option
                        v-for="item in userFolderList"
                        :key="item.folder_id"
                        :label="item.folder_name"
                        :value="item.folder_id"
                      >
                      </el-option>
                    </el-select>
                    <el-input
                      class="selectclass100"
                      v-model="AddFolderName"
                      :maxlength="60"
                      v-show="isAddFolder"
                    ></el-input>
                  </div>
                </div>
              </el-tab-pane>
              <!--              youtube  视频-->
              <el-tab-pane
                :label="$t('PersonalEndLanguage.Youtubevideo')"
                name="second"
                v-if="user_info.video_dubbing_youtube === '1'"
              >
                <!--              -->
                <div
                  class="HardSubtitleRecognition_div_div"
                  v-if="HardSubtitleRecognitionTab === 'second'"
                >
                  <div class="uploadsrt uploadsrt1">
                    <!--                    youtube 视频地址-->
                    <p>{{ $t("PersonalEndLanguage.fileAddress") }}</p>
                    <el-input
                      maxlength="80"
                      v-model="form5.youtubeNameInput"
                      placeholder=""
                      @blur="youtubeInputBlur(form5.youtubeNameInput)"
                    ></el-input>
                  </div>
                  <!--                    --host=client1.ectranslate.com-->
                  <div class="uploadsrt uploadsrt1">
                    <!--            源语言  -->
                    <p>{{ $t("MyFiles.sourceLanguage") }}</p>
                    <el-select
                      v-model="form5.sourcelanguage"
                      filterable
                      :placeholder="$t('PersonalEndLanguage.warningmessage1')"
                      class="selectclass"
                    >
                      <el-option
                        v-for="item in setLanguage"
                        :key="item.language_id"
                        :label="item.cname"
                        :value="item.language_id"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
                <div class="btm_p">
                  <span
                    >YouTube
                    <span style="margin-right: 4px">{{
                      $t("PersonalEndLanguage.youtubeText3")
                    }}</span></span
                  >
                  <p>https://www.youtube.com/watch?v=5Dn9WRWOs4s</p>
                </div>
                <div class="btm_p">
                  <p>https://youtu.be/5Dn9WRWOs4s</p>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
          <!--     视频配音 自带左右 -->
          <div class="VideoDubbing" v-if="[2, 6, 9].includes(project_id)">
            <el-tabs
              v-model="HardSubtitleRecognitionTab"
              @tab-click="uploadVideoHandleClick"
            >
              <!--              上传视频-->
              <el-tab-pane
                :label="$t('PersonalEndLanguage.uploadvideo')"
                name="first"
              >
                <!--                <div >-->
                <!--                  radio_tag_div_first_class-->
                <div
                  class="tag_div radio_tag_div_first_class"
                  v-if="HardSubtitleRecognitionTab === 'first'"
                >
                  <div class="tag_left">
                    <el-upload
                      class="upload-demo"
                      drag
                      ref="upload"
                      :with-credentials="true"
                      :action="actionURL"
                      :name="uploadName"
                      :data="uploadData"
                      :before-upload="beforeUpload"
                      :on-change="uploadChange"
                      :on-progress="uploadProgress"
                      :on-success="uploadSuccess"
                      :on-error="uploadError"
                      :auto-upload="false"
                      :show-file-list="false"
                      :limit="1"
                    >
                      <div
                        class="uploaddiv"
                        slot="trigger"
                        @click="AudiotranscriptionClick"
                      >
                        <div v-if="videoData.name" class="videoDatanamediv">
                          <div class="svgdivs">
                            <svg-icon
                              :icon-class="videoData.icon"
                              class="icon"
                            ></svg-icon>
                          </div>
                          <p class="namep">{{ videoData.name }}</p>
                          <!--                          <p class="topp">重新上传</p>-->
                          <p class="topp">
                            {{ $t("PersonalEndLanguage.Reupload") }}
                          </p>
                        </div>
                        <div v-else>
                          <i class="el-icon-plus"></i>
                          <!--                          <p class="topp">点击选择或拖动视频到这里</p>-->
                          <p class="topp">
                            {{ $t("PersonalEndLanguage.DragVideo") }}
                          </p>
                          <!--                          支持mp4格式，视频-->
                          <p class="botp">
                            {{
                              $t("PersonalEndLanguage.SupportsMP4formatvideo") +
                              "≤" +
                              user_info.video_dubbing_filesize_limit +
                              "GB，" +
                              $t("PersonalEndLanguage.duration") +
                              "≤"
                            }}
                            <span>{{
                              user_info.video_dubbing_duration_limit
                            }}</span>
                            {{ $t("PersonalEndLanguage.minute") }}
                          </p>
                        </div>
                      </div>
                    </el-upload>
                    <div
                      v-if="[2, 6].includes(project_id)"
                      style="display: flex; justify-content: space-between"
                    >
                      <!--              硬字幕涂抹-->
                      <el-checkbox
                        v-model="form8.checkbox1"
                        :disabled="form8.checkboxDisabled"
                        >{{ $t("PersonalEndLanguage.VideoDubbingText20") }}
                        <el-tooltip
                          placement="top-start"
                          popper-class="uploadptooltip"
                        >
                          <div slot="content">
                            <div>
                              {{
                                $t("PersonalEndLanguage.warningmessage41") +
                                user_info.video_daub_minute_limit +
                                $t("PersonalEndLanguage.warningmessage50")
                              }}
                            </div>
                          </div>
                          <svg-icon
                            icon-class="presentation"
                            class="icon"
                            style="cursor: pointer"
                          ></svg-icon> </el-tooltip
                      ></el-checkbox>
                      <!--              硬字幕压制-->
                      <el-checkbox
                        v-model="form8.checkbox2"
                        :disabled="form8.checkboxDisabled"
                        >{{ $t("PersonalEndLanguage.VideoDubbingText21") }}
                        <el-tooltip
                          placement="top-start"
                          popper-class="uploadptooltip"
                        >
                          <div slot="content">
                            <div>
                              {{
                                $t("PersonalEndLanguage.warningmessage41") +
                                user_info.video_daub_minute_limit +
                                $t("PersonalEndLanguage.warningmessage50")
                              }}
                            </div>
                          </div>
                          <svg-icon
                            icon-class="presentation"
                            class="icon"
                            style="cursor: pointer"
                          ></svg-icon> </el-tooltip
                      ></el-checkbox>
                    </div>
                    <div
                      style="display: flex; justify-content: space-between"
                      v-if="[2, 6].includes(project_id)"
                    >
                      <!--              硬字幕涂抹 高级-->
                      <el-checkbox
                        v-model="form8.checkbox3"
                        :disabled="form8.checkboxDisabled"
                        v-if="form8.checkbox1 && user_info.video_daub_mode == 1"
                        >{{ $t("PersonalEndLanguage.VideoDubbingText24") }}
                        <el-popover
                          placement="top"
                          trigger="hover"
                          :visible-arrow="false"
                        >
                          <div>
                            <div
                              style="color: #fff; white-space: pre-wrap"
                              v-html="
                                $t('PersonalEndLanguage.VideoDubbingText25')
                              "
                            ></div>
                            <div class="innerDialogPopover_div1">
                              <div>
                                <el-image
                                  :src="require('@/assets/imgs/tumo.jpg')"
                                  fit="fill"
                                  initial-index="0"
                                  :preview-src-list="srcList"
                                ></el-image>
                                <p class="btmp" style="word-break: break-word">
                                  {{ $t("PersonalEndLanguage.ViewExamples8") }}
                                </p>
                              </div>
                              <div>
                                <el-image
                                  :src="require('@/assets/imgs/tumo_1.jpg')"
                                  fit="fill"
                                  initial-index="1"
                                  :preview-src-list="srcList"
                                ></el-image>
                                <p class="btmp" style="word-break: break-word">
                                  {{ $t("PersonalEndLanguage.ViewExamples9") }}
                                </p>
                              </div>
                              <div>
                                <el-image
                                  :src="require('@/assets/imgs/tumo_2.jpg')"
                                  fit="fill"
                                  initial-index="2"
                                  :preview-src-list="srcList"
                                ></el-image>
                                <p class="btmp" style="word-break: break-word">
                                  {{ $t("PersonalEndLanguage.ViewExamples10") }}
                                </p>
                              </div>
                            </div>
                          </div>

                          <svg-icon
                            slot="reference"
                            icon-class="presentation"
                            class="icon"
                            style="cursor: pointer"
                          ></svg-icon> </el-popover
                      ></el-checkbox>
                    </div>
                  </div>
                  <div class="right" v-if="false">
                    <div class="uploadsrt uploadsrt1">
                      <!--                     上传文件 -->
                      <p class="titlep">
                        {{
                          $t("PersonalEndLanguage.uploadFile") +
                          $t("PersonalEndLanguage.uploadFile2")
                        }}
                      </p>
                      <!-- 上传字幕文件 -->
                      <el-input
                        v-model="form8.srtinput"
                        class="input-with-select srtUnput"
                        @input="form8.srtinput = ''"
                      >
                        <el-upload
                          slot="append"
                          class="upload-demo"
                          ref="srtupload"
                          :action="$uploadURL + '/job/upload_srt_file'"
                          name="srt_file"
                          :with-credentials="true"
                          :before-upload="srtbeforeUpload"
                          :show-file-list="false"
                          :on-success="srtuploadSuccess"
                          :auto-upload="true"
                          :limit="1"
                        >
                          <el-button
                            @click="srtupload('srtupload')"
                            type="text"
                          >
                            <div class="svgbtn">
                              <svg-icon
                                icon-class="upload"
                                class="icon"
                              ></svg-icon>
                              <!--                              上传-->
                              <div>
                                {{ $t("PersonalEndLanguage.upload") }}
                              </div>
                            </div>
                          </el-button>
                        </el-upload>
                      </el-input>
                    </div>
                    <div class="uploadsrt">
                      <!--                      SRT语言-->
                      <p>{{ $t("PersonalEndLanguage.SRTLanguage") }}</p>
                      <el-select
                        v-model="form8.videoLanguage"
                        filterable
                        :placeholder="
                          $t('PersonalEndLanguage.PleaseselectSRTlanguage')
                        "
                        class="selectclass"
                      >
                        <el-option
                          v-for="item in languageoptions"
                          :key="item.language_id"
                          :label="item.cname"
                          :value="item.language_id"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                  <div v-else class="right">
                    <div class="uploadsrt uploadsrt1">
                      <!--                      配音语言-->
                      <p>{{ $t("PersonalEndLanguage.VideoDubbingText") }}</p>
                      <el-select
                        v-model="form8.videoLanguage"
                        filterable
                        :placeholder="$t('CommonName.Pleaseselect')"
                        class="selectclass"
                      >
                        <el-option
                          v-for="item in setpyLanguage"
                          :key="item.language_id"
                          :label="item.cname"
                          :value="item.language_id"
                        >
                        </el-option>
                      </el-select>
                    </div>
                    <div class="uploadsrt radioClass">
                      <el-radio-group v-model="form8.radio1">
                        <!--                        上传配音 SRT 文件-->
                        <el-radio label="1" border>{{
                          $t("PersonalEndLanguage.VideoDubbingText2")
                        }}</el-radio>
                        <!--                        无配音 SRT 文件-->
                        <el-radio label="2" border>{{
                          $t("PersonalEndLanguage.VideoDubbingText3")
                        }}</el-radio>
                      </el-radio-group>
                    </div>
                    <div class="uploadsrt" v-if="form8.radio1 === '1'">
                      <!-- 上传字幕文件 -->
                      <el-input
                        v-model="form8.srtinput"
                        :placeholder="
                          $t('PersonalEndLanguage.VideoDubbingText4')
                        "
                        class="input-with-select srtUnput"
                        @input="form8.srtinput = ''"
                      >
                        <el-upload
                          slot="append"
                          class="upload-demo"
                          ref="srtupload"
                          :action="$uploadURL + '/job/upload_srt_file'"
                          name="srt_file"
                          :with-credentials="true"
                          :before-upload="srtbeforeUpload"
                          :show-file-list="false"
                          :on-success="srtuploadSuccess"
                          :auto-upload="true"
                          :limit="1"
                        >
                          <el-button
                            @click="srtupload('srtupload')"
                            type="text"
                          >
                            <div class="svgbtn">
                              <svg-icon
                                icon-class="upload"
                                class="icon"
                              ></svg-icon>
                              <!--                              上传-->
                              <div>
                                {{ $t("PersonalEndLanguage.upload") }}
                              </div>
                            </div>
                          </el-button>
                        </el-upload>
                      </el-input>
                    </div>
                    <div
                      class="uploadsrt radio2_div"
                      v-if="form8.radio1 === '2'"
                    >
                      <el-tabs v-model="form8.activeName" type="border-card">
                        <!--                        有源语言 SRT 文件-->
                        <el-tab-pane
                          name="1"
                          :label="$t('PersonalEndLanguage.VideoDubbingText5')"
                        >
                          <div class="uploadsrt uploadsrt2">
                            <!-- 上传字幕文件 -->
                            <el-input
                              v-model="form8.srtinput1"
                              :placeholder="
                                $t('PersonalEndLanguage.VideoDubbingText6')
                              "
                              class="input-with-select srtUnput"
                              @input="form8.srtinput1 = ''"
                            >
                              <el-upload
                                slot="append"
                                class="upload-demo"
                                ref="srtupload1"
                                :action="$uploadURL + '/job/upload_srt_file'"
                                name="srt_file"
                                :with-credentials="true"
                                :before-upload="srtbeforeUpload"
                                :show-file-list="false"
                                :on-success="srtuploadSuccess"
                                :auto-upload="true"
                                :limit="1"
                              >
                                <el-button
                                  @click="srtupload('srtupload1')"
                                  type="text"
                                >
                                  <div class="svgbtn">
                                    <svg-icon
                                      icon-class="upload"
                                      class="icon"
                                    ></svg-icon>
                                    <!--                              上传-->
                                    <div>
                                      {{ $t("PersonalEndLanguage.upload") }}
                                    </div>
                                  </div>
                                </el-button>
                              </el-upload>
                            </el-input>
                          </div>
                          <div class="uploadsrt select2">
                            <!--                      源语言-->
                            <div class="select2_div">
                              <p>{{ $t("MyFiles.sourceLanguage") }}</p>
                              <p class="select2_div_p">
                                {{
                                  $t("PersonalEndLanguage.VideoDubbingText7")
                                }}
                              </p>
                            </div>
                            <el-select
                              v-model="form8.select2"
                              filterable
                              :placeholder="$t('CommonName.Pleaseselect')"
                              class="selectclass"
                            >
                              <el-option
                                v-for="item in languageoptions"
                                :key="item.language_id"
                                :label="item.cname"
                                :value="item.language_id"
                              >
                              </el-option>
                            </el-select>
                          </div>
                        </el-tab-pane>
                        <!--                        无源语言 SRT 文件-->
                        <el-tab-pane
                          name="2"
                          :label="$t('PersonalEndLanguage.VideoDubbingText8')"
                        >
                          <div class="uploadsrt radioClass">
                            <el-radio-group v-model="form8.radio2">
                              <!--                              语音转写-->
                              <el-radio label="1" border>{{
                                $t("PersonalEndLanguage.VideoDubbingText9")
                              }}</el-radio>
                              <!--                              硬字幕转写-->
                              <el-radio label="2" border>{{
                                $t("PersonalEndLanguage.VideoDubbingText10")
                              }}</el-radio>
                            </el-radio-group>
                          </div>
                          <div class="uploadsrt select2">
                            <!--                      源语言-->
                            <div class="select2_div">
                              <p>{{ $t("MyFiles.sourceLanguage") }}</p>
                              <p class="select2_div_p">
                                {{
                                  $t("PersonalEndLanguage.VideoDubbingText7")
                                }}
                              </p>
                            </div>
                            <el-select
                              v-model="form8.select2"
                              filterable
                              :placeholder="$t('CommonName.Pleaseselect')"
                              class="selectclass"
                            >
                              <el-option
                                v-for="item in form8.radio2 === '2'
                                  ? setLanguage
                                  : languageoptions"
                                :key="item.language_id"
                                :label="item.cname"
                                :value="item.language_id"
                              >
                              </el-option>
                            </el-select>
                          </div>
                        </el-tab-pane>
                      </el-tabs>
                    </div>
                    <!-- 上传人声音轨（非必填） -->
                    <div class="uploadsrt" v-if="[6].includes(project_id)">
                      <el-input
                        v-model="form8.audioName"
                        :placeholder="
                          $t('PersonalEndLanguage.VideoDubbingText22')
                        "
                        class="input-with-select srtUnput"
                        @input="form8.audioName = ''"
                      >
                        <el-upload
                          slot="append"
                          class="upload-demo"
                          ref="dubbing_vocals"
                          :action="$uploadURL + '/job/upload_audio_file'"
                          name="audio_file"
                          :with-credentials="true"
                          :before-upload="audioBeforeUpload"
                          :show-file-list="false"
                          :on-success="
                            (response, file, fileList) =>
                              audioUploadSuccess(response, file, fileList, 1)
                          "
                          :auto-upload="true"
                          :limit="1"
                        >
                          <el-button
                            @click="srtupload('dubbing_vocals')"
                            type="text"
                          >
                            <div class="svgbtn">
                              <svg-icon
                                icon-class="upload"
                                class="icon"
                              ></svg-icon>
                              <!--                              上传-->
                              <div>
                                {{ $t("PersonalEndLanguage.upload") }}
                              </div>
                            </div>
                          </el-button>
                        </el-upload>
                      </el-input>
                    </div>
                    <!-- 上传国际轨（非必填） -->
                    <div class="uploadsrt" v-if="[6].includes(project_id)">
                      <el-input
                        v-model="form8.audioName1"
                        :placeholder="
                          $t('PersonalEndLanguage.VideoDubbingText23')
                        "
                        class="input-with-select srtUnput"
                        @input="form8.audioName1 = ''"
                      >
                        <el-upload
                          slot="append"
                          class="upload-demo"
                          ref="dubbing_novocals"
                          :action="$uploadURL + '/job/upload_audio_file'"
                          name="audio_file"
                          :with-credentials="true"
                          :before-upload="audioBeforeUpload"
                          :show-file-list="false"
                          :on-success="
                            (response, file, fileList) =>
                              audioUploadSuccess(response, file, fileList, 2)
                          "
                          :auto-upload="true"
                          :limit="1"
                        >
                          <el-button
                            @click="srtupload('dubbing_novocals')"
                            type="text"
                          >
                            <div class="svgbtn">
                              <svg-icon
                                icon-class="upload"
                                class="icon"
                              ></svg-icon>
                              <!--                              上传-->
                              <div>
                                {{ $t("PersonalEndLanguage.upload") }}
                              </div>
                            </div>
                          </el-button>
                        </el-upload>
                      </el-input>
                    </div>
                    <!-- 解说类特殊类型 -->
                    <div
                      :class="'upload_right_div upload_right_div1'"
                      v-if="
                        [6].includes(project_id) &&
                        user_info.clone_dubbing_mode == 1
                      "
                    >
                      <el-checkbox v-model="form8.check_clone_mode">{{
                        $t("PersonalEndLanguage.VideoDubbingText26")
                      }}</el-checkbox>
                    </div>
                    <!-- 选择目标文件夹 -->
                    <div class="uploadsrt">
                      <div class="AssignFolder">
                        <p>{{ $t("CommonName.targetFolder") }}</p>
                        <el-checkbox v-model="isAddFolder">{{
                          $t("PersonalEndLanguage.AssignText2")
                        }}</el-checkbox>
                      </div>
                      <el-select
                        v-model="AddFolderID"
                        clearable
                        v-show="!isAddFolder"
                        filterable
                        :placeholder="$t('CommonName.Pleaseselect')"
                        class="selectclass"
                      >
                        <el-option
                          v-for="item in userFolderList"
                          :key="item.folder_id"
                          :label="item.folder_name"
                          :value="item.folder_id"
                        >
                        </el-option>
                      </el-select>
                      <el-input
                        class="selectclass"
                        v-model="AddFolderName"
                        :maxlength="60"
                        v-show="isAddFolder"
                      ></el-input>
                    </div>
                  </div>
                </div>
                <!--                setShow-->
                <div class="uploadsrt radioClass radioClass1" v-if="false">
                  <!--                 配音方式 -->
                  <p>{{ $t("PersonalEndLanguage.VideoDubbingText11") }}</p>
                  <el-radio-group v-model="form8.radio3">
                    <!--                    单角色配音-->
                    <el-radio label="0" border
                      ><span>{{
                        $t("PersonalEndLanguage.VideoDubbingText12")
                      }}</span>
                      <p class="radio_p">
                        {{ $t("PersonalEndLanguage.VideoDubbingText19") }}
                      </p>
                    </el-radio>
                    <!--                    男女配音-->
                    <el-radio label="1" border :disabled="true">
                      <span>{{
                        $t("PersonalEndLanguage.VideoDubbingText13")
                      }}</span>
                      <p class="radio_p">
                        {{ $t("PersonalEndLanguage.VideoDubbingText14") }}
                      </p>
                    </el-radio>
                    <!--                          多角色配音-->
                    <el-radio label="2" border>
                      <div class="radio_div">
                        <div class="radio_div_div">
                          <span>{{
                            $t("PersonalEndLanguage.VideoDubbingText15")
                          }}</span>
                          <div class="radio_span" v-if="form8.radio3 === '2'">
                            <p>
                              {{ $t("PersonalEndLanguage.VideoDubbingText16") }}
                            </p>
                            <el-select
                              v-model="form8.select3"
                              :placeholder="$t('CommonName.Pleaseselect')"
                              class="radio_span_select"
                            >
                              <el-option
                                v-for="item in form8Select3options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                              >
                              </el-option>
                            </el-select>
                          </div>
                        </div>
                        <p class="radio_p" style="width: 100%">
                          {{ $t("PersonalEndLanguage.VideoDubbingText17") }}
                        </p>
                      </div>
                    </el-radio>
                  </el-radio-group>
                </div>
                <!--                                </div>-->
              </el-tab-pane>
              <!--     视频配音 批量上传   -->
              <el-tab-pane
                :label="$t('PersonalEndLanguage.batchupload')"
                name="third"
              >
                <div
                  class="tag_div radio_tag_div_first_class"
                  v-if="HardSubtitleRecognitionTab === 'third'"
                >
                  <div class="tag_left">
                    <el-upload
                      class="upload-demo"
                      drag
                      ref="upload"
                      :with-credentials="true"
                      :action="actionURL"
                      :name="uploadName"
                      :data="uploadData"
                      :before-upload="beforeUpload"
                      :on-change="uploadChange_third"
                      :on-exceed="uploadExceed_third"
                      :on-progress="uploadProgress"
                      :on-success="uploadSuccess"
                      :on-error="uploadError"
                      :auto-upload="false"
                      :show-file-list="false"
                      :limit="10"
                      :file-list="hardSubtitleRecognition_fileList"
                      multiple
                    >
                      <div
                        class="uploaddiv"
                        slot="trigger"
                        @click="AudiotranscriptionClick_third(2)"
                        @mouseenter="AudiotranscriptionClick_third_enter"
                      >
                        <div
                          v-if="hardSubtitleRecognition_fileList.length > 0"
                          class="filelist_div"
                        >
                          <div
                            class="upload_table_div"
                            @click="
                              (e) => {
                                return e.stopPropagation();
                              }
                            "
                          >
                            <div class="videoDatanamediv_body_list1">
                              <div
                                class="videoDatanamediv_body"
                                v-for="item in hardSubtitleRecognition_fileList"
                                :key="item.id"
                              >
                                <div class="left_div left_div1">
                                  <div class="svgdivs">
                                    <svg-icon
                                      icon-class="video"
                                      class="icon"
                                    ></svg-icon>
                                  </div>
                                  <div
                                    class="body_p"
                                    :id="'tag' + item.id"
                                    @mouseover="onShowNameTipsMouseenter(item)"
                                  >
                                    <el-tooltip
                                      class="item"
                                      effect="dark"
                                      :content="item.name"
                                      placement="top-start"
                                      :disabled="!item.showdropdown"
                                      :visible-arrow="false"
                                    >
                                      <span>{{ item.name }}</span>
                                    </el-tooltip>
                                  </div>
                                </div>
                                <div class="last_delete last_delete1">
                                  <el-button
                                    type="text"
                                    @click="
                                      UploadListDeleteClick($event, item, 2)
                                    "
                                  >
                                    <svg-icon
                                      icon-class="delete"
                                      class="icon"
                                    ></svg-icon>
                                  </el-button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="p_div">
                            <i class="el-icon-plus"></i>
                            <p style="color: #2f9efd">
                              <!--                            点击选择或拖动视频到这里-->
                              {{ $t("PersonalEndLanguage.DragVideo") }}
                            </p>
                          </div>
                          <!--                        <div class="svgdivs">-->
                          <!--                          <svg-icon-->
                          <!--                            :icon-class="videoData.icon"-->
                          <!--                            class="icon"-->
                          <!--                          ></svg-icon>-->
                          <!--                        </div>-->
                          <!--                        <p class="namep">{{ videoData.name }}</p>-->
                          <!--                        <p class="topp">重新上传</p>-->
                        </div>
                        <div v-else>
                          <i class="el-icon-plus"></i>
                          <p class="topp">
                            {{ $t("PersonalEndLanguage.DragVideo") }}
                          </p>
                          <p class="botp">
                            {{
                              $t("PersonalEndLanguage.SupportsMP4formatvideo") +
                              "≤" +
                              user_info.video_dubbing_filesize_limit +
                              "GB，" +
                              $t("PersonalEndLanguage.duration") +
                              "≤"
                            }}
                            <span>{{
                              user_info.video_dubbing_duration_limit
                            }}</span>
                            {{
                              $t("PersonalEndLanguage.minute") +
                              $t("PersonalEndLanguage.moreLangth1")
                            }}
                          </p>
                        </div>
                      </div>
                    </el-upload>
                    <div
                      style="display: flex; justify-content: space-between"
                      v-if="[2, 6].includes(project_id)"
                    >
                      <!--              硬字幕涂抹-->
                      <el-checkbox
                        v-model="form9.checkbox1"
                        :disabled="form9.checkboxDisabled"
                        >{{ $t("PersonalEndLanguage.VideoDubbingText20") }}
                        <el-tooltip
                          placement="top-start"
                          popper-class="uploadptooltip"
                        >
                          <div slot="content">
                            <div>
                              {{
                                $t("PersonalEndLanguage.warningmessage41") +
                                user_info.video_daub_minute_limit +
                                $t("PersonalEndLanguage.warningmessage50")
                              }}
                            </div>
                          </div>
                          <svg-icon
                            icon-class="presentation"
                            class="icon"
                            style="cursor: pointer"
                          ></svg-icon> </el-tooltip
                      ></el-checkbox>
                      <!--              硬字幕压制-->
                      <el-checkbox
                        v-model="form9.checkbox2"
                        :disabled="form9.checkboxDisabled"
                        >{{ $t("PersonalEndLanguage.VideoDubbingText21") }}
                        <el-tooltip
                          placement="top-start"
                          popper-class="uploadptooltip"
                        >
                          <div slot="content">
                            <div>
                              {{
                                $t("PersonalEndLanguage.warningmessage41") +
                                user_info.video_daub_minute_limit +
                                $t("PersonalEndLanguage.warningmessage50")
                              }}
                            </div>
                          </div>
                          <svg-icon
                            icon-class="presentation"
                            class="icon"
                            style="cursor: pointer"
                          ></svg-icon> </el-tooltip
                      ></el-checkbox>
                    </div>
                    <div
                      style="display: flex; justify-content: space-between"
                      v-if="[2, 6].includes(project_id)"
                    >
                      <!--              硬字幕涂抹 高级-->
                      <el-checkbox
                        v-model="form9.checkbox3"
                        :disabled="form9.checkboxDisabled"
                        v-if="form9.checkbox1 && user_info.video_daub_mode == 1"
                        >{{ $t("PersonalEndLanguage.VideoDubbingText24") }}
                        <el-popover
                          placement="top"
                          trigger="hover"
                          :visible-arrow="false"
                        >
                          <div>
                            <div
                              style="color: #fff; white-space: pre-wrap"
                              v-html="
                                $t('PersonalEndLanguage.VideoDubbingText25')
                              "
                            ></div>
                            <div class="innerDialogPopover_div1">
                              <div>
                                <el-image
                                  :src="require('@/assets/imgs/tumo.jpg')"
                                  fit="fill"
                                  initial-index="0"
                                  :preview-src-list="srcList"
                                ></el-image>
                                <p class="btmp" style="word-break: break-word">
                                  {{ $t("PersonalEndLanguage.ViewExamples8") }}
                                </p>
                              </div>
                              <div>
                                <el-image
                                  :src="require('@/assets/imgs/tumo_1.jpg')"
                                  fit="fill"
                                  initial-index="1"
                                  :preview-src-list="srcList"
                                ></el-image>
                                <p class="btmp" style="word-break: break-word">
                                  {{ $t("PersonalEndLanguage.ViewExamples9") }}
                                </p>
                              </div>
                              <div>
                                <el-image
                                  :src="require('@/assets/imgs/tumo_2.jpg')"
                                  fit="fill"
                                  initial-index="2"
                                  :preview-src-list="srcList"
                                ></el-image>
                                <p class="btmp" style="word-break: break-word">
                                  {{ $t("PersonalEndLanguage.ViewExamples10") }}
                                </p>
                              </div>
                            </div>
                          </div>

                          <svg-icon
                            slot="reference"
                            icon-class="presentation"
                            class="icon"
                            style="cursor: pointer"
                          ></svg-icon> </el-popover
                      ></el-checkbox>
                    </div>
                  </div>
                  <div class="right" style="margin-left: 48px">
                    <div class="radio_tag_div_first_class">
                      <div class="uploadsrt uploadsrt1">
                        <!--                      配音语言-->
                        <p>{{ $t("PersonalEndLanguage.VideoDubbingText") }}</p>
                        <el-select
                          v-model="form9.videoLanguage"
                          filterable
                          :placeholder="$t('CommonName.Pleaseselect')"
                          class="selectclass"
                        >
                          <el-option
                            v-for="item in setpyLanguage"
                            :key="item.language_id"
                            :label="item.cname"
                            :value="item.language_id"
                          >
                          </el-option>
                        </el-select>
                      </div>
                      <div
                        class="uploadsrt radioClass"
                        style="height: 33px; margin: 16px 0"
                      >
                        <div class="uploadsrt radioClass">
                          <el-radio-group
                            v-model="form9.radio2"
                            @input="form9Radio2Change"
                          >
                            <!--                              语音转写-->
                            <el-radio label="1" border>{{
                              $t("PersonalEndLanguage.VideoDubbingText9")
                            }}</el-radio>
                            <!--                              硬字幕转写-->
                            <el-radio label="2" border>{{
                              $t("PersonalEndLanguage.VideoDubbingText10")
                            }}</el-radio>
                          </el-radio-group>
                        </div>
                      </div>
                      <div class="uploadsrt uploadsrt1">
                        <!--              源语言-->
                        <p>
                          {{ $t("MyFiles.sourceLanguage") }}
                        </p>
                        <el-select
                          v-model="form9.sourceLanguage"
                          filterable
                          :placeholder="$t('CommonName.Pleaseselect')"
                          class="selectclass"
                        >
                          <el-option
                            v-for="item in languageoptions"
                            :key="item.language_id"
                            :label="item.cname"
                            :value="item.language_id"
                          >
                          </el-option>
                        </el-select>
                      </div>
                      <!-- 解说类特殊类型 -->
                      <div
                        :class="'upload_right_div upload_right_div1'"
                        v-if="
                          [6].includes(project_id) &&
                          user_info.clone_dubbing_mode == 1
                        "
                      >
                        <el-checkbox v-model="form9.check_clone_mode">{{
                          $t("PersonalEndLanguage.VideoDubbingText26")
                        }}</el-checkbox>
                      </div>
                      <!-- 选择目标文件夹 -->
                      <div class="uploadsrt">
                        <div class="AssignFolder">
                          <p>{{ $t("CommonName.targetFolder") }}</p>
                          <el-checkbox v-model="isAddFolder">{{
                            $t("PersonalEndLanguage.AssignText2")
                          }}</el-checkbox>
                        </div>
                        <el-select
                          v-model="AddFolderID"
                          v-show="!isAddFolder"
                          clearable
                          filterable
                          :placeholder="$t('CommonName.Pleaseselect')"
                          class="selectclass"
                        >
                          <el-option
                            v-for="item in userFolderList"
                            :key="item.folder_id"
                            :label="item.folder_name"
                            :value="item.folder_id"
                          >
                          </el-option>
                        </el-select>
                        <el-input
                          class="selectclass"
                          v-model="AddFolderName"
                          :maxlength="60"
                          v-show="isAddFolder"
                        ></el-input>
                      </div>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              <!--     视频配音 批量上传SRT   -->
              <el-tab-pane
                :label="$t('PersonalEndLanguage.batchuploadSRT')"
                name="five"
              >
                <div
                  class="tag_left_third"
                  v-if="HardSubtitleRecognitionTab === 'five'"
                >
                  <el-upload
                    class="upload-demo"
                    drag
                    ref="upload"
                    :with-credentials="true"
                    :action="actionURL"
                    :name="uploadName"
                    :data="uploadData"
                    :before-upload="beforeUpload"
                    :on-change="uploadChange_five"
                    :on-exceed="uploadExceed_third"
                    :on-progress="uploadProgress"
                    :on-success="uploadSuccess"
                    :on-error="uploadError"
                    :auto-upload="false"
                    :show-file-list="false"
                    :limit="10"
                    :file-list="fileList1"
                    multiple
                  >
                    <div
                      class="uploaddiv"
                      slot="trigger"
                      @click="AudiotranscriptionClick_third(4)"
                      @mouseenter="AudiotranscriptionClick_third_enter"
                    >
                      <div v-if="filelist.length > 0" class="filelist_div">
                        <div
                          class="upload_table_div"
                          @click="
                            (e) => {
                              return e.stopPropagation();
                            }
                          "
                        >
                          <div class="videoDatanamediv_header">
                            <p
                              style="padding-left: 17px; box-sizing: border-box"
                            >
                              <!--                              视频标题-->
                              {{ $t("PersonalEndLanguage.videoTitle") }}
                            </p>
                            <p style="padding-left: 30px">
                              <!--                              SRT 文件-->
                              {{ $t("PersonalEndLanguage.SRTfile") }}
                            </p>
                            <p style="padding-left: 20px">
                              <!--                              SRT 语言-->
                              {{ $t("PersonalEndLanguage.SRTLanguage1") }}
                            </p>
                          </div>
                          <div class="videoDatanamediv_body_list">
                            <div
                              class="videoDatanamediv_body"
                              v-for="item in filelist"
                              :key="item.id"
                            >
                              <div class="left_div">
                                <div class="svgdivs">
                                  <svg-icon
                                    icon-class="video"
                                    class="icon"
                                  ></svg-icon>
                                </div>
                                <div
                                  class="body_p"
                                  :id="'tag' + item.id"
                                  @mouseover="onShowNameTipsMouseenter(item)"
                                >
                                  <el-tooltip
                                    class="item"
                                    effect="dark"
                                    :content="item.name"
                                    placement="top-start"
                                    :disabled="!item.showdropdown"
                                    :visible-arrow="false"
                                  >
                                    <span>{{ item.name }}</span>
                                  </el-tooltip>
                                </div>
                              </div>
                              <div class="center_input">
                                <el-input
                                  :placeholder="
                                    $t('PersonalEndLanguage.VideoDubbingText4')
                                  "
                                  v-model="item.srtinput"
                                  class="input-with-select srtUnput"
                                  @input="item.srtinput = ''"
                                >
                                  <el-upload
                                    slot="append"
                                    class="upload-demo"
                                    :ref="item.id + 'srtupload'"
                                    :action="
                                      $uploadURL + '/job/upload_srt_file'
                                    "
                                    name="srt_file"
                                    :with-credentials="true"
                                    :before-upload="srtbeforeUpload"
                                    :show-file-list="false"
                                    :on-success="srtuploadSuccess"
                                    :auto-upload="true"
                                    :limit="1"
                                  >
                                    <el-button
                                      @click="
                                        srtupload(
                                          item.id + 'srtupload',
                                          item,
                                          1
                                        )
                                      "
                                      type="text"
                                    >
                                      <div class="svgbtn">
                                        <svg-icon
                                          icon-class="upload"
                                          class="icon"
                                        ></svg-icon>
                                        <!--                              上传-->
                                        <div>
                                          {{ $t("PersonalEndLanguage.upload") }}
                                        </div>
                                      </div>
                                    </el-button>
                                  </el-upload>
                                </el-input>
                              </div>
                              <div class="right_select">
                                <el-select
                                  v-model="item.videoLanguage"
                                  filterable
                                  :placeholder="
                                    $t(
                                      'PersonalEndLanguage.PleaseselectSRTlanguage1'
                                    )
                                  "
                                  class="selectclass"
                                  @change="SelectChange"
                                >
                                  <el-option
                                    v-for="items in setpyLanguage"
                                    :key="items.language_id"
                                    :label="items.cname"
                                    :value="items.language_id"
                                  >
                                  </el-option>
                                </el-select>
                              </div>
                              <div class="last_delete">
                                <el-button
                                  type="text"
                                  @click="
                                    UploadListDeleteClick1($event, item, 3)
                                  "
                                >
                                  <svg-icon
                                    icon-class="delete"
                                    class="icon"
                                  ></svg-icon>
                                </el-button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="p_div">
                          <i class="el-icon-plus"></i>
                          <p style="color: #2f9efd">
                            <!--                            点击选择或拖动视频到这里-->
                            {{ $t("PersonalEndLanguage.DragVideo") }}
                          </p>
                        </div>
                        <!--                        <div class="svgdivs">-->
                        <!--                          <svg-icon-->
                        <!--                            :icon-class="videoData.icon"-->
                        <!--                            class="icon"-->
                        <!--                          ></svg-icon>-->
                        <!--                        </div>-->
                        <!--                        <p class="namep">{{ videoData.name }}</p>-->
                        <!--                        <p class="topp">重新上传</p>-->
                      </div>
                      <div v-else>
                        <i class="el-icon-plus"></i>
                        <p class="topp">
                          {{ $t("PersonalEndLanguage.DragVideo") }}
                        </p>
                        <p class="botp">
                          {{
                            $t("PersonalEndLanguage.SupportsMP4formatvideo") +
                            "≤" +
                            user_info.video_dubbing_filesize_limit +
                            "GB，" +
                            $t("PersonalEndLanguage.duration") +
                            "≤"
                          }}
                          <span>{{
                            user_info.video_dubbing_duration_limit
                          }}</span>
                          {{
                            $t("PersonalEndLanguage.minute") +
                            $t("PersonalEndLanguage.moreLangth1")
                          }}
                        </p>
                      </div>
                    </div>
                  </el-upload>
                  <!-- 选择目标文件夹 -->
                  <div class="uploadsrt">
                    <div class="AssignFolder">
                      <p>{{ $t("CommonName.targetFolder") }}</p>
                      <el-checkbox v-model="isAddFolder">{{
                        $t("PersonalEndLanguage.AssignText2")
                      }}</el-checkbox>
                    </div>
                    <el-select
                      v-model="AddFolderID"
                      v-show="!isAddFolder"
                      clearable
                      filterable
                      :placeholder="$t('CommonName.Pleaseselect')"
                      class="selectclass100"
                    >
                      <el-option
                        v-for="item in userFolderList"
                        :key="item.folder_id"
                        :label="item.folder_name"
                        :value="item.folder_id"
                      >
                      </el-option>
                    </el-select>
                    <el-input
                      class="selectclass100"
                      v-model="AddFolderName"
                      :maxlength="60"
                      v-show="isAddFolder"
                    ></el-input>
                  </div>
                </div>
              </el-tab-pane>
              <!--            借调  选择已上传视频-->
              <el-tab-pane
                :label="$t('PersonalEndLanguage.Selectuploadedvideo')"
                name="second"
                v-if="[6].includes(project_id)"
              >
                <div
                  class="tag_div radio_tag_div_class"
                  v-if="HardSubtitleRecognitionTab === 'second'"
                >
                  <div class="radioClass">
                    <el-radio-group
                      v-model="videoRadio"
                      @input="videoRadioChange"
                    >
                      <el-radio
                        v-for="i in videoList"
                        :key="i.job_id"
                        :label="i.job_id"
                        border
                      >
                        <div class="radioCenter">
                          <div
                            class="left"
                            :id="'tag' + i.job_id"
                            @mouseover="onShowNameTipsMouseenter(i)"
                          >
                            <el-tooltip
                              class="item"
                              effect="dark"
                              :content="i.job_name"
                              placement="top-start"
                              :disabled="!i.showdropdown"
                              :enterable="false"
                            >
                              <span>{{ i.job_name }}</span>
                            </el-tooltip>
                          </div>
                          <div class="right">{{ i.duration_time }}</div>
                        </div>
                      </el-radio>
                    </el-radio-group>
                  </div>
                  <div class="right">
                    <div class="uploadsrt uploadsrt1">
                      <p class="titlep">
                        <!--                        上传文件（.srt）-->
                        {{
                          $t("PersonalEndLanguage.uploadFile") +
                          $t("PersonalEndLanguage.uploadFile2")
                        }}
                      </p>
                      <!-- 上传字幕文件 -->
                      <el-input
                        v-model="form8.srtinput"
                        class="input-with-select srtUnput"
                        @input="form8.srtinput = ''"
                      >
                        <el-upload
                          slot="append"
                          class="upload-demo"
                          ref="srtupload"
                          :action="$uploadURL + '/job/upload_srt_file'"
                          name="srt_file"
                          :with-credentials="true"
                          :before-upload="srtbeforeUpload"
                          :show-file-list="false"
                          :on-success="srtuploadSuccess"
                          :auto-upload="true"
                          :limit="1"
                        >
                          <el-button
                            @click="srtupload('srtupload')"
                            type="text"
                          >
                            <div class="svgbtn">
                              <svg-icon
                                icon-class="upload"
                                class="icon"
                              ></svg-icon>
                              <!--                              上传-->
                              <div>{{ $t("PersonalEndLanguage.upload") }}</div>
                            </div>
                          </el-button>
                        </el-upload>
                      </el-input>
                    </div>
                    <div class="uploadsrt">
                      <!--                      配音语言-->
                      <p>{{ $t("PersonalEndLanguage.VideoDubbingText") }}</p>
                      <el-select
                        v-model="form8.videoLanguage"
                        filterable
                        :placeholder="$t('CommonName.Pleaseselect')"
                        class="selectclass"
                      >
                        <el-option
                          v-for="item in setpyLanguage"
                          :key="item.language_id"
                          :label="item.cname"
                          :value="item.language_id"
                        >
                        </el-option>
                      </el-select>
                    </div>
                    <div class="uploadsrt">
                      <!--                   任务名-->
                      <p>{{ $t("PersonalEndLanguage.youtubeTitle") }}</p>
                      <el-input
                        v-model="form8.media_file_name1"
                        class="input-with-select selectclass"
                      ></el-input>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              <!--             v-if="user_info.video_dubbing_youtube == '1'"     -->
              <el-tab-pane
                :label="$t('PersonalEndLanguage.Youtubevideo1')"
                name="four"
                v-if="user_info.video_dubbing_youtube == '1'"
              >
                <div
                  class="top_div_header"
                  v-if="HardSubtitleRecognitionTab == 'four'"
                >
                  <!--                 任务名称 -->
                  <p>{{ $t("PersonalEndLanguage.youtubeTitle") }}</p>
                  <p>{{ $t("PersonalEndLanguage.youtubeTitle1") }}</p>
                  <p>
                    {{
                      $t("PersonalEndLanguage.uploadFile") +
                      $t("PersonalEndLanguage.uploadFile2")
                    }}
                  </p>
                  <p>{{ $t("PersonalEndLanguage.SRTLanguage") }}</p>
                </div>
                <div
                  class="tag_div_youtube"
                  v-if="HardSubtitleRecognitionTab == 'four'"
                >
                  <div class="btm_div_body">
                    <div
                      v-for="item in form8.tableData"
                      :key="item.id"
                      class="tr_div"
                    >
                      <div class="name_div">
                        <!--                        任务名称-->
                        <el-input maxlength="80" v-model="item.name"></el-input>
                      </div>
                      <div class="url_div">
                        <!--                         youtube 视频地址-->
                        <el-input maxlength="50" v-model="item.url"></el-input>
                      </div>
                      <div class="uploadsrt child3_div">
                        <el-input
                          v-model="item.srtinput"
                          class="input-with-select srtUnput"
                          @input="item.srtinput = ''"
                        >
                          <el-upload
                            slot="append"
                            class="upload-demo"
                            :ref="item.id + 'srtupload'"
                            :action="$uploadURL + '/job/upload_srt_file'"
                            name="srt_file"
                            :with-credentials="true"
                            :before-upload="srtbeforeUpload"
                            :show-file-list="false"
                            :on-success="srtuploadSuccess"
                            :auto-upload="true"
                            :limit="1"
                          >
                            <el-button
                              @click="srtupload(item.id + 'srtupload', item, 2)"
                              type="text"
                            >
                              <div class="svgbtn">
                                <svg-icon
                                  icon-class="upload"
                                  class="icon"
                                ></svg-icon>
                                <!--                              上传-->
                                <div>
                                  {{ $t("PersonalEndLanguage.upload") }}
                                </div>
                              </div>
                            </el-button>
                          </el-upload>
                        </el-input>
                      </div>
                      <div class="uploadsrt child4_div">
                        <el-select
                          v-model="item.videoLanguage"
                          filterable
                          :popper-append-to-body="false"
                          :placeholder="
                            $t('PersonalEndLanguage.PleaseselectSRTlanguage')
                          "
                          class="selectclass"
                          @change="form8tableDataSelectChange"
                        >
                          <el-option
                            v-for="item in setpyLanguage"
                            :key="item.language_id"
                            :label="item.cname"
                            :value="item.language_id"
                          >
                          </el-option>
                        </el-select>
                        <el-button
                          type="text"
                          style="margin-left: 6px; font-size: 16px"
                          @click="deleteTr(item.id)"
                          v-if="form8.tableData.length > 1"
                        >
                          <svg-icon icon-class="delete"></svg-icon>
                        </el-button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="Add_More"
                  v-if="HardSubtitleRecognitionTab === 'four'"
                >
                  <el-button type="text" @click="addmoreClick">
                    + {{ $t("PersonalEndLanguage.youtubeText") }}
                  </el-button>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="$t('PersonalEndLanguage.youtubeText1')"
                    placement="right"
                    :enterable="false"
                    :visible-arrow="false"
                  >
                    <el-upload
                      class="upload-demo"
                      ref="youtubeUploadxlsx"
                      :with-credentials="true"
                      :on-change="youtube_uploadChange"
                      :auto-upload="false"
                      :show-file-list="false"
                      :limit="1"
                      action="#"
                    >
                      <el-button type="text" @click="clearupload"
                        >{{ $t("PersonalEndLanguage.youtubeText2") }}
                      </el-button>
                    </el-upload>
                  </el-tooltip>
                </div>
                <div class="btm_p">
                  <span
                    >YouTube
                    <span style="margin-right: 4px">{{
                      $t("PersonalEndLanguage.youtubeText3")
                    }}</span></span
                  >
                  <p>https://www.youtube.com/watch?v=5Dn9WRWOs4s</p>
                </div>
                <div class="btm_p">
                  <p>https://youtu.be/5Dn9WRWOs4s</p>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
        <div v-if="quotetype !== 0">
          <div class="dialogmessages">
            <div class="messagesp">
              <p v-if="quotetype == 1">
                {{ $t("PersonalEndLanguage.messages2") }}
              </p>
              <p v-if="quotetype == 2">
                {{ $t("PersonalEndLanguage.messages3") }}
              </p>
              <p v-if="quotetype == 3">
                {{ $t("PersonalEndLanguage.messages4") }}
              </p>
              <p v-if="quotetype == 4">
                {{ $t("PersonalEndLanguage.messages5") }}
              </p>
              <p v-if="quotetype == 5">
                {{ $t("PersonalEndLanguage.messages6") }}
              </p>
            </div>
            <el-button
              class="setpadding16 messagesbtn"
              type="warning"
              @click="gobuy"
            >
              <!--              去购买-->
              {{ $t("PersonalEndLanguage.gobuy") }}
            </el-button>
          </div>
        </div>
      </div>
    </DialogComVue>
    <div
      class="progressdiv"
      v-if="percentage > 0 && showChunk == '1'"
      v-loading.fullscreen.lock="loading"
      element-loading-custom-class="disaplaynoneicon"
      element-loading-background="rgba(0, 0, 0, 0.3)"
    >
      <el-progress type="circle" :percentage="percentage" :stroke-width="10" />
    </div>

    <div
      class="progressdiv"
      v-if="percentage > 0 && showChunk == '2'"
      v-loading.fullscreen.lock="loading"
      element-loading-custom-class="disaplaynoneicon"
      element-loading-background="rgba(0, 0, 0, 0.3)"
    >
      <el-progress
        type="circle"
        :percentage="percentage"
        :stroke-width="10"
      ></el-progress>
    </div>
    <div
      class="progressdiv"
      v-if="
        HardSubtitleRecognitionTab === 'five' &&
        filelist.length > 0 &&
        totalProgress > 0 &&
        totalProgress < 100
      "
      v-loading.fullscreen.lock="loading"
      element-loading-custom-class="disaplaynoneicon"
      element-loading-background="rgba(0, 0, 0, 0.2)"
    >
      <el-progress
        type="circle"
        :percentage="totalProgress"
        :stroke-width="10"
      ></el-progress>
    </div>
    <div
      class="progressdiv"
      v-if="
        (HardSubtitleRecognitionTab === 'third' ||
          HardSubtitleRecognitionTab === 'six') &&
        hardSubtitleRecognition_fileList.length > 0 &&
        totalProgress1 > 0 &&
        totalProgress1 < 100
      "
      v-loading.fullscreen.lock="loading"
      element-loading-custom-class="disaplaynoneicon"
      element-loading-background="rgba(0, 0, 0, 0.2)"
    >
      <el-progress
        type="circle"
        :percentage="totalProgress1"
        :stroke-width="10"
      ></el-progress>
    </div>
    <GiftBagbtndialogVue
      v-if="centerDialogVisible"
      :centerDialogVisible="centerDialogVisible"
      :centerDialogVisibleTime="centerDialogVisibleTime"
      @closeGiftBagbtndialog="closeGiftBagbtndialog"
    ></GiftBagbtndialogVue>
    <!-- 视频展示 -->
    <video
      id="videoPlayer"
      ref="videoPlayer"
      type="video/mp4"
      oncontextmenu="return false"
      v-show="false"
    ></video>
    <DialogComVue
      :dialogVisible="dialogGiftVisible"
      @determineClick="determinePackageClick"
      @changeDialogVisible="changeGiftDialogVisible"
      :width="'448px'"
      :top="'10%'"
      :title="$t('PersonalEndLanguage.GiftPackCodeRedemption')"
      :showfooter="true"
      :destroyonclose="true"
      v-if="dialogGiftVisible"
    >
      <div class="dialogGiftContents">
        <el-form
          :model="ruleForm"
          ref="ruleForm"
          label-width="70px"
          class="demo-ruleForm"
          label-position="top"
          :hide-required-asterisk="false"
          @keydown.enter.prevent
        >
          <!--          礼包码-->
          <el-form-item
            :label="$t('PersonalEndLanguage.Giftpackagecode')"
            prop="package_code"
            :rules="[
              {
                required: true,
                message: $t('PersonalEndLanguage.warningmessage28'),
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              v-model="ruleForm.package_code"
              :placeholder="$t('PersonalEndLanguage.warningmessage29')"
              class="topinput"
              maxlength="50"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
    </DialogComVue>
  </div>
</template>
<script>
import DialogComVue from "@/components/DialogCom.vue";
import GiftBagbtndialogVue from "@/components/GiftBagbtndialog.vue";
import { onShowNameTipsMouseenter } from "@/assets/js/Publicmethods";
import { fabric } from "fabric";
import {
  get_last_job,
  get_language,
  get_free_quate,
  get_last_job_status,
  get_user_quota_info,
  create_video_dubbing_job,
  create_asr_job,
  create_trans_job,
  check_srt_max_duration,
  create_video_dubbing_job1,
  // CreateVideoTranscription,
  get_video_dubbing_job_list,
  create_ocr_job,
  get_ocr_last_job,
  create_video_dubbing_job_tube,
  create_tube_ocr_job,
  create_sub_align_job,
  check_tube_video_info,
  create_video_dubbing_job_new,
  get_oss_policy,
  move_job_folder,
  get_package_code,
} from "@/utils/https";
import { showLoading, hideLoading } from "@/utils/loading";
import axios from "axios";
import request from "@/utils/request";
import { TimeHandle } from "@/api/setTime";
import {
  getStringToTime,
  getTimeString,
  getFileName,
  getFileNameUUID,
} from "@/utils/tools";
import $ from "jquery";
import * as XLSX from "xlsx";

export default {
  components: {
    DialogComVue,
    GiftBagbtndialogVue,
  },
  props: {},
  created() {
    // document.write("程序员节快乐");
    //   $emit子组件调用父组件的方法并传递数据
    // 变换
    this.$emit("header", true, true, true, true, true);
    // sessionStorage.setItem("lang", "cn");
    // this.$i18n.locale = "cn";
    // showLoading();
  },
  data() {
    return {
      youtubeWidth: 0, //youtube 原视频 尺寸
      youtubeHeight: 0, //youtube 原视频 尺寸
      youtubeHeight_new: 0, //youtube 页面显示 视频尺寸
      youtubeWidth_new: 0, //youtube 页面显示 视频尺寸
      goBackDialog: 0, // 字幕识别弹窗 点击取消 回到的初始页面
      project_id: 0, // 点击选中哪个模块 创建任务 1 视频转写 2 视频配音 3 文本配音 4 音频转写   6 视频克隆配音  7 硬字幕识别  8 字幕对齐 9 声纹定制
      playerVars: {
        controls: 0, // 去掉控制条
        showinfo: 0, // 去掉视频信息
        modestbranding: 1, // 去掉YouTube徽标
        playsinline: 1, // 在移动设备中内联播放
        autoplay: 0, // 禁用自动播放
        fs: 0, // 去掉全屏按钮
        rel: 0,
        enablejsapi: 0,
        disablekb: 1, //将参数值设置为 1 会导致播放器无法响应键盘控制键
        iv_load_policy: 3,
        // origin: `https://iiwork.wiimedia.video`,
      }, //youtube 参数
      showOpen: true, //是否开启遮罩 true 显示开启按钮 false 显示 关闭按钮
      youtubeId: "",
      sliderChange: true, //是否能拖拽 youtube 滚动条
      windowdev: 1,
      canvas: null, //canvas
      downPoint: null, // 按下鼠标时的坐标
      upPoint: null, // 松开鼠标时的坐标
      rect: null, //创建矩形
      videoDom: {}, //创建 视频对象
      slidervalue: 0, //当前步
      startTime: "", //片头时间点
      endTime: "", //片头时间点
      old_time: "", //记录 片头时间
      old_time1: "", //记录 片尾事件
      step: 0.01, //步长
      silderMax: 0, //总步长
      videoUrl: "", //内部弹窗 中的视频
      videoHeight: 0, //获取视频原始 高度
      videoTitle: "", //youtube 视频名称
      videoWidth: 0, //获取视频 原始高度
      formatmessage: true, //视频配音 上传文件 格式错误 提示标识
      sizemessage: true, //视频配音 上传文件 大小错误 提示标识
      langMessage: true, //视频配音 上传文件 名称长短 提示标识
      filesizemessage: true, //视频配音 上传文件 所占空间大小 提示标识
      fileSizeMB: 0, //视频配音 上传文件 大小
      uploadSuccessflag: true, //视频配音 上传文件 成功 只走一次提示
      currentItem: {}, //视频配音 上传srt文件 点击当前项
      filelist: [], //视频配音 批量上传 文件列表
      fileList1: [], //视频配音 批量上传 文件列表 数据操作用
      videoRadio: -1, // 视频配音选择 已有视频 jobid
      videoList: [], // 视频配音选择 已有视频列表
      uploadVideoTab: "first", // 视频配音 选择上传还是已有视频tab
      HardSubtitleRecognitionTab: "first", // 硬字幕识别 tab
      videotime: 0, //视频配音 获取视频时长
      srtmaxtime: 0, //视频配音 获取srt时长
      chunkSize: 20 * 1024 * 1024, // 每个分片大小为2MB
      totalChunks: 0, //总片数
      currentChunk: 0, //当前 片数
      chunkProgress: [], //上传进度
      showChunk: 0, //显示 单独进度条
      buttonLoading: false,
      showGiftBagbtns: true,
      centerDialogVisible: false,
      centerDialogVisibleTime: "",
      quotetype: 0,
      showClose: true, //是否显示 关闭按钮
      loading: false,
      percentage: 0,
      uploadName: "", //上传文件类型
      uploadData: {}, //上传文件 数据
      actionURL: "", //创建任务 路径
      videoData: {
        //获取上传文件
        name: "",
        icon: "",
        file: "",
      },
      title: "",
      form: {
        srtinput: "", //上传字幕文件 名称
        radio: "1", //转写方式
        videoLanguage: "", //视频语言
        checked: false,
        targetlanguage: "",
        radio2: "1", //语音转写 硬字幕识别
        activeName: "1", //是否有 源语言 srt 文件
        checkbox1: false, //是否硬字幕涂抹
        checkbox2: false, //是否硬字幕压制
        checkbox3: false, //是否硬字幕压制高级
        checkboxDisabled: true, //是否可以选择硬字幕涂抹与硬字幕压制
      },
      form1: {
        srtinput: "", //上传字幕文件 名称
        radio: "1", //转写方式
        videoLanguage: "", //视频语言
        checked: false,
        targetlanguage: "",
        radio2: "2", //语音转写 硬字幕识别
        activeName: "2", //是否有 源语言 srt 文件
        checkbox1: false, //是否硬字幕涂抹
        checkbox2: false, //是否硬字幕压制
        checkbox3: false, //是否硬字幕压制
        checkboxDisabled: true, //是否可以选择硬字幕涂抹与硬字幕压制
      },
      //视频AI字幕  批量srt
      form10: {
        checked: false,
        videoLanguage: "",
        targetlanguage: "",
        checkbox1: false, //是否硬字幕涂抹
        checkbox2: false, //是否硬字幕压制
        checkbox3: false, //是否硬字幕高级涂抹
        checkboxDisabled: true, //是否可以选择硬字幕涂抹与硬字幕压制
      },
      languageoptions: [], //视频语言下拉框数据
      languageoptions1: [], //克隆 视频配音 单独 语言 小模型
      languageoptions2: [], //克隆 视频配音 单独 语言 大模型
      dialogtitle: "",
      dialogVisible: false, //显示弹窗
      footerbtnName: "", //自定义弹窗 确定按钮
      innerVisible: false, //内部弹窗 开关
      screenWidth: 0,
      form3: {
        //字幕翻译
        srtinput: "", //上传字幕文件 名称
        sourcelanguage: "",
        targetlanguage: "",
      },
      form4: {
        // 音频转写
        videoLanguage: "",
      },
      form5: {
        // 硬字幕识别
        sourcelanguage: "",
        targetlanguage: "",
        youtubeNameInput: "", //youtube 视频名称
      },
      marks: {}, // 硬字幕识别 滑块 标记 时间范围
      hardSubtitleRecognition_fileList: [], //硬字幕识别 上传文件列表
      hardSubtitleRecognition_fileList1: [], //硬字幕识别 上传文件列表 操作数据用
      row_id: 0,
      form6: {
        // 字幕对齐
        videoLanguage: "",
        srtinput: "", //上传字幕文件 名称
        srturl: "",
      },
      form7: {
        //漫画快翻
        sourcelanguage: "",
        targetlanguage: "",
        textdirection: "0", //上传漫画横向纵向 0横向 1纵向
      },
      form8: {
        //视频配音
        srtinput: "", //上传字幕文件 名称
        srturl: "",
        videoLanguage: "", //视频语言||配音语言
        srtinput1: "", // 上传字幕文件（有源语言 srt 文件时）
        srturl1: "",
        srtmaxtime: 0, ///视频配音 获取srt时长
        srtmaxtime1: 0, ///视频配音 获取srt时长
        radio1: -1, //视频配音 是否上传配音srt radio
        radio2: "1", //语音转写 硬字幕识别
        radio3: "0", //选择 配音性别
        select2: "", //源语言
        select3: "2", //配音方式选择  多角色时 选择 角色数量
        activeName: "1", //视频配音 是否有 源语言 srt 文件
        tableData: [
          {
            name: "",
            url: "",
            videoLanguage: "", //视频语言
            srturl: "",
            srtinput: "", //上传字幕文件 名称.
          },
        ],
        checkbox1: false, //是否硬字幕涂抹
        checkbox2: false, //是否硬字幕压制
        checkbox3: false, //是否硬字幕压制高级
        audioName: "", //上传人声音轨文件显示名称
        dubbing_vocals_name: "", //上传人声音轨文件 名称
        dubbing_vocals_path: "", //上传人声音轨文件地址
        dubbing_vocals_time: 0, //上传人声音轨文件时长
        audioName1: "", //上传国际轨音轨文件显示名称
        dubbing_novocals_name: "", //上传国际轨音轨文件 名称
        dubbing_novocals_path: "", //上传国际轨音轨文件地址
        dubbing_novocals_time: 0, //上传国际轨音轨文件时长
        checkboxDisabled: true, //是否可以选择硬字幕涂抹与硬字幕压制
        media_file_name1: "", //借调模式时的新文件名
        check_clone_mode: false, //解说类型
      },
      form8Select3options: [], //配音方式选择  多角色时 选择 角色数量
      form9: {
        //批量上传视频配音
        videoLanguage: "", //配音语言
        sourceLanguage: "", //源语言
        radio2: "2", //1 语音转写  2硬字幕识别
        checkbox1: false, //是否硬字幕涂抹
        checkbox2: false, //是否硬字幕压制
        checkbox3: false, //是否硬字幕涂抹高级
        checkboxDisabled: true, //是否可以选择硬字幕涂抹与硬字幕压制
        check_clone_mode: false, //解说类视频
      },
      serviceList: [],
      // 任务列表数据
      imgData: [],
      timer: null,
      user_info: [],
      isMoveLeft: true,
      maxScaleX: 1,
      isMoveTop: true,
      maxScaleY: 1,
      hardSublangs: [
        "2",
        "1",
        "3",
        "8",
        "9",
        "5",
        "11",
        "13",
        "15",
        "17",
        "24",
        "25",
      ], //硬字幕识别支持的语言
      OSSUploadID: 0, //上传临时ID
      OSSUploadObj: {}, //上传临时对象
      fileTotalDuration: 0, //上传文件 总时长
      userFolderList: [], //用户文件夹列表
      isAddFolder: false, //是否添加文件夹
      AddFolderID: "", //选择文件夹ID
      AddFolderName: "", //添加文件夹名称
      srcList: [
        require("@/assets/imgs/tumo.jpg"),
        require("@/assets/imgs/tumo_1.jpg"),
        require("@/assets/imgs/tumo_2.jpg"),
      ],
      dialogGiftVisible: false,
      submit_loading: false,
      ruleForm: {
        package_code: "",
      },
    };
  },
  watch: {
    // filelist: {
    //   immediate: true,
    //   deep: true,
    //   handler(newData) {
    //     console.log(newData);
    //     const total = newData.reduce(
    //       (acc, progressobj) => acc + progressobj.percentage,
    //       0
    //     );
    //     this.percentage = Math.floor(total / newData.length);
    //   },
    // },
  },
  computed: {
    totalProgress() {
      const total = this.filelist.reduce(
        (acc, progressobj) => acc + progressobj.percentage,
        0
      );
      const total1 = this.filelist.reduce(
        (acc, progressobj) => acc + progressobj.size,
        0
      );
      return Math.floor((total / total1) * 100);
    },
    totalProgress1() {
      const total = this.hardSubtitleRecognition_fileList.reduce(
        (acc, progressobj) => acc + progressobj.percentage,
        0
      );
      const total1 = this.hardSubtitleRecognition_fileList.reduce(
        (acc, progressobj) => acc + progressobj.size,
        0
      );
      return Math.floor((total / total1) * 100);
    },
    /**
     * 设置最近文件 数据
     */
    setImageData() {
      let a = [];
      if (this.imgData.length >= 5) {
        if (this.screenWidth <= 1520) {
          for (let index = 0; index < 5; index++) {
            a.push(this.imgData[index]);
          }
        } else if (this.screenWidth > 1520 && this.screenWidth < 1680) {
          for (let index = 0; index < 6; index++) {
            a.push(this.imgData[index]);
          }
        } else if (this.screenWidth > 1680) {
          for (let index = 0; index < 7; index++) {
            a.push(this.imgData[index]);
          }
        }
        // console.log(a);
        return a;
      } else {
        return this.imgData;
      }
    },
    /**
     * 是否显示 查看更多
     */
    setLookMore() {
      let a = false;
      if (this.screenWidth <= 1520) {
        a = this.imgData.length > 5;
      } else if (this.screenWidth > 1520 && this.screenWidth < 1680) {
        a = this.imgData.length > 6;
      } else if (this.screenWidth > 1680) {
        // console.log(this.screenWidth);
        a = this.imgData.length > 7;
      }
      return a;
    },
    /**
     * 设置弹窗内 左边 上传 class
     */
    setLeftClass() {
      let a = "";
      if (
        [
          this.$t("PersonalEndLanguage.subtitling"),
          this.$t("PersonalEndLanguage.Videotranscription"),
          this.$t("PersonalEndLanguage.Videotranslation"),
        ].includes(this.dialogtitle)
      ) {
        if (this.form.checked) {
          a = "videoTranscription videoTranscription1";
        } else {
          a = "videoTranscription";
        }
      } else if (
        [this.$t("PersonalEndLanguage.ComicQuickFlip")].includes(
          this.dialogtitle
        )
      ) {
        a = "subtitletranslation";
      } else if (
        [
          this.$t("PersonalEndLanguage.Hardsubtitlerecognition"),
          this.$t("PersonalEndLanguage.Audiotranscription"),
          this.$t("PersonalEndLanguage.Subtitlealignment"),
          // this.$t("PersonalEndLanguage.Videotranslation"),
        ].includes(this.dialogtitle)
      ) {
        a = "Audiotranscription";
      }
      return a;
    },
    /**
     * 计算 上传进度
     * @returns {number|string}
     */
    overallProgress() {
      if (this.chunkProgress.length === 0) return 0;
      const sum = this.chunkProgress.reduce((acc, val) => acc + val, 0);
      console.log(((sum / this.chunkProgress.length) * 100).toFixed(2));
      return ((sum / this.chunkProgress.length) * 100).toFixed(2);
    },
    /**
     * 判断是否 显示loading
     * @returns {boolean}
     */
    showProgress() {
      return this.chunkProgress.length > 0;
    },
    /**
     * 过滤 硬字幕识别 源语言
     * @returns {*[]}
     */
    setLanguage() {
      return this.languageoptions.filter((i) => {
        return this.hardSublangs.includes(i.language_id);
      });
    },
    /**
     * 过滤 字幕对齐 源语言
     * @returns {*[]}
     */
    setLanguage1() {
      return this.languageoptions.filter((i) => {
        return ["1", "2", "13", "25"].includes(i.language_id);
      });
    },
    /**
     * 设置 配音方式 显隐
     * @returns {boolean}
     */
    setShow() {
      if (
        !this.form8.videoLanguage ||
        !this.videoData.name ||
        this.form8.radio1 === -1
      ) {
        return false;
      } else {
        if (this.form8.radio1 === "1") {
          return !(!this.form8.srtinput || !this.form8.srturl);
        } else {
          //   console.log(2222);
          if (this.form8.radio1 === -1) {
            return false;
          } else {
            if (this.form8.activeName === "1") {
              return !(
                !this.form8.srtinput1 ||
                !this.form8.srturl1 ||
                !this.form8.select2
              );
            } else {
              return this.form8.select2;
            }
          }
        }
      }
    },
    setpyLanguage() {
      return this.project_id === 9
        ? this.languageoptions1
        : this.project_id === 6
        ? this.languageoptions2
        : this.languageoptions;
    },
    //判断 识别时间是否显示
    isShowTime() {
      let show = true;
      if (this.HardSubtitleRecognitionTab == "first") {
        if (
          (this.form8.checkbox1 && this.form8.radio2 !== "2") ||
          (this.form.checkbox1 && this.form.radio2 !== "2")
        ) {
          show = false;
        }
      } else if (this.HardSubtitleRecognitionTab == "third") {
        if (
          (this.form9.checkbox1 && this.form9.radio2 !== "2") ||
          (this.form1.checkbox1 && this.form1.radio2 !== "2")
        ) {
          show = false;
        }
      } else if (this.HardSubtitleRecognitionTab == "six") {
        show = false;
      }
      return show;
    },
  },
  methods: {
    /**
     * 打开兑换码弹窗
     */
    handlePackageClick() {
      this.dialogGiftVisible = true;
    },
    /**
     *
     * 关闭兑换码弹窗
     */
    changeGiftDialogVisible(val) {
      this.dialogGiftVisible = val;
      this.$refs["ruleForm"].resetFields();
    },
    determinePackageClick() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          console.log("submit!");
          if (this.submit_loading == false) {
            this.submit_loading = true;
            get_package_code({
              action: "get_package_code",
              package_code: this.ruleForm.package_code,
              address: this.ruleForm.contact_lname,
              email: this.ruleForm.contact_email,
              tel: this.ruleForm.contact_text,
            }).then((res) => {
              this.submit_loading = false;
              if (!res) {
                return;
              }
              this.dialogGiftVisible = false;
              this.$messages("success", res.msg);
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /**
     * 直接创建 跳过每个视频都重新选框，全部使用当前框属性来创建
     */
    immediateClick() {
      let title = "";
      title = this.$t("ProjectManagement.hardSubtitleTip");
      this.$confirm(title, this.$t("VideoDubbing.comfirmTips1"), {
        confirmButtonText: this.$t("VideoDubbing.comfirmTips2"),
        cancelButtonText: this.$t("VideoDubbing.comfirmTips3"),
        cancelButtonClass: "el-button--info",
        iconClass: "none",
        closeOnClickModal: false,
        distinguishCancelAndClose: false,
        closeOnPressEscape: false,
      })
        .then(() => {
          if ([2, 6, 9].includes(this.project_id)) {
            // 下一个
            // if (this.form9.radio2 == "2") {
            //   let timeRegExp = /^([01][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])\.[0-9]{2}$/;
            //   if (this.startTime && !timeRegExp.test(this.startTime)) {
            //     return;
            //   }
            //   if (this.endTime && !timeRegExp.test(this.endTime)) {
            //     return;
            //   }
            //   let startTime = this.formattedTime(this.old_time);
            //   let endTime = this.formattedTime(this.old_time1);
            //   if (startTime && endTime) {
            //     if (startTime >= endTime) {
            //       this.$messages(
            //         "warning",
            //         this.$t("PersonalEndLanguage.timeMessage1")
            //       );
            //       return;
            //     }
            //   }
            //   // console.log(startTime, this.silderMax);
            //   if (startTime > this.silderMax) {
            //     this.old_time = "";
            //     return;
            //   }

            //   if (endTime > this.silderMax) {
            //     this.old_time1 = "";
            //     return;
            //   }
            // }
            let obj = this.hardSubtitleRecognition_fileList[this.row_id];
            obj.endTime = this.endTime;
            obj.startTime = this.startTime;
            obj.marks = this.marks;
            obj.silderMax = this.silderMax;
            obj.rect = this.rect;
            obj.videoHeight = this.videoHeight;
            obj.videoWidth = this.videoWidth;
            obj.position = this.setPositions(obj);

            // 创建任务 确定
            this.buttonLoading = true;
            this.hardSubtitleRecognition_fileList.forEach((i, index) => {
              i.position = obj.position;
              i.videoWidth = obj.videoWidth;
              i.videoHeight = obj.videoHeight;
              i.rect = obj.rect;
              i.endTime = obj.endTime;
              i.startTime = obj.startTime;
              console.log(i);
              this.uploadOSSBatchMethod(1, i, 1);
            });
            return;
          } else if (this.project_id == 1) {
            // 下一个
            // if (this.form1.radio2 == "2") {
            //   let timeRegExp = /^([01][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])\.[0-9]{2}$/;
            //   if (this.startTime && !timeRegExp.test(this.startTime)) {
            //     return;
            //   }
            //   if (this.endTime && !timeRegExp.test(this.endTime)) {
            //     return;
            //   }
            //   let startTime = this.formattedTime(this.old_time);
            //   let endTime = this.formattedTime(this.old_time1);
            //   if (startTime && endTime) {
            //     if (startTime >= endTime) {
            //       this.$messages(
            //         "warning",
            //         this.$t("PersonalEndLanguage.timeMessage1")
            //       );
            //       return;
            //     }
            //   }
            //   // console.log(startTime, this.silderMax);
            //   if (startTime > this.silderMax) {
            //     this.old_time = "";
            //     return;
            //   }

            //   if (endTime > this.silderMax) {
            //     this.old_time1 = "";
            //     return;
            //   }
            // }
            let obj = this.hardSubtitleRecognition_fileList[this.row_id];
            obj.endTime = this.endTime;
            obj.startTime = this.startTime;
            obj.marks = this.marks;
            obj.silderMax = this.silderMax;
            obj.rect = this.rect;
            obj.videoHeight = this.videoHeight;
            obj.videoWidth = this.videoWidth;
            obj.position = this.setPositions(obj);
            let id = this.HardSubtitleRecognitionTab == "six" ? 5 : 3;
            // 创建任务 确定
            this.buttonLoading = true;
            this.hardSubtitleRecognition_fileList.forEach((i, index) => {
              i.position = obj.position;
              i.videoWidth = obj.videoWidth;
              i.videoHeight = obj.videoHeight;
              i.rect = obj.rect;
              i.endTime = obj.endTime;
              i.startTime = obj.startTime;
              // console.log(i);
              this.uploadOSSBatchMethod(id, i, 1);
            });
          } else if (this.project_id == 7) {
            //硬字幕识别
            // 下一个
            // let timeRegExp = /^([01][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])\.[0-9]{2}$/;
            // if (this.startTime && !timeRegExp.test(this.startTime)) {
            //   return;
            // }
            // if (this.endTime && !timeRegExp.test(this.endTime)) {
            //   return;
            // }
            // let startTime = this.formattedTime(this.old_time);
            // let endTime = this.formattedTime(this.old_time1);
            // if (startTime && endTime) {
            //   if (startTime >= endTime) {
            //     this.$messages(
            //       "warning",
            //       this.$t("PersonalEndLanguage.timeMessage1")
            //     );
            //     return;
            //   }
            // }
            // // console.log(startTime, this.silderMax);
            // if (startTime > this.silderMax) {
            //   this.old_time = "";
            //   return;
            // }
            // if (endTime > this.silderMax) {
            //   this.old_time1 = "";
            //   return;
            // }
            let obj = this.hardSubtitleRecognition_fileList[this.row_id];
            obj.endTime = this.endTime;
            obj.startTime = this.startTime;
            obj.marks = this.marks;
            obj.silderMax = this.silderMax;
            obj.rect = this.rect;
            obj.videoHeight = this.videoHeight;
            obj.videoWidth = this.videoWidth;
            obj.position = this.setPositions(obj);
            // console.log("obj", obj);
            // console.log(this.row_id, this.hardSubtitleRecognition_fileList);
            // 创建任务 确定
            this.buttonLoading = true;
            this.hardSubtitleRecognition_fileList.forEach((i, index) => {
              i.position = obj.position;
              i.videoWidth = obj.videoWidth;
              i.videoHeight = obj.videoHeight;
              i.endTime = obj.endTime;
              i.startTime = obj.startTime;
              i.rect = obj.rect;
              // console.log(i);
              this.uploadOSSBatchMethod(2, i, 1);
            });
            // console.log(this.videoDom, "this.videoDom");
          }
          // console.log(this.row_id, this.form.hardSubtitleRecognition_fileList);
        })
        .catch((action) => {
          if (action === "cancel") {
            console.log("cancel");
          }
        });
    },
    /**
     * 硬字幕涂抹勾选变化
     */
    checkboxChange(mode, type) {},
    /**
     * 检查文件夹名称是否重复
     */
    check_user_folder_name() {
      return move_job_folder({
        action: "check_user_folder",
        name: this.AddFolderName.trim(),
      }).then((res) => {
        return res;
      });
    },
    /**
     * 获取用户文件夹列表
     */
    get_user_folder_list() {
      move_job_folder({
        action: "get_user_folder_list",
      }).then((res) => {
        this.userFolderList = res.list;
      });
    },
    /**
     * 视频AI 上传之前 验证是否满足上传条件  change 事件
     * @param file
     * @param fileList
     */
    videoAI_change_third(file, fileList) {
      if (!fileList || !file) return;
      this.hardSubtitleRecognition_fileList1 = this.setUploadListData_change(
        file,
        this.hardSubtitleRecognition_fileList,
        1
      );
    },
    /**
     * 硬字幕识别 删除 上传列表中的某条
     * @param e
     * @param item
     */
    videoAI_Delete_Click(e, item) {
      this.hardSubtitleRecognition_fileList = this.setUploadListData_delete(
        this.hardSubtitleRecognition_fileList,
        item,
        1
      );
      e.stopPropagation();
    },
    // ---------------------------------------canvas---------------------------------------
    initCanvas(obj, row) {
      if (this.canvas) {
        this.canvas.setWidth(1);
        this.canvas.dispose();
        this.canvas = null;
      }
      this.canvas = new fabric.Canvas("canvas");
      if (
        this.HardSubtitleRecognitionTab === "first" ||
        this.HardSubtitleRecognitionTab === "six" ||
        this.HardSubtitleRecognitionTab === "third"
      ) {
        this.canvas.setWidth(this.videoDom.clientWidth);
      } else {
        this.canvas.setWidth(
          this.youtubeWidth_new === 0 ? 640 : this.youtubeWidth_new
        );
        console.log(this.youtubeHeight_new, "this.youtubeHeight_new");
        this.canvas.wrapperEl.style.top =
          this.youtubeHeight_new !== 0
            ? ((360 - this.youtubeHeight_new) / 2).toFixed() + "px"
            : 0;
        this.canvas.wrapperEl.style.left =
          this.youtubeWidth_new !== 0
            ? ((888 - this.youtubeWidth_new) / 2).toFixed() + "px"
            : ((888 - 640) / 2).toFixed() + "px";
      }
      this.canvas.wrapperEl.style.position = "absolute";
      // this.canvas.off("mouse:down").on("mouse:down", this.canvasMouseDown); // 鼠标在画布上按下
      // this.canvas.off("mouse:up").on("mouse:up", this.canvasMouseUp); // 鼠标在画布上松开
      // if (this.windowdev > 1) {
      //   this.canvas.clear();
      // }
      this.createRect(obj);
    },
    /**
     *  开启遮罩
     * @param data
     * @constructor
     */
    OpenCanvas() {
      if (this.rect) {
        return;
      }
      // console.log(2222222);
      this.initCanvas(); //创建canvas
      this.showOpen = false;
    },
    /**
     * 关闭遮罩
     * @param
     */
    closeCanvas() {
      this.canvas.setWidth(1);
      this.canvas.dispose();
      this.canvas = null;
      this.rect = null;
      this.showOpen = true;
    },
    /**
     *  创建 canvas 矩形
     * @param obj
     */
    createRect(obj) {
      let left = 0;
      let videoDom = {};
      let option = {
        top: 0,
        left: 0,
        width: 0,
        height: 0,
        // fill: "transparent", // 填充色：透明
        fill: "blue", // 填充色：透明
        // fill: "rgba(255,255,255,0.1)",
        stroke: "#46adff", // 边框颜色：黑色
        strokeWidth: 0.5, // 边框宽度
        // strokeUniform: true,
        cornerSize: 5,
        cornerColor: "#fff",
        lockRotation: true,
        strokeDashArray: [5, 7],
      };
      if (
        this.HardSubtitleRecognitionTab === "first" ||
        this.HardSubtitleRecognitionTab === "six" ||
        this.HardSubtitleRecognitionTab === "third"
      ) {
        videoDom = this.videoDom;
        if (+obj.position_w + +obj.position_x > videoDom.clientWidth) {
          left = this.canvas.width / 2 - this.canvas.width / 4;
        }
        option.top = Object.values(obj).every((i) => i == "0")
          ? this.canvas.height - 100
          : Number(obj.position_y);
        option.left = Object.values(obj).every((i) => i == "0")
          ? this.canvas.width / 2 - this.canvas.width / 4
          : +obj.position_w + +obj.position_x > videoDom.clientWidth
          ? this.canvas.width / 2 - this.canvas.width / 4
          : Number(obj.position_x);
        option.width = Object.values(obj).every((i) => i == "0")
          ? this.canvas.width / 2
          : +obj.position_w > videoDom.clientWidth
          ? this.canvas.width / 2
          : Number(obj.position_w);
        option.height = Object.values(obj).every((i) => i == "0")
          ? 40
          : Number(obj.position_h);
      } else {
        videoDom = this.$refs.youtubePlayer;
        option.top = this.canvas.height - 50;
        option.left = this.canvas.width / 2 - this.canvas.width / 4;
        option.width = this.canvas.width / 2;
        option.height = 40;
        // option.top = 278;
        // option.left = 159;
        // option.width = 320;
        // option.height = 40;
      }
      // console.log(this.videoDom.clientHeight, this.videoDom.clientWidth);
      // console.log(obj);

      // 矩形对象
      this.rect = new fabric.Rect(option);
      this.canvas.add(this.rect);
      const mask = new fabric.Rect({
        left: 0,
        top: 0,
        width: this.canvas.width,
        height: this.canvas.height,
        // backgroundVpt: false,
        // height: 370,
        fill: "rgba(0,0,0,0.4)",
        // fill: "rgba(255,255,255,0.5)",
        selectable: false,
      });

      // 将矩形添加到画布上

      this.canvas.add(mask);
      // mask.globalCompositeOperation = "source-over";
      // mask.globalCompositeOperation = "destination-out";
      this.rect.globalCompositeOperation = "destination-out";
      // this.rect.globalCompositeOperation = "source-over";
      this.canvas.bringToFront(this.rect);
      this.canvas.setActiveObject(this.rect);
      this.canvas.selection = true; // 允许框选
      this.canvas.selectionColor = "rgba(100, 100, 255, 0.3)"; // 选框填充色：半透明的蓝色
      this.canvas.selectionBorderColor = "rgba(255, 255, 255, 0.3)"; // 选框边框颜色：半透明灰色
      this.canvas.skipTargetFind = false; // 允许选中
      this.canvas.on("selection:cleared", () => {
        this.canvas.setActiveObject(this.rect);
      });
      // 监听 矩形移动事件
      this.canvas.off("object:moving").on("object:moving", (e) => {
        this.isMoveLeft = true;
        this.isMoveTop = true;
        var target = e.target;
        var canvasWidth = this.canvas.width;
        var canvasHeight = this.canvas.height;
        var left = target.left;
        var top = target.top;
        var maxLeft = canvasWidth - target.width * target.scaleX;
        var maxTop = canvasHeight - target.height * target.scaleY;
        if (left < 0) {
          target.left = 3;
        } else if (left > maxLeft) {
          target.left = maxLeft - 3;
        }
        if (top < 0) {
          target.top = 3;
        } else if (top > maxTop) {
          target.top = maxTop - 3;
        }
      });
      this.rect.off("scaling").on("scaling", (e) => {
        var rect = e.transform.target;
        // 获取矩形的当前位置和大小
        var left = rect.left;
        var top = rect.top;
        var width = rect.width * rect.scaleX;
        var height = rect.height * rect.scaleY;
        // 获取画布的尺寸
        var canvasWidth = this.canvas.width;
        var canvasHeight = this.canvas.height;

        // 计算矩形边界
        var right = left + width;
        var bottom = top + height;
        // 检查是否超出画布边界，如果是，则限制大小

        if (height < 10) {
          rect.scaleY = 10 / rect.height;
        }
        if (width < 10) {
          rect.scaleX = 10 / rect.width;
        }
        if (left > 0 && right < canvasWidth) {
          this.isMoveLeft = true;
        }
        if (top > 0 && bottom < canvasHeight) {
          this.isMoveTop = true;
        }
        if (left < 0) {
          rect.left = 3;
          if (this.isMoveLeft) {
            rect.scaleX = (width - 3 + left) / rect.width;
            this.maxScaleX = rect.scaleX;
            this.isMoveLeft = false;
          } else {
            // console.log(this.maxScaleX, "maxScaleX");
            rect.scaleX = this.maxScaleX;
          }
        }

        if (right > canvasWidth) {
          rect.scaleX = (canvasWidth - 5 - left) / rect.width;
        }
        if (top < 0) {
          rect.top = 3;
          if (this.isMoveTop) {
            rect.scaleY = (height - 3 + top) / rect.height;
            this.maxScaleY = rect.scaleY;
            this.isMoveTop = false;
          } else {
            rect.scaleY = this.maxScaleY;
          }
        }
        if (bottom > canvasHeight) {
          rect.scaleY = (canvasHeight - 3 - top) / rect.height;
        }
      });
    },
    //-------------------------------------------------------------------------------------
    onShowNameTipsMouseenter(i) {
      this.$forceUpdate();
      onShowNameTipsMouseenter(i);
    },
    /**
     * 判断是否 选择了语言 如果没选择 给语言赋值
     * @param select
     */
    SelectChange(select) {
      this.$forceUpdate();
      if (this.project_id === 7) {
        this.hardSubtitleRecognition_fileList.forEach((i) => {
          if (!i.videoLanguage) {
            i.videoLanguage = select;
          }
        });
      } else {
        this.hardSubtitleRecognition_fileList.forEach((i) => {
          if (!i.videoLanguage) {
            i.videoLanguage = select;
          }
        });
        this.filelist.forEach((i) => {
          if (!i.videoLanguage) {
            i.videoLanguage = select;
          }
        });
      }
    },
    /**
     *视频配音 选择 已有 还是新上传 tab first 表示 上传 second 表示 选择已有
     * @param tab
     */
    uploadVideoHandleClick() {
      // console.log(this.HardSubtitleRecognitionTab);
      let private_job_type = 9;
      if (this.project_id == 6) {
        private_job_type = 11;
      } else if (this.project_id == 9) {
        private_job_type = 10;
      }
      if (this.HardSubtitleRecognitionTab === "second") {
        get_video_dubbing_job_list({
          action: "get_video_dubbing_job_list",
          private_job_type: private_job_type,
        }).then((res) => {
          if (!res) {
            this.videoList = [];
            return;
          }
          // console.log(res);
          res.list.forEach((i) => {
            i.showdropdown = false;
          });
          this.videoList = res.list;
        });
      }
    },
    /**
     * 硬字幕识别 tab切换
     * @param data
     * @constructor
     */
    HardSubtitleRecognitionTabClick() {},
    /**
     * youtube 视频 失去焦点 验证视频是否可用
     * @param val
     */
    youtubeInputBlur(val) {},
    /**
     * 去购买
     */
    gobuy() {
      let pathInfo = this.$router.resolve({
        path: "/ContainerHome/PurchaseServices",
      });
      window.open(pathInfo.href, "_blank");
    },
    /**
     *
     * @param {* 设置默认图片}
     */
    setImageUrl(item) {
      // console.log(item);
      if (["1"].includes(item.private_job_type)) {
        // 视频转写
        return require("@/assets/imgs/img_shipinzhuanxie.png");
      } else if (item.private_job_type === "2") {
        // 视频翻译
        return require("@/assets/imgs/img_shipinfanyi.png");
      } else if (item.private_job_type === "8") {
        // 字幕对齐
        return require("@/assets/imgs/img_zimuduiqi.png");
      } else if (item.private_job_type === "3") {
        // 字幕翻译
        return require("@/assets/imgs/img_zimu.png");
      } else if (item.private_job_type === "4") {
        // 音频转写
        return require("@/assets/imgs/img_audio.png");
      } else if (item.private_job_type === "6") {
        // AI配音-文本
        return require("@/assets/imgs/img_AIdubbing.png");
      } else if (item.private_job_type === "5") {
        // 漫画 快翻
        return require("@/assets/imgs/img_manhua.png");
      } else if (item.private_job_type === "9") {
        //  Al配音-视频
        return require("@/assets/imgs/shipinpeyi_img.png");
      } else if (item.private_job_type === "7") {
        //7 硬字幕识别
        return require("@/assets/imgs/img_yingzimuzhuanxie.png");
      } else if (item.private_job_type === "10") {
        // 视频配音  克隆小
        return require("@/assets/imgs/img_clone.png");
      } else if (item.private_job_type === "11") {
        //  视频配音 克隆大
        return require("@/assets/imgs/img_colone_damoxing.png");
      }
    },
    /**
     *
     * @param {*关闭领取 弹窗} val
     */
    closeGiftBagbtndialog(val) {
      this.centerDialogVisible = val;
      this.showGiftBagbtns = false;
      window.location.reload();
    },
    /**
     * 点击领取本月免费礼包
     */
    showGiftBagbtn() {
      get_free_quate({
        action: "get_free_quate",
      }).then((res) => {
        if (!res) {
          return;
        }
        this.centerDialogVisibleTime = res.time;
        this.centerDialogVisible = true;
      });
    },
    /**
     * 初始化 数据
     */
    initData() {
      this.form = {
        srtinput: "", //上传字幕文件 名称
        radio: "1", //转写方式
        videoLanguage: "", //视频语言
        srturl: "", //上传字幕文件 路径
        targetlanguage: "",
        checked: false,
        radio2: "1", //语音转写 硬字幕识别
        activeName: "1", //是否有 源语言 srt 文件
        checkbox1: false, //是否硬字幕涂抹
        checkbox2: false, //是否硬字幕压制
        checkbox3: false, //是否硬字幕压制
        checkboxDisabled: true, //是否可以选择硬字幕涂抹与硬字幕压制
      };
      this.form1 = {
        srtinput: "", //上传字幕文件 名称
        radio: "1", //转写方式
        videoLanguage: "", //视频语言
        srturl: "", //上传字幕文件 路径
        targetlanguage: "",
        checked: false,
        radio2: "2", //语音转写 硬字幕识别
        activeName: "2", //是否有 源语言 srt 文件
        checkbox1: false, //是否硬字幕涂抹
        checkbox2: false, //是否硬字幕压制
        checkbox3: false, //是否硬字幕压制
        checkboxDisabled: true, //是否可以选择硬字幕涂抹与硬字幕压制
      };
      this.form4 = {
        // 音频转写
        videoLanguage: "",
      };
      this.form5 = {
        // 硬字幕识别
        sourcelanguage: "",
        targetlanguage: "",
        youtubeNameInput: "",
      };
      this.slidervalue = 0;
      this.startTime = "";
      this.endTime = "";
      this.marks = {};
      this.form6 = {
        // 字幕对齐
        videoLanguage: "",
        srtinput: "", //上传字幕文件 名称
        srturl: "",
      };
      this.form7 = {
        //漫画快翻
        sourcelanguage: "",
        targetlanguage: "",
        textdirection: "0",
      };
      this.form8 = {
        //视频配音
        srtinput: "", //上传字幕文件 名称
        videoLanguage: "", //视频语言
        srturl: "",
        name: "",
        url: "",
        srtinput1: "", // 上传字幕文件（有源语言 srt 文件时）
        srturl1: "",
        srtmaxtime: 0, ///视频配音 获取srt时长
        srtmaxtime1: 0, ///视频配音 获取srt时长
        radio1: -1, //视频配音 是否上传配音srt radio
        radio2: "1",
        radio3: "0", //选择 配音性别
        select2: "", //源语言
        select3: "2",
        activeName: "1",
        tableData: [
          {
            id: 0,
            name: "",
            url: "",
            videoLanguage: "", //视频语言
            srturl: "",
            srtinput: "", //上传字幕文件 名称
          },
        ],
        checkbox1: false, //是否硬字幕涂抹
        checkbox2: false, //是否硬字幕压制
        checkbox3: false, //是否硬字幕压制
        audioName: "", //上传人声音轨文件 名称
        dubbing_vocals_name: "", //上传人声音轨文件 名称
        dubbing_vocals_path: "", //上传人声音轨文件地址
        dubbing_vocals_time: 0, //上传人声音轨文件时长
        audioName1: "", //上传国际轨音轨文件 名称
        dubbing_novocals_name: "", //上传国际轨音轨文件 名称
        dubbing_novocals_path: "", //上传国际轨音轨文件地址
        dubbing_novocals_time: 0, //上传国际轨音轨文件时长
        checkboxDisabled: true,
        media_file_name1: "",
        check_clone_mode: false,
      };
      this.form9 = {
        //批量上传视频配音
        videoLanguage: "", //配音语言
        sourceLanguage: "", //源语言
        radio2: "2", //语音转写 硬字幕识别
        checkbox1: false, //是否硬字幕涂抹
        checkbox2: false, //是否硬字幕压制
        checkbox3: false, //是否硬字幕压制
        checkboxDisabled: true, //是否可以选择硬字幕涂抹与硬字幕压制
        check_clone_mode: false, //解说类视频
      };
      this.filelist = [];
      this.fileList1 = [];
      this.fileSizeMB = 0;
      this.fileTotalDuration = 0;
      this.hardSubtitleRecognition_fileList = [];
      this.hardSubtitleRecognition_fileList1 = [];
      this.row_id = 0;
      this.videoData = {
        name: "",
        icon: "",
        file: "",
      };
      this.quotetype = 0;
      this.uploadVideoTab = "first";
      this.HardSubtitleRecognitionTab = "first";
      this.isAddFolder = false;
      this.AddFolderID = "";
      this.AddFolderName = "";
    },
    /**
     * 获取语言接口
     */
    get_language(mode) {
      get_language({
        action: "get_language",
        lang:
          sessionStorage.getItem("lang") === "cn" ||
          sessionStorage.getItem("lang") === null
            ? 1
            : 2,
        tts_mode: mode ? mode : 0,
      }).then((res) => {
        if (res) {
          if (mode === 1) {
            this.languageoptions1 = res.list;
          } else if (mode === 2) {
            this.languageoptions2 = res.list;
          } else {
            this.languageoptions = res.list;
          }
        }
      });
    },
    /**
     * 获取最近任务文件信息
     */
    get_last_job() {
      return get_last_job({
        action: "get_last_job",
      }).then((res) => {
        if (res) {
          let ids = [];
          res.list.forEach((i) => {
            i.showdropdown = false;
            i.id = i.job_private_id;
            if (
              ["1", "2", "3", "4", "7", "8", "9", "10", "11"].includes(
                i.private_job_type
              )
            ) {
              if (Number(i.job_status) < 20) {
                ids.push(i.job_private_id);
                i.showLoadingnow = true;
                i.progress = 0;
              } else {
                i.showLoadingnow = false;
              }
            }
          });

          if (ids.length > 0) {
            // console.log(11111);
            this.getTranscribingJobs(ids);
          } else {
            clearTimeout(this.timer);
          }
          this.imgData = res.list;
          hideLoading();
        }
      });
    },

    /**
     * 点击查看更多
     */
    seemore() {
      sessionStorage.setItem("activeName", "second");
      this.$router.push("/ContainerHome/MyFiles");
    },
    /**
     *
     * @param {*点击 最近文件 跳转页面} item
     */
    imageClick(item) {
      // console.log(item);
      if (item.showLoadingnow) {
        return;
      }
      if (["1", "2", "3", "7", "8"].includes(item.private_job_type)) {
        let pathInfo = this.$router.resolve({
          path: "/ContainerHome/VideoDetails",
          query: {
            job_id: item.job_private_id,
          },
        });
        window.open(pathInfo.href, "_blank");
      } else if (item.private_job_type == "5") {
        window.open(
          `${this.$AnimationFlipURL + "?job_id=" + item.job_private_id}&role=1`,
          "_blank"
        );
      } else if (item.private_job_type == "4") {
        let pathInfo = this.$router.resolve({
          path: "/AudioInfo",
          query: {
            jid: item.job_private_id,
          },
        });
        window.open(pathInfo.href, "_blank");
      } else if (item.private_job_type == "6") {
        let pathInfo = this.$router.resolve({
          path: "/DubbingInfo",
          query: {
            jid: item.job_private_id,
          },
        });
        window.open(pathInfo.href, "_blank");
      } else if (
        item.private_job_type === "10" ||
        item.private_job_type === "11"
      ) {
        let pathInfo = this.$router.resolve({
          path: "/ContainerHome/VideoDubbingClone",
          query: {
            jid: item.job_private_id,
          },
        });
        window.open(pathInfo.href, "_blank");
      } else {
        // console.log(item.private_job_type);
        // 为9 的时候 跳转 视频配音页面
        let pathInfo = this.$router.resolve({
          path: "/ContainerHome/VideoDubbing",
          query: {
            jid: item.job_private_id,
          },
        });
        window.open(pathInfo.href, "_blank");
      }
    },
    /**
     *
     * @param {*弹窗关闭} val
     */
    changeDialogVisible(val) {
      this.dialogVisible = val;
      this.buttonLoading = val; //关闭 loading
      this.initData();
    },
    /**
     * 设置 矩形
     * @param obj
     */
    setPositions(obj) {
      let position = {};
      position.position_h = obj.rect.height * obj.rect.scaleY;
      position.position_w = obj.rect.width * obj.rect.scaleX;
      position.position_x = obj.rect.left;
      position.position_y = obj.rect.top;
      return position;
    },
    /**
     * 内部弹窗 关闭弹窗 上一步
     * @param data
     * @constructor
     */
    CloseinnerVisible(data) {
      if (data === 1) {
        this.innerVisible = false;
        if (this.project_id === 2) {
          // 回退到 视频配音 页面
          this.dialogtitle = this.$t("PersonalEndLanguage.Videodubbing");
        } else if (this.project_id === 9) {
          this.dialogtitle = this.$t(
            "PersonalEndLanguage.VideoCloningDubbingsmall"
          );
        } else if (this.project_id === 6) {
          this.dialogtitle = this.$t("PersonalEndLanguage.VideoCloningDubbing");
        } else if (this.project_id === 7) {
          // this.HardSubtitleRecognitionTab = "first";
          this.dialogtitle = this.$t(
            "PersonalEndLanguage.Hardsubtitlerecognition"
          );
          this.showOpen = true;
          this.row_id = 0;
        } else if (this.project_id === 1) {
          // 回退到 视频AI字幕 页面
          this.dialogtitle = this.$t("PersonalEndLanguage.Videotranscription");
        }
        this.closeCanvas();
      } else {
        if (
          this.HardSubtitleRecognitionTab !== "third" &&
          this.HardSubtitleRecognitionTab !== "six"
        ) {
          if (this.project_id === 1) {
            // 回退到 视频AI字幕 页面
            this.innerVisible = false;
            this.closeCanvas();
            this.dialogtitle = this.$t(
              "PersonalEndLanguage.Videotranscription"
            );
          } else if ([6, 9, 2].includes(this.project_id)) {
            // 回退到 视频配音 页面
            this.innerVisible = false;
            this.closeCanvas();
            if (this.project_id === 2) {
              this.dialogtitle = this.$t("PersonalEndLanguage.Videodubbing");
            }
            if (this.project_id === 9) {
              this.dialogtitle = this.$t(
                "PersonalEndLanguage.VideoCloningDubbingsmall"
              );
            }
            if (this.project_id === 6) {
              this.dialogtitle = this.$t(
                "PersonalEndLanguage.VideoCloningDubbing"
              );
            }
          } else {
            this.innerVisible = false;
            // this.HardSubtitleRecognitionTab = "first";
            this.dialogtitle = this.$t(
              "PersonalEndLanguage.Hardsubtitlerecognition"
            );
            this.showOpen = true;
            if (this.canvas) {
              this.closeCanvas();
            }
          }
        } else {
          if (this.hardSubtitleRecognition_fileList.length === 1) {
            if (this.project_id === 1) {
              this.innerVisible = false;
              this.dialogtitle = this.$t(
                "PersonalEndLanguage.Videotranscription"
              );
              this.showOpen = true;
              this.closeCanvas();
            } else if ([6, 9, 2].includes(this.project_id)) {
              // 回退到 视频配音 页面
              this.innerVisible = false;
              this.closeCanvas();
              if (this.project_id === 2) {
                this.dialogtitle = this.$t("PersonalEndLanguage.Videodubbing");
              }
              if (this.project_id === 9) {
                this.dialogtitle = this.$t(
                  "PersonalEndLanguage.VideoCloningDubbingsmall"
                );
              }
              if (this.project_id === 6) {
                this.dialogtitle = this.$t(
                  "PersonalEndLanguage.VideoCloningDubbing"
                );
              }
            } else {
              this.innerVisible = false;
              this.dialogtitle = this.$t(
                "PersonalEndLanguage.Hardsubtitlerecognition"
              );
              this.showOpen = true;
              this.closeCanvas();
            }
          } else {
            if (this.row_id - 1 < 0) {
              // 创建任务
              this.innerVisible = false;
              // this.HardSubtitleRecognitionTab = "first";
              if (this.project_id === 1) {
                this.dialogtitle = this.$t(
                  "PersonalEndLanguage.Videotranscription"
                );
              } else if (this.project_id === 2) {
                this.dialogtitle = this.$t("PersonalEndLanguage.Videodubbing");
              } else if (this.project_id === 9) {
                this.dialogtitle = this.$t(
                  "PersonalEndLanguage.VideoCloningDubbingsmall"
                );
              } else if (this.project_id === 6) {
                this.dialogtitle = this.$t(
                  "PersonalEndLanguage.VideoCloningDubbing"
                );
              } else {
                this.dialogtitle = this.$t(
                  "PersonalEndLanguage.Hardsubtitlerecognition"
                );
              }
              this.showOpen = true;
              this.row_id = 0;
              this.closeCanvas();
              return;
            }
            // 上一个
            // 当最后一个为 列表的最后一个时 点击上一步 赋值开始结束时间标点
            // if (
            //   this.hardSubtitleRecognition_fileList[this.row_id].new_id ===
            //   this.hardSubtitleRecognition_fileList[
            //     this.hardSubtitleRecognition_fileList.length - 1
            //   ].new_id
            // ) {
            this.hardSubtitleRecognition_fileList[this.row_id].marks =
              this.marks;
            this.hardSubtitleRecognition_fileList[this.row_id].startTime =
              this.startTime;
            this.hardSubtitleRecognition_fileList[this.row_id].endTime =
              this.endTime;
            this.hardSubtitleRecognition_fileList[this.row_id].rect = this.rect;
            this.hardSubtitleRecognition_fileList[this.row_id].position =
              this.setPositions(
                this.hardSubtitleRecognition_fileList[this.row_id]
              );
            // }
            this.canvas.setWidth(1);
            this.canvas.dispose();
            let video = null;
            this.videoUrl = "";
            this.initHardSubtitleRecognition();
            if (this.hardSubtitleRecognition_fileList[this.row_id - 1]) {
              this.dialogtitle =
                this.hardSubtitleRecognition_fileList[this.row_id - 1].name;

              this.videoUrl = URL.createObjectURL(
                this.hardSubtitleRecognition_fileList[this.row_id - 1].raw
              );
              this.videoDom.removeEventListener("loadedmetadata", () => {});
              video = document.createElement("video");
              video.src = this.videoUrl;
              document.body.appendChild(video);
              var v = this.$refs.videoref;
              // video.onloadedmetadata = () => {
              //   this.videoWidth = Number(
              //     (
              //       video.videoWidth /
              //       Number((video.videoHeight / v.clientHeight).toFixed(2))
              //     ).toFixed(2)
              //   );
              //   this.videoHeight = v.clientHeight;
              // };
              document.body.removeChild(video);
              this.windowdev = window.devicePixelRatio;
              let position =
                this.hardSubtitleRecognition_fileList[this.row_id - 1].position;
              // console.log(position, "上一步");
              // 创建 video 与 canvas
              this.initVideo(
                position,
                this.hardSubtitleRecognition_fileList[this.row_id - 1]
              );
              this.row_id--;
            }
          }
        }
      }
    },
    /**
     * 点击 添加 youtube 视频
     * @param data
     */
    addmoreClick() {
      if (this.form8.tableData.length >= 5) {
        this.$messages("warning", this.$t("PersonalEndLanguage.Youtubevideo2"));
        return;
      }
      this.form8.tableData.push({
        id: this.form8.tableData[this.form8.tableData.length - 1]
          ? this.form8.tableData[this.form8.tableData.length - 1].id + 1
          : 0,
        name: "",
        url: "",
        videoLanguage: "", //视频语言
        srturl: "",
        srtinput: "", //上传字幕文件 名称
      });
    },
    /**
     * 清除 youtube 上传 xlsx文件
     */
    clearupload() {
      this.form8.tableData = [];
      let uploadFilesArr = this.$refs["youtubeUploadxlsx"].uploadFiles; //上传字幕文件
      if (uploadFilesArr.length !== 0) {
        this.$refs["youtubeUploadxlsx"].uploadFiles = [];
      }
    },
    /**
     * 上传youtube 模板文件 xlsx
     * @param file
     * @returns {boolean}
     */
    youtube_uploadChange(file) {
      if (!file) return;
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testmsg === "xlsx";
      if (!extension) {
        this.$messages(
          "warning",
          this.$t("TermBaseManagement.warningmessage1")
        );
        this.$refs.youtubeUploadxlsx.clearFiles();
        return false;
      } else {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          // console.log(XLSX, "XLSX");
          const workbook = XLSX.read(data, { type: "array" });
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData = XLSX.utils.sheet_to_json(worksheet);
          if (jsonData.length === 0) {
            this.$messages("warning", "所上传的文件不符合要求，请查看上传模板");
            return;
          }
          let arr = [];
          if (jsonData.length > 5) {
            arr = jsonData.splice(0, 5);
          } else {
            arr = jsonData;
          }
          const entries = arr.map((obj) => Object.entries(obj));
          entries.forEach((i) => {
            i[0][0] = "name";
            i[1][0] = "url";
          });
          arr = entries.map((entry) => Object.fromEntries(entry));
          arr.forEach((j) => {
            this.form8.tableData.push({
              id: this.form8.tableData[this.form8.tableData.length - 1]
                ? this.form8.tableData[this.form8.tableData.length - 1].id + 1
                : 0,
              name: j.name,
              url: j.url,
              videoLanguage: "", //视频语言
              srturl: "",
              srtinput: "", //上传字幕文件 名称
            });
          });
          // console.log(this.form8.tableData);
        };
        reader.readAsArrayBuffer(file.raw);
        return true;
      }
    },
    /**
     * 删除表格某行
     * @param id
     */
    deleteTr(id) {
      this.form8.tableData.forEach((i, index) => {
        // console.log(i.id);
        if (i.id == id) {
          this.form8.tableData.splice(index, 1);
        }
      });
    },
    /**
     * 判断是否 选择了语言 如果没选择 给语言赋值
     * @param select
     */
    form8tableDataSelectChange(select) {
      this.form8.tableData.forEach((i) => {
        if (!i.videoLanguage) {
          i.videoLanguage = select;
        }
      });
    },
    /**
     * 内部弹窗 确认
     * @param data
     * @constructor
     */
    SureClick() {
      console.log("sureclick");
      console.log(this.startTime, this.endTime);
      // return false;
      if ([2, 6, 9].includes(this.project_id)) {
        if (this.HardSubtitleRecognitionTab == "first") {
          //视频克隆配音中(2,6) 如果选择硬字幕涂抹、没选择硬字幕识别 则无开始结束时间校验
          if (
            [2, 6].includes(this.project_id) &&
            this.form8.checkbox1 &&
            this.form8.radio2 != "2"
          ) {
            this.buttonLoading = true;
            let obj = {
              media_file_name: Math.random() + ".mp4",
              id: 1,
            };
            //无配音文件需要走新创建方法
            if (this.form8.radio1 === "2") {
              obj.id = 2;
            }
            this.uploadOSSMethod(4, obj);
          } else {
            let timeRegExp =
              /^([01][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])\.[0-9]{2}$/;
            if (this.startTime && !timeRegExp.test(this.startTime)) {
              return;
            }
            if (this.endTime && !timeRegExp.test(this.endTime)) {
              return;
            }
            let startTime = this.formattedTime(this.old_time);
            let endTime = this.formattedTime(this.old_time1);
            if (startTime && endTime) {
              if (startTime >= endTime) {
                // this.old_time = "";
                // this.old_time1 = "";
                this.$messages(
                  "warning",
                  this.$t("PersonalEndLanguage.timeMessage1")
                );
                return;
              }
            }
            // console.log(startTime, this.silderMax);
            if (startTime > this.silderMax) {
              this.old_time = "";
              return;
            }

            if (endTime > this.silderMax) {
              this.old_time1 = "";
              return;
            }
            this.buttonLoading = true;
            let obj = {
              media_file_name: Math.random() + ".mp4",
              id: 1,
            };
            //无配音文件需要走新创建方法
            if (this.form8.radio1 === "2") {
              obj.id = 2;
            }
            this.uploadOSSMethod(4, obj);
          }
        } else if (this.HardSubtitleRecognitionTab == "third") {
          // 下一个
          if (this.form9.radio2 == "2") {
            let timeRegExp =
              /^([01][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])\.[0-9]{2}$/;
            if (this.startTime && !timeRegExp.test(this.startTime)) {
              return;
            }
            if (this.endTime && !timeRegExp.test(this.endTime)) {
              return;
            }
            let startTime = this.formattedTime(this.old_time);
            let endTime = this.formattedTime(this.old_time1);
            if (startTime && endTime) {
              if (startTime >= endTime) {
                this.$messages(
                  "warning",
                  this.$t("PersonalEndLanguage.timeMessage1")
                );
                return;
              }
            }
            // console.log(startTime, this.silderMax);
            if (startTime > this.silderMax) {
              this.old_time = "";
              return;
            }

            if (endTime > this.silderMax) {
              this.old_time1 = "";
              return;
            }
          }
          let obj = this.hardSubtitleRecognition_fileList[this.row_id];
          obj.endTime = this.endTime;
          obj.startTime = this.startTime;
          obj.marks = this.marks;
          obj.silderMax = this.silderMax;
          obj.rect = this.rect;
          obj.videoHeight = this.videoHeight;
          obj.videoWidth = this.videoWidth;
          obj.position = this.setPositions(obj);
          if (
            this.row_id + 1 ===
            this.hardSubtitleRecognition_fileList.length
          ) {
            // 创建任务 确定
            this.buttonLoading = true;
            this.hardSubtitleRecognition_fileList.forEach((i, index) => {
              // console.log(i);
              this.uploadOSSBatchMethod(1, i);
            });
            return;
          }
          // console.log(this.videoDom, "this.videoDom");
          this.canvas.setWidth(1);
          this.canvas.dispose();
          // this.videoDom = null;
          let video = null;
          this.videoUrl = "";
          this.initHardSubtitleRecognition();
          if (this.hardSubtitleRecognition_fileList[this.row_id + 1]) {
            this.dialogtitle =
              this.hardSubtitleRecognition_fileList[this.row_id + 1].name;
            this.videoUrl = URL.createObjectURL(
              this.hardSubtitleRecognition_fileList[this.row_id + 1].raw
            );
            this.videoDom.removeEventListener("loadedmetadata", () => {});
            video = document.createElement("video");
            video.src = this.videoUrl;
            document.body.appendChild(video);
            var v = this.$refs.videoref;
            // video.onloadedmetadata = () => {
            //   this.videoWidth = Number(
            //     (
            //       video.videoWidth /
            //       Number((video.videoHeight / v.clientHeight).toFixed(2))
            //     ).toFixed(2)
            //   );
            //   this.videoHeight = v.clientHeight;
            // };
            document.body.removeChild(video);
            this.windowdev = window.devicePixelRatio;
            let position = {};
            if (!this.hardSubtitleRecognition_fileList[this.row_id + 1].rect) {
              position = this.setPositions(
                this.hardSubtitleRecognition_fileList[this.row_id]
              );
            } else {
              position =
                this.hardSubtitleRecognition_fileList[this.row_id + 1].position;
            }
            this.initVideo(
              position,
              this.hardSubtitleRecognition_fileList[this.row_id + 1]
            );
            this.row_id++;
          }
        }
        return;
      } else if (this.project_id == 1) {
        if (this.HardSubtitleRecognitionTab == "first") {
          //视频AI字幕1  如果选择硬字幕涂抹、没选择硬字幕识别 则无开始结束时间校验
          if (this.form.checkbox1 && this.form.radio2 != "2") {
            if (this.form.checked) {
              //   视频翻译 + 字幕翻译
              let sourcelanguage = "";
              let targetlanguage = "";
              let mode = "";
              if (
                !this.form.srturl ||
                !this.form.srtinput ||
                this.form.activeName == "2"
              ) {
                //   视频翻译
                mode = "1";
              } else {
                //   字幕翻译
                mode = "2";
              }
              sourcelanguage = this.form.videoLanguage;
              targetlanguage = this.form.targetlanguage;
              let obj = {
                mode: mode,
                slang: sourcelanguage,
                tlang: targetlanguage,
                srt_path: this.form.srturl,
                srt_name: this.form.srtinput,
                mt: "",
              };
              this.buttonLoading = true;
              this.uploadOSSMethod(1, obj);
            } else {
              //   视频转写
              this.buttonLoading = true;
              this.uploadOSSMethod(2);
            }
          } else {
            //硬字幕识别
            let timeRegExp =
              /^([01][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])\.[0-9]{2}$/;
            if (this.startTime && !timeRegExp.test(this.startTime)) {
              return;
            }
            if (this.endTime && !timeRegExp.test(this.endTime)) {
              return;
            }
            let startTime = this.formattedTime(this.old_time);
            let endTime = this.formattedTime(this.old_time1);
            if (startTime && endTime) {
              if (startTime >= endTime) {
                // this.old_time = "";
                // this.old_time1 = "";
                this.$messages(
                  "warning",
                  this.$t("PersonalEndLanguage.timeMessage1")
                );
                return;
              }
            }
            if (startTime > this.silderMax) {
              this.old_time = "";
              return;
            }

            if (endTime > this.silderMax) {
              this.old_time1 = "";
              return;
            }
            if (this.form.checked) {
              //   视频翻译 + 字幕翻译
              let sourcelanguage = "";
              let targetlanguage = "";
              let mode = "3";
              sourcelanguage = this.form.videoLanguage;
              targetlanguage = this.form.targetlanguage;
              let obj = {
                mode: mode,
                slang: sourcelanguage,
                tlang: targetlanguage,
                srt_path: this.form.srturl,
                srt_name: this.form.srtinput,
                mt: "",
              };
              this.buttonLoading = true;
              this.uploadOSSMethod(1, obj);
            } else {
              //   视频转写
              this.buttonLoading = true;
              this.uploadOSSMethod(2);
            }
          }
        } else if (this.HardSubtitleRecognitionTab == "third") {
          // 下一个
          if (this.form1.radio2 == "2") {
            let timeRegExp =
              /^([01][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])\.[0-9]{2}$/;
            if (this.startTime && !timeRegExp.test(this.startTime)) {
              return;
            }
            if (this.endTime && !timeRegExp.test(this.endTime)) {
              return;
            }
            let startTime = this.formattedTime(this.old_time);
            let endTime = this.formattedTime(this.old_time1);
            if (startTime && endTime) {
              if (startTime >= endTime) {
                this.$messages(
                  "warning",
                  this.$t("PersonalEndLanguage.timeMessage1")
                );
                return;
              }
            }
            // console.log(startTime, this.silderMax);
            if (startTime > this.silderMax) {
              this.old_time = "";
              return;
            }

            if (endTime > this.silderMax) {
              this.old_time1 = "";
              return;
            }
          }
          let obj = this.hardSubtitleRecognition_fileList[this.row_id];
          obj.endTime = this.endTime;
          obj.startTime = this.startTime;
          obj.marks = this.marks;
          obj.silderMax = this.silderMax;
          obj.rect = this.rect;
          obj.videoHeight = this.videoHeight;
          obj.videoWidth = this.videoWidth;
          obj.position = this.setPositions(obj);
          if (
            this.row_id + 1 ===
            this.hardSubtitleRecognition_fileList.length
          ) {
            // 创建任务 确定
            this.buttonLoading = true;
            this.hardSubtitleRecognition_fileList.forEach((i, index) => {
              // console.log(i);
              this.uploadOSSBatchMethod(3, i);
            });
            return;
          }
          // console.log(this.videoDom, "this.videoDom");
          this.canvas.setWidth(1);
          this.canvas.dispose();
          // this.videoDom = null;
          let video = null;
          this.videoUrl = "";
          this.initHardSubtitleRecognition();
          if (this.hardSubtitleRecognition_fileList[this.row_id + 1]) {
            this.dialogtitle =
              this.hardSubtitleRecognition_fileList[this.row_id + 1].name;
            this.videoUrl = URL.createObjectURL(
              this.hardSubtitleRecognition_fileList[this.row_id + 1].raw
            );
            this.videoDom.removeEventListener("loadedmetadata", () => {});
            video = document.createElement("video");
            video.src = this.videoUrl;
            document.body.appendChild(video);
            let v = this.$refs.videoref;
            // video.onloadedmetadata = () => {
            //   this.videoWidth = Number(
            //     (
            //       video.videoWidth /
            //       Number((video.videoHeight / v.clientHeight).toFixed(2))
            //     ).toFixed(2)
            //   );
            //   this.videoHeight = v.clientHeight;
            // };
            document.body.removeChild(video);
            this.windowdev = window.devicePixelRatio;
            let position = {};
            if (!this.hardSubtitleRecognition_fileList[this.row_id + 1].rect) {
              position = this.setPositions(
                this.hardSubtitleRecognition_fileList[this.row_id]
              );
            } else {
              position =
                this.hardSubtitleRecognition_fileList[this.row_id + 1].position;
            }
            this.initVideo(
              position,
              this.hardSubtitleRecognition_fileList[this.row_id + 1]
            );
            this.row_id++;
          }
        } else if (this.HardSubtitleRecognitionTab == "six") {
          // 下一个
          let obj = this.hardSubtitleRecognition_fileList[this.row_id];
          obj.endTime = this.endTime;
          obj.startTime = this.startTime;
          obj.marks = this.marks;
          obj.silderMax = this.silderMax;
          obj.rect = this.rect;
          obj.videoHeight = this.videoHeight;
          obj.videoWidth = this.videoWidth;
          obj.position = this.setPositions(obj);
          if (
            this.row_id + 1 ===
            this.hardSubtitleRecognition_fileList.length
          ) {
            // 创建任务 确定
            this.buttonLoading = true;
            this.hardSubtitleRecognition_fileList.forEach((i, index) => {
              // console.log(i);
              this.uploadOSSBatchMethod(5, i);
            });
            return;
          }
          // console.log(this.videoDom, "this.videoDom");
          this.canvas.setWidth(1);
          this.canvas.dispose();
          // this.videoDom = null;
          let video = null;
          this.videoUrl = "";
          this.initHardSubtitleRecognition();
          if (this.hardSubtitleRecognition_fileList[this.row_id + 1]) {
            this.dialogtitle =
              this.hardSubtitleRecognition_fileList[this.row_id + 1].name;
            this.videoUrl = URL.createObjectURL(
              this.hardSubtitleRecognition_fileList[this.row_id + 1].raw
            );
            this.videoDom.removeEventListener("loadedmetadata", () => {});
            video = document.createElement("video");
            video.src = this.videoUrl;
            document.body.appendChild(video);
            let v = this.$refs.videoref;
            // video.onloadedmetadata = () => {
            //   this.videoWidth = Number(
            //     (
            //       video.videoWidth /
            //       Number((video.videoHeight / v.clientHeight).toFixed(2))
            //     ).toFixed(2)
            //   );
            //   this.videoHeight = v.clientHeight;
            // };
            document.body.removeChild(video);
            this.windowdev = window.devicePixelRatio;
            let position = {};
            if (!this.hardSubtitleRecognition_fileList[this.row_id + 1].rect) {
              position = this.setPositions(
                this.hardSubtitleRecognition_fileList[this.row_id]
              );
            } else {
              position =
                this.hardSubtitleRecognition_fileList[this.row_id + 1].position;
            }
            this.initVideo(
              position,
              this.hardSubtitleRecognition_fileList[this.row_id + 1]
            );
            this.row_id++;
          }
        }
        return;
      }
      //硬字幕识别
      if (this.HardSubtitleRecognitionTab !== "third") {
        // console.log(11111);
        if (this.HardSubtitleRecognitionTab !== "first") {
          // if (!this.videoTitle) {
          //   this.$messages("warning", "该视频无法使用,请重新选择视频arr");
          //   return;
          // }
          if (!this.videoTitle && this.silderMax === 0) {
            this.$messages("warning", "该视频无法使用,请重新选择视频");
            return;
          }
          if (!this.rect) {
            this.$messages("warning", "请先设置识别范围");
            return;
          }
        }
        //判断时间格式是否正确的正则表达式
        let timeRegExp =
          /^([01][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])\.[0-9]{2}$/;
        if (this.startTime && !timeRegExp.test(this.startTime)) {
          // this.$messages(
          //   "warning",
          //   this.$t("OverwriteOperation.warningmessage1")
          // );
          return;
        }
        if (this.endTime && !timeRegExp.test(this.endTime)) {
          // this.$messages(
          //   "warning",
          //   this.$t("OverwriteOperation.warningmessage1")
          // );
          return;
        }
        let startTime = this.formattedTime(this.old_time);
        let endTime = this.formattedTime(this.old_time1);
        if (startTime && endTime) {
          if (startTime >= endTime) {
            // this.old_time = "";
            // this.old_time1 = "";
            this.$messages(
              "warning",
              this.$t("PersonalEndLanguage.timeMessage1")
            );
            return;
          }
        }
        // console.log(startTime, this.silderMax);
        if (startTime > this.silderMax) {
          this.old_time = "";
          return;
        }

        if (endTime > this.silderMax) {
          this.old_time1 = "";
          return;
        }
        this.buttonLoading = true;
        if (this.HardSubtitleRecognitionTab === "first") {
          // console.log(this.videoHeight, "this.videoHeight111111111");
          let num = this.videoHeight / 360;
          let x = Math.ceil(this.rect.left * num);
          let y = Math.ceil(this.rect.top * num);
          let width = Math.ceil(this.rect.width * this.rect.scaleX * num);
          let height = Math.ceil(this.rect.height * this.rect.scaleY * num);
          let obj = {
            action: "create_ocr_job",
            slang: this.form5.sourcelanguage,
            tlang: 0,
            media_file_name: this.videoData.name,
            savename: "",
            position_x: x,
            position_y: y,
            position_w: width,
            position_h: height,
            position_original_x: this.rect.left,
            position_original_y: this.rect.top,
            position_original_w: this.rect.width * this.rect.scaleX,
            position_original_h: this.rect.height * this.rect.scaleY,
            time_start: this.startTime ? this.startTime : "",
            time_end: this.endTime
              ? this.endTime
              : this.formatTime(this.silderMax),
            original_width: this.videoWidth,
            original_height: this.videoHeight,
          };
          console.log(obj, "obj");
          this.uploadOSSMethod(5, obj);
        } else {
          // console.log(
          //   this.rect.left,
          //   this.rect.top,
          //   this.rect.width * this.rect.scaleX,
          //   this.rect.height * this.rect.scaleY
          // );
          // return;
          showLoading(this.$t("PersonalEndLanguage.loadingMessage"));
          create_tube_ocr_job({
            action: "create_tube_ocr_job",
            original_width:
              this.youtubeWidth_new === 0 ? 640 : this.youtubeWidth_new,
            original_height:
              this.youtubeHeight_new === 0 ? 360 : this.youtubeHeight_new,
            file_title: this.videoTitle,
            duration: this.silderMax,
            file_url: this.form5.youtubeNameInput,
            slang: this.form5.sourcelanguage,
            position_original_x: this.rect.left,
            position_original_y: this.rect.top,
            position_original_w: this.rect.width * this.rect.scaleX,
            position_original_h: this.rect.height * this.rect.scaleY,
            time_start: this.startTime ? this.startTime : "",
            time_end: this.endTime
              ? this.endTime
              : this.formatTime(this.silderMax),
          })
            .then((res) => {
              if (!res) {
                this.buttonLoading = false;
                this.showOpen = true;
                hideLoading();
                return;
              }
              if (res.result == "SUCCESS") {
                this.get_last_job().then(() => {
                  this.$messages(
                    "success",
                    this.$t("PersonalEndLanguage.successMessage")
                  );
                  hideLoading();
                  this.innerVisible = false;
                  this.dialogVisible = false;
                  this.buttonLoading = false;
                  this.showOpen = true;
                  this.initData();
                });
              } else if (res.result == "LOGIN_FAILED") {
                this.otherResult();
              } else if (res.result == "SPACE_FAILED") {
                this.otherResult2(res);
              } else {
                this.otherResult1(res);
              }
            })
            .catch((err) => {
              this.$messages("error", err.msg);
            });
        }
      } else {
        // 下一个
        let timeRegExp =
          /^([01][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])\.[0-9]{2}$/;
        if (this.startTime && !timeRegExp.test(this.startTime)) {
          return;
        }
        if (this.endTime && !timeRegExp.test(this.endTime)) {
          return;
        }
        let startTime = this.formattedTime(this.old_time);
        let endTime = this.formattedTime(this.old_time1);
        if (startTime && endTime) {
          if (startTime >= endTime) {
            this.$messages(
              "warning",
              this.$t("PersonalEndLanguage.timeMessage1")
            );
            return;
          }
        }
        // console.log(startTime, this.silderMax);
        if (startTime > this.silderMax) {
          this.old_time = "";
          return;
        }

        if (endTime > this.silderMax) {
          this.old_time1 = "";
          return;
        }

        let obj = this.hardSubtitleRecognition_fileList[this.row_id];
        obj.endTime = this.endTime;
        obj.startTime = this.startTime;
        obj.marks = this.marks;
        obj.silderMax = this.silderMax;
        obj.rect = this.rect;
        obj.videoHeight = this.videoHeight;
        obj.videoWidth = this.videoWidth;
        obj.position = this.setPositions(obj);
        if (this.row_id + 1 === this.hardSubtitleRecognition_fileList.length) {
          // 创建任务 确定
          this.buttonLoading = true;
          this.hardSubtitleRecognition_fileList.forEach((i, index) => {
            this.uploadOSSBatchMethod(2, i);
          });
          return;
        }
        // console.log(this.videoDom, "this.videoDom");
        this.canvas.setWidth(1);
        this.canvas.dispose();
        // this.videoDom = null;
        let video = null;
        this.videoUrl = "";
        this.initHardSubtitleRecognition();
        if (this.hardSubtitleRecognition_fileList[this.row_id + 1]) {
          this.dialogtitle =
            this.hardSubtitleRecognition_fileList[this.row_id + 1].name;
          this.videoUrl = URL.createObjectURL(
            this.hardSubtitleRecognition_fileList[this.row_id + 1].raw
          );
          this.videoDom.removeEventListener("loadedmetadata", () => {});
          video = document.createElement("video");
          video.src = this.videoUrl;
          document.body.appendChild(video);
          let v = this.$refs.videoref;
          // video.onloadedmetadata = () => {
          //   this.videoWidth = Number(
          //     (
          //       video.videoWidth /
          //       Number((video.videoHeight / v.clientHeight).toFixed(2))
          //     ).toFixed(2)
          //   );
          //   this.videoHeight = v.clientHeight;
          // };
          document.body.removeChild(video);
          this.windowdev = window.devicePixelRatio;
          let position = {};
          if (!this.hardSubtitleRecognition_fileList[this.row_id + 1].rect) {
            position = this.setPositions(
              this.hardSubtitleRecognition_fileList[this.row_id]
            );
          } else {
            position =
              this.hardSubtitleRecognition_fileList[this.row_id + 1].position;
          }
          this.initVideo(
            position,
            this.hardSubtitleRecognition_fileList[this.row_id + 1]
          );
          this.row_id++;
        }
        // console.log("this...", this.hardSubtitleRecognition_fileList);
      }
    },
    /**
     * 滑块滑动  修改视频时间
     * @param slider
     */
    sliderInput(slider) {
      this.slidervalue = slider;
      if (
        this.HardSubtitleRecognitionTab === "first" ||
        this.HardSubtitleRecognitionTab === "six" ||
        this.HardSubtitleRecognitionTab === "third"
      ) {
        this.videoDom.currentTime = slider;
      } else {
        this.$refs.youtubePlayer.player.pauseVideo();
        this.$refs.youtubePlayer.player.seekTo(slider);
        if (slider === 0) {
          this.$refs.youtubePlayer.player.getPlayerState().then((res) => {
            if (res === 1) {
              this.$refs.youtubePlayer.player.pauseVideo();
            }
          });
        }
      }
    },
    /**
     * 格式化显示 时间
     * @param val
     */
    formatTooltip(val) {
      return getTimeString(val, 1, 2).replace(".", ",");
    },

    /**
     * 往对象中添加 属性值
     * @param id
     */
    setObjData(id, time) {
      return {
        id: id,
        time: time,
        label: this.$createElement("i", {
          class: {
            "el-icon-location": true,
          },
          style: {
            transform: "rotate(180deg)",
            color: id == 1 ? "#98FF6C" : "#FFB655",
          },
        }),
      };
    },
    /**
     * 点击 片头片尾 按钮  获取当前时间 赋值片头片尾时间
     * @param data 0：片头 1：片尾
     * @constructor
     */
    TimeIconClick(data, time) {
      // console.log(this.marks, "this.marks");
      time === -1
        ? this.$refs.youtubePlayer.player.getCurrentTime().then((res) => {
            // console.log(res, "res");
            time = res;
          })
        : time;
      setTimeout(() => {
        if (data == 0) {
          if (Object.keys(this.marks).length !== 0) {
            let obj = {};
            for (let Key in this.marks) {
              if (this.marks[Key].id === 2) {
                obj = this.marks[Key];
              }
            }
            if (time >= obj.time) {
              return;
            }

            for (let Key in this.marks) {
              if (this.marks[Key].id === 1) {
                delete this.marks[Key];
                this.marks[time] = this.setObjData(1, time);
              } else {
                if (this.marks[Key].id === 2) {
                  if (time >= this.marks[Key].time) {
                    return;
                  }
                }
                this.marks[time] = this.setObjData(1, time);
              }
            }
          } else {
            this.marks[time] = this.setObjData(1, time);
          }
          this.startTime = this.formatTime(time);
          this.marks = Object.assign({}, this.marks);
        } else {
          if (Object.keys(this.marks).length !== 0) {
            let obj = {};
            for (let Key in this.marks) {
              if (this.marks[Key].id === 1) {
                obj = this.marks[Key];
              }
            }
            if (time <= obj.time) {
              return;
            }
            for (let Key in this.marks) {
              if (this.marks[Key].id === 2) {
                delete this.marks[Key];
                this.marks[time] = this.setObjData(2, time);
              } else {
                if (this.marks[Key].id === 1) {
                  if (time <= this.marks[Key].time) {
                    return;
                  }
                }
                this.marks[time] = this.setObjData(2, time);
              }
            }
          } else {
            this.marks[time] = this.setObjData(2, time);
          }
          this.endTime = this.formatTime(time);
          this.marks = Object.assign({}, this.marks);
        }
      }, 100);
    },
    /**
     * 清除 输入框里得内容
     * @param data
     */
    clearInput(data) {
      for (let Key in this.marks) {
        if (data == 0) {
          if (this.marks[Key].id === 1) {
            delete this.marks[Key];
          }
          this.old_time = "";
        } else {
          if (this.marks[Key].id === 2) {
            delete this.marks[Key];
          }
          this.old_time1 = "";
        }
      }
      this.marks = Object.assign({}, this.marks);
    },
    /**
     *  片头片尾 时间点 失去焦点
     * @param data
     */
    inputBlur(data) {
      let timeRegExp =
        /^([01][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])\.[0-9]{2}$/;
      if (data === 0) {
        if (!this.startTime) {
          return;
        }
        //判断时间格式是否正确的正则表达式
        if (!timeRegExp.test(this.startTime)) {
          this.$messages(
            "warning",
            this.$t("OverwriteOperation.warningmessage1")
          );
          return;
        }

        let time = this.formattedTime(this.startTime);
        if (this.endTime) {
          let time1 = this.formattedTime(this.endTime);
          if (+time >= +time1) {
            this.old_time = this.startTime;
            this.startTime = "";
            for (const marks in this.marks) {
              if (this.marks[marks].id === 1) {
                delete this.marks[marks];
              }
            }
            this.marks = Object.assign({}, this.marks);
            return;
          }
        }
        if (+time > this.silderMax) {
          this.old_time = this.startTime;
          this.startTime = "";

          for (const marks in this.marks) {
            if (this.marks[marks].id === 1) {
              delete this.marks[marks];
            }
          }
          this.marks = Object.assign({}, this.marks);
          this.$messages("warning", this.$t("PersonalEndLanguage.timeMessage"));
          return;
        }
        this.old_time = this.startTime;
        this.TimeIconClick(data, time);
      } else {
        if (!this.endTime) {
          return;
        }
        if (!timeRegExp.test(this.endTime)) {
          this.$messages(
            "warning",
            this.$t("OverwriteOperation.warningmessage1")
          );
          return;
        }
        let time = this.formattedTime(this.endTime);
        if (this.startTime) {
          let time1 = this.formattedTime(this.startTime);
          if (+time <= +time1) {
            this.old_time1 = this.endTime;
            this.endTime = "";
            for (const marks in this.marks) {
              if (this.marks[marks].id === 2) {
                delete this.marks[marks];
              }
            }
            this.marks = Object.assign({}, this.marks);
            return;
          }
        }
        if (+time > this.silderMax) {
          this.old_time1 = this.endTime;
          this.endTime = "";
          for (const marks in this.marks) {
            if (this.marks[marks].id === 2) {
              delete this.marks[marks];
            }
          }
          this.marks = Object.assign({}, this.marks);
          this.$messages("warning", this.$t("PersonalEndLanguage.timeMessage"));
          return;
        }
        this.old_time1 = this.endTime;
        this.TimeIconClick(data, time);
      }
    },
    /**
     * 时间转换
     * @param time
     * @returns {string}
     */
    formatTime(time) {
      const milliseconds = parseInt(time * 1000);
      const date = new Date(milliseconds);
      const hours = date.getUTCHours().toString().padStart(2, "0");
      const minutes = date.getUTCMinutes().toString().padStart(2, "0");
      const seconds = date.getUTCSeconds().toString().padStart(2, "0");
      const millisecondsPart = date
        .getUTCMilliseconds()
        .toString()
        .padStart(3, "0")
        .slice(0, 2);
      return `${hours}:${minutes}:${seconds}.${millisecondsPart}`;
    },
    /**
     *
     * @param time
     * @returns {string}
     */
    formattedTime(time) {
      const [hours, minutes, seconds] = time.split(":").map(Number);
      const totalSeconds = hours * 3600 + minutes * 60 + seconds;
      return totalSeconds; // 返回总秒数
    },
    /**
     * 初始化 视频
     * @param data
     */
    initVideo(obj, row = {}) {
      this.$nextTick(() => {
        if (
          this.HardSubtitleRecognitionTab === "first" ||
          this.HardSubtitleRecognitionTab === "six" ||
          this.HardSubtitleRecognitionTab === "third"
        ) {
          // this.videoDom = null;
          this.videoDom = document.getElementById("dialogVideo"); // 获取视频dom元素
          let isCurrentMetadataLoaded = false;
          var v = this.$refs.videoref;
          this.videoDom.addEventListener("loadedmetadata", () => {
            if (!isCurrentMetadataLoaded) {
              this.videoWidth = Number(
                (
                  this.videoDom.videoWidth /
                  Number(
                    (this.videoDom.videoHeight / v.clientHeight).toFixed(2)
                  )
                ).toFixed(2)
              );
              this.videoHeight = v.clientHeight;
              console.log(
                this.videoHeight,
                "this.videoHeight",
                this.videoWidth
              );
              this.marks = {};
              this.silderMax = this.videoDom.duration;
              if (row.id) {
                if (Object.keys(row.marks).length === 0) {
                  this.marks[this.silderMax] = this.setObjData(
                    2,
                    this.silderMax
                  );
                } else {
                  this.marks = row.marks;
                }
                if (!row.endTime) {
                  this.endTime = this.formatTime(this.silderMax);
                } else {
                  this.endTime = row.endTime;
                }
                this.startTime = row.startTime;
              } else {
                this.startTime = "";
                this.endTime = this.formatTime(this.silderMax);
                this.marks[this.silderMax] = this.setObjData(2, this.silderMax);
              }
              this.marks = Object.assign({}, this.marks);
              this.initCanvas(obj); //创建canvas
              isCurrentMetadataLoaded = true;
            }
          });
          this.videoDom.addEventListener("error", () => {
            this.$messages("warning", this.$t("CommonName.videoWarning"));
          });
        } else {
          // this.$refs.youtubePlayer.player.setSize("100%", "100%");
          // console.log(this.youtubeWidth, this.youtubeHeight, "111111");
          // $("#dialogVideo").on("load", function () {
          //   console.log("111");
          //   const iframe = this.$refs.youtubePlayer; // 获取 iframe 元素
          //   const iframeContent =
          //     iframe.contentWindow || iframe.contentDocument; // 获取 iframe 的内容窗口或文档对象

          //   if (iframeContent) {
          //     const targetElement =
          //       iframeContent.document.getElementsByClassName(
          //         "html5-main-video"
          //       ); // 使用标准的 DOM 操作方法获取目标元素
          //     console.log(targetElement); // 打印目标元素到控制台
          //   }
          // });
          let height = 0;
          let width = 0;
          if (this.youtubeHeight % 360 === 0 && this.youtubeWidth % 640 === 0) {
            width = 0;
            height = Number(
              (
                this.youtubeHeight /
                Number((this.youtubeWidth / 640).toFixed(2))
              ).toFixed()
            );

            height = height >= 360 ? 0 : height;
            // console.log("% 0");
          } else if (this.youtubeHeight < 360 && this.youtubeWidth < 640) {
            width = 0;
            height = 0;
            // console.log("< 0");
          } else {
            if (this.youtubeWidth > this.youtubeHeight) {
              height = Number(
                (
                  this.youtubeHeight /
                  Number((this.youtubeWidth / 640).toFixed(2))
                ).toFixed()
              );
              if (height > 360) {
                height = 0;
                width = Number(
                  (
                    this.youtubeWidth /
                    Number((this.youtubeHeight / 360).toFixed(2))
                  ).toFixed()
                );
              }
            }
            if (this.youtubeHeight > this.youtubeWidth) {
              width = Number(
                (
                  this.youtubeWidth /
                  Number((this.youtubeHeight / 360).toFixed(2))
                ).toFixed()
              );
              if (width > 640) {
                width = 0;
                height = Number(
                  (
                    this.youtubeHeight /
                    Number((this.youtubeWidth / 640).toFixed(2))
                  ).toFixed()
                );
              }
            }
          }
          console.log(width, height, "height");
          this.youtubeHeight_new = height;
          this.youtubeWidth_new = width;
          this.$refs.youtubePlayer.player.setSize(640, 360);
          // this.$refs.youtubePlayer.player.setPlaybackQuality("default");
          this.$refs.youtubePlayer.player.on("ready", (event) => {
            // console.log(event.target, "event.target");
            this.silderMax = event.target.getDuration()
              ? event.target.getDuration()
              : 0;
            this.endTime = this.formatTime(this.silderMax);
            this.marks[this.silderMax] = this.setObjData(2, this.silderMax);
            this.marks = Object.assign({}, this.marks);
            this.videoTitle = event.target.videoTitle;
            // setTimeout(() => {
            //   this.$refs.youtubePlayer.player.getIframe().then((res) => {
            //     console.log(res);
            //   });
            // }, 3000);
          });

          this.$refs.youtubePlayer.player.on("stateChange", (data) => {
            if (data.data === 1) {
              this.$refs.youtubePlayer.player.seekTo(0.01);
              this.$refs.youtubePlayer.player.pauseVideo();
              this.sliderChange = false;
            }
          });

          // this.$refs.youtubePlayer.player.getVideoEmbedCode().then((res) => {
          //   console.log(res);
          // });
        }
      });
    },
    /**
     * 弹窗 确定 按钮 开始上传
     */
    determineClick() {
      let video = null;
      // console.log(11111111111);
      // this.buttonLoading = true;
      // "视频配音"
      if (
        !this.videoData.name &&
        this.dialogtitle !== this.$t("PersonalEndLanguage.Videodubbing") &&
        this.dialogtitle !==
          this.$t("PersonalEndLanguage.VideoCloningDubbingsmall") &&
        this.dialogtitle !==
          this.$t("PersonalEndLanguage.VideoCloningDubbing") &&
        this.dialogtitle !==
          this.$t("PersonalEndLanguage.Hardsubtitlerecognition") &&
        this.project_id != 1
      ) {
        this.$messages(
          "warning",
          this.$t("PersonalEndLanguage.warningmessage")
        );
        return;
      }
      if (this.isAddFolder) {
        //如果新建文件夹 则需要判断文件夹是否创建成功
        this.check_user_folder_name().then((res) => {
          if (+res.count > 0) {
            this.$messages("warning", this.$t("CommonName.warningmessage8"));
            return;
          }
          this.create_job_method();
        });
      } else {
        this.create_job_method();
      }
    },
    create_job_method() {
      switch (this.dialogtitle) {
        // 视频转写
        case this.$t("PersonalEndLanguage.Videotranscription"):
          this.CreateVideoTranscription();
          break;
        //   视频翻译,
        case this.$t("PersonalEndLanguage.Videotranslation"):
          this.CreateVideoTranscription();
          break;
        //   字幕翻译
        case this.$t("PersonalEndLanguage.subtitling"):
          this.CreateVideoTranscription();
          break;
        //   音频转写
        case this.$t("PersonalEndLanguage.Audiotranscription"):
          this.CreateAudiotranscription();
          break;
        //   漫画快翻
        case this.$t("PersonalEndLanguage.ComicQuickFlip"):
          this.CreateCartoonQuickFlip();
          break;
        //   视频配音
        case this.$t("PersonalEndLanguage.Videodubbing"):
          this.CreateAIVideo();
          break;
        //   视频克隆配音
        case this.$t("PersonalEndLanguage.VideoCloningDubbingsmall"):
          this.CreateAIVideo();
          break;
        //    视频克隆配音 大模型
        case this.$t("PersonalEndLanguage.VideoCloningDubbing"):
          this.CreateAIVideo();
          break;
        //   硬字幕识别
        case this.$t("PersonalEndLanguage.Hardsubtitlerecognition"):
          this.createHardSubtitleRecognition();
          break;
        case this.$t("PersonalEndLanguage.Subtitlealignment"):
          this.CreatedSubtitlealignment();
          break;
        default:
          break;
      }
    },
    /**
     * 弹窗 创建 视频转写  视频翻译, 字幕翻译 任务
     */
    CreateVideoTranscription() {
      if (this.HardSubtitleRecognitionTab === "third") {
        if (this.hardSubtitleRecognition_fileList.length === 0) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage")
          );
          return;
        }
        if (this.form1.checked) {
          //   视频翻译 + 字幕翻译
          let sourcelanguage = "";
          let targetlanguage = "";
          sourcelanguage = this.form1.videoLanguage;
          targetlanguage = this.form1.targetlanguage;
          if (!targetlanguage || !sourcelanguage) {
            this.$messages(
              "warning",
              this.$t("PersonalEndLanguage.warningmessage3")
            );
            return;
          } else if (targetlanguage === sourcelanguage) {
            this.$messages(
              "warning",
              this.$t("PersonalEndLanguage.warningmessage4")
            );
            return;
          }
        } else {
          //   视频转写
          if (!this.form1.videoLanguage) {
            this.$messages(
              "warning",
              this.$t("PersonalEndLanguage.warningmessage1")
            );
            return;
          }
        }
        //选择硬字幕涂抹需要验证涂抹时长是否足够
        if (this.form1.checkbox1) {
          console.log("验证涂抹时长是否足够", this.fileTotalDuration);
          get_user_quota_info({
            action: "get_user_quota_info",
          }).then((res) => {
            if (res) {
              //如果选择高级涂抹，需验证高级涂抹时长是否足够
              if (this.form1.checkbox3) {
                if (
                  !!res.info.daub.video_daub_pro_minute == false ||
                  res.info.daub.video_daub_pro_minute * 60 <
                    this.fileTotalDuration
                ) {
                  this.$messages(
                    "warning",
                    this.$t("PersonalEndLanguage.warningmessage53")
                  );
                  return;
                }
              } else {
                if (
                  !!res.info.daub.video_daub_minute == false ||
                  res.info.daub.video_daub_minute * 60 < this.fileTotalDuration
                ) {
                  this.$messages(
                    "warning",
                    this.$t("PersonalEndLanguage.warningmessage42")
                  );
                  return;
                }
              }

              // 跳转硬字幕识别
              this.hardSubtitleRecognition_fileList.forEach((i, index) => {
                i.videoHeight = 0;
                i.videoWidth = 0;
                i.slidervalue = 0;
                i.startTime = "";
                i.endTime = "";
                i.marks = {};
                i.rect = null;
                i.canvas = null;
                i.position = {};
                i.new_id = index;
              });
              showLoading(this.$t("CommonName.loadingmessage"));
              get_ocr_last_job({ action: "get_ocr_last_job" }).then((res) => {
                if (res) {
                  this.initHardSubtitleRecognition();
                  this.hardSubtitleRecognition_fileList.forEach((i) => {
                    i.position = res.position;
                  });
                  this.innerVisible = true;
                  let video = null;
                  this.$nextTick(() => {
                    this.dialogtitle =
                      this.hardSubtitleRecognition_fileList[0].name;
                    this.videoUrl = URL.createObjectURL(
                      this.hardSubtitleRecognition_fileList[0].raw
                    );
                    video = document.createElement("video");
                    video.src = this.videoUrl;
                    document.body.appendChild(video);
                    var v = this.$refs.videoref;
                    document.body.removeChild(video);
                    // console.log(window.devicePixelRatio, " * window.screen.availHeight");
                    this.windowdev = window.devicePixelRatio;
                  });

                  // console.log(this.windowdev);
                  this.initVideo(
                    this.hardSubtitleRecognition_fileList[0].position
                  );
                  hideLoading();
                }
              });
            }
          });
        } else if (this.form1.radio2 === "2") {
          // 跳转硬字幕识别
          this.hardSubtitleRecognition_fileList.forEach((i, index) => {
            i.videoHeight = 0;
            i.videoWidth = 0;
            i.slidervalue = 0;
            i.startTime = "";
            i.endTime = "";
            i.marks = {};
            i.rect = null;
            i.canvas = null;
            i.position = {};
            i.new_id = index;
          });
          showLoading(this.$t("CommonName.loadingmessage"));
          get_ocr_last_job({ action: "get_ocr_last_job" }).then((res) => {
            if (res) {
              this.initHardSubtitleRecognition();
              this.hardSubtitleRecognition_fileList.forEach((i) => {
                i.position = res.position;
              });
              this.innerVisible = true;
              let video = null;
              this.$nextTick(() => {
                this.dialogtitle =
                  this.hardSubtitleRecognition_fileList[0].name;
                this.videoUrl = URL.createObjectURL(
                  this.hardSubtitleRecognition_fileList[0].raw
                );
                video = document.createElement("video");
                video.src = this.videoUrl;
                document.body.appendChild(video);
                var v = this.$refs.videoref;
                document.body.removeChild(video);
                // console.log(window.devicePixelRatio, " * window.screen.availHeight");
                this.windowdev = window.devicePixelRatio;
              });

              // console.log(this.windowdev);
              this.initVideo(this.hardSubtitleRecognition_fileList[0].position);
              hideLoading();
            }
          });
        } else {
          console.log("直接创建", this.hardSubtitleRecognition_fileList);
          // return;
          if (this.form1.checked) {
            //   视频翻译 + 字幕翻译
            let sourcelanguage = "";
            let targetlanguage = "";
            let mode = "";
            // if (
            //   !this.form.srturl ||
            //   !this.form.srtinput ||
            //   this.form.activeName == "2"
            // ) {
            //   //   视频翻译
            //   mode = "1";
            // } else {
            //   //   字幕翻译
            //   mode = "2";
            // }
            // sourcelanguage = this.form.videoLanguage;
            // targetlanguage = this.form.targetlanguage;

            // let obj = {
            //   mode: mode,
            //   slang: sourcelanguage,
            //   tlang: targetlanguage,
            //   srt_path: this.form.srturl,
            //   srt_name: this.form.srtinput,
            //   mt: "",
            // };
            // this.uploadOSSMethod(1, obj);
            this.buttonLoading = true;
            this.hardSubtitleRecognition_fileList.forEach((i) => {
              this.uploadOSSBatchMethod(3, i);
            });
          } else {
            //   视频转写
            // this.uploadOSSMethod(2);
            this.buttonLoading = true;
            this.hardSubtitleRecognition_fileList.forEach((i) => {
              this.uploadOSSBatchMethod(3, i);
            });
          }
        }
        // this.buttonLoading = true;
        // this.hardSubtitleRecognition_fileList.forEach((i) => {
        //   this.uploadOSSBatchMethod(1, i);
        // });
        // this.allpercentage = (allpercentage/this.hardSubtitleRecognition_fileList.length)*100
      } else if (this.HardSubtitleRecognitionTab === "first") {
        if (!this.videoData.name) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage")
          );
          return;
        }
        if (this.form.checked) {
          //   视频翻译 + 字幕翻译
          let sourcelanguage = "";
          let targetlanguage = "";
          sourcelanguage = this.form.videoLanguage;
          targetlanguage = this.form.targetlanguage;
          if (!targetlanguage || !sourcelanguage) {
            this.$messages(
              "warning",
              this.$t("PersonalEndLanguage.warningmessage3")
            );
            return;
          } else if (targetlanguage === sourcelanguage) {
            this.$messages(
              "warning",
              this.$t("PersonalEndLanguage.warningmessage4")
            );
            return;
          }
          //选择有源语言srt 就必须上传
          if (
            this.form.activeName == "1" &&
            (!this.form.srturl || !this.form.srtinput)
          ) {
            this.$messages(
              "warning",
              this.$t("PersonalEndLanguage.warningmessage2")
            );
            return;
          }
        } else {
          //   视频转写
          if (!this.form.videoLanguage) {
            this.$messages(
              "warning",
              this.$t("PersonalEndLanguage.warningmessage1")
            );
            return;
          }
          //选择有源语言srt 就必须上传
          if (
            this.form.activeName == "1" &&
            (!this.form.srturl || !this.form.srtinput)
          ) {
            this.$messages(
              "warning",
              this.$t("PersonalEndLanguage.warningmessage2")
            );
            return;
          }
        }
        //选择硬字幕涂抹需要验证涂抹时长是否足够
        if (this.form.checkbox1) {
          console.log("验证涂抹时长是否足够", this.videotime);
          get_user_quota_info({
            action: "get_user_quota_info",
          }).then((res) => {
            if (res) {
              //如果选择高级涂抹，需验证高级涂抹时长是否足够
              if (this.form.checkbox3) {
                if (
                  !!res.info.daub.video_daub_pro_minute == false ||
                  res.info.daub.video_daub_pro_minute * 60 < this.videotime
                ) {
                  this.$messages(
                    "warning",
                    this.$t("PersonalEndLanguage.warningmessage53")
                  );
                  return;
                }
              } else {
                if (
                  !!res.info.daub.video_daub_minute == false ||
                  res.info.daub.video_daub_minute * 60 < this.videotime
                ) {
                  this.$messages(
                    "warning",
                    this.$t("PersonalEndLanguage.warningmessage42")
                  );
                  return;
                }
              }

              //选择硬字幕涂抹就要跳转到硬字幕识别
              showLoading(this.$t("CommonName.loadingmessage"));
              get_ocr_last_job({ action: "get_ocr_last_job" }).then((res) => {
                if (res) {
                  this.initHardSubtitleRecognition();
                  this.initHardSubtitleRecognitionMethod();
                  this.initVideo(res.position);
                  hideLoading();
                }
              });
            }
          });
        } else if (this.form.radio2 === "2") {
          // 跳转硬字幕识别
          showLoading(this.$t("CommonName.loadingmessage"));
          get_ocr_last_job({ action: "get_ocr_last_job" }).then((res) => {
            if (res) {
              this.initHardSubtitleRecognition();
              this.initHardSubtitleRecognitionMethod();
              this.initVideo(res.position);
              hideLoading();
            }
          });
        } else {
          console.log("直接创建");
          if (this.form.checked) {
            //   视频翻译 + 字幕翻译
            let sourcelanguage = "";
            let targetlanguage = "";
            let mode = "";
            if (
              !this.form.srturl ||
              !this.form.srtinput ||
              this.form.activeName == "2"
            ) {
              //   视频翻译
              mode = "1";
            } else {
              //   字幕翻译
              mode = "2";
            }
            sourcelanguage = this.form.videoLanguage;
            targetlanguage = this.form.targetlanguage;

            let obj = {
              mode: mode,
              slang: sourcelanguage,
              tlang: targetlanguage,
              srt_path: this.form.srturl,
              srt_name: this.form.srtinput,
              mt: "",
            };
            this.buttonLoading = true;
            // this.$refs.upload.submit();
            this.uploadOSSMethod(1, obj);
          } else {
            //   视频转写
            this.buttonLoading = true;
            // this.$refs.upload.submit();
            this.uploadOSSMethod(2);
          }
        }
      } else if (this.HardSubtitleRecognitionTab === "six") {
        if (this.hardSubtitleRecognition_fileList.length === 0) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage")
          );
          return;
        }
        const resultObj = this.hardSubtitleRecognition_fileList.find(
          (obj) => !obj.srtinput || !obj.srturl
        );
        if (resultObj) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage2")
          );
          return;
        }
        if (this.form10.checked) {
          //   视频翻译 + 字幕翻译
          let sourcelanguage = "";
          let targetlanguage = "";
          sourcelanguage = this.form10.videoLanguage;
          targetlanguage = this.form10.targetlanguage;
          if (!targetlanguage || !sourcelanguage) {
            this.$messages(
              "warning",
              this.$t("PersonalEndLanguage.warningmessage3")
            );
            return;
          } else if (targetlanguage === sourcelanguage) {
            this.$messages(
              "warning",
              this.$t("PersonalEndLanguage.warningmessage4")
            );
            return;
          }
        } else {
          //   视频转写
          if (!this.form10.videoLanguage) {
            this.$messages(
              "warning",
              this.$t("PersonalEndLanguage.warningmessage1")
            );
            return;
          }
        }
        //选择硬字幕涂抹需要验证涂抹时长是否足够
        if (this.form10.checkbox1) {
          console.log("验证涂抹时长是否足够", this.fileTotalDuration);
          get_user_quota_info({
            action: "get_user_quota_info",
          }).then((res) => {
            if (res) {
              //如果选择高级涂抹，需验证高级涂抹时长是否足够
              if (this.form10.checkbox3) {
                if (
                  !!res.info.daub.video_daub_pro_minute == false ||
                  res.info.daub.video_daub_pro_minute * 60 <
                    this.fileTotalDuration
                ) {
                  this.$messages(
                    "warning",
                    this.$t("PersonalEndLanguage.warningmessage53")
                  );
                  return;
                }
              } else {
                if (
                  !!res.info.daub.video_daub_minute == false ||
                  res.info.daub.video_daub_minute * 60 < this.fileTotalDuration
                ) {
                  this.$messages(
                    "warning",
                    this.$t("PersonalEndLanguage.warningmessage42")
                  );
                  return;
                }
              }
              // 跳转硬字幕识别
              this.hardSubtitleRecognition_fileList.forEach((i, index) => {
                i.videoHeight = 0;
                i.videoWidth = 0;
                i.slidervalue = 0;
                i.startTime = "";
                i.endTime = "";
                i.marks = {};
                i.rect = null;
                i.canvas = null;
                i.position = {};
                i.new_id = index;
              });
              showLoading(this.$t("CommonName.loadingmessage"));
              get_ocr_last_job({ action: "get_ocr_last_job" }).then((res) => {
                if (res) {
                  this.initHardSubtitleRecognition();
                  this.hardSubtitleRecognition_fileList.forEach((i) => {
                    i.position = res.position;
                  });
                  this.innerVisible = true;
                  let video = null;
                  this.$nextTick(() => {
                    this.dialogtitle =
                      this.hardSubtitleRecognition_fileList[0].name;
                    this.videoUrl = URL.createObjectURL(
                      this.hardSubtitleRecognition_fileList[0].raw
                    );
                    video = document.createElement("video");
                    video.src = this.videoUrl;
                    document.body.appendChild(video);
                    var v = this.$refs.videoref;
                    document.body.removeChild(video);
                    // console.log(window.devicePixelRatio, " * window.screen.availHeight");
                    this.windowdev = window.devicePixelRatio;
                  });

                  // console.log(this.windowdev);
                  this.initVideo(
                    this.hardSubtitleRecognition_fileList[0].position
                  );
                  hideLoading();
                }
              });
            }
          });
        } else {
          // console.log("直接创建", this.hardSubtitleRecognition_fileList);
          // console.log("直接创建", this.form10);
          // return;
          this.buttonLoading = true;
          this.hardSubtitleRecognition_fileList.forEach((i) => {
            this.uploadOSSBatchMethod(5, i);
          });
        }
      }
    },
    /**
     * OSS自定义单个上传
     * @param file
     */
    uploadOSSMethod(id, obj) {
      // console.log("uploadOSSMethod.marks", Object.keys(this.marks));
      // console.log("this.videotime", this.videotime);
      // showLoading(this.$t("PersonalEndLanguage.loadingMessage"));
      // return false;
      //是否选择涂抹
      let isdaub = 0;
      if (id === 4 && this.form8.checkbox1) {
        isdaub = 1;
      } else if (id === 2 && this.form.checkbox1) {
        isdaub = 1;
      } else if (id === 1 && this.form.checkbox1) {
        isdaub = 1;
      }
      get_oss_policy({
        action: "get_policy_upload_info",
        daub: isdaub, //1是选择涂抹，其他未选择
      }).then((resu) => {
        if (resu) {
          // console.log(resu);
          // return false;
          let that = this;
          let file = this.videoData.file.raw;
          let filename = getFileNameUUID() + getFileName(file.name);
          let data = resu.info;
          // let resu = {
          //   policy:
          //     "eyJleHBpcmF0aW9uIjoiMjAyNC0xMC0xNFQwNzoxMTozMC4wMDBaIiwiY29uZGl0aW9ucyI6W1siY29udGVudC1sZW5ndGgtcmFuZ2UiLDAsMzIyMTIyNTQ3Ml0sWyJzdGFydHMtd2l0aCIsIiRrZXkiLCJwcml2YXRlXzBcLyJdXX0=",
          //   ossAccessKeyId: "LTAI4FdmBa8evmQuXNqkoijs",
          //   signature: "mmREYdkGs/fKIEL74CoY+PB+u6E=",
          //   dir: "private_0/",
          //   host: "http://wiimedia-zjk.oss-cn-zhangjiakou.aliyuncs.com/",
          // };
          const formData = new FormData();
          formData.append("name", filename);
          formData.append("policy", data.policy);
          formData.append("OSSAccessKeyId", data.ossAccessKeyId);
          formData.append("success_action_status", "200");
          formData.append("signature", data.signature);
          formData.append("key", data.dir + filename);
          // file必须为最后一个表单域，除file以外的其他表单域无顺序要求。
          formData.append("file", file);
          // 使用XMLHttpRequest手动上传分片
          const xhr = new XMLHttpRequest();
          xhr.open("POST", data.host, true);
          xhr.withCredentials = true;
          xhr.upload.onprogress = (event) => {
            if (event.lengthComputable) {
              // console.log("event.loaded", event.loaded);
              // console.log("event.total", event.total);
              // console.log("event", event);
              this.percentage = Math.round((event.loaded * 100) / event.total);
              this.loading = true;
            }
          };

          xhr.onerror = (error) => {
            this.$messages(
              "error",
              this.$t("PersonalEndLanguage.errormessage")
            );
            this.currentChunk = 0;
            this.percentage = 0;
            this.loading = false;
            this.buttonLoading = false;
            this.dialogVisible = false;
            // this.$refs.upload.active[this.uploadFile.uid].percentage = 0;
          };
          xhr.onreadystatechange = (event) => {
            //status状态为200 表示请求成功，readystate为4表示对象传递完毕或者准备就绪
            if (xhr.readyState == 4 && xhr.status == 200) {
              that.$messages("success", that.$t("CommonName.successmessage5"));
              // console.log(res.savename, "文件上传完成！");
              // console.log(data.host + data.dir + filename);
              let savepath = data.host + data.dir + filename;
              this.currentChunk = 0;
              this.percentage = 0;
              this.loading = false;
              showLoading(this.$t("PersonalEndLanguage.loadingMessage"));
              if (id === 4 && savepath !== "") {
                let x = "";
                let y = "";
                let width = "";
                let height = "";
                if (obj.id === 1) {
                  //字幕涂抹
                  if (this.form8.checkbox1) {
                    let num = this.videoHeight / 360;
                    x = Math.ceil(this.rect.left * num);
                    y = Math.ceil(this.rect.top * num);
                    width = Math.ceil(this.rect.width * this.rect.scaleX * num);
                    height = Math.ceil(
                      this.rect.height * this.rect.scaleY * num
                    );
                  }
                  create_video_dubbing_job({
                    action: "create_video_dubbing_job_oss",
                    slang: this.form8.videoLanguage
                      ? this.form8.videoLanguage
                      : "",
                    srt_path: this.form8.srturl ? this.form8.srturl : "",
                    srt_name: this.form8.srtinput ? this.form8.srtinput : "",
                    savename: filename,
                    savepath: savepath,
                    duration: this.videotime,
                    size: this.videoData.file.size,
                    mode: this.HardSubtitleRecognitionTab === "first" ? 1 : 2,
                    media_file_name: this.videoData.name,
                    video_dubbing_mode: this.form8.radio3,
                    speaker_number:
                      this.form8.radio3 === "2" ? this.form8.select3 : "0",
                    category:
                      this.project_id === 2 ? 0 : this.project_id === 9 ? 1 : 2,
                    subtitle_smearing: this.form8.checkbox1 ? 1 : 0, //字幕涂抹
                    subtitle_suppression: this.form8.checkbox2 ? 1 : 0, //字幕压制
                    subtitle_smearing_mode: this.form8.checkbox3 ? 1 : 0, //字幕涂抹
                    clone_dubbing_mode: this.form8.check_clone_mode ? 1 : 0,
                    dubbing_vocals_name:
                      this.form8.audioName != ""
                        ? this.form8.dubbing_vocals_name
                        : "", //人声音频名称
                    dubbing_vocals_path:
                      this.form8.audioName != ""
                        ? this.form8.dubbing_vocals_path
                        : "", //人声音频地址
                    dubbing_novocals_name:
                      this.form8.audioName1 != ""
                        ? this.form8.dubbing_novocals_name
                        : "", //国际音轨音频名称
                    dubbing_novocals_path:
                      this.form8.audioName1 != ""
                        ? this.form8.dubbing_novocals_path
                        : "", ///国际音轨音频地址
                    position_x: x,
                    position_y: y,
                    position_w: width,
                    position_h: height,
                    original_width: this.videoWidth,
                    original_height: this.videoHeight,
                    folder_type: this.isAddFolder ? 1 : 0, //是否新建文件夹
                    folder_id: this.AddFolderID, //文件夹ID
                    folder_name: this.AddFolderName, //新建文件夹名称
                  })
                    .then((res) => {
                      if (!res) {
                        this.videoData.file.status = "ready";
                        this.buttonLoading = false;
                        return;
                      }
                      if (res.result == "SUCCESS") {
                        this.get_last_job().then(() => {
                          this.$messages(
                            "success",
                            this.$t("PersonalEndLanguage.successMessage")
                          );

                          // let pathInfo = this.$router.resolve({
                          //   path: "/ContainerHome/VideoDubbing",
                          //   query: {
                          //     jid: res.job_id,
                          //   },
                          // });
                          // // console.log(pathInfo.href);
                          // window.open(pathInfo.href, "_blank");
                          this.dialogVisible = false;
                          this.buttonLoading = false;
                          this.innerVisible = false;
                          hideLoading();
                          this.initData();
                        });
                      } else if (res.result == "LOGIN_FAILED") {
                        this.otherResult();
                      } else if (res.result == "SPACE_FAILED") {
                        this.otherResult2(res);
                      } else {
                        this.otherResult1(res);
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                } else {
                  //硬字幕识别 或 字幕涂抹
                  if (this.form8.radio2 === "2" || this.form8.checkbox1) {
                    let num = this.videoHeight / 360;
                    x = Math.ceil(this.rect.left * num);
                    y = Math.ceil(this.rect.top * num);
                    width = Math.ceil(this.rect.width * this.rect.scaleX * num);
                    height = Math.ceil(
                      this.rect.height * this.rect.scaleY * num
                    );
                  }
                  create_video_dubbing_job_new({
                    category:
                      this.project_id === 2 ? 0 : this.project_id === 9 ? 1 : 2,
                    slang: this.form8.select2 ? this.form8.select2 : "",
                    tlang: this.form8.videoLanguage
                      ? this.form8.videoLanguage
                      : "",
                    stt_mode:
                      this.form8.activeName === "1"
                        ? "1"
                        : this.form8.radio2 === "1"
                        ? "2"
                        : "3",
                    media_file_name: this.videoData.name,
                    srt_path:
                      this.form8.activeName === "1"
                        ? this.form8.srturl1
                          ? this.form8.srturl1
                          : ""
                        : "",
                    srt_name:
                      this.form8.activeName === "1"
                        ? this.form8.srtinput1
                          ? this.form8.srtinput1
                          : ""
                        : "",
                    savename: filename,
                    savepath: savepath,
                    duration: this.videotime,
                    size: this.videoData.file.size,
                    action: "create_video_dubbing_job_new_oss",
                    video_dubbing_mode: this.form8.radio3,
                    speaker_number:
                      this.form8.radio3 === "2" ? this.form8.select3 : "0",
                    position_x: x,
                    position_y: y,
                    position_w: width,
                    position_h: height,
                    position_original_x:
                      this.form8.radio2 === "2" ? this.rect.left : "",
                    position_original_y:
                      this.form8.radio2 === "2" ? this.rect.top : "",
                    position_original_w:
                      this.form8.radio2 === "2"
                        ? this.rect.width * this.rect.scaleX
                        : "",
                    position_original_h:
                      this.form8.radio2 === "2"
                        ? this.rect.height * this.rect.scaleY
                        : "",
                    original_width: this.videoWidth,
                    original_height: this.videoHeight,
                    time_start:
                      this.form8.radio2 === "2"
                        ? this.startTime
                          ? this.startTime
                          : ""
                        : "",
                    time_end:
                      this.form8.radio2 === "2"
                        ? this.endTime
                          ? this.endTime
                          : this.formatTime(this.silderMax)
                        : "",
                    subtitle_smearing: this.form8.checkbox1 ? 1 : 0, //字幕涂抹
                    subtitle_suppression: this.form8.checkbox2 ? 1 : 0, //字幕压制
                    subtitle_smearing_mode: this.form8.checkbox3 ? 1 : 0, //字幕涂抹
                    clone_dubbing_mode: this.form8.check_clone_mode ? 1 : 0,
                    dubbing_vocals_name:
                      this.form8.audioName != ""
                        ? this.form8.dubbing_vocals_name
                        : "", //人声音频名称
                    dubbing_vocals_path:
                      this.form8.audioName != ""
                        ? this.form8.dubbing_vocals_path
                        : "", //人声音频地址
                    dubbing_novocals_name:
                      this.form8.audioName1 != ""
                        ? this.form8.dubbing_novocals_name
                        : "", //国际音轨音频名称
                    dubbing_novocals_path:
                      this.form8.audioName1 != ""
                        ? this.form8.dubbing_novocals_path
                        : "", ///国际音轨音频地址
                    folder_type: this.isAddFolder ? 1 : 0, //是否新建文件夹
                    folder_id: this.AddFolderID, //文件夹ID
                    folder_name: this.AddFolderName, //新建文件夹名称
                  })
                    .then((res) => {
                      if (!res) {
                        this.videoData.file.status = "ready";
                        this.buttonLoading = false;
                        return;
                      }
                      if (res.result == "SUCCESS") {
                        this.get_last_job().then(() => {
                          this.$messages(
                            "success",
                            this.$t("PersonalEndLanguage.successMessage")
                          );
                          hideLoading();
                          this.dialogVisible = false;
                          this.buttonLoading = false;
                          this.innerVisible = false;
                          this.initData();
                        });
                      } else if (res.result == "LOGIN_FAILED") {
                        this.otherResult();
                      } else if (
                        res.result == "QUOTE_FAILED" ||
                        res.result == "SPACE_FAILED"
                      ) {
                        this.otherResult2(res);
                      } else {
                        this.otherResult1(res);
                      }
                    })
                    .catch((err) => {});
                }
              } else if (id === 3 && savepath !== "") {
                console.log("音频转写");
                //音频转写任务
                create_asr_job({
                  action: "create_asr_audio_job_oss",
                  slang: this.form4.videoLanguage,
                  savename: filename,
                  savepath: savepath,
                  duration: this.videotime,
                  size: this.videoData.file.size,
                  media_file_name: this.videoData.name,
                  folder_type: this.isAddFolder ? 1 : 0, //是否新建文件夹
                  folder_id: this.AddFolderID, //文件夹ID
                  folder_name: this.AddFolderName, //新建文件夹名称
                }).then((res) => {
                  if (!res) {
                    hideLoading();
                    this.videoData.file.status = "ready";
                    this.buttonLoading = false;
                    return;
                  }
                  if (res.result == "SUCCESS") {
                    this.get_last_job().then(() => {
                      this.$messages(
                        "success",
                        this.$t("PersonalEndLanguage.successMessage")
                      );
                      hideLoading();
                      this.dialogVisible = false;
                      this.buttonLoading = false;
                      this.initData();
                    });
                  } else if (res.result == "LOGIN_FAILED") {
                    this.otherResult();
                  } else if (
                    res.result == "QUOTE_FAILED" ||
                    res.result == "SPACE_FAILED"
                  ) {
                    this.otherResult2(res);
                  } else {
                    this.otherResult1(res);
                  }
                });
              } else if (id === 2 && savepath !== "") {
                //视频转写任务
                //mode:1表示自动识别，2表示上传srt，3硬字幕识别
                let mode =
                  this.form.radio2 === "2"
                    ? 3
                    : this.form.activeName == "1" && this.form.srtinput
                    ? 2
                    : 1;
                create_asr_job({
                  action: "create_asr_job_oss",
                  mode: mode,
                  slang: this.form.videoLanguage,
                  srt_path: this.form.srturl ? this.form.srturl : "",
                  srt_name: this.form.srtinput ? this.form.srtinput : "",
                  savename: filename,
                  savepath: savepath,
                  duration: this.videotime,
                  size: this.videoData.file.size,
                  media_file_name: this.videoData.name,
                  position_original_x:
                    this.form.radio2 === "2" || this.form.checkbox1
                      ? this.rect.left
                      : "",
                  position_original_y:
                    this.form.radio2 === "2" || this.form.checkbox1
                      ? this.rect.top
                      : "",
                  position_original_w:
                    this.form.radio2 === "2" || this.form.checkbox1
                      ? this.rect.width * this.rect.scaleX
                      : "",
                  position_original_h:
                    this.form.radio2 === "2" || this.form.checkbox1
                      ? this.rect.height * this.rect.scaleY
                      : "",
                  original_width: this.videoWidth,
                  original_height: this.videoHeight,
                  time_start:
                    this.form.radio2 === "2" || this.form.checkbox1
                      ? this.startTime
                        ? this.startTime
                        : ""
                      : "",
                  time_end:
                    this.form.radio2 === "2" || this.form.checkbox1
                      ? this.endTime
                        ? this.endTime
                        : this.formatTime(this.silderMax)
                      : "",
                  subtitle_smearing: this.form.checkbox1 ? 1 : 0, //字幕涂抹
                  subtitle_suppression: this.form.checkbox2 ? 1 : 0, //字幕压制
                  subtitle_smearing_mode: this.form.checkbox3 ? 1 : 0, //字幕涂抹
                  folder_type: this.isAddFolder ? 1 : 0, //是否新建文件夹
                  folder_id: this.AddFolderID, //文件夹ID
                  folder_name: this.AddFolderName, //新建文件夹名称
                }).then((res) => {
                  if (!res) {
                    this.videoData.file.status = "ready";
                    this.buttonLoading = false;
                    this.innerVisible = false;
                    this.dialogtitle = this.$t(
                      "PersonalEndLanguage.Videotranscription"
                    );
                    return;
                  }
                  if (res.result == "SUCCESS") {
                    this.get_last_job().then(() => {
                      this.$messages(
                        "success",
                        this.$t("PersonalEndLanguage.successMessage")
                      );
                      hideLoading();
                      this.dialogVisible = false;
                      this.buttonLoading = false;
                      this.innerVisible = false;
                      this.initData();
                    });
                  } else if (res.result == "LOGIN_FAILED") {
                    this.otherResult();
                  } else if (
                    res.result == "QUOTE_FAILED" ||
                    res.result == "SPACE_FAILED"
                  ) {
                    this.otherResult2(res);
                  } else {
                    this.otherResult1(res);
                  }
                });
              } else if (id === 1 && savepath !== "") {
                //mode:1表示视频翻译，2表示字幕翻译srt，3硬字幕识别
                create_trans_job({
                  action: "create_trans_job_oss",
                  mode: obj.mode,
                  slang: obj.slang,
                  tlang: obj.tlang,
                  srt_path: obj.srt_path,
                  srt_name: obj.srt_name,
                  mt: obj.mt,
                  savename: filename,
                  savepath: savepath,
                  duration: this.videotime,
                  size: this.videoData.file.size,
                  media_file_name: this.videoData.name,
                  position_original_x:
                    this.form.radio2 === "2" || this.form.checkbox1
                      ? this.rect.left
                      : "",
                  position_original_y:
                    this.form.radio2 === "2" || this.form.checkbox1
                      ? this.rect.top
                      : "",
                  position_original_w:
                    this.form.radio2 === "2" || this.form.checkbox1
                      ? this.rect.width * this.rect.scaleX
                      : "",
                  position_original_h:
                    this.form.radio2 === "2" || this.form.checkbox1
                      ? this.rect.height * this.rect.scaleY
                      : "",
                  original_width: this.videoWidth,
                  original_height: this.videoHeight,
                  time_start:
                    this.form.radio2 === "2" || this.form.checkbox1
                      ? this.startTime
                        ? this.startTime
                        : ""
                      : "",
                  time_end:
                    this.form.radio2 === "2" || this.form.checkbox1
                      ? this.endTime
                        ? this.endTime
                        : this.formatTime(this.silderMax)
                      : "",
                  subtitle_smearing: this.form.checkbox1 ? 1 : 0, //字幕涂抹
                  subtitle_suppression: this.form.checkbox2 ? 1 : 0, //字幕压制
                  subtitle_smearing_mode: this.form.checkbox3 ? 1 : 0, //字幕涂抹
                  folder_type: this.isAddFolder ? 1 : 0, //是否新建文件夹
                  folder_id: this.AddFolderID, //文件夹ID
                  folder_name: this.AddFolderName, //新建文件夹名称
                }).then((res) => {
                  if (!res) {
                    this.videoData.file.status = "ready";
                    this.buttonLoading = false;
                    this.innerVisible = false;
                    this.dialogtitle = this.$t(
                      "PersonalEndLanguage.Videotranscription"
                    );
                    return;
                  }
                  if (res.result == "SUCCESS") {
                    this.get_last_job().then(() => {
                      this.$messages(
                        "success",
                        this.$t("PersonalEndLanguage.successMessage")
                      );
                      hideLoading();
                      this.dialogVisible = false;
                      this.buttonLoading = false;
                      this.innerVisible = false;
                      this.initData();
                    });
                  } else if (res.result == "LOGIN_FAILED") {
                    this.otherResult();
                  } else if (
                    res.result == "QUOTE_FAILED" ||
                    res.result == "SPACE_FAILED"
                  ) {
                    this.otherResult2(res);
                  } else {
                    this.otherResult1(res);
                  }
                });
              } else if (id === 5 && savepath !== "") {
                console.log("硬字幕识别");
                create_ocr_job({
                  action: "create_ocr_job_oss",
                  slang: obj.slang,
                  tlang: obj.tlang,
                  savename: filename,
                  savepath: savepath,
                  duration: this.videotime,
                  size: this.videoData.file.size,
                  media_file_name: obj.media_file_name,
                  position_x: obj.position_x,
                  position_y: obj.position_y,
                  position_w: obj.position_w,
                  position_h: obj.position_h,
                  position_original_x: obj.position_original_x,
                  position_original_y: obj.position_original_y,
                  position_original_w: obj.position_original_w,
                  position_original_h: obj.position_original_h,
                  original_width: obj.original_width,
                  original_height: obj.original_height,
                  time_start: obj.time_start,
                  time_end: obj.time_end,
                  folder_type: this.isAddFolder ? 1 : 0, //是否新建文件夹
                  folder_id: this.AddFolderID, //文件夹ID
                  folder_name: this.AddFolderName, //新建文件夹名称
                })
                  .then((res) => {
                    if (!res) {
                      this.videoData.file.status = "ready";
                      this.buttonLoading = false;
                      return;
                    }
                    if (res.result == "SUCCESS") {
                      this.get_last_job().then(() => {
                        this.$messages(
                          "success",
                          this.$t("PersonalEndLanguage.successMessage")
                        );
                        hideLoading();
                        this.dialogVisible = false;
                        this.buttonLoading = false;
                        this.innerVisible = false;
                        this.initData();
                      });
                    } else if (res.result == "LOGIN_FAILED") {
                      this.otherResult();
                    } else if (
                      res.result == "QUOTE_FAILED" ||
                      res.result == "SPACE_FAILED"
                    ) {
                      this.otherResult2(res);
                    } else {
                      this.otherResult1(res);
                    }
                  })
                  .catch((err) => {
                    this.$messages("error", err.msg);
                  });
              } else if (id === 6 && savepath !== "") {
                console.log("字幕对齐");
                // 字幕对齐 创建任务
                create_sub_align_job({
                  action: "create_sub_align_job_oss",
                  slang: obj.slang,
                  txt_path: obj.txt_path,
                  txt_name: obj.txt_name,
                  savename: filename,
                  savepath: savepath,
                  duration: this.videotime,
                  size: this.videoData.file.size,
                  media_file_name: this.videoData.name,
                  folder_type: this.isAddFolder ? 1 : 0, //是否新建文件夹
                  folder_id: this.AddFolderID, //文件夹ID
                  folder_name: this.AddFolderName, //新建文件夹名称
                })
                  .then((res) => {
                    if (!res) {
                      this.videoData.file.status = "ready";
                      this.buttonLoading = false;
                      return;
                    }
                    if (res.result == "SUCCESS") {
                      this.get_last_job().then(() => {
                        this.$messages(
                          "success",
                          this.$t("PersonalEndLanguage.successMessage")
                        );
                        hideLoading();
                        this.dialogVisible = false;
                        this.buttonLoading = false;
                        this.innerVisible = false;
                        this.initData();
                      });
                    } else if (res.result == "LOGIN_FAILED") {
                      this.otherResult();
                    } else if (
                      res.result == "QUOTE_FAILED" ||
                      res.result == "SPACE_FAILED"
                    ) {
                      this.otherResult2(res);
                    } else {
                      this.otherResult1(res);
                    }
                  })
                  .catch((err) => {
                    this.$messages("error", err.msg);
                  });
              }
            }
          };
          xhr.send(formData);
        }
      });
    },
    /**
     * OSS自定义批量上传
     * @param quick_mode 1:快捷模式；0：普通模式
     */
    uploadOSSBatchMethod(id, obj, quick_mode = 0) {
      // console.log("obj", obj);
      // return;
      //是否选择涂抹
      let isdaub = 0;
      if (id === 1 && this.form9.checkbox1) {
        isdaub = 1;
      } else if (id === 3 && this.form1.checkbox1) {
        isdaub = 1;
      } else if (id === 5 && this.form10.checkbox1) {
        isdaub = 1;
      }
      get_oss_policy({
        action: "get_policy_upload_info",
        daub: isdaub, //1是选择涂抹，其他未选择
      }).then((resu) => {
        if (resu) {
          // console.log(resu);
          // return false;
          let that = this;
          let file = obj.raw;
          let filename = getFileNameUUID() + getFileName(file.name);
          let data = resu.info;
          // let resu = {
          //   policy:
          //     "eyJleHBpcmF0aW9uIjoiMjAyNC0xMC0xNFQwNzoxMTozMC4wMDBaIiwiY29uZGl0aW9ucyI6W1siY29udGVudC1sZW5ndGgtcmFuZ2UiLDAsMzIyMTIyNTQ3Ml0sWyJzdGFydHMtd2l0aCIsIiRrZXkiLCJwcml2YXRlXzBcLyJdXX0=",
          //   ossAccessKeyId: "LTAI4FdmBa8evmQuXNqkoijs",
          //   signature: "mmREYdkGs/fKIEL74CoY+PB+u6E=",
          //   dir: "private_0/",
          //   host: "http://wiimedia-zjk.oss-cn-zhangjiakou.aliyuncs.com/",
          // };
          const formData = new FormData();
          formData.append("name", filename);
          formData.append("policy", data.policy);
          formData.append("OSSAccessKeyId", data.ossAccessKeyId);
          formData.append("success_action_status", "200");
          formData.append("signature", data.signature);
          formData.append("key", data.dir + filename);
          // file必须为最后一个表单域，除file以外的其他表单域无顺序要求。
          formData.append("file", file);
          // 使用XMLHttpRequest手动上传分片
          const xhr = new XMLHttpRequest();
          xhr.open("POST", data.host, true);
          xhr.withCredentials = true;
          xhr.upload.onprogress = (event) => {
            if (event.lengthComputable) {
              // console.log("event.loaded", event.loaded);
              // console.log("event.total", event.total);
              obj.independence_percentage = Math.floor(
                (event.loaded / event.total) * 100
              );
              obj.percentage = event.loaded;
              this.loading = true;
            }
          };

          xhr.onerror = (error) => {
            this.$messages(
              "error",
              this.$t("PersonalEndLanguage.errormessage")
            );
            obj.currentChunk = 0;
            obj.percentage = 0;
            this.percentage = 0;
            this.loading = false;
            this.buttonLoading = false;
            this.dialogVisible = false;
            this.innerVisible = false;
          };
          xhr.onreadystatechange = (event) => {
            //status状态为200 表示请求成功，readystate为4表示对象传递完毕或者准备就绪
            if (xhr.readyState == 4 && xhr.status == 200) {
              // that.$messages("success", that.$t("CommonName.successmessage5"));
              // console.log(res.savename, "文件上传完成！");
              console.log(data.host + data.dir + filename);
              let savepath = data.host + data.dir + filename;
              if (id === 1) {
                //配音
                const isAll = this.hardSubtitleRecognition_fileList.every(
                  (obj) => obj.independence_percentage == 100
                );
                if (isAll) {
                  this.loading = false;
                  showLoading(this.$t("PersonalEndLanguage.loadingMessage"));
                }
              } else if (id === 2) {
                //硬字幕
                const isAll1 = this.hardSubtitleRecognition_fileList.every(
                  (obj) => obj.independence_percentage == 100
                );
                if (isAll1) {
                  this.loading = false;
                  showLoading(this.$t("PersonalEndLanguage.loadingMessage"));
                }
              } else if (id === 3) {
                //视频AI
                const isAll1 = this.hardSubtitleRecognition_fileList.every(
                  (obj) => obj.independence_percentage == 100
                );
                if (isAll1) {
                  this.loading = false;
                  showLoading(this.$t("PersonalEndLanguage.loadingMessage"));
                }
              } else if (id === 4) {
                const isAll = this.filelist.every(
                  (obj) => obj.independence_percentage == 100
                );
                if (isAll) {
                  this.loading = false;
                  showLoading(this.$t("PersonalEndLanguage.loadingMessage"));
                }
              } else if (id === 5) {
                //视频AI
                const isAll1 = this.hardSubtitleRecognition_fileList.every(
                  (obj) => obj.independence_percentage == 100
                );
                if (isAll1) {
                  this.loading = false;
                  showLoading(this.$t("PersonalEndLanguage.loadingMessage"));
                }
              }
              if (savepath !== "") {
                if (id === 2) {
                  //硬字幕
                  let num = obj.videoHeight / 360;
                  let x = Math.ceil(obj.rect.left * num);
                  let y = Math.ceil(obj.rect.top * num);
                  let width = Math.ceil(obj.rect.width * obj.rect.scaleX * num);
                  let height = Math.ceil(
                    obj.rect.height * obj.rect.scaleY * num
                  );
                  create_ocr_job({
                    action: "create_ocr_job_oss",
                    slang: obj.videoLanguage,
                    tlang: 0,
                    savename: filename,
                    savepath: savepath,
                    duration: obj.videotime,
                    size: obj.size,
                    media_file_name: obj.name,
                    position_x: x,
                    position_y: y,
                    position_w: width,
                    position_h: height,
                    position_original_x: obj.rect.left,
                    position_original_y: obj.rect.top,
                    position_original_w: obj.rect.width * obj.rect.scaleX,
                    position_original_h: obj.rect.height * obj.rect.scaleY,
                    original_width: obj.videoWidth,
                    original_height: obj.videoHeight,
                    time_start: obj.startTime ? obj.startTime : "",
                    time_end: obj.endTime
                      ? obj.endTime
                      : obj.formatTime(obj.silderMax),
                    folder_type: this.isAddFolder ? 1 : 0, //是否新建文件夹
                    folder_id: this.AddFolderID, //文件夹ID
                    folder_name: this.AddFolderName, //新建文件夹名称
                    quick_mode,
                  })
                    .then((res) => {
                      if (!res) {
                        this.videoData.file.status = "ready";
                        this.buttonLoading = false;
                        return;
                      }
                      if (res.result == "SUCCESS") {
                        const isAll =
                          this.hardSubtitleRecognition_fileList.every(
                            (obj) => obj.independence_percentage == 100
                          );
                        if (isAll) {
                          this.get_last_job().then(() => {
                            this.$messages(
                              "success",
                              this.$t("PersonalEndLanguage.successMessage")
                            );
                            hideLoading();
                            this.dialogVisible = false;
                            this.buttonLoading = false;
                            this.innerVisible = false;
                            this.initData();
                          });
                        }
                      } else if (res.result == "LOGIN_FAILED") {
                        this.otherResult();
                      } else if (
                        res.result == "QUOTE_FAILED" ||
                        res.result == "SPACE_FAILED"
                      ) {
                        this.otherResult2(res);
                      } else {
                        this.otherResult1(res);
                      }
                    })
                    .catch((err) => {
                      this.$messages("error", err.msg);
                    });
                } else if (id == 3) {
                  let action = "create_asr_job_oss";
                  //mode:1表示自动识别，2表示上传srt，3硬字幕识别
                  let mode =
                    this.form1.radio2 === "2"
                      ? 3
                      : this.form1.activeName == "1" && this.form1.srtinput
                      ? 2
                      : 1;
                  if (this.form1.checked) {
                    //视频AI翻译
                    action = "create_trans_job_oss";
                    mode = this.form1.radio2 === "2" ? 3 : 1;
                  }
                  //视频AI
                  create_trans_job({
                    action: action,
                    mode: mode,
                    slang: this.form1.videoLanguage,
                    tlang: this.form1.targetlanguage,
                    srt_path: "",
                    srt_name: "",
                    mt: "",
                    savename: filename,
                    savepath: savepath,
                    duration: obj.videotime,
                    size: obj.size,
                    media_file_name: obj.name,
                    position_original_x:
                      this.form1.radio2 === "2" || this.form1.checkbox1
                        ? obj.rect.left
                        : "",
                    position_original_y:
                      this.form1.radio2 === "2" || this.form1.checkbox1
                        ? obj.rect.top
                        : "",
                    position_original_w:
                      this.form1.radio2 === "2" || this.form1.checkbox1
                        ? obj.rect.width * obj.rect.scaleX
                        : "",
                    position_original_h:
                      this.form1.radio2 === "2" || this.form1.checkbox1
                        ? obj.rect.height * obj.rect.scaleY
                        : "",
                    original_width: obj.videoWidth,
                    original_height: obj.videoHeight,
                    time_start:
                      this.form1.radio2 === "2" || this.form1.checkbox1
                        ? obj.startTime
                          ? obj.startTime
                          : ""
                        : "",
                    time_end:
                      this.form1.radio2 === "2" || this.form1.checkbox1
                        ? obj.endTime
                          ? obj.endTime
                          : this.formatTime(obj.silderMax)
                        : "",
                    subtitle_smearing: this.form1.checkbox1 ? 1 : 0, //字幕涂抹
                    subtitle_suppression: this.form1.checkbox2 ? 1 : 0, //字幕压制
                    subtitle_smearing_mode: this.form1.checkbox3 ? 1 : 0, //字幕涂抹
                    folder_type: this.isAddFolder ? 1 : 0, //是否新建文件夹
                    folder_id: this.AddFolderID, //文件夹ID
                    folder_name: this.AddFolderName, //新建文件夹名称
                    quick_mode,
                  }).then((res) => {
                    if (!res) {
                      this.videoData.file.status = "ready";
                      this.buttonLoading = false;
                      return;
                    }
                    if (res.result == "SUCCESS") {
                      const isAll = this.hardSubtitleRecognition_fileList.every(
                        (obj) => obj.independence_percentage == 100
                      );
                      if (isAll) {
                        this.get_last_job().then(() => {
                          this.$messages(
                            "success",
                            this.$t("PersonalEndLanguage.successMessage")
                          );
                          hideLoading();
                          this.dialogVisible = false;
                          this.buttonLoading = false;
                          this.innerVisible = false;
                          this.initData();
                        });
                      }
                    } else if (res.result == "LOGIN_FAILED") {
                      this.otherResult();
                    } else if (
                      res.result == "QUOTE_FAILED" ||
                      res.result == "SPACE_FAILED"
                    ) {
                      this.otherResult2(res);
                    } else {
                      this.otherResult1(res);
                    }
                  });
                } else if (id == 1) {
                  //配音
                  //硬字幕识别 或 字幕涂抹
                  let x = "";
                  let y = "";
                  let width = "";
                  let height = "";
                  if (this.form9.radio2 === "2" || this.form9.checkbox1) {
                    let num = this.videoHeight / 360;
                    x = Math.ceil(this.rect.left * num);
                    y = Math.ceil(this.rect.top * num);
                    width = Math.ceil(this.rect.width * this.rect.scaleX * num);
                    height = Math.ceil(
                      this.rect.height * this.rect.scaleY * num
                    );
                  }
                  create_video_dubbing_job_new({
                    category:
                      this.project_id === 2 ? 0 : this.project_id === 9 ? 1 : 2,
                    slang: this.form9.sourceLanguage,
                    tlang: this.form9.videoLanguage
                      ? this.form9.videoLanguage
                      : "",
                    stt_mode: this.form9.radio2 === "1" ? "2" : "3",
                    srt_path: "",
                    srt_name: "",
                    savename: filename,
                    savepath: savepath,
                    duration: obj.videotime,
                    size: obj.size,
                    media_file_name: obj.name,
                    action: "create_video_dubbing_job_new_oss",
                    video_dubbing_mode: "",
                    speaker_number: "0",
                    position_x: x,
                    position_y: y,
                    position_w: width,
                    position_h: height,
                    position_original_x:
                      this.form9.radio2 === "2" ? obj.rect.left : "",
                    position_original_y:
                      this.form9.radio2 === "2" ? obj.rect.top : "",
                    position_original_w:
                      this.form9.radio2 === "2"
                        ? obj.rect.width * obj.rect.scaleX
                        : "",
                    position_original_h:
                      this.form9.radio2 === "2"
                        ? obj.rect.height * obj.rect.scaleY
                        : "",
                    original_width: obj.videoWidth,
                    original_height: obj.videoHeight,
                    time_start:
                      this.form9.radio2 === "2"
                        ? obj.startTime
                          ? obj.startTime
                          : ""
                        : "",
                    time_end:
                      this.form9.radio2 === "2"
                        ? obj.endTime
                          ? obj.endTime
                          : this.formatTime(obj.silderMax)
                        : "",
                    subtitle_smearing: this.form9.checkbox1 ? 1 : 0, //字幕涂抹
                    subtitle_suppression: this.form9.checkbox2 ? 1 : 0, //字幕压制
                    subtitle_smearing_mode: this.form9.checkbox3 ? 1 : 0, //字幕涂抹
                    dubbing_vocals_name: "", //人声音频名称
                    dubbing_vocals_path: "", //人声音频地址
                    dubbing_novocals_name: "", //国际音轨音频名称
                    dubbing_novocals_path: "", ///国际音轨音频地址
                    folder_type: this.isAddFolder ? 1 : 0, //是否新建文件夹
                    folder_id: this.AddFolderID, //文件夹ID
                    folder_name: this.AddFolderName, //新建文件夹名称
                    clone_dubbing_mode: this.form9.check_clone_mode ? 1 : 0,
                    quick_mode,
                  })
                    .then((res) => {
                      if (!res) {
                        this.videoData.file.status = "ready";
                        this.buttonLoading = false;
                        return;
                      }
                      if (res.result == "SUCCESS") {
                        const isAll =
                          this.hardSubtitleRecognition_fileList.every(
                            (obj) => obj.independence_percentage === 100
                          );
                        if (isAll) {
                          this.get_last_job().then(() => {
                            if (this.uploadSuccessflag) {
                              this.$messages(
                                "success",
                                this.$t("PersonalEndLanguage.successMessage")
                              );
                              this.uploadSuccessflag = false;
                            }
                            hideLoading();
                            this.dialogVisible = false;
                            this.buttonLoading = false;
                            this.innerVisible = false;
                            this.initData();
                          });
                        }
                      } else if (res.result == "LOGIN_FAILED") {
                        this.percentage = 0;
                        this.loading = false;
                        hideLoading();
                        this.$messages(
                          "warning",
                          this.$t("CommonName.tokenMessage")
                        );
                        this.$router.push("/Login");
                        this.$Cookie.remove("token_code");
                        sessionStorage.clear();
                      } else if (res.result == "SPACE_FAILED") {
                        this.percentage = 0;
                        this.loading = false;
                        hideLoading();
                        this.quotetype = res.type;
                        this.buttonLoading = false;
                      } else {
                        this.percentage = 0;
                        this.loading = false;
                        // console.log(22222222);
                        // this.quotetype = response.type;
                        hideLoading();
                        this.$messages("error", res.msg);
                        this.buttonLoading = false;
                        this.dialogVisible = false;
                        this.innerVisible = false;
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                } else if (id == 4) {
                  //配音
                  create_video_dubbing_job({
                    action: "create_video_dubbing_job_oss",
                    slang: obj.videoLanguage,
                    srt_path: obj.srturl,
                    srt_name: obj.srtinput,
                    savename: filename,
                    savepath: savepath,
                    duration: obj.videotime,
                    size: obj.size,
                    mode: 1,
                    media_file_name: obj.name,
                    category:
                      this.project_id === 2 ? 0 : this.project_id === 9 ? 1 : 2,
                    folder_type: this.isAddFolder ? 1 : 0, //是否新建文件夹
                    folder_id: this.AddFolderID, //文件夹ID
                    folder_name: this.AddFolderName, //新建文件夹名称
                  })
                    .then((res) => {
                      if (!res) {
                        this.buttonLoading = false;
                        return;
                      }
                      if (res.result == "SUCCESS") {
                        const isAll = this.filelist.every(
                          (obj) => obj.independence_percentage === 100
                        );
                        if (isAll) {
                          this.get_last_job().then(() => {
                            if (this.uploadSuccessflag) {
                              this.$messages(
                                "success",
                                this.$t("PersonalEndLanguage.successMessage")
                              );
                              this.uploadSuccessflag = false;
                            }
                            hideLoading();
                            this.dialogVisible = false;
                            this.buttonLoading = false;
                            this.initData();
                          });
                        }
                      } else if (res.result == "LOGIN_FAILED") {
                        this.percentage = 0;
                        this.loading = false;
                        hideLoading();
                        this.$messages(
                          "warning",
                          this.$t("CommonName.tokenMessage")
                        );
                        this.$router.push("/Login");
                        this.$Cookie.remove("token_code");
                        sessionStorage.clear();
                      } else if (res.result == "SPACE_FAILED") {
                        this.percentage = 0;
                        this.loading = false;
                        hideLoading();
                        this.quotetype = res.type;
                        this.buttonLoading = false;
                      } else {
                        this.percentage = 0;
                        this.loading = false;
                        // console.log(22222222);
                        // this.quotetype = response.type;
                        hideLoading();
                        this.$messages("error", res.msg);
                        this.buttonLoading = false;
                        this.dialogVisible = false;
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                } else if (id == 5) {
                  let action = "create_asr_job_oss";
                  //mode:2表示上传srt
                  let mode = 2;
                  if (this.form10.checked) {
                    //视频AI翻译
                    action = "create_trans_job_oss";
                  }
                  //视频AI
                  create_trans_job({
                    action: action,
                    mode: mode,
                    slang: this.form10.videoLanguage,
                    tlang: this.form10.targetlanguage,
                    srt_path: obj.srturl,
                    srt_name: obj.srtinput,
                    mt: "",
                    savename: filename,
                    savepath: savepath,
                    duration: obj.videotime,
                    size: obj.size,
                    media_file_name: obj.name,
                    position_original_x: this.form10.checkbox1
                      ? obj.rect.left
                      : "",
                    position_original_y: this.form10.checkbox1
                      ? obj.rect.top
                      : "",
                    position_original_w: this.form10.checkbox1
                      ? obj.rect.width * obj.rect.scaleX
                      : "",
                    position_original_h: this.form10.checkbox1
                      ? obj.rect.height * obj.rect.scaleY
                      : "",
                    original_width: obj.videoWidth,
                    original_height: obj.videoHeight,
                    time_start: "",
                    time_end: "",
                    subtitle_smearing: this.form10.checkbox1 ? 1 : 0, //字幕涂抹
                    subtitle_suppression: this.form10.checkbox2 ? 1 : 0, //字幕压制
                    subtitle_smearing_mode: this.form10.checkbox3 ? 1 : 0, //字幕涂抹
                    folder_type: this.isAddFolder ? 1 : 0, //是否新建文件夹
                    folder_id: this.AddFolderID, //文件夹ID
                    folder_name: this.AddFolderName, //新建文件夹名称
                    quick_mode,
                  }).then((res) => {
                    if (!res) {
                      hideLoading();
                      this.videoData.file.status = "ready";
                      this.buttonLoading = false;
                      return;
                    }
                    if (res.result == "SUCCESS") {
                      const isAll = this.hardSubtitleRecognition_fileList.every(
                        (obj) => obj.independence_percentage == 100
                      );
                      if (isAll) {
                        this.get_last_job().then(() => {
                          this.$messages(
                            "success",
                            this.$t("PersonalEndLanguage.successMessage")
                          );
                          hideLoading();
                          this.dialogVisible = false;
                          this.buttonLoading = false;
                          this.innerVisible = false;
                          this.initData();
                        });
                      }
                    } else if (res.result == "LOGIN_FAILED") {
                      this.otherResult();
                    } else if (
                      res.result == "QUOTE_FAILED" ||
                      res.result == "SPACE_FAILED"
                    ) {
                      this.otherResult2(res);
                    } else {
                      this.otherResult1(res);
                    }
                  });
                }
              }
            }
          };
          xhr.send(formData);
        }
      });
    },
    /**
     * 批量上传 视频配音
     * @param obj
     */
    uploadList(obj, data) {
      obj.totalChunks = Math.ceil(obj.size / this.chunkSize);
      const start = obj.currentChunk * this.chunkSize;
      const end = Math.min(start + this.chunkSize, obj.raw.size);
      const chunk = obj.raw.slice(start, end);
      const formData = new FormData();
      formData.append("file", chunk);
      formData.append("totalChunks", obj.totalChunks);
      formData.append("currentChunk", obj.currentChunk);
      formData.append("media_file_name", obj.id);
      // 使用XMLHttpRequest手动上传分片
      const xhr = new XMLHttpRequest();
      xhr.open(
        "POST",
        `${this.$uploadURL}/job/fragment_upload_media_file`,
        true
      );
      xhr.withCredentials = true;
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const uploaded = start + event.loaded; // 已上传的大小
          const total = obj.size; // 文件总大小
          obj.independence_percentage = Math.floor((uploaded / total) * 100);
          obj.percentage = uploaded;
          this.loading = true;
        }
      };
      xhr.onerror = (error) => {
        this.$messages("error", this.$t("PersonalEndLanguage.errormessage"));
        obj.currentChunk = 0;
        obj.percentage = 0;
        this.percentage = 0;
        this.loading = false;
        this.buttonLoading = false;
        this.dialogVisible = false;
        this.innerVisible = false;
      };
      xhr.onload = () => {
        if (xhr.status === 200) {
          obj.currentChunk++;
          if (obj.currentChunk < obj.totalChunks) {
            // 继续上传下一个分片
            this.uploadList(obj, data);
          } else {
            let res = JSON.parse(xhr.response);
            // console.log(res.savename, "文件上传完成！");

            if (data === 1) {
              const isAll = this.hardSubtitleRecognition_fileList.every(
                (obj) => obj.independence_percentage == 100
              );
              if (isAll) {
                this.loading = false;
                showLoading(this.$t("PersonalEndLanguage.loadingMessage"));
              }
            } else {
              const isAll1 = this.hardSubtitleRecognition_fileList.every(
                (obj) => obj.independence_percentage == 100
              );
              if (isAll1) {
                this.loading = false;
                showLoading(this.$t("PersonalEndLanguage.loadingMessage"));
              }
            }

            // obj.currentChunk = 0;
            // this.percentage = 0;
            // obj.percentage = 0;
            // this.loading = false;
            // console.log(id, res.savename);
            // showLoading("文件已上传完毕，正在解析中... ");
            if (res && res.savename !== "") {
              if (data === 2) {
                let num = obj.videoHeight / 360;
                let x = Math.ceil(obj.rect.left * num);
                let y = Math.ceil(obj.rect.top * num);
                let width = Math.ceil(obj.rect.width * obj.rect.scaleX * num);
                let height = Math.ceil(obj.rect.height * obj.rect.scaleY * num);
                create_ocr_job({
                  action: "create_ocr_job",
                  slang: obj.videoLanguage,
                  tlang: 0,
                  savename: res.savename,
                  media_file_name: obj.name,
                  position_x: x,
                  position_y: y,
                  position_w: width,
                  position_h: height,
                  position_original_x: obj.rect.left,
                  position_original_y: obj.rect.top,
                  position_original_w: obj.rect.width * obj.rect.scaleX,
                  position_original_h: obj.rect.height * obj.rect.scaleY,
                  original_width: obj.videoWidth,
                  original_height: obj.videoHeight,
                  time_start: obj.startTime ? obj.startTime : "",
                  time_end: obj.endTime
                    ? obj.endTime
                    : obj.formatTime(obj.silderMax),
                })
                  .then((res) => {
                    if (!res) {
                      this.videoData.file.status = "ready";
                      this.buttonLoading = false;
                      return;
                    }
                    if (res.result == "SUCCESS") {
                      const isAll = this.hardSubtitleRecognition_fileList.every(
                        (obj) => obj.independence_percentage == 100
                      );
                      if (isAll) {
                        this.get_last_job().then(() => {
                          this.$messages(
                            "success",
                            this.$t("PersonalEndLanguage.successMessage")
                          );
                          hideLoading();
                          this.dialogVisible = false;
                          this.buttonLoading = false;
                          this.innerVisible = false;
                          this.initData();
                        });
                      }
                    } else if (res.result == "LOGIN_FAILED") {
                      this.otherResult();
                    } else if (
                      res.result == "QUOTE_FAILED" ||
                      res.result == "SPACE_FAILED"
                    ) {
                      this.otherResult2(res);
                    } else {
                      this.otherResult1(res);
                    }
                  })
                  .catch((err) => {
                    this.$messages("error", err.msg);
                  });
              } else {
                create_video_dubbing_job({
                  action: "create_video_dubbing_job",
                  slang: obj.videoLanguage,
                  srt_path: obj.srturl,
                  srt_name: obj.srtinput,
                  savename: res.savename,
                  mode: 1,
                  media_file_name: obj.name,
                  category:
                    this.project_id === 2 ? 0 : this.project_id === 9 ? 1 : 2,
                })
                  .then((res) => {
                    if (!res) {
                      this.buttonLoading = false;
                      return;
                    }
                    if (res.result == "SUCCESS") {
                      const isAll = this.hardSubtitleRecognition_fileList.every(
                        (obj) => obj.independence_percentage === 100
                      );
                      if (isAll) {
                        this.get_last_job().then(() => {
                          if (this.uploadSuccessflag) {
                            this.$messages(
                              "success",
                              this.$t("PersonalEndLanguage.successMessage")
                            );
                            this.uploadSuccessflag = false;
                          }
                          hideLoading();
                          this.dialogVisible = false;
                          this.buttonLoading = false;
                          this.initData();
                        });
                      }
                    } else if (res.result == "LOGIN_FAILED") {
                      this.percentage = 0;
                      this.loading = false;
                      hideLoading();
                      this.$messages(
                        "warning",
                        this.$t("CommonName.tokenMessage")
                      );
                      this.$router.push("/Login");
                      this.$Cookie.remove("token_code");
                      sessionStorage.clear();
                    } else if (res.result == "SPACE_FAILED") {
                      this.percentage = 0;
                      this.loading = false;
                      hideLoading();
                      this.quotetype = res.type;
                      this.buttonLoading = false;
                    } else {
                      this.percentage = 0;
                      this.loading = false;
                      // console.log(22222222);
                      // this.quotetype = response.type;
                      hideLoading();
                      this.$messages("error", res.msg);
                      this.buttonLoading = false;
                      this.dialogVisible = false;
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            }
          }
        }
      };
      xhr.send(formData);
    },
    /**
     * 创建项目 不成功 的其中一个情况
     */
    otherResult() {
      this.percentage = 0;
      this.loading = false;
      hideLoading();
      this.$messages("warning", this.$t("CommonName.tokenMessage"));
      this.$router.push("/Login");
      this.$Cookie.remove("token_code");
      sessionStorage.clear();
    },
    /**
     * 创建项目 不成功 的其中一个情况
     * @param res
     */
    otherResult1(res) {
      this.percentage = 0;
      this.loading = false;
      this.videoData.file.status = "ready";
      // this.quotetype = response.type;
      hideLoading();
      this.$messages("error", res.msg);
      this.buttonLoading = false;
      this.dialogVisible = false;
      this.innerVisible = false;
    },
    /**
     * 创建项目 不成功 的其中一个情况
     * @param res
     */
    otherResult2(res) {
      this.videoData.file.status = "ready";
      this.percentage = 0;
      this.loading = false;
      hideLoading();
      this.quotetype = res.type;
      this.buttonLoading = false;
    },
    /**
     * 自定义上传
     * @param file
     */
    uploadNextChunk(id, obj) {
      const start = this.currentChunk * this.chunkSize;
      const end = Math.min(
        start + this.chunkSize,
        this.videoData.file.raw.size
      );
      const chunk = this.videoData.file.raw.slice(start, end);

      const formData = new FormData();
      formData.append("file", chunk);
      formData.append("totalChunks", this.totalChunks);
      formData.append("currentChunk", this.currentChunk);
      if (id === 4) {
        formData.append("media_file_name", obj.media_file_name);
      } else {
        formData.append("media_file_name", this.videoData.name);
      }

      // 使用XMLHttpRequest手动上传分片
      const xhr = new XMLHttpRequest();

      xhr.open(
        "POST",
        `${this.$uploadURL}/job/fragment_upload_media_file`,
        true
      );
      xhr.withCredentials = true;
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const uploaded = start + event.loaded; // 已上传的大小
          const total = this.videoData.file.size; // 文件总大小
          this.percentage = Math.floor((uploaded / total) * 100); // 计算上传进度
          // console.log(",percentCompleted", percentCompleted);
          this.loading = true;
        }
      };

      xhr.onerror = (error) => {
        this.$messages("error", this.$t("PersonalEndLanguage.errormessage"));
        this.currentChunk = 0;
        this.percentage = 0;
        this.loading = false;
        this.buttonLoading = false;
        this.dialogVisible = false;
        // this.$refs.upload.active[this.uploadFile.uid].percentage = 0;
      };

      xhr.onload = () => {
        if (xhr.status === 200) {
          this.currentChunk++;
          if (this.currentChunk < this.totalChunks) {
            // 继续上传下一个分片
            this.uploadNextChunk(id, obj);
          } else {
            let res = JSON.parse(xhr.response);
            // console.log(res.savename, "文件上传完成！");
            this.currentChunk = 0;
            this.percentage = 0;
            this.loading = false;
            // console.log(id, res.savename);
            showLoading(this.$t("PersonalEndLanguage.loadingMessage"));
            if (id === 4 && res && res.savename !== "") {
              let x = "";
              let y = "";
              let width = "";
              let height = "";
              if (obj.id === 1) {
                //字幕涂抹
                if (this.form8.checkbox1) {
                  let num = this.videoHeight / 360;
                  x = Math.ceil(this.rect.left * num);
                  y = Math.ceil(this.rect.top * num);
                  width = Math.ceil(this.rect.width * this.rect.scaleX * num);
                  height = Math.ceil(this.rect.height * this.rect.scaleY * num);
                }
                create_video_dubbing_job({
                  action: "create_video_dubbing_job",
                  slang: this.form8.videoLanguage
                    ? this.form8.videoLanguage
                    : "",
                  srt_path: this.form8.srturl ? this.form8.srturl : "",
                  srt_name: this.form8.srtinput ? this.form8.srtinput : "",
                  savename: res.savename,
                  mode: this.HardSubtitleRecognitionTab === "first" ? 1 : 2,
                  media_file_name: this.videoData.name,
                  video_dubbing_mode: this.form8.radio3,
                  speaker_number:
                    this.form8.radio3 === "2" ? this.form8.select3 : "0",
                  category:
                    this.project_id === 2 ? 0 : this.project_id === 9 ? 1 : 2,
                  subtitle_smearing: this.form8.checkbox1 ? 1 : 0, //字幕涂抹
                  subtitle_suppression: this.form8.checkbox2 ? 1 : 0, //字幕压制
                  subtitle_smearing_mode: this.form8.checkbox3 ? 1 : 0, //字幕涂抹
                  dubbing_vocals_name:
                    this.form8.audioName != ""
                      ? this.form8.dubbing_vocals_name
                      : "", //人声音频名称
                  dubbing_vocals_path:
                    this.form8.audioName != ""
                      ? this.form8.dubbing_vocals_path
                      : "", //人声音频地址
                  dubbing_novocals_name:
                    this.form8.audioName1 != ""
                      ? this.form8.dubbing_novocals_name
                      : "", //国际音轨音频名称
                  dubbing_novocals_path:
                    this.form8.audioName1 != ""
                      ? this.form8.dubbing_novocals_path
                      : "", ///国际音轨音频地址
                  position_x: x,
                  position_y: y,
                  position_w: width,
                  position_h: height,
                  original_width: this.videoWidth,
                  original_height: this.videoHeight,
                })
                  .then((res) => {
                    if (!res) {
                      this.videoData.file.status = "ready";
                      this.buttonLoading = false;
                      return;
                    }
                    if (res.result == "SUCCESS") {
                      this.get_last_job().then(() => {
                        this.$messages(
                          "success",
                          this.$t("PersonalEndLanguage.successMessage")
                        );

                        // let pathInfo = this.$router.resolve({
                        //   path: "/ContainerHome/VideoDubbing",
                        //   query: {
                        //     jid: res.job_id,
                        //   },
                        // });
                        // // console.log(pathInfo.href);
                        // window.open(pathInfo.href, "_blank");
                        this.dialogVisible = false;
                        this.buttonLoading = false;
                        this.innerVisible = false;
                        hideLoading();
                        this.initData();
                      });
                    } else if (res.result == "LOGIN_FAILED") {
                      this.otherResult();
                    } else if (res.result == "SPACE_FAILED") {
                      this.otherResult2(res);
                    } else {
                      this.otherResult1(res);
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              } else {
                //硬字幕识别 或 字幕涂抹
                if (this.form8.radio2 === "2" || this.form8.checkbox1) {
                  let num = this.videoHeight / 360;
                  x = Math.ceil(this.rect.left * num);
                  y = Math.ceil(this.rect.top * num);
                  width = Math.ceil(this.rect.width * this.rect.scaleX * num);
                  height = Math.ceil(this.rect.height * this.rect.scaleY * num);
                }
                create_video_dubbing_job_new({
                  category:
                    this.project_id === 2 ? 0 : this.project_id === 9 ? 1 : 2,
                  slang: this.form8.select2 ? this.form8.select2 : "",
                  tlang: this.form8.videoLanguage
                    ? this.form8.videoLanguage
                    : "",
                  stt_mode:
                    this.form8.activeName === "1"
                      ? "1"
                      : this.form8.radio2 === "1"
                      ? "2"
                      : "3",
                  media_file_name: this.videoData.name,
                  srt_path:
                    this.form8.activeName === "1"
                      ? this.form8.srturl1
                        ? this.form8.srturl1
                        : ""
                      : "",
                  srt_name:
                    this.form8.activeName === "1"
                      ? this.form8.srtinput1
                        ? this.form8.srtinput1
                        : ""
                      : "",
                  savename: res.savename,
                  action: "create_video_dubbing_job_new",
                  video_dubbing_mode: this.form8.radio3,
                  speaker_number:
                    this.form8.radio3 === "2" ? this.form8.select3 : "0",
                  position_x: x,
                  position_y: y,
                  position_w: width,
                  position_h: height,
                  position_original_x:
                    this.form8.radio2 === "2" ? this.rect.left : "",
                  position_original_y:
                    this.form8.radio2 === "2" ? this.rect.top : "",
                  position_original_w:
                    this.form8.radio2 === "2"
                      ? this.rect.width * this.rect.scaleX
                      : "",
                  position_original_h:
                    this.form8.radio2 === "2"
                      ? this.rect.height * this.rect.scaleY
                      : "",
                  original_width: this.videoWidth,
                  original_height: this.videoHeight,
                  time_start:
                    this.form8.radio2 === "2"
                      ? this.startTime
                        ? this.startTime
                        : ""
                      : "",
                  time_end:
                    this.form8.radio2 === "2"
                      ? this.endTime
                        ? this.endTime
                        : this.formatTime(this.silderMax)
                      : "",
                  subtitle_smearing: this.form8.checkbox1 ? 1 : 0, //字幕涂抹
                  subtitle_suppression: this.form8.checkbox2 ? 1 : 0, //字幕压制
                  subtitle_smearing_mode: this.form8.checkbox3 ? 1 : 0, //字幕涂抹
                  dubbing_vocals_name:
                    this.form8.audioName != ""
                      ? this.form8.dubbing_vocals_name
                      : "", //人声音频名称
                  dubbing_vocals_path:
                    this.form8.audioName != ""
                      ? this.form8.dubbing_vocals_path
                      : "", //人声音频地址
                  dubbing_novocals_name:
                    this.form8.audioName1 != ""
                      ? this.form8.dubbing_novocals_name
                      : "", //国际音轨音频名称
                  dubbing_novocals_path:
                    this.form8.audioName1 != ""
                      ? this.form8.dubbing_novocals_path
                      : "", ///国际音轨音频地址
                })
                  .then((res) => {
                    if (!res) {
                      this.videoData.file.status = "ready";
                      this.buttonLoading = false;
                      return;
                    }
                    if (res.result == "SUCCESS") {
                      this.get_last_job().then(() => {
                        this.$messages(
                          "success",
                          this.$t("PersonalEndLanguage.successMessage")
                        );
                        hideLoading();
                        this.dialogVisible = false;
                        this.buttonLoading = false;
                        this.innerVisible = false;
                        this.initData();
                      });
                    } else if (res.result == "LOGIN_FAILED") {
                      this.otherResult();
                    } else if (
                      res.result == "QUOTE_FAILED" ||
                      res.result == "SPACE_FAILED"
                    ) {
                      this.otherResult2(res);
                    } else {
                      this.otherResult1(res);
                    }
                  })
                  .catch((err) => {});
              }
            } else if (id === 2 && res && res.savename !== "") {
              //视频转写任务
              //mode:1表示自动识别，2表示上传srt，3硬字幕识别
              let mode =
                this.form.radio2 === "2"
                  ? 3
                  : this.form.activeName == "1" && this.form.srtinput
                  ? 2
                  : 1;
              create_asr_job({
                action: "create_asr_job",
                mode: mode,
                slang: this.form.videoLanguage,
                srt_path: this.form.srturl ? this.form.srturl : "",
                srt_name: this.form.srtinput ? this.form.srtinput : "",
                savename: res.savename,
                media_file_name: this.videoData.name,
                position_original_x:
                  this.form.radio2 === "2" || this.form.checkbox1
                    ? this.rect.left
                    : "",
                position_original_y:
                  this.form.radio2 === "2" || this.form.checkbox1
                    ? this.rect.top
                    : "",
                position_original_w:
                  this.form.radio2 === "2" || this.form.checkbox1
                    ? this.rect.width * this.rect.scaleX
                    : "",
                position_original_h:
                  this.form.radio2 === "2" || this.form.checkbox1
                    ? this.rect.height * this.rect.scaleY
                    : "",
                original_width: this.videoWidth,
                original_height: this.videoHeight,
                time_start:
                  this.form.radio2 === "2" || this.form.checkbox1
                    ? this.startTime
                      ? this.startTime
                      : ""
                    : "",
                time_end:
                  this.form.radio2 === "2" || this.form.checkbox1
                    ? this.endTime
                      ? this.endTime
                      : this.formatTime(this.silderMax)
                    : "",
                subtitle_smearing: this.form.checkbox1 ? 1 : 0, //字幕涂抹
                subtitle_suppression: this.form.checkbox2 ? 1 : 0, //字幕压制
                subtitle_smearing_mode: this.form.checkbox3 ? 1 : 0, //字幕涂抹
              }).then((res) => {
                if (!res) {
                  this.videoData.file.status = "ready";
                  this.buttonLoading = false;
                  this.innerVisible = false;
                  this.dialogtitle = this.$t(
                    "PersonalEndLanguage.Videotranscription"
                  );
                  return;
                }
                if (res.result == "SUCCESS") {
                  this.get_last_job().then(() => {
                    this.$messages(
                      "success",
                      this.$t("PersonalEndLanguage.successMessage")
                    );
                    hideLoading();
                    this.dialogVisible = false;
                    this.buttonLoading = false;
                    this.innerVisible = false;
                    this.initData();
                  });
                } else if (res.result == "LOGIN_FAILED") {
                  this.otherResult();
                } else if (
                  res.result == "QUOTE_FAILED" ||
                  res.result == "SPACE_FAILED"
                ) {
                  this.otherResult2(res);
                } else {
                  this.otherResult1(res);
                }
              });
            } else if (id === 1 && res && res.savename !== "") {
              //mode:1表示视频翻译，2表示字幕翻译srt，3硬字幕识别
              create_trans_job({
                action: "create_trans_job",
                mode: obj.mode,
                slang: obj.slang,
                tlang: obj.tlang,
                srt_path: obj.srt_path,
                srt_name: obj.srt_name,
                mt: obj.mt,
                savename: res.savename,
                media_file_name: this.videoData.name,
                position_original_x:
                  this.form.radio2 === "2" || this.form.checkbox1
                    ? this.rect.left
                    : "",
                position_original_y:
                  this.form.radio2 === "2" || this.form.checkbox1
                    ? this.rect.top
                    : "",
                position_original_w:
                  this.form.radio2 === "2" || this.form.checkbox1
                    ? this.rect.width * this.rect.scaleX
                    : "",
                position_original_h:
                  this.form.radio2 === "2" || this.form.checkbox1
                    ? this.rect.height * this.rect.scaleY
                    : "",
                original_width: this.videoWidth,
                original_height: this.videoHeight,
                time_start:
                  this.form.radio2 === "2" || this.form.checkbox1
                    ? this.startTime
                      ? this.startTime
                      : ""
                    : "",
                time_end:
                  this.form.radio2 === "2" || this.form.checkbox1
                    ? this.endTime
                      ? this.endTime
                      : this.formatTime(this.silderMax)
                    : "",
                subtitle_smearing: this.form.checkbox1 ? 1 : 0, //字幕涂抹
                subtitle_suppression: this.form.checkbox2 ? 1 : 0, //字幕压制
                subtitle_smearing_mode: this.form.checkbox3 ? 1 : 0, //字幕涂抹
              }).then((res) => {
                if (!res) {
                  this.videoData.file.status = "ready";
                  this.buttonLoading = false;
                  this.innerVisible = false;
                  this.dialogtitle = this.$t(
                    "PersonalEndLanguage.Videotranscription"
                  );
                  return;
                }
                if (res.result == "SUCCESS") {
                  this.get_last_job().then(() => {
                    this.$messages(
                      "success",
                      this.$t("PersonalEndLanguage.successMessage")
                    );
                    hideLoading();
                    this.dialogVisible = false;
                    this.buttonLoading = false;
                    this.innerVisible = false;
                    this.initData();
                  });
                } else if (res.result == "LOGIN_FAILED") {
                  this.otherResult();
                } else if (
                  res.result == "QUOTE_FAILED" ||
                  res.result == "SPACE_FAILED"
                ) {
                  this.otherResult2(res);
                } else {
                  this.otherResult1(res);
                }
              });
            } else if (id === 5 && res && res.savename !== "") {
              create_ocr_job({
                action: "create_ocr_job",
                slang: obj.slang,
                tlang: obj.tlang,
                savename: res.savename,
                media_file_name: obj.media_file_name,
                position_x: obj.position_x,
                position_y: obj.position_y,
                position_w: obj.position_w,
                position_h: obj.position_h,
                position_original_x: obj.position_original_x,
                position_original_y: obj.position_original_y,
                position_original_w: obj.position_original_w,
                position_original_h: obj.position_original_h,
                original_width: obj.original_width,
                original_height: obj.original_height,
                time_start: obj.time_start,
                time_end: obj.time_end,
              })
                .then((res) => {
                  if (!res) {
                    this.videoData.file.status = "ready";
                    this.buttonLoading = false;
                    return;
                  }
                  if (res.result == "SUCCESS") {
                    this.get_last_job().then(() => {
                      this.$messages(
                        "success",
                        this.$t("PersonalEndLanguage.successMessage")
                      );
                      hideLoading();
                      this.dialogVisible = false;
                      this.buttonLoading = false;
                      this.innerVisible = false;
                      this.initData();
                    });
                  } else if (res.result == "LOGIN_FAILED") {
                    this.otherResult();
                  } else if (
                    res.result == "QUOTE_FAILED" ||
                    res.result == "SPACE_FAILED"
                  ) {
                    this.otherResult2(res);
                  } else {
                    this.otherResult1(res);
                  }
                })
                .catch((err) => {
                  this.$messages("error", err.msg);
                });
            } else if (id === 6 && res && res.savename !== "") {
              // 字幕对齐 创建任务
              create_sub_align_job({
                action: "create_sub_align_job",
                slang: obj.slang,
                txt_path: obj.txt_path,
                txt_name: obj.txt_name,
                savename: res.savename,
                media_file_name: this.videoData.name,
              })
                .then((res) => {
                  if (!res) {
                    this.videoData.file.status = "ready";
                    this.buttonLoading = false;
                    return;
                  }
                  if (res.result == "SUCCESS") {
                    this.get_last_job().then(() => {
                      this.$messages(
                        "success",
                        this.$t("PersonalEndLanguage.successMessage")
                      );
                      hideLoading();
                      this.dialogVisible = false;
                      this.buttonLoading = false;
                      this.innerVisible = false;
                      this.initData();
                    });
                  } else if (res.result == "LOGIN_FAILED") {
                    this.otherResult();
                  } else if (
                    res.result == "QUOTE_FAILED" ||
                    res.result == "SPACE_FAILED"
                  ) {
                    this.otherResult2(res);
                  } else {
                    this.otherResult1(res);
                  }
                })
                .catch((err) => {
                  this.$messages("error", err.msg);
                });
            }
          }
        }
      };
      xhr.send(formData);
    },
    /**
     * 创建 音频转写 任务
     */
    CreateAudiotranscription() {
      if (!this.form4.videoLanguage) {
        this.$messages(
          "warning",
          this.$t("PersonalEndLanguage.warningmessage5")
        );
        return;
      }
      this.buttonLoading = true;
      this.uploadOSSMethod(3);
    },
    /**
     * 创建 漫画快翻 任务
     */
    CreateCartoonQuickFlip() {
      this.uploadData = {
        job_name: this.videoData.name,
        tlang: this.form7.targetlanguage,
        slang: this.form7.sourcelanguage,
        text_direction: this.form7.textdirection,
      };
      if (!this.form7.targetlanguage || !this.form7.sourcelanguage) {
        this.$messages(
          "warning",
          this.$t("PersonalEndLanguage.warningmessage3")
        );
        return;
      } else if (this.form7.targetlanguage == this.form7.sourcelanguage) {
        this.$messages(
          "warning",
          this.$t("PersonalEndLanguage.warningmessage4")
        );
        return;
      }
      this.buttonLoading = true;
      this.actionURL = `${this.$uploadURL}/comic/create_private_comic_job`;
      this.$nextTick(() => {
        this.$refs.upload.submit();
      });
    },
    /**
     * 判断
     * @param obj
     * @param data
     * @returns {boolean}
     */
    checkEmpty(obj, data) {
      if (data === 1) {
        return obj.name === null || obj.name === undefined || obj.name === "";
      } else if (data === 2) {
        return obj.url === null || obj.url === undefined || obj.url === "";
      } else if (data === 3) {
        return (
          obj.srtinput === null ||
          obj.srtinput === undefined ||
          obj.srtinput === ""
        );
      } else if (data === 4) {
        return (
          obj.srturl === null || obj.srturl === undefined || obj.srturl === ""
        );
      } else if (data === 6) {
        let regExp =
          /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(\?\S*)?$/;
        return !regExp.test(obj.url);
      } else {
        return (
          obj.videoLanguage === null ||
          obj.videoLanguage === undefined ||
          obj.videoLanguage === ""
        );
      }
    },
    /**
     *  判断 视频配音 是否达成创建条件
     * @returns {boolean}
     */
    setAIVideoUploadConditions() {
      // 是否选择 配音语言
      if (!this.form8.videoLanguage) {
        this.$messages(
          "warning",
          this.$t("PersonalEndLanguage.warningmessage39")
        );
        return false;
      }
      //是否上传 配音 srt 文件
      if (this.form8.radio1 === -1) {
        this.$messages("warning", this.$t("CommonName.selectMessage"));
        return false;
      }
      // 选择 上传配音 srt 文件
      if (this.form8.radio1 === "1") {
        if (!this.form8.srtinput || !this.form8.srturl) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage2")
          );
          return false;
        }
      } else {
        //无配音srt 文件 =》 有源语言srt 文件
        if (this.form8.activeName === "1") {
          if (!this.form8.srtinput1 || !this.form8.srturl1) {
            this.$messages(
              "warning",
              this.$t("PersonalEndLanguage.warningmessage2")
            );
            return false;
          }
        }
        // 源语言
        if (!this.form8.select2) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage34")
          );
          return false;
        }
      }
      // 当 选择了上传与非上传srt 文件 进行判断
      let srtmaxtime = 0;
      this.form8.radio1 === "1"
        ? (srtmaxtime = this.form8.srtmaxtime)
        : (srtmaxtime = this.form8.srtmaxtime1);
      this.videotime = Math.ceil(this.videotime * 100) / 100;
      if (srtmaxtime > Number(this.videotime)) {
        this.$messages(
          "warning",
          this.$t("PersonalEndLanguage.warningmessage23")
        );
        return false;
      }
      return true;
    },
    /**
     * 创建 视频配音 任务
     */
    async CreateAIVideo() {
      if (this.HardSubtitleRecognitionTab === "third") {
        if (this.hardSubtitleRecognition_fileList.length === 0) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage")
          );
          return;
        }
        if (this.form9.videoLanguage == "" || this.form9.sourceLanguage == "") {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage49")
          );
          return;
        }
        if (this.form9.videoLanguage === this.form9.sourceLanguage) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.VideoDubbingText18")
          );
          return;
        }
        //选择硬字幕涂抹需要验证涂抹时长是否足够
        if (this.form9.checkbox1) {
          console.log("验证涂抹时长是否足够", this.fileTotalDuration);
          get_user_quota_info({
            action: "get_user_quota_info",
          }).then((res) => {
            if (res) {
              //如果选择高级涂抹，需验证高级涂抹时长是否足够
              if (this.form9.checkbox3) {
                if (
                  !!res.info.daub.video_daub_pro_minute == false ||
                  res.info.daub.video_daub_pro_minute * 60 <
                    this.fileTotalDuration
                ) {
                  this.$messages(
                    "warning",
                    this.$t("PersonalEndLanguage.warningmessage53")
                  );
                  return;
                }
              } else {
                if (
                  !!res.info.daub.video_daub_minute == false ||
                  res.info.daub.video_daub_minute * 60 < this.fileTotalDuration
                ) {
                  this.$messages(
                    "warning",
                    this.$t("PersonalEndLanguage.warningmessage42")
                  );
                  return;
                }
              }

              if (this.form9.videoLanguage === this.form9.sourceLanguage) {
                this.$messages(
                  "warning",
                  this.$t("PersonalEndLanguage.VideoDubbingText18")
                );
                return;
              }
              // 跳转硬字幕识别
              this.hardSubtitleRecognition_fileList.forEach((i, index) => {
                i.videoHeight = 0;
                i.videoWidth = 0;
                i.slidervalue = 0;
                i.startTime = "";
                i.endTime = "";
                i.marks = {};
                i.rect = null;
                i.canvas = null;
                i.position = {};
                i.new_id = index;
              });
              showLoading(this.$t("CommonName.loadingmessage"));
              get_ocr_last_job({ action: "get_ocr_last_job" }).then((res) => {
                if (res) {
                  this.initHardSubtitleRecognition();
                  this.hardSubtitleRecognition_fileList.forEach((i) => {
                    i.position = res.position;
                  });
                  this.innerVisible = true;
                  let video = null;
                  this.$nextTick(() => {
                    this.dialogtitle =
                      this.hardSubtitleRecognition_fileList[0].name;
                    this.videoUrl = URL.createObjectURL(
                      this.hardSubtitleRecognition_fileList[0].raw
                    );
                    video = document.createElement("video");
                    video.src = this.videoUrl;
                    document.body.appendChild(video);
                    var v = this.$refs.videoref;
                    document.body.removeChild(video);
                    // console.log(window.devicePixelRatio, " * window.screen.availHeight");
                    this.windowdev = window.devicePixelRatio;
                  });

                  // console.log(this.windowdev);
                  this.initVideo(
                    this.hardSubtitleRecognition_fileList[0].position
                  );
                  hideLoading();
                }
              });
            }
          });
        } else if (this.form9.radio2 === "2") {
          // 跳转硬字幕识别
          this.hardSubtitleRecognition_fileList.forEach((i, index) => {
            i.videoHeight = 0;
            i.videoWidth = 0;
            i.slidervalue = 0;
            i.startTime = "";
            i.endTime = "";
            i.marks = {};
            i.rect = null;
            i.canvas = null;
            i.position = {};
            i.new_id = index;
          });
          showLoading(this.$t("CommonName.loadingmessage"));
          get_ocr_last_job({ action: "get_ocr_last_job" }).then((res) => {
            if (res) {
              this.initHardSubtitleRecognition();
              this.hardSubtitleRecognition_fileList.forEach((i) => {
                i.position = res.position;
              });
              this.innerVisible = true;
              let video = null;
              this.$nextTick(() => {
                this.dialogtitle =
                  this.hardSubtitleRecognition_fileList[0].name;
                this.videoUrl = URL.createObjectURL(
                  this.hardSubtitleRecognition_fileList[0].raw
                );
                video = document.createElement("video");
                video.src = this.videoUrl;
                document.body.appendChild(video);
                var v = this.$refs.videoref;
                document.body.removeChild(video);
                // console.log(window.devicePixelRatio, " * window.screen.availHeight");
                this.windowdev = window.devicePixelRatio;
              });

              // console.log(this.windowdev);
              this.initVideo(this.hardSubtitleRecognition_fileList[0].position);
              hideLoading();
            }
          });
        } else {
          console.log("直接创建", this.hardSubtitleRecognition_fileList);
          this.buttonLoading = true;
          this.hardSubtitleRecognition_fileList.forEach((i) => {
            this.uploadOSSBatchMethod(1, i);
          });
        }
        // this.allpercentage = (allpercentage/this.hardSubtitleRecognition_fileList.length)*100
      } else if (this.HardSubtitleRecognitionTab === "four") {
        if (this.form8.tableData.length == 0) {
          return;
        }
        if (this.form8.tableData.some((obj) => this.checkEmpty(obj, 1))) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.youtubeTitle2")
          );
          return;
        }
        if (this.form8.tableData.some((obj) => this.checkEmpty(obj, 2))) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.youtubeTitle3")
          );
          return;
        }
        if (
          this.form8.tableData.some((obj) => this.checkEmpty(obj, 3)) ||
          this.form8.tableData.some((obj) => this.checkEmpty(obj, 4))
        ) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage2")
          );
          return;
        }
        if (this.form8.tableData.some((obj) => this.checkEmpty(obj, 5))) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage1")
          );
          return;
        }
        if (this.form8.tableData.some((obj) => this.checkEmpty(obj, 6))) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.Youtubevideo3")
          );

          return;
        }
        // let flag = true;
        // await this.form8.tableData.forEach((i) => {
        //   this.check_tube_video_info(i.url).then((res) => {
        //     flag = res;
        //     if (!res) {
        //       return;
        //     }
        //   });
        // });
        // console.log(flag);
        // if (!flag) {
        //   return;
        // }
        // return;
        showLoading(this.$t("PersonalEndLanguage.loadingMessage"));
        this.form8.tableData.forEach((i) => {
          this.create_video_dubbing_job_tube(i);
        });
      } else if (this.HardSubtitleRecognitionTab === "second") {
        if (!this.form8.srturl || !this.form8.srtinput) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage2")
          );
          return;
        }
        if (!this.form8.videoLanguage) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage1")
          );
          return;
        }
        if (this.form8.media_file_name1.trim() == "") {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage46")
          );
          return;
        }
        if (this.videoRadio < 0) {
          this.$messages("warning", this.$t("CommonName.selectMessage"));
          return;
        }
        showLoading(this.$t("PersonalEndLanguage.loadingMessage"));
        create_video_dubbing_job1({
          action: "create_video_dubbing_job",
          slang: this.form8.videoLanguage ? this.form8.videoLanguage : "",
          srt_path: this.form8.srturl ? this.form8.srturl : "",
          srt_name: this.form8.srtinput ? this.form8.srtinput : "",
          mode: 2,
          job_id: this.videoRadio,
          category: this.project_id === 2 ? 0 : this.project_id === 9 ? 1 : 2,
          media_file_name: this.form8.media_file_name1,
        })
          .then((res) => {
            if (!res) {
              this.buttonLoading = false;
              return;
            }
            if (res.result == "SUCCESS") {
              this.get_last_job().then(() => {
                this.$messages(
                  "success",
                  this.$t("PersonalEndLanguage.successMessage")
                );
                hideLoading();
                if (this.project_id === 2) {
                  let pathInfo = this.$router.resolve({
                    path: "/ContainerHome/VideoDubbing",
                    query: {
                      jid: res.job_id,
                    },
                  });
                  window.open(pathInfo.href, "_blank");
                } else {
                  let pathInfo = this.$router.resolve({
                    path: "/ContainerHome/VideoDubbingClone",
                    query: {
                      jid: res.job_id,
                    },
                  });
                  window.open(pathInfo.href, "_blank");
                }

                this.dialogVisible = false;
                this.buttonLoading = false;
                this.initData();
              });
            } else if (res.result == "LOGIN_FAILED") {
              this.percentage = 0;
              this.loading = false;
              hideLoading();
              this.$messages("warning", this.$t("CommonName.tokenMessage"));
              this.$router.push("/Login");
              this.$Cookie.remove("token_code");
              sessionStorage.clear();
            } else if (res.result == "SPACE_FAILED") {
              this.videoData.file.status = "ready";
              this.percentage = 0;
              this.loading = false;
              hideLoading();
              this.quotetype = res.type;
              this.buttonLoading = false;
            } else {
              this.percentage = 0;
              this.loading = false;
              this.videoData.file.status = "ready";
              // console.log(22222222);
              // this.quotetype = response.type;
              hideLoading();
              this.$messages("error", res.msg);
              this.buttonLoading = false;
              this.dialogVisible = false;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (this.HardSubtitleRecognitionTab === "five") {
        if (this.filelist.length === 0) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage")
          );
          return;
        }
        const resultObj = this.filelist.find(
          (obj) => !obj.srtinput || !obj.srturl
        );
        if (resultObj) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage2")
          );
          return;
        }
        const resultObj1 = this.filelist.find((obj) => !obj.videoLanguage);
        if (resultObj1) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage1")
          );
          return;
        }
        this.buttonLoading = true;
        this.filelist.forEach((i) => {
          this.uploadOSSBatchMethod(4, i);
        });
      } else if (this.HardSubtitleRecognitionTab === "first") {
        // 视频配音 上传 单个
        if (!this.videoData.name) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage")
          );
          return;
        }
        let flag = this.setAIVideoUploadConditions();
        if (!flag) {
          return;
        }
        //如果上传国际音轨与人声音轨 需要验证 音轨时长与视频时长是否一样，精确到秒
        if (this.form8.audioName != "") {
          let abs_c = Math.abs(
            this.videotime - +this.form8.dubbing_vocals_time
          );
          console.log("abs_c", abs_c);
          if (abs_c > 2) {
            this.$messages(
              "warning",
              this.$t("PersonalEndLanguage.warningmessage44")
            );
            return false;
          }
        }

        if (this.form8.audioName1 != "") {
          let abs_n = Math.abs(
            this.videotime - +this.form8.dubbing_novocals_time
          );
          console.log("abs_n", abs_n);
          if (abs_n > 2) {
            this.$messages(
              "warning",
              this.$t("PersonalEndLanguage.warningmessage45")
            );
            return false;
          }
        }

        //选择硬字幕涂抹需要验证涂抹时长是否足够
        if (this.form8.checkbox1) {
          console.log("验证涂抹时长是否足够", this.videotime);
          get_user_quota_info({
            action: "get_user_quota_info",
          }).then((res) => {
            if (res) {
              //如果选择高级涂抹，需验证高级涂抹时长是否足够
              if (this.form8.checkbox3) {
                if (
                  !!res.info.daub.video_daub_pro_minute == false ||
                  res.info.daub.video_daub_pro_minute * 60 < this.videotime
                ) {
                  this.$messages(
                    "warning",
                    this.$t("PersonalEndLanguage.warningmessage53")
                  );
                  return;
                }
              } else {
                if (
                  !!res.info.daub.video_daub_minute == false ||
                  res.info.daub.video_daub_minute * 60 < this.videotime
                ) {
                  this.$messages(
                    "warning",
                    this.$t("PersonalEndLanguage.warningmessage42")
                  );
                  return;
                }
              }
              if (this.form8.radio1 === "1") {
                //选择硬字幕涂抹就要跳转到硬字幕识别
                let video = null;
                // 跳转硬字幕识别
                showLoading(this.$t("CommonName.loadingmessage"));
                get_ocr_last_job({ action: "get_ocr_last_job" }).then((res) => {
                  if (res) {
                    this.initHardSubtitleRecognition();
                    this.initHardSubtitleRecognitionMethod();
                    // this.innerVisible = true;
                    // this.dialogtitle = this.videoData.name;
                    // this.videoUrl = URL.createObjectURL(
                    //   this.videoData.file.raw
                    // );
                    // video = document.createElement("video");
                    // video.src = this.videoUrl;
                    // document.body.appendChild(video);
                    // video.onloadedmetadata = () => {
                    //   this.videoHeight = video.videoHeight;
                    //   this.videoWidth = video.videoWidth;
                    // };
                    // document.body.removeChild(video);
                    // this.windowdev = window.devicePixelRatio;
                    this.initVideo(res.position);
                    hideLoading();
                  }
                });
              } else {
                if (this.form8.videoLanguage === this.form8.select2) {
                  this.$messages(
                    "warning",
                    this.$t("PersonalEndLanguage.VideoDubbingText18")
                  );
                  return;
                }
                if (this.form8.radio2 === "2") {
                  let video = null;
                  // 跳转硬字幕识别
                  showLoading(this.$t("CommonName.loadingmessage"));
                  get_ocr_last_job({ action: "get_ocr_last_job" }).then(
                    (res) => {
                      if (res) {
                        this.initHardSubtitleRecognition();
                        this.initHardSubtitleRecognitionMethod();
                        // this.innerVisible = true;
                        // this.dialogtitle = this.videoData.name;
                        // this.videoUrl = URL.createObjectURL(
                        //   this.videoData.file.raw
                        // );
                        // video = document.createElement("video");
                        // video.src = this.videoUrl;
                        // document.body.appendChild(video);
                        // video.onloadedmetadata = () => {
                        //   this.videoHeight = video.videoHeight;
                        //   this.videoWidth = video.videoWidth;
                        // };
                        // document.body.removeChild(video);
                        // this.windowdev = window.devicePixelRatio;
                        this.initVideo(res.position);
                        hideLoading();
                      }
                    }
                  );
                } else {
                  //选择硬字幕涂抹就要跳转到硬字幕识别
                  let video = null;
                  // 跳转硬字幕识别
                  showLoading(this.$t("CommonName.loadingmessage"));
                  get_ocr_last_job({ action: "get_ocr_last_job" }).then(
                    (res) => {
                      if (res) {
                        this.initHardSubtitleRecognition();
                        this.initHardSubtitleRecognitionMethod();
                        // this.innerVisible = true;
                        // this.dialogtitle = this.videoData.name;
                        // this.videoUrl = URL.createObjectURL(
                        //   this.videoData.file.raw
                        // );
                        // video = document.createElement("video");
                        // video.src = this.videoUrl;
                        // document.body.appendChild(video);
                        // video.onloadedmetadata = () => {
                        //   this.videoHeight = video.videoHeight;
                        //   this.videoWidth = video.videoWidth;
                        // };
                        // document.body.removeChild(video);
                        // this.windowdev = window.devicePixelRatio;
                        this.initVideo(res.position);
                        hideLoading();
                      }
                    }
                  );
                }
              }
            }
          });
        } else {
          if (this.form8.radio1 === "1") {
            //选择硬字幕涂抹就要跳转到硬字幕识别
            if (this.form8.checkbox1) {
              let video = null;
              // 跳转硬字幕识别
              showLoading(this.$t("CommonName.loadingmessage"));
              get_ocr_last_job({ action: "get_ocr_last_job" }).then((res) => {
                if (res) {
                  this.initHardSubtitleRecognition();
                  this.initHardSubtitleRecognitionMethod();
                  // this.innerVisible = true;
                  // this.dialogtitle = this.videoData.name;
                  // this.videoUrl = URL.createObjectURL(
                  //   this.videoData.file.raw
                  // );
                  // video = document.createElement("video");
                  // video.src = this.videoUrl;
                  // document.body.appendChild(video);
                  // video.onloadedmetadata = () => {
                  //   this.videoHeight = video.videoHeight;
                  //   this.videoWidth = video.videoWidth;
                  // };
                  // document.body.removeChild(video);
                  // this.windowdev = window.devicePixelRatio;
                  this.initVideo(res.position);
                  hideLoading();
                }
              });
            } else {
              let obj = {
                media_file_name: Math.random() + ".mp4",
                id: 1,
              };
              this.buttonLoading = true;
              this.uploadOSSMethod(4, obj);
            }
          } else {
            if (this.form8.videoLanguage === this.form8.select2) {
              this.$messages(
                "warning",
                this.$t("PersonalEndLanguage.VideoDubbingText18")
              );
              return;
            }
            if (this.form8.radio2 === "2") {
              let video = null;
              // 跳转硬字幕识别

              showLoading(this.$t("CommonName.loadingmessage"));
              get_ocr_last_job({ action: "get_ocr_last_job" }).then((res) => {
                if (res) {
                  this.initHardSubtitleRecognition();
                  this.initHardSubtitleRecognitionMethod();
                  // this.innerVisible = true;
                  // this.dialogtitle = this.videoData.name;
                  // this.videoUrl = URL.createObjectURL(
                  //   this.videoData.file.raw
                  // );
                  // video = document.createElement("video");
                  // video.src = this.videoUrl;
                  // document.body.appendChild(video);
                  // video.onloadedmetadata = () => {
                  //   this.videoHeight = video.videoHeight;
                  //   this.videoWidth = video.videoWidth;
                  // };

                  // document.body.removeChild(video);
                  // this.windowdev = window.devicePixelRatio;
                  this.initVideo(res.position);
                  hideLoading();
                }
              });
            } else {
              //选择硬字幕涂抹就要跳转到硬字幕识别
              if (this.form8.checkbox1) {
                // 跳转硬字幕识别
                showLoading(this.$t("CommonName.loadingmessage"));
                get_ocr_last_job({ action: "get_ocr_last_job" }).then((res) => {
                  if (res) {
                    this.initHardSubtitleRecognition();
                    this.initHardSubtitleRecognitionMethod();
                    this.initVideo(res.position);
                    hideLoading();
                  }
                });
              } else {
                let obj = {
                  media_file_name: Math.random() + ".mp4",
                  id: 2,
                };
                this.buttonLoading = true;
                this.uploadOSSMethod(4, obj);
              }
            }
          }
        }
      }
    },
    /**
     * 初始化 硬字幕识别 弹窗内容
     * @param data
     */
    initHardSubtitleRecognition() {
      this.videoHeight = 0;
      this.videoWidth = 0;
      this.slidervalue = 0;
      this.startTime = "";
      this.endTime = "";
      this.marks = {};
      this.rect = null;
      this.canvas = null;
    },
    /**
     * 初始化 硬字幕识别 处理
     * @param data
     */
    initHardSubtitleRecognitionMethod() {
      let video = null;
      this.innerVisible = true;
      this.dialogtitle = this.videoData.name;
      this.$nextTick(() => {
        this.videoUrl = URL.createObjectURL(this.videoData.file.raw);
        video = document.createElement("video");
        video.src = this.videoUrl;
        document.body.appendChild(video);
        var v = this.$refs.videoref;
        // video.onloadedmetadata = () => {
        //   this.videoWidth = Number(
        //     (
        //       video.videoWidth /
        //       Number((video.videoHeight / v.clientHeight).toFixed(2))
        //     ).toFixed(2)
        //   );
        //   this.videoHeight = v.clientHeight;
        // };

        document.body.removeChild(video);
        this.windowdev = window.devicePixelRatio;
      });
    },
    /**
     * 创建 硬字幕识别 任务
     * @returns {}
     */
    async createHardSubtitleRecognition() {
      // 下一步
      if (this.HardSubtitleRecognitionTab === "first") {
        if (!this.videoData.name) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage")
          );
          return;
        }
      } else if (this.HardSubtitleRecognitionTab === "third") {
        if (this.hardSubtitleRecognition_fileList.length === 0) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage")
          );
          return;
        }
        if (
          this.hardSubtitleRecognition_fileList.some((obj) =>
            this.checkEmpty(obj, 5)
          )
        ) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage34")
          );
          return;
        }
        this.hardSubtitleRecognition_fileList.forEach((i, index) => {
          i.videoHeight = 0;
          i.videoWidth = 0;
          i.slidervalue = 0;
          i.startTime = "";
          i.endTime = "";
          i.marks = {};
          i.rect = null;
          i.canvas = null;
          i.position = {};
          i.new_id = index;
        });
        showLoading(this.$t("CommonName.loadingmessage"));
        get_ocr_last_job({ action: "get_ocr_last_job" }).then((res) => {
          if (res) {
            this.initHardSubtitleRecognition();
            this.hardSubtitleRecognition_fileList.forEach((i) => {
              i.position = res.position;
            });
            // this.hardSubtitleRecognition_fileList[0].position = res.position;
            this.innerVisible = true;
            let video = null;
            this.$nextTick(() => {
              this.dialogtitle = this.hardSubtitleRecognition_fileList[0].name;
              this.videoUrl = URL.createObjectURL(
                this.hardSubtitleRecognition_fileList[0].raw
              );
              video = document.createElement("video");
              video.src = this.videoUrl;
              document.body.appendChild(video);
              var v = this.$refs.videoref;
              // video.onloadedmetadata = () => {
              //   this.videoWidth = Number(
              //     (
              //       video.videoWidth /
              //       Number((video.videoHeight / v.clientHeight).toFixed(2))
              //     ).toFixed(2)
              //   );
              //   this.videoHeight = v.clientHeight;
              // };
              document.body.removeChild(video);
              // console.log(window.devicePixelRatio, " * window.screen.availHeight");
              this.windowdev = window.devicePixelRatio;
            });

            // console.log(this.windowdev);
            this.initVideo(this.hardSubtitleRecognition_fileList[0].position);
            hideLoading();
          }
        });
      } else {
        if (!this.form5.youtubeNameInput) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.fileAddress1")
          );
          return;
        }
        let flag = false;
        showLoading(this.$t("CommonName.loadingmessage"));
        await this.check_tube_video_info(this.form5.youtubeNameInput)
          .then((res) => {
            flag = res;
          })
          .catch((err) => {
            hideLoading();
          });
        if (!flag) {
          hideLoading();
          return;
        }
        hideLoading();
        if (!this.youtubeId) {
          this.$messages("error", this.$t("PersonalEndLanguage.fileAddress2"));
          return;
        }
      }
      if (this.HardSubtitleRecognitionTab !== "third") {
        if (!this.form5.sourcelanguage) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage1")
          );
          return;
        }
        // if (this.form5.sourcelanguage == this.form5.targetlanguage) {
        //   this.$messages(
        //     "warning",
        //     this.$t("PersonalEndLanguage.warningmessage4")
        //   );
        //   return;
        // }
        showLoading(this.$t("CommonName.loadingmessage"));
        get_ocr_last_job({ action: "get_ocr_last_job" }).then((res) => {
          if (res) {
            this.initHardSubtitleRecognition();
            this.innerVisible = true;
            if (this.HardSubtitleRecognitionTab === "first") {
              this.initHardSubtitleRecognitionMethod();
            } else {
              this.dialogtitle = this.$t(
                "PersonalEndLanguage.Hardsubtitlerecognition"
              );
              this.sliderChange = true;
              this.showOpen = true;
              // this.initYoutubeVideo(res.position);
            }
            this.initVideo(res.position);
            hideLoading();
          }
        });
      }
    },
    /**
     * 判断 YouTube 视频 是否正确
     * @returns {Promise<boolean>}
     */
    check_tube_video_info(id) {
      return check_tube_video_info({
        action: "check_tube_video_info",
        file_url: id,
      }).then((res) => {
        if (!res) {
          return;
        }

        this.youtubeWidth = res.width;
        this.youtubeHeight = res.height;
        this.youtubeHeight_new = 0;
        this.youtubeWidth_new = 0;
        this.youtubeId = res.youtube_id;
        return true;
      });
    },

    /**
     * 视频配音 youtube 视频 批量上传
     * @param res
     */
    create_video_dubbing_job_tube(i) {
      create_video_dubbing_job_tube({
        srt_path: i.srturl,
        srt_name: i.srtinput,
        slang: i.videoLanguage,
        job_name: i.name,
        action: "create_video_dubbing_job_tube",
        file_url: i.url,
        category: this.project_id === 2 ? 0 : this.project_id === 9 ? 1 : 2,
      })
        .then((res) => {
          if (!res) {
            this.buttonLoading = false;
            return;
          }
          if (res.result == "SUCCESS") {
            if (
              i.id == this.form8.tableData[this.form8.tableData.length - 1].id
            ) {
              this.get_last_job().then(() => {
                this.$messages(
                  "success",
                  this.$t("PersonalEndLanguage.successMessage")
                );
                hideLoading();
                this.dialogVisible = false;
                this.buttonLoading = false;
                this.initData();
              });
            }
          } else if (res.result == "LOGIN_FAILED") {
            this.percentage = 0;
            this.loading = false;
            hideLoading();
            this.$messages("warning", this.$t("CommonName.tokenMessage"));
            this.$router.push("/Login");
            this.$Cookie.remove("token_code");
            sessionStorage.clear();
          } else if (res.result == "SPACE_FAILED") {
            this.videoData.file.status = "ready";
            this.percentage = 0;
            this.loading = false;
            hideLoading();
            this.quotetype = res.type;
            this.buttonLoading = false;
          } else {
            this.percentage = 0;
            this.loading = false;
            this.videoData.file.status = "ready";
            // console.log(22222222);
            // this.quotetype = response.type;
            hideLoading();
            this.$messages("error", res.msg);
            this.buttonLoading = false;
            this.dialogVisible = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /**
     * 创建 字幕对齐 任务
     * @param data
     * @constructor
     */
    CreatedSubtitlealignment() {
      if (!this.form6.videoLanguage) {
        this.$messages(
          "warning",
          this.$t("PersonalEndLanguage.warningmessage1")
        );
        return;
      }
      if (!this.form6.srtinput || !this.form6.srturl) {
        this.$messages(
          "warning",
          this.$t("PersonalEndLanguage.warningmessage37")
        );
        return;
      }
      let obj = {
        slang: this.form6.videoLanguage,
        txt_path: this.form6.srturl ? this.form6.srturl : "",
        txt_name: this.form6.srtinput ? this.form6.srtinput : "",
      };
      this.buttonLoading = true;
      this.uploadOSSMethod(6, obj);
    },
    /**
     *
     * @param {点击八个模块 区分展示} item
     */
    serviceClick(item) {
      this.project_id = item.id;
      if (
        [6].includes(item.id) &&
        this.user_info.video_dubbing_bigmodel === "0"
      ) {
        return false;
      }
      this.initData();
      //获取用户文件夹列表
      this.get_user_folder_list();
      if (item.name === this.$t("PersonalEndLanguage.Textdubbing")) {
        let pathInfo = this.$router.resolve({
          path: "/DubbingInfo",
          query: {
            jid: "0",
          },
        });
        window.open(pathInfo.href, "_blank");
      } else {
        // 如果是 硬字幕识别 把确定按钮 变成 下一步
        if (this.project_id === 7) {
          this.footerbtnName = this.$t("CommonName.Next");
        } else {
          this.footerbtnName = "";
        }
        this.HardSubtitleRecognitionTab = "first";
        this.dialogVisible = true;
        this.dialogtitle = item.name;
      }
      if (
        [
          this.$t("PersonalEndLanguage.Videotranscription"),
          this.$t("PersonalEndLanguage.Videotranslation"),
          this.$t("PersonalEndLanguage.subtitling"),
          this.$t("PersonalEndLanguage.Videodubbing"),
          this.$t("PersonalEndLanguage.VideoCloningDubbingsmall"),
          this.$t("PersonalEndLanguage.VideoCloningDubbing"),
        ].includes(item.name)
      ) {
        this.showChunk = "1";
      } else {
        this.showChunk = "2";
      }
    },
    /**
     *
     * @param {*上传视频、音频、图片成功} response
     * @param {*} file
     */
    uploadSuccess(response, file) {
      // console.log(response, file);
      if (response.result == "SUCCESS") {
        this.get_last_job().then(() => {
          this.$messages(
            "success",
            this.$t("PersonalEndLanguage.successMessage")
          );
          hideLoading();
          this.dialogVisible = false;
          this.buttonLoading = false;
          if (this.dialogtitle == this.$t("PersonalEndLanguage.Videodubbing")) {
            let pathInfo = this.$router.resolve({
              path: "/ContainerHome/VideoDubbing",
              query: {
                jid: response.job_id,
              },
            });
            window.open(pathInfo.href, "_blank");
          }
          this.initData();

          // hideLoading();
        });
      } else if (response.result == "LOGIN_FAILED") {
        this.percentage = 0;
        this.loading = false;
        hideLoading();
        this.$messages("warning", this.$t("CommonName.tokenMessage"));
        this.$router.push("/Login");
        this.$Cookie.remove("token_code");
        sessionStorage.clear();
      } else if (response.result == "QUOTE_FAILED") {
        file.status = "ready";
        this.percentage = 0;
        this.loading = false;
        hideLoading();
        this.quotetype = response.type;
        this.buttonLoading = false;
      } else {
        this.percentage = 0;
        this.loading = false;
        file.status = "ready";
        // console.log(22222222);
        // this.quotetype = response.type;
        hideLoading();
        this.$messages("error", response.msg);
        this.buttonLoading = false;
      }
    },
    /**
     *
     * @param {*上传失败} err
     * @param {*} file
     */
    uploadError(err, file) {
      console.log("err", err);
    },
    /**
     *
     * @param {*上传过程中} file
     */
    uploadProgress(file) {
      // console.log(file);
      // showLoading();
      if (file.percent > 0) {
        this.percentage = Number(file.percent.toFixed());
        this.loading = true;
      }
      if (file.percent == 100) {
        this.percentage = 0;
        this.loading = false;
        showLoading(this.$t("PersonalEndLanguage.loadingMessage"));
      }
    },
    /**
     *  视频配音 上传 之前验证 那些满足上传条件
     * @param file
     * @param fileList
     * @returns {boolean}
     */
    uploadChange_third(file, fileList) {
      if (!fileList || !file) return;
      this.hardSubtitleRecognition_fileList1 = this.setUploadListData_change(
        file,
        this.hardSubtitleRecognition_fileList,
        2
      );
    },
    /**
     *  视频配音 批量上传srt 之前验证 那些满足上传条件
     * @param file
     * @param fileList
     * @returns {boolean}
     */
    uploadChange_five(file, fileList) {
      if (!fileList || !file) return;
      this.fileList1 = this.setUploadListData_change(file, this.filelist, 3);
    },
    /**
     *  视频AI 批量上传srt 之前验证 那些满足上传条件
     * @param file
     * @param fileList
     * @returns {boolean}
     */
    uploadChangeAI_six(file, fileList) {
      if (!fileList || !file) return;
      this.hardSubtitleRecognition_fileList1 = this.setUploadListData_change(
        file,
        this.hardSubtitleRecognition_fileList,
        4
      );
    },
    uploadExceed_third(filelist) {
      // console.log(filelist);
      this.$messages(
        "warning",
        this.$t("PersonalEndLanguage.warningmessage11")
      );
      //   // if (this.hardSubtitleRecognition_fileList.length > 10) {
      //   //   return;
      //   // }
      //   this.hardSubtitleRecognition_fileList = [];
    },
    /**
     * 删除 对应 filelist 里面得项
     * @param e
     * @param item
     * @constructor
     */
    UploadListDeleteClick(e, item, mode = 2) {
      this.hardSubtitleRecognition_fileList = this.setUploadListData_delete(
        this.hardSubtitleRecognition_fileList,
        item,
        mode
      );
      e.stopPropagation();
    },
    /**
     * 删除 对应 filelist 里面得项
     * @param e
     * @param item
     * @constructor
     */
    UploadListDeleteClick1(e, item, mode) {
      this.filelist = this.setUploadListData_delete(this.filelist, item, mode);
      e.stopPropagation();
    },
    /**
     * 硬字幕识别 上传之前 验证是否满足上传条件  change 事件
     * @param file
     * @param fileList
     */
    hardSubtitleRecognition_change_third(file, fileList) {
      if (!fileList || !file) return;
      this.hardSubtitleRecognition_fileList1 = this.setUploadListData_change(
        file,
        this.hardSubtitleRecognition_fileList
      );
    },
    /**
     * 硬字幕识别 上传超出 数量限制 事件
     * @param filelist
     */
    hardSubtitleRecognition_Exceed_third(filelist) {
      this.$messages(
        "warning",
        this.$t("PersonalEndLanguage.warningmessage11")
      );
    },
    /**
     * 硬字幕识别 删除 上传列表中的某条
     * @param e
     * @param item
     */
    hardSubtitleRecognitionList_Delete_Click(e, item) {
      this.hardSubtitleRecognition_fileList = this.setUploadListData_delete(
        this.hardSubtitleRecognition_fileList,
        item
      );
      e.stopPropagation();
    },
    /**
     * 设置批量上传 数据 删除
     * @param arr
     * @returns {*}
     */
    setUploadListData_delete(arr, item, mode = 0) {
      this.fileSizeMB = 0;
      this.fileTotalDuration = 0;
      arr.map((i, index) => {
        if (i.id == item.id) {
          arr.splice(index, 1);
        }
      });
      this.form1.checkboxDisabled = false;
      this.form9.checkboxDisabled = false;
      this.form10.checkboxDisabled = false;
      arr.forEach((i) => {
        this.fileSizeMB += Number((i.size / (1024 * 1024)).toFixed(2));
        this.fileTotalDuration += Number(i.videotime);
        if (mode == 1) {
          console.log("少了一个", i);
          if (+i.videotime / 60 > +this.user_info.video_daub_minute_limit) {
            this.form1.checkboxDisabled = true;
            this.form1.checkbox1 = false;
            this.form1.checkbox2 = false;
          }
        } else if (mode == 2) {
          console.log("少了一个", i);
          if (+i.videotime / 60 > +this.user_info.video_daub_minute_limit) {
            this.form9.checkboxDisabled = true;
            this.form9.checkbox1 = false;
            this.form9.checkbox2 = false;
          }
        } else if (mode == 4) {
          console.log("少了一个", i);
          if (+i.videotime / 60 > +this.user_info.video_daub_minute_limit) {
            this.form10.checkboxDisabled = true;
            this.form10.checkbox1 = false;
            this.form10.checkbox2 = false;
          }
        }
      });

      return arr;
    },
    /**
     * 设置批量上传 数据 上传之前 判断是否满足条件
     * @param file
     * @param arr
     * @param mode 0:硬字幕与配音一套限制，1：视频AI字幕，2:视频配音批量；3：视频配音批量srt；4:视频AI字幕批量srt
     * @returns {*|boolean}
     */
    setUploadListData_change(file, arr, mode = 0) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      let filesize_limit =
        mode == 1 || mode == 4
          ? this.user_info.video_filesize_limit
          : this.user_info.video_dubbing_filesize_limit;
      const isLt2M =
        file.size / 1024 / 1024 / 1024 < parseFloat(filesize_limit);
      const extension = testmsg.toLowerCase() === "mp4";
      if (!extension) {
        if (this.formatmessage) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage7")
          );

          this.formatmessage = false;
        }
        this.$refs.upload.clearFiles();
        return [];
      } else if (!isLt2M) {
        if (this.sizemessage) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage18") +
              filesize_limit +
              "GB"
          );
          this.sizemessage = false;
        }
        this.$refs.upload.clearFiles();
        return [];
      } else if (file.name.length > 80) {
        if (this.langMessage) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage36")
          );
          this.langMessage = false;
        }
        this.$refs.upload.clearFiles();
        return [];
      } else {
        let duration_limit =
          mode == 1 || mode == 4
            ? this.user_info.video_duration_limit
            : this.user_info.video_dubbing_duration_limit;
        this.getFileTime(file).then((res) => {
          if (res / 60 > parseFloat(duration_limit)) {
            this.$messages(
              "warning",
              this.$t("PersonalEndLanguage.warningmessage19") +
                duration_limit +
                this.$t("PersonalEndLanguage.minute")
            );
            this.$refs.upload.clearFiles();
            return false;
          } else {
            // fileTotalDuration
            get_user_quota_info({
              action: "get_user_quota_info",
            })
              .then((res1) => {
                if (res1) {
                  this.fileSizeMB += Number(
                    (file.size / (1024 * 1024)).toFixed(2)
                  ); // 转换为MB并保留两位小数
                  if (
                    +this.fileSizeMB >
                    res1.info.space.max - res1.info.space.current
                  ) {
                    if (this.filesizemessage) {
                      this.$messages(
                        "warning",
                        this.$t("PersonalEndLanguage.warningmessage10")
                      );
                      this.fileSizeMB =
                        this.fileSizeMB -
                        Number((file.size / (1024 * 1024)).toFixed(2));
                      // console.log(this.fileSizeMB, "222222");
                      this.filesizemessage = false;
                    }
                    this.$refs.upload.clearFiles();
                    return;
                  }
                  if (mode == 1) {
                    this.fileTotalDuration += +res;
                    console.log("fileTotalDuration", this.fileTotalDuration);
                    //在视频AI字幕中 上传视频时长小于等于5分钟 则开放硬字幕涂抹与硬字幕压制.
                    if (res / 60 <= +this.user_info.video_daub_minute_limit) {
                      this.form1.checkboxDisabled = false;
                    } else {
                      this.form1.checkboxDisabled = true;
                      this.form1.checkbox1 = false;
                      this.form1.checkbox2 = false;
                    }
                    arr.forEach((i) => {
                      if (
                        +i.videotime / 60 >
                        +this.user_info.video_daub_minute_limit
                      ) {
                        this.form1.checkboxDisabled = true;
                        this.form1.checkbox1 = false;
                        this.form1.checkbox2 = false;
                      }
                    });
                  } else if (mode == 4) {
                    this.fileTotalDuration += +res;
                    console.log("fileTotalDuration", this.fileTotalDuration);
                    //在视频AI字幕中 上传视频时长小于等于5分钟 则开放硬字幕涂抹与硬字幕压制.
                    if (res / 60 <= +this.user_info.video_daub_minute_limit) {
                      this.form10.checkboxDisabled = false;
                    } else {
                      this.form10.checkboxDisabled = true;
                      this.form10.checkbox1 = false;
                      this.form10.checkbox2 = false;
                    }
                    arr.forEach((i) => {
                      if (
                        +i.videotime / 60 >
                        +this.user_info.video_daub_minute_limit
                      ) {
                        this.form10.checkboxDisabled = true;
                        this.form10.checkbox1 = false;
                        this.form10.checkbox2 = false;
                      }
                    });
                  } else if (mode == 2) {
                    this.fileTotalDuration += +res;
                    console.log("fileTotalDuration", this.fileTotalDuration);
                    //在视频配音中 上传视频时长小于等于5分钟 则开放硬字幕涂抹与硬字幕压制.
                    if (res / 60 <= +this.user_info.video_daub_minute_limit) {
                      this.form9.checkboxDisabled = false;
                    } else {
                      this.form9.checkboxDisabled = true;
                      this.form9.checkbox1 = false;
                      this.form9.checkbox2 = false;
                    }
                    arr.forEach((i) => {
                      if (
                        +i.videotime / 60 >
                        +this.user_info.video_daub_minute_limit
                      ) {
                        this.form9.checkboxDisabled = true;
                        this.form9.checkbox1 = false;
                        this.form9.checkbox2 = false;
                      }
                    });
                  }

                  file.id = Math.random() + ".mp4";
                  file.showdropdown = false;
                  file.srtinput = "";
                  file.videoLanguage = "";
                  file.srturl = "";
                  file.videotime = res;
                  file.srttime = "";
                  file.totalChunks = 0;
                  file.currentChunk = 0;
                  file.percentage = 0;
                  file.independence_percentage = 0;
                  arr.push(file);
                  arr.sort((a, b) => {
                    let nameA = a.name.toLowerCase();
                    let nameB = b.name.toLowerCase();
                    return nameA.localeCompare(nameB);
                  });
                  // console.log(this.hardSubtitleRecognition_fileList);
                }
              })
              .catch((err) => {});
          }
        });
      }
      return arr;
    },
    /**
     *
     * @param {*上传视频 改变视频} file
     */
    uploadChange(file) {
      if (!file) return;
      const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2); // 转换为MB并保留两位小数
      // this.setUploadData(file);
      get_user_quota_info({
        action: "get_user_quota_info",
      })
        .then((res) => {
          if (res) {
            if (+fileSizeMB > res.info.space.max - res.info.space.current) {
              this.$messages(
                "warning",
                this.$t("PersonalEndLanguage.warningmessage10")
              );
              // console.log(file);
              this.$refs.upload.clearFiles();
              // file.status = "ready";
              return;
            }
            this.setUploadData(file);
          }
        })
        .catch((err) => {});
    },

    // 计算图片尺寸
    /**
     *
     * @param {*计算图片尺寸} file
     */
    asyncImgChecked(file) {
      return new Promise((resolve) => {
        let reader = new FileReader();
        reader.readAsDataURL(file.raw); // 必须用file.raw
        reader.onload = () => {
          // 让页面中的img标签的src指向读取的路径
          let img = new Image();
          img.src = reader.result;
          // console.log(`1当前文件尺寸大小：${img.width}×${img.height}`)
          if (img.complete) {
            // 如果存在浏览器缓存中
            if (img.width > 4001 || img.height > 10001) {
              resolve(false);
            } else {
              resolve(true);
            }
          } else {
            img.onload = () => {
              if (img.width > 4001 || img.height > 10001) {
                resolve(false);
              } else {
                resolve(true);
              }
            };
          }
        };
      });
    },
    /**
     *
     * @param {*获取音频时长} file
     */
    async getFileTime(file) {
      // console.log(file);
      let content = file.raw;
      let a = await new Promise((resolve) => {
        var url = URL.createObjectURL(content);
        var audioElement = new Audio(url);
        audioElement.addEventListener("loadedmetadata", () => {
          resolve(audioElement.duration.toFixed(3)); //playTime就是当前视频长度
          // this.playTime = audioElement.duration;
        });
      });
      return a;
    },
    /**
     *
     * @param {*获取视频详情} file
     */
    async getVideoFileInfo(file) {
      // console.log(file);
      let content = file.raw;
      let a = await new Promise((resolve) => {
        var url = URL.createObjectURL(content);
        this.$refs.videoPlayer.src = url;
        this.$refs.videoPlayer.addEventListener("loadedmetadata", () => {
          resolve({
            width: this.$refs.videoPlayer.videoWidth,
            height: this.$refs.videoPlayer.videoHeight,
          });
        });
      });
      return a;
    },
    /**
     * 上传视频之前
     */
    beforeUpload(file) {
      console.log(file, "111111");
      if ([2, 6].includes(this.project_id)) {
        this.form8.checkboxDisabled = true;
        this.form8.checkbox1 = false;
        this.form8.checkbox2 = false;
      }
    },
    /**
     *
     * @param {*设置 上传文件 是否可以上传} file
     */
    setUploadData(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      // 音频转写
      if (
        this.dialogtitle === this.$t("PersonalEndLanguage.Audiotranscription")
      ) {
        testmsg = testmsg.toLowerCase();
        const extension = testmsg === "mp3";
        const extension1 = testmsg === "wav";
        const extension2 = testmsg === "aac";

        if (!extension && !extension1 && !extension2) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage12")
          );
          this.videoData.name = "";
          this.$refs.upload.clearFiles();
        } else if (file.name.length > 80) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage36")
          );
          this.videoData.name = "";
          this.$refs.upload.clearFiles();
        } else {
          this.getFileTime(file).then((res) => {
            this.videotime = res;
            if (res / 60 > parseFloat(this.user_info.audio_duration_limit)) {
              this.$messages(
                "warning",
                this.$t("PersonalEndLanguage.warningmessage13") +
                  this.user_info.audio_duration_limit +
                  this.$t("PersonalEndLanguage.minute")
              );
              this.videoData.name = "";
              this.$refs.upload.clearFiles();
            } else {
              if (["mp3", "wav", "aac"].includes(testmsg)) {
                this.videoData.icon = "audio";
                this.videoData.name = file.name;
                this.videoData.file = file;
                this.uploadName = "media_file";
              }
            }
          });
        }
      } else if (
        this.dialogtitle == this.$t("PersonalEndLanguage.ComicQuickFlip")
      ) {
        // 漫画
        // console.log(testmsg.toLowerCase());
        let extension = testmsg.toLowerCase() === "jpg";
        let extension1 = testmsg.toLowerCase() === "jpeg";
        let extension2 = testmsg.toLowerCase() === "zip";
        let extension3 = testmsg.toLowerCase() === "png";
        const isLt500M = extension2
          ? file.size / 1024 / 1024 <
            parseFloat(this.user_info.comic_filesize_limit)
          : file.size / 1024 / 1024 < 50;
        if (!extension && !extension1 && !extension2 && !extension3) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage14")
          );
          this.videoData.name = "";
          this.$refs.upload.clearFiles();
        } else if (!isLt500M) {
          if (extension2) {
            this.$messages(
              "warning",
              this.$t("PersonalEndLanguage.warningmessage15") +
                this.user_info.comic_filesize_limit +
                "M"
            );
          } else {
            this.$messages(
              "warning",
              this.$t("PersonalEndLanguage.warningmessage16")
            );
          }
          this.videoData.name = "";
          this.$refs.upload.clearFiles();
        } else {
          if (extension || extension1 || extension3) {
            this.asyncImgChecked(file).then((res) => {
              if (res) {
                this.videoData.name = file.name;
                this.videoData.icon = "Comic";
                this.uploadName = "comic_file";
              } else {
                this.$messages(
                  "warning",
                  this.$t("PersonalEndLanguage.warningmessage17")
                );
                this.videoData.name = "";
                this.$refs.upload.clearFiles();
              }
            });
          } else if (extension2) {
            this.videoData.name = file.name;
            this.videoData.icon = "FileZip";
            this.videoData.file = file;
            this.uploadName = "comic_file";
          }
        }
      } else if ([2, 6, 9].includes(this.project_id)) {
        // 视频配音
        const isLt2M =
          file.size / 1024 / 1024 / 1024 <
          parseFloat(this.user_info.video_dubbing_filesize_limit);
        const extension = testmsg.toLowerCase() === "mp4";
        if (!extension) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage7")
          );
          this.videoData.name = "";
          this.videotime = 0;
          this.$refs.upload.clearFiles();
        } else if (!isLt2M) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage18") +
              this.user_info.video_dubbing_filesize_limit +
              "GB"
          );
          this.videoData.name = "";
          this.videotime = 0;
          this.$refs.upload.clearFiles();
        } else if (file.name.length > 80) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage36")
          );
          this.videoData.name = "";
          this.videotime = 0;
          this.$refs.upload.clearFiles();
        } else {
          this.getFileTime(file)
            .then((res) => {
              if (
                res / 60 >
                parseFloat(this.user_info.video_dubbing_duration_limit)
              ) {
                this.$messages(
                  "warning",
                  this.$t("PersonalEndLanguage.warningmessage19") +
                    this.user_info.video_dubbing_duration_limit +
                    this.$t("PersonalEndLanguage.minute")
                );
                this.videoData.name = "";
                this.videotime = 0;
                this.$refs.upload.clearFiles();
              } else {
                this.getVideoFileInfo(file).then((res1) => {
                  console.log(res1);
                  let is_limte = true; //是否小于1080p
                  // let small_p =
                  //   res1.width < res1.height ? res1.width : res1.height;
                  // is_limte = small_p <= 1080 ? true : false;

                  //在视频克隆配音中 上传视频时长小于等于5分钟 并且不大于1080p 则开放硬字幕涂抹与硬字幕压制.
                  if ([2, 6].includes(this.project_id)) {
                    if (
                      res / 60 <= +this.user_info.video_daub_minute_limit &&
                      is_limte
                    ) {
                      this.form8.checkboxDisabled = false;
                    } else {
                      this.form8.checkboxDisabled = true;
                      this.form8.checkbox1 = false;
                      this.form8.checkbox2 = false;
                    }
                  }
                });

                this.videotime = res;
                this.videoData.name = file.name;
                this.videoData.icon = "video";
                this.videoData.file = file;
                this.uploadName = "media_file";
                this.totalChunks = Math.ceil(file.size / this.chunkSize);
                this.currentChunk = 0;
              }

              // console.log(res);
            })
            .catch(() => {
              this.$messages(
                "error",
                this.$t("PersonalEndLanguage.errormessage1")
              );
            });
        }
      } else {
        const isLt2M =
          file.size / 1024 / 1024 / 1024 <
          parseFloat(this.user_info.video_filesize_limit);
        const extension = testmsg.toLowerCase() === "mp4";
        if (!extension) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage7")
          );
          this.videoData.name = "";
          this.$refs.upload.clearFiles();
        } else if (!isLt2M) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage18") +
              this.user_info.video_filesize_limit +
              "GB"
          );
          this.videoData.name = "";
          this.$refs.upload.clearFiles();
        } else if (file.name.length > 80) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage36")
          );
          this.videoData.name = "";
          this.$refs.upload.clearFiles();
        } else {
          this.getFileTime(file).then((res) => {
            if (res / 60 > parseFloat(this.user_info.video_duration_limit)) {
              this.$messages(
                "warning",
                this.$t("PersonalEndLanguage.warningmessage19") +
                  this.user_info.video_duration_limit +
                  this.$t("PersonalEndLanguage.minute")
              );
              this.videoData.name = "";
              this.$refs.upload.clearFiles();
            } else {
              this.getVideoFileInfo(file).then((res1) => {
                // console.log(res1);
                let is_limte = true; //是否小于1080p
                // let small_p =
                //   res1.width < res1.height ? res1.width : res1.height;
                // is_limte = small_p <= 1080 ? true : false;

                //在视频AI字幕中 上传视频时长小于等于5分钟 并且不大于1080p 则开放硬字幕涂抹与硬字幕压制.

                if (
                  res / 60 <= +this.user_info.video_daub_minute_limit &&
                  is_limte
                ) {
                  this.form.checkboxDisabled = false;
                } else {
                  this.form.checkboxDisabled = true;
                  this.form.checkbox1 = false;
                  this.form.checkbox2 = false;
                }
              });
              this.videotime = res;
              this.videoData.name = file.name;
              this.videoData.icon = "video";
              this.videoData.file = file;
              this.uploadName = "media_file";
              // 获取总片数
              this.totalChunks = Math.ceil(file.size / this.chunkSize);
              this.currentChunk = 0;
              // this.chunkProgress = [];
              // this.chunkProgress.length = this.totalChunks;
              // console.log(this.videoData.file);
            }
          });
        }
      }
    },
    /**
     * 限制只能上传一个文件
     */
    AudiotranscriptionClick() {
      this.videoData.name = "";
      // this.$refs.upload.clearFiles();
      // console.log(this.$refs["upload"]);
      if (this.$refs["upload"] != undefined) {
        let uploadFilesArr = this.$refs["upload"].uploadFiles; //上传字幕文件
        // console.log(uploadFilesArr);
        if (uploadFilesArr.length == 0) {
          return;
        } else {
          this.$refs["upload"].uploadFiles = [];
        }
      }
    },

    /**
     *  视频配音 多文件上传时 限制
     * @constructor
     */
    AudiotranscriptionClick_third(data) {
      if (data === 1) {
        if (this.hardSubtitleRecognition_fileList.length === 0) {
          this.fileSizeMB = 0;
        } else {
          this.fileSizeMB = 0;
          this.hardSubtitleRecognition_fileList.forEach((i) => {
            this.fileSizeMB += Number((i.size / (1024 * 1024)).toFixed(2));
          });
          // console.log(this.fileSizeMB, "333333");
        }
      } else if (data === 2) {
        if (this.hardSubtitleRecognition_fileList.length === 0) {
          this.fileSizeMB = 0;
        } else {
          this.fileSizeMB = 0;
          this.hardSubtitleRecognition_fileList.forEach((i) => {
            this.fileSizeMB += Number((i.size / (1024 * 1024)).toFixed(2));
          });
          // console.log(this.fileSizeMB, "333333");
        }
      } else if (data === 3) {
        if (this.hardSubtitleRecognition_fileList.length === 0) {
          this.fileSizeMB = 0;
        } else {
          this.fileSizeMB = 0;
          this.hardSubtitleRecognition_fileList.forEach((i) => {
            this.fileSizeMB += Number((i.size / (1024 * 1024)).toFixed(2));
          });
          // console.log(this.fileSizeMB, "333333");
        }
      } else if (data === 4) {
        //配音批量srt
        if (this.filelist.length === 0) {
          this.fileSizeMB = 0;
        } else {
          this.fileSizeMB = 0;
          this.filelist.forEach((i) => {
            this.fileSizeMB += Number((i.size / (1024 * 1024)).toFixed(2));
          });
          // console.log(this.fileSizeMB, "333333");
        }
      }
      // this.formatmessage = true;
      // this.sizemessage = true;
      // this.filesizemessage = true;
      // this.langMessage = true;
    },
    /**
     *  鼠标划上 修改提示 状态
     * @param data
     * @constructor
     */
    AudiotranscriptionClick_third_enter() {
      this.formatmessage = true;
      this.sizemessage = true;
      this.filesizemessage = true;
      this.langMessage = true;
    },
    /**
     *
     * @param {*切换转写方式} e
     */
    radiochange(e) {
      console.log(e);
    },
    // 点击上传文件（srt） 清空已上传文件
    srtupload(refs, item, data = 1) {
      let uploadFilesArr = {};
      if (item) {
        // if (data == 1) {
        //   this.currentItem = item;
        //   this.srtmaxtime = 0;
        //   item.srttime = 0;
        // } else {
        this.currentItem = item;
        this.srtmaxtime = 0;
        item.srttime = 0;
        // }
        if (data == 2) {
          uploadFilesArr = this.$refs[refs][0].uploadFiles; //上传字幕文件
          // item.srtinput = "";
          // item.srturl = "";
        }
        if (item.id) {
          uploadFilesArr = this.$refs[refs][0].uploadFiles; //上传字幕文件
        }
      } else {
        uploadFilesArr = this.$refs[refs].uploadFiles; //上传字幕文件
      }

      if (uploadFilesArr.length == 0) {
        return;
      } else {
        if (item) {
          if (item.id) {
            this.$refs[refs][0].uploadFiles = [];
          }
          if (data == 2) {
            this.$refs[refs][0].uploadFiles = []; //上传字幕文件
            // item.srtinput = "";
            // item.srturl = "";
          }
        } else {
          this.$refs[refs].uploadFiles = [];
        }
      }
    },
    /**
     *
     * @param {*判断是否是 上传的对应后缀文件} file
     */
    srtbeforeUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testmsg.toLowerCase() === "txt";
      const extension1 = testmsg.toLowerCase() === "srt";
      const extension2 = testmsg.toLowerCase() === "ass";
      if (
        this.dialogtitle === this.$t("PersonalEndLanguage.Subtitlealignment")
      ) {
        if (!extension) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage20")
          );
          return false;
        } else {
          return true;
        }
      } else if ([2, 6, 9].includes(this.project_id)) {
        if (!extension1) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage21")
          );
          return false;
        } else {
          return true;
        }
      } else {
        if (!extension1 && !extension2) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage22")
          );
          return false;
        } else {
          return true;
        }
      }
    },
    /**
     *
     * @param {*上传成功code} response
     * @param {*上传成功文件} file
     */
    srtuploadSuccess(response, file) {
      // console.log(response);
      if (response.result == "SUCCESS") {
        if (
          // 字幕对齐
          this.dialogtitle === this.$t("PersonalEndLanguage.Subtitlealignment")
        ) {
          this.form6.srtinput = file.name;
          this.form6.srturl = response.path;
        } else if ([2, 6, 9].includes(this.project_id)) {
          if (this.HardSubtitleRecognitionTab === "third") {
            let obj = this.hardSubtitleRecognition_fileList.find(
              (i) => i.id === this.currentItem.id
            );
            obj.srturl = response.path;
            check_srt_max_duration({
              action: "check_srt_max_duration",
              srt_path: response.path,
            }).then((res) => {
              if (res) {
                let obj = this.hardSubtitleRecognition_fileList.find(
                  (i) => i.id === this.currentItem.id
                );
                obj.videotime = Math.ceil(obj.videotime * 100) / 100;
                if (res.maxtime > Number(obj.videotime)) {
                  this.$messages(
                    "warning",
                    this.$t("PersonalEndLanguage.warningmessage23")
                  );
                  obj.srtinput = "";
                  this.$forceUpdate();
                  return;
                }
                obj.srttime = res.maxtime;
                obj.srtinput = file.name;
                this.$forceUpdate();
              } else {
                obj.srtinput = "";
                this.$forceUpdate();
              }
            });
          } else if (this.HardSubtitleRecognitionTab === "five") {
            let obj = this.filelist.find((i) => i.id === this.currentItem.id);
            obj.srturl = response.path;
            check_srt_max_duration({
              action: "check_srt_max_duration",
              srt_path: response.path,
            }).then((res) => {
              if (res) {
                let obj = this.filelist.find(
                  (i) => i.id === this.currentItem.id
                );
                obj.videotime = Math.ceil(obj.videotime * 100) / 100;
                if (res.maxtime > Number(obj.videotime)) {
                  this.$messages(
                    "warning",
                    this.$t("PersonalEndLanguage.warningmessage23")
                  );
                  obj.srtinput = "";
                  this.$forceUpdate();
                  return;
                }
                obj.srttime = res.maxtime;
                obj.srtinput = file.name;
                this.$forceUpdate();
              } else {
                obj.srtinput = "";
                this.$forceUpdate();
              }
            });
          } else if (this.HardSubtitleRecognitionTab === "four") {
            let obj = this.form8.tableData.find(
              (i) => i.id === this.currentItem.id
            );
            obj.srturl = response.path;
            obj.srtinput = file.name;
          } else {
            this.form8.srturl = response.path;
            check_srt_max_duration({
              action: "check_srt_max_duration",
              srt_path: response.path,
            }).then((res) => {
              // console.log(res);
              if (res) {
                if (this.HardSubtitleRecognitionTab === "first") {
                  if (this.form8.radio1 === "1") {
                    this.form8.srtinput = file.name;
                    this.form8.srturl = response.path;
                    this.form8.srtmaxtime = res.maxtime;
                  } else {
                    this.form8.srtinput1 = file.name;
                    this.form8.srturl1 = response.path;
                    this.form8.srtmaxtime1 = res.maxtime;
                  }
                  // console.log(this.form8, "this.form8");
                } else {
                  this.form8.srtinput = file.name;
                  this.srtmaxtime = res.maxtime;
                }
              }
            });
          }
        } else {
          if (this.HardSubtitleRecognitionTab === "six") {
            let obj = this.hardSubtitleRecognition_fileList.find(
              (i) => i.id === this.currentItem.id
            );
            obj.srturl = response.path;
            check_srt_max_duration({
              action: "check_srt_max_duration",
              srt_path: response.path,
            }).then((res) => {
              if (res) {
                let obj = this.hardSubtitleRecognition_fileList.find(
                  (i) => i.id === this.currentItem.id
                );
                obj.videotime = Math.ceil(obj.videotime * 100) / 100;
                if (res.maxtime > Number(obj.videotime)) {
                  this.$messages(
                    "warning",
                    this.$t("PersonalEndLanguage.warningmessage23")
                  );
                  obj.srtinput = "";
                  this.$forceUpdate();
                  return;
                }
                obj.srttime = res.maxtime;
                obj.srtinput = file.name;
                this.$forceUpdate();
              } else {
                obj.srtinput = "";
                this.$forceUpdate();
              }
            });
          } else {
            this.form.srtinput = file.name;
            this.form.srturl = response.path;
          }
        }
      } else if (response.result === "LOGIN_FAILED") {
        this.$messages("warning", this.$t("CommonName.tokenMessage"));
        this.$router.push("/Login");
        this.$Cookie.remove("token_code");
        sessionStorage.clear();
      } else {
        this.$messages("error", response.msg);
      }
    },

    /**
     * 查询正在转写任务的状态
     */
    getTranscribingJobs(ids) {
      if (ids.length == 0) {
        return false;
      }
      return get_last_job_status({
        action: "get_last_job_status",
        job_private_ids: ids,
      }).then((res) => {
        if (res) {
          if (res.mode === 1) {
            //还有任务在转写中
            let that = this;
            let job_ids = [];
            res.list.forEach((i) => {
              for (let index = 0; index < that.imgData.length; index++) {
                if (that.imgData[index].job_private_id === i.job_private_id) {
                  if (Number(i.status) < 20) {
                    job_ids.push(i.job_private_id);
                    that.imgData[index].showLoadingnow = true;
                    that.imgData[index].progress = i.task_progress;
                  } else {
                    that.imgData[index].showLoadingnow = false;
                  }
                  break;
                }
              }
            });
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
              this.getTranscribingJobs(job_ids);
            }, 30000);
          } else if (res.mode === 2) {
            //全部转写完成
            this.get_last_job();
          }
        }
      });
    },
    /**
     *
     * @param {*判断是否是 上传的音轨对应后缀文件} file
     */
    audioBeforeUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testmsg.toLowerCase() === "wav";
      //视频克隆配音
      if ([6].includes(this.project_id)) {
        if (file.size / 1024 / 1024 > 200) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage43")
          );
          return false;
        }

        if (!extension) {
          this.$messages(
            "warning",
            this.$t("PersonalEndLanguage.warningmessage40")
          );
          return false;
        } else {
          return true;
        }
      }
    },
    /**
     *
     * @param {*上传成功code} response
     * @param {*上传成功文件} file
     */
    audioUploadSuccess(response, file, list, mode) {
      if (response.result == "SUCCESS") {
        if ([6].includes(this.project_id)) {
          if (mode == 1) {
            this.form8.audioName = file.name; //上传人声音轨文件 名称
            this.form8.dubbing_vocals_name = response.name; //上传人声音轨文件 名称
            this.form8.dubbing_vocals_path = response.path; //上传人声音轨文件地址
            this.form8.dubbing_vocals_time = response.duration; //上传人声音轨文件时长
          } else if (mode == 2) {
            this.form8.audioName1 = file.name; //上传人声音轨文件 名称
            this.form8.dubbing_novocals_name = response.name; //上传国际轨音轨文件 名称
            this.form8.dubbing_novocals_path = response.path; //上传国际轨音轨文件地址
            this.form8.dubbing_novocals_time = response.duration; //上传国际音轨文件时长
          }
          console.log("this.form8", this.form8);
        }
      } else if (response.result === "LOGIN_FAILED") {
        this.$messages("warning", this.$t("CommonName.tokenMessage"));
        this.$router.push("/Login");
        this.$Cookie.remove("token_code");
        sessionStorage.clear();
      } else {
        this.$messages("error", response.msg);
      }
    },
    formRadio2Change(value) {
      if (value == 2) {
        if (!this.hardSublangs.includes(this.form.videoLanguage)) {
          this.form.videoLanguage = "";
        }
      }
    },
    form1Radio2Change(value) {
      if (value == 2) {
        if (!this.hardSublangs.includes(this.form1.videoLanguage)) {
          this.form1.videoLanguage = "";
        }
      }
    },
    form9Radio2Change(value) {
      if (value == 2) {
        if (!this.hardSublangs.includes(this.form9.videoLanguage)) {
          this.form9.videoLanguage = "";
        }
      }
    },
    videoRadioChange(value) {
      const findItem = this.videoList.find((item) => item.job_id == value);
      if (findItem) {
        this.form8.media_file_name1 = findItem.job_name;
      }
    },
  },
  mounted() {
    hideLoading();
    showLoading(this.$t("CommonName.loadingmessage"));
    this.get_last_job();
    this.get_language();
    this.get_language(1);
    this.get_language(2);
    this.user_info = JSON.parse(sessionStorage.getItem("info"));
    this.title = this.$t("PersonalEndLanguage.staging");
    for (let i = 2; i <= 10; i++) {
      this.form8Select3options.push({
        lable: i,
        value: i,
      });
    }
    this.serviceList = [
      {
        id: 2,
        icon: "shipinpeiyin_AI",
        // 视频配音
        name: this.$t("PersonalEndLanguage.Videodubbing"),
        explain: this.$t("PersonalEndLanguage.Videodubbingillustrate"),
        color: "#4EEDFF",
      },
      {
        id: 9,
        icon: "shipinkelongpeiyin",
        // 视频克隆 配音
        name: this.$t("PersonalEndLanguage.VideoCloningDubbingsmall"),
        explain: this.$t(
          "PersonalEndLanguage.VideoCloningDubbingsmallillustrate"
        ),
        // color: "#ff9c95",
        color: "#747886",
      },
      {
        id: 3,
        icon: "AI_Text",
        // 文本配音
        name: this.$t("PersonalEndLanguage.Textdubbing"),
        explain: this.$t("PersonalEndLanguage.Textdubbingillustrate"),
        color: "#e1cbff",
      },

      {
        id: 6,
        icon:
          this.user_info.video_dubbing_bigmodel === "0"
            ? "0shipinkelongpeiyin_damoxing"
            : "shipinkelongpeiyin_damoxing",
        //视频克隆配音大模型
        name: this.$t("PersonalEndLanguage.VideoCloningDubbing"),
        explain: this.$t("PersonalEndLanguage.VideoCloningDubbingillustrate"),
        color: "#747886",
      },
      {
        id: 1,
        icon: "VideoTranslation",
        // 视频转写
        name: this.$t("PersonalEndLanguage.Videotranscription"),
        explain: this.$t("PersonalEndLanguage.Videotranscriptionillustrate"),
        color: "#FFB860FF",
      },
      {
        id: 4,
        icon: "AudioTranscription",
        // 音频转写
        name: this.$t("PersonalEndLanguage.Audiotranscription"),
        explain: this.$t("PersonalEndLanguage.Audiotranscriptionillustrate"),
        color: "#d9d784",
      },
      {
        id: 7,
        icon: "IdentificationCaption",
        // name: "硬字幕识别",
        name: this.$t("PersonalEndLanguage.Hardsubtitlerecognition"),
        explain: this.$t(
          "PersonalEndLanguage.Hardsubtitlerecognitionillustrate"
        ),
        color: "#FFA4CE",
      },

      {
        id: 8,
        icon: "SubtitleAlignment",
        // name: "字幕对齐",
        name: this.$t("PersonalEndLanguage.Subtitlealignment"),
        explain: this.$t("PersonalEndLanguage.Subtitlealignmentillustrate"),
        color: "#90F8E4",
      },
      // {
      //   id: 5,
      //   icon: "ComicBookTranslation",
      //   // 漫画快翻
      //   name: this.$t("PersonalEndLanguage.ComicQuickFlip"),
      //   explain: this.$t("PersonalEndLanguage.ComicQuickFlipillustrate"),
      //   color: "#ff9c95",
      //   // color: "#747886",
      // },
    ];
    this.screenWidth = document.body.clientWidth;

    // this.$nextTick(() => {
    // this.setLookMore();
    // });
    // console.log(JSON.parse(sessionStorage.getItem("info")));
    this.showGiftBagbtns =
      JSON.parse(sessionStorage.getItem("info")).free_status == "0";
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
        // this.setLookMore();
      })();
    };
  },
};
</script>
<style scoped lang="scss">
@import "./WorkbenchMain.scss";
@import "@/assets/scss/dialogStyle.scss";
// 领取本月免费礼包
</style>
