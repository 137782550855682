/*
 * @Description: 自定义指令
 * @version:
 * @Author: Carful
 * @Date: 2023-04-21 09:47:57
 * @LastEditors: Carful
 * @LastEditTime: 2023-07-05 11:36:42
 */
import Vue from "vue";
/**
 * author: carful
 * 水印
 * text：水印文字
 * font：字体
 * textColor：文字颜色
 * width：宽度
 * height：高度
 * textRotate：偏转度 -90到0， 负数值，不包含-90
 */
Vue.directive("watermark", {
  inserted(el, binding) {
    console.log("watermark1");
    let text = binding.value.text;
    let font = binding.value.font || "12px Microsoft YaHei";
    let textColor = binding.value.textColor || "rgba(255,255,255,0.6)";
    let width, height;

    if (binding.value.fullscreen) {
      width = binding.value.width || 250;
      height = binding.value.height || 200;
    } else {
      width = binding.value.width || 500;
      height = binding.value.height || 400;
    }

    function addWaterMarker() {
      console.log("watermark2");
      var can = document.createElement("canvas");
      can.width = width;
      can.height = height;
      can.style.display = "none";
      var cans = can.getContext("2d");
      cans.rotate((-15 * Math.PI) / 180); // 逆时针旋转π/9
      cans.font = font;
      cans.fillStyle = textColor;
      cans.textAlign = "left";
      cans.textBaseline = "Middle";
      cans.fillText(text, 0, can.height);
      cans.fillText(text, can.width / 2, can.height / 2);

      el.style.backgroundImage = "url(" + can.toDataURL("image/png") + ")";
    }

    addWaterMarker();

    const resizeHandler = () => {
      addWaterMarker();
    };

    window.addEventListener("resize", resizeHandler);

    el.__resizeHandler__ = resizeHandler; // 保存引用以便后续移除
  },
  unbind(el) {
    window.removeEventListener("resize", el.__resizeHandler__);
  },
});
