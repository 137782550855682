<!--
 * @Description: 批量指派任务人
 * @version: 
 * @Author: Tom
 * @Date: 2023-03-21 08:41:36
 * @LastEditors: Tom
 * @LastEditTime: 2023-03-21 08:41:36
-->
<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    :width="'888px'"
    :top="'10%'"
    center
    :close-on-click-modal="false"
    @close="closeDialog"
  >
    <div class="GlossariesDialog">
      <div class="glo_bottom">
        <div class="tabs1_top">
          <div
            class="tableClass borderTableStyle"
            :class="tableData.length == 0 ? 'tablebtmborder' : ''"
          >
            <el-table
              :row-style="{
                height: 40 + 'px',
                background: '#393c4e',
                color: '#cbd7e8',
              }"
              :cell-style="{ padding: 0 + 'px' }"
              :header-cell-style="{
                height: 32 + 'px',
                padding: '0px',
                color: '#adb0bc',
                background: '#393c4e',
              }"
              :data="tableData"
              tooltip-effect="dark"
              style="width: 100%; overflow-y: auto; border-right: 1px solid"
              max-height="233px"
              border
            >
              <el-table-column prop="name" :label="$t('CommonName.TaskName')">
              </el-table-column>
            </el-table>
          </div>
          <div class="setSelectClass">
            <p>{{ $t("ProjectManagement.Waterprint1") }}</p>
            <el-select
              v-model="STTUser"
              :placeholder="$t('CommonName.Pleaseselect')"
              filterable
              clearable
              @change="selectChange(1, $event)"
            >
              <el-option
                v-for="item in MemberOptions"
                :key="item.member_id"
                :label="item.nickname"
                :value="item.member_id"
              >
                <div class="selectContentClass">
                  <p class="onep overflowEllipsis">
                    <span>{{ item.nickname }}</span>
                  </p>
                </div>
              </el-option>
            </el-select>
            <div class="AssignFolder">
              <span>{{ $t("PersonalEndLanguage.AssignText1") }}</span>
              <el-checkbox
                v-model="STTNew"
                @change="checkboxChange(1, $event)"
                >{{ $t("PersonalEndLanguage.AssignText2") }}</el-checkbox
              >
            </div>
            <el-select
              v-model="STTFolderSelect"
              :placeholder="$t('CommonName.Pleaseselect')"
              filterable
              clearable
              v-show="!STTNew"
            >
              <el-option
                v-for="item in STTFolderList"
                :key="item.folder_id"
                :label="item.folder_name"
                :value="item.folder_id"
              >
                <div class="selectContentClass">
                  <p class="onep overflowEllipsis">
                    <span>{{ item.folder_name }}</span>
                  </p>
                </div>
              </el-option>
            </el-select>
            <el-input
              v-model="STTFolderName"
              :maxlength="60"
              ref="STTFolderinput"
              v-show="STTNew"
              @blur="inputBlue(1)"
            ></el-input>
            <div v-if="STTValidate" style="margin-top: 12px; color: #ef7373">
              {{ $t("ProjectDetails.FolderNameTip") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div slot="footer" class="dialog-footer" style="margin-top: 24px">
      <div>
        <el-button @click="closeDialog" type="info" class="setpadding23"
          ><p class="footerleftbuttonp">
            {{ $t("CommonName.Cancel") }}
          </p></el-button
        >
        <el-button
          type="primary"
          @click="determineClick"
          class="setpadding23"
          :loading="buttonLoading"
        >
          {{ $t("CommonName.Sure") }}
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { customer_api_method, get_member_list } from "@/utils/https";
import { showLoading, hideLoading } from "@/utils/loading";

export default {
  props: {
    showdialog: {
      type: Boolean,
      default: false,
    },
    multipleSelection: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      buttonLoading: false,
      dialogVisible: false,
      title: "",
      project_id: "",
      MemberOptions: [],
      selectrowData: {}, //术语弹窗 表格 下拉框使用
      STTSelection: [], //STT 数据列表
      tableData: [], // 任务列表
      select: "",
      STTUser: "",
      STTNew: false, //是否选择新建文件夹
      STTFolderSelect: "", //选择保存文件夹选项
      STTFolderName: "", //新建文件夹名称
      STTFolderList: [], //文件夹路径
      STTValidate: false,
    };
  },
  created() {
    this.project_id = this.$route.query.id;
  },
  mounted() {
    console.log(this.multipleSelection);
    this.dialogVisible = this.showdialog;
    this.title = this.$t("ProjectDetails.bottomButtonList.name14");

    this.multipleSelection.forEach((i) => {
      if (!!i.member == false) {
        this.tableData.push(i);
      }
    });

    this.get_member_list();
    // this.get_project_termfile_list();
  },
  methods: {
    //================================ 调用接口======================================
    /**
     *
     * @param {*获取成员列表信息}
     */
    get_member_list() {
      get_member_list({
        action: "get_member_list",
        team_user_status: 2,
      }).then((res) => {
        if (!res) {
          return;
        }
        this.MemberOptions = res.list;
      });
    },
    // =========================================其他 方法========================================================
    /**
     *
     * @param {* 确定按钮} data
     */
    determineClick() {
      let stt_selection = [];
      this.tableData.forEach((item) => {
        stt_selection.push(item.job_id);
      });
      if (this.STTNew && this.STTFolderName.trim() == "") {
        this.$messages(
          "warning",
          this.$t("PersonalEndLanguage.warningmessage47")
        );
        return;
      }
      let data = {
        action: "set_processor_project_job",
        project_id: this.project_id,
        stt_member: this.STTUser,
        stt_selection,
        stt_folder_mode: this.STTNew ? 2 : 1,
        stt_folder_id: this.STTFolderSelect,
        stt_folder_name: this.STTFolderName,
      };
      // console.log(data);
      this.buttonLoading = true;
      customer_api_method(data).then((res) => {
        if (!res) {
          this.buttonLoading = false;
          return;
        }
        this.dialogVisible = false;
        this.$emit("clickOperator", this.dialogVisible);
        this.buttonLoading = false;
      });
    },
    /**
     * 关闭弹窗
     */
    closeDialog() {
      this.$emit("closeOperator", this.dialogVisible);
      this.buttonLoading = false;
      this.dialogVisible = false;
    },
    STTSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 2) {
        return {
          rowspan: this.tableData.length,
          colspan: 1,
        };
      }
    },
    selectChange(mode, value) {
      get_member_list({
        action: "get_member_folder_list",
        member_id: value,
      }).then((res) => {
        if (!res) {
          return;
        }
        // STT
        this.STTFolderSelect = "";
        this.STTFolderList = res.list;
        if (this.STTFolderName.trim() != "" && this.STTNew) {
          this.STTValidate = this.validateFolderName(
            this.STTFolderList,
            this.STTFolderName.trim()
          );
        }
      });
    },
    inputBlue(mode) {
      if (this.STTFolderList.length > 0) {
        this.STTValidate = this.validateFolderName(
          this.STTFolderList,
          this.STTFolderName.trim()
        );
      }
    },
    checkboxChange(mode, value) {
      if (
        this.STTFolderList.length > 0 &&
        this.STTFolderName.trim() != "" &&
        this.STTNew
      ) {
        this.STTValidate = this.validateFolderName(
          this.STTFolderList,
          this.STTFolderName.trim()
        );
      } else {
        this.STTValidate = false;
      }
    },
    /**
     * 验证文件夹名称
     */
    validateFolderName(list, name) {
      return list.some((subArray) => subArray.folder_name === name);
    },
  },
};
</script>
<style scoped lang="scss">
.GlossariesDialog {
  margin: 24px;

  .glo_top {
    width: 840px;
    height: 32px;
    display: flex;
    line-height: 32px;
    background-color: #51546a;
    border-radius: 3px;
    color: #b3d3ec;
    font-size: 14px;
    position: relative;

    p {
      padding: 0 15px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }

    .setbackground {
      background-color: #46adff;
      border-radius: 3px;
      font-weight: 700;
      color: #ffffff;
    }

    .tabsbtnicon {
      position: absolute;
      color: #46adff;
      z-index: 99;
      top: 27px;
    }
  }

  .glo_bottom {
    margin-top: 22px;
    height: 233px;

    .tabs1_bottom {
      // height: 300px;
      margin-top: 24px;
    }

    .tableClass {
      width: 50%;
    }

    .tabs2 {
      p {
        color: #adb0bc;
        font-size: 14px;
        margin-bottom: 10px;
      }

      .setSelectClass {
        ::v-deep .el-input__inner {
          width: 316px !important;
        }

        ::v-deep .el-input__icon {
          line-height: 32px;
        }
      }

      .tableappend {
        height: 40px;
        background-color: #393c4e;
        line-height: 40px;
        padding-left: 12px;
        border-left: 1px solid #515464;
        border-bottom: 1px solid #515464;
        border-right: 1px solid #515464;
      }

      ::v-deep .el-table {
        overflow: initial;
      }
    }
  }

  .tabs1_top {
    display: flex;
    justify-content: space-between;

    .tabs1_top_left {
      display: flex;

      p {
        color: #adb0bc;
        font-size: 14px;
        margin-bottom: 5px;
      }

      .selectclass {
        margin-right: 24px;
      }

      ::v-deep .el-input__inner {
        width: 220px !important;
      }

      ::v-deep .el-input__icon {
        line-height: 32px;
      }
    }

    .tabs1_top_right {
      margin-top: 24px;
    }

    .setSelectClass {
      width: 360px;
      .AssignFolder {
        margin: 12px 0 6px 0;
        color: #adb0bc;
        display: flex;
        justify-content: space-between;
      }
      ::v-deep .el-checkbox {
        color: #adb0bc;
      }
      p {
        color: #adb0bc;
        font-size: 14px;
        margin-bottom: 5px;
      }
    }
  }
}

.selectContentClass {
  width: 318px;

  p {
    width: 100%;
  }
}
</style>
